import { cipo } from 'cipo';

    cipo.controller('editHContractController',
        function ($scope, printService, WorkflowEntityInstance, Message, ADJUSTMENTS, MODAL, CIPO_ICONS, Form, item, module, screenId, $mdDialog, $window, ContractH, userService, $timeout, fileService) {
            $scope.module = module;
            $scope.itemId = item.entity_instance_id || 0;
            $scope.isInitiator = item.isInitiator || false;
            $scope.modalInfo = MODAL;
            $scope.isDisplayDupForm = false;
            $scope.screenId = screenId;

            Object.defineProperty($scope, 'operations', {
                get: function () { return userService.getOperationsFor(module.code) }
            });
            $scope.loadInstance = function (id) {
                $scope.entityInstance = new WorkflowEntityInstance({
                    moduleId: module.moduleId,
                    moduleCode: module.code,
                    entityInstanceId: id || $scope.itemId,
                    transitionTriggered: onContractTransitionTriggered,
                    screenId: $scope.screenId
                });
                $scope.entityInstance.operations = $scope.operations;
                $scope.entityInstance.init()
                    .then(function () {
                        if (id) {
                            createContract(id);
                        }
                        // $scope.contractOps = $scope.entityInstance.operations;
                    })
                    .catch(function () { })

            };
            $scope.icons = CIPO_ICONS().lookup;
            $scope.currencySymbol = userService.getCurrency();
            $scope.Math = Math;
            $scope.periodsTab = function () {
                $scope.contract.isPeriodsLoaded  = false;
                if ($scope.entityInstance.isDraft) 
                    return;
                
                $scope.contract.get_periods();
            }

            $scope.assignmentsTab = function () {
                if (!$scope.contract.isAssignmentsLoaded)
                    $scope.contract.init_assignments();
            }

            $scope.presetsTab = function () {
                if (!$scope.contract.isPresetsLoaded) {
                    $scope.contract.init_presets();
                }
            }

            $scope.adjustmentsTab = function () {
                if (!$scope.contract.isAdjustmentLoaded)
                    $scope.contract.init_adjustments();
            }

            var formatMoney = function (amount) {

                var currencySymbol = userService.getCurrency();
                if (amount) {
                    var sign = amount > 0 ? currencySymbol : "-" + currencySymbol;
                    var noString = Math.abs(amount).toFixed(2).toString();
                    var decimal = noString.slice(-3);
                    noString = noString.slice(0, -3);
                    noString = noString.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                    return sign + noString + decimal;

                }
                else return currencySymbol + "0.00";
            }

            var retOpt = function () {
                return {
                    loadingFlag: 'isRetentionsLoaded',
                    listKey: "retentionsList",
                    listItemUsed: "inUse",
                    title: "Recurring adjustments",
                    hasSearch: true,
                    parent: $scope.contract,
                    // subtitle: { text: "Type: ", content: function (item) { return "Transition form"; } },
                    addButton: {
                        text: "Add adjustment",
                        icon: "mdi-plus",
                        condition: function (item) {
                            return true;
                        },
                        click: function () {
                            return $scope.contract.new_Retention();
                        }
                    },
                    save_or_delete: "retention_Action",
                    operations: $scope.entityInstance.operations

                };
            }

            var otaOpt = function () {
                return {
                    loadingFlag: 'isOTALoaded',
                    listKey: "otaList",
                    listItemUsed: "inUse",
                    title: "One time adjustments",
                    hasSearch: true,
                    parent: $scope.contract,
                    // subtitle: { text: "Type: ", content: function (item) { return "Transition form"; } },
                    addButton: {
                        text: "Add adjustment",
                        icon: "mdi-plus",
                        condition: function (item) {
                            return true;
                        },
                        click: function () {
                            return $scope.contract.new_OTAdjustment();
                        }
                    },
                    save_or_delete: "otAdjustment_Action",
                    operations: $scope.operations

                };
            }

            var periodsOpt = function () {
                return {
                    loadingFlag: 'isPeriodsLoaded',
                    listKey: "periodsObjList",
                    listItemUsed: "inUse",
                    title: "Periods",
                    hasSearch: true,
                    parent: $scope.contract,
                    step: 15,
                    subtitle: {
                        text: "",
                        content: function (item) {
                            var subtitle = '<span class="miniStatus" style=" color: ' + item.properties.paymentInfo.textColor
                                + '; background-color: ' + item.properties.paymentInfo.color
                                + '">' + item.properties.paymentInfo.status + '</span>'
                                + (item.properties.amount ? ('<span>Amount:</span> $' + item.properties.amount) : '');
                            return subtitle;
                        }
                    },
                    addButton: {
                        text: "Add period",
                        icon: "mdi-plus",
                        condition: function (item) {
                            return item.periodMessage ? false : true;
                        },
                        click: function () {
                            return $scope.contract.new_Period();
                        }
                    },
                    showDelete: function (item) {
                        return item.properties.allowsDelete ? true : false;
                    },
                    save_or_delete: "period_Action",
                    operations: $scope.operations
                };
            }

            var adjustmentOpt = function () {
                return {
                    loadingFlag: 'isAdjustmentLoaded',
                    listKey: "adjustmentList",
                    listItemUsed: "inUse",
                    title: "Adjustments",
                    hasSearch: true,
                    parent: $scope.contract,
                    subtitle: {
                        text: "",
                        hideClassInLine: true,
                        content: function (item) {
                            var parentheses = function (amount, prefix, suffix) {
                                var ret;
                                if (amount) {
                                    ret = (amount < 0 ? '-' : '') + (prefix || '') + Math.abs(amount).toFixed(2) + (suffix || '');
                                } else ret = '-';
                                return ret;
                            }

                            var numberWithCommas = function (x, prefix, suffix) {
                                var ret;
                                if (x) {

                                    var isNegative = x < 0 ? "-" : "";
                                    x = Math.abs(x).toFixed(2);
                                    var parts = x.toString().split(".");
                                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    ret = isNegative + (prefix || '') + parts.join(".") + (suffix || '');

                                } else ret = '-';
                                return ret;
                                
                            }

                            var value = (item.properties.isDeduction ? -1 : 1) * (item.properties.valueType == 1 ? item.properties.amount : item.properties.percentage);
                            var dollarSign = item.properties.valueType == 1 ? '$' : '';
                            var percentSign = item.properties.valueType == 2 ? '%' : '';
                            //return '<span>Amount:</span> ' + parentheses(value, dollarSign, percentSign) + '<span>, '
                            //    + 'Start Date:</span> ' + (item.properties.start ? item.properties.start.format(userService.formats.date) : '- ') + '<span>, '
                            //    + 'End Date:</span> ' + (item.properties.end ? item.properties.end.format(userService.formats.date) : '- ') + '<span>, </span>'
                            //    + (item.properties.isRecurring ? 'Recurring' : item.properties.isAccrual ? ('Accruing <span>(once per </span>' + item.accrualPeriodsLookup[item.properties.accrualFrequencyId].value + '<span>)</span>') : 'One Time') + '<span>, '
                            //    + 'Category:</span> ' + item.categoriesLookup[item.properties.categoryId].value
                            //    + (item.properties.allowsReleases
                            //        ? (item.properties.retainedAmount ? ('<span>, Total Retained:</span> ' + parentheses(item.properties.retainedAmount, '$')) : '')
                            //        + (item.properties.releasedAmount ? ('<span>, Total Released:</span> ' + parentheses(item.properties.releasedAmount, '$')) : '')
                            //        + (item.properties.paidAmount ? ('<span>, Total Paid:</span> ' + parentheses(item.properties.paidAmount, '$')) : '')
                            //        + (item.properties.remainingRetainedAmount && item.properties.releasedAmount ? ('<span>, Remaining:</span> ' + parentheses(item.properties.remainingRetainedAmount, '$')) : '')
                            //        : '');

                            return '<table class="table doc-table mainTable descriptionTable"><tr>'
                                + '<th class="tabletKeep phoneKeep" style="width: 5%;">Amount</th>'
                                + '<th class="tabletKeep phoneKeep" style="width: 9%;">Start Date</th><th class="tabletKeep phoneKeep" style="width: 9%;">End Date</th>'
                                + '<th class="tabletKeep phoneKeep" style="width: 15%;">Type</th><th class="tabletKeep phoneKeep" style="width: 15%;">Category</th>'
                                + '<th class="tabletKeep phoneKeep">Total Retained</th>'
                                + '<th class="tabletKeep phoneKeep">Total Released</th>'
                                + '<th class="tabletKeep phoneKeep">Total Paid</th><th class="tabletKeep phoneKeep">Remaining</th></tr>'
                                + '<tr><td class="tabletKeep phoneKeep">' + numberWithCommas(value, dollarSign, percentSign) + '</td>'
                                + '<td class="tabletKeep phoneKeep">' + (item.properties.start ? item.properties.start.format(userService.formats.date) : '- ') + '</td>'
                                + '<td class="tabletKeep phoneKeep">' + (item.properties.end ? item.properties.end.format(userService.formats.date) : '- ') + '</td>'
                                //+ '<td>' + (item.properties.isRecurring ? 'Yes' : 'No') + '</td>'
                                + '<td class="tabletKeep phoneKeep">' + item.properties.typeName + '</td>'
                                + '<td class="tabletKeep phoneKeep">' + item.categoriesLookup[item.properties.categoryId].value + '</td>'
                                + '<td class="tabletKeep phoneKeep">' + numberWithCommas(item.properties.retainedAmount, '$') + '</td>'
                                + '<td class="tabletKeep phoneKeep">' + numberWithCommas(item.properties.releasedAmount, '$') + '</td>'
                                + '<td class="tabletKeep phoneKeep">' + numberWithCommas(item.properties.paidAmount, '$') + '</td>'
                                + '<td class="tabletKeep phoneKeep">' + numberWithCommas(item.properties.remainingAmount, '$') + '</td>'
                                + '</tr></table>';
                        }
                    },
                    addButton: {
                        text: "Add adjustment",
                        titleOnPage: function (item) {
                            return "Add " + item.properties.typeName + " Adjustment" 
                        },
                        icon: "mdi-plus",
                        condition: function (item) {
                            return true;
                        },
                        items: ADJUSTMENTS.types,
                        click: function (parent, item) {
                            return $scope.contract.new_Adjustment(item);
                        }
                    },

                    hasChildren: true,
                    children: {
                        parent: function () { return $scope.contract; },
                        isCollapsed: true,
                        hasCounts: true,
                        keepSmallBtn: true,
                        addButton: {
                            text: "Release retained amount",
                            icon: "mdi mdi-plus",
                            condition: function (item) {
                                var permission = $scope.operations['Release Adjustment'] ? true : false;
                                return item.properties.allowsReleases && permission;
                            },
                            click: function (parent) {
                                return parent.new_Release();
                            }
                        },
                        listKey: "releaseList",
                        title: "Releases",
                        hideTitleWhenAddPrevented: true,
                        //getListAction: "get_adjustments",
                        save_or_delete: "release_Action",
                        keepFormOnSave: true,
                        operations: $scope.operations,
                        subtitle: {
                            text: "",
                            content: function (item) {
                                var parentheses = function (amount, prefix, suffix) { return (amount < 0 ? '(' : '') + (prefix || '') + Math.abs(amount).toFixed(2) + (suffix || '') + (amount < 0 ? ')' : ''); }
                                return '<span>Amount:</span> ' + parentheses(item.properties.amount, '$') + (item.properties.paid ? ' (Paid)' : '') + '<span>, '
                                    + 'Released:</span> ' + (item.properties.lastUpdatedDate ? item.properties.lastUpdatedDate.format(userService.formats.date) : '- ') + '<span>, '
                                    + 'Comment:</span> ' + (item.properties.comment ? item.properties.comment : '-');
                            }
                        },
                    },

                    save_or_delete: "adjustment_Action",
                    operations: $scope.operations
                };
            }

            var createContract = function (id) {
                $scope.contract = new ContractH(id || $scope.itemId, $scope.entityInstance.operations);
                // $scope.contract.operations = $scope.entityInstance.operations;
                // $scope.retentionsOptions = retOpt();
                // $scope.otaOptions = otaOpt();
                $scope.adjustmentOptions = adjustmentOpt();
                $scope.periodsOptions = periodsOpt();

                $scope.contract.get_details().then((details) => {
                    if ((details || {}).hasOverview || !$scope.entityInstance.isDraft) {
                        $scope.contract.get_overview();
                    }
                    else {
                        $scope.contract.details.contractNo = null;
                    }
                });
            }

            var onContractTransitionTriggered = function (data, err) {
                if (!err && $scope.contract) {
                    $scope.contract.properties.id = $scope.entityInstance.entityInstanceId;
                    $scope.contract.get_overview().then(function () {
                        $scope.contract.details.contractNo = $scope.contract.overview.contractNo;
                    });
                }
            }
            userService.getWorkflow($scope.module.moduleId)
                .then(function() {
                    $scope.loadInstance();
                });
            
            $scope.toggleModuleSummaries = function (_module) {
                _module.expanded = !_module.expanded;

                if(_module.expanded)
                    $scope.contract.get_detailedSummaries(_module);

                return;
            }

            $scope.allModulesSummariesExpanded = false;

            $scope.toggleAllModuleSummaries = function () {
                $scope.contract.overview.allModulesSummariesExpanded = !$scope.contract.overview.allModulesSummariesExpanded;
            }

            $scope.$watch(function () {
                if($scope.entityInstance && $scope.entityInstance.hasOwnProperty('isDraft') && typeof $scope.entityInstance.isDraft != 'undefined')
                    return $scope.entityInstance.isDraft;
            },
                function (n, o) {
                    //console.error('id changed', n);
                    if (n === false && !$scope.contract) {
                        createContract($scope.entityInstance.entityInstanceId);
                    }
                });

            $scope.openMenu = function ($mdMenu, ev) {
                // originatorEv = ev;
                console.log('clicked', $mdMenu);
                $mdMenu.open(ev);
            };
            $scope.disabledBtn = false;

            // prevent tab change if unsaved changes
            $scope.$on('tab-change', function (event, tabEvent) {
                if (!$scope.contract || !$scope.contract.isDirtyAssignments) tabEvent.accept();
                else {
                    // capture the index of the tab cliked
                    $scope.navToIndex = tabEvent.index;
                    // set the warning params
                    $scope.hightlight = true;
                    $scope.tabWarning = true;

                    $scope.disabledBtn = true;
                    // prevent tab change
                    tabEvent.reject();
                }
            });

            // print stuff
            $scope.printStuff = function (stuffId) {
                if ($("#section-to-print").length == 0) {
                    var div = $('<div />').appendTo('body');
                    div.attr('id', 'section-to-print');
                    div.attr('class', 'contractOverview');
                }

                var toPrint = $('#' + stuffId).html();

                $('#section-to-print').html(toPrint);
                $('#section-to-print md-card').replaceWith(function () {
                    return $('<div/>', {
                        html: this.innerHTML
                    });
                });
                $('#section-to-print md-card-content').replaceWith(function () {
                    return $('<div/>', {
                        html: this.innerHTML
                    });
                });

                $timeout(function () {
                    $window.print();
                }, 1000);
            }

            $scope.printMe = function (printoutId, isClosed) {

                // Get default print or first in case printoutId is not sent
                if (!printoutId) {
                    printoutId = $scope.entityInstance.printouts.filter((printout) => printout.isPrimary).length > 0
                        ? $scope.entityInstance.printouts.filter((printout) => printout.isPrimary)[0].key
                        : $scope.entityInstance.printouts[0].key;
                }
                
                $scope.entityInstance.isPrintStarted = true;
                $scope.entityInstance.get_Printout(printoutId, isClosed)
                    .then(function (result) {
                        // If we downloaded the blob, then we just need to print the content
                        if (isClosed && result && result.length) {

                            $timeout(function () {
                                
                                var iframe = document.createElement('iframe');
                                iframe.style.display = 'none';
                                iframe.src = window.URL.createObjectURL(fileService.createFile(result, '', 'application/pdf'));
                                document.body.appendChild(iframe);

                                $timeout(function () {
                                    iframe.contentWindow.print();
                                }, 400);
                            }, 0);
                        }
                        // We do a print
                        else {
                            if (!result.margin) result.margin = { marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0 };
                            else result.margin = { marginLeft: result.marginLeft || 0, marginRight: result.marginRight || 0, marginTop: result.marginTop || 0, marginBottom: result.marginBottom || 0 };

                            if ($("#section-to-print").length == 0) {
                                var div = $('<div />').appendTo('body');
                                div.attr('id', 'section-to-print');
                            }

                            $("#section-to-print").css({
                                "margin-left": result.margins.marginLeft + "in",
                                "margin-right": result.margins.marginRight + "in",
                                "margin-bottom": result.margins.marginBottom + "in",
                                "margin-top": result.margins.marginTop + "in"
                            });

                            var content = '<div style="position: fixed; top: ' + result.margins.marginTop + 'in; left: ' + result.margins.marginLeft + 'in; right: ' + result.margins.marginRight + 'in; overflow: hidden; height: ' + ((result.header || {}).height + 'in' || 'auto') + ';">'
                                + ((result.header || {}).content || "")
                                + '</div><table><thead><tr><td><div class="invisible" style="height: ' + ((result.header || {}).height + 'in' || 'auto') + ';">'
                                + ((result.header || {}).height ? "" : ((result.header || {}).content || ""))
                                + '</div></td></tr></thead>'
                                + '<tbody class="forceBlock"><tr class="forceBlock"><td class="forceBlock">'
                                + (result.content || "")
                                + '</td></tr></tbody>'
                                + '<tfoot><tr><td><div class="invisible" style="height: ' + ((result.footer || {}).height + 'in' || 'auto') + ';">'
                                + ((result.footer || {}).height ? "" : ((result.footer || {}).content || ""))
                                + '</div></td></tr></tfoot></table >'
                                + '<div style="position: fixed; bottom: ' + result.margins.marginBottom + 'in; left: ' + result.margins.marginLeft + 'in; right: ' + result.margins.marginRight + 'in; overflow: hidden; height: ' + ((result.footer || {}).height + 'in' || 'auto') + ';">'
                                + ((result.footer || {}).content || "")
                                + '</div>';

                            $('#section-to-print').html(content);
                            
                            printService.setPrintLayout(result.isLandscape);

                            $timeout(function () {
                                $window.print();
                                printService.clearPrintStyle();
                            }, 1000)
                        }
                    })
                    .catch(function (e) {
                        console.error(e);
                        Message.dberror(e);
                    })
                    .finally(function () {
                        $scope.entityInstance.isPrintStarted = false;
                    });
            }


            // warning actions
            $scope.revertTabInfo = function () {
                console.error('revert params', $scope.closingModal);
                $scope.tabWarning = false;
                $scope.hightlight = false;
                $scope.disabledBtn = false;

                if (!$scope.closingModal) {
                    $scope.contract.revertAssignmentsChanges();
                    $scope.selectedIndex = $scope.navToIndex;
                } else {
                    $scope.close(true);
                }
            }

            $scope.updateTabInfo = function () {
                $scope.hightlight = false;
                $scope.actionBtnDisabled = true;

                $scope.contract.save_assignments()
                    .then(function (r) {
                        $scope.tabWarning = false;
                        $scope.disabledBtn = false;
                        if (!$scope.closingModal) {
                            //console.error('changing tab')
                            $scope.selectedIndex = $scope.navToIndex;
                        } else {
                            //console.error('closing modal')
                            $scope.close(true);
                        }

                    })
                    .catch(function (e) {
                        console.error(e);
                    })
                    .finally(function () {
                        $scope.actionBtnDisabled = false;
                    })
            }

            $scope.cancelAction = function () {
                $scope.tabWarning = false;
                $scope.hightlight = false;
                $scope.disabledBtn = false;

                if ($scope.closingModal) $scope.closingModal = false;
            }

            //!($scope.contract || {}).isDirtyModules ||
            $scope.close = function (isWarning) {
                if (!($scope.contract || {}).isDirtyAssignments || isWarning) {
                    if (!$scope.entityInstance.isModified && !($scope.contract || {}).isModified) $mdDialog.cancel();
                    else $mdDialog.hide(($scope.contract || {}).isModified);
                } else {
                    //
                    $scope.hightlight = true;
                    $scope.tabWarning = true;
                    $scope.closingModal = true;
                }

            }

            $scope.duplicateBtnClick = function ($event) {
                $scope.selectedIndex = 1;
                var description = {
                    newContractName: { label: 'Contract Name', type: 'text', validation: { required: true, maxChars: 250 } },
                    newContractNo: { label: 'Contract No', type: 'text', validation: { required: true, maxChars: 80 } },
                    isDuplicateProject: { label: 'Create duplicate project', type: 'checkbox' }
                };
                var template = [
                    { newContractName: 50, newContractNo: 50 },
                    { isDuplicateProject: 100 }
                ];
                $scope.dupFormProperties = {
                    newContractName: "",
                    newContractNo: "",
                    isDuplicateProject: false
                };

                $scope.duplicationForm = new Form($scope.dupFormProperties);
                $scope.duplicationForm.set_Description(description);
                $scope.duplicationForm.setTemplate('grid', template);
                $scope.duplicationForm.store_Data();

                $scope.isDisplayDupForm = true;
                $scope.contract.isBusy = true;
                $scope.entityInstance.isBusy = true;
            };

            $scope.confirmDuplication = function () {
                $scope.duplicationForm.validate();
                if ($scope.duplicationForm.isValid) {
                    var paramsObject = angular.copy($scope.dupFormProperties)
                    // paramsObject.contractId = userService.system.userdata.contractId;
                    paramsObject.entityInstanceId = $scope.entityInstance.entityInstanceId;
                    paramsObject.moduleId = $scope.module.moduleId;

                    $scope.duplicationForm.loading = true;

                    $scope.contract.duplicate(paramsObject)
                        .then(function (entityInstanceId) {
                            $scope.loadInstance(entityInstanceId);
                            // Hides/resets form, toggles busy flags
                            $scope.cancelDuplication();
                        }).catch(function (err) {
                            Message.dberror(err);
                            $scope.duplicationForm.loading = false;
                        });
                }
            }

            $scope.cancelDuplication = function () {
                $scope.duplicationForm.restore_Data();
                $scope.duplicationForm.loading = false;
                $scope.isDisplayDupForm = false;
                $scope.contract.isBusy = false;
                $scope.entityInstance.isBusy = false;
            }
        });
