import { cipo } from 'cipo';

cipo.factory("URL", function ($q, Model, URI, Dictionaries, ModuleDictionaries, Form, Message) {
    var URL = Model.extend(function (obj, simplifiedVersion) {
        var self = this;
        
        self.loading = true;
        self.disabled = obj ? obj.disabled : false;
        self.simplifiedVersion = simplifiedVersion || false;
        
        self.properties = {
            id: obj ? obj.id : null,
            disabled: obj ? obj.disabled : false,
            iconId: obj ? obj.iconId : null,
            isSystem: obj ? obj.isSystem : false,
            isUrl: obj ? obj.isUrl : true,
            url: obj ? obj.url : null,
            name: obj ? obj.name : "",
            description: obj ? obj.description : ""
        };

        if (simplifiedVersion) {
            self.properties.id = obj.moduleId;
        }

        self.operationsDict = [];
        self.permissionsList = [];
        
        //if existent
        if (self.properties.id) {
            if (!simplifiedVersion) {
                self.loadingPermissions = true;
            }
            self.get_data();
        }

        self.form = new Form(self.properties);
        self.form.initializing = true;

        //create form
        var form = {
            name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 100 } },
            url: { label: 'URL', type: 'text', validation: { required: true, maxChars: 500 } }
        };
        
        self.form.set_Description(form);

        self.form.setTemplate('grid', [
            { name: 100 },
            { url: 100 }
        ]);

        self.form.store_Data();

        self.loading = true;
        self.form.initializing = false;

    });

    URL.prototype.get_data = function () {
        var self = this;
        var p = $q.defer();
        self.loading = true;
        self[URI.MODULES.GET.method](URI.MODULES.GET + '?id=' + self.properties.id)
            .then(function (result) {
                for (var key in result) {
                    if (result.hasOwnProperty(key)) {
                        self.properties[key] = result[key];
                    }
                }
                if (!self.simplifiedVersion)
                    self.initialize();
                self.form.set_Data(self.properties);
                p.resolve();
            }).catch(function (e) {
                p.reject(e);
                console.error(e);
            }).finally(function () {
                self.loading = false;
            });
        return p.promise;
    };

    URL.prototype.initialize = function () {
        var self = this;
        
        self.isBusy = true;
        self.currentType = {};
        self.rolesLookup = {};
        self.backupRolesList = [];
        self.rolesList = [];

        Object.defineProperty(self, 'dirtyPermissions', {
            get: function () { return !angular.equals(self.extractPermissions(), self.backupRolesList); }
        });

        Dictionaries.ModuleTypes(self.properties.id).then(function (r) {
            self.isBusy = false;
            self.moduleTypes = r;
            self.currentType = r && r.length ? r[0] : undefined;
            ModuleDictionaries.init({ moduleId: self.properties.id, currentType: self.currentType });
            self.roles_init();
        });
    };

    URL.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        self.form.validate();
        var urlData = self.properties.id ? URI.MODULES.EDIT : URI.MODULES.ADD;
        if (self.form.isValid) {
            var params = {
                url: !self.properties.id ? { roleId: self.selectedRoleId } : {}, urltype: 'obj',
                body: self.properties
            };
            self[urlData.method](urlData, params)
                .then(function (r) {
                    if (!self.properties.id) {
                        self.properties.id = r;
                        self.initialize();
                    }
                    self.form.set_Data(self.properties);
                    self.form.store_Data();
                    p.resolve();
                })
                .catch(function (e) {
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    self.form.loading = false;
                });
        }
        else {
            self.form.loading = false;
            p.reject();
        }

        return p.promise;
    };
    
    URL.prototype.enable = function () {
        var self = this;
        var p = $q.defer();
        self[URI.MODULES.ENABLE.method](URI.MODULES.ENABLE, { body: { list: [self.properties.id] } })
            .then(function (result) {
                self.properties.disabled = false;
                self.form.set_Data(self.properties);
                p.resolve(result);
            })
            .catch(function (e) {
                p.reject(e);
            });
        return p.promise;
    };

    URL.prototype.disable = function () {
        var self = this;
        var p = $q.defer();
        self[URI.MODULES.DISABLE.method](URI.MODULES.DISABLE, { body: { list: [self.properties.id] } })
            .then(function (result) {
                self.properties.disabled = true;
                self.form.set_Data(self.properties);
                p.resolve(result);
            })
            .catch(function (e) {
                p.reject(e);
            });
        return p.promise;
    };

    // permissions related
    // roles

    URL.prototype.openAssignmentDialog = function () {
        var self = this;
        self.isShowingRoles = !self.isShowingRoles;
        if(!self.dirtyPermissions) self.isBusy = !self.isBusy;
        ModuleDictionaries.getDicts(["allRoles"])
            .then(function () {
                self.assignmentsDict = angular.copy(ModuleDictionaries.dataSources.allRoles.data);
            })
    }

    URL.prototype.cancelAssignmentDialog = function () {
        var self = this;
        self.isShowingRoles = !self.isShowingRoles;
        self.isBusy = !self.isBusy;
    }

    URL.prototype.sync_assignments = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        self.isSavingAssignments = true;
        var urlData = URI.ROLES.ASSIGN_ROLES_TO_MODULETYPE;
        var toSend = [];
        for (var i = 0; i < self.assignmentsDict.length; i++) {
            if (self.assignmentsDict[i].isUsed) toSend.push(self.assignmentsDict[i].key);
        }
        self[urlData.method](urlData, { url: {}, body: { id: self.currentType.key || self.properties.id, roleIds: toSend }, urltype: 'obj' })
            .then(function (r) {
                self.isShowingRoles = false;
                self.get_RolesList();
                ModuleDictionaries.get_dataSource("allRoles");
                Message.info('Assignments saved successfully.')
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isSavingAssignments = false;
                self.isBusy = false;
            });

        return p.promise;
    }

    URL.prototype.save_assignments = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlData = URI.ROLES.SYNC_MODULE_ROLES;
        var toSend = self.extractPermissions();
        self[urlData.method](urlData, { url: {}, urltype: 'obj', body: toSend })
            .then(function (r) {
                ModuleDictionaries.get_dataSource("allRoles");
                self.get_RolesList();
                self.restoreRolesList = angular.copy(self.rolesList);

                self.backupRolesList = angular.copy(self.extractPermissions());
                p.resolve(r);
            })
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isBusy = false;
            });

        return p.promise;
    }

    URL.prototype.roles_init = function (moduleType) {
        var self = this;
        
        self.currentType = moduleType || (self.currentType || self.moduleTypes[0]);
        self.get_RolesList();
        ModuleDictionaries.getDicts(["allRoles"]);
    }

    URL.prototype.processItem = function (item) {
        item.permissionsLine = "";
        if ((item.permissions || []).length) {
            for (var j = 0; j < item.permissions.length; j++) {
                item.permissions[j].filterId = item.permissions[j].filterId || item.permissions[j].filters[0].key;
                if (item.permissions[j].isUsed) item.permissionsLine += item.permissions[j].name + ', ';
            }
        }
        if (!item.permissionsLine) item.permissionsLine = '';
        else item.permissionsLine = item.permissionsLine.substring(0, item.permissionsLine.length - 2);

        if (!item.bkpPermissionsLine) item.bkpPermissionsLine = item.permissionsLine;
        return item;
    }

    URL.prototype.extractPermissions = function () {
        var self = this;
        var permissionsObj = {
            id: self.currentType.key,
            roles: []
        };
        if (self.rolesList && self.rolesList.length) {
            for (var i = 0; i < self.rolesList.length; i++) {
                var role = { roleId: self.rolesList[i].roleId };
                role.permissions = [];
                if (self.rolesList[i].permissions && self.rolesList[i].permissions.length) {

                    for (var j = 0; j < self.rolesList[i].permissions.length; j++) {

                        if (self.rolesList[i].permissions[j].isUsed)
                            role.permissions.push({
                                permissionId: self.rolesList[i].permissions[j].id,
                                filterId: self.rolesList[i].permissions[j].filterId
                            });
                    }
                    if (role.permissions.length) permissionsObj.roles.push(role);
                }
            }
        }

        return permissionsObj;
    }

    URL.prototype.checkPermissionDirty = function (permission, role) {
        var self = this;

        var isDirty = !self.permissionsLookup[role.roleId][permission.id] && permission.isUsed
            || self.permissionsLookup[role.roleId][permission.id] && !permission.isUsed
            || permission.isUsed && self.permissionsLookup[role.roleId][permission.id] != permission.filterId;
        
        return isDirty;
    }
    
    URL.prototype.get_RolesList = function (moduleType) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        self.isRolesLoaded = false;
        if (moduleType || self.rolesModuleTypeKey != self.currentType.key) self.rolesList = [];
        self.currentType = moduleType || (self.currentType || self.moduleTypes[0]);
        self.rolesModuleTypeKey = self.currentType.key;
        var dataURL = URI.ROLES.GET_MODULE_ROLES;
        self.permissionsLookup = {};
        self[dataURL.method](dataURL, { url: { moduleTypeId: self.currentType.key }, urltype: "obj" }, { headers: { moduleId: self.properties.id } })
            .then(function (r) {
                // self.rolesList = r.data;
                if (r.data && r.data.length) {
                    self.set_permission_dirty(r.data);
                }

                self.rolesList = r.data || [];

                ModuleDictionaries.getDicts(["allRoles"])
                    .then(function () {
                        for (var i = 0; i < self.rolesList.length; i++) {
                            self.rolesList[i].isDisabled = ModuleDictionaries.dataSources.allRoles.lookup[self.rolesList[i].roleId].isDisabled || false;
                        }
                    })

                self.restoreRolesList = angular.copy(self.rolesList);
                self.backupRolesList = angular.copy(self.extractPermissions());

                
                p.resolve();
                self.isRolesLoaded = true;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    URL.prototype.set_permission_dirty = function (data) {
        var self = this;
        
        for (var i = 0; i < data.length; i++) {
            data[i] = self.processItem(data[i]);
            data[i].isExpanded = (self.rolesLookup[data[i].roleId] || {}).isExpanded || false;
            self.permissionsLookup[data[i].roleId] = {};
            for (var j = 0; j < data[i].permissions.length; j++) {

                if (data[i].permissions[j].isUsed) {
                    self.permissionsLookup[data[i].roleId][data[i].permissions[j].id] = data[i].permissions[j].filterId;
                }
                data[i].permissions[j].isDirty = false;

            }

            (function (index) {
                Object.defineProperty(data[index], 'isDirty', {
                    get: function () {
                        var isDirty = false;
                        for (var k = 0; k < data[index].permissions.length; k++) {
                            if (data[index].permissions[k].isDirty) isDirty = true;
                            // break;
                        }

                        return isDirty;

                    }
                });
            }(i));

            self.rolesLookup[data[i].roleId] = data[i];



        }

    }

    return URL;
});
