import { cipo } from 'cipo';

cipo.controller('editFileController',
    function ($scope, MODAL, URI, $http, $timeout, file, dataURL, otherParams, $mdDialog, Message, Dictionaries, File) {
        $scope.modalInfo = MODAL;
        $scope.disabledBtn = false;
        $scope.file = new File(file, dataURL, otherParams);
        $scope.closeModal = function () {
            $scope.file.isModified
                ? $mdDialog.hide()
                : $mdDialog.cancel();
        };
        $scope.sortableOptions = {
            handle: '> span>.listHandle',
            'ui-floating': true
        }
        $scope.openMenu = function ($mdMenu, ev) {
            $mdMenu.open(ev);
        };

    });
