import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient, private loaderService: LoaderService) {}

  get<T>(partialurl: string): Observable<T> {
    this.loaderService.show();
    return this.http.get<T>(environment.baseApiUrl + partialurl).pipe(
      tap(() => this.loaderService.hide()),
      catchError(err => {
        this.loaderService.hide();
        return throwError(() => err);
      }),
      take(1)
    );
  }

  post<T>(partialurl: string, body: any) {
    this.loaderService.show();
    return this.http.post<T>(environment.baseApiUrl + partialurl, body).pipe(
      tap(() => this.loaderService.hide()),
      catchError(err => {
        this.loaderService.hide();
        return throwError(() => err);
      }),
      take(1)
    );
  }

  put<T>(partialurl: string, body: any) {
    this.loaderService.show();
    return this.http.put<T>(environment.baseApiUrl + partialurl, body).pipe(
      tap(() => this.loaderService.hide()),
      catchError(err => {
        this.loaderService.hide();
        return throwError(() => err);
      }),
      take(1)
    );
  }
}
