import { cipo } from 'cipo';

cipo.controller('contractsController',
function ($scope, $state, $http, $location, userService, rememberManagerService, Manager, dialogs, URI, ACTIONS, $mdDialog, Message, AggregateOperators) {
    $scope.moduleName = userService.system.mNames[$state.current.code];
    $scope.canPrint = true;
    $scope.module = userService.system.modules[$state.current.code];
    $scope.initiator = false;
    var moduleCode = $state.current.code;
    var moduleId = $scope.module.moduleId;
    if ($scope.module) {
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor(moduleCode) }
        });

        $scope.display = true;
    } else {
        $state.go('tenant.404', null, { location: false });
    }
    $scope.currencySymbol = userService.getCurrency();

    // Get workflow for the module
    userService.getWorkflow($scope.module.moduleId);

    var bottomFormulasLookup = {};

    var setBottomFormulas = function () {
        $scope.AggregateOperators = [];
        $scope.bottomFormulas = [];
        bottomFormulasLookup = {};

        for (var key in AggregateOperators) {
            if (AggregateOperators.hasOwnProperty(key)) {
                $scope.AggregateOperators.push({ key: parseInt(key), value: AggregateOperators[key] });
                var op = {
                    key: parseInt(key), value: AggregateOperators[key], fields: {}, fieldIds: []
                };
                $scope.bottomFormulas.push(op);
                bottomFormulasLookup[key] = op;
            }

        }
    }

    // hybrid temporary changes
    var dataURL = typeof URI[moduleCode.toUpperCase()] != "undefined" ?
        URI[moduleCode.toUpperCase()].GRID_SEARCH : URI.MODULE.GRID_SEARCH;
    var columnsURL = typeof URI[moduleCode.toUpperCase()] != "undefined" ?
        URI[moduleCode.toUpperCase()].GRID_FIELDS : URI.MODULE.GRID_FIELDS;
    if ($scope.module) {
        $scope.manager = new Manager({
            objectsPerPage: 20,
            dataURL: dataURL,
            layout: 2,
            defaultColumns: 1,
            gridClass: 'contractsGrid',
            urlParams: {
                moduleId: moduleId
            },
            layoutUrlParams: function (layout) {
                return {
                    dataListType: (layout == 2 ? 3 : 1),
                };
            },
            moduleId: $scope.module.moduleId,
            moduleName: $scope.module.name,
            moduleCode: $scope.module.code,
            contractId: userService.system.userdata.contractId,
            contractNo: $scope.module.perContract ? userService.system.contractsLookup[userService.system.userdata.contractId].contractNo : "",
            correspondenceOperations: userService.getOperationsFor("C"),
            options: {
                multiselect: false
            },
            rowOnClick: function (row) {
                $scope.saveItem(row);
            },
            rowClass: function (row) {
                if (row._is_draft) return "draftRow";
            },
            dataWrapper: 'data',
            leftActions: [
                {
                    setProperties: ACTIONS.CREATE,
                    // name: 'Create new',
                    conditionOnTop: function () {
                        if ($scope.initiator) return true;
                        else return false;
                    },
                    click: function (rows, screenId) {
                        $scope.saveItem(undefined, undefined, screenId);
                    }
                },
            ],
            actions: [
                // Top actions
                {
                    setProperties: ACTIONS.REFRESH,
                    click: function () {
                        $scope.manager.refresh();
                    }
                },
                // Row actions
                {
                    setProperties: ACTIONS.DELETE,
                    alwaysOnTop: false,
                    alwaysOnRow: true,
                    condition: function (row) {
                        return row._is_draft == true;
                    },
                    click: function (row) {
                        $scope.deleteItem(row.entity_instance_id);
                    }
                },
                {
                    setProperties: ACTIONS.DETAILS,
                    click: function (row) {
                        $scope.saveItem(row);
                    }
                },
                {
                    setProperties: ACTIONS.DOWNLOAD,
                    name: 'Download PDF',
                    alwaysOnRow: true,
                    click: function (row) {
                        $scope.manager.download(row);
                    },
                    condition: function () {
                        return $scope.canPrint;
                    }
                },
                {
                    setProperties: ACTIONS.EMAIL,
                    name: 'Email Contract',
                    alwaysOnRow: true,
                    alwaysOnTop: false,
                    click: function (row) {
                        $scope.manager.email($scope, row);
                    },
                    condition: function () {
                        return $scope.operations.Read && $scope.canPrint ? true : false;
                    }
                },
                {
                    setProperties: ACTIONS.DOCUMENTS,
                    name: "Print Contract",
                    alwaysOnRow: true,
                    alwaysOnTop: false,
                    click: function (row) {
                        $scope.manager.printDocuments(row);
                    },
                    condition: function () {
                        return $scope.canPrint;
                    }
                },
                {
                    setProperties: ACTIONS.ARCHIVE,
                    //name: 'Create Document Archive',
                    name: 'Create Archive',
                    alwaysOnRow: true,
                    alwaysOnTop: false,
                    click: function (row) {
                        $scope.createArchive(row);
                    },
                    condition: function () {
                        for (var i = 0; i < userService.system.modules[moduleCode].operations.length; i++) {
                            //check if the user is in a Role that has the permission to create/udate/delete archives
                            if (userService.system.modules[moduleCode].operations[i].name == 'Archive') {
                                return true;
                            }
                        }
                        return false;
                    }
                },
                {
                    setProperties: ACTIONS.ARCHIVE,
                    name: 'View Archives',
                    alwaysOnRow: true,
                    alwaysOnTop: false,
                    click: function (row) {
                        $scope.viewArchives(row);
                    },
                    condition: function () {
                        for (var i = 0; i < userService.system.modules[moduleCode].operations.length; i++) {
                            //check if the user is in a Role that has the permission to read archives
                            if (userService.system.modules[moduleCode].operations[i].name == 'Archive') {
                                return true;
                            }
                        }
                        return false;
                    }
                }
            ]
        });
    }
    
    var getGridColumns = function () {
        if ($scope.module) $scope.manager[columnsURL.method](columnsURL.toString(), { url: { workflowId: userService.system.workflowId }, urltype: 'obj' }, { headers: { moduleId: moduleId } })
            .then(function (result) {
                var currentFieldsLookup = {};
                var r = result.fields || [];
                $scope.initiator = result.userIsInitiator ? result.userIsInitiator : false;
                $scope.canPrint = result.canPrint;
                var columns = [];

            if (r && r.length) {
                var widthBits = 0;
                for (var i = 0; i < r.length; i++) {
                    if (!r[i].isFilter) {
                        r[i].width = r[i].width || 1;
                        widthBits += parseInt(r[i].width);
                    }
                }
                for (var i = 0; i < r.length; i++) {
                    if (!r[i].isSystem || r[i].name == "Name") {
                        // var dataSourceLookup = {};
                        //if (r[i].dataSourceValues) {
                        //    for (var j = 0; j < r[i].dataSourceValues.length; j++) {
                        //        var value = r[i].dataSourceValues[j]['value'] ? r[i].dataSourceValues[j]['value'] : "";
                        //        dataSourceLookup[r[i].dataSourceValues[j]['key'].toString()] = value;
                        //    }
                        //}
                        //set flags
                        if ((1 & r[i].priority) != 0) r[i].onPhone = true;
                        else r[i].onPhone = false;
                        if ((2 & r[i].priority) != 0) r[i].onTablet = true;
                        else r[i].onTablet = false;
                        //var type = function (typeId) {
                        //    if (typeId == 23) return 'money';
                        //    if (typeId == 16) return 'integer';
                        //    if (typeId == 2) return 'number';
                        //   // if (typeId == 12) return 'attachment';
                        //    return 'text';
                        //}
                        r[i].restrictionsLookup = {};
                        if ((r[i].restrictions || []).length) {
                            for (var j = 0; j < r[i].restrictions.length; j++) {
                                r[i].restrictionsLookup[r[i].restrictions[j].key] = r[i].restrictions[j].value || true;
                            }
                        }


                        var column = {
                            width: 100 / widthBits * r[i].width,
                            onPhone: r[i].onPhone,
                            onTablet: r[i].onTablet,
                            name: r[i].id > 0 ? r[i].id : r[i].name,
                            fieldName: r[i].name,
                            id: r[i].id,
                            replacementFor: r[i].id > 0 ? r[i].id : r[i].name,
                            label: r[i].label,
                            type: r[i].name == 'state_name' ? 'state' : 'checkbox',
                            typeId: r[i].typeId,
                            displayTypeId: r[i].displayTypeId,
                            dataSourceId: r[i].dataSourceId || null,
                            relationId: r[i].relationId || null,
                            fieldTypeId: r[i].fieldTypeId,
                            options: r[i].options || "",
                            restrictionsLookup: r[i].restrictionsLookup,
                            isPrimarySort: r[i].isPrimarySort,
                            isFilter: r[i].isFilter
                            // valueLookup: dataSourceLookup,
                            // dataSourceValues: r[i].dataSourceValues
                        }


                        columns.push(column);

                        currentFieldsLookup[column.replacementFor] = column;
                    }
                }
            }

            $scope.manager.set_Screens(result.startScreens);
            $scope.manager.set_Columns({
                1: columns,
                2: [
                    { name: "_contract_name", label: 'Contract Name', type: 'checkbox' },
                    { name: "_description", label: 'Desc', type: 'checkbox' },
                    { name: "_total_float", label: 'Total Float', type: 'checkbox' },
                    { name: "_budget", label: 'Budget', type: 'checkbox' },
                    { name: "_co_to_date", label: 'Budget', type: 'checkbox' },
                    { name: "_current_contract_value", label: 'Budget', type: 'checkbox' },
                    { name: "_contingency", label: 'Contingency', type: 'checkbox' },
                    { name: "_earned_to_date", label: 'Budget', type: 'checkbox' },
                    { name: "_plnd_design_start_date", label: 'Budget', type: 'checkbox' },
                    { name: "_state_name", label: 'Budget', type: 'checkbox' },
                    { name: "_plnd_design_end_date", label: 'Budget', type: 'checkbox' },
                    { name: "_plnd_constr_start_date", label: 'Budget', type: 'checkbox' },
                    { name: "_plnd_constr_end_date", label: 'Budget', type: 'checkbox' },
                    { name: "_contract_completion_date", label: 'Budget', type: 'checkbox' },
                    { name: "_additionalhardcoded", label: ' ', type: 'checkbox' },
                    { name: "_driving_activities", label: ' ', type: 'checkbox' },

            ]
            });

            setBottomFormulas();

            // bottom formulas
            if ((result.bottomFormulas || []).length) {
                for (var i = 0; i < result.bottomFormulas.length; i++) {
                    if (result.bottomFormulas[i].operator != 5) {
                        bottomFormulasLookup[result.bottomFormulas[i].operator].fields[result.bottomFormulas[i].fieldId] = result.bottomFormulas[i];
                        bottomFormulasLookup[result.bottomFormulas[i].operator].fieldIds.push(result.bottomFormulas[i].fieldId);
                    }

                }


                for (var index = 0; index < $scope.bottomFormulas.length; index++) {
                    (function (i) {
                        Object.defineProperty($scope.bottomFormulas[i], "visibleOnTablet", {
                            get: function () {
                                var visible = false;
                                for (var key in $scope.bottomFormulas[i].fields) {
                                    if ($scope.bottomFormulas[i].fields.hasOwnProperty(key))
                                        if ((currentFieldsLookup[key] || {}).onTablet) {
                                            visible = true;
                                            break;
                                        }
                                }
                                return visible;
                            }
                        })

                        Object.defineProperty($scope.bottomFormulas[i], "visibleOnPhone", {
                            get: function () {
                                var visible = false;
                                for (var key in $scope.bottomFormulas[i].fields) {
                                    if ($scope.bottomFormulas[i].fields.hasOwnProperty(key))
                                        if ((currentFieldsLookup[key] || {}).onPhone) {
                                            visible = true;
                                            break;
                                        }
                                }
                                return visible;
                            }
                        })

                    })(index);

                }

                $scope.manager.bottomFormulas = $scope.bottomFormulas;
            }
            else {
                $scope.manager.bottomFormulas = [];
            }
            $scope.manager.topFormulas = result.topFormulas;

            $scope.manager.loading = true;
            if (rememberManagerService.moduleId == $scope.module.moduleId) {
                $scope.manager = rememberManagerService.get();
                $scope.manager.loading = false;
            } else {
                $scope.manager.loadPage()
                    .finally(function () {
                        $scope.manager.loading = false;
                    });
                rememberManagerService.set($scope.manager, $scope.module.moduleId);
            }

            
        })
        .catch(function (e) {
            $scope.manager.loading = false;
        });
    }

    // Get workflow for the module
    userService.getWorkflow($scope.module.moduleId)
        .then(function() {
            getGridColumns();
        });

    $scope.$watch(
        function () {
            return userService.isReloadGrid;
        },
        function (n, o) {
            if (n) {
                $scope.manager.loading = true;
                getGridColumns();
                userService.isReloadGrid = false;

            }
        }
    );

    var confirm = function (title, text, ev) {
        return $mdDialog.confirm()
            .title(title)
            .textContent(text)
            .ariaLabel('Confirm Dialogue')
            .targetEvent(ev)
            .ok('Proceed')
            .cancel('Cancel');
    }

    $scope.deleteItem = function (id) {
        $mdDialog.show(confirm('Deleting Document(s)', 'Are you sure you want to delete the selected Document(s)?'))
            .then(function () {
                var deleteURL = typeof URI[moduleCode.toUpperCase()] != "undefined" ?
                    URI[moduleCode.toUpperCase()].DELETE : URI.MODULE.DELETE;

                // $scope.manager.loading = true;
                $scope.manager[deleteURL.method](deleteURL.toString(), { url: { moduleId: moduleId, entityInstanceId: id, contractId: userService.system.userdata.contractId }, urltype: 'obj' })
                    .then(function () {
                        $scope.manager.page = 1;
                        $scope.manager.loadPage();
                        Message.info('Document(s) deleted successfully');
                    })
                    .catch(function (e) {
                        if (e.data.ids) {
                            messageContent = $scope.processError(e.data.ids, "deleted");
                            var d = dialogs.notify("Deleting Instances", messageContent);
                        } else {
                            Message.dberror(e, $scope.manager.rows, 'id', 'name');
                        }

                        //$scope.manager.loading = false;
                        //Message.dberror(e);
                    })
                    .finally(function () {
                        //$scope.manager.loadPage();
                    })
            })
            .catch(function () {
                // cancel pressed
            });



    }

    $scope.saveItem = function (item, ev, screenId) {
        var item = (typeof item != 'undefined') ? item : {};
        item.isInitiator = $scope.initiator || false;
        $mdDialog.show({
            locals: { item: item, module: userService.system.modules[moduleCode], screenId: screenId },
            controller: 'editHContractController',
            templateUrl: '/ng/views/admin/modals/editHContract.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            escapeToClose: false,
            clickOutsideToClose: false,
            disableParentScroll: true,
            multiple: true
        })
        .then(function (result) {
            $scope.manager.page = 1;
            $scope.manager.loadPage();
            // userService.Contracts();

        }, function () {
            $scope.manager.loadPage();
        });
    };

    $scope.createArchive = function (item, ev) {
        var filter = {};
        var modulesCheckboxes = [];

        var selectedContract = userService.system.contracts.find(x => x.contractNo == item.contract_no);
        var cid = selectedContract.key;
        var cNo = selectedContract.contractNo;

        $http[URI.MODULES.SEARCH.method](URI.MODULES.SEARCH.toString(), filter)
            .then(function (result) {                                 
                if (result.data && result.data.data.length > 0) {
                    for (var i = 0; i < result.data.data.length; i++) {
                        if (result.data.data[i].perContract == true) {
                            var desc = '(' + result.data.data[i].abbreviation + ')';
                            if (!(result.data.data[i].projectFolderId != null)) {
                                desc = desc + ' - This module has no Destination Folder defined!';
                            }
                            modulesCheckboxes.push({
                                description: desc,
                                abbr: result.data.data[i].abbreviation,
                                key: result.data.data[i].id,
                                value: result.data.data[i].name,
                                label: result.data.data[i].name,
                                folderId: result.data.data[i].projectFolderId,
                                hasFolder: result.data.data[i].projectFolderId != null
                            });
                        }
                    }
                }
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })

        var dialogLocals = {
            data: {
                title: "Select the modules you would like to create an archive for:",
                description: " ",
                showSelectUnselect: true,
                buttonLabels: [
                    { key: 'confirm', value: 'Create Archive' },
                    { key: 'cancel', value: 'Cancel' },
                ],
                contractId: cid,
                contractNumber: cNo,
                checkboxes: modulesCheckboxes
            }
        }

        $mdDialog.show({
            templateUrl: '/ng/views/admin/modals/archiveModuleSelector.html',
            locals: dialogLocals,
            controller: 'archiveModuleSelectorController',
            parent: angular.element(document.body)
        })
            .then(function (result) {

            }, function () {

            });
    };

    $scope.viewArchives = function (item, ev) {
        //var tenantName = userService.system.tenantsLookup[userService.tenantId].name.toLowerCase();
        //var url = tenantName + "/filecontractmanager";
        //$location.url(url);

        //$state.go('tenant.filecontractmanager', { tenantIdentifier: $stateParams.tenantIdentifier }, {archive: true });
        $location.url($state.params.tenantIdentifier + '/filecontractmanager?archive=true');
    };

});
