import { cipo } from 'cipo';

cipo.directive("entitytabs", function ($timeout, userService) {
    return {
        restrict: "E",
        scope: {
            "entityinstance": "="
        },
        templateUrl: "/ng/views/directives/dynamics/entitytabs.html",
        controller: function ($scope, ACTIONS, userService, $document) {

            Object.defineProperty($scope, 'operations', {
                get: function () { return userService.getOperationsFor($scope.entityinstance.moduleCode) }
            });
            $scope.openMenu = function ($mdMenu, ev) {
                // originatorEv = ev;
                $mdMenu.open(ev);
            };

        }

    }
});
