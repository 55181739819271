import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ItvDateTimeCustomFormatPipe, ItvDateTimeWithRestrictionsPipe } from '../pipes/itv-date.pipe';
import { ItvNumberPipe, ItvNumberWithFormattingsPipe } from '../pipes/itv-number.pipe';

@NgModule({
  providers: [DecimalPipe, ItvNumberPipe, ItvDateTimeCustomFormatPipe, ItvDateTimeWithRestrictionsPipe, ItvDateTimeCustomFormatPipe],
  declarations: [ItvNumberPipe, ItvNumberWithFormattingsPipe, ItvDateTimeWithRestrictionsPipe, ItvDateTimeCustomFormatPipe],
  exports: [ItvNumberPipe, ItvNumberWithFormattingsPipe, ItvDateTimeWithRestrictionsPipe, ItvDateTimeCustomFormatPipe],
})
export class PipesModule {}
