import { cipo } from 'cipo';

cipo.factory('rememberManagerService', ['$http', 
    function ($http) {
        var rememberManagerService = {};

        rememberManagerService.entityInstanceId = 0;
        rememberManagerService.moduleId = 0;
        rememberManagerService.manager = {};

        rememberManagerService.get = function () {
            return rememberManagerService.manager;
        };

        rememberManagerService.set = function (manager, moduleId) {
            rememberManagerService.moduleId = moduleId;
            rememberManagerService.manager = manager;
        };
        
        return rememberManagerService;
    }]);
