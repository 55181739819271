import { cipo } from 'cipo';

cipo.controller(
  "mapsController",
  function (
    $scope,
    $state,
    $http,
    $uibModal,
    userService,
    rememberManagerService,
    Manager,
    dialogs,
    URI,
    ACTIONS,
    $mdDialog,
    Message,
    AggregateOperators
  ) {
    $scope.moduleName = userService.system.mNames[$state.current.code];
    $scope.module = userService.system.modules[$state.current.code];

    var moduleCode = $state.current.code;
    var moduleId = $scope.module.moduleId;

    if (!$scope.module) {
      $state.go("tenant.404", null, { location: false });
    }

    var dataURL =
      typeof URI[moduleCode.toUpperCase()] != "undefined"
        ? URI[moduleCode.toUpperCase()].GRID_SEARCH
        : URI.MODULE.GRID_SEARCH;

    $scope.manager = new Manager({
      objectsPerPage: 20,
      dataURL: dataURL,
      layout: 1,
      defaultColumns: 1,
      gridClass: "mapsGrid",
      urlParams: {
        contractId: userService.system.userdata.contractId,
        moduleId: moduleId,
      },
      moduleId: $scope.module.moduleId,
      moduleName: $scope.module.name,
      moduleCode: $scope.module.code,
      contractId: userService.system.userdata.contractId,
      contractNo: $scope.module.perContract
        ? userService.system.contractsLookup[
            userService.system.userdata.contractId
          ].contractNo
        : "",
      correspondenceOperations: userService.getOperationsFor("C"),
      options: {
        multiselect: false,
      },
      rowOnClick: function (row) {
        $scope.openMap(row);
      },
      rowClass: function (row) {
        if (row._is_draft) return "draftRow";
      },
      dataWrapper: "data",
      parseData: function (data, columns) {
        return data;
      },
      leftActions: [
        {
          setProperties: ACTIONS.CREATE,
          // name: 'Create new',
          conditionOnTop: function () {
            if ($scope.initiator) return true;
            else return false;
          },
          click: function () {
            $scope.openMap();
          },
        },
      ],
      actions: [
        // Top actions
        {
          setProperties: ACTIONS.REFRESH,
          click: function () {
            $scope.manager.refresh();
          },
        },
        // Row actions
        {
          setProperties: ACTIONS.DETAILS,
          click: function (row) {
            $scope.openMap(row);
          },
        },
      ],
    });

    $scope.manager.set_Columns([
      {
        name: "name",
        label: "Name",
        type: "text",
        onTablet: true,
        onPhone: true,
        width: 200,
        fieldTypeId: 1,
      },
    ]);

    var loadMaps = function () {
      $scope.manager.loading = true;
      if (rememberManagerService.moduleId == $scope.module.moduleId) {
        $scope.manager = rememberManagerService.get();
        $scope.manager.loading = false;
      } else {
        $scope.manager.loadPage().finally(function () {
          $scope.manager.loading = false;
        });
        rememberManagerService.set($scope.manager, $scope.module.moduleId);
      }
    };

    $scope.openMap = function (mapDef) {
      mapDef = typeof mapDef != "undefined" ? mapDef : {};
      mapDef.isInitiator = $scope.initiator || false;
      
      $mdDialog
        .show({
          locals: {
            mapDef: mapDef,
            module: userService.system.modules[moduleCode],
          },
          controller: "editMapController",
          templateUrl: "/ng/views/admin/modals/editMap.html",
          parent: angular.element(document.body),
          fullscreen: true,
          escapeToClose: false,
          clickOutsideToClose: false,
          disableParentScroll: true,
          multiple: true,
        })
        .then(
          function () {
            $scope.manager.page = 1;
            $scope.manager.loadPage();
          },
          function () {
            $scope.manager.loadPage();
          }
        );
    };

    $scope.$watch(
      function () {
        return userService.isReloadGrid;
      },
      function (n, o) {
        // console.error("userService.isReloadGrid", n, o);
        if (n) {
          $scope.manager.loading = true;
          loadMaps();
          userService.isReloadGrid = false;
        }
      }
    );

    loadMaps();
  }
);
