<table matSort (matSortChange)="sortData($event)" mat-table [dataSource]="dataSource"
  class="mat-elevation-z0 weather-table">
  <!-- Time Column -->
  <ng-container matColumnDef="timeInput">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="time">Time</th>
    <td mat-cell *matCellDef="let element" class="time-cell">
      <input type="time" matInput class="time-input" [class.disable-default-styles]="!this.fielddata._editMode"
        (change)="getWeatherDataByTime($event, element)" [ngModel]="element.time"
        [disabled]="!this.fielddata._editMode">
    </td>
  </ng-container>

  <!-- Temperature Column -->
  <ng-container matColumnDef="temperature">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="temperature">Temp.</th>
    <td mat-cell *matCellDef="let element">{{element.temperature}}</td>
  </ng-container>

  <!-- WindSpeed Column -->
  <ng-container matColumnDef="windSpeed">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="windSpeed">Wind Speed</th>
    <td mat-cell *matCellDef="let element">{{element.windSpeed}}</td>
  </ng-container>

  <!-- Humidity Column -->
  <ng-container matColumnDef="humidity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="humidity">Humidity</th>
    <td mat-cell *matCellDef="let element">{{element.humidity}}</td>
  </ng-container>

  <!-- Phrase Column -->
  <ng-container matColumnDef="phrase">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="phrase">Condition</th>
    <td mat-cell *matCellDef="let element">{{element.phrase}}</td>
  </ng-container>

  <!-- Remove Column -->

  <!-- Star Column -->
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let element" class="delete-button">
      <button mat-icon-button class="transparent-button" *ngIf="this.fielddata._editMode" (click)="deleteRow(element)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div class="add-row-container" *ngIf="this.fielddata._editMode">
  <button (click)="createTimeFieldInstance()" color="primary" mat-icon-button class="add-row-button">
    <span class="add-row-text"><mat-icon>add</mat-icon> Add row</span>
  </button>
</div>