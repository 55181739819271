import { cipo } from 'cipo';

cipo.factory('Menu', ['$q', '$location', '$window', 'ROUTES', 'ICONS', 'userService', 'localStorageService','$timeout',
    function ($q, $location, $window, ROUTES, ICONS, userService, localStorageService, $timeout) {
        var Menu = {};
        Menu.TYPE_FOLDER = 'Folder';
        if (!localStorageService.get('menu.expandedList'))
            localStorageService.set('menu.expandedList', []);
        Menu.expandedList = localStorageService.get('menu.expandedList');
        Menu.currentRoute = null;
        Menu.currentParams = null;
        Menu.full = null;
        Menu.toggle = false;

        if (typeof localStorageService.get('menu.show') == 'undefined')
            localStorageService.set('menu.show', true);
        Menu.show = localStorageService.get('menu.show');
        Menu.visibleMenu = Menu.show;
        Menu.isHideMenu = true;

        // Menu.show = true;

        Menu.init = function () {
            var p = $q.defer();

            Menu.unparsedMenu = userService.system.menu || [];
            Menu.items = [];
            Menu.referenced = [];
            Menu.selected = null;
            Menu.routes = ROUTES.byCode || [];
            Menu.mappedURLs = [];
            Menu.hasNonCollapsedElems = false;
            Menu.parseRequest();
            p.resolve();
            return p.promise;
        }

        Menu.searchItems = function (event) {
            var menu = this;
            event.stopPropagation();
            if (event.keyCode != 13) {
                if (menu.timeout) $timeout.cancel(menu.timeout);
                menu.timeout = $timeout(function () {
                    menu._filterItems();
                    $timeout.cancel(menu.timeout);
                }, 1000);
            } else {
                if (menu.timeout) $timeout.cancel(menu.timeout);
                menu._filterItems();
            }
        }

        Menu._filterItems = function () {
            var menu = this;
            if (menu._searchTerm) {
                for (var i = 0; i < menu.referenced.length; i++) {
                    // set visibility for non-folders
                    if (!(menu.referenced[i].children || []).length) {
                        menu.referenced[i].isShow = false;
                        menu.referenced[i].isContextuallyShown = false;
                        if (menu.referenced[i].name.toLowerCase().indexOf(menu._searchTerm.toLowerCase()) != -1
                            || (menu.referenced[i].code || "").toLowerCase().indexOf(menu._searchTerm.toLowerCase()) != -1) {
                            menu.referenced[i].isShow = true;
                            if (menu.referenced[i].parentId) {
                                if (!menu.isExpanded(menu.list[menu.referenced[i].parentId])) menu.toggleExpand(menu.list[menu.referenced[i].parentId]);
                            }
                        }
                    }
                    
                }
                // set visibility for folders and make children visible if parent is visible
                for (var i = 0; i < menu.referenced.length; i++) {
                    if ((menu.referenced[i].children || []).length) {
                        menu.referenced[i].isShow = false;
                        if (menu.referenced[i].hasVisibleChildren) {
                            menu.referenced[i].isShow = true;
                        } else if (menu.referenced[i].name.toLowerCase().indexOf(menu._searchTerm.toLowerCase()) != -1 && !menu.referenced[i].hasVisibleChildren) {
                            menu.referenced[i].isShow = true;
                            for (var j = 0; j < menu.referenced[i].children.length; j++) {
                                menu.referenced[i].children[j].isContextuallyShown = true;
                                menu.referenced[i].children[j].isShow = true;
                            }
                        } 
                        
                        if (!menu.referenced[i].hasVisibleChildren && menu.isExpanded(menu.referenced[i])) menu.toggleExpand(menu.referenced[i]);
                    }
                }
            }
            else {
                for (var i = 0; i < menu.referenced.length; i++) {
                    menu.referenced[i].isShow = true;
                    menu.referenced[i].isContextuallyShown = false;
                }
                localStorageService.set('menu.expandedList', []);
                menu.expandedList = localStorageService.get('menu.expandedList');

                if ((menu.selectedItem || {}).parentId) menu.toggleExpand(menu.list[menu.selectedItem.parentId]);
            }
        }

        Menu.refresh = function () {
            Menu.init();
            Menu.highlight(Menu.currentRoute);
        }

        Menu.triggerRefresh = function () {
            var p = $q.defer();

            userService.triggerMenuRefresh()
                .then(function () {
                    Menu.refresh();
                    p.resolve();
                })
                .catch(function (e) {
                    p.reject(e);
                })

            return p.promise;
        }

        Menu.parseRequest = function () {
            var referenced = [];

            var list = Menu.convertTreeToList().referenced;
            Menu.moduleMapping = Menu.convertTreeToList().moduleMapping;
            for (var item in list) {
                if (list.hasOwnProperty(item)) {
                    var tenantIdentifier = userService.getTenantIdentifierById(userService.tenantId);
                    var parentId = (typeof list[item].parentId != 'undefined') ? list[item].parentId : 0;
                    list[item].selected = false;
                    list[item].miniExpanded = false;
                    list[item].parent = (list[parentId]) ? list[parentId] : null;
                    list[item].icon = userService.system.iconsLookup[list[item].iconId] ?
                        userService.system.iconsLookup[list[item].iconId].class : 'mdi mdi-square-outline';
                    list[item].systemName = (Menu.routes[list[item].code]) ? Menu.routes[list[item].code].name : '[none]';

                    if (Menu.routes[list[item].code]) {
                        list[item].route = (Menu.routes[list[item].code]) ? tenantIdentifier + Menu.routes[list[item].code].route : '/e404';
                    }

                    if (!Menu.routes[list[item].code]) {
                        if (list[item].name == "google_ella"){}
                        if (list[item].typeId == 4) list[item].route = list[item].url;
                        else if (list[item].moduleId) list[item].route = tenantIdentifier + '/dm/'+ (list[item].perContract ? '{contractId}/' : '0/') + list[item].moduleId;
                    }
                    list[item].isShow = true;

                    var route = (list[item].route || "").indexOf("?") != -1 ? (list[item].route || "").substring(0, (list[item].route || "").indexOf("?")) : (list[item].route || "");

                    if (route == $location.path().substring(1)) Menu.selectedItem = list[item];

                    referenced.push(list[item]);
                }
            }
            Menu.referenced = referenced;
            Menu.items = Menu.unparsedMenu;
            Menu.list = list;


            for (var i = 0; i < Menu.items.length; i++) {
                if ((Menu.items[i].children || []).length && typeof Menu.items[i].hasVisibleChildren == 'undefined') {
                    (function (i) {
                        Object.defineProperty(Menu.items[i], 'hasVisibleChildren', {
                            get: function () {
                                var hasVisibleChildren = false;
                                for (var j = 0; j < Menu.items[i].children.length; j++) {
                                    if (Menu.items[i].children[j].isShow) {
                                        hasVisibleChildren = true;
                                        break;
                                    }
                                }
                                return hasVisibleChildren;
                            }
                        });

                    })(i);


                    
                }
            }
            

            return;
        }

        Menu.convertTreeToList = function () {
            var stack = [], referenced = {}, moduleMapping = {};
            stack.push({ id: 0, children: Menu.unparsedMenu });

            while (stack.length !== 0) {
                var node = stack.pop();
                if (node.children && node.children.length) {
                    for (var i = node.children.length - 1; i >= 0; i--) {
                        stack.push(node.children[i]);
                    }
                }

                if (!referenced[node.id] && node.id) {
                    referenced[node.id] = node;
                }
                if (node.moduleId) moduleMapping[node.moduleId] = node;
            }

            return {
                referenced: referenced, moduleMapping: moduleMapping
            };
        }

        Menu.clearSelection = function () {
            if (Menu.referenced.length) {
                for (var i = 0; i < Menu.referenced.length; i++) {
                    Menu.referenced[i].selected = false;
                    Menu.referenced[i].expanded = false;
                }
            }
        }

        // collapse all but active and pand parent
        Menu.collapseElems = function () {
            Menu.expandedList = [];
            localStorageService.set('menu.expandedList', Menu.expandedList);
        }

        Menu.collapseMiniElems = function (elem) {
            var elemParentId = -1;
            var elem = elem || { id: 0, parent: null, typeName: null }
            if (elem.parent != null) elemParentId = elem.parent.id;

            if (Menu.referenced.length) {
                for (var i = 0; i < Menu.referenced.length; i++) {
                    if ((Menu.referenced[i].id != elemParentId &&
                        Menu.referenced[i].id != elem.id) ||
                        elem.typeName != Menu.TYPE_FOLDER) {

                        Menu.referenced[i].miniExpanded = false;
                    }
                }
            }
        }

        Menu.clearSearchTerm = function (keepSearch) {
            Menu.focusSearch = false;
            if (!keepSearch) Menu.isMenuSearch = false;
            else {
                
                $timeout(function () {
                    Menu.focusSearch = true;
                }, 500);
            }
            Menu._searchTerm = "";
            Menu._filterItems();
        }

        Menu.expandParents = function (elem) {
            if (elem.parent) {
                if (!Menu.isExpanded(elem.parent)) Menu.toggleExpand(elem.parent);
                if (Menu.list[elem.parent.id].parent) Menu.expandParents(elem.parent);
            }
        }

        Menu.select = function (elem, contractId) {
            // if selected item is an element, go to the route of that element if any
            if (elem.typeId != 1) {
                // menu item selection comes from outside of menu
                Menu.expandParents(elem);

                Menu.selectedItem = elem;
                var elemRoute = elem.route.toString().replace('{contractId}', contractId || 0);

                if (elem.typeId == 4) {
                    var url = (elem.route.startsWith('http') ? '' : 'http://') + elem.route;
                    $window.open(url, elem.name);
                }
                else if (elem.moduleId) {
                    $window.scrollTo(0, 0);
                    if (!Menu.full) Menu.toggle = !Menu.toggle;
                    var qmark = elemRoute.indexOf('?'); 
                    $location.url(qmark === -1 ? elemRoute : elemRoute.substring(0, qmark));
                    
                }
            } else {
                if (Menu.full) {
                    Menu.toggleExpand(elem);
                } else {
                    elem.miniExpanded = !elem.miniExpanded;
                    Menu.toggleExpand(elem);
                }
            }

            Menu.collapseMiniElems(elem);

            if (Menu._searchTerm && elem.typeId != 1) {
                Menu.clearSearchTerm();
            }


            return;
        }

        Menu.highlight = function (route, params) {
            try {
                // get selected element, cleanup everything but that.
                var selectedRoute = null;
                var pathIds = [];
                var route = route || null;
                var params = params || {};

                // clear previous selection
                if (Menu.referenced) {
                    for (var i = 0; i < Menu.referenced.length; i++) {
                        Menu.referenced[i].selected = false;
                    }
                }

                if (route) {
                    var dynId = (typeof params.id != 'undefined' && route.code == 'DYN-MOD') ? params.id : -1;

                    // search for route name in list
                    for (var i = 0; i < Menu.referenced.length; i++) {
                        // TODO: dynamic modules, get id from url?
                        if (route.code == 'DYN-MOD' && Menu.referenced[i].moduleId == dynId) {
                            selectedRoute = Menu.referenced[i];
                            break;
                        } else
                            if (route.name == Menu.referenced[i].systemName) {
                                selectedRoute = Menu.referenced[i];
                            break;
                        }
                    }

                    if (selectedRoute) {
                        // highlight selected
                        selectedRoute.selected = true;

                        // highlight parents if any
                        if (selectedRoute.parent) {
                            selectedRoute.parent.selected = true;
                            pathIds.push(selectedRoute.parent.id);
                            if (selectedRoute.parent.parent) {
                                selectedRoute.parent.parent.selected = true;
                                pathIds.push(selectedRoute.parent.parent.id);
                            }
                        }

                        // expand parents if not expanded for selected route
                        if (pathIds.length) {
                            for (var i = 0; i < pathIds.length; i++) {
                                if (Menu.expandedList.indexOf(pathIds[i]) == -1)
                                    Menu.expandedList.push(pathIds[i]);
                            }
                        }

                        localStorageService.set('menu.expandedList', Menu.expandedList);
                    }
                }
            } catch (e) { };
        }

        Menu.toggleExpand = function(node) {
            var index = this.expandedList.indexOf(node.id);
            if (index == -1) {
                Menu.expandedList.push(node.id);
            } else {
                Menu.expandedList.splice(index, 1);
            }
            localStorageService.set('menu.expandedList', Menu.expandedList);

            return;
        }

        Menu.isExpanded = function (node) {
            return Menu.expandedList.indexOf(node.id) != -1;
        }

        Menu.itemExists = function (moduleId, items) {

            var _items = items || Menu.items;

            if (_items && _items.length > 0) {
                for (var i = 0; i < _items.length; i++) {
                    if (_items[i].moduleId == moduleId)
                        return true;

                    if (_items[i].children && _items[i].children.length > 0) {

                        // Get if project files is on children
                        var childrenExists = Menu.itemExists(moduleId, _items[i].children);
                        // In case is true, then return that we found it, otherwise just move forward
                        if (childrenExists)
                            return childrenExists;
                    }
                }
            }

            return false;
        }

        return Menu;
    }]);
