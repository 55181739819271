import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("Email", function ($q, $filter, Model, Upload, $timeout, URI, Form, Dictionaries, userService, fileService, Message) {
    var Email = Model.extend(function (email, moduleId) {
        var self = this;
        var obj = email || {};
        this.hasDraft = obj.hasDraft || false;
        this.draft = obj.draft || [];
        this.files = [];
        self.loading = true;
        self.moduleId = moduleId || 0;
        this.properties = {
            id: obj.id || 0,
            contractId: obj.contractId || userService.system.userdata.contractId,
            conversationId: obj.conversationId ? obj.conversationId : 0,
            contract: obj.contractsList ? obj.contractsList : null,
            // typeList: obj.typeList ? obj.typeList : null,
            content: obj.content || "",
            deleted: obj.deleted || false,
            important: obj.important || false,
            read: obj.read || true,
            parentId: obj.properties && obj.properties.parentId ? obj.properties.parentId : 0,
            userId: obj.userId || 0,
            attachments: obj.attachments || [],
            sendBodyAttached: obj.sendBodyAttached || false,
            sendAttachments: obj.sendAttachments || true,
            folder: obj.folder ? {
                id: obj.folder.id || 0,
                name: obj.folder.name || ""
            } : {
                    id: 0,
                    name: ""
                },
            cc: obj.cc || [],
            isDraft: email ? obj.isDraft ? obj.isDraft : false : true,
            preview: obj.preview || "",
            sender: obj.sender ? {
                id: obj.sender.id || null,
                firstName: obj.sender.firstName || "",
                lastName: obj.sender.lastName || "",
                name: obj.sender.name || "",
                isContact: obj.sender.isContact || false,
                email: obj.sender.email || ""
            } : {
                    id: 0,
                    firstName: "",
                    lastName: "",
                    name: "",
                    isContact: false,
                    email: ""
                },
            sent: obj.sent || null,
            subject: email && !obj.isDraft ? obj.subject : email && obj.isDraft && obj.subject != '(no subject)' ? obj.subject : "",
            to: obj.to || [],
            templateId: obj.templateId || null,
            tags: obj.tags || []
        };
        self.to = self.properties.to;
        self.cc = self.properties.cc;

        if (self.properties.isDraft) {
            self.recipientsArray = [];
            self.contractsList = userService.system.contracts;
            self.typeList = [];
        }
        //self.loading = false;

        self.loadingEmpty = true;

        self.getDataModel = function (data) {
            var data = data || self.properties;

            // The CorrespondenceViewModel class in c# only needs these properties, no others.
            // I did not remove any properties from self.properties because that will break the front-end.
            // This method may need to be used whenever we send/create/update emails. 
            var model = {
                id: data.id,
                contractId: data.contractId,
                conversationId: data.conversationId,
                templateId: data.templateId,
                parentId: data.parentId,
                to: data.to,
                cc: data.cc,
                sender: data.sender,
                content: data.content,
                subject: data.subject,
                preview: data.preview,
                conversationCount: data.conversationCount,
                draftCount: data.draftCount,
                requestedFolderId: data.requestedFolderId || 0,
                isConvo: data.isConvo || false,
                listPartyDisplayString: data.listPartyDisplayString || '',
                partyDisplayString: data.partyDisplayString || '',
                sent: data.sent || null,
                isDraft: data.isDraft || false,
                folder: data.folder || { id: 0, name: '' },
                hasAttachments: data.hasAttachments || false,
                folderName: data.folderName || '',
                userId: data.userId || 0,
                important: data.important || false,
                deleted: data.deleted || false,
                read: data.read || false,
                attachments: data.attachments || [],
                createdByName: data.createdByName || '',
                createdOn: data.createdOn || new Date(),
                sendBodyAttached: data.sendBodyAttached || false,
                sendAttachments: data.sendAttachments || true
            };

            if (model.attachments.length) {
                for (var i = 0; i < model.attachments.length; i++) {
                    model.attachments[i].createdOn = moment(model.attachments[i].createdOn).toISOString();
                    model.attachments[i].updatedOn = moment(model.attachments[i].updatedOn).toISOString();
                }
            }

            return model;
        }
    });

    Email.prototype.get_contractNo = function () {
        var contract = (this.contractsList || []).find(c => c.key === this.properties.contractId);
        return contract ? contract.contractNo : undefined;
    }

    Email.prototype.addContractNoToSubject = function () {
        if (this.properties.id) {
            // only applies to new emails
            return;
        }

        var contractNo = this.get_contractNo();
        if (contractNo) {
            this.properties.subject = this.properties.subject || `[${contractNo}]` || '';
        }
    }

    Email.prototype.set_selectsData = function (recipientsArray, typeList) {
        var self = this;
        // var recipientsTemp = [];
        if (recipientsArray) self.recipientsArray = recipientsArray;
        // if (contractsList) self.contractsList = contractsList;
        if (typeList) self.typeList = typeList;

        // var lookupRecipients = self.methods.create_lookup(recipientsArray, 'id', {});
        var lookupRecipients = recipientsArray;
        self.lookupTemplates = self.methods.create_lookup(typeList, 'key', 'description');

        self.properties.to = [];
        self.properties.cc = [];


        var setErrMsg = function () {
            self.errMessage = "One or more users are not available in the system anymore.";
            $timeout(function () {
                self.errMessage = "";
            }, 4000);
        }

        if (recipientsArray.length) {
            self.properties.to = self.methods.process_recipients(self.to, lookupRecipients);
            self.properties.cc = self.methods.process_recipients(self.cc, lookupRecipients);
        }
        if (self.properties.to.length != self.to.length || self.properties.cc.length != self.cc.length) {
            setErrMsg();
        }
        // self.to = self.methods.create_idsArr(self.properties.to);
        // self.cc = self.methods.create_idsArr(self.properties.cc);
        self.to = self.properties.to;
        self.cc = self.properties.cc;

        if (self.contractsList.length == 1) {
            if (!self.properties.contractId) self.properties.contractId = self.contractsList[0].key;
        }

        self.form = new Form(self.properties);

        self.form.initializing = true;

        var formDescriptionObject, formTemplateObject;


        if ((!self.properties.id && self.properties.conversationId == 0) || self.properties.id == self.properties.conversationId) {
            formDescriptionObject = {
                // contractId: { label: 'Select Contract', type: 'select', options: self.contractsList, validation: { required: true } },
                templateId: { label: 'Template', type: 'select', options: self.typeList },
                to: {
                    label: 'To', type: 'contact', options: self.recipientsArray, validation: { required: true }, onKeyUp: function (ev, data) { self.formOnKeyUp(ev, data) }
                },
                cc: {
                    label: 'CC', type: 'contact', options: self.recipientsArray, onKeyUp: function (ev, data) { self.formOnKeyUp(ev, data) }
                },
                subject: { label: 'Subject', type: 'text' },
                content: {
                    label: "", type: 'editor', options: {
                        //toolbarSticky: true,
                        //toolbarStickyOffset: 100,
                        //toolbarBottom: true,
                        scaytAutoload: false
                    }
                },
                sendBodyAttached: { label: "Send body as PDF", type: 'checkbox' },
                sendAttachments: { label: "Send attachment(s)", type: 'checkbox' },
                attachments: { label: "", type: 'attachment' }
            }
            formTemplateObject = [
                // { contractId: 50, templateId:50 },
                { templateId: 100 },
                { to: 100 },
                { cc: 100 },
                { subject: 100 },
                { content: 100 },
                { sendBodyAttached: 50, sendAttachments: 50 },
                { attachments: 100 }
            ];
        } else {
            formDescriptionObject = {
                templateId: { label: 'Template', type: 'select', options: self.typeList },
                to: {
                    label: 'To', type: 'contact', options: self.recipientsArray, validation: { required: true }, onKeyUp: function (ev, data) { self.formOnKeyUp(ev, data); }
                },
                cc: {
                    label: 'CC', type: 'contact', options: self.recipientsArray, onKeyUp: function (ev, data) { self.formOnKeyUp(ev, data); }
                },
                subject: { label: 'Subject', type: 'text' },
                content: { label: "", type: 'editor' },
                sendBodyAttached: { label: "Send body as PDF", type: 'checkbox' },
                sendAttachments: { label: "Send attachment(s)", type: 'checkbox' },
                attachments: { label: "", type: 'attachment' }
            };

            formTemplateObject = [
                { templateId: 100 },
                { to: 100 },
                { cc: 100 },
                { subject: 100 },
                { content: 100 },
                { sendBodyAttached: 50, sendAttachments: 50 },
                { attachments: 100 }
            ];

            // Displays the contract dropdown in the form if it's a fresh draft. Otherwise, we don't display it.
            // if (self.properties.parentId == 0 ) {
            //     formDescriptionObject.contractId = { label: 'Select Contract', type: 'select', options: self.contractsList, validation: { required: true } };
            //     formTemplateObject.unshift({ contractId: 50, templateId: 50 });
            //     formTemplateObject.unshift({ contractId: 50, templateId: 50 });
            // } else {
            //     formTemplateObject.unshift({ templateId: 100 });
            // }
        }

        self.addContractNoToSubject();
        self.form.set_Description(formDescriptionObject);
        self.form.setTemplate('grid', formTemplateObject);
        self.form.initializing = false;
        self.form.set_Data(self.properties);
        self.loading = false;
    };

    Email.prototype.methods = {
        process_recipients: function (selectedRecipients, latestRecipients) {
            var recipientsArray = [];
            if (selectedRecipients.length) {
                for (var i = 0; i < selectedRecipients.length; i++) {
                    var matchingRecipient = latestRecipients.find(x => {
                        return x.id == selectedRecipients[i].id &&
                            x.isContact == selectedRecipients[i].isContact;
                    });
                    if (typeof matchingRecipient != 'undefined') {
                        recipientsArray.push(matchingRecipient);
                    }
                }
            }
            return recipientsArray;
        },
        create_lookup: function (arr, key, value) {
            var lookup = {};
            var key = key || 'key';
            var value = value || 'value';
            if (arr && arr.length)
                for (var i = 0; i < arr.length; i++) {
                    if (typeof value == 'string') lookup[arr[i][key]] = arr[i][value];
                    else lookup[arr[i][key]] = arr[i];
                }
            return lookup;
        },
        create_idsArr: function (arr, key) {
            var idsArr = [];
            var key = key || 'id';
            if (arr && arr.length)
                for (var i = 0; i < arr.length; i++) {
                    idsArr.push(arr[i][key]);
                }
            return idsArr;
        }
    }

    Email.prototype.reload_Recipients = function (contractId, moduleId) {
        var self = this;
        self.to = self.properties.to;
        self.cc = self.properties.cc;
        // Get all current recipients
        Dictionaries.RecipientsList({ contractId: contractId, moduleId: moduleId })
            .then(function (r) {
                var lookupRecipients = r;
                if (r.length) {
                    self.properties.to = self.methods.process_recipients(self.to, lookupRecipients);
                    self.properties.cc = self.methods.process_recipients(self.cc, lookupRecipients);
                }

                self.form.set_Description({
                    to: {
                        label: 'To', type: 'contact', options: r, validation: { required: true }, onKeyUp: function (ev, data) { self.formOnKeyUp(ev, data); }
                    },
                    cc: {
                        label: 'CC', type: 'contact', options: r, onKeyUp: function (ev, data) { self.formOnKeyUp(ev, data); }
                    }
                }, true);

                self.form.set_Data(self.properties);

                // self.to = self.methods.create_idsArr(self.properties.to);
                // self.cc = self.methods.create_idsArr(self.properties.cc);
                self.to = self.properties.to;
                self.cc = self.properties.cc;

            })
            .catch(function (e) { console.error(e) })
    }

    Email.prototype.validateEmail = function (email) {
        var EMAIL_REGEXP = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
        return EMAIL_REGEXP.test(email);
    }

    // Key up correspondence
    Email.prototype.formOnKeyUp = function (e, _fv) {
        var self = this;

        // Check if we pressed enter and _value is declared and we have a searchValue
        // For contact field, in _value we have all the contacts selected that exists in options, but what you search and don't exists, will be in searchValue
        if (e && e.key && e.keyCode == 13 && _fv && _fv.searchValue && _fv._value && Object.prototype.toString.call(_fv._value) == '[object Array]') {

            // Now we need to check if searchValue is an email and check if not already exists in value or in options, it should not
            if (self.validateEmail(_fv.searchValue)
                && _fv._value.filter(function (contact) { return contact.email == _fv.searchValue }).length == 0
                && _fv.options && Object.prototype.toString.call(_fv.options) == '[object Array]' && _fv.options.filter(function (contact) { return contact.email == _fv.searchValue }).length == 0) {

                // Email is not in value and in options, then we need to add it
                self.createContact(e, _fv.searchValue, _fv)
            }
        }
    }

    Email.prototype.createContact = function (e, email, data) {
        var self = this;
        var p = $q.defer();

        var contact = {
            email: email,
            isPrimaryContact: false
        };

        if (data && email) {
            var dataURL = URI.CONTACT.CREATE_CONTACT;
            Model[dataURL.method](dataURL, { url: {}, urltype: 'obj', body: contact })
                .then(function (r) {
                    
                    // Add new recipients
                    self.recipientsArray.push(r);
                    // Reload to and cc dictionaries
                    var lookupRecipients = self.recipientsArray;
                    if (lookupRecipients.length) {
                        self.properties.to = self.methods.process_recipients(self.to, lookupRecipients);
                        self.properties.cc = self.methods.process_recipients(self.cc, lookupRecipients);
                    }
                    
                    self.form.set_Description({
                        to: {
                            label: 'To', type: 'contact', options: lookupRecipients, validation: { required: true }, onKeyUp: function (ev, data) { self.formOnKeyUp(ev, data); }
                        },
                        cc: {
                            label: 'CC', type: 'contact', options: lookupRecipients, onKeyUp: function (ev, data) { self.formOnKeyUp(ev, data); }
                        }
                    }, true);

                        self.form.set_Data(self.properties);

                        // Set searchValue to empty string
                        if (self.form.fieldsList && self.form.fieldsList['to']) {
                            self.form.fieldsList['to'].searchValue = '';
                        }
                        if (self.form.fieldsList && self.form.fieldsList['cc']) {
                            self.form.fieldsList['cc'].searchValue = '';
                        }
                        
                        self.to = self.properties.to;
                        self.cc = self.properties.cc;

                    // Add new contact in value
                    data._value.push(r);
                    // Remove search value
                    $(e.target).val('');

                    Message.info('Contact created successfully.');

                    p.resolve(r);
                })
                .catch(function (e) {
                    p.reject(e);
                })
                .finally(function () {

                });
        }

        return p.promise;
    }

    // send conversation re/fw w/o saving
    Email.prototype.set_Template = function (templateId, contractId) {
        var self = this;
        var p = $q.defer();

        if (templateId) {
            var url = URI.TEMPLATES.PREVIEW;
            var urlString = `${url}?id=${templateId}&contractId=${contractId ? contractId : null}`;

            self[url.method](urlString, self.properties)
                .then(function (result) {
                    self.properties.content = result.content;
                    self.properties.subject = self.lookupTemplates[templateId];
                    p.resolve();
                }).catch(function (e) {
                    p.reject();
                });
        } else {
            self.properties.content = "";
            p.resolve();
        }

        return p.promise;
    };

    // send conversation re/fw w/o saving
    Email.prototype.send_conversation_email = function () {
        var self = this;
        var p = $q.defer();
        //if (self.to.length) {
        self.set_final_recipients(true);
        self.setContent();
        self.properties.parentId = typeof (self.properties.parentId) != 'number'
            ? 0
            : self.properties.parentId;

        var data = self.getDataModel(self.properties);

        self[URI.EMAIL.SEND.method](`${URI.EMAIL.SEND}?moduleId=${self.moduleId}`, data).then(function (result) {

                p.resolve(result);
            }).catch(function (e) {
                console.error(e);
                p.reject(e);
            });
            return p.promise;
            //} 
        };

    //send saved draft
    Email.prototype.send_draft = function () {
        var self = this;
        var p = $q.defer();
        self.set_final_recipients(true);
        self.setContent();
        self.properties.parentId = typeof (self.properties.parentId) != 'number'
            ? 0
            : self.properties.parentId;

        var data = self.getDataModel(self.properties);

            self[URI.EMAIL.SEND.method](`${URI.EMAIL.SEND}?moduleId=${self.moduleId}`, data)
                .then(function (result) {
                    p.resolve(true);
                }).catch(function (e) {
                    console.error(e);
                    p.reject(e);
                });
            return p.promise;
        };

    // send unsaved single draft
    Email.prototype.send_orphan = function () {
        var self = this;
        var p = $q.defer();
        self.set_final_recipients(true);
        self.setContent();
        self.properties.parentId = typeof (self.properties.parentId) != 'number'
            ? 0
            : self.properties.parentId;

        var data = self.getDataModel(self.properties);

            self[URI.EMAIL.SEND.method](`${URI.EMAIL.SEND}?moduleId=${self.moduleId}`, data).then(function (result) {
                p.resolve(true);
            }).catch(function (e) {
                console.error(e);
                p.reject(e);
            });
            return p.promise;
        };

    Email.prototype.save_draft = function (isCloseOrSend = false) {
        var self = this;
        var p = $q.defer();
        // Save existing
        if (self.properties.id != null && self.properties.id > 0) {
            self.save_existing_draft(isCloseOrSend)
                .then(function (res) {
                    p.resolve(true);
                });
            // Save new
        } else {
            self.save_new_draft()
                .then(function (res) {
                    p.resolve(true);
                });
        }

        return p.promise;
    }

    // save new draft
    Email.prototype.save_new_draft = function () {
        var self = this;
        var p = $q.defer();
        self.set_final_recipients();
        self.saving = true;
        self.properties.id = 0;
        self.properties.parentId = typeof (self.properties.parentId) != 'number'
            ? 0
            : self.properties.parentId;

        var data = self.getDataModel(self.properties);

        self[URI.EMAIL.SAVE_NEW_DRAFT.method](`${URI.EMAIL.SAVE_NEW_DRAFT}?moduleId=${self.moduleId}`, data)
            .then(function (result) {
                self.properties.id = result;
                p.resolve(result);
            }).catch(function (e) {
                p.resolve(false);
            })
            .finally(function () {
                self.saving = false;
            });

        return p.promise;
    };

    // save existing draft
    Email.prototype.save_existing_draft = function (isCloseOrSend) {
        var self = this;
        var p = $q.defer();
        self.saving = true;
        self.set_final_recipients(isCloseOrSend);
        if (typeof self.properties.contractId == "undefined") {
            self.properties.contractId = "";
        }

        var data = self.getDataModel(self.properties);

        self[URI.EMAIL.SAVE_EXISTING_DRAFT.method](`${URI.EMAIL.SAVE_EXISTING_DRAFT}?contractId=${self.properties.contractId}&moduleId=${self.moduleId}`, data).then(function (result) {
            p.resolve(true);
        }).catch(function (e) {
            p.resolve(false);
        }).finally(function () {
            self.saving = false;
        });

        return p.promise;
    };

    // discard draft
    Email.prototype.discard_draft = function () {
        var self = this;
        var p = $q.defer();
        var data = {
            header: { "Content-type": "application/json; charset=utf-8" },
            body: [self.properties.id]
        };
        self[URI.EMAIL.DISCARD_DRAFTS.method](`${URI.EMAIL.DISCARD_DRAFTS}?isCorrespondenceIds=true&contractId=${self.properties.contractId}&moduleId=${self.moduleId}`, data)
            .then(function () {
                p.resolve(true);
            }).catch(function (e) {
                p.resolve(false);
            });

        return p.promise;
    };

    // process recipients before sending email
    Email.prototype.set_final_recipients = function (isCloseOrSend) {
        var self = this;
        if (isCloseOrSend) {
            if (!self.properties.subject || self.properties.subject == "")
                self.properties.subject = "(no subject)";
        }
    };

    Email.prototype.setContent = function () {
        var self = this;
        // We do this so sendgrid will send the email even if there's no content in the body
        if (!self.properties.content || self.properties.content.length <= 0) {
            self.properties.content = "(no content)";
        }
    }

    Email.prototype.set_sender = function (sender) {
        var self = this;
        self.sender = {
            id: sender.id,
            firstName: sender.firstName,
            lastName: sender.lastName,
            name: sender.displayName,
            email: sender.email,
            isContact: false
        }
    }

    // populate draft from parent email
    Email.prototype.set_draft_info = function (obj) {
        var self = this;
        // The new id of the draft will be 0 until it's saved.
        self.properties.id = 0;
        // The parent id will be the obj's id (this is the email we're replying to)
        self.properties.parentId = obj.properties.id || 0;
        self.properties.attachments = [];
        self.properties.conversationId = obj.properties.conversationId || 0;
        self.properties.isDraft = true;
        self.properties.to = [];
        self.properties.cc = [];
        var sentOn = $filter('date')(obj.properties.sent, 'EEE, MMM dd, y,');
        sentOn += " at ";
        sentOn += $filter('date')(obj.properties.sent, 'h:mm a');
        self.properties.content = " <br/> <br/>On " + sentOn
            + ", " + obj.properties.sender.firstName + " " + obj.properties.sender.lastName
            + " wrote: <blockquote>" + obj.properties.content + "</blockquote>";
        self.properties.templateId = null;
        self.properties.contractId = obj.properties ? obj.properties.contractId : userService.system.userdata.contractId;
        self.properties.subject = obj.properties.subject;
    };

    // process recipients for reply
    Email.prototype.set_reply_recipients = function (obj) {
        var self = this;
        self.to = [];
        self.cc = [];
        // if (obj.properties.userId != obj.properties.sender.id) {
        //     //"we keep the sender"
        //     self.to = [obj.properties.sender.id];
        // } else {
        //     self.to = [obj.properties.to[0].id];
        //     //"we swap the sender"
        // }
        // Reply button should always want to send a reply to the sender
        self.to = [obj.properties.sender];
    };

    // process recipients for reply all
    Email.prototype.set_all_recipients = function (obj) {
        var self = this;
        var currentSenderId = userService.system.userdata.id;
        var originalSenderId = obj.properties.sender.id;
        self.to = obj.properties.to.length
            ? Array.isArray(obj.properties.to) ? obj.properties.to : [obj.properties.to]
            : [];
        self.cc = obj.properties.cc.length
            ? Array.isArray(obj.properties.cc) ? obj.properties.cc : [obj.properties.cc]
            : [];

        // Remove current sender from recipients and add original sender to recipients
        if (currentSenderId != originalSenderId) {
            self.to = $.grep(self.to, function (e) { return e.id != currentSenderId; });
            self.cc = $.grep(self.cc, function (e) { return e.id != currentSenderId; });
            var isOriginalSenderInTos = $.grep(self.to, function (e) { return e.id == obj.properties.sender.id; }).length > 0;
            var isOriginalSenderInCcs = $.grep(self.cc, function (e) { return e.id == obj.properties.sender.id; }).length > 0;

            // If original sender did NOT email themselves, add them to the recipients
            if (!isOriginalSenderInTos && !isOriginalSenderInCcs) {
                self.to.unshift(obj.properties.sender);
            }
        }
    };

    // process recipients for fwd
    Email.prototype.set_forward_info = function (obj) {
        var self = this;
        self.to = [];
        self.cc = [];
        self.properties.attachments = obj.properties.attachments;
    };

    Email.prototype.uploadFiles = function () {
        var self = this;
        if (self.files && self.files.length) {
            angular.forEach(self.files, function (file) {
                fileService.setMimeTypeFromExt(file);

                file.successMsg = "Uploading file...";
                if (!file.$error) {
                    var uploadObject = Upload.upload({
                        url: URI.FILE.ATTACH + '?correspId=' + self.properties.id + '&mimeType=' + file.mimeTypeQueryString,
                        method: URI.FILE.ATTACH.method,
                        data: {
                            file: file
                        }
                    });

                    var isUploadInProgress = Upload.isUploadInProgress();

                    uploadObject.then(function (result) {
                        $timeout(function () {
                            file.successMsg = "File uploaded successfully.";
                            file.file_id = result.data[0];
                            // file.file_name = file.name;
                            self.properties.attachments.push(file);
                            for (var i = 0; i < self.files.length; i++) {
                                if (self.files[i].name == file.name) {
                                    self.files.splice(i, 1);
                                    break;
                                }
                            }
                            isUploadInProgress = false;
                        });
                    }, function (response) {
                        if (response.status > 0)
                            file.successMsg = "An error has occured. The file couldn't be uploaded.";
                    }, function (evt) {
                        file.progressPercentage = parseInt(100.0 *
                            evt.loaded / evt.total);
                    });
                }
            });
        }
    };

    Email.prototype.removeFiles = function (file) {
        var self = this;

        var p = $q.defer();
        file.disableBtn = true;
        var fileToRemove = {
            correspId: self.properties.id,
            fileId: file.file_id
        };
        self[URI.FILE.DELETE_ATTACHMENT.method](URI.FILE.DELETE_ATTACHMENT + "?correspId=" + self.properties.id + "&fileId=" + file.file_id)
            .then(function () {
                for (var i = 0; i < self.properties.attachments.length; i++) {
                    if (self.properties.attachments[i].file_id == file.file_id) {
                        self.properties.attachments.splice(i, 1);
                        break;
                    }
                }
                p.resolve();
            }).catch(function (e) {
                p.reject();
            })
            .finally(function () {
                file.disableBtn = false;
            });

        return p.promise;

    };

    return Email;
});
