import { cipo } from 'cipo';

cipo.controller('filemanagerController',
    function ($scope, $state, $http, APP_CONTENT, URI, Explorer, userService) {
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.content = APP_CONTENT;
        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor("FM"); }
        });

        $scope.currentProject = { id: 0 };
        $scope.projects = null;
        $scope.project = true;

        $scope.userStorage = {
            treeURLs: {
                rootTreeURL: URI.FOLDER.GET_FOLDERS,
                childrenURL: URI.FOLDER.FOLDER_DETAIL,
                addFolderURL: URI.FOLDER.ADD_FOLDER,
                editFolderURL: URI.FOLDER.EDIT_FOLDER,
                deleteFolderURL: URI.FOLDER.DELETE
            },
            fileURLs: {
                uploadFileURL: URI.FILE.UPLOAD,
                downloadFileURL: URI.FILE.DOWNLOAD,
                deleteFileURL: URI.FILE.DELETE,
                renameFileURL: URI.FILE.RENAME
            },
            managerURLs: {
                rootData: {
                    dataURL: URI.FOLDER.GET_FOLDERS
                },
                childData: {
                    dataURL: URI.FOLDER.FOLDER_DETAIL
                }
            }
        };

        $scope.projectStorage = {
            treeURLs: {
                rootTreeURL: URI.PROJECT_FOLDER.GET_FOLDERS,
                childrenURL: URI.PROJECT_FOLDER.FOLDER_DETAIL,
                addFolderURL: URI.PROJECT_FOLDER.ADD_FOLDER,
                editFolderURL: URI.PROJECT_FOLDER.EDIT_FOLDER,
                deleteFolderURL: URI.PROJECT_FOLDER.DELETE
            },
            fileURLs: {
                uploadFileURL: URI.PROJECT_FILE.UPLOAD,
                downloadFileURL: URI.PROJECT_FILE.DOWNLOAD,
                deleteFileURL: URI.PROJECT_FILE.DELETE,
                renameFileURL: URI.PROJECT_FILE.RENAME
            },
            URLparam: {
                name: "projectId",
                value: "key",
                appliesTo: ["rootTreeURL", "childrenURL", "uploadFileURL", "rootData", "childData"]
            },
            managerURLs: {
                rootData: {
                    dataURL: URI.PROJECT_FOLDER.GET_FOLDERS
                },
                childData: {
                    dataURL: URI.PROJECT_FOLDER.FOLDER_DETAIL
                }

            }
        };

        Object.defineProperty($scope.projectStorage.URLparam, 'object', {
            get: function () { return $scope.currentProject; }
        });

        $scope.loadExplorer = function (storageType) {
            $scope.explorer = new Explorer({
                components: 3,
                operations: $scope.operations,
                layout: 1,
                treeSetup: {
                    rootTreeURL: storageType.treeURLs.rootTreeURL,
                    childrenURL: storageType.treeURLs.childrenURL,
                    addFolderURL: storageType.treeURLs.addFolderURL,
                    editFolderURL: storageType.treeURLs.editFolderURL,
                    deleteFolderURL: storageType.treeURLs.deleteFolderURL,
                    uploadFileURL: storageType.treeURLs.uploadFileURL,
                    renameFileURL: storageType.treeURLs.renameFileURL,

                    treeOptions: {
                        nodeChildren: "folders",
                        dirSelectable: true,
                        isLeaf: function (node) { return false; },
                        multiSelection: false,
                        injectClasses: {
                            ul: "a1",
                            li: "a2",
                            liSelected: "a7",
                            iExpanded: "a3",
                            iCollapsed: "a4",
                            iLeaf: "a5",
                            label: "a6",
                            labelSelected: "a8"

                        }
                    },
                    orderby: "name"
                },
                URLparam: storageType.URLparam || null,
                fileSetup: {
                    uploadFileURL: storageType.fileURLs.uploadFileURL || null,
                    downloadFileURL: storageType.fileURLs.downloadFileURL || null,
                    deleteFileURL: storageType.fileURLs.deleteFileURL || null,
                    renameFileURL: storageType.fileURLs.renameFileURL || null
                },
                managerSetup: {
                    rootData: {
                        dataURL: storageType.managerURLs.rootData.dataURL,
                    },
                    childData: {
                        dataURL: storageType.managerURLs.childData.dataURL,
                        dataWrapper: ["folders", "files"],
                    },
                    search: false,
                    columns: {
                        1: [
                            { name: "name", label: 'Name', type: 'text', onTablet: true, onPhone: true, width: 30 },
                            { name: "createdBy", label: 'Owner', type: 'text', onTablet: true, onPhone: true, width: 30 },
                            { name: ["updatedOn"], label: 'Last Modified', type: 'date', onTablet: true, onPhone: true, width: 20 },
                            {
                                name: "length",
                                label: 'File Size',
                                type: 'processed_text',
                                separators: [["", "KB"], ["", "MB"]],
                                conditions: [
                                    ["<", 1000000, "", true, ["/", "1000"]],
                                    [">=", 1000000, "", true, ["/", "1000000"]]
                                ],
                                onTablet: true, onPhone: true, width: 20
                            }
                        ],
                        2: [
                            { name: "name", label: 'Name', type: 'text' }
                        ]
                    }
                }
            });
        };

        $scope.getProjects = function () {
            if ($scope.projects === null) {
                $scope.loadingProjects = true;
                $http[URI.PROJECT.DICT.method](URI.PROJECT.DICT.toString())
                    .then(function (result) {
                        $scope.loadingProjects = false;
                        $scope.projects = result.data;
                        if ($scope.projects && $scope.projects.length) {
                            $scope.currentProject = $scope.projects[0];
                        }
                        $scope.changeProject();
                    })
                    .catch(function (e) {
                        console.error(e);
                    });
            } else {
                $scope.changeProject();
            }
        };
    
        $scope.getProjects();

        $scope.changeProject = function () {
            $scope.loadExplorer($scope.projectStorage);
        };

        $scope.loadCipoDrive = function () {
            if ($scope.project) {
                $scope.project = false;
                $scope.drive = true;
                $scope.loadExplorer($scope.userStorage);
            }
        };

        $scope.loadProjectStorage = function () {
            if ($scope.drive) {
                $scope.project = true;
                $scope.drive = false;
                $scope.getProjects();
            }
        };
});
