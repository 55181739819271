import { KeyValueModel } from "../../../../models/common";

export enum RestrictionType {
  ReadOnly = 1,
  Required = 2,
  NeedsToBeChecked = 3,
  Number = 4,
  DateTime = 5,
  MimeTypes = 6,
  ShowThousandSeparator = 7,
}

export enum RestrictionValueType {
  Decimal = 1,
  Integer = 2,
  Date = 3,
  DateTime = 4,
  Time = 5,
}

export const RestrictionTypeValues = new Map<RestrictionType, RestrictionValueType[]>([
  [RestrictionType.ReadOnly, []],
  [RestrictionType.Required, []],
  [RestrictionType.NeedsToBeChecked, []],
  [RestrictionType.Number, [RestrictionValueType.Decimal, RestrictionValueType.Integer]],
  [RestrictionType.DateTime, [RestrictionValueType.Date, RestrictionValueType.DateTime, RestrictionValueType.Time]],
  [RestrictionType.MimeTypes, []],
  [RestrictionType.ShowThousandSeparator, []],
]);

export interface KeyValueRestrictionModel extends KeyValueModel<RestrictionType, RestrictionValueType | number> {
  mimeTypes: number[];
  mimeTypesDataSource: KeyValueModel<number, string>[];
}
