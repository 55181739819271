import { cipo } from 'cipo';

cipo.controller('arolesController',
	function ($scope, $state, Manager, rememberManagerService, $uibModal, dialogs, Message, URI, ACTIONS, Menu, userService, $mdDialog, CIPO_ICONS) {
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.module = userService.system.modules[$state.current.code];
        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor($state.current.code); }
        });

        $scope.tempList = [];
        $scope.icons = CIPO_ICONS.lookup;
        $scope.showDisabled = true;
        $scope.roleType = 0;
        $scope.sendList = function (data) {
            $scope.tempList = data;
            var listToSend = [];
            if (Object.prototype.toString.call(data) == '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].id != 'undefined') {
                            listToSend.push(data[i].id);
                        }
                    }
                }
            } else {
                if (typeof data.id != 'undefined') {
                    listToSend.push(data.id);
                }
            }
            return listToSend;
        }

        $scope.loadManagerData = function (URLdata) {
            $scope.manager = new Manager({
                objectsPerPage: 20,
                dataURL: URLdata,
                dataWrapper: "data",
                options: {
                    multiselect: $scope.operations.Update || $scope.operations.Delete ? true : false
                },
                otherParams: {
                    ShowDisabled: $scope.showDisabled
                },
                rowOnClick: function(row) {
                    $scope.editRole(row);     
                },
                statusFilter: {
                    model: $scope.showDisabled,
                    name: "Filter",
                    managerParam: "ShowDisabled",
                    dataArr: [
                        {
                            Key: true,
                            Val: "Show All"
                        },
                        {
                            Key: false,
                            Val: "Show Available"
                        }
                    ],
                    type: "select"
                },
                filters: [
                ],
                leftActions: [
                    {
                        setProperties: ACTIONS.CREATE,
                        conditionOnTop: function () {
                            if ($scope.operations.Create) return true;
                            else return false;
                        },
                        click: function (rows) {
                            $scope.editRole();
                        }
                    },
                ],
                actions: [
                    {
                        setProperties: ACTIONS.REFRESH,
                        click: function () {
                            $scope.manager.refresh();
                        }
                    },
                    {
                        setProperties: ACTIONS.DETAILS,
                        condition: function (row) {
                            if (row.operations.Update)
                                return true;
                            else return false;
                        },
                        click: function (row) {
                            $scope.editRole(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.VIEW,
                        condition: function (row) {
                            if (!row.operations.Update)
                                return true;
                            else return false;
                        },
                        click: function (row) {
                            $scope.editRole(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.ACTIVATE,
                        displayOnRow: $scope.operations.Update ? true : false,
                        condition: function (row) {
                            if (row.disabled && row.operations.Update)
                                return true;
                            else return false;
                        },
                        conditionOnTop: function () {
                            if ($scope.operations.Update) return true;
                            else return false;
                        },
                        click: function (rows) {
                            $scope.enableRole($scope.sendList(rows));
                        }
                    },
                    {
                        setProperties: ACTIONS.DEACTIVATE,
                        displayOnRow: $scope.operations.Update ? true : false,
                        condition: function (row) {
                            if (!row.disabled && row.operations.Update)
                                return true;
                            else return false;
                        },
                        conditionOnTop: function () {
                            if ($scope.operations.Update) return true;
                            else return false;
                        },
                        click: function (rows) {
                            $scope.disableRole($scope.sendList(rows));
                        }
                    },
                    {
                        setProperties: ACTIONS.DELETE,
                        displayOnRow: $scope.operations.Delete ? true : false,
                        conditionOnTop: function () {
                            if ($scope.operations.Delete) return true;
                            else return false;
                        },
                        condition: function (row) {
                            if (row.operations.Delete)
                                return true;
                            else return false;
                        },
                        click: function (rows) {
                            $scope.deleteRole($scope.sendList(rows));
                        }
                    },
                    {
                        setProperties: ACTIONS.MORE,
                        conditionOnTop: function () {
                            if ($scope.operations.Update) return true;
                            else return false;
                        },
                        actions: [
                            {
                                setProperties: ACTIONS.ACTIVATE,
                                click: function (rows) {
                                    $scope.enableRole($scope.sendList(rows));
                                }
                            },
                            {
                                setProperties: ACTIONS.DEACTIVATE,
                                click: function (rows) {
                                    $scope.disableRole($scope.sendList(rows));
                                }
                            }

                        ]
                    }
                ]
            });
            var statusLookup = {
                false: { name: 'Available', class: 'basic' },
                true: { name: 'Deactivated', class: 'deactivated' }
            };
        
            $scope.manager.set_Columns([
                { name: 'name', label: 'Name', type: 'text', onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                { name: 'abbr', label: 'Abbreviation', type: 'text', onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                //{ name: 'access', label: 'Access level', type: 'text' },
                { name: 'disabled', label: 'Status', type: 'status', lookupArr: statusLookup, onTablet: true, onPhone: true, width: 30 }
            ]);

            // $scope.manager.loadPage();
            if (rememberManagerService.moduleId == $scope.module.moduleId) {
                $scope.manager = rememberManagerService.get();
                $scope.manager.loading = false;
            } else {

                $scope.manager.loadPage();
                rememberManagerService.set($scope.manager, $scope.module.moduleId);
            }
        }

        $scope.loadSystemRoles = function () {
            // $scope.URLdata = ;
            if ($scope.roleType != 0) {
                $scope.roleType = 0;
                $scope.loadManagerData(URI.ROLES.SEARCH);
            }

        };

        $scope.init = function () {
            $scope.loadManagerData(URI.ROLES.SEARCH);
        };
        $scope.init();

        $scope.loadDynamicRoles = function () {
            if ($scope.roleType != 1) {
                $scope.roleType = 1;
                $scope.loadManagerData(URI.ROLE.DYNAMIC_LIST);
            }
        }

        $scope.newCalls = function () {

        }


        /*$scope.editRole = function (role) {
            var role = role || null;
            var modalInstance = $uibModal.open({
                controller: 'editRoleController',
                templateUrl: '/ng/views/admin/modals/editRole.html',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    "role": function () {
                        return role;
                    },
                    "roleType": function () {
                        return $scope.roleType;
                    },
                    "operations": function () {
                        return $scope.operations
                    }
                }
            });

            modalInstance.result.then(function (result) {
                $scope.manager.page = 1;
                $scope.manager.loadPage();
                Menu.triggerRefresh();
            }, function () {
                console.log('cancel pressed');
                $scope.manager.loadPage();
                Menu.triggerRefresh();
            });
        }*/

        $scope.editRole = function (role, roleType, ev) {
            var role = role || null;
            $mdDialog.show({
                locals: { role: role, roleType: $scope.roleType, operations: $scope.operations, module: $scope.module },
                controller: 'editRoleController',
                templateUrl: '/ng/views/admin/modals/editRole.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (result) {
                    $scope.manager.page = 1;
                    $scope.manager.loadPage();
                    Menu.triggerRefresh();
                }, function () {
                    Menu.triggerRefresh();
                    //$scope.manager.loadPage();
                });
        };

        var confirm = function (title, text, ev) {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        }

        $scope.enableRole = function (list, ev) {
            var list = list || [];

            if (list.length) {
                //var d = dialogs.confirm('Activating Roles', 'Are you sure you want to activate the selected Role(s)?');
                $mdDialog.show(confirm('Enable Roles', 'Are you sure you want to enable the selected Role(s)?' ))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[URI.ROLE.ENABLE.method](URI.ROLE.ENABLE, { body: { list: list } })
                            .then(function () {
                                Message.info('Role(s) activated successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(e, $scope.manager.rows, 'id', 'name');
                            })
                            .finally(function () {
                                $scope.manager.loadPage();
                            })
                    })
                    .catch(function () {
                        //cancel pressed
                    });

            } else {
                Message.info('Please select the item(s) to activate');
            }

        }

        $scope.deleteRole = function (list) {
            var list = list || [];

            if (list.length) {
                //var d = dialogs.confirm('Deleting Roles', 'Are you sure you want to delete the selected Role(s)?');
                $mdDialog.show(confirm('Deleting Roles', 'Are you sure you want to delete the selected Role(s)?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[URI.ROLE.DELETE.method](URI.ROLE.DELETE, { body: { list: list } })
                            .then(function () {
                                
                                Message.info('Role(s) deleted successfully');
                            })
                            .catch(function (e) {
                                if (e.data.ids) {
                                    messageContent = $scope.processError(e.data.ids, "deleted");
                                    var d = dialogs.notify("Deleting Roles", messageContent);
                                } else {
                                    Message.dberror(e, $scope.manager.rows, 'id', 'name');
                                }

                                //$scope.manager.loading = false;
                                //Message.dberror(e);
                            })
                            .finally(function () {
                                $scope.manager.loadPage();
                            })
                    })
                    .catch(function () {
                        //cancel pressed
                    });
            } else {
                Message.info('Please select the item(s) to delete');
            }
        }

        $scope.disableRole = function (list) {
            var list = list || [];

            if (list.length) {
                // var d = dialogs.confirm('Deactivating Roles', 'Are you sure you want to deactivate the selected Role(s)?');
                $mdDialog.show(confirm('Disabling Roles', 'Are you sure you want to disable the selected Role(s)?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[URI.ROLE.DISABLE.method](URI.ROLE.DISABLE, { body: { list: list } })
                            .then(function () {
                                Message.info('Role(s) deactivated successfully');
                            })
                            .catch(function (e) {
                                
                                /*var rolesIds = e.data.ids.join(", ");
                                if (e.data.ids.length == 1) {
                                    messageContent = 'The role ' + rolesIds + " is in use and can't be deactivated.";
                                } else messageContent = 'The roles ' + rolesIds + " are in use and can't be deactivated.";*/
                                //var rolesIds = e.data.ids;
                                
                                if (e.data.ids) {
                                    messageContent = $scope.processError(e.data.ids, "deactivated");
                                    var d = dialogs.notify("Deactivating Roles", messageContent);
                                } else {
                                    Message.dberror(e, $scope.manager.rows, 'id', 'name');
                                }

                            })
                            .finally(function () {
                                $scope.manager.loadPage();
                            })
                    })
                    .catch(function () {
                        //cancel pressed
                    });
            } else {
                Message.info('Please select the item(s) to deactivate');
            }


        }

        $scope.$watch(function () {
            return userService.isReloadGrid;
        },
            function (n, o) {
                if (n) {
                    $scope.manager.loadPage();
                    userService.isReloadGrid = false;

                }
            });

        $scope.processError = function (data, action) {
            var rolesNames = [];
            var messageContent = "";
            if (data.length == 1) {
                messageContent = 'The role ' + $scope.tempList[0].name + " is in use and can't be " + action + ".";
            } else {
                for (var i = 0; i < data.length; i++) {
                    for (var j = 0; j < $scope.tempList.length; j++) {
                        if ($scope.tempList[j].id == data[i]) {
                            rolesNames.push($scope.tempList[j].name);
                            break;
                        }
                    }
                }
                messageContent = 'The roles ' + rolesNames.join(", ") + " are in use and can't be " + action + ".";
            }
            return messageContent;
        }
    });
