import { cipo } from 'cipo';

cipo.controller('filecontractmanagerController',
    function ($transition$, $scope, $state, APP_CONTENT, URI, Explorer1, userService, FileManagerConfig) {
        const params = $transition$.params();
        $scope.moduleName = userService.system.mNames[$state.current.code];
        // $scope.module = userService.system.modules["FCM"];
        $scope.content = APP_CONTENT;
        // get live operations
        Object.defineProperty($scope, 'module', {
            get: function () { return userService.system.modules[$state.current.code]; }
        });
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor($state.current.code); }
        });
        Object.defineProperty($scope, 'userService', {
            get: function () {
                return userService;
            }
        });
        $scope.tempOps = { Read: true, Create: true, Delete: true, Update: true };
        

        //$scope.$watch(function () {
        //    return userService.isLoadingMenu;
        //},
        //    function (n, o) {
        //        if (!n) {
        //            console.log('newVal', n);
        //            if (!$scope.explorer) $scope.loadExplorer($scope.explorerData);
        //            else if (!userService.isLoadingMenu && $scope.module) {
        //                $scope.explorer.urlsData.tree.urlParams.contractId = userService.system.userdata.contractId;
        //                // $scope.explorer.manager.urlParams = $scope.explorer.setParams();

        //                var reloadExplorer = function () {
        //                    var root = { id: -1, folders: [], isLoadingChildren: true };
        //                    $scope.explorer.lookupFolders = {};
        //                    $scope.explorer.lookupFolders[root.id] = root;
        //                    $scope.explorer.init();
        //                    //  $scope.explorer.getChildren();
        //                }

        //                reloadExplorer();
                        
        //            }
        //        }
        //    });

        $scope.explorerData = FileManagerConfig.projectFiles;

        Object.defineProperty($scope.explorerData.tree.urlParams, 'contractId', {
            get: function () {
                return userService.system.userdata.contractId;
            },
            set: function() {}
        });

            $scope.loadExplorer = function (storageType) {
                // storageType = $scope.userStorage;
                $scope.explorer = new Explorer1({
                    // operations: $scope.tempOps,
                    // module: $scope.module,
                    navigateTo: function (route, params) {
                        $state.go(route, params);
                    },
                    abbreviation: 'FCM',
                    layout: 1,
                    hasGlobal: true,
                    rootName: "...",
                    treeSetup: {
                        treeOptions: {
                            nodeChildren: "folders",
                            allowDeselect: false,
                            dirSelectable: true,
                            isLeaf: function (node) { if ((node.id == -10) || (node.id == -15)) return true; else return false; },
                            multiSelection: false,
                            injectClasses: {
                                ul: "a1",
                                li: "a2",
                                liSelected: "a7",
                                iExpanded: "a3",
                                iCollapsed: "a4",
                                iLeaf: "a5",
                                label: "a6",
                                labelSelected: "a8"

                            }
                        },
                        orderby: ""
                    },
                    urlsData: storageType,
                    managerSetup: {
                        search: true,
                        customFilters: [
                            { key: "isAllContracts", value: false, label: "On all contracts", type: "checkbox" },
                            { key: "showAllPhotos", value: false, label: "Show images only", type: "checkbox" }
                        ],
                        columns: {
                            1: [
                                { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                                { name: "createdBy", replacementFor: "createdBy", label: 'Owner', type: 'text', onTablet: true, onPhone: false, width: 25, fieldTypeId: 1 },
                                { name: "createdOn", replacementFor: "createdOn", label: 'Created On', type: 'text', onTablet: true, onPhone: false, width: 15, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                                { name: "updatedOn", replacementFor: "updatedOn", label: 'Last Modified', type: 'text', onTablet: true, onPhone: false, width: 15, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                                { name: "length", replacementFor: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 15 } // fieldTypeId: 2
                            ],
                            2: [
                                { name: "_big_name", label: 'Name', type: 'checkbox' }
                            ]
                        },
                        searchColumns: [
                            { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 25, fieldTypeId: 1 },
                            { name: "folderPath", replacementFor: "folderPath", label: 'Folder', type: 'checkbox',  onTablet: true, onPhone: true, width: 25, fieldTypeId: 1 },
                            { name: "createdBy", replacementFor: "createdBy", label: 'Owner', type: 'text', onTablet: true, onPhone: false, width:  20, fieldTypeId: 1 },
                            { name: "createdOn", replacementFor: "createdOn", label: 'Created On', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            { name: "updatedOn", replacementFor: "updatedOn", label: 'Last Modified', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            // { name: "isContractGlobal", replacementFor: "isContractGlobal", label: 'Global', type: 'text', onTablet: true, onPhone: true, width: 10, fieldTypeId: 3 },
                            { name: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 10 } // fieldTypeId: 2
                        ],
                        globalSearchColumns: [
                            { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 20, fieldTypeId: 1 },
                            { name: "folderPath", replacementFor: "folderPath", label: 'Folder', type: 'checkbox',  onTablet: true, onPhone: true, width: 15, fieldTypeId: 1 },
                            { name: "contractName", replacementFor: "contractName", label: 'Contract', type: 'checkbox',  onTablet: true, onPhone: true, width: 15, fieldTypeId: 1 },
                            { name: "createdBy", replacementFor: "createdBy", label: 'Owner', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 1 },
                            { name: "createdOn", replacementFor: "createdOn", label: 'Created On', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            { name: "updatedOn", replacementFor: "updatedOn", label: 'Last Modified', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            // { name: "isContractGlobal", replacementFor: "isContractGlobal", label: 'Global', type: 'text', onTablet: true, onPhone: true, width: 10, fieldTypeId: 3 },
                            { name: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 10 } // fieldTypeId: 2
                        ],
                        recycleBinColumns: [
                            { name: "_name", label: 'Name', type: 'checkbox', replacementFor: "name", onTablet: true, onPhone: true, width: 25, fieldTypeId: 1 },
                            //{ name: "contractName", replacementFor: "contractName", label: 'Contract', type: 'checkbox',  onTablet: true, onPhone: true, width: 15, fieldTypeId: 1 },
                            { name: "updatedBy", replacementFor: "updatedBy", label: 'Deleted By', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 1 },
                            { name: "updatedOn", replacementFor: "updatedOn", label: 'Deleted On', type: 'text', onTablet: true, onPhone: false, width: 20, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            { name: "pathWhenDeleted", replacementFor: "pathWhenDeleted", label: 'Original Location', type: 'checkbox', onTablet: true, onPhone: true, width: 20, fieldTypeId: 1 },
                            // { name: "updatedOn", replacementFor: "updatedOn", label: 'Last Modified', type: 'text', onTablet: true, onPhone: false, width: 10, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            // { name: "isContractGlobal", replacementFor: "isContractGlobal", label: 'Global', type: 'text', onTablet: true, onPhone: true, width: 10, fieldTypeId: 3 },
                            { name: "length", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 15 } // fieldTypeId: 2
                        ],
                        archiveColumns: [
                            { name: "archiveName", label: 'Name', type: 'checkbox', replacementFor: "archiveName", onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                            { name: "archiveDescription", replacementFor: "archiveDescription", label: 'Description', type: 'checkbox', onTablet: true, onPhone: true, width: 27, fieldTypeId: 1 },
                            { name: "createdOn", replacementFor: "createdOn", label: 'Created On', type: 'text', onTablet: true, onPhone: false, width: 15, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            { name: "createdBy", replacementFor: "createdBy", label: 'Created By', type: 'text', onTablet: true, onPhone: false, width: 25, fieldTypeId: 1 },                           
                            { name: "filesNumber", replacementFor: "filesNumber", label: 'No. of Files', type: 'checkbox', onTablet: true, onPhone: false, width: 8 }
                        ],
                        archiveFileColumns: [
                            { name: "archiveFileName", label: 'Name', type: 'checkbox', replacementFor: "archiveFileName", onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                            { name: "module", replacementFor: "module", label: 'Module', type: 'checkbox', onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                            { name: "createdOn", replacementFor: "createdOn", label: 'Created On', type: 'text', onTablet: true, onPhone: false, width: 15, fieldTypeId: 4, options: "YYYY-MM-DD hh:mm:ss A" },
                            { name: "createdBy", replacementFor: "createdBy", label: 'Created By', type: 'text', onTablet: true, onPhone: false, width: 15, fieldTypeId: 1 },
                            { name: "fileSize", replacementFor: "fileSize", label: 'File Size', type: 'checkbox', onTablet: true, onPhone: false, width: 15 }
                        ]
                    }
                });

            // Open file detail based on state params
            if (typeof params.f != "undefined" && params.f.length) {
                $scope.explorer.fileDetails({ id: params.f });
            }
        };

        $scope.loadInitialData = function () {

        }
        $scope.loadExplorer($scope.explorerData);

        $scope.$watch(function () {
            return userService.isReloadGrid;
        },
            function (n, o) {
                if (n) {
                    $scope.explorer.manager.loading = true;
                    $scope.explorer.manager.loadPage();
                    userService.isReloadGrid = false;

                }
            });
    });
