import { GridsterModule } from '@adrian3de/angular-gridster2';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../shared/modules/material.module';
import { PipesModule } from '../shared/modules/pipes.module';
import { SharedComponentsModule } from '../shared/modules/shared-components.module';
import { DataCardViewPartComponent } from './instance/data-card-view-part/data-card-view-part.component';
import { DataCardViewComponent } from './instance/data-card-view/data-card-view.component';
import { DataCardsViewComponent } from './instance/data-cards-view/data-cards-view.component';
import { DataCardsViewService } from './instance/data-cards-view/data-cards-view.service';

@NgModule({
  imports: [CommonModule, TranslateModule, MaterialModule, FlexLayoutModule, GridsterModule, PipesModule, SharedComponentsModule],
  providers: [DataCardsViewService],
  declarations: [DataCardViewPartComponent, DataCardViewComponent, DataCardsViewComponent],
  exports: [DataCardViewPartComponent, DataCardViewComponent, DataCardsViewComponent],
})
export class DataCardViewModule {}
