import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { UpgradeModule } from '@angular/upgrade/static';

import { get } from "scriptjs";
import { AppModule } from "./src/app.module";
import { environment } from "./src/environments/environment";

get("https://cdnjs.cloudflare.com/ajax/libs/d3/5.16.0/d3.js", () => {});
get(
  "https://s3.amazonaws.com/cdn.hellosign.com/public/js/hellosign-embedded.LATEST.min.js",
  () => {}
);
get(
  environment.production
    ? "https://pspdfkit.cipo.cloud/pspdfkit.js"
    : "https://pspdfkit-dev.cipo.cloud/pspdfkit.js",
  () => {}
);

platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
  const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
  upgrade.bootstrap(document.body, ['cipo']);
});
