import { downgradeComponent } from "@angular/upgrade/static";
import * as angular from "angular";
import { GridsterComponent, GridsterItemComponent } from "@adrian3de/angular-gridster2";
import { FooterComponent } from "./footer/footer.component";

export const Ng1ModuleSystem = angular
  .module("ng1module.system", [])
  .directive("appFooter", downgradeComponent({ component: FooterComponent }) as angular.IDirectiveFactory)
  .directive("gridster", downgradeComponent({ component: GridsterComponent }) as angular.IDirectiveFactory)
  .directive("gridsterItem", downgradeComponent({ component: GridsterItemComponent }) as angular.IDirectiveFactory)
  .name;
