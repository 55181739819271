import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FieldModel, ScreenFieldModel } from '../../../models/module/fields/main';
import { GridViewModel } from '../../../models/module/grid';
import { ScreenModel } from '../../../models/module/screen';
import { HttpService } from '../../../shared/services/http.service';

@Injectable()
export class DataCardSettingsService {
  constructor(private httpService: HttpService) {}

  getFields(screen: ScreenModel): Observable<GridViewModel<FieldModel[]>> {
    var qs = `screenId=${screen.id}&addContractFields=true`;
    return this.httpService.post<GridViewModel<FieldModel[]>>(`_api/FieldDefinition/Search?${qs}`, {});
  }

  getField(id: number): Observable<FieldModel> {
    return this.httpService.get<FieldModel>(`_api/FieldDefinition/Get?id=${id}`);
  }

  getScreenFields(screen: ScreenModel): Observable<GridViewModel<ScreenFieldModel[]>> {
    return this.httpService.post<GridViewModel<ScreenFieldModel[]>>(`_api/ScreenFields/Search?screenId=${screen.id}`, {});
  }

  syncScreenFields(screen: ScreenModel, screenFields: ScreenFieldModel[]): Observable<void> {
    return this.httpService.put<void>(`_api/ScreenFields/SyncFieldToScreen?screenId=${screen.id}`, screenFields);
  }
}
