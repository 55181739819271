import { cipo } from 'cipo';

cipo.controller('menuFolderController',
function ($scope, Message, $http, URI, ICONS, editFolder, parentScope, $mdDialog, FolderName, MODAL) {
    $scope.isCreate = editFolder ? false : true;
    $scope.disabledBtns = false;
    $scope.message = "Please enter a folder name:";
    $scope.modalInfo = MODAL;
    $scope.newFolderName = editFolder ? editFolder.name : "";

    $scope.folderName = new FolderName($scope.newFolderName);
    $scope.icons = ICONS;
    if (editFolder && editFolder.iconId) {
        $scope.selectedIcon = ICONS[editFolder.iconId - 1];
    }
    $scope.setIcon = function (icon) {
        $scope.selectedIcon = icon;
    }
    $scope.isCreate = editFolder ? false : true;
    if (parentScope != null) {
        var editFolder = {};
        if (parentScope != 0) editFolder.parentId = parentScope.$modelValue.id;
    }

    $scope.createOrUpdateFolder = function () {
        $scope.folderName.form.validate();
        if ($scope.folderName.form.isValid) {
            $scope.disabledBtns = true;
            if ($scope.isCreate) {
                editFolder.name = $scope.folderName.properties.folderName;
                editFolder.children = [];
                editFolder.typeId = 1;
                editFolder.typeName = "Folder";
                if ($scope.selectedIcon) editFolder.iconId = $scope.selectedIcon.id;
                $http[URI.MENU.ADD.method](URI.MENU.ADD.toString(), editFolder)
                        .then(function (result) {
                            editFolder.id = result.data;
                            // $scope.newFolderName = "";
                            $scope.disabledBtns = false;
                            Message.info('Menu item saved successfully');
                            $mdDialog.hide(editFolder);

                        }).catch(function (e) {
                            console.error(e);
                            $scope.message = "The folder could not be created. Please try again later.";
                            $scope.disabledBtns = false;
                            Message.dberror(e);
                        });
            } else {
                editFolder.name = $scope.folderName.properties.folderName;
                if ($scope.selectedIcon) editFolder.iconId = $scope.selectedIcon.id;
                $http[URI.MENU.EDIT.method](URI.MENU.EDIT.toString(), editFolder)
                    .then(function (result) {
                        Message.info('Menu item saved successfully');
                        $mdDialog.hide(result.data);
                        })
                    .catch(function (e) {
                        console.error(e);
                        $scope.message = "The folder could not be created. Please try again later.";
                        $scope.disabledBtns = false;
                        Message.dberror(e);
                    });
            }
        }
        else {
            $scope.message = "No name specified. Please try another name:";
        }
    }

    $scope.cancel = function () {
        //$uibModalInstance.dismiss();
        $mdDialog.cancel();
    }


});
