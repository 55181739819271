export interface ScreenFieldFormattingModel {
  id?: number;
  showColon?: boolean;
  size?: number;
  fontStyle: FontStyleType;
  fontSize: number;
  fontColor: string;
  alignHorizontal: HorizontalAlignType;
  alignVertical: VerticalAlignType;
}

export enum FontStyleType {
  None = 0,
  Bold = 1,
  Italic = 2,
  Underlined = 4,
}

export enum HorizontalAlignType {
  Left = 1,
  Center = 2,
  Right = 3,
}

export enum VerticalAlignType {
  Bottom = 1,
  Center = 2,
  Top = 3,
}
