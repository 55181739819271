import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { DataCardViewPartComponent } from './instance/data-card-view-part/data-card-view-part.component';
import { DataCardViewComponent } from './instance/data-card-view/data-card-view.component';
import { DataCardsViewComponent } from './instance/data-cards-view/data-cards-view.component';

export const Ng1ModuleInstance = angular
  .module('ng1module.module-instance', [])
  .directive('appDataCardView', downgradeComponent({ component: DataCardViewComponent }) as angular.IDirectiveFactory)
  .directive('appDataCardViewPart', downgradeComponent({ component: DataCardViewPartComponent }) as angular.IDirectiveFactory)
  .directive('appDataCardsView', downgradeComponent({ component: DataCardsViewComponent }) as angular.IDirectiveFactory)
  .name;
