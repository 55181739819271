import { cipo } from 'cipo';

cipo.controller('editNModuleController',
    function ($scope, Message, STATES_INFO, MODAL, ACTIONS, ModuleDictionaries, ActionTypes,
        $timeout, $window, ModuleN, operations, module, $q, obj, $mdDialog,
        simplifiedVersion, userService, STATES_STATUS, FileManagerConfig, localStorageService) {
        $scope.modalInfo = MODAL;
        $scope.simplifiedVersion = simplifiedVersion || false;

        $scope.module = new ModuleN(obj, simplifiedVersion);
        localStorageService.set('currentModule', parseInt($scope.module.properties.id));
        
        // For SOV and PP, Printouts tab is global
        $scope.printoutIsGlobal = ($scope.module.properties.id == -4 || $scope.module.properties.id == -3);

        $scope.operations = operations || {};
        Object.defineProperty($scope, 'canEditRoles', {
            get: function () { return userService.getOperationsFor("R").Update; }
        });
        $scope.parentModule = module;
        $scope.changeSelectedRole = function (r) {
            $scope.selectedRole = r || (module.roles || [])[0] || {};
            $scope.module.selectedRoleId = $scope.selectedRole.key;
        };
        $scope.changeSelectedRole();
        $scope.openMenu = function ($mdMenu, ev) {
            $timeout(function () {
                $scope.focusSearch = true;
                $timeout(function () {
                    $scope.focusSearch = false;

                }, 300);
            }, 300);
            $mdMenu.open(ev);
        };

        var confirm = function (title, text, isMultiple) {
            return $mdDialog.confirm({
                title: title,
                textContent: text,
                ariaLabel: 'Confirm Dialogue',
                ok: 'Proceed',
                cancel: 'Cancel',
                multiple: isMultiple || false
            });
        }

        $scope.selectFolder = function (ev) {

            var info = {
                modalName: 'Select Destination Folder',
                saveButtonText: 'Select',
                modalAction: "selectDestinationFolder",
                urlsData: FileManagerConfig.projectFiles,
                otherParams: FileManagerConfig.projectFiles.itemParams,
                bodyParams: { isGlobal: true },
                canUseRoot: false
            }

            if ($scope.module.destinationFolder && $scope.module.properties.projectFolderId)
                info.selectedFolder = $scope.module.destinationFolder;

            $mdDialog.show({
                locals: {
                    items: null,
                    info: info
                },
                controller: 'moveItemsController',
                templateUrl: '/ng/views/admin/modals/moveItems.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                focusOnOpen: false,
                multiple: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (result) {

                    // Check if we have a result
                    if (!result || !result.name)
                        return;

                    // Check if we have an old destination folder to add the question
                    if ($scope.module.destinationFolder && $scope.module.destinationFolder.name) {
                        $mdDialog.show(confirm('Change Destination Folder', `This will move all documents from folder ${$scope.module.destinationFolder.name} to ${result.name}. Do you want to continue ?`, true))
                            .then(function () {
                                $scope.module.destinationFolder = result;
                                $scope.module.properties.projectFolderId = result.id;
                            });
                    }
                    // We don't have an old destination folder, so just set the new one
                    else {
                        $scope.module.destinationFolder = result;
                        $scope.module.properties.projectFolderId = result.id;
                    }
                }, function () {
                    // console.log('cancel pressed');
                });
        }


        $scope.disabledBtn = false;
        $scope.visual = {
            prop: false,
            string: null,
        };

        //$scope.table = undefined;
        Object.defineProperty($scope, 'newModule', {
            get: function () { return $scope.module.properties.id ? false : true }
        });

        $scope.icons = userService.system.icons;
        $scope.iconsLookup = userService.system.iconsLookup;
        if ($scope.module.properties.iconId)
            $scope.selectedIcon = userService.system.iconsLookup[$scope.module.properties.iconId];
        $scope.moreIcons = { show: false };
        $scope.setIcon = function (icon) {
            $scope.module.properties.iconId = icon.key;
            $scope.selectedIcon = icon;
        }

        $scope.screensOptions = {
            loadingFlag: 'isScreensLoaded',
            listKey: "screensList",
            listItemUsed: "inUse",
            title: "forms",
            hasSearch: true,
            parent: $scope.module,
            disableDeleteCondition: function (item) {
                if (item.properties.inUse) {
                    return true;
                }
                else {
                    return false;
                }
            },
            subtitle: { text: "Type: ", content: function (item) { return "Transition form"; } },
            addButton: {
                text: "Add Form",
                icon: "mdi-table-plus",
                condition: function (item) {
                    return true;
                },
                click: function () {
                    return $scope.module.new_Screen();
                }
            },
            save_or_delete: "screen_Action",
            detailsIcon: 'mdi mdi-table-edit',
            hasDetails: true,
            type: "screen",
            operations: $scope.operations
        }

        $scope.actionsOptions = {
            loadingFlag: 'isActionsLoaded',
            listKey: "list",
            listItemUsed: "inUse",
            title: "actions",
            hasSearch: true,
            parent: $scope.module,
            disableDeleteCondition: function (item) {
                if (item.properties.inUse) {
                    return true;
                }
                else {
                    return false;
                }
            },
            keepParentBusyTillClose: true,
            subtitle: {
                text: "Type: ", content: function (item) {
                    var subtitle;
                    if (item.properties.type == ActionTypes['Comment']) subtitle = "Comment";
                    else if (item.properties.type == ActionTypes['PreferredFieldValue']) subtitle = "Preferred Field Value";
                    else if (item.properties.type == ActionTypes['Assignment']) subtitle = "Assignment";
                    return subtitle;
                }
            },
            addButton: {
                text: "Add Action Item",
                icon: "mdi-comment-plus-outline",
                condition: function (item) {
                    return true;
                },
                items: [
                    {
                        name: "Comment",
                        click: function (parent) {
                            return $scope.module.new_Action(ActionTypes['Comment']);
                        }
                    },
                    {
                        name: "Preferred Value",
                        click: function (parent) {
                            return $scope.module.new_Action(ActionTypes['PreferredFieldValue']);
                        }
                    },
                    {
                        name: "Assignment",
                        click: function (parent) {
                            return $scope.module.new_Action(ActionTypes['Assignment']);
                        }
                    }
                ],
            },
            save_or_delete: "action_Action",
            operations: $scope.operations
        }

        $scope.workflowGroupsOptions = {
            loadingFlag: 'isWorkflowGroupsLoaded',
            listKey: "list",
            listItemUsed: "inUse",
            listItemUsedSecond: "isDefault",
            listItemUsedTextSecond: "Default",
            title: "Workflow Groups",
            hasSearch: true,
            parent: $scope.module,
            disableDeleteCondition: function (item) {
                if (item.properties.isDefault || item.properties.inUse) {
                    return true;
                }
                else {
                    return false;
                }
            },
            keepParentBusyTillClose: true,
            addButton: {
                text: "Add Workflow Group",
                icon: "mdi-comment-plus-outline",
                condition: function (item) {
                    return true;
                },
                click: function () {
                    return $scope.module.new_WorkflowGroup();
                }
            },
            save_or_delete: "workflowGroup_Action",
            operations: $scope.operations
        }

        $scope.fieldsOptions = {
            loadingFlag: 'isScreensLoaded',
            listKey: "startscreensList",
            listItemUsed: "inUse",
            title: "fields",
            hasSearch: true,
            keepHeader: true,
            parent: $scope.module,
            disableDeleteCondition: function (item) { if (item.properties.inUse) return true; else return false; },
            subtitle: { text: "Type: ", content: function (item) { return "Start Form"; } },
            addButton: {
                text: "Add Form",
                icon: "mdi-table-plus",
                condition: function (parent) {
                    if (!parent.startscreensList.length) return true;
                    else return false;
                },
                click: function () {
                    return $scope.module.new_Screen(true);
                }
            },
            save_or_delete: "screen_Action",
            detailsIcon: 'mdi mdi-table-edit',
            hasDetails: true,
            type: "screen",
            operations: $scope.operations
        }

        $scope.templatesGroupOptions = {
            loadingFlag: 'isTemplatesGroupLoaded',
            listKey: "templatesGroupList",
            listItemUsed: "isDefault",
            listItemUsedText: "Default",
            title: "Conditional Printouts",
            hasSearch: true,
            hasToggle: true,
            toggleAction: {
                setProperties: ACTIONS.SET_DEFAULT,
                isSecondary: function (item) {
                    if (item.properties.isDefault) return true; else return false;
                },
                condition: function () {
                    return true;
                },
                disabled: function (item) {
                    if (item.properties.isDefault) return true; else return false;
                },
                click: function (item) {
                    $scope.module.set_default_GroupTemplate(item);
                }
            },
            parent: $scope.module,
            block: "templatesGroupBlocked",
            warningMsg: "No roles are assigned to this module. Please add a permission to be able to continue.",
            addButton: {
                text: "Add Conditional Printouts",
                icon: "mdi-playlist-plus",
                condition: function (item) {
                    return true;
                },
                click: function (item) {
                    return $scope.module.new_TemplateGroup($scope.printoutIsGlobal ? null : item.properties.id);
                }
            },
            save_or_delete: "templateGroup_Action",
            detailsIcon: 'mdi mdi-playlist-edit',
            hasDetails: false,
            type: "templateGroup",
            operations: $scope.operations
        }

        $scope.templatesOptions = {
            loadingFlag: 'isTemplatesLoaded',
            listKey: "templatesList",
            listItemUsed: "isDefault",
            listItemUsedText: "Default",
            title: "Printouts",
            hasSearch: true,
            hasToggle: true,
            toggleAction: {
                setProperties: ACTIONS.SET_DEFAULT,
                isSecondary: function (item) {
                    if (item.properties.isDefault) return true; else return false;
                },
                condition: function () {
                    return true;
                },
                disabled: function (item) {
                    if (item.properties.isDefault) return true; else return false;
                },
                click: function (item) {
                    $scope.module.set_default_Template(item);
                }
            },
            parent: $scope.module,
            block: "templatesBlocked",
            warningMsg: "No roles are assigned to this module. Please add a permission to be able to continue.",
            // subtitle: { text: "", content: function (item) { if (item.properties.isDefault) return "Default"; else return ""; } },
            addButton: {
                text: "Add Printout",
                icon: "mdi-playlist-plus",
                condition: function (item) {
                    return true;
                },
                click: function (item) {
                    return $scope.module.new_Template(false, $scope.printoutIsGlobal ? null : item.properties.id);
                }
            },
            save_or_delete: "template_Action",
            detailsIcon: 'mdi mdi-playlist-edit',
            hasDetails: true,
            type: "template",
            operations: $scope.operations
        }

        $scope.fieldsListOptions = {
            loadingFlag: 'isFormsLoaded',
            listKey: "fieldsList",
            listItemUsed: "inUse",
            title: "fields",
            hasSearch: true,
            keepHeader: true,
            parent: $scope.module,
            disableDeleteCondition: function (item) { if (item.properties.inUse) return true; else return false; },
            subtitle: { text: "Type: ", content: function (item) { return "Start Form"; } },
            middleActions: [
                {
                    setProperties: ACTIONS.EDIT_PRINTOUTS,
                    condition: function (item) {
                        return true;
                    },
                    click: function (item, parent) {
                        item.switchToMiddleAction = true;
                        parent.currentItem = item;
                        $scope.templatesTab(item);
                    }
                }
            ],
            addButton: {
                text: "Add Main Form",
                icon: "mdi-table-plus",
                condition: function (parent) {
                    return parent.properties.id != -5;
                },
                click: function () {
                    return $scope.module.new_FormConfig(1/* start screen */);
                }
            },
            save_or_delete: "formConfig_Action",
            detailsIcon: 'mdi mdi-table-edit',
            hasDetails: true,
            type: "form",
            secondtype: "printouts",
            extraOptions: [
                $scope.templatesOptions,
                $scope.templatesGroupOptions
            ],
            operations: $scope.operations
        };

        $scope.formsListOptions = {
            loadingFlag: 'isFormsLoaded',
            listKey: "formsList",
            listItemUsed: "inUse",
            title: "forms",
            hasSearch: true,
            keepHeader: true,
            parent: $scope.module,
            disableDeleteCondition: function (item) { if (item.properties.inUse) return true; else return false; },
            subtitle: { text: "Type: ", content: function (item) { return "Transition Form"; } },
            addButton: {
                text: "Add Form",
                icon: "mdi-table-plus",
                condition: function (parent) {
                    return true;
                },
                click: function () {
                    return $scope.module.new_FormConfig(2/* action screen */);
                }
            },
            save_or_delete: "formConfig_Action",
            detailsIcon: 'mdi mdi-table-edit',
            hasDetails: true,
            type: "form",
            operations: $scope.operations
        };

        $scope.cardsListOptions = {
            loadingFlag: 'isFormsLoaded',
            listKey: "cardsList",
            listItemUsed: "inUse",
            title: "cards",
            hasSearch: true,
            keepHeader: true,
            parent: $scope.module,
            disableDeleteCondition: function (item) { if (item.properties.inUse) return true; else return false; },
            subtitle: { text: "Type: ", content: function () { return "Card Form"; } },
            addButton: {
                text: "Add Card",
                icon: "mdi-table-plus",
                condition: function (parent) {
                    return parent.properties.id != -5;
                },
                click: function () {
                    return $scope.module.new_FormConfig(3/* card screen */);
                }
            },
            save_or_delete: "formConfig_Action",
            detailsIcon: 'mdi mdi-table-edit',
            hasDetails: true,
            type: "card",
            operations: $scope.operations
        };

        $scope.notificationsOptions = {
            loadingFlag: 'isNotificationsLoaded',
            listKey: "notificationList",
            title: "Notifications",
            listItemUsedSecond: "useForTag",
            listItemUsedTextSecond: "assigned - tag",
            hasSearch: true,
            parent: $scope.module,
            // subtitle: { text: "Type: ", content: function (item) { return "Start Screen"; }},
            addButton: {
                text: "Add Notification",
                icon: "mdi-playlist-plus",
                condition: function (item) {
                    return true;
                },
                click: function () {
                    return $scope.module.new_Template(true);
                }
            },
            /*
            middleActions: [
                {
                    setProperties: ACTIONS.SET_TAG,
                    condition: function (item) {
                        if (item.properties.useForTag) return false; else return true;
                    },
                    disabled: function (item) {
                        if (item.properties.isUsed) return true; else return false;
                    },
                    click: function (item) {
                        $scope.module.set_tag_Template(item);
                    }
                },
                {
                    setProperties: ACTIONS.UNSET_TAG,
                    condition: function (item) {
                        if (item.properties.useForTag) return true; else return false;
                    },
                    disabled: function (item) {
                        return false;
                    },
                    click: function (item) {
                        $scope.module.unset_tag_Template(item);
                    }
                }
            ],
            */
            save_or_delete: "template_Action",
            detailsIcon: 'mdi mdi-playlist-edit',
            hasDetails: true,
            type: "template",
            operations: $scope.operations
        }
        //$scope.permissionsOptions = {
        //    loadingFlag: 'isPermissionsLoaded',
        //    listKey: "permissionsList",
        //    title: "permissions",
        //    hasSearch: true,
        //    parent: $scope.module,
        //    subtitle: {
        //        text: "Operations: ",
        //        content: function (item) { return item.properties.operationNames; }
        //    },
        //    addButton: {
        //        text: "Add Permission",
        //        icon: "mdi-eye-plus-outline",
        //        condition: function (item) {
        //            return true;
        //        },
        //        click: function () {
        //            return $scope.module.new_Permission();
        //        }
        //    },
        //    save_or_delete: "permission_Action",
        //    operations: $scope.operations
        //}
        $scope.relationsOptions = {
            loadingFlag: 'isRelationsLoaded',
            listKey: "relationsList",
            title: "relations",
            listItemUsed: "inUse",
            listItemUsedText: "in Use",
            hasSearch: true,
            parent: $scope.module,
            name: function (item) {
                return item.properties.parentEntityId;
            },
            hasAdditionalScreens: true,
            subtitle: {
                text: "Relation Type:",
                content: function (item) { return item.properties.typeName || '-'; },
                secondLine: function (item) {
                    return '<br/> <strong class="warningBorderBox" style="font-size: 12px; padding: 6px 6px 2px; margin: 6px 0 4px;">'
                        + item.properties.parentName
                        + ' <i class="mdi mdi-arrow-right" style="font-size: 12px;"></i> ' + item.properties.childName + "</strong>";
                }
            },
            addButton: {
                text: $scope.module.properties.id == -3 ? "Add Payment Relation" : "Add New",
                icon: "mdi-plus",
                condition: function (item) {
                    return true;
                },
                items: $scope.module.properties.id != -3 ? [
                    {
                        name: "Relation",
                        click: function (parent) {
                            return $scope.module.new_Relation(1);
                        }

                    },
                    {
                        name: "Payment Relation",
                        click: function (parent) {
                            return $scope.module.new_Relation(2);
                        }

                    }
                ] : null,
                click: $scope.module.properties.id == -3 ? function () {
                    return $scope.module.new_Relation(2);
                } : null
            },
            additionalFormAction: {
                setProperties: ACTIONS.SWAP,
                condition: function (item, parent) {
                    return !item.properties.id && item.properties.relationType != 2 ? true : false;
                },
                click: function (item, parent) {
                    item.swapParent(item, parent);
                }
            },
            actionsBeforeDefault: [
                {
                    setProperties: ACTIONS.EDIT_CURRENCY,
                    condition: function (item) {
                        return item.properties.partialPayment == true ? true : false;
                    },
                    click: function (item) {
                        $scope.module.isBusy = true;
                        item.createPPForm();
                    }
                },
                {
                    setProperties: ACTIONS.ADD_CURRENCY,
                    condition: function (item) {
                        return item.properties.partialPayment == false ? true : false;
                    },
                    click: function (item) {
                        $scope.module.isBusy = true;
                        item.createPPForm();
                    }
                },
                {
                    setProperties: ACTIONS.REMOVE_CURRENCY,
                    condition: function (item) {
                        return item.properties.partialPayment ? true : false;
                    },
                    disabled: function (item) {
                        return false;
                    },
                    click: function (item) {
                        item.removePP()
                            .then(function () {
                                $scope.module.get_RelationsList();
                            })
                            .catch(function (e) {

                            })
                    }
                }

            ],

            save_or_delete: "relation_Action",
            operations: $scope.operations
        }

        $scope.signaturesOptions = {
            loadingFlag: 'isSignaturesLoaded',
            listKey: "signaturesList",
            title: "signatures",
            hasSearch: true,
            parent: $scope.module,
            name: function (item) {
                return 'no name ';
            },
            addButton: {
                text: "Add Signature",
                icon: "mdi-plus",
                condition: function (item) {
                    return true;
                },
                click: function () {
                    return $scope.module.new_Signature();
                }
            },
            save_or_delete: "signature_Action",
            operations: $scope.operations
        }

        $scope.transitionGroupsOptions = {
            loadingFlag: 'isTransitionGroupsLoaded',
            listKey: "transitionGroupsList",
            title: "transitions groups",
            hasSearch: true,
            parent: $scope.module,
            name: function (item) {
                return item.properties.name;
            },
            addButton: {
                text: "Add Transitions Group",
                icon: "mdi-plus",
                condition: function (item) {
                    return true;
                },
                click: function () {
                    return $scope.module.new_TransitionGroup();
                }
            },
            save_or_delete: "transitionGroup_Action",
            keepFormOnSave: true,
            operations: $scope.operations
        }

        $scope.presetsOptions = {
            loadingFlag: 'isPresetsLoaded',
            listKey: "presetsList",
            title: "presets",
            hasSearch: true,
            parent: $scope.module,
            name: function (item) {
                return item.properties.name;
            },
            subtitle: {
                text: "Document No:",
                content: function (item) { return item.properties.moduleDocumentNumber + '<span> Contract Type: </span>' + item.properties.typeName || 'Auto'; },
                //secondLine: function (item) {
                //    return '<br/> <strong>'

                //        + "</strong>";
                //}
            },
            addButton: {
                text: "Add Preset",
                icon: "mdi-plus",
                condition: function (item) {
                    return true;
                },
                click: function () {
                    return $scope.module.new_Preset();
                }
            },
            detailsIcon: 'mdi mdi-table-edit',
            hasDetails: true,
            type: "preset",
            save_or_delete: "preset_Action",
            operations: $scope.operations
        }

        $scope.presetsTypesOptions = {
            loadingFlag: 'isPresetsTypesLoaded',
            listKey: "list",
            listItemUsed: "inUse",
            title: "types",
            hasSearch: true,
            parent: $scope.module,
            disableDeleteCondition: function (item) {
                if (item.properties.inUse) {
                    return true;
                }
                else {
                    return false;
                }
            },
            keepParentBusyTillClose: true,
            // subtitle: { text: "Type: ", content: function (item) { return "Comment"; } },
            addButton: {
                text: "Add Type",
                icon: "mdi-plus",
                condition: function (item) {
                    return true;
                },
                click: function () {
                    return $scope.module.new_PresetType();
                }
            },
            save_or_delete: "presetsTypes_Action",
            operations: $scope.operations

        }

        $scope.globalTransitionsOptions = {
            loadingFlag: 'isTransitionsLoaded',
            listKey: "transitions",
            title: "global transitions",
            hasSearch: false,
            parent: function () { return $scope.module.gState; },
            subtitle: {
                text: "To state: ",
                content: function (item) {
                    if ($scope.module.lookupStates[item.properties.nextStateId])
                        return $scope.module.lookupStates[item.properties.nextStateId].properties.name;
                    else if (item.properties.nextStateId == -1) return "self";
                    else return "-";
                }
            },
            addButton: {
                text: "Add Transition",
                icon: "mdi-source-branch-plus",
                condition: function (item) {
                    return true;
                },
                items: [
                    {
                        name: "Transition to self",
                        click: function (parent) {
                            return parent.new_Transition(1);
                        }

                    },
                    {
                        name: "Transition to a state",
                        click: function (parent) {
                            return parent.new_Transition(2);
                        }

                    }
                ]
            },
            save_or_delete: "transition_Action",
            operations: $scope.operations
        }
        $scope.workflowOptions = {
            loadingFlag: 'isStatesLoaded',
            listKey: "statesList",
            title: "states",
            hasSearch: true,
            parent: $scope.module,
            disableDeleteCondition: function (item) {
                if (item.properties.default) return true;
                else return false;
            },
            block: "statesBlocked",
            hasOrder: true,
            orderAction: "sync_States",
            warningMsg: "No roles are assigned to this module. Please add a permission to be able to continue.",
            subtitle: {
                text: "Type: ",
                content: function (item) { return STATES_INFO().statusNamesLookup[item.properties.type]; }
            },
            addButton: {
                text: "Add State",
                icon: "mdi-shape-square-plus",
                condition: function (item) {
                    return true;
                },
                click: function (parent, i) {
                    return parent.new_State(i ? i.key : undefined);
                },
                items: (function () {
                    var links = [];
                    for (var i = 0; i < STATES_STATUS().statusSmallerDict.length; i++) {
                        links.push({
                            name: STATES_STATUS().statusSmallerDict[i].value,
                            icon: STATES_STATUS().statusSmallerDict[i].icon,
                            key: STATES_STATUS().statusSmallerDict[i].key,

                            click: function (parent, i) {
                                return parent.new_State(i.key);
                            }
                        })
                    }
                    return links;
                }()),

                //    $scope.module.properties.id != -3 ? [
                //    {
                //        name: "Relation",
                //        click: function (parent) {
                //            return $scope.module.new_Relation(1);
                //        }

                //    },
                //    {
                //        name: "Payment Relation",
                //        click: function (parent) {
                //            return $scope.module.new_Relation(2);
                //        }

                //    }
                //] : null,
            },
            save_or_delete: "state_Action",

            hasChildren: true,
            children: {
                isCollapsed: true,
                hasCounts: true,
                keepSmallBtn: true,
                modifiesParent: true,
                addButton: {
                    text: "Add Transition",
                    icon: "mdi-source-branch-plus",
                    condition: function (item) {
                        if (STATES_INFO().haveTransitions.indexOf(item.properties.type.toString()) == -1)
                            return false;
                        else return true;
                        // return true;
                    },
                    click: function (parent) {
                        return parent.new_Transition();
                    }
                },
                listKey: "transitions",
                title: "transitions",
                hasOrder: true,
                orderAction: "sync_Transitions",
                getListAction: "get_Transitions",
                parent: $scope.module,
                disableDeleteCondition: function (item) { if (item.properties.default || item.properties.isGlobal) return true; else return false; },
                disableEditCondition: function (item) { if (item.properties.isGlobal) return true; else return false; },
                subtitle: {
                    text: "To state: ",
                    content: function (item) {
                        if ($scope.module.lookupStates[item.properties.nextStateId]) {
                            var text = item.properties.isGlobal ? " (global transition)" : "";
                            return item.properties.nextStateName + text;

                        }

                        else return "-";
                    }
                },
                save_or_delete: "transition_Action",
                keepFormOnSave: true,
                operations: $scope.operations
            },
            operations: $scope.operations

        }

        $scope.openMenu = function ($mdMenu, ev) {
            // originatorEv = ev;
            $mdMenu.open(ev);
        };

        $scope.permissionsTab = function () {
            $scope.currentTab = 'permissions';
            if (!$scope.module.isPermissionsLoaded)
                $scope.module.permissions_init();
        }

        $scope.rolesTab = function () {
            $scope.currentTab = 'roles';
            // $scope.module.roles_init();
            if (!$scope.module.isRolesLoaded || $scope.module.rolesModuleTypeKey != $scope.module.currentType.key)
                $scope.module.roles_init();
        }

        $scope.relationsTab = function () {
            $scope.currentTab = 'relations';
            // $scope.module.relations_init();
            if (!$scope.module.isRelationsLoaded || $scope.module.relationsModuleTypeKey != $scope.module.currentType.key)
                $scope.module.relations_init();
        }

        $scope.presetsTab = function () {
            $scope.currentTab = 'presets';
            // $scope.module.relations_init();
            if (!$scope.module.isPresetsLoaded)
                $scope.module.presets_init();
        }

        $scope.transitionGroupsTab = function () {
            $scope.currentTab = 'transitionGroups';
            if (!$scope.module.isWorkflowTransitionGroupLoaded) {
                $scope.module.getWorkflowsTransitionGroups()
                    .then(function () {
                        if (!$scope.module.isTransitionGroupsLoaded)
                            $scope.module.transitionGroups_init();
                    });
            }
            else {
                if (!$scope.module.isTransitionGroupsLoaded)
                    $scope.module.transitionGroups_init();
            }
        }

        $scope.signaturesTab = function () {
            $scope.currentTab = 'signatures';
            if (!$scope.module.isWorkflowSignaturesLoaded) {
                $scope.module.getWorkflowsSignatures()
                    .then(function () {
                        if (!$scope.module.isSignaturesLoaded)
                            $scope.module.signatures_init();
                    });
            }
            else {
                if (!$scope.module.isSignaturesLoaded)
                    $scope.module.signatures_init();
            }
        }

        $scope.workflowTab = function () {
            $scope.currentTab = 'workflow';
            // Load workflow
            if (!$scope.module.isWorkflowLoaded) {
                $scope.module.getWorkflows()
                    .then(function () {
                        // After workflow, load states
                        if (!$scope.module.isStatesLoaded)
                            $scope.module.states_init();
                    });
            }
            else {
                // In case workflow is loaded and states not, load states
                if (!$scope.module.isStatesLoaded)
                    $scope.module.states_init();
            }
        }

        $scope.startScreenTab = function () {
            $scope.currentTab = 'startscreen';
            $scope.module.get_ScreensList();
        }

        $scope.fieldsListTab = function () {
            $scope.currentTab = 'fieldsList';
            if (!$scope.module.isFormsLoaded)
                $scope.module.get_FormsList();
        }

        $scope.formsListTab = function () {
            $scope.currentTab = 'formsList';
            if (!$scope.module.isFormsLoaded)
                $scope.module.get_FormsList();
        }

        $scope.cardsListTab = function () {
            $scope.currentTab = 'cardsList';
            if (!$scope.module.isFormsLoaded)
                $scope.module.get_FormsList();
        }

        $scope.screensTab = function () {
            $scope.currentTab = 'screens';
            $scope.module.get_ScreensList();
        }

        $scope.templatesTabModule = function () {
            $scope.currentTab = 'templates';
            if (!$scope.module.isTemplatesLoaded)
                $scope.module.get_TemplatesList();
            if (!$scope.module.isTemplatesGroupLoaded)
                $scope.module.get_TemplatesGroupList();
        }

        $scope.templatesTab = function (item) {
            $scope.module.get_TemplatesList(false, item.properties.id);
            $scope.module.get_TemplatesGroupList(item.properties.id);
        }

        $scope.notificationsTab = function () {
            $scope.currentTab = 'notifications';
            if (!$scope.module.isNotificationsLoaded)
                $scope.module.get_TemplatesList(true);
        }

        $scope.gridfieldsTab = function () {
            $scope.currentTab = 'gridfields';
            $scope.module.get_GridFields();
            $scope.module.set_FormulaScreens();

        }

        $scope.datalistsettingsTab = function () {
            $scope.currentTab = 'datalistsettings';
            $scope.module.get_DataListSettings();
        }

        $scope.widgetsTab = function () {
            $scope.currentTab = 'widgets';
        }

        $scope.ana = false;

        $scope.$on('tab-change', function (event, tabEvent) {
            $scope.navToIndex = null;
            if (!$scope.module.form.dirty && !$scope.module.dirtyPermissions) {
                tabEvent.accept();
                return;
            }

            // capture the index of the tab clicked
            $scope.navToIndex = tabEvent.index;
            // set the warning params
            $scope.hightlight = true;
            $scope.tabWarning = true;

            $scope.disabledBtn = true;
            // prevent tab change
            tabEvent.reject();
        });
        $scope.closingModal = false;
        $scope.revertTabInfo = function () {
            // console.error('revert params', $scope.closingModal);
            $scope.tabWarning = false;
            $scope.hightlight = false;
            $scope.disabledBtn = false;
            if ($scope.module.currentItem) {
                $scope.cancelEditDetails(true);
                return;
            }

            if (!$scope.closingModal) {
                if ($scope.module.dirtyPermissions) {
                    $scope.module.rolesList = angular.copy($scope.module.restoreRolesList);
                    $scope.module.set_permission_dirty($scope.module.rolesList);

                    if ($scope.module.isShowingRoles) $scope.module.isBusy = true;
                } else if ($scope.module.form.dirty) {
                    $scope.module.form.restore_Data();
                    $scope.module.form.set_Data($scope.module.properties);
                }

                if ($scope.navToIndex) $scope.selectedIndex = $scope.navToIndex;
            } else {
                $scope.close(true);
            }


        }
        $scope.updateTabInfo = function () {
            $scope.hightlight = false;
            $scope.actionBtnDisabled = true;
            var action;
            if ($scope.module.currentItem) action = $scope.updateItem;
            else action = $scope.save;
            action()
                .then(function (r) {
                    $scope.tabWarning = false;
                    if (!$scope.module.currentItem) {
                        if (!$scope.closingModal) {
                            if ($scope.navToIndex) $scope.selectedIndex = $scope.navToIndex;
                        } else {
                            $scope.close(true);
                        }
                    } else {
                        $scope.cancelEditDetails(true);
                        // $scope.module.isBusy = false;
                    }

                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    $scope.actionBtnDisabled = false;
                })

        }
        $scope.cancelAction = function () {
            $scope.tabWarning = false;
            $scope.hightlight = false;
            $scope.disabledBtn = false;

            if ($scope.closingModal) $scope.closingModal = false;
        }

        // module actions

        $scope.showDocNoForm = function () {
            $scope.disabledBtn = true;
            if (!$scope.module.docNoForm) $scope.module.set_documentNumber();
            $scope.isDocNoView = true;
        }
        $scope.updateDocNo = function () {
            $scope.module.update_documentNumber();

            $scope.disabledBtn = false;
            $scope.isDocNoView = false;
        }
        $scope.cancelDocNo = function () {
            $scope.disabledBtn = false;
            $scope.isDocNoView = false;
            $scope.module.docNoForm.restore_Data();
        }
        $scope.changeStatus = function () {
            $scope.disabledBtn = true;
            var action;
            if ($scope.module.disabled) {
                $scope.message = "Deactivating module...";
                action = $scope.module.disable();
            } else {
                $scope.message = "Activating module...";
                action = $scope.module.enable();
            }
            action
                .then(function (result) {
                    $scope.isModified = true;
                })
                .catch(function (e) {
                })
                .finally(function () {
                    $scope.disabledBtn = false;
                    $scope.message = "";
                })

        }
        var isRolesChanged = false;
        $scope.save = function () {
            var p = $q.defer()
            $scope.disabledBtn = true;
            if ($scope.selectedIndex == 0) {
                $scope.message = "Saving module...";
                $scope.module.save()
                    .then(function (result) {
                        $scope.isModified = true;
                        p.resolve();
                    })
                    .catch(function (e) {
                        console.error(e);
                        p.reject(e);
                    })
                    .finally(function () {
                        $scope.disabledBtn = false;
                        $scope.message = "";
                    })
                return p.promise;
            }
            else if ($scope.currentTab == 'roles') {
                $scope.message = "Saving roles...";
                $scope.module.save_assignments()
                    .then(function (result) {
                        isRolesChanged = true;
                        p.resolve();
                    })
                    .catch(function (e) {
                        console.error(e);
                        p.reject(e);
                    })
                    .finally(function () {
                        $scope.disabledBtn = false;
                        $scope.message = "";
                    })
                return p.promise;
            }
        }


        // general 


        $scope.updateItem = function () {
            var p = $q.defer()
            // $scope.disabledBtnView = true;
            if ($scope.module.currentItem.currentItem) {
                $scope.module.currentItem.currentItem.update()
                    .then(function () {
                        p.resolve();
                    })
                    .catch(function (e) {
                        p.reject();
                        console.error(e);
                    })
            }
            else {
                $scope.module.currentItem.update()
                    .then(function () {
                        p.resolve();
                    })
                    .catch(function (e) {
                        p.reject();
                        console.error(e);
                    })
            }

            return p.promise;
        }

        $scope.cancelEditDetails = function (isWarning) {

            if ($scope.module.currentItem.properties.type == 3/* card screen type */ && !$scope.module.currentItem.isDirtyExternally) {
                $scope.module.currentItem = null;
            }
            else if ($scope.module.currentItem.switchToMiddleAction && $scope.module.currentItem.currentItem && !$scope.module.currentItem.currentItem.checkDirty()) {
                $scope.module.currentItem.currentItem = null;
            }
            else if (isWarning || ($scope.module.currentItem.switchToMiddleAction && !$scope.module.currentItem.currentItem) || !$scope.module.currentItem.checkDirty()) {
                if ($scope.module.currentItem.definitionForm) {
                    $scope.module.currentItem.loadingDetails = true;
                    delete $scope.module.currentItem.definitionForm;
                } else if ($scope.module.currentItem.dashboard) {
                    $scope.module.currentItem.dashboard = [];
                    $scope.module.currentItem.entityFields = [];
                } else if ($scope.module.currentItem.screen) {
                    // 
                }
                // Set only if needed
                if ($scope.module.currentItem.switchToMiddleAction)
                    $scope.module.currentItem.switchToMiddleAction = false;
                $scope.module.currentItem = null;
            }
            else {
                $scope.hightlight = true;
                $scope.tabWarning = true;
            }
        }

        $scope.close = function (isWarning) {
            if (!$scope.module.form.dirty && !$scope.module.dirtyPermissions || isWarning) {
                var isReloadGrid = ($scope.module.dataListConfig || {}).isModified || $scope.module.isReloadInformation || isRolesChanged || $scope.module.isModified || $scope.module.anyStateRemovedApproval || $scope.module.templatesChanged || $scope.module.mainScreenModified;
                if (!$scope.isModified) $mdDialog.cancel(isReloadGrid);
                else $mdDialog.hide({ abbr: $scope.module.properties.abbreviation, 'isReloadGrid': isReloadGrid, 'isReloadModuleInformation': $scope.module.isReloadModuleInformation });

                return;
            }
            $scope.hightlight = true;
            $scope.tabWarning = true;
            $scope.closingModal = true;
        }

        $scope.duplicate = function () {
            $mdDialog.hide({ properties: $scope.module.properties })
        }


    });

