<div fxLayout="row"
     fxLayoutAlign="space-between center">
    <div>
        <h2>
            {{screen.name}}
            <span class="noteBlock">Edit form</span>
        </h2>
        <p>You can move the existing fields by dragging them on the dashboard and resize them.</p>
    </div>
    <div>
        <button mat-raised-button
                [matMenuTriggerFor]="menuCustomFields">
            <mat-icon>add</mat-icon> {{ 'MODULE_DEF_CARD.CARD_DEFINITION.ACTION_ADD_FIELD' | translate }} <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menuCustomFields="matMenu">
            <button *ngFor="let customField of customFields"
                    mat-menu-item
                    [disabled]="!isCustomFieldEnabled(customField)"
                    (click)="addCustomField(customField)">
                {{getCustomFieldLabel(customField)}}
            </button>
        </mat-menu>
    </div>
</div>
<div fxLayout="row"
     fxLayoutGap="16px"
     fxLayoutAlign="space-between end"
     class="settings-container">
    <div fxFlex="25"
         fxLayout="row"
         fxLayoutAlign="space-between end"
         class="settings-fields-header">
        <h3>{{ 'MODULE_DEF_CARD.FIELDS.HEADER' | translate }}</h3>
        <div>
            <input class="itv-legacy-input"
                   [(ngModel)]="fieldSearch"
                   [placeholder]="'MODULE_DEF_CARD.FIELDS.SEARCH_PLACEHOLDER' | translate">
        </div>
    </div>
    <div fxFlex
         fxLayout="row"
         fxLayoutAlign="space-between end"
         class="settings-cards-header">
        <h3>{{ 'MODULE_DEF_CARD.CARD_DEFINITION.HEADER' | translate }}</h3>
    </div>
</div>
<div fxLayout="row"
     fxLayoutGap="16px"
     fxLayoutAlign="space-between start">
    <div class="fields-container"
         fxFlex="25">
        <mat-list>
            <ng-container *ngFor="let field of filteredFields">
                <mat-list-item [class.system-field]="field.id<0">
                    <div class="field-container"
                         fxLayout="row"
                         fxLayoutAlign="space-between stretch">
                        <div class="field-info"
                             fxFlex
                             fxLayout="column"
                             fxLayoutAlign="space-evenly start">
                            <div class="field-desc">{{field.label}}</div>
                            <div class="field-type-desc">{{getFieldTypeDescription(field)}}</div>
                        </div>
                        <div class="field-actions"
                             fxFlex="5"
                             fxLayout="row"
                             fxLayoutAlign="end center">
                            <button *ngIf="!isFieldAdded(field)"
                                    mat-icon-button
                                    color="primary"
                                    [title]="'MODULE_DEF_CARD.FIELDS.ACTION_ADD' | translate"
                                    (click)="addField($event, field)">
                                <mat-icon>playlist_add</mat-icon>
                            </button>
                            <button *ngIf="isFieldAdded(field)"
                                    mat-icon-button
                                    color="warn"
                                    [title]="'MODULE_DEF_CARD.FIELDS.ACTION_REMOVE' | translate"
                                    (click)="removeField($event, field)">
                                <mat-icon>playlist_remove</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>
    </div>
    <div class="gridster-container"
         fxFlex>
        <gridster [options]="gristerOptions">
            <gridster-item class="gridster-item"
                           [item]="item"
                           *ngFor="let item of gridsterItems">
                <div fxLayout="row"
                     fxLayoutAlign="space-between center">
                    <div class="item-info"
                         fxLayout="column"
                         fxLayoutAlign="center start">
                        <div>{{item.field.label}}</div>
                        <div class="item-info-type-desc">{{getItemTypeDescription(item)}}</div>
                    </div>
                    <div class="item-actions"
                         [fxFlex]="item.cols > 2 ? '80px' : '40px'"
                         fxLayout="row"
                         fxLayoutAlign="end center">
                        <ng-container *ngTemplateOutlet="item.cols > 2 ? actionsLarge : actionsSmall; context: {item: item}"></ng-container>
                    </div>
                </div>
            </gridster-item>
        </gridster>
    </div>
</div>
<div class="settings-preview">
    <app-data-card-view [parts]="previewParts"></app-data-card-view>
</div>

<ng-template #actionsLarge
             let-item="item">
    <button mat-icon-button
            [title]="'MODULE_DEF_CARD.CARD_DEFINITION.ACTION_EDIT' | translate"
            (click)="editItem($event, item)">
        <mat-icon color="primary">edit</mat-icon>
    </button>
    <button mat-icon-button
            [title]="'MODULE_DEF_CARD.CARD_DEFINITION.ACTION_REMOVE' | translate"
            (click)="removeItem($event, item)">
        <mat-icon color="warn">playlist_remove</mat-icon>
    </button>
</ng-template>

<ng-template #actionsSmall
             let-item="item">
    <button #menuButton="matMenuTrigger"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [title]="'MODULE_DEF_CARD.CARD_DEFINITION.ACTION_OPTIONS' | translate">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
                (click)="menuButton.closeMenu(); editItem($event, item)">
            <mat-icon color="primary">edit</mat-icon>
            <span>{{'MODULE_DEF_CARD.CARD_DEFINITION.ACTION_EDIT' | translate}}</span>
        </button>
        <button mat-menu-item
                (click)="menuButton.closeMenu(); removeItem($event, item)">
            <mat-icon color="warn">playlist_remove</mat-icon>
            <span>{{'MODULE_DEF_CARD.CARD_DEFINITION.ACTION_REMOVE' | translate}}</span>
        </button>
    </mat-menu>
</ng-template>