import { cipo } from 'cipo';

cipo.directive("workflowentityinstance", function ($timeout) {
    return {
        restrict: "E",
        scope: {
            "entityinstance": "=",
            "percontract": "="
        },
        templateUrl: "/ng/views/directives/dynamics/workflowentityinstance.html",
        controller: function ($scope, ACTIONS) {
        
            // $scope.list = $scope.options.listKey ? $scope.parent[$scope.options.listKey] : [];
            $scope.openMenu = function ($mdMenu, ev) {
                // originatorEv = ev;
                $mdMenu.open(ev);
            };

        }

    }
});
