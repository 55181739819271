import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  FormattingType,
  FormattingValueType,
  KeyValueFormattingModel,
  KeyValueRestrictionModel,
  RestrictionType,
} from '../../models/module/fields/enums';

@Pipe({ name: 'itvNumberWithFormattings' })
export class ItvNumberWithFormattingsPipe implements PipeTransform {
  constructor(private itvNumberPipe: ItvNumberPipe) {}

  transform(value: number | string, formattings: KeyValueFormattingModel[] = [], restrictions: KeyValueRestrictionModel[] = []): string {
    const numberFormatting = (formattings || []).find(f => f.key == FormattingType.Number);
    const decimalFormatting = (formattings || []).find(f => f.key == FormattingType.Decimals);
    const showComma = !!(restrictions || []).find(f => f.key == RestrictionType.ShowThousandSeparator);
    const showCurrency = numberFormatting && numberFormatting.value == FormattingValueType.Money;
    const showPercentage = numberFormatting && numberFormatting.value == FormattingValueType.Percentage;

    let decimals = decimalFormatting ? decimalFormatting.value : 0;

    return this.itvNumberPipe.transform(value, decimals, showComma, showCurrency, showPercentage);
  }
}

@Pipe({ name: 'itvNumber' })
export class ItvNumberPipe implements PipeTransform {
  private currencySymbol = '$';

  constructor(private decimalPipe: DecimalPipe) {}

  transform(
    value: number | string,
    decimals: number = 0,
    showComma: boolean = true,
    showCurrency: boolean = false,
    showPercentage: boolean = false
  ): string {
    let ret = '';
    const numberValue: number = typeof value == 'string' ? (value as any) * 1 : value;

    const currencyText = showCurrency ? this.currencySymbol : '';
    const percentageText = showPercentage ? '%' : '';

    if (value === undefined) {
      return '';
    } else if (!showComma && !decimals) {
      ret = numberValue.toFixed(0);
    } else if (showComma && !decimals) {
      ret = this.decimalPipe.transform(numberValue, '1.0-0');
    } else if (!showComma && decimals) {
      ret = numberValue.toFixed(decimals);
    } else if (showComma && decimals) {
      ret = this.decimalPipe.transform(numberValue, `1.${decimals}-${decimals}`);
    }

    return currencyText + ret + percentageText;
  }
}
