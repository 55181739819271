import { cipo } from 'cipo';

    cipo.controller('SaveWorkflowEntityInstanceController',
        function ($state, printService, $scope, WorkflowEntityInstance, Message, rememberManagerService, URI, URL_ACTIONS, item, contractId, module, smallerFullscreen, $window, userService, screenId, $timeout, Upload, $document, $mdDialog, fileService) {
            $scope.module = module;
            var itemId = parseInt(item.itemId) || 0;
            var fromItemId = parseInt(item.fromItemId) || 0;
            var relationId = parseInt(item.relationId || 0);
            var module = module || {};
            $scope.isInitiator = item.isInitiator || false;
            $scope.smallerFullscreen = smallerFullscreen || false;

            $scope.loadInstance = function (itemId, fromItemId) {
                
                // preserve isModified if changing between revisions
                var wasModified = ($scope.entityInstance || {}).isModified || false;

                $scope.entityInstance = new WorkflowEntityInstance({
                    moduleId: module.moduleId,
                    moduleCode: module.code,
                    moduleName: module.name,
                    entityInstanceId: itemId || null,
                    fromEntityInstanceId: fromItemId || null,
                    perContract: module.perContract,
                    contractId: contractId,
                    screenId: screenId
                });
                // $scope.entityInstance.operations = item.operations;
                if (item.presetId) {
                    $scope.entityInstance.presetId = item.presetId;
                    $scope.entityInstance.get_PresetData()
                        .then(function () {
                            $scope.entityInstance.init();
                        })

                } else $scope.entityInstance.init();

                $scope.entityInstance.isModified = wasModified;
            };

            $scope.loadAnotherInstance = function (eiId, aId, aeId) {
                if ($state.current.name == 'tenant.dm.details') {
                    var params = {
                        id: $scope.module.moduleId,
                        contractId: userService.system.userdata.contractId,
                        urlActionId: aId || 0,
                        urlActionEntityId: aeId || 0,
                        entityId: eiId
                    };

                    try {
                        $state.transitionTo($state.current.name, params, {
                            reload: true,
                            inherit: true,
                            notify: true
                        });
                    }
                    catch (e) {
                        console.error(e);
                    }
                } else {
                    $scope.loadInstance(eiId, aeId);
                }
            }

            $scope.$on("$mdMenuClose", function () {
                try {
                    $scope.entityInstance.propertiesScreen.data.searchUser = '';
                } catch { }
            });

            var pagetitle = $document[0].title;
            // unused?
            Object.defineProperty($scope, 'operations', {
                get: function () { return userService.getOperationsFor(module.code) }
            });

            // Get new workflow id and after load instance
            userService.getWorkflow($scope.module.moduleId)
                .then(function() {
                    $scope.loadInstance(itemId, fromItemId);
                });

            $scope.openMenu = function ($mdMenu, ev) {
                // originatorEv = ev;
                $mdMenu.open(ev);
            };

            $scope.disabledBtn = false;

            $scope.duplicate = function () {
                $scope.loadAnotherInstance(0, URL_ACTIONS.CLONE, $scope.entityInstance.entityInstanceId);
            }

            $scope.uploadFiles = function (file, errFiles, isXer) {
                $scope.f = file;
                $scope.errFile = errFiles && errFiles[0];
                var params = "?entityInstanceId=" + $scope.entityInstance.entityInstanceId;
                var urlData = isXer ? URI.P.IMPORT_XER_FILE : URI.P.IMPORT_MS_PROJECT_FILE;
                if (file) {
                    file.upload = Upload.upload({
                        method: urlData.method,
                        url: urlData.toString() + params,
                        data: { file: file }
                    });

                        file.upload.then(function (response) {
                            $timeout(function () {
                                file.result = response.data;
                                Message.info("File imported successfully");
                                $scope.sov.get_categories();
                            });
                        }, function (response) {
                            if (response.status > 0)
                                $scope.errorMsg = response.status + ': ' + response.data;
                            Message.dberror(response);
                        }, function (evt) {
                            file.progress = Math.min(100, parseInt(100.0 *
                                evt.loaded / evt.total));
                        });
                }
            }

            $scope.printMe = function (printoutId, isClosed) {

                // Get default print or first in case printoutId is not sent
                if (!printoutId) {
                    printoutId = $scope.entityInstance.printouts.filter((printout) => printout.isPrimary).length > 0
                        ? $scope.entityInstance.printouts.filter((printout) => printout.isPrimary)[0].key
                        : $scope.entityInstance.printouts[0].key;
                }

                $scope.entityInstance.isPrintStarted = true;
                $scope.entityInstance.get_Printout(printoutId, isClosed)
                    .then(function (result) {

                        // If we downloaded the blob, then we just need to print the content
                        if (isClosed && result && result.length) {

                            $timeout(function () {
                                var iframe = document.createElement('iframe');
                                iframe.style.display = 'none';
                                iframe.src = window.URL.createObjectURL(fileService.createFile(result, '', 'application/pdf'));
                                document.body.appendChild(iframe);

                                $timeout(function () {
                                    iframe.contentWindow.print();
                                }, 400);
                            }, 0);
                        }
                        // We do a print
                        else {
                            if (!result.margin) result.margin = { marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0 };
                            else result.margin = { marginLeft: result.marginLeft || 0, marginRight: result.marginRight || 0, marginTop: result.marginTop || 0, marginBottom: result.marginBottom || 0 };

                            if ($("#section-to-print").length == 0) {
                                var div = $('<div />').appendTo('body');
                                div.attr('id', 'section-to-print');
                                div.attr('class', 'fr-view');

                            }

                            var content = '<div style="position: fixed; top: ' + result.margins.marginTop + 'in; left: ' + result.margins.marginLeft + 'in; right: ' + result.margins.marginRight + 'in; overflow: hidden; height: ' + ((result.header || {}).height + 'in' || 'auto') + ';">'
                                + ((result.header || {}).content || "")
                                + '</div><table><thead><tr><td><div class="invisible" style="height: ' + ((result.header || {}).height + 'in' || 'auto') + ';">'
                                + ((result.header || {}).height ? "" : ((result.header || {}).content || ""))
                                + '</div></td></tr></thead>'
                                + '<tbody class="forceBlock"><tr class="forceBlock"><td class="forceBlock">'
                                + (result.content || "")
                                + '</td></tr></tbody>'
                                + '<tfoot><tr><td><div class="invisible" style="height: ' + ((result.footer || {}).height + 'in' || 'auto') + ';">'
                                + ((result.footer || {}).height ? "" : ((result.footer || {}).content || ""))
                                + '</div></td></tr></tfoot></table >'
                                + '<div style="position: fixed; bottom: ' + result.margins.marginBottom + 'in; left: ' + result.margins.marginLeft + 'in; right: ' + result.margins.marginRight + 'in; overflow: hidden; height: ' + ((result.footer || {}).height + 'in' || 'auto') + ';">'
                                + ((result.footer || {}).content || "")
                                + '</div>';

                            $('#section-to-print').html(content);
                            // $("#section-to-print").prepend("<style> @font-face { font-family: 'la_belle_auroreregular'; src: url('./Content/fonts/labelleaurore-regular-webfont.woff2') format('woff2'), url('./Content/fonts/labelleaurore-regular-webfont.woff') format('woff'); font-weight: normal; font-style: normal;} </style><p>BLA</p>");

                            $("#section-to-print").css({
                                "margin-left": result.margins.marginLeft + "in",
                                "margin-right": result.margins.marginRight + "in",
                                "margin-bottom": result.margins.marginBottom + "in",
                                "margin-top": result.margins.marginTop + "in"
                            });

                            printService.setPrintLayout(result.isLandscape);

                            $timeout(function () {
                                $window.print();
                                printService.clearPrintStyle();
                                $window.onfocus = function () {
                                }

                            }, 1000);
                        }
                    })
                    .catch(function (e) {
                        console.error(e);
                        Message.dberror(e);
                    })
                    .finally(function () {
                        $scope.entityInstance.isPrintStarted = false;
                    });
            }

            $scope.close = function () {
                rememberManagerService.entityInstanceId = $scope.entityInstance.entityInstanceId;
                $document[0].title = pagetitle;
                if ($state.current.name == 'tenant.dm.details')
                    $state.go('^');
                else
                    $mdDialog.hide();
            }
    });