import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("SOV", function (CommonEntityInstance, Form, WorkflowScreen, $q, Message, Dictionaries, $timeout, TEMPLATES_TYPES,
    $window, URI, SOVCategory, userService, $http, FileSaver, Blob, Manager, ACTIONS, Functions, $mdDialog, fileService) {
    var SOV = CommonEntityInstance.extend(function (moduleId, entityInstanceId, obj = null) {
        var self = this;
        self.properties = { entityInstanceId: entityInstanceId || 0 };
        self.contractId = userService.system.userdata.contractId || 0;
        self.moduleId = moduleId;
        self.moduleCode = userService.getModuleIdentifierById(moduleId);
        self.entityInstanceId = entityInstanceId;
        self.userService = userService;
        //self.loading = true;
        //self.loadingTransitions = true;
        // self.get_Data();
        self.printouts = [];
        self.loadedCategories = false;
        self.dict = [];
        self.lookup = {};
        self.gridfields = [];
        self.currencySymbol = userService.getCurrency();
        self.coTotal = 0;
        self.wbs = [];
        self.loadedWbs = false;
        self.loadingWbs = true;
        self.toggleItems = obj.toggleItems || null;
        self.toggleSovItemView = obj.toggleSovItemView || null;
        self.importErrorsManager = undefined;
        self.importErrorsExists = false;

        // Get workflow for the module
        userService.getWorkflow(self.moduleId)
                .then(function() {
                    self.get_general_info();
                })
                .catch(function() {
                    self.get_general_info();
                });

        Object.defineProperty(self, 'grandTotal', {
            get: function () {
                var total = 0;
                if (self.dict.length)
                    for (var i = 0; i < self.dict.length; i++) {
                        total = total + self.dict[i].subTotal;
                    }
                return total;
            }
        });
        Object.defineProperty(self, 'hasImportErrors', {
            get: function () {
                return self.importErrorsManager && self.importErrorsManager.records !== 0;
            }
        });
    });


    SOV.prototype.setupApprovalForm = function () {
        var self = this;
        self.approvalContent = {
            comment: ""
        }
        self.approvalForm = new Form(self.approvalContent);
        // self.approvalForm.initializing = true;
        self.approvalForm.set_Description(
            { comment: { label: 'Comment', type: 'editor' } });
    }

    SOV.prototype.cancelApprove = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlParams = {
            approvalId: self.generalInfo.approvalId,
            contractId: userService.system.userdata.contractId,
            moduleId: self.moduleId
        };
        self[URI.MODULE_APPROVAL.CANCEL_APPROVAL.method](URI.MODULE_APPROVAL.CANCEL_APPROVAL, { url: urlParams, urltype: 'obj' })
            .then(function (result) {
                self.get_general_info();
                self.isModified = true;
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            });

        return p.promise;
    }

    SOV.prototype.approve = function (isApprove) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlParams = {
            // approvalId: self.data.approvalId,
            contractId: userService.system.userdata.contractId,
            moduleId: self.moduleId
        }
        var bodyParams = {
            id: self.generalInfo.approvalId,
            approved: isApprove ? true : false,
            comment: self.approvalContent.comment,
            // documentNumber: self.selectedDocNo ? self.selectedDocNo.key : null
        };
        self.approvalForm.loading = true;
        var dataURL = URI.SOV.SOV_DO_APPROVAL;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: bodyParams })
            .then(function (result) {
                self.get_general_info();

                self.isModified = true;

                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
                self.approvalForm.loading = false;
                // self.form.catch(e);
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    SOV.prototype.comment = function () {
        var self = this;
        var p = $q.defer();


        if (self.approvalContent.comment) {
            self.isBusy = true;
            var urlParams = {
                // approvalId: self.data.approvalId,
                contractId: userService.system.userdata.contractId,
                moduleId: self.moduleId
            }
            var bodyParams = {
                entityInstanceId: self.entityInstanceId,
                comment: self.approvalContent.comment
            };
            self.approvalForm.loading = true;
            self[URI.MODULE_APPROVAL.ADD_COMMENT.method](URI.MODULE_APPROVAL.ADD_COMMENT, { url: urlParams, urltype: 'obj', body: bodyParams })
                .then(function (result) {
                    self.get_activity_summary();
                    self.approvalContent.comment = "";
                    self.isModified = true;

                    p.resolve();
                })
                .catch(function (e) {
                    p.reject(e);
                    Message.dberror(e);

                })
                .finally(function () {
                    self.isBusy = false;
                    self.approvalForm.loading = false;
                })
        }
        else {
            Message.error("Please add comment");
            p.reject();
        }

        return p.promise;
    }


    SOV.prototype.get_general_info = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var dataURL = URI.SOV.GET_GENERAL_INFO;

        self.itemsLookup = {};
        self.isModulesLoaded = false;
        self.modulesLookup = {};
        self.modulesList = [];

        self.generalInfo = {};
        self.workflow = {
            transitions: [],
            transitionsInstancesList: [],
            pastAssignments: []
        };
        self.activityList = [];
        self.approvalsList = [];
        self.signInfo = {};
        self.loadedCategories = false;
        var params = {
            contractId: self.contractId,
            // transitionId: transitionId || 0,
            entityInstanceId: self.entityInstanceId || 0,
            // paymentPeriodId: self.selectedPeriodId,
            // moduleTypeId: (self.currentType || {}).key || self.types[0].key
        };
        self[dataURL.method](dataURL, { url: params, urltype: 'obj', body: {} })
            .then(function (r) {
                if (r) {
                    for (var key in r) {
                        if (r.hasOwnProperty(key))
                            self.generalInfo[key] = r[key];
                    }
                    self.entityInstanceId = self.generalInfo.entityInstanceId || 0;

                    self.get_RolesDict();

                    self.toggleSovItemView(r.hasWbs);
                    
                    if (r.entityInstanceId) {
                        self.get_wbs();
                        self.get_categories();
                        self.get_gridfields();
                        // self.get_co();

                        if (r.hasManualDocumentAssignment || r.isClosedState) self.get_assignments();
                        if (r.isClosedState) self.get_signers();
                        if (!r.isDraftState) self.get_assignments_history();
                        self.get_activity_summary();
                        self.get_transitionList();
                    }
                    self.operations = userService.getOperationsFor("SOV");
                    //if ((r.permissions || []).length) {
                    //    for (var j = 0; j < r.permissions.length; j++) {
                    //        Object.assign(self.operations, Permissions[r.permissions[j]]);
                    //    }
                    //}
                    self.isDraft = r.isDraftState;

                    if (self.generalInfo.approvalId && self.generalInfo.canApprove) {
                        self.setupApprovalForm();
                    }

                        self.get_PrintoutList();
                    }
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);

                p.reject(e)
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    SOV.prototype.get_assignments = function () {
        var self = this;
        var dataURL = URI.SOV.GET_ASSIGNMENT;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };
        self.assignments = {
            lookup: {}
        };
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var key in (r || {})) {
                    if (r.hasOwnProperty(key))
                        self.assignments[key] = r[key];
                }
                for (var i = 0; i < (r.manualAssignUsers || []).length; i++) {
                    self.assignments.lookup[r.manualAssignUsers[i].key] = r.manualAssignUsers[i];
                }
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            });

        return p.promise;

    }

    SOV.prototype.get_signers = function () {
        var self = this;
        var dataURL = URI.SOV.GET_SIGNERS;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };
        self.signInfo = {
            lookup: {}
        };
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var key in (r || {})) {
                    if (r.hasOwnProperty(key))
                        self.signInfo[key] = r[key];
                }
                for (var i = 0; i < (r.signers || []).length; i++) {
                    self.signInfo.lookup[r.signers[i].key || r.signers[i].id] = r.signers[i];
                }
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            });

        return p.promise;

    }

    SOV.prototype.get_assignments_history = function () {
        var self = this;
        var dataURL = URI.SOV.GET_ASSIGNMENT_HISTORY;
        self.workflow.pastAssignments = [];
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };

        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var i = 0; i < (r || []).length; i++) {
                    if (r[i].validFrom)
                        r[i].validFrom = moment(addZ(r[i].validFrom)).format(userService.formats.datetime);
                    if (r[i].validThrough)
                        r[i].validThrough = moment(addZ(r[i].validThrough)).format(userService.formats.datetime);
                }

                self.workflow.pastAssignments = r;
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            });

        return p.promise;

    }
    var addZ = function (date) {
        var ret = date || null;
        if (date && date.indexOf("Z") == -1)
            ret = date + "Z";
        return ret;
    }

    SOV.prototype.get_activity_summary = function () {
        var self = this;
        var dataURL = URI.SOV.GET_ACTIVITY_SUMMARY;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };

        self.activityList = [];
        self.isActivityLoaded = false;

        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var i = 0; i < (r.approvals || []).length; i++) {
                    if (r.approvals[i].approvedOn.charAt(r.approvals[i].approvedOn.length - 1) != 'Z') r.approvals[i].approvedOn = r.approvals[i].approvedOn + 'Z';
                    r.approvals[i].approvedOn = moment(addZ(r.approvals[i].approvedOn)).format(userService.formats.datetime);

                }
                self.approvalsList = (r || {}).approvals || [];
                for (var i = 0; i < r.actionInstanceIds.length; i++) {
                    if (r.actionInstanceIds[i].isDraft) r.actionInstanceIds[i].msg = 'The sov draft has entered the workflow';
                    self.activityList.push(r.actionInstanceIds[i]);
                    r.actionInstanceIds[i].loading = true;
                    self.get_past_transition_info(r.actionInstanceIds[i]);
                }
                //var relations = [];
                //for (var i = 0; i < (r.relations || []).length; i++) {
                //    if (r.relations[i].records > 0) relations.push(r.relations[i]);
                //}

                // self.propertiesScreen.loadManagers(relations);
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                self.isActivityLoaded = true;
            });

        return p.promise;

    }

    SOV.prototype.get_transitionList = function () {
        var self = this;
        var dataURL = URI.SOV.GET_TRANSITIONS;
        var urlParams = {
            workflowId: userService.system.workflowId,
            entityInstanceId: self.entityInstanceId || 0,
            // paymentPeriodId: self.selectedPeriodId || 0,
            contractId: self.contractId || 0
        };
        self.workflow.transitions = [];
        self.loadingTransitions = true;
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                self.workflow.transitions = r;
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                self.loadingTransitions = false;
            })

        return p.promise;

    }

    SOV.prototype.get_past_transition_info = function (actionInstance) {
        var self = this;
        var p = $q.defer();

        // self.activityList.push(actionInstance);
        var urlParams = {
            // entityInstanceId: self.entityInstanceId === 0 ? self.fromEntityInstanceId : self.entityInstanceId,
            actionInstanceId: actionInstance.key,
            contractId: userService.system.userdata.contractId,
            noFields: actionInstance.isDraft || false
        };
        var dataURL = URI.SOV.GET_TRANSITION_INSTANCE;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var key in (r || {})) {
                    if (r.hasOwnProperty(key))
                        actionInstance[key] = r[key];
                }
                if (actionInstance.createdOn.charAt(actionInstance.createdOn.length - 1) != 'Z') actionInstance.createdOn = actionInstance.createdOn + 'Z';
                actionInstance.createdOn = moment(addZ(actionInstance.createdOn)).format(userService.formats.datetime);
                if (actionInstance.fields) {
                    actionInstance.screen = new WorkflowScreen({
                        moduleId: self.moduleId,
                        entityInstanceId: self.entityInstanceId
                    });
                    actionInstance.screen.data = { fields: actionInstance.fields };
                    actionInstance.screen.setupForm(false);
                }


                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            })
            .finally(function () {

                actionInstance.loading = false;

            });


        return p.promise;
    }

    SOV.prototype.assign_User = function (u) {
        var self = this;
        var p = $q.defer();
        self.isAssigningUser = true;
        self[URI.STATE_ASSIGNMENT.ASSIGN_USER.method](URI.STATE_ASSIGNMENT.ASSIGN_USER, { url: { entityInstanceId: self.entityInstanceId, contractId: self.contractId || 0, moduleId: self.moduleId }, urltype: 'obj', body: u })
            .then(function (result) {
                //self.dataOriginalObject = result;
                p.resolve(result);
                self.assignments.assignee = self.assignments.lookup[u.key];
                Message.info('User assignment changed');
                if (result && result.length) {
                    for (var i = 0; i < result.length; i++) {

                            if (result[i].validFrom)
                                result[i].validFrom = moment(addZ(result[i].validFrom)).format(userService.formats.datetime);
                            if (result[i].validThrough)
                                result[i].validThrough = moment(addZ(result[i].validThrough)).format(userService.formats.datetime);
                        }
                    }
                    self.workflow.pastAssignments = result;

                    self.get_activity_summary();
                })
                .catch(function (e) {
                    p.reject(e);
                })
                .finally(function () { self.isAssigningUser = false; });

        return p.promise;
    }

    SOV.prototype.get_fields = function (transitionId, isAction) {
        var self = this;
        var p = $q.defer();
        var params = {
            entityInstanceId: self.entityInstanceId,
            // transitionId: transitionId,
            contractId: self.contractId,
            workflowId: userService.system.workflowId
        };

        if (isAction) params.actionId = transitionId;
        else params.transitionId = transitionId;

        var dataURL = URI.SOV.GET_FIELDS;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject();
            })
        return p.promise;
    }


    SOV.prototype.get_RolesDict = function () {
        var self = this;
        var p = $q.defer();
        self.rolesDict = [];
        var params = {
            moduleId: self.moduleId,
            contractId: self.contractId
        };
        self.rolesDictLookup = {};
        var dataURL = URI.MODULE.MODULE_ROLES_DICT;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.rolesDict = r;
                for (var i = 0; i < r.length; i++) {
                    self.rolesDictLookup[r[i].key] = r[i];
                }
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject();
            })
        return p.promise;
    }

    SOV.prototype.excludeRolesFromTransition = function (t) {
        var self = this;
        t.exclusionRoles = angular.copy(self.rolesDict);
        t.showExcludeRoles = true;
        if ((t.roleToExcludeIds || []).length) {
            for (var i = 0; i < t.exclusionRoles.length; i++) {
                if (t.roleToExcludeIds.indexOf(t.exclusionRoles[i].key) != -1)
                    t.exclusionRoles[i].isUsed = true;
            }
        }
    }

    SOV.prototype.cancelExcludeRoles = function (t) {
        var self = this;
        t.showExcludeRoles = false;
    }

    SOV.prototype.syncExcludeRoles = function (t) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE.SYNC_EXCLUDED_ROLES;
        var roleToExcludeIds = [];
        for (var i = 0; i < t.exclusionRoles.length; i++) {
            if (t.exclusionRoles[i].isUsed)
                roleToExcludeIds.push(t.exclusionRoles[i].key);
        }
        var params = {
            url: {
                moduleId: self.moduleId,
                actionInstanceId: t.actionInstanceId,
                contractId: self.contractId
            },
            body: roleToExcludeIds,
            urltype: 'obj'
        }
        self[dataURL.method](dataURL, params)
            .then(function (result) {
                t.roleToExcludeIds = roleToExcludeIds;
                t.showExcludeRoles = false;
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                p.reject(e);
            });
        return p.promise;
    }

    SOV.prototype.sign_Doc = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE_SIGNATURE.GET;
        var signId;
        self.signatureOptions = {
            sign: false
        };
        for (var i = 0; i < self.signInfo.signers.length; i++) {
            if (self.signInfo.signers[i].id == userService.system.userdata.id && !self.signInfo.signers[i].signed) {
                signId = self.signInfo.signers[i].signId;
                break;
            }
        }
        self[dataURL.method](dataURL, { id: signId })
            .then(function (result) {
                self.signatureOptions = {
                    sign: true,
                    signatureId: result.signId,
                    clientId: result.clientId,
                    url: result.signatureUrl,
                    // redirect: true,
                    isProd: result.isProd,
                    setSigned: function (sid) {
                        self.put(URI.MODULE_SIGNATURE.SET_SIGNED, { url: { id: sid }, urltype: 'obj' })
                            .then(function () { self.get_signers(); })
                            .catch(function (e) { Message.dberror(e); });
                    },
                    callback: function () {

                        return;
                    }
                };
            })
            .catch(function (e) {
                Message.dberror(e);
            });
    }

    SOV.prototype.exportsov = function (isBlank) {
        var self = this;
        self.isBusy = true;
        var p = $q.defer();
        var dataURL = URI.SOV.SOV_EXPORT;
        var urlParam = '?entityInstanceId=' + (isBlank ? 0 : self.entityInstanceId) + '&contractId=' + self.contractId;
        $http[dataURL.method](dataURL.toString() + urlParam, { responseType: "arraybuffer" })
            .then(function (r) {
                var type = r.headers('Content-Type');
                var disposition = r.headers('Content-Disposition');
                var defaultFileName = "SOV Items.xlsx";
                if (disposition) {
                    var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                    if (match[1])
                        defaultFileName = match[1];
                }
                defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
                var blob = new Blob([r.data], { type: type });
                FileSaver.saveAs(blob, defaultFileName);
                p.resolve(defaultFileName);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            });
        return p.promise;
    }

    SOV.prototype.get_categories = function () {
        var self = this;
        self.dict = [];
        self.isBusy = true;
        // self.loadingCategories = true;
        Dictionaries.SOVCategories({ moduleId: -4 }, { sovEntityInstanceId: self.entityInstanceId })
            .then(function (r) {
                var temp;
                if (r && r.length)
                    for (var i = 0; i < r.length; i++) {
                        temp = new SOVCategory(r[i]);
                        temp.contractId = self.contractId;
                        self.dict.push(temp);
                        self.lookup[temp.key] = temp;
                    }
            })
            .catch(function (e) { Message.dberror(e); })
            .finally(function () { self.loadedCategories = true; self.isBusy = false; })
    }

    SOV.prototype.reload_categories = function (isReloadManagers) {
        var self = this;
        Dictionaries.SOVCategories({ moduleId: -4 }, { sovEntityInstanceId: self.entityInstanceId })
            .then(function (r) {
                if (r && r.length)
                    for (var i = 0; i < r.length; i++) {
                        self.lookup[r[i].key].itemsCount = r[i].itemsCount;
                        self.lookup[r[i].key].subTotal = r[i].subTotal;
                        self.lookup[r[i].key].isUsed = r[i].isUsed;

                        if (isReloadManagers) if (self.lookup[r[i].key].manager) self.lookup[r[i].key].manager.loadPage();
                    }

            })
            .catch(function (e) { Message.dberror(e); console.error(e); })
            .finally(function () { self.loadingCategories = false; })
    }

    SOV.prototype.processWBSData = function () {
        var self = this;
        var p = $q.defer()

        self[URI.WBS.PROCESS_DATA.method](`${URI.WBS.PROCESS_DATA}?contractId=${self.contractId}&sovEntityInstanceId=${self.entityInstanceId}`)
            .then(function (wbs) {
                p.resolve();
            })
            .catch(function (err) {
                p.reject();
            });

        return p.promise;
    }

    SOV.prototype.get_wbs = function () {
        var self = this;
        self.wbs = [];
        self.isBusy = true;
        self.loadedWbs = false;
        self.loadingWbs = true;
        self.wbsItemCount = 0;
        self.wbsItemTotal = 0;

        self[URI.WBS.GET_WITH_CATEGORIES.method](`${URI.WBS.GET_WITH_CATEGORIES}?contractId=${self.contractId}&sovEntityInstanceId=${self.entityInstanceId}`)
            .then(function (wbs) {
                if (wbs.length) {
                    self.wbs = wbs;
                    self.wbsItemCount = self.wbs.map(x => x.sovItemCount).length;
                    self.wbsItemTotal = self.wbs.map(x => x.sovItemTotal);
                    self.toggleSovItemView(true);
                }

            })
            .catch(function (err) {
                Message.dberror(err);
            })
            .finally(function () {
                self.loadedWbs = true;
                self.loadingWbs = false;
                self.isBusy = false;
            });
    }

    SOV.prototype.reload_wbs = function (isReloadManagers) {
        var self = this;
        var uri = URI.WBS.GET_WITH_CATEGORIES;
        var expandedNodeIds = self.get_expanded_nodes(self.wbs);
        var expandedCategoryIds = self.get_expanded_categories(self.wbs);
        self.loadedWbs = false;
        self.isBusy = true;

        self[uri.method](`${uri}?contractId=${self.contractId}&sovEntityInstanceId=${self.entityInstanceId}`)
            .then(function (wbs) {
                self.wbs = wbs;
                self.set_expanded_nodes(self.wbs, expandedNodeIds, expandedCategoryIds);
            })
            .catch(function (err) {
                Message.dberror(err);
            })
            .finally(function () {
                self.loadedWbs = true;
                self.isBusy = false;
            });
    }

    SOV.prototype.get_expanded_nodes = function (nodes) {
        var self = this;
        var expandedNodeIds = [];
        nodes.forEach(node => {
            if (node.isExpanded) {
                expandedNodeIds.push(node.id);
                if (node.children) {
                    expandedNodeIds = expandedNodeIds.concat(self.get_expanded_nodes(node.children));
                }
            }
        });

        return expandedNodeIds;
    }

    SOV.prototype.get_expanded_categories = function (nodes) {
        var self = this;
        var expandedCategoryIds = [];
        nodes.forEach(node => {
            if (node.isExpanded) {
                // Check current node's categories
                if (node.categories) {
                    var expandedCategories = node.categories.filter(c => c.isExpanded);
                    expandedCategoryIds = expandedCategoryIds.concat(expandedCategories.map(x => x.categoryId));
                }

                // Loop through nested child nodes
                if (node.children) {
                    expandedCategoryIds = expandedCategoryIds.concat(self.get_expanded_categories(node.children));
                }
            }
        });

        return expandedCategoryIds;
    }

    SOV.prototype.set_expanded_nodes = function (nodes, expandedNodeIds, expandedCategoryIds) {
        var self = this;
        nodes.forEach(node => {
            node.isExpanded = expandedNodeIds.includes(node.id);
            if (node.isExpanded) {
                // Expand necessary categories
                if (node.categories) {
                    node.categories.forEach(category => {
                        if (expandedCategoryIds.includes(category.categoryId)) {
                            // This function toggles isExpanded
                            self.toggleItems(category, category.id);
                        }
                    });
                }

                // Expand child nodes/categories
                if (node.children) {
                    self.set_expanded_nodes(node.children, expandedNodeIds, expandedCategoryIds);
                }
            }
        });
    }

    SOV.prototype.get_transitions = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOV_TRANSITIONS;
        var params = { contractId: self.contractId, entityInstanceId: self.entityInstanceId };
        self.loadingTransitions = true;
        self.pastTransitions = [];
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.transitions = r.transitions;
                // self.revisions = r.revisions;
                if (r && r.pastTransitions.length) {
                    var temp;
                    for (var i = 0; i < r.pastTransitions.length; i++) {
                        temp = r.pastTransitions[i];
                        temp.screen = new WorkflowScreen();
                        temp.screen.data = { fields: temp.fields };
                        temp.screen.setupForm(false);
                        if (r.pastTransitions[i].createdOn)
                            r.pastTransitions[i].createdOn = moment(r.pastTransitions[i].createdOn).format(userService.formats.datetime);
                        self.pastTransitions.push(temp);
                    }
                }
                // self.pastTransitions = r.pastTransitions;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loadingTransitions = false;
            })

        return p.promise;
    }

    SOV.prototype.get_Data = function (transitionId) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOV_GET;
        var params = { entityInstanceId: self.entityInstanceId, transitionId: transitionId || 0, contractId: self.contractId };
        self.loading = transitionId ? false : true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                if (r && !transitionId) {
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) {
                            self.properties[key] = r[key];
                        }
                    }
                }
                if (!transitionId) if (r.entityInstanceId) self.get_transitions();
                self.userIsInitiator = r.userIsInitiator;
                if (r.entityInstanceId && !transitionId) {
                    self.get_wbs();
                    self.get_categories();
                    self.get_gridfields();
                    // self.get_co();
                }

                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }
    SOV.prototype.get_co = function () {
        var self = this;
        var all = $q.all([self.get_co_fields(), self.get_co_items()]);
        self.loadingco = true;
        all
            .then(function () {

            })
            .catch(function (e) { Message.dberror(e); })
            .finally(function () { self.loadingco = false; })

    }

    SOV.prototype.get_co_fields = function () {
        var self = this;
        var dataURL = URI.SOV.ACO_FIELDS;
        var p = $q.defer();
        var params = { contractId: self.contractId };
        self.coColumns = [
            { name: 'full_doc_num', label: 'Document No' },
            { name: 'subject', label: 'Subject' },
            { name: 'duration_days', label: 'Duration Days', rightAligned: true },
            { name: '_cost', label: 'Cost', rightAligned: true },
            { name: 'updated_on', label: 'Approved on' }
        ];
        self.dcoColumns = [
            { name: 'full_doc_num', label: 'Document No' },
            { name: 'subject', label: 'Subject' },
            { name: 'duration_days', label: 'Duration Days', rightAligned: true },
            { name: '_cost', label: 'Cost', rightAligned: true },
            { name: 'updated_on', label: 'Approved on' }
        ];

        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
            })
        return p.promise;
    }
    SOV.prototype.get_co_items = function () {
        var self = this;
        var dataURL = URI.SOV.ACO_SEARCH;
        var p = $q.defer();

        var params = { contractId: self.contractId };
        var formatMoney = function (amount) {
            if (amount) {
                var sign = amount > 0 ? self.currencySymbol : "-" + self.currencySymbol;
                var noString = Math.abs(amount).toFixed(2).toString();
                var decimal = noString.slice(-3);
                noString = noString.slice(0, -3);
                noString = noString.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                return sign + noString + decimal;

            }
            else return self.currencySymbol + "0.00";
        }
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.coTotal = 0;
                self.coItems = [];
                self.dcoItems = [];
                for (var i = 0; i < r.data.length; i++) {
                    self.coTotal = self.coTotal + r.data[i].cost;
                    if (r.data[i].updated_on) r.data[i].updated_on = moment(r.data[i].updated_on).format(userService.formats.datetime);
                    r.data[i]._cost = formatMoney(r.data[i].cost);
                    if (r.data[i].cost < 0) r.data[i].isDanger = true;
                    self.coItems.push(r.data[i]);
                    /*if (parseFloat(r.data[i].cost) > 0) self.coItems.push(r.data[i]);
                    else self.dcoItems.push(r.data[i]);*/
                }
                // self.coItems = r.data;
                p.resolve();
            })
            .catch(function (e) {
                p.reject();
                Message.dberror(e);
            })
        return p.promise;
    }

    SOV.prototype.get_gridfields = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOVI_FIELDS;
        var params = { contractId: self.contractId, workflowId: userService.system.workflowId };
        //self.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.gridfields = r.fields;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                //self.loading = false;
            })

        return p.promise;
    }

    SOV.prototype.create = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOV_CREATE;
        var params = { contractId: self.contractId, workflowId: userService.system.workflowId };
        self.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                if (r) {
                    self.entityInstanceId = r;
                }
                self.get_general_info();
                Message.info("SOV created successfully.")
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    SOV.prototype.save = function (transitionId, roleId, isRevise) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOV_SAVE;
        if (isRevise) dataURL = URI.SOV.SOV_REVISE;
        var params = { contractId: self.contractId, transitionId: transitionId, entityInstanceId: self.entityInstanceId, roleId: roleId, workflowId: userService.system.workflowId };
        self.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj', body: {} })
            .then(function (r) {

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
                self.isBusy = false;
            })
            .finally(function () {
                self.loading = false;

            })

        return p.promise;
    }

    SOV.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOV_DELETE;
        var params = { contractId: self.contractId, entityInstanceId: self.entityInstanceId };
        self.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                Message.info("SOV deleted successfully.")
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    SOV.prototype.get_itemDetails = function (i) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.SOVI_GET;
        var params = { contractId: self.contractId, entityInstanceId: i ? i.entity_instance_id : 0 };
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

        SOV.prototype.printDocument = function (id, isClosed) {
            var self = this;
            var p = $q.defer();
            self.isPrintStarted = true;
            var dataURL = isClosed ? URI.MODULE.GET_INSTANCES_AS_PDF : URI.TEMPLATES.PREVIEWS;
            var params = {
                url: {
                    id: id,
                    moduleId: self.moduleId,
                    contractId: self.contractId
                },
                body: { list: [self.entityInstanceId] },
                urltype: 'obj'
            }
            self[dataURL.method](dataURL, params)
                .then(function (result) {

                    // If we downloaded the blob, then we just need to print the content
                    if (isClosed && result && result.length) {
                        $timeout(function () {
                            var iframe = document.createElement('iframe');
                            iframe.style.display = 'none';
                            iframe.src = window.URL.createObjectURL(fileService.createFile(result, '', 'application/pdf'));
                            document.body.appendChild(iframe);

                            $timeout(function () {
                                iframe.contentWindow.print();
                            }, 400);
                        }, 0);
                    }
                    // We do a print
                    else {
                        if (result && result.length) {
                            if ($("#section-to-print").length == 0) {
                                var div = $('<div />').appendTo('body');
                                div.attr('id', 'section-to-print');
                                div.attr('class', 'fr-view');
                            }
                            var margin = {};
                            if (!result[0].margins) margin = { marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0 };
                            else margin = { marginLeft: result[0].margins.marginLeft || 0, marginRight: result[0].margins.marginRight || 0, marginTop: result[0].margins.marginTop || 0, marginBottom: result[0].margins.marginBottom || 0 };
                            $("#section-to-print").css({
                                "margin-left": margin.marginLeft + "in",
                                "margin-right": margin.marginRight + "in",
                                "margin-bottom": margin.marginBottom + "in",
                                "margin-top": margin.marginTop + "in"
                            });
                            var content = "";
                            for (var i = 0; i < result.length; i++) {

                                content += '<div style="position: fixed; top: ' + result[i].margins.marginTop + 'in; left:  ' + result[i].margins.marginLeft + 'in; right:  ' + result[i].margins.marginRight + 'in;  overflow: hidden; height: ' + ((result[i].header || {}).height + 'in' || 'auto') + ';">'
                                    + ((result[i].header || {}).content || "")
                                    + '</div><table><thead><tr><td><div class="invisible" style="height: ' + ((result[i].header || {}).height + 'in' || 'auto') + ';">'
                                    + ((result[i].header || {}).height ? "" : ((result[i].header || {}).content || ""))
                                    + '</div></td></tr></thead>'
                                    + '<tbody class="forceBlock"><tr class="forceBlock"><td class="forceBlock">'
                                    + (result[i].content || "")
                                    + '</td></tr></tbody>'
                                    + '<tfoot><tr><td><div class="invisible" style="height: ' + ((result[i].footer || {}).height + 'in' || 'auto') + ';">'
                                    + ((result[i].footer || {}).height ? "" : ((result[i].footer || {}).content || ""))
                                    + '</div></td></tr></tfoot></table >'
                                    + '<div style="position: fixed; bottom: ' + result[i].margins.marginBottom + 'in; left:  ' + result[i].margins.marginLeft + 'in; right:  ' + result[i].margins.marginRight + 'in; overflow: hidden; height: ' + ((result[i].footer || {}).height + 'in' || 'auto') + ';">'
                                    + ((result[i].footer || {}).content || "")
                                    + '</div>';

                                if (i != result.length - 1) content += "<div style='page-break-after: always;'></div>";

                            }
                            $('#section-to-print').html(content);
                            $timeout(function () {
                                $window.print();
                            }, 400)
                        }
                        else {
                            Message.dberror('No print document found');
                        }
                    }
                })
                .catch(function (e) {
                    Message.dberror(e);
                })
                .finally(function () {
                    self.isPrintStarted = false;
                    //$scope.manager.loadPage();
                });
        }

        SOV.prototype.get_PrintoutList = function () {
            var self = this;
            var p = $q.defer();

            if (self.entityInstanceId) {
                Dictionaries.UserTemplates({ moduleId: self.moduleId }, { contractId: self.contractId, typeName: TEMPLATES_TYPES[1], screenId: self.generalInfo.screenId })
                    .then(function (result) {
                        if (result) self.printouts = result;
                        p.resolve();
                    })
                    .catch(function (e) {
                        p.reject(e);
                    });
            } else {
                p.resolve();
            }

            return p.promise;
        }

    SOV.prototype.downloadPdfs = function () {
        var self = this;
        var p = $q.defer();
        var list = [self.entityInstanceId];
        self.isPrintStarted = true;
        var urlparams = {
            moduleId: self.moduleId,
            contractId: self.contractId
        }
        var saveByteArray = function (reportName, byte) {
            var blob = new Blob([byte], { type: "application/pdf" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = reportName;
            link.download = fileName;
            link.click();
        };

        // $scope.manager.loading = true;
        //var d = dialogs.confirm('Deactivate User', 'Are you sure you want to deactivate the selected User(s)?');
        self[URI.MODULE.GET_INSTANCES_AS_PDF.method](URI.MODULE.GET_INSTANCES_AS_PDF, { url: urlparams, urltype: 'obj', body: { list: list } })
            .then(function (result) {
                var contractNo = self.userService.system.contractsLookup[self.userService.system.userdata.contractId].contractNo;
                var name = "Schedule of Values" + '-' + contractNo;
                var sampleArr = Functions.base64ToArrayBuffer(result);
                saveByteArray(name, sampleArr);

            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                // $scope.manager.loading = false;
                self.isPrintStarted = false;
            });
    }


    // unused?!
    SOV.prototype.pp_get = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.PP_GET;
        var params = { contractId: self.contractId, transitionId: 0, entityInstanceId: 0, paymentPeriodId: 0 };
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                p.resolve(r);
                // self.pp_create(r.id);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    SOV.prototype.pp_create = function (id) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SOV.PP_CREATE;
        var params = { contractId: self.contractId, paymentPeriodId: id, workflowId: userService.system.workflowId };
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    SOV.prototype.undoLastAction = function (t) {
        var self = this;
        CommonEntityInstance.commonUndoLastAction(t, 'SOV', self.contractId, self.moduleId).then(function() {
            // console.log('undo done');
            self.get_general_info();
        })
    }

    // import

    SOV.prototype.openImportEntitiesDialog = function () {
        var self = this;

            $mdDialog.show({
                locals: { 
                    moduleId: -5, 
                    moduleCode: 'SOVI',
                    importOptions: {
                        fieldsForImportDatasource: 'fieldsForImportSOV',
                        moduleIdForStates: -4,
                        importUrlData: URI.SOV.IMPORT_DOCS,
                        entityInstanceId: self.entityInstanceId,
                        closeOnSuccess: true,
                        closeOnError: true,
                    } 
                },
                controller: 'importEntitiesController',
                templateUrl: '/ng/controllers/files/importEntities.html',
                parent: angular.element(document.body),
                fullscreen: true,
                focusOnOpen: false,
                multiple: false,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (output) {
                    self.handleInportOutput(output);
                }, function (output) {
                    self.handleInportOutput(output);
                });
        }

        SOV.prototype.handleInportOutput = function (output) {
            var self = this;

            self.resetImportErrors();
            
            if (output.importError) {
                self.getImportErrors().then(function() {
                    self.get_general_info();
                });
            } 
            else {
                self.get_general_info();
            }
        }

        SOV.prototype.resetImportErrors = function () {
            this.importErrorsManager = undefined;
            this.importErrorsExists = false;
        }

    SOV.prototype.getImportErrors = function () {
        var self = this;
        var p = $q.defer();
        self.loadImportErrors().then(function (errors) {
            self.importErrorsExists = false;
            if (errors && errors.records) {
                self.showImportErrors(errors.data);
                self.importErrorsExists = true;
            }
            p.resolve();
        });
        return p.promise;
    }

    SOV.prototype.loadImportErrors = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        self[URI.SOV.SOV_ERRORS.method](URI.SOV.SOV_ERRORS, { url: { entityInstanceId: self.entityInstanceId }, urltype: 'obj' })
        .then(function (result) {
            p.resolve(result);
        })
        .catch(function (e) {
            console.error(e);
            Message.dberror(e);
            p.reject(e);
        })
        .finally(function () {
            self.isBusy = false;
        });
        return p.promise;
    }

    SOV.prototype.showImportErrors = function (errors) {
        var self = this;

            self.importErrorsManager = new Manager({
                rows: errors,
                objectsPerPage: 0,
                search: false,
                hideFilters: true,
                options: {
                    multiselect: false
                },
                menuClass: "inlineManager",
                rowOnClick: function () { },
                dataWrapper: 'data',
                parseData: function (data, columns) {
                    return (data || []).map(d => ({
                        row: d.row,
                        error: d.values || d.error
                    }));
                },
                leftActions: [],
                actions: [],
                hasActionBar: false,
            });
            self.importErrorsManager.set_Columns([
                {
                    name: 'row',
                    label: 'Row',
                    type: 'text',
                    typeId: 1,
                    fieldTypeId: 1,
                    onTablet: true,
                    onPhone: false,
                    width: 10
                },
                {
                    name: 'error',
                    label: 'Error',
                    type: 'text',
                    typeId: 1,
                    fieldTypeId: 1,
                    onTablet: true,
                    onPhone: true,
                    width: 90
                },
            ]);
            self.importErrorsManager.loading = false;
        }

    return SOV;
});
