import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScreenFieldModel } from '../../../models/module/fields/main';
import { GridViewModel } from '../../../models/module/grid';
import { ScreenTypeEnum } from '../../../models/module/screen';
import { HttpService } from '../../../shared/services/http.service';

@Injectable()
export class DataCardsViewService {
  constructor(private httpService: HttpService) {}

  getScreenFields(): Observable<GridViewModel<ScreenFieldModel[]>> {
    return this.httpService.post<GridViewModel<ScreenFieldModel[]>>(`_api/ScreenFields/Search?screenType=${ScreenTypeEnum.Card}`, {});
  }
}
