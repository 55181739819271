import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("Report", function ($q, Model, URI, Functions, userService, Message, $timeout, $window, Form) {
    var fieldsLookup;
    var createLookup = function (items) {
        var lookup = {};
        for (var i = 0; i < items.length; i++) {
            lookup[items[i].id] = items[i];
        }
        return lookup;
    }

    var Report = Model.extend(function (obj, fields) {
        var self = this;            

        // var obj = obj ? angular.copy(obj) : null;
        if (fields) self.fields = angular.copy(fields);

        self.backup = {
            obj: angular.copy(obj),
            fields: angular.copy(fields),
            filters: {}
        }
        

        fieldsLookup = createLookup(self.fields);

        self.filtersInfo = { moduleId: obj.moduleId, savedFilters: null };

        self.selectedFields = [];

        self.properties = { name: "", isGlobal: true, isOwn: true };

        if (obj) {
            for (var key in obj) {
                if(obj.hasOwnProperty(key))
                    self.properties[key] = obj[key];
            }

            if ((obj.fields || []).length) {
                for (var i = 0; i < obj.fields.length; i++) {
                    for (var key in obj.fields[i]) {
                        if (obj.fields[i].hasOwnProperty(key)) {
                            (fieldsLookup[obj.fields[i].id] || {})[key] = obj.fields[i][key];
                        }
                    }
                }
                self.selectedFields = obj.fields;
            }
        }

        if (self.properties.id && self.properties.hasFilters) self.get_Data();
        else self.isDetailsLoaded = true;
        
        self.isModified = false;
        self.createForm();
        // self.get_Data();

    });

    Report.prototype.reinit = function (selectedFields) {
        var self = this;
        fieldsLookup = createLookup(self.fields);
        if ((selectedFields || []).length) {

            for (var i = 0; i < self.fields.length; i++) {
                self.fields[i].selected = false;
            }

            for (var i = 0; i < selectedFields.length; i++) {
                // avoid bad data
                if (fieldsLookup[selectedFields[i].id]) {
                    fieldsLookup[selectedFields[i].id].selected = true;
                    for (var key in selectedFields[i]) {
                        if (selectedFields[i].hasOwnProperty(key)) {
                            fieldsLookup[selectedFields[i].id][key] = selectedFields[i][key];
                        }
                    }
                } else {
                    Message.warning('The field ' + selectedFields[i].label + ' is no longer available');
                }
                
            }
            self.selectedFields = angular.copy(selectedFields);
        }
    }

    var processFiltersFromArrayToMapping = function (filters) {
        var savedFilters = null;
        // change arrays into mapping by id
        if (filters) {
            var reportFilters = angular.copy(filters);
            var savedFilters = { overallLogic: 0, filters: {} };
            savedFilters.overallLogic = reportFilters.logic;
            // the key for missing dataSources
            var key = -100001;

            for (var i = 0; i < reportFilters.filters.length; i++) {
                for (var j = 0; j < reportFilters.filters[i].filters.length; j++) {
                    if (reportFilters.filters[i].filters[j].field) {
                        savedFilters.filters[reportFilters.filters[i].filters[j].field] = {
                            operator: reportFilters.filters[i].filters[j].operator,
                            value: reportFilters.filters[i].filters[j].value,
                            logic: reportFilters.filters[i].filters[j].logic,
                            dataSource: reportFilters.filters[i].filters[j].dataSource,
                        }
                    } else {
                        // multiselects
                        for (var k = 0; k < reportFilters.filters[i].filters[j].filters.length; k++) {
                            // add the dataSource if missing
                            if (!reportFilters.filters[i].filters[j].filters[k].dataSource || !(reportFilters.filters[i].filters[j].filters[k].dataSource || [])[0]) {
                                reportFilters.filters[i].filters[j].filters[k].dataSource = [{
                                    value: reportFilters.filters[i].filters[j].filters[k].value,
                                    key: key
                                }];

                                key--;
                            }

                            if (!savedFilters.filters[reportFilters.filters[i].filters[j].filters[k].field]) {
                                savedFilters.filters[reportFilters.filters[i].filters[j].filters[k].field] = {
                                    operator: reportFilters.filters[i].filters[j].filters[k].operator,
                                    value: [reportFilters.filters[i].filters[j].filters[k].dataSource[0].key],
                                    logic: reportFilters.filters[i].filters[j].filters[k].logic,
                                    dataSource: reportFilters.filters[i].filters[j].filters[k].dataSource,
                                }
                            } else {
                                savedFilters.filters[reportFilters.filters[i].filters[j].filters[k].field].value.push(reportFilters.filters[i].filters[j].filters[k].dataSource[0].key);
                                savedFilters.filters[reportFilters.filters[i].filters[j].filters[k].field].dataSource.push(reportFilters.filters[i].filters[j].filters[k].dataSource[0]);
                            }
                        }
                    }
                }
            }
        }


        return savedFilters;
    }

    Report.prototype.setPrimarySort = function (item) {
        var self = this;
        for (var i = 0; i < self.selectedFields.length; i++) {
            if (self.selectedFields[i].id != item.id) {
                self.selectedFields[i].isPrimarySort = false;
                self.selectedFields[i].isDescending = false;
            }
        }
        if (item.isPrimarySort) item.isDescending = !item.isDescending;
        else item.isPrimarySort = true;
    }

    Report.prototype.resetFilters = function () {
        var self = this;
        self.isExpandFilters = false;
        self.filtersInfo.savedFilters = angular.copy(self.backup.filters);
        
        $timeout(function () {
            self.isExpandFilters = true;
        }, 0)

    }

    Report.prototype.applyFilters = function () {
        var self = this;
        if (self.filtersInfo.filters) {
            var filters = { logic: self.filtersInfo.filters.overallLogic, filters: [] };
            for (var i = 0; i < self.filtersInfo.filters.filters.length; i++) {
                if (self.filtersInfo.filters.filters[i].hasValue) {
                    // 
                    if (self.filtersInfo.filters.filters[i].type == "multiselect") {
                        // multiselect
                        var multiselectFilters = [];
                        for (var j = 0; j < self.filtersInfo.filters.filters[i].value.length; j++) {
                            multiselectFilters.push({
                                logic: 1,
                                filters: [{
                                    field: self.filtersInfo.filters.filters[i].field,
                                    operator: self.filtersInfo.filters.filters[i].operator,
                                    options: (self.filtersInfo.filters.filters[i].options || {}).format,
                                    value: self.filtersInfo.filters.filters[i].dataSourceLookup[self.filtersInfo.filters.filters[i].value[j]].value,
                                    fieldTypeId: self.filtersInfo.filters.filters[i].typeId,
                                    dataSource: [self.filtersInfo.filters.filters[i].dataSourceLookup[self.filtersInfo.filters.filters[i].value[j]]]
                                }]

                            })
                        }

                        filters.filters.push({ logic: 1, filters: multiselectFilters });

                    } else {

                        var value;
                        if (Object.prototype.toString.call(self.filtersInfo.filters.filters[i].value) == '[object Array]') {
                            // is date and between operator
                            value = [];
                            for (var j = 0; j < self.filtersInfo.filters.filters[i].value.length; j++) {
                                value.push((self.filtersInfo.filters.filters[i].value[j]).format(self.filtersInfo.filters.filters[i].options.format));
                            }
                        } else value = self.filtersInfo.filters.filters[i].value;

                        filters.filters.push({
                            logic: self.filtersInfo.filters.overallLogic,
                            filters: [{
                                field: self.filtersInfo.filters.filters[i].field,
                                operator: self.filtersInfo.filters.filters[i].operator,
                                options: (self.filtersInfo.filters.filters[i].options || {}).format,
                                value: value,
                                fieldTypeId: self.filtersInfo.filters.filters[i].typeId
                            }]

                        })
                    }
                }
            }

            self.currentFilters = filters;
            self.filtersInfo.savedFilters = processFiltersFromArrayToMapping(filters);
        }
        
    }

    Report.prototype.saveFilters = function () {
        var self = this;
        var p = $q.defer();
        self.applyFilters();
        var urlData = URI.MODULE_REPORTS.SAVE_FILTERS;
        self.isBusy = true;
        
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj', body: [self.currentFilters] }, { headers: { moduleId: self.properties.moduleId } })
            .then(function (r) {
                Message.info("Report updated successfully.");
                p.resolve();

                self.isModifiedFilters = true;
            })
            .catch(function (e) { p.reject(); Message.dberror(e); })
            .finally(function (e) { self.isBusy = false; })
        return p.promise;
    }

    Report.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        self.form.initializing = true;
        
        //create form
        var form = {
            name: { label: 'Name', type: 'text', validation: { required: true } },
            isGlobal: { label: 'Available for all users', type: 'checkbox' }
        };

        if (!self.properties.isOwn) form.isGlobal.editMode = false;
        
        self.form.set_Description(form);

        var grid1 = [
            { name: 50, isGlobal: 50 },
        ];
        self.form.setTemplate('grid', grid1);
        self.form.store_Data();

        self.form.initializing = false;
    }

    

    Report.prototype.get_Data = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.MODULE_REPORTS.GET;
        self.isBusy = true;
        self[urlData.method](urlData, { url: { id: self.properties.id, contractId: userService.system.userdata.contractId }, urltype: "obj" }, { headers: { moduleId: self.properties.moduleId } })
            .then(function (r) {
                var savedFilters = null;


                for (var key in r) if (r.hasOwnProperty(key)) self.properties[key] = r[key];

                if (r.filters && r.filters.length)
                    self.filtersInfo.savedFilters = processFiltersFromArrayToMapping(r.filters[0]);

                self.backup.filters = self.filtersInfo.savedFilters ? angular.copy(self.filtersInfo.savedFilters) : null;
                self.isDetailsLoaded = true;

                p.resolve();
            })
            .catch(function (e) { p.reject(); Message.dberror(e); console.error(e) })
            .finally(function (e) { self.isBusy = false; })
        return p.promise;
    }

    Report.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        var urlData = self.properties.id ? URI.MODULE_REPORTS.EDIT : URI.MODULE_REPORTS.ADD;
        self.form.validate();
        if (self.form.isValid) {
            self.isBusy = true;
            self[urlData.method](urlData, { url: {}, body: self.properties }, { headers: { moduleId: self.properties.moduleId } })
                .then(function (r) { Message.info("Report saved successfully."); p.resolve(); if (r) self.properties.id = r; self.isModified = true; })
                .catch(function (e) { p.reject(e); Message.dberror(e); })
                .finally(function (e) { self.isBusy = false; });
        } else {
            p.reject();
        }
        return p.promise;
    }

    var processSelectedFields = function (fields) {
        var fields = fields ? angular.copy(fields) : [];
        for (var i = 0; i < fields.length; i++) {
            fields[i].position = i;
            fields[i].priority = 3;
        }
        return fields;
    }

    Report.prototype.syncFields = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.MODULE_REPORTS.SYNC_FIELDS;
        self.isBusy = true;
        
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj', body: processSelectedFields(self.selectedFields) }, { headers: { moduleId: self.properties.moduleId } })
            .then(function (r) { p.resolve(); Message.info("Report updated successfully."); self.isModified = true; })
            .catch(function (e) { p.reject(); Message.dberror(e); })
            .finally(function (e) { self.isBusy = false; })

        return p.promise;
    }

    var processDataReport = function (data, columns) {
        var columnsLookup = {}, retval=[];
        for (var j = 0; j < columns.length; j++) {
            columnsLookup[columns[j].id] = columns[j];
        }

        for (var i = 0; i < data.length; i++) {
            data[i]._is_draft = data[i].is_draft;
            data[i]._in_my_court = data[i].in_my_court;
            if (data[i].needs_approval) data[i].state_name = 'In Approval';
            if (data[i].entity_template_id && !data[i].entity_instance_id) {
                data[i].state_name = 'Preset';
                // data[i].state_color = '#fff';
                data[i].state_text_color = '#1ab393';
            }


            for (var key in data[i]) {
                if (data[i].hasOwnProperty(key) && typeof columnsLookup[key] != 'undefined') {
                    if (columnsLookup[key].typeId == 3)
                        data[i][key] = ([true, false].indexOf(data[i][key]) != -1) ? (data[i][key] ? "Yes" : "No") : data[i][key] || "-";
                    //date
                    else if (columnsLookup[key].typeId == 4 && data[i][key]) {
                        var format = userService.formats.datetime;
                        if (columnsLookup[key].restrictionsLookup[5] == 5) {
                            format = userService.formats.time;
                            data[i][key] = moment(addZ(data[i][key])).format(format);
                        } else if (columnsLookup[key].restrictionsLookup[5] == 3) {
                            format = userService.formats.date;
                            data[i][key] = moment.utc(addZ(data[i][key])).format(format);
                        } else {
                            data[i][key] = moment(addZ(data[i][key])).format(format);
                        }
                    }
                    else if (columnsLookup[key].typeId == 5 && columnsLookup[key].showThumbnail) {

                        if (data[i][key] && data[i][key + '_thumb'])
                            data[i][key + '_thumb'] = '<span class=""><img src="data:'
                                + 'image/jpeg;base64,'
                                + data[i][key + '_thumb'] + '" />'
                                + '</span>';
                        else {
                            data[i][key + '_thumb'] = data[i][key] ? "<i title='Thumbnail Missing' class='neutralText mdi mdi-image-minus'></i>" : "-";
                            data[i][key] = "-";

                        }
                    }

                    else data[i][key] = (data[i][key] || "-").toString();
                }
            }


            retval.push(data[i]);
        }
        return retval;
    }

    Report.prototype.getReportDataPage = function(pageNo){
        var self = this;
        self.dataView.loading = true;
        
        if (self.filtersInfo.filters) self.applyFilters();
        var filters = self.currentFilters ? [self.currentFilters] : self.properties.filters;
        var dataURL = URI.MODULE_GRID.SEARCH_REPORT;
        var bodyParams = {
            filters: filters,
            fields: processSelectedFields(self.selectedFields),
            pagesize: self.dataView.pagesize,
            page: pageNo || self.dataView.page
        };
        var urlParams = { reportId: self.properties.id, contractId: userService.system.userdata.contractId, current: true };

        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: bodyParams }, { headers: { moduleId: self.properties.moduleId } })
            .then(function (r) {
                if (pageNo) self.dataView.page = pageNo;

                self.dataView.data = processDataReport(r.data, self.dataView.fields);
                self.dataView.records = r.records;
                self.dataView.pages = Math.ceil(parseInt(r.records) / self.dataView.pagesize);
                self.dataView.pageStart = self.dataView.pagesize * (self.dataView.page-1) + 1;
                self.dataView.pageEnd = self.dataView.pagesize * self.dataView.page <= r.records ? self.dataView.pagesize * self.dataView.page : r.records;
            })
            .catch(function (e) { Message.dberror(e); console.error(e) })
            .finally(function () { self.dataView.loading = false; })


    }

    Report.prototype.getReportData = function () {
        var self = this;
        self.isDataView = true;
        self.dataView = {
            fields: processSelectedFields(self.selectedFields),
            data: [],
            records: null,
            page: 1,
            pageStart: 1,
            pageEnd: 10,
            pagesize: 10,
            loading: true
        }
        for (var i = 0; i < self.dataView.fields.length; i++) {
            self.dataView.fields[i].restrictionsLookup = {};
            if (self.dataView.fields[i].id < 0) self.dataView.fields[i].id = self.dataView.fields[i].name;
            // put date restriction if no restrictions on a datetime field
            if (self.dataView.fields[i].typeId == 4 && !(self.dataView.fields[i].restrictions || []).length) {
                self.dataView.fields[i].restrictions = [{ key: 5, value: 3 }];
                self.dataView.fields[i].options = "YYYY-MM-DD";
            }
            if ((self.dataView.fields[i].restrictions || []).length) {
                for (var j = 0; j < self.dataView.fields[i].restrictions.length; j++) {
                    self.dataView.fields[i].restrictionsLookup[self.dataView.fields[i].restrictions[j].key] = self.dataView.fields[i].restrictions[j].value || true;
                }
            }
        }

        self.getReportDataPage();
    }

    Report.prototype.printData = function () {
        var self = this;
        self.dataView.printing = true;
        
        if (self.filtersInfo.filters) self.applyFilters();
        var filters = self.currentFilters ? [self.currentFilters] : self.properties.filters;

        var dataURL = URI.MODULE_GRID.SEARCH_REPORT;
        var bodyParams = {
            filters: filters,
            fields: processSelectedFields(self.selectedFields)
        };
        var urlParams = { reportId: self.properties.id, contractId: userService.system.userdata.contractId, current: true };

        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: bodyParams }, { headers: { moduleId: self.properties.moduleId } })
            .then(function (r) {
                var toPrint = processDataReport(r.data, self.dataView.fields);
                var columns = angular.copy(self.dataView.fields);
                var data;
                
                if ($("#section-to-print").length != 0) {
                    $("#section-to-print").remove();

                }
                var div = $('<div />').appendTo('body');
                div.attr('id', 'section-to-print');
                //$('<table />').appendTo(div);

                var tableHtml = "<table class='table'><thead><tr>";
                for (var i = 0; i < columns.length; i++) {
                    tableHtml += "<th>" + columns[i].label + "</th>";
                }
                tableHtml += "</tr></thead>";
                tableHtml += "<tbody>";

                for (var i = 0; i < toPrint.length; i++) {
                    data = "";
                    tableHtml += "<tr>";
                    for (var j = 0; j < columns.length; j++) {
                        if (columns[j].typeId == 5 && columns[j].showThumbnail) {
                            data = toPrint[i][columns[j].id + '_thumb'];
                        } else  {
                            data = toPrint[i][columns[j].id];
                        }
                        
                        tableHtml += "<td>" + data + "</td>";
                    }
                    tableHtml += "</tr>";
                }
                tableHtml += "</tbody>";
                tableHtml += "</table>";
                $timeout(function () {
                    $("#section-to-print").html(tableHtml);
                    $window.print();
                    self.dataView.printing = false;
                }, 1)
            })
            .catch(function (e) { Message.dberror(e); console.error(e) })
            .finally(function () { self.dataView.loading = false; })
    }
    

    Report.prototype.exportAsExcel = function () {
        var self = this;

        if (self.filtersInfo.filters) self.applyFilters();
        var filters = self.currentFilters ? [self.currentFilters] : self.properties.filters;
        var urlParams = { reportId: self.properties.id, contractId: userService.system.userdata.contractId, current: true };
        var bodyParams = {
            filters: filters,
            fields: processSelectedFields(self.selectedFields)
        };
        self.isDownloadExcel = true;
        var dataURL = URI.MODULE_GRID.SEARCH_REPORT_EXCEL;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: bodyParams }, { headers: { moduleId: self.properties.moduleId } })
            .then(function (result) {
                if (result.length <= 0) {
                    Message.error('An error occurred when generating the Excel document.');
                    return;
                }
                var fileName = [self.properties.name, moment(new Date()).format('YYYY_MM_DD')]
                    .filter(x => x)
                    .join('-') + '.xlsx';
                var fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                var fileOptions = {
                    type: fileType,
                    mimeType: fileType,
                    fileClass: fileType.replace(/\//g, '-').replace(/\./g, '-')
                };

                var newFile = new File([Functions.base64ToArrayBuffer(result)], fileName, fileOptions);
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(newFile);
                link.download = fileName;
                link.click();
            })
            .catch(function (e) { console.error(e); Message.dberror(e); })
            .finally(function () { self.isDownloadExcel = false; });
    }

    Report.prototype.exportAsPdf = function () {
        var self = this;
        if (self.filtersInfo.filters) self.applyFilters();
        var filters = self.currentFilters ? [self.currentFilters] : self.properties.filters;
        var urlParams = { reportId: self.properties.id, contractId: userService.system.userdata.contractId, current: true };
        var bodyParams = {
            filters: filters,
            fields: processSelectedFields(self.selectedFields)
        };

        self.isDownloadPdf = true;
        var dataURL = URI.MODULE_GRID.SEARCH_REPORT_PDF;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: bodyParams }, { headers: { moduleId: self.properties.moduleId } })
            .then(function (result) {
                if (result.length <= 0) {
                    Message.error('An error occurred when generating the Pdf document.');
                    return;
                }
                var fileName = [self.properties.name, moment(new Date()).format('YYYY_MM_DD')]
                    .filter(x => x)
                    .join('-') + '.pdf';
                var fileType = 'application/pdf';
                var fileOptions = {
                    type: fileType,
                    mimeType: fileType,
                    fileClass: fileType.replace(/\//g, '-').replace(/\./g, '-')
                };

                var newFile = new File([Functions.base64ToArrayBuffer(result)], fileName, fileOptions);
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(newFile);
                link.download = fileName;
                link.click();
            })
            .catch(function (e) { console.error(e); Message.dberror(e); })
            .finally(function () { self.isDownloadPdf = false; });
    }
        
    

    var addZ = function (date) {
        var ret = date || null;
        if (date && date.indexOf("Z") == -1)
            ret = date + "Z";
        return ret;
    }

    

    return Report;
});
