import { Component, Input, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { WeatherFieldModel } from 'src/app/models/module/fields/main/weatherField.model';
import { WeatherTableService } from './weather-table.service';

@Component({
  selector: 'app-weather-table',
  templateUrl: './weather-table.component.html',
  styleUrls: ['./weather-table.component.scss']
})
export class WeatherTableComponent implements OnInit {

  displayedColumns: string[] = ['timeInput', 'temperature', 'windSpeed', 'phrase', 'humidity', 'delete'];
  dataSource: WeatherFieldModel[] = [];

  @Input() editMode: boolean = false;
  @Input() fielddata: { _fieldProperty: number, entityInstanceId: number, _editMode: boolean, _value: any };

  fieldsList: [{ id: number, name: string }];
  timeFieldId: number;

  constructor(private weatherService: WeatherTableService) { }

  ngOnInit(): void {
    this.getTimeFieldInstances();
    this.weatherService.getTableTimeFieldId(this.fielddata._fieldProperty, this.fielddata.entityInstanceId)
      .subscribe(x => this.timeFieldId = x);
  }

  createTimeFieldInstance() {
    this.dataSource = [...this.dataSource, { fieldId: this.timeFieldId } as WeatherFieldModel];
    this.initializeFieldDataValue();
  }

  getWeatherDataByTime(event, element) {
    this.weatherService.getContractWeatherDataByTime(this.fielddata._fieldProperty, this.fielddata.entityInstanceId, event.target.value, element.fieldInstanceId)
      .subscribe(x => {
        let row = this.dataSource.find(y => y === element);
        row.time = event.target.value;
        if (x !== undefined && x !== null) {
          row.phrase = x.phrase;
          row.temperature = x.temperature;
          row.windSpeed = x.windSpeed;
          row.humidity = x.humidity;
        }
        this.initializeFieldDataValue();
      });
  }

  deleteRow(element) {
    this.dataSource = this.dataSource.filter(x => x !== element);
    this.initializeFieldDataValue();
  }

  getTimeFieldInstances() {
    this.weatherService.getTimeFieldInstances(this.fielddata._fieldProperty, this.fielddata.entityInstanceId)
      .subscribe(d => {
        this.dataSource = d;
        this.initializeFieldDataValue();
      });
  }

  //we are using this method because the old fielddata object requires everytime to contain the exact rows we have in our table
  initializeFieldDataValue() {
    this.fielddata._value = this.dataSource.map(x => {
      const obj = {};
      obj[x.fieldId] = x.time;
      return obj;
    })
  }



  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'time':
          return this.compare(a.time.toLocaleString(), b.time.toLocaleString(), isAsc);
        case 'temperature':
          return this.compare(a.temperature, b.temperature, isAsc);
        case 'windSpeed':
          return this.compare(a.windSpeed, b.windSpeed, isAsc);
        case 'phrase':
          return this.compare(a.phrase, b.phrase, isAsc);
        case 'humidity':
          return this.compare(a.humidity, b.humidity, isAsc);
        default:
          return 0;
      }
    });
  }


  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}

