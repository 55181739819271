import { cipo } from 'cipo';

cipo.controller('externalcontactsController',
    function ($scope, $state, $location, Manager, rememberManagerService, $uibModal, dialogs, Message, URI, ACTIONS, Menu, userService, $mdDialog) {
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.module = userService.system.modules[$state.current.code];

        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor($state.current.code); }
        });

        $scope.sendList = function (data) {
            var listToSend = [];
            if (Object.prototype.toString.call(data) == '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].id != 'undefined') {
                            listToSend.push(data[i].id);
                        }
                    }
                }
            } else {
                if (typeof data.id != 'undefined') {
                    listToSend.push(data.id);
                }
            }
            return listToSend;
        }
        $scope.manager = new Manager({
            objectsPerPage: 20,
            dataURL: URI.CONTACT.SEARCH,
            dataWrapper: "data",
            options: {
                multiselect: $scope.operations.Update || $scope.operations.Delete ? true : false
            },
            rowOnClick: function (row) {
                $scope.editUser(row);
            },

            leftActions: [
                {
                    setProperties: ACTIONS.CREATE,
                    conditionOnTop: function () {
                        if ($scope.operations.Create) return true;
                        else return false;
                    },
                    click: function (rows) {
                        $scope.editUser();
                    }
                }
            ],
            actions: [
                {
                    setProperties: ACTIONS.REFRESH,
                    click: function () {
                        $scope.manager.refresh();
                    }
                },
                {
                    setProperties: ACTIONS.DETAILS,
                    condition: function (row) {
                        if (row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.editUser(row);
                    }
                },
                {
                    setProperties: ACTIONS.VIEW,
                    condition: function (row) {
                        if (!row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.editUser(row);
                    }
                },

                {
                    setProperties: ACTIONS.DELETE,
                    displayOnRow: $scope.operations.Delete ? true : false,
                    conditionOnTop: function () {
                        if ($scope.operations.Delete) return true;
                        else return false;
                    },
                    condition: function (row) {
                        if (row.operations.Delete) return true;
                        else return false;
                    },
                    click: function (rows) {
                        $scope.deleteUser($scope.sendList(rows));
                    }
                }
            ]
        });
        $scope.manager.set_Columns([
            { name: 'firstName', label: 'First Name', type: 'text', onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
            { name: 'lastName', label: 'Last Name', type: 'text', onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
            { name: 'email', label: 'Email', type: 'text', onTablet: true, onPhone: false, width: 30, fieldTypeId: 1 },
            { name: 'orgName', label: 'Organization', type: 'text', onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 }
        ]);

        // $scope.manager.loadPage();
        if (rememberManagerService.moduleId == $scope.module.moduleId) {
            $scope.manager = rememberManagerService.get();
            $scope.manager.loading = false;
        } else {

            $scope.manager.loadPage();
            rememberManagerService.set($scope.manager, $scope.module.moduleId);
        }


        var saving = false;
        $scope.editUser = function (obj, fromurl, ev) {
            var fromurl = fromurl || false;
            var obj = angular.copy(obj);
            if (!saving) {
                saving = true;
                $mdDialog.show({
                    locals: { obj: obj, operations: $scope.operations, module: $scope.module },
                    controller: 'editExternalUserController',
                    templateUrl: '/ng/views/admin/modals/editExternalUser.html',
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    fullscreen: true,
                    escapeToClose: false,
                    clickOutsideToClose: false
                })
                    .then(function (result) {
                        $scope.manager.page = 1;
                        $scope.manager.loadPage();
                        saving = false;
                    }, function () {
                        saving = false;
                    });
            }
        };
        var confirm = function (title, text, ev) {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        }



        $scope.deleteUser = function (list) {
            var list = list || [];

            if (list.length) {
                //var d = dialogs.confirm('Delete User', 'Are you sure you want to delete the selected User(s)?');
                $mdDialog.show(confirm('Delete Contacts', 'Are you sure you want to delete the selected Contact(s)?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[URI.CONTACT.DELETE.method](URI.CONTACT.DELETE, { list: list })
                            .then(function () {
                                Message.info('Contact(s) deleted successfully');
                            })
                            .catch(function (e) {
                                Message.dberror(e, $scope.manager.rows, 'id', 'name');
                            })
                            .finally(function () {
                                $scope.manager.loadPage();
                            });
                    })
                    .catch(function () {
                        // cancel pressed
                    });
            } else {
                Message.info('Please select the item(s) to delete');
            }
        }

        $scope.$watch(function () {
            return userService.isReloadGrid;
        },
            function (n, o) {
                if (n) {
                    $scope.manager.loadPage();
                    userService.isReloadGrid = false;

                }
            });



    });
