import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScreenFieldModel } from '../../../models/module/fields/main';
import { DataCardViewPart, DataCardViewPartValueType, DataCardViewStateValue } from '../../../models/module/main';
import { DataCardsViewService } from './data-cards-view.service';
import { SystemFieldsEnum } from 'src/app/models/module/fields/enums';

interface DataCard {
  parts: DataCardViewPart[],
  entityInstanceId: number;
}

@Component({
  selector: 'app-data-cards-view',
  templateUrl: './data-cards-view.component.html',
  styleUrls: ['./data-cards-view.component.scss'],
})
export class DataCardsViewComponent implements OnInit {
  constructor(private cardsService: DataCardsViewService) {}

  @Input() set rows(value: any[]) {
    this.rows$.next(value || []);
  }

  @Output('cardclick') cardClick: EventEmitter<number> = new EventEmitter<number>();

  cards: DataCard[] = [];
  rows$ = new Subject<any[]>();
  fields$ = new Subject<any[]>();

  ngOnInit(): void {
    this.getScreenFields().subscribe(fields => this.fields$.next(fields));
    combineLatest([this.rows$, this.fields$]).subscribe(([rows, fields]) => this.MakeCards(fields, rows));
  }

  cardClickInternal(card: DataCard) {
    this.cardClick.emit(card.entityInstanceId);
  }

  private MakeCards(fields: ScreenFieldModel[], rows: any[]) {
    this.cards = [...rows].map(row => {
      const parts = fields.map(field => {
        const part = new DataCardViewPart(field.id, field);
        part.value = this.getDataCardViewPartValue(field, row);
        return part;
      });
      return {
        parts: parts,
        entityInstanceId: row['entity_instance_id'],
      } as DataCard;
    });
    // force gridster to recalculate the width
    setTimeout(function() {
      window.dispatchEvent(new Event('resize'));
    }, 100)
  }

  private getDataCardViewPartValue(field: ScreenFieldModel, row: any): DataCardViewPartValueType {
    if (field.id == SystemFieldsEnum.state_name) {
      return {
        name: row[SystemFieldsEnum[SystemFieldsEnum.state_name]],
        fontColor: row[SystemFieldsEnum[SystemFieldsEnum.state_text_color]],
        bgColor: row[SystemFieldsEnum[SystemFieldsEnum.state_color]],
      } as DataCardViewStateValue;
    }
    return row[field.systemFieldName] || row[field.id];
  }

  private getScreenFields(): Observable<ScreenFieldModel[]> {
    return this.cardsService.getScreenFields().pipe(map(f => f.data || []));
  }
}
