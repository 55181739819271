import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NumberProgressBarComponent } from '../components/number-progress-bar/number-progress-bar.component';
import { MaterialModule } from './material.module';
import { PipesModule } from './pipes.module';

@NgModule({
  declarations: [NumberProgressBarComponent],
  exports: [NumberProgressBarComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, PipesModule],
})
export class SharedComponentsModule {}
