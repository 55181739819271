import { cipo } from 'cipo';

cipo.directive("explorerGrid", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "explorer": "="
        },
        templateUrl: "/ng/views/directives/system/explorer.html",
        controller: function ($scope) {
            $scope.showTreeInfo = function () {
            }
            if ($scope.explorer) {
                if (typeof $scope.persistentLayout != "undefined") {
                    $scope.explorer.layout = $scope.persistentLayout;
                }
            }

            $scope.$watch(function () { if ($scope.explorer) return $scope.explorer.layout; }, function (n, o) {
                if (typeof $scope.persistentLayout != "undefined") {
                    $scope.explorer.layout = $scope.persistentLayout;
                }
            });

            $scope.$watch(function () { if($scope.explorer && $scope.explorer.manager) return $scope.explorer.manager.layout; }, function (n, o) {
                if ($scope.explorer && $scope.explorer.manager) {
                    $scope.explorer.layout = n;
                    $scope.persistentLayout = n;
                }
            });
        }
    }
});
