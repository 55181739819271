import { FontStyleType, HorizontalAlignType, ScreenFieldFormattingModel, VerticalAlignType } from "../fields/main";

export interface DataCardSetting {
  label: ScreenFieldFormattingModel;
  value: ScreenFieldFormattingModel;
}

export const DataCardTextSizeToPercent = new Map<number, number>([
  [1, 170],
  [2, 140],
  [3, 120],
  [4, 100],
  [5, 85],
  [6, 70],
]);

export const DataCardPartSizes = [10, 25, 50, 75, 90, 100];

export const DataCardSettingFontColorDefault: string = '#000';
export const DataCardSettingFontColorIndicatorUp: string = '#54b095';
export const DataCardSettingFontColorIndicatorDown: string = '#ea5768';

export const DataCardSettingLabelDefault: ScreenFieldFormattingModel = {
  showColon: true,
  fontStyle: FontStyleType.Bold,
  fontSize: 4,
  fontColor: DataCardSettingFontColorDefault,
  alignHorizontal: HorizontalAlignType.Left,
  alignVertical: VerticalAlignType.Center,
}

export const DataCardSettingValueDefault: ScreenFieldFormattingModel = {
  size: 50,
  fontStyle: FontStyleType.None,
  fontSize: 4,
  fontColor: DataCardSettingFontColorDefault,
  alignHorizontal: HorizontalAlignType.Right,
  alignVertical: VerticalAlignType.Center,
}

