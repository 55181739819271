import { cipo } from 'cipo';

cipo.factory('ModuleDictionaries', function ($q, Model, URI, TEMPLATES_TYPES, Message) {
    var ModuleDictionaries = {};


    ModuleDictionaries.init = function (moduleInfo) {
        var self = ModuleDictionaries;
        self.moduleInfo = moduleInfo || {};
        self.isPresetFieldsLoaded = false;

        var headerParams = { moduleId: self.moduleInfo.moduleId };

        self.isPresetFieldsLoaded = false;

        self.dataSources = {
            actionFields: {
                url: URI.ACTIONS.FIELDS_DICT,
                urlParams: self.moduleInfo.currentType ? { moduleTypeId: self.moduleInfo.currentType.key, id: 0 } : {}
            },
            allRoles: {
                url: URI.ROLES.DICT,
                urlParams: self.moduleInfo.currentType ? { moduleTypeId: self.moduleInfo.currentType.key } : {}
            },
            // should get out of use
            assignedRoles: {
                url: URI.PERMISSIONS.ROLES_DICT,
                headerParams: headerParams
            },
            availableDocNumbers: {
                url: URI.MODULE_PRESETS.AVAILABLE_NUMBERS_DICT,
                headerParams: headerParams
            },
            closedStates: {
                url: URI.STATES.CLOSED_STATES_DICT,
                headerParams: headerParams,
                urlParams: { workflowId: self.moduleInfo.workflowId }
            },
            conditionalOperators: {
                url: URI.TRANSITION.OPERATORS_DICT,
                headerParams: headerParams
            },
            contractTypes: {
                url: URI.MODULE_PRESETS.CONTRACT_TYPES_DICT,
            },
            correspondenceFields: {
                url: URI.MODULE_LINK_CORRESPONDENCE.DICT,
                headerParams: headerParams
            },
            dataSources: {
                url: URI.LISTS.DATASOURCE_DICT,
                urlParams: { withParent: false }
            },
            //docNumbers: {
            //    url: URI.MODULE.DOC_NUM_AVAILABLE,
            //    urlParams: { moduleId: self.moduleInfo.moduleId, contractId: 0, entityInstanceId: 0 }
            //},
            fieldsForImport: {
                url: URI.FIELD_DEFINITION.IMPORT_DICT,
                headerParams: headerParams,
                urlParams: { screenId: self.moduleInfo.screenId }
            },
            fieldsForImportSOV: {
                url: URI.SOV.IMPORT_DICT,
                headerParams: headerParams,
                urlParams: { screenId: self.moduleInfo.screenId }
            },
            forms: {
                url: URI.SCREEN_DEFINITION.DICT,
                headerParams: headerParams,
                urlParams: { type: 2 /* action screens */ }
            },
            footers: {
                url: URI.HEADER_FOOTER_SETTINGS.DICT,
                headerParams: headerParams,
                urlParams: { isHeader: false }
            },
            headers: {
                url: URI.HEADER_FOOTER_SETTINGS.DICT,
                headerParams: headerParams,
                urlParams: { isHeader: true }
            },
            initiators: {
                url: URI.STATES.INITIATOR_DICT,
                headerParams: headerParams,
                urlParams: { workflowId: self.moduleInfo.workflowId }
            },
            mimetypes: {
                url: URI.FIELD_DEFINITION.MIME_TYPES_DICT
            },
            moduleEntities: {
                url: URI.FIELD_DEFINITION.ENTITIES_DICT,
                headerParams: headerParams,
                urlParams: self.moduleInfo.currentType ? { moduleTypeId: self.moduleInfo.currentType.key } : {}
            },
            modules: {
                url: URI.MODULE_RELATION.DATASOURCE_DICT_RELATIONS,
                headerParams: headerParams
            },
            moduleTypes: {
                url: URI.MODULE_TYPES.TYPES_DICT,
                headerParams: headerParams
            },
            moduleFieldsForCorrespondence: {
                url: URI.FIELD_DEFINITION.CORRESPONDENCE_DICT,
                headerParams: headerParams,
                urlParams: { screenId: self.moduleInfo.screenId }
            },
            notifications: {
                url: URI.TEMPLATES.TEMPLATES_DICT,
                headerParams: headerParams,
                urlParams: { typeName: TEMPLATES_TYPES[3] }
            },
            presetTypes: {
                url: URI.MODULE_PRESETS_TYPES.DICT,
                headerParams: headerParams
            },
            relationTypes: {
                url: URI.MODULE_RELATION.RELATION_TYPES_DICT,
                headerParams: headerParams
            },
            selectsDataSources: {
                url: URI.LISTS.DATASOURCE_DICT,
                urlParams: { withParent: true }
            },
            signatures: {
                url: URI.TRANSITION.SIGN_LIST,
                headerParams: headerParams,
                urlParams: { workflowId: self.moduleInfo.workflowId }
            },
            signatureTransitions: {
                url: URI.TRANSITION_GROUP.SIGN_LIST_DICT,
                headerParams: headerParams,
                urlParams: { workflowId: self.moduleInfo.workflowId }
            },
            states: {
                url: URI.STATES.DICT,
                headerParams: headerParams,
                urlParams: { workflowId: self.moduleInfo.workflowId }
            },
            statesOpen: {
                url: URI.STATES.DICT,
                headerParams: headerParams,
                // Only open states
                urlParams: { typeId: 2, workflowId: self.moduleInfo.workflowId }
            },
            stateTypes: {
                url: URI.STATES.TYPES_DICT,
            },
            startScreens: {
                url: URI.SCREEN_DEFINITION.DICT,
                headerParams: headerParams,
                urlParams: { type: 1/* entity screen */ }
            },
            templates: {
                url: URI.TEMPLATES.TEMPLATES_DICT,
                headerParams: headerParams,
                urlParams: { typeName: TEMPLATES_TYPES[1] }
            },
            templatesWithoutConditionals: {
                url: URI.TEMPLATES.TEMPLATES_DICT,
                headerParams: headerParams,
                urlParams: { typeName: TEMPLATES_TYPES[1], withConditionals: false, screenId: self.moduleInfo.screenId }
            },
        }

        for (var key in self.dataSources) {
            if (self.dataSources.hasOwnProperty(key)) {
                self.dataSources[key].data = [];
                self.dataSources[key].lookup = {};
                self.dataSources[key].isLoaded = false;
                self.dataSources[key].urlParams = self.dataSources[key].urlParams || {};
                self.dataSources[key].headerParams = self.dataSources[key].headerParams || {};
            }
        }
    }

    var createLookup = function (data) {
        var lookup = {};
        for (var i = 0; i < data.length; i++) {
            lookup[data[i].key] = data[i];
        }
        return lookup;
    }

    ModuleDictionaries.get_dataSource = function (property) {
        var self = ModuleDictionaries;
        var p = $q.defer();

        Model.request(self.dataSources[property].url.method, self.dataSources[property].url,
            { url: self.dataSources[property].urlParams, urltype: 'obj' },
            { headers: self.dataSources[property].headerParams })

            .then(function (result) {
                self.dataSources[property].data = result;
                self.dataSources[property].lookup = createLookup(result);
                self.dataSources[property].isLoaded = true;
                p.resolve();
            }).catch(function (e) {

                p.reject(e);
            })

        return p.promise;
    }

    ModuleDictionaries.getDicts = function (dictsList) {
        var p = $q.defer();
        var dicts = [];

        for (var i = 0; i < (dictsList || []).length; i++) {
            if (!ModuleDictionaries.dataSources[dictsList[i]].isLoaded)
                dicts.push(ModuleDictionaries.get_dataSource(dictsList[i]));
        }

        if (dicts.length) {
            dicts = $q.all(dicts);
            dicts
                .then(function () { p.resolve(); })
                .catch(function (e) { p.reject(); Message.dberror(e) })
        } else {
            p.resolve();
        }

        return p.promise;
    }

    ModuleDictionaries.refreshDicts = function (dictsList) {
        var p = $q.defer();

        for (var i = 0; i < (dictsList || []).length; i++) {
            ModuleDictionaries.dataSources[dictsList[i]].isLoaded = false;
        }
        ModuleDictionaries.getDicts(dictsList)
            .then(function () { p.resolve(); })
            .catch(function (e) { p.reject(); Message.dberror(e) });

        return p.promise;
    }

    ModuleDictionaries.addUrlParameter = function (property) {
        var self = ModuleDictionaries;

        for (var key in property) {
            // In case we already have the property set, don't need to do this again
            if (self.moduleInfo.hasOwnProperty(key) && self.moduleInfo[key] && self.moduleInfo[key] == property[key])
                return;

            if (property.hasOwnProperty(key)) {
                self.moduleInfo[key] = property[key];
            }
        }

        self.init(self.moduleInfo);
    }

    ModuleDictionaries.resetUrlParameter = function (property) {
        var self = ModuleDictionaries;

        for (var key in property) {

            if (property.hasOwnProperty(key)) {
                self.moduleInfo[key] = property[key];
            }
        }

        self.init(self.moduleInfo);
    }

    ModuleDictionaries.getPresetsFields = function (screenId, workflowId) {
        var self = ModuleDictionaries;
        var p = $q.defer();
        var dataURL = URI.MODULE.GET_FIELDS;
        Model.request(dataURL.method, dataURL,
            { url: { entityInstanceId: 0, transitionId: 0, contractId: 0, screenId: screenId, workflowId: workflowId }, urltype: 'obj' },
            { headers: { moduleId: self.moduleInfo.moduleId } })
            .then(function (result) {
                self.presetsFields = result;
                self.isPresetFieldsLoaded = true;
                p.resolve();
            }).catch(function (e) {

                p.reject(e);
            })

        return p.promise;
    }

    return ModuleDictionaries;
});
