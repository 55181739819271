import { cipo } from 'cipo';

cipo.controller('financialsController',
    function ($scope, $state, Manager, userService, $mdDialog, URI, ACTIONS, Message, rememberManagerService ) {
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.module = userService.system.modules[$state.current.code];
        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor($state.current.code); }
        });

        // $mdThemingProvider.setDefaultTheme('Green');

        var sendList = function (data) {
            var listToSend = [];
            if (Object.prototype.toString.call(data) == '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].id != 'undefined') {
                            listToSend.push(data[i].id);
                        }
                    }
                }
            } else {
                if (typeof data.id != 'undefined') {
                    listToSend.push(data.id);
                }
            }
            return listToSend;
        }

        $scope.manager = new Manager({
            objectsPerPage: 20,
            dataURL: URI.FINANCIAL_SCHEDULE.SEARCH,
            otherParams: {
                ShowDisabled: true
            },
            dataWrapper: 'data',
            options: {
                multiselect: $scope.operations.Update || $scope.operations.Delete ? true : false
            },
            rowOnClick: function (row) {
                $scope.edit(row.id);
            },
            leftActions: [
                {
                    setProperties: ACTIONS.CREATE,
                    icon: 'mdi mdi-calendar-plus',
                    conditionOnTop: function () {
                        if ($scope.operations.Create) return true;
                        else return false;
                    },
                    click: function (rows) {
                        $scope.edit();
                    }
                },
            ],
            actions: [
                {
                    setProperties: ACTIONS.REFRESH,
                    click: function () {
                        $scope.manager.refresh();
                    }
                },
                {
                    setProperties: ACTIONS.DETAILS,
                    condition: function (row) {
                        if (row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.edit(row.id);
                    }
                },
                {
                    setProperties: ACTIONS.VIEW,
                    condition: function (row) {
                        if (!row.operations.Update)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        $scope.edit(row.id);
                    }
                },
                {
                    setProperties: ACTIONS.DELETE,
                    condition: function (row) {
                        if (row.operations.Delete)
                            return true;
                        else return false;
                    },
                    conditionOnTop: function () {
                        if ($scope.operations.Delete)
                            return true;
                        else return false;
                    },
                    //alwaysOnTop: false,
                    click: function (rows) {
                        $scope.delete(sendList(rows));
                    }
                },
            ],
            parseData: function (data) {
                var data = data || [];

                for (var i = 0; i < data.length; i++) {
                    data[i].periodEndIsWorkday = data[i].periodEndIsWorkday ? 'Yes' : "No";
                }

                return data;
            },
        });

        $scope.manager.set_Columns([
            { name: 'name', label: 'Name', type: 'text', onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
            { name: 'description', label: 'Description', type: 'text', onTablet: true, onPhone: false, width: 40, fieldTypeId: 1 },
            { name: 'periodEndIsWorkday', label: 'Period End Is Workday', type: 'checkbox', onTablet: true, onPhone: true, width: 30 }
        ]);
        // $scope.manager.loadPage();
        if (rememberManagerService.moduleId == $scope.module.moduleId) {
            $scope.manager = rememberManagerService.get();
            $scope.manager.loading = false;
        } else {

            $scope.manager.loadPage();
            rememberManagerService.set($scope.manager, $scope.module.moduleId);
        }

        $scope.edit = function (id, ev) {
            $mdDialog.show({
                locals: { id: id || null, operations: $scope.operations, module: $scope.module },
                controller: 'editFinancialController',
                templateUrl: '/ng/views/admin/modals/editFinancial.html',
                parent: angular.element(document.body),
                //targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (result) {
                    // $scope.schedules.push(result);
                    $scope.manager.page = 1;
                    $scope.manager.loadPage();
                    //Menu.triggerRefresh();
                }, function () {
                    //Menu.triggerRefresh();
                    //$scope.manager.page = 1;
                    //$scope.manager.loadPage();
                });
        }

        var confirm = function (title, text, ev) {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        }

        $scope.delete = function (list, ev) {
            var list = list || [];
            if (list.length) {
                //var d = dialogs.confirm('Activating Roles', 'Are you sure you want to activate the selected Role(s)?');
                $mdDialog.show(confirm('Deleting Financials', 'Are you sure you want to delete the selected Financial(s)?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[URI.FINANCIAL_SCHEDULE.DELETE.method](URI.FINANCIAL_SCHEDULE.DELETE, { body: { list: list } })
                            .then(function () {
                                Message.info('Financial(s) deleted successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(e, $scope.manager.rows, 'id', 'name');
                            })
                            .finally(function () {
                                $scope.manager.loadPage();
                            })
                    })
                    .catch(function () {
                        // cancel pressed
                    });

            } else {
                Message.info('Please select the item(s) to delete');
            }

            //$mdDialog.show(confirm('Deleting Financial', 'Are you sure you want to delete the selected Financial(s)?'))
            //    .then(function () {
            //        $scope.manager.loading = true;
            //        $scope.manager[URI.FINANCIAL_SCHEDULE.DELETE.method](URI.FINANCIAL_SCHEDULE.DELETE, { url: { id: id }, urltype: 'obj' })
            //            .then(function () {
            //                Message.info('Financial deleted successfully');
            //            })
            //            .catch(function (e) {
            //                if (e.data.ids) {
            //                    messageContent = $scope.processError(e.data.ids, "deleted");
            //                    var d = dialogs.notify("Deleting Holidays", messageContent);
            //                } else {
            //                    Message.dberror(e, $scope.manager.rows, 'id', 'name');
            //                }

            //                //$scope.manager.loading = false;
            //                //Message.dberror(e);
            //            })
            //            .finally(function () {
            //                $scope.manager.page = 1;
            //                $scope.manager.loadPage();
            //            })
            //    })
            //    .catch(function () {
            //        console.log('cancel pressed');
            //    });
        }

        $scope.$watch(function () {
            return userService.isReloadGrid;
        },
            function (n, o) {
                if (n) {
                    $scope.manager.loadPage();
                    userService.isReloadGrid = false;

                }
            });



    });
