export enum SystemFieldsEnum {
    entity_instance_id = -1,
    full_doc_num = -2,
    doc_num_increment = -3,
    is_draft = -4,
    is_signed = -5,
    daysopened = -6,
    assigneerole = -7,
    assigneeorganisation = -8,
    ballincourt = -9,
    ballincourtrole = -10,
    revision = -11,
    state_color = -12,
    state_text_color = -13,
    updated_on = -14,
    updated_by = -15,
    initiator = -16,
    initiator_role = -17,
    created_by_role_id = -18,
    state_name = -19,
    state_type = -20,
    is_approval = -21,
    is_current = -22,
    date_submitted = -23,
    closed_on = -24,
    in_my_court = -25,
    total_payment = -26,
    payment_amount = -27,
    payment_percent = -28,
    previous_payment_amount = -29,
    previous_payment_percent = -30,
    paid_amount = -31,
    paid_percent = -32,
    assignee = -33,
    created_on = -36,
    overdue = -37,
    near_overdue = -38,

    // Contract system field from fn_contract_totals
    // In case we need to add new fields, then we need to add the new filed here and in the ContractFields Array from below
    orig_contract_value = -39,
    total_approved_pp_amount = -40,
    total_approved_co_cost = -41,
    adjusted_contract_total = -42,
    contract_balance = -43,
    original_contract_start_date = -44,
    original_contract_completion_date = -45,
    days_remaining = -47,
    revised_days_remaining = -48,
    contract_duration = -49,
    total_approved_co_duration = -50,
    revised_contract_duration = -51,
    adjusted_completion_date = -52,
    days_expended_through = -53,
    current_percent_complete_amount = -54,
    current_percent_complete_duration = -55,
    contingency = -56,
    remaining_contingency = -57,
    project_manager = -69,
    driving_activities = -70,
    contract_days_late = -72,

    // Correspondence fields
    correspondence_from = -58,
    correspondence_tos = -59,
    correspondence_ccs = -60,
    correspondence_subject = -61,
    correspondence_body = -62,
    correspondence_date = -63,
    correspondence_attachments = -64,

    // new fields for Progress Payment to pay on Quantity
    payment_qty = -65,
    previous_payment_qty = -66,
    paid_qty = -67,

    // System fields
    screen = -68,

    // custom field
    line = -71,
}
