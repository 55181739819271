import { GridsterConfig } from '@adrian3de/angular-gridster2';
import { Component, HostListener, Input } from '@angular/core';
import { DataCardViewPart } from '../../../models/module/main/dataCardView';

@Component({
  selector: 'app-data-card-view',
  templateUrl: './data-card-view.component.html',
  styleUrls: ['./data-card-view.component.scss'],
})
export class DataCardViewComponent {
  _parts: DataCardViewPart[];

  @Input() preview: false;

  @Input()
  set parts(value: DataCardViewPart[]) {
    this._parts = value;

    // force gridster to redraw
    if (this.gristerOptions.api) {
      this.gristerOptions.api.optionsChanged();
    }
    // force gridster to recalculate the width
    setTimeout(function() {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }
  get parts(): DataCardViewPart[] {
    return this._parts;
  }

  gristerOptions: GridsterConfig = {
    gridType: 'verticalFixed',
    fixedRowHeight: 24,
    displayGrid: 'none',
    outerMargin: false,
    margin: 4,
    mobileBreakpoint: 1,
    setGridSize: true,
  };
}
