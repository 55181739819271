import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { DataCardSettingDialogComponent } from './definition/data-card-setting-dialog/data-card-setting-dialog.component';
import { ScreenFieldFormattingModelComponent } from './definition/data-card-setting-part/data-card-setting-part.component';
import { DataCardSettingsComponent } from './definition/data-card-settings/data-card-settings.component';
import { WeatherTableComponent } from './definition/weather-table/weather-table.component';

export const Ng1ModuleDefinition = angular
  .module('ng1module.module-definition', [])
  .directive('appDataCardSettingDialog', downgradeComponent({ component: DataCardSettingDialogComponent }) as angular.IDirectiveFactory)
  .directive('appScreenFieldFormattingModel', downgradeComponent({ component: ScreenFieldFormattingModelComponent }) as angular.IDirectiveFactory)
  .directive('appDataCardSettings', downgradeComponent({ component: DataCardSettingsComponent }) as angular.IDirectiveFactory)
  .directive('appWeatherTable', downgradeComponent({ component: WeatherTableComponent }) as angular.IDirectiveFactory)
  .name;
