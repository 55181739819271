import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("FieldConfig", function ($q, Model, URI, Message, FIELDS_CONFIG, Dictionaries, ModuleDictionaries, $timeout, userService, froalaService, FROALA_TOOLBAR_SMALL, validationService) {
    var FieldConfig = Model.extend(function (obj, info, moduleId) { 
        var self = this;
            var mimetypesDict = angular.copy(ModuleDictionaries.dataSources.mimetypes.data);
            mimetypesDict.unshift({ key: 0, name: "All", description: "Any file can be selected" });
            self.config = FIELDS_CONFIG(mimetypesDict);

            self.moduleId = moduleId;
            // self.entityId = obj.entityId || null;
            self.isDetailsExpanded = true;
            self.properties = {};

            if (info)
                for (var key in info) {
                    if (info.hasOwnProperty(key))
                        obj[key] = info[key];
                }
            var obj = angular.copy(obj);
            if (obj.id) {
                self.properties.fieldOrigin = obj.fieldOrigin;
                self.properties.id = obj.id;
                var all = $q.all([Dictionaries.FieldScreens({ id: obj.id }), self.get_Details(obj)]);
                all
                    .then(function (r) {
                        // screens the field is on
                        self.fieldScreens = r[0];
                        self.fieldScreensLookup = {};
                        for (var i = 0; i < self.fieldScreens.length; i++)
                            self.fieldScreensLookup[self.fieldScreens[i].key] = self.fieldScreens[i];

                    if (obj.screenId) r[1].screenId = obj.screenId;

                    self.process_existingField(r[1]);
                    self.init(r[1]);
                })
                .catch(function (e) { })

        } else {
            self.init(obj);
        }
        // example of field structure
        var x = {
            name: "name",
            label: "name label",
            hasMultipleValues: false,
            typeId: 1, // Text
            displayTypeId: 2, // MultiLine
            restrictions: [
                { key: 2, value: null }, // fara element
                { key: 4, value: 1 }, // cu element
                { key: 4, mimeTypes: [{ key: 5, value: "image/jpg" }] } // mime types
            ], // value is elements
            formattings: [
                { key: 3, value: 4 },
                { key: 2, value: 1 } // 1 fiind numarul decimalelor
            ],
            dataSourceId: 5, // key listei selectate
            expression: "sum(52) * count()",
            entityIds: [2], // module types entity id
            defaultValue: "5", // aici se trimit valorile cand hasMultipleValues: false
            multipleDefaultValue: null // aici se trimit valorile cand hasMultipleValues: true
        };


        self.froalaOptions = {
            scaytAutoload: false,
            placeholderText: 'Enter Text Here',
            toolbarButtons: FROALA_TOOLBAR_SMALL.toolbarButtons,
            toolbarButtonsXS: FROALA_TOOLBAR_SMALL.toolbarButtonsXS,
            heightMin: 0,
            heightMax: 400,
        };

        froalaService.attachImageOptions(self.froalaOptions);

        if(obj.weatherDateId !== undefined && obj.weatherDateId !== null) {
            self.properties.weatherDateId = obj.weatherDateId;
        }
    });

        FieldConfig.prototype.changeRestriction = function (r) {
            var self = this;
            self.restrictionName = r.name;
            self.properties.defaultValue = null;
            self.properties.multipleDefaultValue = self.properties.hasDataSource ? [] : [null];

        }

    FieldConfig.prototype.changeDisplayType = function (id) {
        var self = this;
        // check for bad data
        if (!self.properties.displayTypeId) {
            self.properties.displayTypeId = id;

            var field = angular.copy(self.properties);

            self.process_existingField(field);
            self.init(field);
        } else self.properties.displayTypeId = id;


            // replace the displayType props
            for (var key in self.config.fieldTypesMapping[self.properties.typeId][self.properties.displayTypeId])
                if (self.config.fieldTypesMapping[self.properties.typeId][self.properties.displayTypeId].hasOwnProperty(key))
                    self.properties[key] = self.config.fieldTypesMapping[self.properties.typeId][self.properties.displayTypeId][key];
            // if not attachment and not select/radio
            if (self.properties.typeId != 5 && !self.properties.hasDataSource) {
                self.properties.multipleDefaultValue = [null];

        }
        // if select/radio
        if (self.properties.hasDataSource) self.properties.multipleDefaultValue = [];
        // if attachment
        if (self.properties.typeId == 5) self.mimeTypes = [0];

        // if (self.properties.dataSourceId) self.properties.dataSourceId = null;
        self.properties.defaultValue = "";
    }

    FieldConfig.prototype.aggregateExists = function (list) {
        var exists = list.filter(x => x.aggregateId && x.aggregateId > 0).length > 0;
        return exists;
    }

    FieldConfig.prototype.process_existingField = function (obj) {
        var self = this;

        var mimetypesDict = angular.copy(ModuleDictionaries.dataSources.mimetypes.data);
        mimetypesDict.unshift({ key: 0, name: "All", description: "Any file can be selected" });
        self.config = FIELDS_CONFIG(mimetypesDict);

            // will not change label with name
            self.editedLabel = true;
            //
            if (obj.displayTypeId) {
                obj.fieldTypeName = self.config.fieldTypesMapping[obj.typeId][obj.displayTypeId].fieldTypeName;
                obj.fieldTypeNameMultiple = self.config.fieldTypesMapping[obj.typeId][obj.displayTypeId].fieldTypeNameMultiple;
            }

            obj.canHaveMultipleValues = self.config.fieldTypesMapping[obj.typeId].canHaveMultipleValues;
            obj.restrictionsList = self.config.fieldTypesMapping[obj.typeId].restrictionsList;
            obj.defaultRestrictions = self.config.fieldTypesMapping[obj.typeId].defaultRestrictions;
            obj.initialRestrictions = [...obj.restrictions];

            // set the existing restrictions as the new defaults
            if (obj.displayTypeId) {
                for (var key in self.config.fieldTypesMapping[obj.typeId][obj.displayTypeId])
                    if (self.config.fieldTypesMapping[obj.typeId][obj.displayTypeId].hasOwnProperty(key))
                        obj[key] = self.config.fieldTypesMapping[obj.typeId][obj.displayTypeId][key];
            }

            if (obj.restrictions.length) {
                for (var i = 0; i < obj.restrictions.length; i++) {
                    // if object has value for the restriction, set it to config, if not, check if it is boolean or just bad data
                    if (typeof self.config.restrictionsMapping[obj.restrictions[i].key].value != 'undefined')
                        self.config.restrictionsMapping[obj.restrictions[i].key].value = typeof obj.restrictions[i].value != 'undefined'
                            && obj.restrictions[i].value != null
                            ? obj.restrictions[i].value
                            : (self.config.restrictions[self.config.restrictionsMapping[obj.restrictions[i].key].name].elements.length ? obj.restrictions[i].value : true);
                    if (typeof self.config.restrictionsMapping[obj.restrictions[i].key].property != 'undefined') {
                        var property = self.config.restrictionsMapping[obj.restrictions[i].key].property;
                        self.config.restrictionsMapping[obj.restrictions[i].key][property] = obj.restrictions[i][property].length ? obj.restrictions[i][property] : [0];

                }

            }
        }
        obj.restrictions = null;
        // set the existing formattings as the new defaults
        if (obj.formattings.length) {
            for (var i = 0; i < obj.formattings.length; i++) {
                self.config.formattingsMapping[obj.formattings[i].key].value = typeof obj.formattings[i].value != 'undefined' ? obj.formattings[i].value : true;
            }
        }
        obj.formattings = null;


        if(obj.weatherDateId !== undefined && obj.weatherDateId !== null) {
            self.properties.weatherDateId = obj.weatherDateId;
        }
        
    }

    FieldConfig.prototype.init = function (obj) {

        var self = this;

        var fieldStructure = {

            //mine
            fieldTypeName: "",
            fieldTypeNameMultiple: "",
            // formattingName: "",
            canHaveMultipleValues: "",
            hasDataSource: false,
            categoryName: "",
            screenId: 0,
            // defaultFormattingId: [],
            id: null,
            typeId: null,
            displayTypeId: null,
            restrictions: [],
            formattings: [],
            isFilter: false,

                // commun stuff
                name: "",
                label: "",
                hasMultipleValues: false,
                dataSourceId: null,
                expression: "",
                entityIds: obj.entityId ? [obj.entityId] : [],
                defaultValue: null,
                fieldOrigin: null,
                inUse: false,
                // {value: "", label: ""}
                multipleDefaultValue: [],
                // aggregate
                aggregateId: null,
                canHaveAggregate: false,
                currentDateTime: false,

                // Needed for formula field
                onlyFormula: true,
                moduleId: self.moduleId
            };

        // 
        if (obj.restrictionsList.length)
            for (var i = 0; i < obj.restrictionsList.length; i++) {
                fieldStructure.restrictions.push(self.config.restrictions[obj.restrictionsList[i]]);
                if (self.config.restrictions[obj.restrictionsList[i]].elements.length
                    && !self.config.restrictions[obj.restrictionsList[i]].isMultiple) {
                    self.config.restrictions[obj.restrictionsList[i]].value = self.config.restrictions[obj.restrictionsList[i]].elements[0].key;
                    // self.restrictionName = self.config.restrictions[obj.restrictionsList[i]].elements[0].name;
                }

            }
        if (obj.defaultRestrictions.length) {
            for (var i = 0; i < obj.defaultRestrictions.length; i++) {
                if (!self.config.restrictionsMapping[fieldStructure.restrictions[i].key].property) {
                    fieldStructure.restrictions[i].value = self.config.restrictionsMapping[fieldStructure.restrictions[i].key].value !== 0 && self.config.restrictionsMapping[fieldStructure.restrictions[i].key].value !== false ? self.config.restrictionsMapping[fieldStructure.restrictions[i].key].value : obj.defaultRestrictions[i];
                    if (self.config.restrictions[obj.restrictionsList[i]].elements.length && !self.config.restrictionsMapping[fieldStructure.restrictions[i].key].isMultiple) {
                        if (self.config.restrictionsMapping[fieldStructure.restrictions[i].key].value) {
                            var value = self.config.restrictionsMapping[fieldStructure.restrictions[i].key].value;
                            self.restrictionName = self.config.restrictionsMapping[fieldStructure.restrictions[i].key].elements[value];
                        } else {
                            self.restrictionName = self.config.restrictions[obj.restrictionsList[i]].elements[0].name;
                        }
                    }
                }
                else {
                    var property = self.config.restrictionsMapping[fieldStructure.restrictions[i].key].property;
                    fieldStructure.restrictions[i][property] = self.config.restrictionsMapping[fieldStructure.restrictions[i].key][property];
                }

            }
        }
        if (obj.id) {
            fieldStructure.restrictions.forEach(r => {
                var restrictionDefaultValue = Object.values(self.config.restrictions).find(s => s.key == r.key).value;
                var initial = obj.initialRestrictions.find(i => i.key === r.key);
                if (!initial && restrictionDefaultValue === true) {
                    // if restriction default value is true but is not received from DB (initial is null) then value should be false
                    r.value = false;
                }
            });
        }

        if ((obj.formattingsList || []).length)
            for (var i = 0; i < obj.formattingsList.length; i++) {
                fieldStructure.formattings.push(self.config.formattings[obj.formattingsList[i]]);
                if (self.config.formattings[obj.formattingsList[i]].elements.length) {
                    self.config.formattings[obj.formattingsList[i]].value = self.config.formattings[obj.formattingsList[i]].elements[0].key;

                }

            }
        if ((obj.defaultFormattingIds || []).length) {
            for (var i = 0; i < obj.defaultFormattingIds.length; i++) {
                fieldStructure.formattings[i].value = typeof self.config.formattingsMapping[fieldStructure.formattings[i].key].value != 'undefined' ? self.config.formattingsMapping[fieldStructure.formattings[i].key].value : obj.defaultFormattingIds[i];
                if (fieldStructure.formattings[i].elements.length)
                    self.formattingName = self.config.formattingsMapping[fieldStructure.formattings[i].key].elements[fieldStructure.formattings[i].value];
            }
        }


        for (var key in fieldStructure) {
            // null and undefined properties are ignored
            // properties with value 0 is valid
            if (fieldStructure.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined)
                fieldStructure[key] = obj[key];
        }

        self.properties = fieldStructure;
        // self.formattingName = self.properties.fieldTypeName;

        if (self.properties.typeId == 4) {
            if (self.properties.hasMultipleValues && self.properties.multipleDefaultValue.length) {
                for (var i = 0; i < self.properties.multipleDefaultValue.length; i++) {
                    self.properties.multipleDefaultValue[i] = moment(addZ(self.properties.multipleDefaultValue[i]))
                }
            }
            if (!self.properties.hasMultipleValues && self.properties.defaultValue) {
                self.properties.defaultValue = moment(addZ(self.properties.defaultValue));
            }
        }

        self.errors = {
            name: '',
            label: "",
            defaultValue: "",
            multipleDefaultValue: [''],
            weatherDateId: "",
            dataSourceId: ""
        };

        if (self.properties.typeId != 5 && !self.properties.hasDataSource && !self.properties.multipleDefaultValue.length) self.properties.multipleDefaultValue.push(null);

            if (self.properties.typeId == 5) {
                self.mimeTypes = [0];
                if (self.properties.defaultValue) {
                    self.properties.defaultValue = [self.properties.defaultValue];
                    self.properties.defaultValue[0].mimeType = ModuleDictionaries.dataSources.mimetypes.lookup[self.properties.defaultValue[0].mimeTypeId].description;
                }

                if (self.properties.multipleDefaultValue.length) {
                    for (var i = 0; i < self.properties.multipleDefaultValue.length; i++) {
                        self.properties.multipleDefaultValue[i].mimeType = ModuleDictionaries.dataSources.mimetypes.lookup[self.properties.multipleDefaultValue[i].mimeTypeId].description;
                    }

            }
        }

        self.displayTypesDict = [];

        for (var i = 0; i < self.config.fieldTypesMapping[self.properties.typeId].displayTypes.length; i++) {
            var displayTypeId = self.config.fieldTypesMapping[self.properties.typeId].displayTypes[i];
            var displayType = self.config.displayTypeDict[displayTypeId];
            displayType.fieldTypeName = self.config.fieldTypesMapping[self.properties.typeId][displayTypeId].fieldTypeName;
            displayType.icon = self.config.fieldTypesMapping[self.properties.typeId][displayTypeId].icon;
            displayType.fieldTypeNameMultiple = self.config.fieldTypesMapping[self.properties.typeId][displayTypeId].fieldTypeNameMultiple;
            self.displayTypesDict.push(displayType);
        }

        if (self.properties.dataSourceId && self.properties.typeId != 10) self.init_DataSourceElements(true);

        // If we can have aggregate for an existing field or if type is Number and is new
        if (self.properties.canHaveAggregate || (!self.properties.id && self.properties.typeId == 2 && !self.properties.hasMultipleValues)) self.get_AggregateFunctions();

        // remove required if it is readonly
        if (self.config.restrictions['ReadOnly'].value) {
            if (self.config.restrictions['Required'].value) {
                self.config.restrictions['Required'].value = false;
                self.save();
            }
            self.config.restrictions['Required'].disabled = true;
        }


        if(obj.weatherDateId !== undefined && obj.weatherDateId !== null) {
            self.properties.weatherDateId = obj.weatherDateId;
        }
    }

    var addZ = function (date) {
        var ret = date || null;
        if (date && date.indexOf("Z") == -1)
            ret = date + "Z";
        return ret;
    }

        FieldConfig.prototype.defaultValue = function ($event, index) {
            var self = this;

            var isAdd = typeof (index) != 'undefined' ? false : true;


            var currentIndex = !isAdd ? index : self.properties.multipleDefaultValue.length - 1;
            if (self.properties.multipleDefaultValue[currentIndex]) {
                if ($event) $event.target.blur();
                if (isAdd) {
                    self.properties.multipleDefaultValue.push(null);
                    self.errors.multipleDefaultValue.push("");
                } else self.errors.multipleDefaultValue[currentIndex] = "";
            } else {
                if (isAdd) {
                    self.errors.multipleDefaultValue[currentIndex] = "Please add a value";
                    $timeout(function () {
                        self.errors.multipleDefaultValue[currentIndex] = "";
                    }, 2000);
                    if ($event) $event.target.focus();
                }
                else {
                    self.errors.multipleDefaultValue[currentIndex] = null;
                    if (index !== 0 || self.properties.multipleDefaultValue.length > 1) self.removeDefaultValue(index);
                    else if ($event) $event.target.blur();
                }
            }
        }

        FieldConfig.prototype.removeDefaultValue = function (index) {
            var self = this;
            if (index !== 0 || self.properties.multipleDefaultValue.length > 1) {
                self.properties.multipleDefaultValue.splice(index, 1);
                self.errors.multipleDefaultValue.splice(index, 1);
            } else {
                self.properties.multipleDefaultValue[0] = "";
                self.errors.multipleDefaultValue[0] = "";
            }

        }

    FieldConfig.prototype.updateName = function () {
        var self = this;
        if (!self.editedLabel || self.properties.label == "") {
            self.properties.label = self.properties.name;
            self.errors['label'] = '';
        }
    }

    FieldConfig.prototype.checkMutuallyExclusiveRestrictions = function (r) {
        var self = this;
        if (!r.value && r.key == 1) {
            if (self.config.restrictions['Required']) {
                self.config.restrictions['Required'].value = false;
                self.config.restrictions['Required'].disabled = true;
            }
        } else if (r.key == 1) {
            self.config.restrictions['Required'].disabled = false;
        }
    }

        FieldConfig.prototype.toggleAll = function (item, key) {
            var self = this;
            if (key == 0) {
                item[item.property] = [];
                // item[item.property].push(0);

            }
            else if (item[item.property].indexOf(0) != -1) item[item.property].splice(0, 1);
        }

    FieldConfig.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        var dataURL, params = {}, requiredRestrictionsMissing = false;

        for (var i = 0; i < self.properties.restrictions.length; i++) {
            if (self.properties.restrictions[i].required && !self.properties.restrictions[i].value) {
                requiredRestrictionsMissing = true;
                break;
            }
        }

            var datasourcecheck = true;
            if (self.properties.hasDataSource && !self.properties.dataSourceId) datasourcecheck = false;
            // Validate formula
            var formulaValidation = self.properties.typeId == 9 ? validationService.validateExpression(self.properties.currentFormula, true) : [];

            const weatherFieldValidation = self.properties.typeId == 11 ? (self.properties.weatherDateId !== null && self.properties.weatherDateId !== undefined) : [];


            if (self.properties.name && 
                self.properties.label && 
                self.properties.displayTypeId && 
                !requiredRestrictionsMissing && 
                datasourcecheck && 
                formulaValidation &&
                weatherFieldValidation
            ) {

                // valid field
                var field = angular.copy(self.properties);
                var restrictions = [], formattings = [];

                    // 0 == "" is true, while 0 === "" is false
                    // so === is used here to avoid that 0 is saved as null
                    // only empty string is saved as null
                    if (field.defaultValue === "") field.defaultValue = null;

                    for (var i = 0; i < field.restrictions.length; i++) {
                        if (field.typeId == 4) {
                            //if we have datetime format default value
                            if (field.restrictions[i].key == 5) {
                                if (!field.hasMultipleValues) {
                                    if (field.defaultValue) {
                                        if (field.restrictions[i].value == 3) field.defaultValue = field.defaultValue.format(userService.formats.date);
                                        if (field.restrictions[i].value == 4) field.defaultValue = field.defaultValue.utc().format("YYYY-MM-DD hh:mm:ss A");
                                        if (field.restrictions[i].value == 5) field.defaultValue = field.defaultValue.utc().format(userService.formats.time);
                                    }

                                } else {
                                    if (field.multipleDefaultValue.length) {
                                        for (var j = 0; j < field.multipleDefaultValue.length; j++) {
                                            if (field.multipleDefaultValue[j]) {
                                                if (field.restrictions[i].value == 3) field.multipleDefaultValue[j] = field.multipleDefaultValue[j].format(userService.formats.date);
                                                if (field.restrictions[i].value == 4) field.multipleDefaultValue[j] = field.multipleDefaultValue[j].format("YYYY-MM-DD hh:mm:ss A");
                                                if (field.restrictions[i].value == 5) field.multipleDefaultValue[j] = field.multipleDefaultValue[j].format(userService.formats.time);
                                            } else field.multipleDefaultValue.splice(j, 1);

                                        }

                                    }
                                }
                            }
                        }
                        if (field.restrictions[i].value !== false) {
                            var restriction = {
                                key: field.restrictions[i].key,
                                // mimeTypes:  ?  : null,
                                value: field.restrictions[i].value !== true ? field.restrictions[i].value : null
                            };
                            if (self.properties.typeId == 5) { //attachment
                                if (field.restrictions[i].mimeTypes && field.restrictions[i].mimeTypes.indexOf(0) != -1)
                                    restriction.mimeTypes = [];
                                else restriction.mimeTypes = field.restrictions[i].mimeTypes;
                            }
                            restrictions.push(restriction);
                        }

                    }
                    for (var i = 0; i < field.formattings.length; i++) {
                        if (field.formattings[i].value !== false)
                            formattings.push({
                                key: field.formattings[i].key,
                                value: field.formattings[i].value !== true ? field.formattings[i].value : null
                            });
                    }
                    if (!field.hasMultipleValues) {
                        field.multipleDefaultValue = [];

                        if (field.typeId == 5) { // if attachment
                            if (field.defaultValue && field.defaultValue.length) field.defaultValue = field.defaultValue[0];

                        }
                    } else {
                        field.defaultValue = null;
                        if (field.typeId == 5) { // if attachment
                            if (field.multipleDefaultValue.length) {
                                //var multipleVals = [];
                                //for (var i = 0; i < field.multipleDefaultValue.length; i++) {
                                //    multipleVals.push(field.multipleDefaultValue[i]);
                                //}
                                //field.multipleDefaultValue = multipleVals;
                            }
                        } else {
                            if (field.multipleDefaultValue.length) {
                                if (!field.multipleDefaultValue[field.multipleDefaultValue.length - 1]) field.multipleDefaultValue.pop();
                            }
                        }
                    }
                    field.formattings = formattings;
                    field.restrictions = restrictions;
                    // if (!self.properties.id && self.entityId) field.entityIds.push(self.entityId);
                    self.isBusy = true;

                    // Generate expression in case field is formula
                    if (self.properties.typeId == 9) {
                        field.expression = formulaValidation[0];
                        field.expressionFields = formulaValidation[2];
                    }

                    if (self.tableId) {
                        dataURL = self.properties.id ? URI.FIELD_DEFINITION.EDIT_FIELD : URI.FIELD_DEFINITION.ADD_FIELD;
                        params = {
                            url: { id: self.tableId }, urltype: 'obj', body: field
                        };
                    } else {
                        if (!self.properties.screenId) {
                            dataURL = self.properties.id ? URI.FIELD_DEFINITION.EDIT : URI.FIELD_DEFINITION.ADD;
                            params = { body: field };
                        }
                        else {
                            dataURL = self.properties.id ? URI.SCREEN_FIELDS.UPDATE : URI.FIELD_DEFINITION.ADD;
                            params = { url: { screenId: self.properties.screenId }, urltype: 'obj', body: field }
                        }
                    }

                    self[dataURL.method](dataURL, params, { headers: { moduleId: self.moduleId } })
                        .then(function (r) {
                            Message.info("Field saved successfully.");
                            if (r) {
                                self.properties.id = r;
                                field.id = r;
                            }
                            if (ModuleDictionaries.dataSources["actionFields"].isLoaded) ModuleDictionaries.get_dataSource("actionFields");
                            p.resolve(field);
                        })
                        .catch(function (e) {
                            Message.dberror(e);
                            console.error(e);
                            p.reject();
                        })
                        .finally(function () {
                            self.isBusy = false;
                        });
                } else {
                    self.errors = {
                        name: !self.properties.name ? 'This field is required' : '',
                        label: !self.properties.label ? 'This field is required' : '',
                        dataSourceId: !datasourcecheck ? 'This field is required' : '',
                        weatherDateId: !self.properties.weatherDateId ? 'This field is required' : '',
                        defaultValue: "",
                        multipleDefaultValue: [''],
                    };
                    if (!self.properties.displayTypeId) Message.error('Please select a display type');
                    if (requiredRestrictionsMissing) Message.error('Please select the restriction(s)');
                    p.reject();
                }

            return p.promise;

        }

        FieldConfig.prototype.checkDataSourceParentDefault = function () {
            var self = this;
            var p = $q.defer();
            var dataURL = URI.SCREEN_FIELDS.DATASOURCE_HAS_DEFAULT_VALUE;
            self[dataURL.method](dataURL, { url: { id: ModuleDictionaries.dataSources.selectsDataSources.lookup[self.properties.dataSourceId].parentId, screenId: self.properties.screenId }, urltype: 'obj' })
                .then(function (r) {
                    p.resolve(r);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                })
                .finally(function () {

                })
            return p.promise;

    }

        FieldConfig.prototype.init_DataSourceElements = function (isInit, value) {
            var self = this;
            value = value || self.properties.dataSourceId;
            var p = $q.defer();

        self.currentDataSourceElems = [];
        self.currentDataSourceElemsLoaded = false;

            if (value) {
                // if data source has no parent
                if (!ModuleDictionaries.dataSources.selectsDataSources.lookup[value].parentName) {
                    self.get_DataSourceElements({ id: value })
                        .then(function (r) {
                            self.currentDataSourceElems = r;
                            if (!isInit) {
                                self.properties.multipleDefaultValue = [];
                                self.properties.defaultValue = null;
                            }
                            p.resolve();
                        }).catch(function () { p.reject(); })
                }
                // if data source has parent and field is on screen
                else if (self.properties.screenId) {
                    self.checkDataSourceParentDefault()
                        .then(function (r) {
                            self.get_DataSourceElements({
                                id: value,
                                parentElementIds: r,
                                parentListId: ModuleDictionaries.dataSources.selectsDataSources.lookup[value].parentId

                            })
                                .then(function (r) {
                                    self.currentDataSourceElems = r;
                                    if (!isInit) {
                                        self.properties.multipleDefaultValue = [];
                                        self.properties.defaultValue = null;
                                    }
                                    p.resolve();
                                }).catch(function () { p.reject(); })
                        }).catch(function () { p.reject(); })
                }
                // if data source has parent and the field is not on a screen
                else {
                    self.properties.multipleDefaultValue = [];
                    self.properties.defaultValue = null;
                    p.resolve();
                }
            }
            else p.resolve();


        return p.promise;

    }

    FieldConfig.prototype.get_DataSourceElements = function (bodyParams) {
        var self = this;
        var p = $q.defer();

        var dataURL = URI.LISTS.GET_ELEMENTS;
        var bodyParams = bodyParams || {};
        self.loadingElements = true;
        self[dataURL.method](dataURL, { url: {}, body: bodyParams, urltype: 'obj' })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                self.loadingElements = false;
                self.currentDataSourceElemsLoaded = true;

            })

        return p.promise;
    }

    FieldConfig.prototype.get_AggregateFunctions = function () {
        var self = this;
        var p = $q.defer();

        self.currentAggregateFunctions = [];

        var dataURL = URI.FIELD_DEFINITION.AGGREGATE_DICT;
        self.loadingElements = true;
        self[dataURL.method](dataURL)
            .then(function (r) {
                self.currentAggregateFunctions = r;
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                self.loadingElements = false;
            })

        return p.promise;
    }

    // to go
    FieldConfig.prototype.getListElements = function (isInit) {
        var self = this;
        var p = $q.defer();
        self.currentDataSourceElems = [];
        self.currentDataSourceElemsLoaded = false;

        if (self.properties.screenId || !ModuleDictionaries.dataSources.selectsDataSources.lookup[self.properties.dataSourceId].parentName) {
            if (ModuleDictionaries.dataSources.selectsDataSources.lookup[self.properties.dataSourceId].parentName)
                self.checkDataSourceParentDefault();
            var dataURL = URI.LISTS.GET_ELEMENTS;
            if (self.properties.dataSourceId) {
                self.loadingElements = true;
                self[dataURL.method](dataURL, { url: {}, body: { id: self.properties.dataSourceId }, urltype: 'obj' })
                    .then(function (r) {
                        self.currentDataSourceElems = r;

                        if (!isInit) {
                            self.properties.multipleDefaultValue = [];
                            self.properties.defaultValue = null;
                        }


                        p.resolve(r);
                    })
                    .catch(function (e) {
                        console.error(e);
                        p.reject(e);
                    })
                    .finally(function () {
                        self.loadingElements = false;
                        self.currentDataSourceElemsLoaded = true;

                        })
                }
            } else {
                self.properties.multipleDefaultValue = [];
                self.properties.defaultValue = null;
                p.resolve();
            }

            return p.promise;
        }

        FieldConfig.prototype.get_Details = function (field) {
            var self = this, obj;
            var p = $q.defer();
            self.isBusy = true;
            if (!field) {
                obj = { id: self.properties.id, screenId: self.properties.screenId };
            } else {
                obj = field;
            }
            var dataURL = obj.screenId ? URI.SCREEN_FIELDS.GET : URI.FIELD_DEFINITION.GET;
            var urlParams = { id: obj.id };
            if (obj.screenId) urlParams.screenId = obj.screenId;
            self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
                .then(function (r) {
                    if ((r || {}).typeId == 2) {
                        if (r.defaultValue !== null) r.defaultValue = parseFloat(r.defaultValue);
                        if ((r.multipleDefaultValue || []).length)
                            for (var i = 0; i < r.multipleDefaultValue.length; i++) {
                                r.multipleDefaultValue[i] = parseFloat(r.multipleDefaultValue[i]);
                            }
                    }
                    if (!field) { // is reload
                        r.screenId = obj.screenId;
                        self.process_existingField(r);
                        self.init(r);
                    }

                    p.resolve(r);
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                })
                .finally(function () {
                    self.isBusy = false;
                    froalaService.ignoreImageDeletion();
                })
            return p.promise;
        }

    return FieldConfig;
});
