import { cipo } from 'cipo';

cipo.controller('modulesController',
    function ($scope, $state, rememberManagerService, $http, $q, Functions, ACTIONS, dialogs, URI, ICONS, Message, ModuleN, Manager, Menu, userService, FileSaver, Blob, $mdDialog) {

        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.module = userService.system.modules[$state.current.code];
        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor($state.current.code); }
        });

        $scope.selectedIndex = 0;
        $scope.iconsLookup = userService.system.iconsLookup;
        $scope.showDisabled = true;
        //$scope.moduleType = 0;
        $scope.sendList = function (data) {
            var listToSend = [];
            if (Object.prototype.toString.call(data) === '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].id !== 'undefined') {
                            listToSend.push(data[i].id);
                        } else if (typeof data[i].moduleId != 'undefined') {
                            listToSend.push(data[i].moduleId);
                        }
                    }
                }
            } else {
                if (typeof data.id != 'undefined') {
                    listToSend.push(data.id);
                } else if (typeof data.moduleId !== 'undefined') {
                    listToSend.push(data.moduleId);
                }
            }
            return listToSend;
        };

        $scope.loadManagerData = function () {
            //console.error('param for manager', $scope.moduleType === 0 ? true : false);
            $scope.manager = new Manager({
                objectsPerPage: 20,
                dataURL: URI.MODULES.SEARCH,
                /* urlParams: {
                    isSystem: $scope.moduleType === 0 ? true : false
                },*/
                dataWrapper: "data",
                options: {
                    multiselect: $scope.operations.Update ? true : false
                },
                otherParams: {
                    ShowDisabled: $scope.showDisabled,
                    filterUsed: false
                },
                /*rowClass: function (row) {
                    if (row.iconId) { return $scope.icons[row.iconId-1].cssClass; }
                    else return "fa fa-th-large";
                },*/
                rowOnClick: function (row) {
                    $scope.editModule(row);
                },

                statusFilter: {
                    model: $scope.showDisabled,
                    name: "Filter",
                    managerParam: "ShowDisabled",
                    dataArr: [
                        {
                            Key: true,
                            Val: "Show All"
                        },
                        {
                            Key: false,
                            Val: "Show Active"
                        }
                    ],
                    type: "select"
                },
                filters: [
                ],
                leftActions: [
                    {
                        setProperties: ACTIONS.CREATE,
                        isGroup: true,
                        conditionOnTop: function () {
                            if ($scope.operations.Create) return true;
                            else return false;
                        },
                        actions: [
                            {
                                setProperties: ACTIONS.CREATE_IN_DROPDOWN,
                                name: "Add Module",
                                click: function () {
                                    $scope.editModule(null, false);
                                }
                            },
                            {
                                setProperties: ACTIONS.CREATE_URL,
                                name: "Add URL",
                                click: function () {
                                    $scope.editModule(null, true);
                                }
                            },
                            

                        ]
                    }
                ],
                actions: [
                    {
                        setProperties: ACTIONS.REFRESH,
                        click: function () {
                            $scope.manager.refresh();
                        }
                    },

                    {
                        setProperties: ACTIONS.DETAILS,
                        click: function (row) {
                            $scope.editModule(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.DUPLICATE,
                        displayOnRow: $scope.operations.Create ? true : false,
                        condition: function (row) {
                            if (row.isSystem || row.isUrl)
                                return false;
                            else return true;
                        },
                        click: function (row) {
                            $scope.duplicateModule(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.ACTIVATE,
                        displayOnRow: $scope.operations.Update ? true : false,
                        condition: function (row) {
                            if (row.disabled)
                                return true;
                            else return false;
                        },
                        conditionOnTop: function () {
                            if ($scope.operations.Update) return true;
                            else return false;
                        },
                        click: function (rows) {
                            $scope.activateModule($scope.sendList(rows));
                        }
                    },
                    {
                        setProperties: ACTIONS.DEACTIVATE,
                        displayOnRow: $scope.operations.Update ? true : false,
                        condition: function (row) {
                            if (!row.disabled)
                                return true;
                            else return false;
                        },
                        conditionOnTop: function () {
                            if ($scope.operations.Update) return true;
                            else return false;
                        },
                        click: function (rows) {
                            $scope.deactivateModule($scope.sendList(rows));
                        }
                    },
                    {
                        setProperties: ACTIONS.EXPORT,
                        displayOnRow: $scope.operations.Update ? true : false,
                        click: function (rows) {
                            $scope.export(rows);
                        }
                    },
                    {
                        setProperties: ACTIONS.DELETE,

                        conditionOnTop: function () {
                            if ($scope.operations.Delete) return true;
                            else return false;
                        },
                        displayOnRow: $scope.operations.Delete ? true : false,
                        condition: function (row) {
                            if (!row.isUrl === false || !row.isSystem)
                                return true;
                            else return false;
                        },
                        click: function (rows) {
                            $scope.deleteModule($scope.sendList(rows));
                        }
                    },
                    {
                        setProperties: ACTIONS.MORE,
                        conditionOnTop: function () {
                            if ($scope.operations.Update) return true;
                            else return false;
                        },
                        actions: [
                            {
                                setProperties: ACTIONS.OPENUPLOADDIALOG,
                                name: 'Import',
                                accept: "application/json",
                                uploadUrl: URI.MODULES.IMPORT,
                                // urlParams: function() { return {}; },
                                click: function (rows) {
                                    $scope.import();
                                }
                            },
                            {
                                setProperties: ACTIONS.EXPORT,
                                click: function (rows) {
                                    $scope.export(rows);
                                }
                            },
                            {
                                setProperties: ACTIONS.ACTIVATE,
                                click: function (rows) {
                                    $scope.activateModule($scope.sendList(rows));
                                }
                            },
                            {
                                setProperties: ACTIONS.DEACTIVATE,
                                click: function (rows) {
                                    $scope.deactivateModule($scope.sendList(rows));
                                }
                            }

                        ]
                    }
                ],
                parseData: function (data, columns) {
                    var data = data || [], retval = [];
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].iconId) data[i]._name = '<i class="tableIcon ' + $scope.iconsLookup[data[i].iconId].class + '" style="color: ' + data[i].color +  '"></i>' + data[i].name;
                        else data[i]._name = '<i class="tableIcon mdi mdi-square-outline"></i>' + data[i].name;
                        retval.push(data[i]);
                    }
                    return retval;
                },
            });
            var statusLookup = {
                false: { name: 'Active', class: 'basic' },
                true: { name: 'Deactivated', class: 'deactivated' }
            }
            var typeLookup = {
                false: { name: 'System Module' },
                true: { name: 'URL' }
            }

            var columns = [
                { name: '_name', label: 'Name', type: 'checkbox', replacementFor: 'name', onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                { name: 'abbreviation', label: 'Abbreviation', type: 'text', onTablet: true, onPhone: false, width: 20, fieldTypeId: 1 },
                { name: 'type', label: 'Type', type: 'text', onTablet: true, onPhone: false, width: 20, fieldTypeId: 1 },
                { name: 'disabled', label: 'Status', type: 'status', lookupArr: statusLookup, onTablet: true, onPhone: true, width: 20 }
            ];
            $scope.manager.set_Columns(columns);



            if (rememberManagerService.moduleId == $scope.module.moduleId) {
                $scope.manager = rememberManagerService.get();
                $scope.manager.loading = false;
            } else {
                
                $scope.manager.loadPage();
                rememberManagerService.set($scope.manager, $scope.module.moduleId);
            }

            
        }

        $scope.loadManagerData();


        $scope.editModule = function (obj, isUrl, ev) {
            var obj = obj || null;
            var isUrl = obj ? obj.isUrl : isUrl;

            if ((obj && !obj.isUrl) || !isUrl) {
                $mdDialog.show({
                    locals: {
                        obj: obj, simplifiedVersion: false,
                        operations: $scope.operations, module: $scope.module
                    },
                    controller: 'editNModuleController',
                    templateUrl: '/ng/views/admin/modals/editNModule.html',
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    fullscreen: true,
                    focusOnOpen: false,
                    escapeToClose: false,
                    multiple: true,
                    clickOutsideToClose: false
                })
                    .then(function (result) {
                        //$scope.manager.page = 1;
                        if (result.properties) {
                            $scope.duplicateModule(result.properties);
                        }
                        $scope.manager.loadPage();
                        Menu.triggerRefresh();
                    }, function () {
                        Menu.triggerRefresh();
                        //$scope.manager.loadPage();
                    });
            }
            else {
                $mdDialog.show({
                    locals: { obj: obj, simplifiedVersion: false, operations: $scope.operations, module: $scope.module },
                    controller: 'editUrlController',
                    templateUrl: '/ng/views/admin/modals/editUrl.html',
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    fullscreen: true,
                    escapeToClose: false,
                    clickOutsideToClose: false
                })
                    .then(function (result) {
                        $scope.manager.page = 1;
                        $scope.manager.loadPage();
                        Menu.triggerRefresh();
                    }, function () {
                        Menu.triggerRefresh();
                        $scope.manager.loadPage();
                    });
            }
        };

        var confirm = function (title, text, ev) {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        };
        //- Details
        //    - Roles
        //    - Relations
        //    - Fields
        //    - Workflow
        //    - Printouts
        //    - Notifications

        //ModuleConfigurationSections

        var initialTabList = [
            { key: 1, value: "Details", selected: true },
            { key: 2, value: "Roles", selected: false },
            { key: 3, value: "Relations", selected: false },
            {
                key: 4, value: "Fields", selected: false,
                dependencies: [
                    { key: 8, value: "Forms", selected: false },
                    { key: 9, value: "Data List Settings", selected: false }]
            },
            {
                key: 5, value: "Workflow", selected: false,
                dependencies: [
                    { key: 10, value: "Transition Groups", selected: false },
                    { key: 11, value: "Signatures", selected: false }]
            },
            { key: 6, value: "Printouts", selected: false },
            { key: 7, value: "Notifications", selected: false }
        ];

            $scope.changeSelectState = function (isDeselect) {
                for (var i = 0; i < $scope.tabsList.length; i++) {
                    if (!$scope.tabsList[i].disabled) $scope.tabsList[i].selected = isDeselect ? false : true;
                    if (($scope.tabsList[i].dependencies || []).length) {
                        for (var j = 0; j < $scope.tabsList[i].dependencies.length; j++) {
                            if (!$scope.tabsList[i].dependencies[j].disabled) $scope.tabsList[i].dependencies[j].selected = isDeselect ? false : true;
                        }
                    }
                }
                if (!isDeselect) $scope.sectionError = false;
                if ($scope.duplicateMod)
                    if (isDeselect) $scope.duplicateMod.set_properties({
                        name: $scope.duplicateMod.properties.name,
                        abbreviation: $scope.duplicateMod.properties.abbreviation
                    });
                    else $scope.duplicateMod.set_properties(extractInfoFromModule($scope.sourceMod));
            }

        $scope.hide = function () {
            $mdDialog.hide();
            clearInfo();
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
            clearInfo();
        };

        var extractInfoFromModule = function (m) {
            var newMod = {};
            var excludeProps = ['id', 'name', 'abbreviation', '_name', 'selected'];
            for (var key in m) {
                if (m.hasOwnProperty(key) && excludeProps.indexOf(key) == -1) {
                    newMod[key] = m[key];
                }
            }
            if ($scope.duplicateMod) {
                newMod.name = $scope.duplicateMod.properties.name;
                newMod.abbreviation = $scope.duplicateMod.properties.abbreviation;
            }
            return newMod;
        }

        $scope.duplicateModule = function (m) {
            $scope.sourceMod = m;
            $scope.duplicateMod = new ModuleN(extractInfoFromModule(m));
            if (m.iconId) $scope.selectedIcon = $scope.iconsLookup[m.iconId];
            $scope.showTabsSelection();
        };

        $scope.checkTabDependencies = function (t) {
            if (t.selected) $scope.sectionError = false;
            if (!t.selected && (t.dependencies || []).length)
                for (var i = 0; i < t.dependencies.length; i++) {
                    t.dependencies[i].selected = false;
                }
            if (t.key == 1 && $scope.duplicateMod) {
                if (t.selected) $scope.duplicateMod.set_properties(extractInfoFromModule($scope.sourceMod));
                else $scope.duplicateMod.set_properties({
                    name: $scope.duplicateMod.properties.name,
                    abbreviation: $scope.duplicateMod.properties.abbreviation
                });
            }
        }

        $scope.icons = userService.system.icons;
        // $scope.iconsLookup = userService.system.iconsLookup;

        $scope.moreIcons = { show: false };
        $scope.setIcon = function (icon) {
            $scope.duplicateMod.properties.iconId = icon.key;
            $scope.selectedIcon = icon;
        };

        var processSections = function () {
            var list = [];
            for (var i = 0; i < $scope.tabsList.length; i++) {
                if ($scope.tabsList[i].selected) list.push($scope.tabsList[i].key);
                if (($scope.tabsList[i].dependencies || []).length) {
                    for (var j = 0; j < $scope.tabsList[i].dependencies.length; j++) {
                        if ($scope.tabsList[i].dependencies[j].selected)
                            list.push($scope.tabsList[i].dependencies[j].key);
                    }
                }
            }
            return list;
        }

        $scope.saveDuplicate = function () {
            $scope.sectionError = false;
            $scope.duplicateMod.form.validate();
            if ($scope.duplicateMod.form.isValid && processSections().length) {
                // $scope.duplicateMod.isBusy = true;
                $scope.duplicateMod.form.loading = true;
                var dataURL = URI.MODULES.COPY;
                var urlParams = {
                    roleId: $scope.duplicateMod.selectedRoleId,
                    id: $scope.sourceMod.id
                };
                var bodyParams = {
                    details: $scope.duplicateMod.properties,
                    sections: processSections()
                }

                $scope.duplicateMod[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: bodyParams })
                    .then(function (r) {
                        Message.info('Module duplicated successfully');
                        $scope.hide();
                    })
                    .catch(function (e) {
                        $scope.duplicateMod.form.catch(e);
                        $scope.duplicateMod.form.loading = false;
                    });
            } else {
                if (!processSections().length) $scope.sectionError = true;
            }
            
        }
        $scope.changeSelectedRole = function (r) {
            $scope.selectedRole = r || ($scope.module.roles || [])[0];
            ($scope.duplicateMod || {}).selectedRoleId = $scope.selectedRole.key;
        };
        //  $scope.changeSelectedRole();

        $scope.showTabsSelection = function (ev, row) {
            $scope.tabsList = angular.copy(initialTabList);
            if (!$scope.duplicateMod) $scope.tabsList[0].disabled = true;
            $scope.changeSelectedRole();
            $mdDialog.show({
                contentElement: '#exportDetails',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false
            })
                .then(function () {
                    $scope.manager.loadPage();
                    clearInfo();
                })
                .catch(function (e) {
                    clearInfo();
                });
        };

        var clearInfo = function () {
            $scope.duplicateMod = null;
            $scope.selectedRole = null;
            $scope.selectedIcon = null;
            $scope.exportList = null;
            if($scope.tabsList) $scope.tabsList = angular.copy(initialTabList);
            $scope.sectionError = null;
        }

        $scope.export = function (data) {
            var list = [];
            
            if (Object.prototype.toString.call(data) !== '[object Array]')
                list.push(data)
            else list = data;

            if (list.length) {
                for (var i = 0; i < list.length; i++) {
                    if (list[i].isSystem || list[i].isUrl) list[i].selected = false;
                    else list[i].selected = true;
                }
                $scope.exportList = list;
                $scope.showTabsSelection();
            }
            else {
                Message.warning('Please select the item(s) to export');
            }
        }

        $scope.saveExport = function () {
            var ids = [];

            for (var i = 0; i < $scope.exportList.length; i++) {
                if ($scope.exportList[i].selected) ids.push($scope.exportList[i].id);
            }

            if (ids.length) {
                $scope.isSavingExport = true;
                var dataURL = URI.MODULES.EXPORT;
                var url = dataURL.toString() + "?roleId=" + $scope.selectedRole.key;
                // urlParams = { roleId: $scope.selectedRole.key };
                var bodyParams = {
                    ids: ids,
                    sections: processSections()
                };

                $http[dataURL.method](url, bodyParams, { responseType: "arraybuffer" })
                    .then(function (r) {
                        var type = r.headers('Content-Type');
                        var disposition = r.headers('Content-Disposition');
                        if (disposition) {
                            var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                            if (match[1])
                                defaultFileName = match[1];
                        }
                        defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
                        var blob = new Blob([r.data], { type: type });
                        FileSaver.saveAs(blob, defaultFileName);
                        $scope.hide();
                    })
                    .catch(function (e) {
                        Message.dberror(e);
                    })
                    .finally(function () {
                        $scope.isSavingExport = false;
                    })

            } else {
                Message.warning('Please select the item(s) to export');
            }

        }

        $scope.deactivateModule = function (list) {
            var url = URI.MODULES.DISABLE;

            list = list || [];

            if (list.length) {
                //var d = dialogs.confirm('Deactivate Module', 'Are you sure you want to deactivate the selected module(s)?');
                $mdDialog.show(confirm('Disable Modules', 'Are you sure you want to disable the selected Module(s)?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[url.method](url, { list: list })
                            .then(function () {
                                $scope.manager.loadPage();
                                Message.info('Module(s) disabled successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(e, $scope.manager.rows, 'id', 'name');
                            });
                    })
                    .catch(function (e) {
                        //cancel pressed
                    });


            } else {
                Message.warning('Please select the item(s) to disable');
            }
        };

        $scope.deleteModule = function (list) {
            list = list || [];

            if (list.length) {
                //var d = dialogs.confirm('Delete Module', 'Are you sure you want to delete the selected module(s)?');
                $mdDialog.show(confirm('Delete Modules', 'Are you sure you want to delete the selected Module(s)?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[URI.MODULES.DELETE.method](URI.MODULES.DELETE, { list: list })
                            .then(function () {
                                $scope.manager.loadPage();
                                Message.info('Module(s) deleted successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(e, $scope.manager.rows, 'id', 'name');
                            });
                    })
                    .catch(function () {
                        // cancel pressed
                    });
            } else {
                Message.warning('Please select the item(s) to delete');
            }
        };

        $scope.activateModule = function (list) {
            var url = URI.MODULES.ENABLE;
            list = list || [];

            if (list.length) {
                //var d = dialogs.confirm('Activate Module', 'Are you sure you want to activate the selected module(s)?');
                $mdDialog.show(confirm('Enable Modules', 'Are you sure you want to enable the selected Module(s)?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[url.method](url, { list: list })
                            .then(function () {
                                $scope.manager.loadPage();
                                Message.info('Module(s) activated successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(ee, $scope.manager.rows, 'id', 'name');
                            });
                    })
                    .catch(function () {
                        // cancel pressed
                    });



            } else {
                Message.warning('Please select the item(s) to activate');
            }
        };

    });
