import { cipo } from 'cipo';

cipo.factory("ListManagement", function ($q, Model, URI, $http, $timeout, FileSaver, Blob, Upload, Message, Dictionaries, List, ListRelation, ListElements) {
    var ListManagement = Model.extend(function (obj) {
        var self = this;
        self.list = new List(obj);

        

        self.properties = {};
        Object.defineProperty(self.properties, 'name', {
            get: function () {
                return self.list.properties.name;
            }
        });
    })

    ListManagement.prototype.exportAsExcel = function (isTemplate) {
        var self = this;
        var dataURL = isTemplate ? URI.LISTS.EXPORT_LIST_TEMPLATE : URI.LISTS.EXPORT_LIST;
        var urlParam = '?id=' + self.list.properties.id;
        var defaultFileName = 'test';
        self.isBusy = true;
        $http[dataURL.method](dataURL.toString() + urlParam, { responseType: "arraybuffer" })
            .then(function (r) {
                var type = r.headers('Content-Type');
                var disposition = r.headers('Content-Disposition');
                if (disposition) {
                    var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                    if (match[1])
                        defaultFileName = match[1];
                }
                defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
                var blob = new Blob([r.data], { type: type });
                FileSaver.saveAs(blob, defaultFileName);
                // p.resolve(defaultFileName);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            });
    }

    ListManagement.prototype.uploadFiles = function (file, errFiles) {
        var self = this;
        var f = file;
        var errFile = errFiles && errFiles[0];
        // $scope.importLoader = true;
        self.uploadMessage = 'Import in progress. This may take a few minutes';
        var uploadUri = URI.LISTS.IMPORT_LIST;
        var params = '?id=' + self.list.properties.id;
        self.isBusy = true;
        if (file) {
            file.upload = Upload.upload({
                method: uploadUri.method,
                url: uploadUri.toString() + params,
                data: { file: file }
            });

            file.upload.then(function (response) {
                self.isBusy = false;
                $timeout(function () {
                    file.result = response.data;
                    Message.info("File imported successfully");
                    self.uploadMessage = "";
                    self.list.getElements();
                });
            }, function (response) {
                    Message.dberror(response.data);
                    self.isBusy = false;
                //var errorMsg = response.data.messages[0].message;

                //Message.error(`Import failed. ${errorMsg}`);

                self.uploadMessage = "";
                // $scope.loadSov();
            }, function (evt) {
                file.progress = Math.min(100, parseInt(100.0 *
                    evt.loaded / evt.total));
            });
        }
    }

    // relations
    ListManagement.prototype.get_RelationsList = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.LISTS.SEARCH_RELATIONS;

        ListElements.currentListDetails = self.list.properties;
        ListElements.currentListElements = self.list.properties.elements;

        self.isBusy = true;
        self[urlData.method](urlData, { url: { id: self.list.properties.id }, urltype: 'obj', body: {} })
            .then(function (r) {
                p.resolve();
                for (var i = 0; i < ((r || {}).data || []).length; i++) {
                    if (r.data[i].parentListId == self.list.properties.id) r.data[i].isParent = true;
                    else r.data[i].isParent = false;
                    r.data[i] = new ListRelation(r.data[i]);
                }
                self.relationsList = r.data;

            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isRelationsLoaded = true;
                self.isBusy = false;
            })


        return p.promise;

    }

    ListManagement.prototype.new_Relation = function (obj) {
        var relation = new ListRelation(obj);
        return relation;
    }

    ListManagement.prototype.createNewChildList = function () {
        var list = new List();
        this.newItem = list;
        this.isBusy = true;
    }

    ListManagement.prototype.relation_Action = function (item, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting relation..." : "Saving relation...";
        var action;
        if (item.isList) action = item.save();
        else action = isDelete ? item.delete() : item.save();
        action
            .then(function (r) {
                if (item.isList) {
                    var relation = new ListRelation({
                        parentListId: self.list.properties.id,
                        parentName: self.list.properties.name,
                        childListId: r,
                        childName: item.properties.name
                    });

                    relation.save(true)
                        .then(function () {                                
                            self.get_RelationsList();
                            self.newItem = relation;                             

                            p.resolve();
                        })
                        .catch(function (e) {
                            p.reject(e);
                        })

                } else {
                    self.get_RelationsList();
                    // screen.editable = false;
                    delete item.form;
                    p.resolve();
                }




            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    ListManagement.prototype.gotoListView = function () {
        var self = this;
        self.currentItem = null;
    }


    return ListManagement;

})
