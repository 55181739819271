import { cipo } from 'cipo';

cipo.factory('printService', [function () {

    const printId = "printId";
    
    var printService = {};

    printService.setPrintLayout = function(isLandscape) {
        const style = document.createElement("style");
        style.id = printId;
        style.innerHTML = isLandscape ? "@page { size: landscape; }" : "@page { size: portrait; }";
        document.head.append(style);
    }

    printService.clearPrintStyle = function() {
        const style = document.getElementById(printId);
        if(style !== null) {
            style.remove();
        }
    }

    return printService;
}]);