import { cipo } from 'cipo';

cipo.directive("managerGrid", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "manager": "="
        },
        templateUrl: "/ng/views/directives/system/gridmanager.html",
        controller: function ($scope, $mdDialog, userService, $timeout, Message) {
            $scope.mobileForm = false;
            $scope.toggleMobileForm = function () {
                // if ($scope.mobileForm) $scope.manager.criteria = "";
                $scope.mobileForm = !$scope.mobileForm;
            }
            $scope.searchType = function (event) {
                event.stopPropagation();
            }
            $scope.grid = {
                allselected: false
            };

            Object.defineProperty($scope, "isIndeterminateSelected", {
                get: function () {
                    var isIndeterminateSelected = false, x = false;

                        for (var i = 0; i < (($scope.manager || {}).rows || []).length; i++) {
                            x = $scope.manager.rows[i].selected;
                            break;
                        }

                        for (var i = 0; i < (($scope.manager || {}).rows || []).length; i++) {
                            if (x != $scope.manager.rows[i].selected) {
                                isIndeterminateSelected = true;
                                break;
                            }
                        }
                        return isIndeterminateSelected;
                    }
                })

                Object.defineProperty($scope, "isAllSelected", {
                    get: function () {
                        var isAllSelected = false;
                        for (var i = 0; i < (($scope.manager || {}).rows || []).length; i++) {
                            isAllSelected = true;
                            break;
                        }
                        // if ((($scope.manager || {}).rows || []).length) isAllSelected = true;

                        for (var i = 0; i < (($scope.manager || {}).rows || []).length; i++) {
                            isAllSelected = isAllSelected && $scope.manager.rows[i].selected;
                        }
                        return isAllSelected;
                    },
                    set: function (value) {
                        for (var i = 0; i < $scope.manager.rows.length; i++) {
                            $scope.manager.rows[i].selected = value;
                        }
                    }
                })

            if (!$scope.manager) {
                $scope.pseudoLoading = true;
            }
            if (userService.system.userdata.contractId)
                $scope.currencySymbol = userService.getCurrency();

            $scope.$watch(function () {
                return userService.system.userdata.contractId;
            },
                function (newVal) {
                    if (typeof newVal != 'undefined') {
                        $scope.currencySymbol = userService.getCurrency();
                    }
                });

            $scope.$watch(function () {
                return userService.isReloadModuleInformation;
            },
                function (newVal) {
                    if (newVal) {
                        $scope.manager.reloadInformation();
                        userService.isReloadModuleInformation = false;
                    }
                });

            $scope.$watch(function () {
                return userService.system.userdata.loadMore;
            },
                function (n, o) {
                    if ($scope.manager && n != o) $scope.manager.goToPage(1);
                });

            $scope.toggleSelect = function (i, e) {
                e.stopPropagation();
                if ($scope.checkAllSelected() == true) $scope.grid.allselected = true;
                else $scope.grid.allselected = false;
                // console.log("toggleSelect ", $scope.grid.allselected);
                return;
            }
            $scope.openMenu = function ($mdMenu, ev) {
                // originatorEv = ev;
                $mdMenu.open(ev);
            };
            $scope.$watch("manager.loading", function (newValue, oldValue) {
                $scope.grid.allselected = false;
                if (typeof newValue != "undefined") $scope.pseudoLoading = false;
            });

            $scope.$watch(function () { return $scope.grid.allselected; }, function (newValue, oldValue) {
                if (newValue != oldValue) $scope.toggleAllSelect();
            });

            $scope.checkAllSelected = function () {
                var selected = true;

                    for (var i = 0; i < $scope.manager.rows.length; i++) {
                        selected = (selected && $scope.manager.rows[i].selected);
                    }
                    // console.log("checkAllSelected ", selected);
                    return selected;
                }

                $scope.toggleAllSelect = function () {
                    for (var i = 0; i < $scope.manager.rows.length; i++) {
                        $scope.manager.rows[i].selected = $scope.grid.allselected;
                    }
                    return;
                }
                $scope.setLayout = function (option) {
                    $scope.manager.setLayout(option);
                }
                $scope.dateFilter = 'MM/dd/yy';
                $scope.getStatusClassOf = function (value) {
                    var value = value || 'plain';
                    var possible = ['danger', 'primary', 'success', 'information', 'warning', 'basic', 'plain', 'deactivated', 'none'];

                if (possible.indexOf(value) == -1) value = 'plain';
                return 'label label-' + value;
            }
            $scope.table = undefined;
            $scope.resizeMode = 'OverflowResizer';
            var timer = null;
            $scope.searchStart = function (event) {  
                
                event.stopPropagation();
                if (event.keyCode != 13) {

                    if (timer) $timeout.cancel(timer);
                    timer = $timeout(function () {
                        if (event) event.target.blur();
                        $scope.loadNewCriteria();
                        $timeout.cancel(timer);
                    }, 3000);
                } else {
                    if (event) event.target.blur();
                    if (timer) $timeout.cancel(timer);
                    $scope.loadNewCriteria();
                }
            }

            $scope.setGlobalSearchParam = function (isToggle) {
                
                if (isToggle) {
                    $scope.manager.isGlobalSearch = !$scope.manager.isGlobalSearch;
                }
                // self.urlParams[self.globalSearchParam] = self.isGlobalSearch;
                $scope.manager.dataParams[$scope.manager.globalSearchParam] = $scope.manager.isGlobalSearch;
                if ($scope.manager.criteria) $scope.loadNewCriteria();
                if ($scope.manager.hasFilters) $scope.manager.loadPage();
            }

            $scope.loadNewCriteria = function (event, isKeypress) {
                if (timer) $timeout.cancel(timer);
                if ($scope.manager.criteria) {
                    $scope.manager.searchObject = {};
                    $scope.manager.searchObject.dataParams = angular.copy($scope.manager.dataParams);
                    $scope.manager.searchObject.dataParams.criteria = $scope.manager.criteria;
                    $scope.manager.searchObject.urlParams = angular.copy($scope.manager.urlParams);
                    $scope.manager.searchObject.additionalSearchInfo = angular.copy($scope.manager.additionalSearchInfo);
                    $scope.manager.searchObject.isSearchView = true;

                } else {
                    $scope.manager.searchObject = null;
                }

                if (!$scope.manager.criteria && $scope.manager.isGlobalSearch) $scope.manager.isGlobalSearch = false;

                    if(event) event.stopPropagation();
                    $scope.grid.allselected = false;
                    $scope.manager.page = 1;
                    $scope.manager.loadPage();
                    if ($scope.mobileForm) $scope.toggleMobileForm();
                }
                $scope.keypress = function (event) {

                if ($scope.manager.criteria == "") {
                    $scope.loadNewCriteria(event, true);
                }
            }

            $scope.upload = function (a, ev) {
                // var self = this;
                var acceptedMimeTypes = $scope.manager.dataParams.mimeTypes || [];
                if (a.uploadUrl) {
                    $mdDialog.show({
                        locals: { action: a, acceptedMimeTypes: acceptedMimeTypes },
                        controller: a.isImport ? 'importUploadController' : 'fmUploadController',
                        templateUrl: '/ng/views/admin/modals/fmUpload.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        fullscreen: true,
                        multiple: a.multiple || false,
                        escapeToClose: false,
                        clickOutsideToClose: false
                    })
                        .then(function (r) {
                            if (a.methodOnFinish) $scope.manager[a.methodOnFinish](r);
                            else $scope.manager.loadPage();
                            

                        }, function () {
                            // cancel pressed
                        });
                } else {
                    Message.warning("The upload URL is not specified.")
                }
                
            }
        }
    }
});
