import { cipo } from 'cipo';

cipo.factory('authService', ['localStorageService', 'msalAuthenticationService', 'AADB2C',
    function (localStorageService, msalService, AADB2C)
    {
        var ASFactory = {};

        ASFactory.login = function ()
        {
            msalService.loginRedirect();
        };

        ASFactory.logout = function ()
        {
            localStorageService.clearAll();

            msalService.logout();
        };

        ASFactory.passwordChange = function ()
        {
            window.location.href = AADB2C[location.host].POLICY_URLS.PASSWORD_CHANGE;
        };

        ASFactory.profileEdit = function ()
        {
            window.location.href = AADB2C[location.host].POLICY_URLS.PROFILE_EDIT;
        };

        ASFactory.isLoggedIn = function ()
        {
            return msalService.userInfo.isAuthenticated;
        };

        return ASFactory;
    }]);

