import { cipo } from 'cipo';

cipo.controller('modalDetailsController',
    function ($transition$, $state, $scope, $mdDialog, userService, URL_ACTIONS) {
        const params = $transition$.params()
        var moduleId = params.moduleId || 0;
        var moduleCode = userService.getModuleIdentifierById(moduleId);
        var entityId = params.entityId || 0;
        var screenId = params.screenId * 1;
        userService.system.selectedModuleId = moduleId;

        var c = 'SaveWorkflowEntityInstanceController';
        var v = '/ng/views/dynamics/modals/saveWorkflowEntityInstance.html';

        var item = {
            isInitiator: true,
            itemId: entityId,
            fromItemId: (params.urlActionId == URL_ACTIONS.CLONE) ? (params.urlActionEntityId || 0) : 0,
            presetId: (params.urlActionId == URL_ACTIONS.PRESET) ? (params.urlActionEntityId || 0) : 0,
        };

        var obj = {};

            $mdDialog.show({
                locals: { item: item, obj: obj, operations: item.operations, module: userService.system.modules[moduleCode], contractId: null, smallerFullscreen: false, screenId: screenId },
                controller: c,
                templateUrl: v,
                parent: angular.element(document.body),
                //targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                multiple: true,
                clickOutsideToClose: false
            });

        $scope.$on('$destroy', function () {
            $mdDialog.hide();
        })
    });