import { cipo } from 'cipo';

cipo.controller('amenuController',
function ($scope, $state, $http, URI, ICONS, CIPO_ICONS, $q, Menu, userService, $mdDialog, Message) {
    $scope.moduleName = userService.system.mNames[$state.current.code];
    $scope.module = userService.system.modules[$state.current.code];
    // get live operations
    Object.defineProperty($scope, 'operations', {
        get: function () { return userService.getOperationsFor($state.current.code); }
    });

    $scope.icons = userService.system.icons;
    $scope.iconsLookup = userService.system.iconsLookup;
    $scope.loadingMenuTree = true;
    $scope.loadingModules = true;

    $scope.getModulesList = function () {
        $scope.loadingModules = true;
        $http[URI.MENU.MODULES.method](URI.MENU.MODULES.toString())
            .then(function (result) {
                $scope.loadingModules = false;
                $scope.modulesTree = [];
                if (result.data && result.data.length) {
                    for (var i = 0; i < result.data.length; i++) {
                        result.data[i].name = result.data[i].name;
                        //console.log('module', result.data[i]);
                        result.data[i].id = 0;
                        // result.data[i].typeId = 2;
                        // result.data[i].typeName = "Module";
                        $scope.modulesTree.push(result.data[i]);
                    }
                }


            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })
    }
        
    $scope.getModulesList();
    $scope.getList = function () {
        $scope.loadingMenuTree = true;
        $http[URI.MENU.LIST.method](URI.MENU.LIST.toString())
            .then(function (result) {
                $scope.loadingMenuTree = false;
                $scope.menuTree = [];
                // library bug fix
                for (var i = 0; i < result.data.length; i++) {
                    if (result.data[i].typeId == 1 && !result.data[i].children)
                        result.data[i].children = [];
                    else if (result.data[i].typeId == 1) {
                        for (var j = 0; j < result.data[i].children.length; j++) {
                            if (result.data[i].children[j].typeId == 1 && !result.data[i].children[j].children)
                                result.data[i].children[j].children = [];
                        }
                    }
                }
                $scope.menuTree = result.data;
            })
            .catch(function (e) {
                Message.dberror(e);
            })
    }

    $scope.getList();   
    
    $scope.toggle = function (scope) {
        scope.toggle();
    };
    $scope.dropProcessing = function (e) {
        var parentId = e.dest.nodesScope.$parent.$modelValue ? e.dest.nodesScope.$parent.$modelValue.id : null;
        e.source.nodeScope.$modelValue.parentId = parentId;
        if (e.dest.nodesScope.$parent.$modelValue) e.dest.nodesScope.$parent.$modelValue.loading = true;
        else {
            $scope.rootLoad = true;
        }
        $scope.orderItems(e.dest.nodesScope.$modelValue)
            .then(function (result) {
                Menu.triggerRefresh();
                if (result && result != 0) e.source.nodeScope.$modelValue.id = result;

            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })
            .finally(function () {
                if (e.dest.nodesScope.$parent.$modelValue) e.dest.nodesScope.$parent.$modelValue.loading = false;
                else {
                    $scope.rootLoad = false;
                }
            });
    }

    $scope.tree1Options = {
        beforeDrop: function (e) {
            // not dropping a folder on the third level even if empty
            if (e.dest.nodesScope.depth() == 2 && e.source.nodeScope.$modelValue.typeId == 1) {
                return false;
            }
            else {
                if (e.dest.nodesScope.$modelValue == e.source.nodesScope.$modelValue && e.dest.index == e.source.index){

                }
                else {
                    $scope.dropProcessing(e);
                }
                
                return true;
                
            }
        }
    }
    $scope.tree2Options = {
        beforeDrop: function (e) {
            if (e.dest.nodesScope.$modelValue == e.source.nodesScope.$modelValue && e.dest.index == e.source.index){

            }
            else {
                $scope.dropProcessing(e);
            }
            return true;
        }
    }
    // note to self: reject needs to be handled in treeoptions
    $scope.orderItems = function (nodes) {
        $scope.deleteBtn = true;
        var pos = 1;
        for (var i = 0; i < nodes.length; i++) {
            pos++;
            nodes[i].position = pos;
        }
        var p = $q.defer();
        $http[URI.MENU.ORDER.method](URI.MENU.ORDER.toString(), nodes)
            .then(function (result) {
                p.resolve(result.data);
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                Menu.triggerRefresh();
                $scope.deleteBtn = false;
            })
        return p.promise;
    }

    $scope.detachItem = function (scope) {
        if (scope.$parent.$parentNodeScope) scope.$parent.$parentNodeScope.$modelValue.loading = true;
        else {
            for (var i = 0; i < scope.$parent.$parent.$modelValue.length; i++) {
                scope.$parent.$parent.$modelValue[i].loadingSiblings = true;
            }
            scope.$modelValue.loadingSiblings = false;
            scope.$modelValue.loading = true;
        }
        $http[URI.MENU.DETACH.method](URI.MENU.DETACH.toString(), scope.$modelValue.id)
            .then(function (result) {
                for (var i = 0; i < scope.$parent.$parent.$modelValue.length; i++) {
                    scope.$parent.$parent.$modelValue[i].loadingSiblings = false;
                }
                scope.$modelValue.id = 0;
                $scope.getModulesList();
                scope.remove();
                // Message.dberror(e);
                Message.info('Menu item removed successfully');
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })
            .finally(function () {
                if (scope.$parent.$parentNodeScope) scope.$parent.$parentNodeScope.$modelValue.loading = false;
                else scope.$modelValue.loading = false;
                Menu.triggerRefresh();
            })

    }

    $scope.logTree = function () {
    }

    $scope.delete = function (scope) {
        scope.$modelValue.loading = true;
        $http[URI.MENU.DELETE.method](URI.MENU.DELETE.toString() + '?menuId=' + scope.$modelValue.id)
            .then(function (result) {
                scope.remove();
                $scope.getModulesList();
                Menu.triggerRefresh();
                
                Message.info('Menu item deleted successfully');
            })
            .catch(function (e) {
                console.log(e);
                Message.dberror(e);
            })
            .finally(function () {
                scope.$modelValue.loading = false;
            })
    };

    $scope.newFolder = function (scope) {
        var parentScope = 0;
        if (scope) parentScope = scope.$parent.$modelValue;
        $scope.editFolder(null, parentScope);
        
    };

    $scope.editFolder = function (scope, parent_scope, ev) {
        //check if new or existing
        var node = scope ? scope.$parent.$modelValue : { name: "", id: null, typeId: 1, isFolder: true, iconId: null, position: 0};
        
        if (parent_scope) node.parentId = !scope ? parent_scope.id : null;
        var dataURL = node.id ? URI.MENU.EDIT : URI.MENU.ADD;

        $mdDialog.show({
            locals: {
                item: node, dataURL: dataURL, showIcons: true, otherParams: "" },
            controller: 'renameItemController',
            templateUrl: '/ng/views/admin/modals/renameItem.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            focusOnOpen: false,
            escapeToClose: false,
            clickOutsideToClose: false
        })
            .then(function (result) {
                if (node.id) {
                    // is rename
                    for (var key in result) {
                        if (result.hasOwnProperty(key)) {
                            node[key] = result[key];
                        }
                    }
                    Menu.triggerRefresh();
                } else {
                    //new folder
                    node = result;
                    node.children = [];
                    var nodeData;
                    if (parent_scope) {
                        nodeData = parent_scope.children;
                    } else {
                        nodeData = $scope.menuTree;
                    }
                    nodeData.splice(0, 0, node);
                    $scope.orderItems(nodeData);
                }
            }, function () {
                //cancel pressed
            });
    };
    $scope.editModule = function (scope, ev) {
        var node = scope ? scope.$modelValue : null;
        if (!node.iconId) node.iconId = 0;
        var dataURL = URI.MODULES.EDIT;
        var temp = angular.copy(node);
        temp.id = temp.moduleId;
        temp.abbreviation = temp.code;

        $mdDialog.show({
            locals: { item: temp, dataURL: dataURL, showIcons: true, otherParams: "" },
            controller: 'renameItemController',
            templateUrl: '/ng/views/admin/modals/renameItem.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            focusOnOpen: false,
            escapeToClose: false,
            clickOutsideToClose: false
        })
            .then(function (result) {
                // is rename
                for (var key in result) {
                    if (result.hasOwnProperty(key) && key!='id') {
                        node[key] = result[key];
                    }
                    
                }
                Menu.triggerRefresh();
            }, function () {
                //cancel pressed
            });
    }

    $scope.manageIcons = function (ev) {

        $mdDialog.show({
            locals: {},
            controller: 'manageIconsController',
            templateUrl: '/ng/views/admin/modals/manageIcons.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            focusOnOpen: false,
            escapeToClose: false,
            clickOutsideToClose: false
        })
            .then(function (result) {
                
            }, function () {
                //cancel pressed
            });
    }


});
