import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { GridsterModule } from '@adrian3de/angular-gridster2';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    GridsterModule,
  ],
  declarations: [
    FooterComponent,
  ]
})
export class SystemModule {
  ngDoBootstrap() {
    // Required module bootstrap method
  }
}