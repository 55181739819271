import { GridsterItem } from '@adrian3de/angular-gridster2';
import { DisplayType, FieldTypeEnum } from '../fields/enums';
import { ScreenFieldModel } from '../fields/main';
import { DataCardSettingLabelDefault, DataCardSettingValueDefault } from './dataCardSetting';

export interface DataCardViewStateValue {
  name: string;
  fontColor: string;
  bgColor: string;
}

export type DataCardViewPartValueType = string | number | boolean | DataCardViewStateValue;

export class DataCardViewPart implements GridsterItem {
  x: number;
  y: number;
  cols: number;
  rows: number;
  minItemCols?: number;
  maxItemRows?: number;

  id: number | string;
  field: ScreenFieldModel;
  value?: DataCardViewPartValueType;
  preview?: boolean;

  constructor(id: number | string, field: ScreenFieldModel, preview: boolean = false) {
    this.id = id;
    this.field = field;
    this.field.labelFormatting = this.field.labelFormatting || { ...DataCardSettingLabelDefault };
    this.field.valueFormatting = this.field.valueFormatting || { ...DataCardSettingValueDefault };
    this.x = this.field.x || 0;
    this.y = this.field.y || 0;
    this.cols = this.field.cols || 6;
    this.rows = this.field.rows || 1;

    this.minItemCols = 1;
    this.maxItemRows = 12;
    this.preview = preview;
  }

  get valueOrPreview(): DataCardViewPartValueType {
    if (!this.preview) {
      return this.value;
    }
    if (this.isNumber) {
      return this.isNumberIndicator ? 121 : this.isNumberProgress ? 29 : 12345.678;
    } else if (this.isDate) {
      return '2022.11.29 13:49:22';
    } else if (this.isYesNo) {
      return true;
    } else if (this.isState) {
      return { name: 'Active', fontColor: '#fff', bgColor: '#54b095' } as DataCardViewStateValue;
    }
    return 'Lorem ipsum';
  }

  get valueNumber(): number {
    const value = this.valueOrPreview as number;
    if (value && this.isNumberIndicator) {
      return Math.abs(value);
    }
    return value;
  }

  get valueString(): string {
    return this.valueOrPreview?.toString();
  }

  get valueBoolean(): boolean {
    return this.valueOrPreview as boolean;
  }

  get valueState(): DataCardViewStateValue {
    return this.valueOrPreview as DataCardViewStateValue;
  }

  get isNumber(): boolean {
    return this.field.typeId == FieldTypeEnum.Number;
  }

  get isDate(): boolean {
    return this.field.typeId == FieldTypeEnum.Date;
  }

  get isYesNo(): boolean {
    return this.field.typeId == FieldTypeEnum.YesNo;
  }

  get isState(): boolean {
    return this.field.displayTypeId == DisplayType.Status;
  }

  get isLine(): boolean {
    return this.field.displayTypeId == DisplayType.Line;
  }

  get isText(): boolean {
    return !this.isNumber && !this.isDate && !this.isYesNo && !this.isState && !this.isLine;
  }

  get displayTypeId(): DisplayType {
    return this.field.displayTypeId || DisplayType.Text;
  }

  get isNumberIndicatorUp(): boolean {
    return (
      this.displayTypeId == DisplayType.IndicatorUp || (this.displayTypeId == DisplayType.IndicatorBasedOnValue && this.valueNumber > 0)
    );
  }

  get isNumberIndicatorDown(): boolean {
    return (
      this.displayTypeId == DisplayType.IndicatorDown || (this.displayTypeId == DisplayType.IndicatorBasedOnValue && this.valueNumber < 0)
    );
  }

  get isNumberIndicator(): boolean {
    return (
      this.displayTypeId == DisplayType.IndicatorUp ||
      this.displayTypeId == DisplayType.IndicatorDown ||
      this.displayTypeId == DisplayType.IndicatorBasedOnValue
    );
  }

  get isNumberDurationProgress(): boolean {
    return this.displayTypeId == DisplayType.PercentDurationProgress;
  }

  get isNumberAmountProgress(): boolean {
    return this.displayTypeId == DisplayType.PercentAmountProgress;
  }

  get isNumberProgress(): boolean {
    return this.isNumberDurationProgress || this.isNumberAmountProgress;
  }
}
