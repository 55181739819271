import { cipo } from 'cipo';
import moment from 'moment';
    
cipo.controller('dashboardController', 
    function ($scope, $state, authService, $window, $location, $timeout, Message,Menu, $q, userService, Dictionaries, Form, DashboardModule, $mdDialog , URI, ICONS) {
        $scope.module = userService.system.modules["D"] || { name: "Dashboard", code: "D" };
        $scope.selectedIndex = 0;

        $scope.gtdata = {
            content: null
        };
        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor("D"); }
        });
        Object.defineProperty($scope, 'userService', {
            get: function () {
                return userService;
            }
        });
        $scope.printMe = function () {
            $scope.isPrint = true;
            if ($("#section-to-print").length != 0) {
                $("#section-to-print").remove();

            }
            var div = $('<div />').appendTo('body');
            div.attr('id', 'section-to-print');
            $timeout(function () {
                $("#section-to-print").html($("#forPrint").html());
                $scope.isPrint = false;
                
                $window.print();
                
            }, 400)
            // $window.print();
        }

        $scope.contractSearch = { content: "" };

        $scope.contracts = angular.copy(userService.system.contracts);
        $scope.selectedContract = { key: 0, value: "All contracts" }
        $scope.contracts.unshift($scope.selectedContract);

        $scope.changePersonalDashboardContract = function (contract) {
            $scope.selectedContract = contract;
            // var contractId = contract.key;
            $scope.get_tasks(1);
            $scope.get_emails(1);
        }
        var dashboardMod = new DashboardModule();

        var addZ = function (date) {
            var ret = date || null;
            if (date && date.indexOf("Z") == -1)
                ret = date + "Z";
            return ret;
        }

        $scope.Math = Math;

        Object.defineProperty($scope, 'corOps', {
            get: function () { return userService.getOperationsFor("C") }
        });

        $scope.personalDashboard = {
            tasks: { items: [], currentPage: 1, loading: true, pages: 1, pagesize: 20, pageStart: 0,  pageEnd: 0,  records: 0, assignedToMe: false },
            emails: { items: [], currentPage: 1, loading: true, pages: 1, pagesize: 20, pageStart: 0, pageEnd: 0, records: 0  },
        }

        var processPagination = function (pageNo, obj) {
            obj.pageStart = obj.pagesize * (pageNo - 1) + 1;
            obj.pageEnd = obj.records < obj.pagesize * pageNo ? obj.records : obj.pagesize * pageNo;
            obj.currentPage = pageNo;
            
            obj.pages = Math.ceil(obj.records / obj.pagesize);
        }

        $scope.showConversation = function (item) {
            if (item.contractId != userService.system.userdata.contractId) {
                userService.setContract(item.contractId);
                Menu.triggerRefresh();
                Message.warning('The contract was changed to ' + item.contractName);
            }
            Menu.select(Menu.moduleMapping[-11], userService.system.userdata.contractId);
            $location.url($state.params.tenantIdentifier + '/emails?f=-1&e=' + item.threadId);
        }

        $scope.get_tasks = function (pageNo) {
            $scope.personalDashboard.tasks.loading = true;
            var dataURL = URI.DASHBOARD.PERSONAL_TASKS;
            var pageNo = pageNo || $scope.personalDashboard.tasks.currentPage;
            dashboardMod[dataURL.method](dataURL, {
                url: { contractId: $scope.selectedContract.key || null, assignedToMe: $scope.personalDashboard.tasks.assignedToMe },
                urltype: "obj",
                body: { pagesize: $scope.personalDashboard.tasks.pagesize, page: pageNo }
            })
                .then(function (r) {
                    if (r.data) {
                        // a.diff(b, 'days')

                        if ((r.data || []).length) {
                            for (var i = 0; i < r.data.length; i++) {
                                if (!r.data[i].daysLeft) {
                                    r.data[i].daysLeft = moment(addZ(r.data[i].dueDate)).diff(moment(), 'days') >= 0
                                        ? moment(addZ(r.data[i].dueDate)).diff(moment(), 'days')
                                        : moment(addZ(r.data[i].dueDate)).diff(moment(), 'days') - 1;
                                    
                                } 
                                if (!r.data[i].agencyRespDays) r.data[i].daysLeft = "N/A";
                                r.data[i].overduePercentage = r.data[i].agencyRespDays
                                    ? r.data[i].daysOpened / r.data[i].agencyRespDays * 100 : 0;

                                //  r.data[i].dateSent = moment(addZ(r.data[i].dateSent)).format(userService.formats.date);
                            }
                        }

                        $scope.personalDashboard.tasks.items = r.data;
                        $scope.personalDashboard.tasks.records = r.records;
                        processPagination(pageNo, $scope.personalDashboard.tasks);

                    } else {
                        $scope.personalDashboard.tasks.items = r;
                        $scope.personalDashboard.tasks.records = 22;
                    }

                    
                })
                .catch(function (e) {
                    Message.dberror(e);
                    console.error(e);
                }).finally(function () {
                    $scope.personalDashboard.tasks.loading = false;
                })
        }

        $scope.viewTask = function (item) {
            var contractId = (typeof item != 'undefined') ? parseInt(item.contractId) : 0;
            var itemToSend = {
                itemId: (typeof item != 'undefined') ? parseInt(item.entityInstanceId) : 0,
                fromItem: 0
            }


                $mdDialog.show({
                    locals: {
                        item: itemToSend,
                        module: { moduleId: item.moduleId, code: item.moduleCode, name: item.moduleName },
                        contractId: contractId,
                        smallerFullscreen: false,
                        screenId: null
                    },
                    controller: 'SaveWorkflowEntityInstanceController',
                    templateUrl: '/ng/views/dynamics/modals/saveWorkflowEntityInstance.html',
                    parent: angular.element(document.body),
                    //targetEvent: ev,
                    fullscreen: true,
                    escapeToClose: false,
                    clickOutsideToClose: false
                })
                    .then(function (result) {
                        $scope.get_tasks();
                    }, function () {
                            $scope.get_tasks();
                    });
            }
           
            $scope.get_emails = function (pageNo) {
                var dataURL = URI.DASHBOARD.PERSONAL_CORRESPONDENCE;
                $scope.personalDashboard.emails.loading = true;
                var pageNo = pageNo || $scope.personalDashboard.emails.currentPage;
                dashboardMod[dataURL.method](dataURL, {
                    url: { contractId: $scope.selectedContract.key || null },
                    urltype: "obj",
                    body: { pagesize: $scope.personalDashboard.emails.pagesize, page: pageNo }
                })
                    .then(function (r) {

                    if ((r.data || []).length) {
                        for (var i = 0; i < r.data.length; i++) {
                            r.data[i].dateSent = moment(addZ(r.data[i].dateSent)).format(userService.formats.date);
                        }
                    }

                    $scope.personalDashboard.emails.items = r.data;
                    $scope.personalDashboard.emails.records = r.records;
                    processPagination(pageNo, $scope.personalDashboard.emails);
                })
                .catch(function (e) {
                    Message.dberror(e);
                    console.error(e);
                }).finally(function () {
                    $scope.personalDashboard.emails.loading = false;
                })
        }
        $scope.changePersonalDashboardContract($scope.selectedContract);

        $scope.$watch(function () {
            return userService.system.userdata.contractId;
        },
            function (newVal, oldVal) {
                if (newVal) {
                    get_rollups(null, true)
                        .then(function () {
                            return getCounts($scope.rollupInfo);    
                        }).then(function () {
                            get_gantData(newVal);
                        });
                }
            });

        

        $scope.goToModPage = function (m) {
            $location.url($state.params.tenantIdentifier + '/dm/' + userService.system.userdata.contractId + '/' + m.properties.moduleId);
            Menu.select(Menu.moduleMapping[m.properties.moduleId], userService.system.userdata.contractId);
        }

        $scope.rollupInfo = {
            contractId: 0,
            projectId: 0,
            programId: 0
        };

        Object.defineProperty($scope, 'hasContracts', {
            get: function () {
                return $scope.rollupInfo.contractId + $scope.rollupInfo.projectId + $scope.rollupInfo.programId;
            }
        });
        $scope.rollupInfoForm = new Form($scope.rollupInfo);
        $scope.rollupInfoForm.initializing = true;
        $scope.openMenu = function ($mdMenu, ev) {
            $mdMenu.open(ev);
        };
        $scope.isShowFilters = false;

        $scope.showFilters = function () {
            if ($scope.isShowFilters) {
                // temp fix backend data wrong
                var params = {};
                if ($scope.pageFilters.contractId) params.contractId = $scope.pageFilters.contractId;
                else if ($scope.pageFilters.projectId) params.projectId = $scope.pageFilters.projectId;
                else if ($scope.pageFilters.programId) params.programId = $scope.pageFilters.programId;
                else params.programId = 0;
                get_rollups(params);

            } 
            $scope.isShowFilters = !$scope.isShowFilters;
        }

        var createlookup = function (array) {
            var lookup = {};
            if (array.length)
                for (var i = 0; i < array.length; i++) {
                    lookup[array[i].key] = array[i];
                }
            lookup[0] = { key: 0, value: "All" };
            return lookup;
        }
        
        var get_rollups = function (id, isSetFilters) {
            var p = $q.defer();
            var urlParam;
            if (!id) urlParam = { contractId: userService.system.userdata.contractId };
            else urlParam = id;
            $scope.rollupInfoForm.initializing = false;
            $scope.rollupInfoForm.loading = true;
            Dictionaries.ProgramProjectContract(urlParam)
                .then(function (r) {
                    $scope.rollupInfo = {
                        contractId: r.contractId != -1 ? r.contractId : "",
                        projectId: r.projectId != -1 ? r.projectId : "",
                        programId: r.programId != -1 ? r.programId : ""
                    };

                    $scope.contractsLookup = createlookup(r.contractsDict);
                    $scope.projectsLookup = createlookup(r.projectsDict);
                    $scope.programsLookup = createlookup(r.programsDict);
                    $scope.contractsName = r.contractsName;
                    $scope.projectsName = r.projectsName;
                    $scope.programsName = r.programsName;

                    if (!$scope.pageFilters || isSetFilters) $scope.pageFilters = angular.copy($scope.rollupInfo);

                        if ((r.projectsDict || []).length) r.projectsDict.splice(0, 0, { key: 0, value: "All" });
                        if ((r.programsDict || []).length) r.programsDict.splice(0, 0, { key: 0, value: "All" });
                        if ((r.contractsDict || []).length) r.contractsDict.splice(0, 0, { key: 0, value: "All" });
                        $scope.rollupInfoForm.set_Data($scope.rollupInfo);
                        var formDescription = {
                            programId: { label: r.programsName, type: 'select', options: r.programsDict, optionsHasEmptyValue: false },
                            projectId: { label: r.projectsName, type: 'select', options: r.projectsDict, optionsHasEmptyValue: false },
                            contractId: { label: r.contractsName, type: 'select', options: r.contractsDict, optionsHasEmptyValue: false },
                        };
                        $scope.rollupInfoForm.set_Description(formDescription);
                        $scope.rollupInfoForm.fieldsList.programId.onClose = function (field) {
                            console.error('changed', self, field);
                            if (field._value != r.programId) get_rollups({ programId: field._value });
                            if (field) field.onSelectClose();
                        }
                        $scope.rollupInfoForm.fieldsList.projectId.onClose = function (field) {
                            if (field._value != r.projectId) {
                                if (field._value != 0 || $scope.rollupInfo.programId == 0)
                                    get_rollups({ projectId: field._value });
                                else
                                    get_rollups({ programId: $scope.rollupInfo.programId });
                            }
                            if (field) field.onSelectClose();
                        }
                        $scope.rollupInfoForm.fieldsList.contractId.onClose = function (field) {
                            if (field._value != r.contractId) {
                                if (field._value != 0)
                                    get_rollups({ contractId: field._value });
                                else if ($scope.rollupInfo.projectId != 0 || $scope.rollupInfo.programId == 0)
                                    get_rollups({ projectId: $scope.rollupInfo.projectId });
                                else get_rollups({ programId: $scope.rollupInfo.programId });
                            } 
                            if (field) field.onSelectClose();
                        }

                        $scope.rollupInfoForm.loading = false;
                    })
                    .catch(function (e) {
                        Message.dberror(e);
                    })
                    .finally(function () {
                        p.resolve();
                    });
                return p.promise;
            }

        // get_rollups();

        var get_gantData = function (contractId) {
            var dataURL = URI.DASHBOARD.GANTT_DATA;
            dashboardMod[dataURL.method](dataURL, { url: { contractId: contractId || 0 }, urltype: 'obj' })
                .then(function (r) {
                    $scope.gtdata.content = r;
                })
                .catch(function (e) {
                    Message.dberror(e);
                    console.error(e);
                })
        }
        
        $scope.updateDashboard = function () {
            getCounts($scope.rollupInfo, true);
            $scope.isShowFilters = !$scope.isShowFilters;
            get_gantData($scope.rollupInfo.contractId);
        }

        var getCounts = function (urlParams, isNewFilters) {
            
            var p = $q.defer();
            $scope.loading = true;
            if (isNewFilters) {
                $scope.pageFilters = angular.copy($scope.rollupInfo);
            }
            if (urlParams) {
                //
                urlParams = {
                    contractId: urlParams.contractId || 0,
                    projectId: urlParams.projectId || 0,
                    programId: urlParams.programId || 0
                }
            }
            
            var urlParams = urlParams || { contractid: userService.system.userdata.contractId };
            var countsURL = URI.DASHBOARD.STATUS_TYPE_COUNTS;
            dashboardMod[countsURL.method](countsURL, { url: urlParams, urltype: 'obj' });
            var dataURL = URI.DASHBOARD.MODULE_COUNTS;
            dashboardMod[dataURL.method](dataURL, { url: urlParams, urltype: 'obj'})
                .then(function (r) {
                    $scope.moduleCounts = [];
                    for (var i = 0; i < r.length; i++) {
                        $scope.moduleCounts.push(new DashboardModule(r[i]))
                    }
                    
                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    $scope.loading = false;
                    p.resolve();
                })
            return p.promise;
        }
        // getCounts();

});
