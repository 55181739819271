import { cipo } from 'cipo';

cipo.factory("Explorer", function (Model, $q, ACTIONS, Manager, $mdDialog, Form) {
    //constructor extending Model
    var Explorer = Model.extend(function (obj) {
        var self = this;
        self.components = obj.components || 3;
        self.modals = obj.modals || true;
        self.operations = obj.operations || {};
        self.rootUrlParam = obj.rootUrlParam || null;
        self.childUrlParam = obj.childUrlParam || null;
        if (obj.treeSetup)
            self.treeSetup = {
                rootTreeURL: obj.treeSetup.rootTreeURL || null,
                childrenURL: obj.treeSetup.childrenURL || null,
                addFolderURL: obj.treeSetup.addFolderURL || null,
                editFolderURL: obj.treeSetup.editFolderURL || null,
                deleteFolderURL: obj.treeSetup.deleteFolderURL || null,
                // uploadFileURL: obj.treeSetup.uploadFileURL || null,
                treeOptions: obj.treeSetup.treeOptions || {},
                orderby: obj.treeSetup.orderby || null,
                reverse: obj.treeSetup.reverse || false
            };

        self.fileSetup = {
            uploadFileURL: obj.fileSetup.uploadFileURL || null,
            downloadFileURL: obj.fileSetup.downloadFileURL || null,
            deleteFileURL: obj.fileSetup.deleteFileURL || null,
            renameFileURL: obj.fileSetup.renameFileURL || null
        };
        self.selectedNode = { id: 0 };
        self.breadcrumbItemWidth = 100;
        self.expandedNodes = [];
        self.linearLookup = [];
        self.loadingFolders = true;
        self.layout = obj.layout || 1;
        self.breadcrumb = [];
        //self.currentFiles = [];
        self.currentFolders = [];

        // self.URLparams = obj.urlParams || null;

        self.URLparam = obj.URLparam || null;
        if (obj.managerSetup)
            self.managerSetup = {
                rootData: {
                    dataURL: obj.managerSetup.rootData.dataURL || null,
                    dataWrapper: obj.managerSetup.rootData.dataWrapper || null
                },
                childData: {
                    dataURL: obj.managerSetup.childData.dataURL || null,
                    dataWrapper: obj.managerSetup.childData.dataWrapper || null
                    // otherParams: obj.managerSetup.childData.otherParams || null,
                },
                search: typeof obj.managerSetup.search != "undefined" ? obj.managerSetup.search : true,
                columns: obj.managerSetup.columns || []
            }
        self.init();
    });

    Explorer.prototype.setParams = function (node, string) {
        var self = this;
        var param = {};
        if (self.URLparam && self.URLparam.appliesTo.indexOf(string) != -1) {
            param[self.URLparam.name] = self.URLparam.object[self.URLparam.value];
        }
        if (node) param.folderId = node.id;
        return param;
    }

    Explorer.prototype.addFolderToLookup = function (folder) {
        var self = this;
        self.linearLookup[folder.id] = folder;
    }

    Explorer.prototype.init = function () {
        var self = this;
        if (self.manager) {
            self.manager.loading = true;
        }
        self.getRootTree()
                .then(function (result) {
                    self.folderTree = {
                        folders: []
                    }
                    var rootFolders = result;
                    if (rootFolders && rootFolders.length) {
                        for (var i = 0; i < rootFolders.length; i++) {
                            //set breadcrumb
                            rootFolders[i].breadcrumb = [rootFolders[i].id]

                            //add all root folders to linearLookup and tree
                            self.addFolderToLookup(rootFolders[i]);
                            self.folderTree.folders.push(rootFolders[i]);

                            //add all level1 folders to linearLookup
                            if (rootFolders[i].folders && rootFolders[i].folders.length) {
                                for (var j = 0; j < rootFolders[i].folders.length; j++) {
                                    // add level1 children to linearLookup
                                    self.addFolderToLookup(rootFolders[i].folders[j]);

                                    //manually add the root parent and self to breadcrumb
                                    self.folderTree.folders[i].folders[j].breadcrumb = [];
                                    self.folderTree.folders[i].folders[j].breadcrumb.push(self.folderTree.folders[i].id);
                                    self.folderTree.folders[i].folders[j].breadcrumb.push(self.folderTree.folders[i].folders[j].id);
                                }
                            } else {
                                self.folderTree.folders[i].folders = [];
                            }
                        }
                    }
                    self.loadRootInformation();
                    self.loadingFolders = false;

                }).catch(function (e) {
                    console.error(e);
                    if (self.manager) self.manager.loading = false;
                });
    }

    Explorer.prototype.getRootTree = function () {
        var self = this;
        var p = $q.defer();
        var method = self.managerSetup.rootData.dataURL.method || 'get';

        self[method](self.managerSetup.rootData.dataURL, self.setParams(self.selectedNode, "rootData"))
            .then(function (result) {
                p.resolve(result);
            })
            .catch(function (reject) {
                p.reject(reject);
            })

        return p.promise;
    }

    Explorer.prototype.getChildTree = function (folder) {
        var self = this;
        var p = $q.defer();
        var method = self.managerSetup.childData.dataURL.method || 'get';

        self[method](self.managerSetup.childData.dataURL, self.setParams(folder, "childData"))
            .then(function (result) {
                p.resolve(result);
            })
            .catch(function (reject) {
                p.reject(reject);
            })
        return p.promise;
    }

    Explorer.prototype.loadRootInformation = function () {
        var self = this;
        self.currentFolders = self.folderTree.folders;
        self.breadcrumb = [];
        self.selectedNode = { id: 0 };
        self.expandedNodes = [];
        self.loadManagerData(self.managerSetup.rootData, "rootData");
    }

    Explorer.prototype.loadManagerData = function (data, string) {
        var self = this;
        self.manager = new Manager({
            dataURL: data.dataURL,
            dataWrapper: data.dataWrapper,
            otherParams: self.setParams(self.selectedNode, string),
            fileManagerMenu: true,
            noTabletVersion: true,
            menuClass: 'fileManagerMenu',
            search: self.managerSetup.search,
            layout: self.layout,
            rowClass: function (row) {
                if (row.isFolder) { return "folderInfo"; }
                else return "fileInfo " + row.mimeType.replace('/', '-');
            },
            rowOnClick: function (row) {
                if (row.isFolder) {
                    self.getFolderContentFromList(row);
                } else {
                    self.downloadFile(row.id);
                }
            },
            leftActions: [
                {
                    setProperties: ACTIONS.BACK,
                    conditionOnTop: function () {
                        if (self.selectedNode.id != 0)
                            return true;
                        else return false;
                    },
                    click: function () {
                        var parent = self.breadcrumb[self.breadcrumb.length - 2];
                        if (self.breadcrumb.length > 1) {
                            self.getFolderContentFromBreadcrumb(parent);
                        } else self.loadRootInformation();
                    }
                },
                {
                    setProperties: ACTIONS.NEWFOLDER,
                    type: 'primary',
                    conditionOnTop: function () {
                        if (self.operations.Create) return true;
                        else return false;
                    },
                    click: function (row) {
                        self.editItem();
                    }
                },
                {
                    setProperties: ACTIONS.UPLOAD,
                    conditionOnTop: function (row) {
                        if (self.selectedNode.id != 0 && self.operations.Create)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        self.upload();
                    }
                },
            ],
            actions: [
                
                {
                    setProperties: ACTIONS.DETAILS,
                    condition: function (row) {
                        if (row.isFolder)
                            return true;
                        else return false;
                    },
                    click: function (row) {
                        self.getFolderContentFromList(row);
                    }
                },
                {
                    setProperties: ACTIONS.EDIT,
                    displayOnRow: self.operations.Update ? true : false,
                    name: "Rename",
                    click: function (row) {
                        self.editItem(row);
                    }
                },
                {
                    setProperties: ACTIONS.DOWNLOAD,
                    condition: function (row) {
                        if (row.isFolder)
                            return false;
                        else return true;
                    },
                    click: function (row) {
                        self.downloadFile(row.id);
                    }
                },
                {
                    setProperties: ACTIONS.DELETE,
                    alwaysOnTop: false,
                    displayOnRow: self.operations.Delete ? true : false,
                    click: function (row) {
                        if (row.isFolder)
                            self.deleteFolder(row.id);
                        else {
                            self.deleteFile(row.id);
                        };

                    }
                }
            ]
        });
        self.manager.set_Columns(self.managerSetup.columns);
        self.manager.loadPage();
    }

    

    Explorer.prototype.expandTree = function (node) {
        var self = this;
        var expandedNodesIds = [];
        if (self.expandedNodes && self.expandedNodes.length) {
            for (var i = 0; i < self.expandedNodes.length; i++) {
                expandedNodesIds.push(self.expandedNodes[i].id);
            }
        }
        for (var i = 0; i < node.breadcrumb.length - 1; i++) {
            if (expandedNodesIds.indexOf(node.breadcrumb[i]) == -1) {
                self.expandedNodes.push(self.linearLookup[node.breadcrumb[i]]);
            }
        }
    }

    // set children breadcrumbs and push them into lookup
    Explorer.prototype.setChildren = function (node) {
        var self = this;
        for (var i = 0; i < node.folders.length; i++) {
            node.folders[i].breadcrumb = []
            node.folders[i].breadcrumb = node.folders[i].breadcrumb.concat(node.breadcrumb);
            node.folders[i].breadcrumb.push(node.folders[i].id);
            self.addFolderToLookup(node.folders[i]);
        }
    }

    // get children on expand
    Explorer.prototype.getNodeChildren = function (node, status) {
        var self = this;
        // self.selectedNode = node;
        // if action is expand
        if (status) {
            if (node.folders && node.folders.length) {
            } else {
                if (typeof node.folders == "undefined") {
                    node.loadingChildren = true;
                    self.getChildTree(node)
                        .then(function (result) {
                            node.loadingChildren = false;
                            node.folders = result.folders || [];
                            if (node.folders && node.folders.length)
                                self.setChildren(node);
                        })
                        .catch(function (reject) {
                            console.error(reject);
                        })
                }

            }
        } else {
            // if action is collapse, collapse all children of the collapsed node
            if (self.expandedNodes && self.expandedNodes.length) {
                for (var i = self.expandedNodes.length - 1; i >= 0 ; i--) {
                    if (self.expandedNodes[i].breadcrumb.indexOf(node.id) != -1) {
                        self.expandedNodes.splice(i, 1);
                    }
                }
            }
        }
    }

    Explorer.prototype.populateBreadcrumb = function (node) {
        var self = this;
        if (node.breadcrumb && node.breadcrumb.length) {
            self.breadcrumb = [];
            for (var i = 0; i < node.breadcrumb.length ; i++) {
                self.breadcrumb.push(self.linearLookup[node.breadcrumb[i]]);
            }
        } else {
            self.breadcrumb = [];
        }
        if (self.breadcrumb.length) self.breadcrumbItemWidth = 100 / (self.breadcrumb.length+1);
        else self.breadcrumbItemWidth = 100;
    }

    Explorer.prototype.getFolderContentFromBreadcrumb = function (node) {
        var self = this;
        self.populateBreadcrumb(node);
        self.selectedNode = node;
        self.currentFolders = self.selectedNode.folders || [];
        self.expandTree(node);
        self.loadManagerData(self.managerSetup.childData, "childData");
    }

    Explorer.prototype.getFolderContentFromList = function (node) {
        var self = this;
        node.breadcrumb = self.selectedNode.breadcrumb ? self.selectedNode.breadcrumb.concat([node.id]) : [node.id];
        //if no folders is selected (root view)
        if (self.selectedNode.id == 0) {
            for (var i = 0; i < self.folderTree.folders.length; i++) {
                if (self.folderTree.folders[i].id == node.id) {
                    self.selectedNode = self.folderTree.folders[i];
                    self.currentFolders = self.selectedNode.folders || [];
                    break;
                }
            }
            self.loadManagerData(self.managerSetup.childData, "childData");
        } else {
            // change the selected node to the clicked element (child of selectedNode) and update tree information
            for (var i = 0; i < self.selectedNode.folders.length; i++) {
                if (self.selectedNode.folders[i].id == node.id) {
                    self.selectedNode = self.selectedNode.folders[i];
                    self.expandTree(node);
                    // if the selected node's children are not in yet, get them
                    if (typeof self.selectedNode.folders == "undefined") {
                        self.selectedNode.loadingChildren = true;
                        self[self.treeSetup.childrenURL.method](self.treeSetup.childrenURL, self.setParams(node, "childrenURL"))
                            .then(function (result) {
                                self.loadManagerData(self.managerSetup.childData, "childData");
                                self.selectedNode.loadingChildren = false;
                                self.selectedNode.folders = result.folders || [];
                                self.currentFolders = self.selectedNode.folders;
                                if (self.selectedNode.folders && self.selectedNode.folders.length) {
                                    self.setChildren(self.selectedNode);
                                }
                            }).catch(function (e) {
                                console.error(e);
                            });

                    } else {
                        self.loadManagerData(self.managerSetup.childData, "childData");
                    }
                    break;
                }
            }

        }
        self.manager.loading = true;
        self.populateBreadcrumb(node);
    }


    Explorer.prototype.getFolderContentFromTree = function (node, status) {
        var self = this;
        self.selectedNode = node;
        if(node.folders) self.currentFolders = node.folders;
        if (status) {
            self.manager.loading = true;
            self.populateBreadcrumb(node);

            // if the node children are not in yet
            if (typeof node.folders == "undefined") {
                node.loadingChildren = true;
                self[self.treeSetup.childrenURL.method](self.treeSetup.childrenURL, self.setParams(self.selectedNode, "childrenURL"))
                    .then(function (result) {
                        self.loadManagerData(self.managerSetup.childData, "childData");
                        node.loadingChildren = false;
                        node.folders = result.folders || [];
                        self.currentFolders = node.folders;
                        //self.currentFiles = result.files || [];

                        if (node.folders && node.folders.length) {
                            self.setChildren(node);
                        }
                    }).catch(function (e) {
                        console.error(e);
                        self.manager.loading = false;
                    });

            } else {
                self.loadManagerData(self.managerSetup.childData, "childData");
                // self.currentFolders = node.folders;
            }
        } else {
            self.loadRootInformation();
        }
    }

    Explorer.prototype.editItem = function (item, ev) {
        var self = this;
        var item = item ? item : { name: "", parentId: self.selectedNode ? self.selectedNode.id : 0, breadcrumb: self.selectedNode && self.selectedNode.breadcrumb ? angular.copy(self.selectedNode.breadcrumb): []};

        var dataURL = item.id ? (item.isFolder ? self.treeSetup.editFolderURL : self.fileSetup.renameFileURL) : self.treeSetup.addFolderURL;

        $mdDialog.show({
            locals: { item: item, dataURL: dataURL, showIcons: false },
            controller: 'renameItemController',
            templateUrl: '/ng/views/admin/modals/renameItem.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            focusOnOpen: false,
            clickOutsideToClose: false
        })
            .then(function (result) {
                if (item.id && item.isFolder) {
                    // is rename
                    for (var i = 0; i < self.currentFolders.length; i++) {
                        if (self.currentFolders[i].id == result.id) {
                            self.currentFolders[i].name = result.name;
                            break;
                        }
                    }
                } else if(!item.id) {
                    //new folder
                    self.linearLookup[result.id] = result;
                    
                    if (self.selectedNode.folders) {
                        self.currentFolders.push(result);
                    }
                    else {
                        self.selectedNode.folders = self.currentFolders;
                        self.currentFolders.push(result);
                    }
                }
                self.manager.loadPage();
            }, function (result) {
                // cancel pressed
            });
    }


    Explorer.prototype.upload = function (ev) {
        var self = this;
        var parentFolder = self.selectedNode.id != 0 ? self.selectedNode : null;
        var uploadURL = self.fileSetup.uploadFileURL;
        var params = self.setParams(self.selectedNode, "uploadFileURL");
        //currentFiles: self.currentFiles, 
        $mdDialog.show({
            locals: { dataURL: uploadURL, dataParams: params, action: null },
            controller: 'fmUploadController',
            templateUrl: '/ng/views/admin/modals/fmUpload.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            clickOutsideToClose: false
        })
            .then(function (result) {
                self.manager.loadPage();
            }, function () {
                // cancel pressed
                self.manager.loadPage();
            });
    }

    var confirm = function (title, text, ev) {
        return $mdDialog.confirm()
            .title(title)
            .textContent(text)
            .ariaLabel('Confirm Dialogue')
            .targetEvent(ev)
            .ok('Proceed')
            .cancel('Cancel');
    }

    Explorer.prototype.deleteFolder = function (id) {
        var self = this;
        if (id) {
            $mdDialog.show(confirm('Deleting Folder', 'Are you sure you want to delete the selected Folder?'))
                .then(function () {
                    self[self.treeSetup.deleteFolderURL.method](self.treeSetup.deleteFolderURL + "?folderId=" + id)
                        .then(function (result) {
                            delete (self.linearLookup[id]);
                            for (var i = 0; i < self.currentFolders.length; i++) {
                                if (self.currentFolders[i].id == id) {
                                    self.currentFolders.splice(i, 1);
                                    break;
                                }
                            }
                            self.manager.loadPage();
                        })
                        .catch(function (e) {
                            console.error(e);
                            self.manager.loading = false;
                        });
                })
                .catch(function () {
                    // cancel pressed
                });
        }
    };

    Explorer.prototype.deleteFile = function (id) {
        var self = this;
        if (id) {
            $mdDialog.show(confirm('Deleting File', 'Are you sure you want to delete the selected File?'))
                .then(function () {
                    self[self.fileSetup.deleteFileURL.method](self.fileSetup.deleteFileURL + "?fileId=" + id)
                        .then(function (result) {
                            self.manager.loadPage();
                        })
                        .catch(function (e) {
                            console.error(e);
                            self.manager.loading = false;
                        });
                })
                .catch(function () {
                    // cancel pressed 
                });
        }
    };

    Explorer.prototype.downloadFile = function (id) {
        var self = this;
        self[self.fileSetup.downloadFileURL.method](self.fileSetup.downloadFileURL + "?fileId=" + id)
            .then(function (result) {
                var link = document.createElement("a");
                link.href = result.fileUrl;
                link.download = result.name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(function (e) {
                console.error(e);
            });
    }


    return Explorer;
});
