import { cipo } from 'cipo';

cipo.factory("RelationMod", function ($q, Model, URI, Form, Message, Dictionaries, STATES_STATUS, StatesMapping,
        ModuleDictionaries) {
    var RelationMod = Model.extend(function (obj) {
        //console.error('params', stateId, obj)

        this.properties = {
            id: null,
            typeId: null,
            parentId: ModuleDictionaries.moduleInfo.moduleId,
            name: "",
            childName: "",
            parentName: "",
            parentFieldName: "",
            childFieldId: null,
            childFieldName: "",
            childId: null,
            parentStateTypeIds: [],
            parentTypeId: null,
            childStateTypeIds: [],
            childTypeId: null,
            allowChanges: true,
            uniqueness: false,
            inUse: false,
            relationType: 1,
            isParent: true

        };



        var self = this;

        self.sources = ["relationTypes", "modules", "moduleTypes"];

        self.isSwapped = false;
        // self.moduleId = ModuleDictionaries.moduleInfo.moduleId;
        self.editMode = false;
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        }

        if (!self.properties.id) self.createForm();

    });
        // to go
    RelationMod.prototype.setDicts = function (relationTypesDict, modulesDict, moduleTypes) {
        var self = this;
        self.modulesDict = [];
        self.relationTypesDict = relationTypesDict;
        if (modulesDict && modulesDict.length) {
            for (var i = 0; i < modulesDict.length; i++) {
                if (modulesDict[i].key != -3) {
                    self.modulesDict.push(modulesDict[i]);
                }
            }
        }
        // self.modulesDict = modulesDict;
        self.moduleTypes = moduleTypes;
        if (!self.properties.id) self.createForm();

    }

    RelationMod.prototype.swapParent = function () {
        var self = this;
        self.isSwapped = !self.isSwapped;
        // if (self.isSwapped) self.childIdBkp = self.properties.parentId;
        var newChild = {
            childName: self.properties.parentName,
            childFieldName: self.properties.parentFieldName,
            childId: self.properties.parentId,
            childTypeId: self.properties.parentTypeId,
            childStateTypeIds: self.properties.parentStateTypeIds,
            
        }
        self.properties.parentName = self.properties.childName;
        self.properties.parentFieldName = self.properties.childFieldName;
        self.properties.parentId = self.properties.childId;
        self.properties.parentTypeId = self.properties.childTypeId;
        self.properties.parentStateTypeIds = self.properties.childStateTypeIds;
        self.properties.isParent = !self.properties.isParent;

        for (var key in newChild) {
            if (newChild.hasOwnProperty(key)) {
                self.properties[key] = newChild[key];
            }
        }
        self.form.fieldsList.parentId.editMode = !self.form.fieldsList.parentId.editMode;
        self.form.fieldsList.childId.editMode = !self.form.fieldsList.childId.editMode;

        var temp = angular.copy(self.form.fieldsList.parentTypeId.options);
        self.form.fieldsList.parentTypeId.options = angular.copy(self.form.fieldsList.childTypeId.options);
        self.form.fieldsList.childTypeId.options = angular.copy(temp);
    }

    RelationMod.prototype.createForm = function () {
        var self = this;

        self.form = new Form(self.properties);
        self.form.initializing = true;

        if (!self.properties.isParent) self.isSwapped = true;

        ModuleDictionaries.getDicts(self.sources)
            .then(function () {
                
                if (!self.properties.parentTypeId && ModuleDictionaries.dataSources.moduleTypes.data.length == 1)
                    self.properties.parentTypeId = ModuleDictionaries.dataSources.moduleTypes.data[0].key;
                var grid;
                var form = {
                    parentId: { label: 'Parent', type: 'select', options: ModuleDictionaries.dataSources.modules.data, validation: { required: true } },
                    name: { label: 'Relation name', type: 'text', validation: { required: true, maxChars: 100 } },
                    parentFieldName: { label: 'Parent field name', type: 'text', validation: { required: true, maxChars: 100 } },
                    childFieldName: { label: 'Child field name', type: 'text', validation: { required: true, maxChars: 100 } },
                    parentStateTypeIds: { label: 'Parent State Type(s)', type: 'select', multiple: true, options: STATES_STATUS().statusSmallerDict, validation: { required: true } },
                    parentTypeId: { label: 'Parent Type', type: 'select', options: ModuleDictionaries.dataSources.moduleTypes.data, validation: { required: true } },
                    typeId: { label: 'Select Relation Type', type: 'select', options: ModuleDictionaries.dataSources.relationTypes.data, validation: { required: true } },
                    childId: { label: 'Child', type: 'select', options: ModuleDictionaries.dataSources.modules.data, validation: { required: true } },
                    childTypeId: { label: 'Child Type', type: 'select', options: [], validation: { required: true } },
                    childStateTypeIds: { label: 'Child State Type(s) ', type: 'select', multiple: true, options: STATES_STATUS().statusSmallerDict, validation: { required: true } },
                    allowChanges: { label: 'Can be updated after the instance entered workflow', type: 'checkbox' },
                    uniqueness: { label: 'Each child instance can be related to only one parent instance', type: 'checkbox' },
                };


                self.form.set_Description(form);


                grid = [
                    { name: 50, typeId: 50 },
                    { parentFieldName: 25, parentId: 25, parentTypeId: 25, parentStateTypeIds: 25 },
                    { childFieldName: 25, childId: 25, childTypeId: 25, childStateTypeIds: 25 },
                    { allowChanges: 50, uniqueness: 50 }
                ];
                self.form.setTemplate('grid', grid);
                self.form.store_Data();
                self.form.fieldsList.parentId.editMode = false;
                self.form.fieldsList.childId.editMode = true;
                if (self.properties.id) {
                    self.form.fieldsList.childId.editMode = false;
                }
                if (self.properties.inUse) {
                    self.form.editMode = false;
                    self.form.fieldsList.childTypeId.editMode = false;
                    self.form.fieldsList.parentStateTypeIds.editMode = true;
                    self.form.fieldsList.childStateTypeIds.editMode = true;
                    self.form.fieldsList.name.editMode = true;
                }
                self.childIdBkp = self.properties.childId;

                self.getTypesOnClose = function (field) {
                    if (self.isSwapped) {
                        // the other module is parent
                        if (self.childIdBkp != self.properties.parentId) {
                            self.childIdBkp = self.properties.parentId;
                            self.form.fieldsList.parentTypeId.loading = true;
                            Dictionaries.ModuleTypes(field ? field._value : self.properties.parentId)
                                .then(function (r) {
                                    if (r.length == 1) self.properties.parentTypeId = r[0].key;
                                    self.form.set_Description(
                                        {
                                            parentTypeId: { label: 'Parent Type', type: 'select', options: r, validation: { required: true } },
                                            childTypeId: { label: 'Child Type', type: 'select', options: ModuleDictionaries.dataSources.moduleTypes.data, validation: { required: true } },
                                        }, true);
                                    self.form.fieldsList.parentTypeId.loading = false;
                                    self.form.fieldsList.childId.editMode = false;
                                    self.form.fieldsList.parentId.editMode = true;
                                    self.form.fieldsList.childId.onClose = self.getTypesOnClose;
                                    self.form.fieldsList.parentId.onClose = self.getTypesOnClose;

                                })
                                .catch(function (e) { console.error(e); Message.dberror(e) })

                        }
                    } else {
                        // current module is parent
                        if (self.childIdBkp != self.properties.childId) {
                            self.childIdBkp = self.properties.childId;
                            self.form.fieldsList.childTypeId.loading = true;
                            Dictionaries.ModuleTypes(field ? field._value : self.properties.childId)
                                .then(function (r) {
                                    if (r.length == 1) self.properties.childTypeId = r[0].key;
                                    self.form.set_Description(
                                        {
                                            childTypeId: { label: 'Child Type', type: 'select', options: r, validation: { required: true } },
                                            parentTypeId: { label: 'Parent Type', type: 'select', options: ModuleDictionaries.dataSources.moduleTypes.data, validation: { required: true } },
                                        }, true);
                                    self.form.fieldsList.childTypeId.loading = false;
                                    self.form.fieldsList.parentId.editMode = false;
                                    self.form.fieldsList.childId.editMode = true;
                                    self.form.fieldsList.childId.onClose = self.getTypesOnClose;
                                    self.form.fieldsList.parentId.onClose = self.getTypesOnClose;
                                    if (self.properties.inUse) {
                                        self.form.editMode = false;
                                        self.form.fieldsList.childId.editMode = false;
                                        if (self.properties.allowChanges) {
                                            self.form.fieldsList.childStateTypeIds.editMode = true;
                                            self.form.fieldsList.parentStateTypeIds.editMode = true;
                                        }
                                        
                                        self.form.fieldsList.name.editMode = true;
                                    }
                                    if (self.properties.id) {
                                        self.form.fieldsList.childId.editMode = false;
                                    }
                                })
                                .catch(function (e) { console.error(e); Message.dberror(e); })

                        }
                    }

                        if (field) field.onSelectClose();

                }

                if (self.properties.childId) {
                    self.childIdBkp = null;
                    self.getTypesOnClose();
                } else {
                    self.form.fieldsList.childId.onClose = function (field) {
                        self.getTypesOnClose(field);
                    };
                    self.form.fieldsList.parentId.onClose = function (field) {
                        self.getTypesOnClose(field);
                    };
                }





                self.form.initializing = false;
            })

        
    }

    RelationMod.prototype.createPPForm = function () {
        var self = this;
        
        self.form = new Form(self.properties);
        self.form.isPPForm = true;
        self.form.initializing = true;
        self.form.set_Description({ childFieldId: { label: 'Select field', type: 'select', options: [], validation: { required: true } } });
        self.form.initializing = false;
        self.form.fieldsList.childFieldId.loading = true;
        
        Dictionaries.ModuleFieldsByType({ type: 2, multiple: 0 }, { moduleId: self.properties.childId })
            .then(function (r) {
                self.form.set_Description({ childFieldId: { label: 'Select field', type: 'select', options: r, validation: { required: true } } });
                self.form.store_Data();
            })
            .catch(function () {})
    }



    RelationMod.prototype.removePP = function () {
        var self = this;
        var dataURL = URI.MODULE_RELATION.TOGGLE_PARTIAL_PAYMENT;
        var p = $q.defer();
        self[dataURL.method](dataURL, { body: { childFieldId: null, id: self.properties.id } }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
            .then(function () {
                Message.info('Payment removed successfully');
                self.isModified = true;

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {

            });
        return p.promise;
    }

    RelationMod.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = self.properties.id ? URI.MODULE_RELATION.EDIT : URI.MODULE_RELATION.ADD;

        self.form.loading = true;
        self.form.validate();

        if (self.form.isValid) {
            if (!self.form.isPPForm) {
                self[dataURL.method](dataURL, { body: self.properties }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
                    .then(function () {
                        Message.info('Relation saved successfully');
                        self.isModified = true;

                        p.resolve();
                    })
                    .catch(function (e) {
                        console.error(e);
                        self.form.loading = false;
                        self.form.catch(e);
                        p.reject(e);
                    })
                    .finally(function () {

                    });
            }
            else {
                dataURL = URI.MODULE_RELATION.TOGGLE_PARTIAL_PAYMENT;
                self[dataURL.method](dataURL, { body: { childFieldId: self.properties.childFieldId, id: self.properties.id } }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
                    .then(function () {
                        Message.info('Transition saved successfully');
                        self.isModified = true;
                        
                        p.resolve();
                    })
                    .catch(function (e) {
                        console.error(e);
                        self.form.loading = false;
                        self.form.catch(e);
                        p.reject(e);
                    })
                    .finally(function () {

                    });
            }
            

        }
        else {
            self.form.loading = false;
            p.reject();
        }
        return p.promise;
    }

    RelationMod.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting relation...";
        self.deleting = true;
        var dataURL = URI.MODULE_RELATION.DELETE;

        self[dataURL.method](dataURL, { body: [self.properties.id ] }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
            .then(function (result) {
                Message.info('Relation deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete relation. Try again?";
                p.reject(e);
                Message.dberror(e);
            })

        return p.promise;
    }

    RelationMod.prototype.workflowMapping = function () {
        var self = this;
        var mappingInfo = {
            parent: { name: self.properties.parentName, id: self.properties.parentId },
            child: { name: self.properties.childName, id: self.properties.childId },
            relationId: self.properties.id

        };

        self.statesMapping = new StatesMapping(mappingInfo);
        self.isSecondScreen = true;
    }
    RelationMod.prototype.cancelMapping = function () {
        this.statesMapping = null;
        this.isSecondScreen = false;
    }

    RelationMod.prototype.closeGridSettings = function () {
        var self = this;
        delete self.isGridSettings;
        self.screenModule = null;
        self.isSecondScreen = false;
    }


    RelationMod.extend = function (construct) {
        var self = this;
        var Child = construct;
        Child.prototype = angular.copy(self.prototype);
        Child.prototype.constructor = Child;

        return Child;
    }


    return RelationMod;
});
