import { cipo } from 'cipo';

cipo.controller('pspdfkitAttachmentViewerController',
    function ($scope, $mdDialog, MODAL, attachment, list, userService, PSPDFKitInstance, URI) {
    /* VARIABLES */
        $scope.list = list;
        attachmentIndex = list.map(function (e) { return e.id }).indexOf(attachment) || 0;
        $scope.modalInfo = MODAL;
        $scope.isLoading = false;
        $scope.isBusy = false;
        $scope.title = list[attachmentIndex].name;
        $scope.loadDocument = _loadDocument;
        $scope.close = _close;
    /* END VARIABLES */

        //var updateActive = function (id) {
        //    for (var i = 0; i < $scope.list.length; i++) {
        //        $scope.list[i].isActive = false;
        //    }

        //    $scope.list[id].isActive = true;
        //}

        //updateActive(attachmentIndex);
        

        /* INIT */
        // $scope.setTitle($scope.isViewOnly);
        $scope.pspdfkitInstance = new PSPDFKitInstance({
            moduleId: null,
            contractId: 0,
            currentUserId: userService.system.userdata.id,
            isLoading: function (isLoading) { /*$scope.isLoading = isLoading;*/ }
        });


        $scope.loadDocument();
    /* END INIT */

        $scope.next = function () {
            list[attachmentIndex].isActive = false;
            attachmentIndex = attachmentIndex < $scope.list.length - 1 ? attachmentIndex + 1 : 0;
            // updateActive(attachmentIndex);
            $scope.pspdfkitInstance.unload(true);
            $scope.loadDocument();
        }

        $scope.previous = function () {
            list[attachmentIndex].isActive = false;
            attachmentIndex = attachmentIndex > 0 ? attachmentIndex - 1 : $scope.list.length - 1;
            // updateActive(attachmentIndex);
            $scope.pspdfkitInstance.unload(true);
            $scope.loadDocument();
        }

        $scope.select = function (o) {
            list[attachmentIndex].isActive = false;
            attachmentIndex = $scope.list.map(function (e) { return e.id }).indexOf(o.id) || 0;
            // updateActive(attachmentIndex);
            $scope.pspdfkitInstance.unload(true);
            $scope.loadDocument();
        }

        /* WATCHERS */
        $scope.$watch(function () {
            return document.querySelector('#pspdfkit').innerHTML;
        },
            function (n, o) {
                if (n != o) {
                    n.length > 0 ? $('#pspdfkitLoadingMsg').hide() : $('#pspdfkitLoadingMsg').show();
                }
            });
        $scope.$watch(function () {
            return $scope.pspdfkitInstance.showClickMessage;
        },
            function (n, o) {
                if (n != o) {
                    n ? $('.pspdfkit-click-message').slideDown() : $('.pspdfkit-click-message').slideUp();
                }
            });
        /* END WATCHERS */

        // Sets the title of the dialog
        function _setTitle(isViewOnly) {
            if (!isViewOnly)
                $scope.title = `${$scope.module.name} - ${$scope.title}`;
        }

        // Loads the document provided to the viewer
        function _loadDocument() {
            list[attachmentIndex].isActive = true;
            $scope.title = list[attachmentIndex].name;
            $scope.pspdfkitInstance[URI.MY_CIPO_DRIVE.GET_FILE_URL.method](`${URI.MY_CIPO_DRIVE.GET_FILE_URL}?versionId=${list[attachmentIndex].versionId}&isPreview=false`)
                .then(function (url) {
                    $scope.pspdfkitInstance.view(url, list[attachmentIndex].id, list[attachmentIndex].mimeType)
                })
                .catch(function (e) {

                })
                .finally(function () {

                });
            
        }

        // Unloads the document instance and then closes dialog.
        function _close(isViewOnly) {
            $scope.pspdfkitInstance.unload(isViewOnly);
            $mdDialog.cancel();
        }
    });
