import { cipo } from 'cipo';

cipo.directive("formField", function ($compile, $http, $templateCache, userService, ICONS, froalaService) {
    var set_Template = function (template) {
        var base = "/ng/views/directives/system/form/fields/";
        var map = {
            text: 'text.html',
            number: 'number.html',
            formula: 'number.html',
            integer: 'integer.html',
            money: 'money.html',
            password: 'password.html',
            chips: 'chips.html',
            contact: 'contact.html',
            autocomplete: 'autocomplete.html',
            select: 'select.html',
            attachment: 'attachment.html',
            textarea: 'textarea.html',
            editor: 'editor.html',
            annotation: 'annotation.html',
            datepicker: 'datetime.html',
            timepicker: 'datetime.html',
            datetimepicker: 'datetime.html',
            checkbox: 'checkbox.html',
            radio: 'radio.html',
            colorpicker: 'colorpicker.html',
            submodule: 'submodule.html',
            table: "table.html",
            icons: "icons.html",
            weather: "weather.html"
        }

        return $http.get(base + map[template], { cache: $templateCache });
    }

    return {
        restrict: "E",
        replace: true,
        scope: {
            "fielddata": "="
        },
        link: function ($scope, $el, $attrs) {
            var el = $el;
            var childScope = $scope.$new();

                if ($scope.fielddata.type && $scope.fielddata.type == 'colorpicker') {
                    $scope.colorpickerOptions = {
                        label: $scope.fielddata.label,
                        //icon: "brush",
                        default: "#808080",
                        genericPalette: false,
                        history: false,
                        hasBackdrop: false,
                        clearButton: false
                    }
                }
                $scope.currencySymbol = userService.getCurrency();
                $scope.showmanagerFn = function (fielddata) {
                    fielddata.showmanager = true;
                    fielddata._clearErrors();
                }
                
            // froala stuff
            if ($scope.fielddata.type && $scope.fielddata.type == 'editor') {
                var froalaOptions = {
                    fontFamily: {
                        'Calibri, sans-serif': 'Calibri',
                        "Roboto,sans-serif": 'Roboto',
                        "'Nunito Sans',sans-serif": 'Nunito Sans',
                        "Muli,sans-serif": 'Muli',
                        "Poppins,sans-serif": 'Poppins',
                        "'Open Sans',sans-serif": 'Open Sans'
                    },

                    fontFamilySelection: true,
                    events: {},
                };

                var froalaInitAddAtwho = function() { };


                if (Object.prototype.toString.call($scope.fielddata.options) == '[object Object]') {
                    for (var key in $scope.fielddata.options) {
                        if ($scope.fielddata.options.hasOwnProperty(key)) {
                            froalaOptions[key] = $scope.fielddata.options[key];
                        }
                    }
                }

                if (Object.prototype.toString.call($scope.fielddata.hints) == '[object Object]' ||
                    Object.prototype.toString.call($scope.fielddata.hints) == '[object Array]') {

                    try {
                        var list = [], temp;
                        if (Object.prototype.toString.call($scope.fielddata.hints) == '[object Object]')
                            for (var key in $scope.fielddata.hints) {
                                if ($scope.fielddata.hints.hasOwnProperty(key)) {
                                    temp = {
                                        value: $scope.fielddata.hints[key],
                                        key: key
                                    };
                                    list.push(temp);

                                }
                            }
                        else list = $scope.fielddata.hints;

                        // Define config for At.JS.
                        var config = {
                            at: "#",
                            data: list,
                            displayTpl: '<li>${value}</li>',
                            //headerTpl: "<h3>Select</h3>",
                            insertTpl: "{{${key}}}",
                            limit: 200,
                            searchKey: "value",
                            suffix: '',
                            //key: "${key}",
                            //prefix: ' ',
                            editableAtwhoQueryAttrs: { "data-fr-verified": true }
                        }

                        if (Object.prototype.toString.call($scope.fielddata.atwhoConfig) == '[object Object]') {
                            for (var key in $scope.fielddata.atwhoConfig) {
                                if ($scope.fielddata.atwhoConfig.hasOwnProperty(key)) {
                                    config[key] = $scope.fielddata.atwhoConfig[key];
                                }
                            }
                        }

                        froalaInitAddAtwho = function () {
                            var editor = $scope.froalaOptions.froalaEditor;

                            $(editor.$el).atwho(config);

                            editor.events && editor.events.on && editor.events.on('keydown', function (e) {
                                if (e.which == FroalaEditor.KEYCODE.ENTER && $(editor.$el).atwho('isSelecting')) {
                                    return false;
                                }
                            }, true);
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                }

                if (Object.prototype.toString.call($scope.fielddata.hintsTagg) == '[object Object]' ||
                    Object.prototype.toString.call($scope.fielddata.hintsTagg) == '[object Array]') {

                    try {
                        var listTagging = [], tempTagging;
                        if (Object.prototype.toString.call($scope.fielddata.hintsTagg) == '[object Object]')
                            for (var key in $scope.fielddata.hintsTagg) {
                                if ($scope.fielddata.hintsTagg.hasOwnProperty(key)) {
                                    tempTagging = {
                                        value: $scope.fielddata.hintsTagg[key],
                                        key: key
                                    };
                                    listTagging.push(tempTagging);
                                }
                            }
                        else listTagging = $scope.fielddata.hintsTagg;

                        var configTagging = {
                            at: "@",
                            data: listTagging,
                            displayTpl: '<li>${value}</li>',
                            insertTpl: "<span class='tag-user' id='${key}'>@${value}</span>",
                            limit: 200,
                            searchKey: "value",
                            suffix: '',
                            editableAtwhoQueryAttrs: { "data-fr-verified": true }
                        }

                        if (Object.prototype.toString.call($scope.fielddata.atwhoConfigTagging) == '[object Object]') {
                            for (var key in $scope.fielddata.atwhoConfigTagging) {
                                if ($scope.fielddata.atwhoConfigTagging.hasOwnProperty(key)) {
                                    configTagging[key] = $scope.fielddata.atwhoConfigTagging[key];
                                }
                            }
                        }

                        froalaInitAddAtwho = function () {
                            var editor = $scope.froalaOptions.froalaEditor;

                            $(editor.$el).atwho(configTagging);

                            editor.events && editor.events.on && editor.events.on('keydown', function (e) {
                                if (e.which == FroalaEditor.KEYCODE.ENTER && $(editor.$el).atwho('isSelecting')) {
                                    return false;
                                }
                            }, true);
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                }

                froalaService.attachImageOptions(froalaOptions, $scope.fielddata.entityInstanceId);

                froalaOptions.events.initialized = function () {
                    froalaInitAddAtwho();
                }

                $scope.froalaOptions = froalaOptions;
            }


            // summernote stuff
            if ($scope.fielddata.type && $scope.fielddata.type == 'editor') {
                var summernoteOptions = {};

                summernoteOptions.toolbar = [
                    ['style', ['style']],
                    // ['edit', ['undo', 'redo']],
                    ['style', ['bold', 'italic']],// , 'underline'
                    ['textsize', ['fontsize']],
                    ['fontclr', ['color']],
                    ['alignment', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture', 'video']],
                    //['view', ['fullscreen'/*, 'codeview', 'help'*/]]
                ];

                if (Object.prototype.toString.call($scope.fielddata.options) == '[object Object]') {
                    var labels = [];
                    var lookupList = {};

                    for (var key in $scope.fielddata.options) {
                        labels.push($scope.fielddata.options[key]);
                        lookupList[$scope.fielddata.options[key]] = {
                            _key: key,
                            _value: $scope.fielddata.options[key]
                        };
                    }

                    summernoteOptions.hint = [{
                        labels: labels,
                        lookupList: lookupList,
                        match: /\B#(\w*)$/,
                        search: function (keyword, callback) {
                            callback($.grep(this.labels, function (item) {
                                return item.indexOf(keyword) == 0;
                            }));
                        },
                        content: function (item) {
                            return '{{'+this.lookupList[item]._key+'}}';
                        }
                    }]
                }

                $scope.summernoteOptions = summernoteOptions;
            }

            $scope.$watch(function () {
                return $scope.fielddata.type;
            }, function (n, o) {
                if (typeof n != 'undefined') {
                    set_Template(n)
                        .then(function (r) {
                            if (childScope) {
                                childScope.$destroy();
                                childScope = null;
                            }

                            childScope = $scope.$new();

                            $(el).html($compile(r.data)(childScope));
                        })
                        .catch(function (e) {
                            console.error(e);
                        });
                }
            });

            $scope.weatherHasValues = function() {
                const {temperature, wind, precipitation, humidity, pressure} = $scope.fielddata._value;
                return (temperature !== undefined && temperature !== null) ||
                    (wind !== undefined && wind !== null) ||
                    (precipitation !== undefined && precipitation !== null) ||
                    (humidity !== undefined && humidity !== null) ||
                    (pressure !== undefined && pressure !== null);
            }
            if($scope.fielddata.type == 'weather') {
                $scope.weatherHasValuesProperty = $scope.weatherHasValues()
            }

            if ($scope.fielddata.type == 'contact') {
                $scope.$watch(function () { return $scope.fielddata._value }, function (n, o) {
                    if (n != o) {
                        $scope.fielddata.searchValue = null;
                    }
                }, true)
            }

            if ($scope.fielddata.type == 'icons') {
                $scope.fielddata.icons = userService.system.icons;
            }
        }
    }
});
