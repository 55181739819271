import { cipo } from 'cipo';

cipo.controller('editSovItemController',
    function ($scope, Message, MODAL, SovItem, contractId, operations, $q, item, $mdDialog) {

        $scope.modalInfo = MODAL;

        $scope.operations = operations || {};
        // $scope.module = module;
        $scope.disabledBtn = false;

        $scope.actionBtnDisabled = false;
        // var fields = angular.copy(r.fields);
        $scope.sovItem = new SovItem(item, contractId);

        $scope.revertTabInfo = function () {
            if (!$scope.isModified) $mdDialog.cancel();
            else $mdDialog.hide({ start: $scope.sovItem.initialCategory, end: $scope.sovItem.finalCategory });
        };

        $scope.updateTabInfo = function () {
            $scope.hightlight = false;
            $scope.actionBtnDisabled = true;
            $scope.save()
                .then(function (r) {
                    $mdDialog.hide({ start: $scope.sovItem.initialCategory, end: $scope.sovItem.finalCategory });
                })
                .catch(function (e) {
                    console.error(e);
                })
                .finally(function () {
                    $scope.actionBtnDisabled = false;
                });
        };

        $scope.cancelAction = function () {
            $scope.tabWarning = false;
            $scope.hightlight = false;
            $scope.disabledBtn = false;
        };


        $scope.save = function (isAddAnother) {
            var p = $q.defer();
            $scope.disabledBtn = true;
            $scope.tabWarning = false;
            $scope.hightlight = false;
            $scope.message = "Saving SOV item...";
            
            $scope.sovItem.save(isAddAnother)
                .then(function (result) {
                    Message.info('SOV item saved successfully');
                    p.resolve();
                    $scope.disabledBtn = false;
                    $scope.isModified = true;
                    if (isAddAnother) {
                        $scope.sovItem.init();
                        $scope.sovItem.get_itemDetails();
                    } else $scope.close();
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                })
                .finally(function () {
                    $scope.disabledBtn = false;
                    $scope.message = "";
                });
            return p.promise;
        };


        $scope.close = function () {
            if (!$scope.sovItem.screen.form.dirty)
                if (!$scope.isModified) $mdDialog.cancel();
                else $mdDialog.hide({ start: $scope.sovItem.initialCategory, end: $scope.sovItem.finalCategory, categoriesArray: $scope.sovItem.categoriesArray });
            else {
                $scope.hightlight = true;
                $scope.tabWarning = true;
            }
        };

    });
