import { cipo } from 'cipo';

cipo.directive("explorerGrid1", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "explorer": "="
        },
        templateUrl: "/ng/views/directives/system/explorer1.2.html",
        controller: function ($scope, userService) {
            $scope.showTreeInfo = function () {
            };

            $scope.userService = userService;

            Object.defineProperty($scope, 'module', {
                get: function () { return userService.system.modules[$scope.explorer.abbreviation]; }
            });

            

            if ($scope.explorer) {
                if (typeof $scope.persistentLayout != "undefined") {
                    $scope.explorer.layout = $scope.persistentLayout;
                }
            }

            $scope.$watch(function () { if ($scope.explorer) return $scope.explorer.layout; }, function (n, o) {
                if (typeof $scope.persistentLayout != "undefined") {
                    $scope.explorer.layout = $scope.persistentLayout;
                }
            });

            $scope.$watch(function () {
                return userService.system.userdata.contractId;
            },
                function (newVal) {
                    if (typeof newVal != 'undefined') {
                        
                    }
                });

            $scope.$watch(function () {
                return userService.isLoadingMenu;
            },
                function (n, o) {
                    if (!n && $scope.module) {
                        ($scope.explorer.urlsData.tree.urlParams || {}).contractId = userService.system.userdata.contractId;
                            // $scope.explorer.manager.urlParams = $scope.explorer.setParams();

                            var reloadExplorer = function () {
                                var root = {
                                    id: -1, folders: [], isLoadingChildren: true,
                                    contractName: userService.system.contractsLookup && userService.system.contractsLookup.length && userService.system.userdata.contractId
                                        ? userService.system.contractsLookup[userService.system.userdata.contractId].value
                                        : ''
                                };
                                $scope.explorer.lookupFolders = {};
                                $scope.explorer.lookupFolders[root.id] = root;
                                $scope.explorer.init();
                                //  $scope.explorer.getChildren();
                            }

                        reloadExplorer();
                        if (typeof $scope.persistentLayout != "undefined") {
                            $scope.explorer.layout = $scope.persistentLayout;
                        }
                    }
                });
            

            $scope.$watch(function () { if ($scope.explorer && $scope.explorer.manager) return $scope.explorer.manager.layout; }, function (n, o) {
                if ($scope.explorer && $scope.explorer.manager) {
                    $scope.explorer.layout = n;
                    $scope.persistentLayout = n;
                }
            });
            //$scope.$watch(function () { if ($scope.explorer && $scope.explorer.manager) return $scope.explorer.manager.criteria; }, function (n, o) {
            //    console.log("watch criteria ", n, o);
            //    if (n) {
            //        if (!$scope.explorer.manager.searchObject) $scope.explorer.manager.searchObject = {};
            //        $scope.explorer.manager.searchObject.additionalSearchInfo = angular.copy($scope.explorer.selectedFolder);
            //    }
            //});

            //$scope.$watch(function () {
            //    if ($scope.explorer && $scope.explorer.manager && $scope.explorer.manager.isLocalSearch )
            //        return $scope.explorer.manager.layout;
            //}, function (n, o) {
            //    console.log("watch layout ", n, o);
            //    if ($scope.explorer && $scope.explorer.manager) {
            //        $scope.explorer.layout = n;
            //        $scope.persistentLayout = n;
            //    }
            //});


        }
    }
});
