import { cipo } from 'cipo';

cipo.controller('mainController', ['$scope', '$state', 'userService', 'authService', 'Message', 'ROUTES', function ($scope, $state, userService, authService, Message, ROUTES)
{
    $scope.chooseCompany = false;
    $scope.loading = false;

    $scope.setError = function (msg, retry)
    {
        var retry = retry || false;
        $scope.loading = false;
        $scope.chooseCompany = true;
        $scope.error = true;
        $scope.retry = retry;
        $scope.errorMsg = 'Error: ' + msg;
    };

    $scope.cancelError = function ()
    {
        $scope.error = false;
        $scope.retry = true;
        $scope.errorMsg = '';
        $scope.chooseCompany = false;

        userService.error = false;
    };

    $scope.back = function ()
    {
        userService.error = false;

        $state.go("login");
    };

    $scope.loadTenant = function (tenantId)
    {
        $scope.chooseCompany = false;
        $scope.loading = true;

        userService.changeTenant(tenantId).then(function ()
        {
            var targetState;
            var identifier = userService.getTenantIdentifierById(tenantId);

            targetState = userService.targetState;

            $state.go(targetState, { tenantIdentifier: identifier });
        }).catch(function ()
        {
            $scope.setError('Could not load tenant data');
        });
    };

    $scope.processTenants = function ()
    {
        $scope.cancelError();

        if (authService.isLoggedIn())
        {
            $scope.loading = true;

            userService.Tenants().then(function ()
            {
                var tenants = userService.system.tenants;

                if (tenants.length > 1)
                {
                    $scope.loading = false;
                    $scope.chooseCompany = true;

                    // More than 1 tenant, show list of tenants for user to choose
                    $scope.tenants = tenants;

                    userService.targetState = ROUTES.byCode.D.name;
                }
                else
                {
                    if (tenants.length === 0) // No CIPO tenant(s) for the current login IDP...
                    {
                        userService.targetState = ROUTES.byCode.IDP.name;
                    }
                    else if (tenants.length === 1) // Single tenant... go directly to the Dashboard.
                    {
                        userService.targetState = ROUTES.byCode.D.name;
                    }
                    else // Show Welcome page.
                    {
                        userService.targetState = ROUTES.byCode.WLC.name;
                    }

                    var tenantId = tenants.length ? tenants[0].id : -1;

                    $scope.loadTenant(tenantId);
                }
            }).catch(function (e)
            {
                var defaultMessage = " Contact your system administrator.";

                if (e.status === 401)
                {
                    $scope.setError("User disabled." + defaultMessage, false);
                }
                else
                {
                    // Tenants call failed, notify the user with a generic message and go back to login.
                    $scope.setError("An error occurred." + defaultMessage, true);
                }

                userService.error = true;
            });
        }
        else
        {
            $state.go("login");
        }
    };

    var redirect = userService.getRedirectPage();

    if (redirect) window.location.href = redirect;
    else $scope.processTenants();
}

]);