export enum ScreenTypeEnum {
    None = 0,
    Entity = 1,
    Action = 2,
    Card = 3,
}

export interface ScreenModel {
    id: number;
    name: string;
    description: string;
    isStartScreen: boolean;
    type: ScreenTypeEnum;
    inUse: boolean;
    anyFieldInUse: boolean;
    roleIds: number[];
}