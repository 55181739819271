import { cipo } from 'cipo';

cipo.directive("topformula", function (AggregateOperators, FIELDS_CONFIG, validationService, Dictionaries) {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "datalist": "=",
            // "numericfields": "=",

        },
        templateUrl: "/ng/views/directives/tabdetails/topformula.html",
        controller: function ($scope) {
                $scope.isNegativeError = false;

                // process existing formula
                // $scope.datalist.currentFormula.expression = '10+SUM({{1970}})*21-AVG({{1997}})*15';
                var doom = [], index = 0;

                // operands
                $scope.functionsDict = [];
                var functionsLookup = {};

                $scope.operatorDict = [
                    { key: 1, type: "operator", value: "+", class: "mdi mdi-plus" },
                    { key: 2, type: "operator", value: "-", class: "mdi mdi-minus" },
                    { key: 3, type: "operator", value: "*", class: "mdi mdi-close" },
                    { key: 4, type: "operator", value: "/", class: "mdi mdi-division" },
                ]

            var operatorsLookup = {
                "+": $scope.operatorDict[0],
                "-": $scope.operatorDict[1],
                "*": $scope.operatorDict[2],
                "/": $scope.operatorDict[3]
            }

                var CONFIG = angular.copy(FIELDS_CONFIG());

                $scope.checkNumber = function (n) {
                    $scope.isNegativeError = (n * 1) < 0;
                }

                $scope.setFormulara = function () {
                    // first check if it is just a number
                    // ..
                    if ($scope.datalist.currentFormula.expression) {
                        $scope.isOperatorTurn = true;

                        // split the expression
                        for (var i = 0; i < $scope.datalist.currentFormula.expression.length; i++) {
                            if (["/", "*", "+", "-"].indexOf($scope.datalist.currentFormula.expression[i]) != -1) {
                                doom.push($scope.datalist.currentFormula.expression.substring(index, i));
                                doom.push($scope.datalist.currentFormula.expression[i]);
                                index = i + 1;
                            }
                            if (i == $scope.datalist.currentFormula.expression.length - 1)
                                doom.push($scope.datalist.currentFormula.expression.substring(index, $scope.datalist.currentFormula.expression.length));
                        }

                        for (var i = 0; i < doom.length; i++) {
                            if (["/", "*", "+", "-"].indexOf(doom[i]) != -1) {
                                $scope.datalist.currentFormula.formula.push(operatorsLookup[doom[i]]);
                            }
                            else if (["SUM", "AVG", "MIN", "MAX"].indexOf(doom[i].substring(0, 3)) != -1) {
                                var selectedFunction = angular.copy(functionsLookup[doom[i].substring(0, 3)]);
                                var fieldId = doom[i].substring(6, doom[i].length - 3);
                                selectedFunction.field = $scope.datalist.numericFieldsLookup[fieldId];
                                $scope.datalist.currentFormula.formula.push(selectedFunction);
                            } else {
                                $scope.datalist.currentFormula.formula.push({ value: parseFloat(doom[i]), type: "number" });
                            }
                        }
                    }

                    $scope.datalist.currentFormula.numericFields = $scope.datalist.numericFields;

                    $scope.datalist.isBusy = false;
                }

                $scope.initFormula = function () {

                    $scope.datalist.currentFormula = $scope.datalist.currentFormula || {
                        label: $scope.datalist.label,
                        id: $scope.datalist.id,
                        typeId: 2,
                        displayTypeId: 1,
                        cols: 4,
                        formattings: [],
                        rows: 1,
                        expression: $scope.datalist.expression
                    };

                    var additionalInfo = {
                        errors: { label: "" },
                        minItemCols: 3,
                        maxItemRows: 1,
                        formula: [],
                        numericFields: []
                    }

                    for (var key in additionalInfo) {
                        if (additionalInfo.hasOwnProperty(key))
                            $scope.datalist.currentFormula[key] = additionalInfo[key];
                    }

                    // defaults by typeId and displayTypeId
                    var formulaDefaults = CONFIG.fieldTypesMapping[$scope.datalist.currentFormula.typeId][$scope.datalist.currentFormula.displayTypeId];

                    // attach them to the formula field
                    $scope.datalist.currentFormula.formattingsList = [];
                    $scope.datalist.currentFormula.numericFields = [];

                    // replace defaults with formula formattings
                    if (($scope.datalist.currentFormula.formattings || []).length) {
                        for (var i = 0; i < $scope.datalist.currentFormula.formattings.length; i++) {
                            CONFIG.formattingsMapping[$scope.datalist.currentFormula.formattings[i].key].value = $scope.datalist.currentFormula.formattings[i].value;
                            CONFIG.formattings[CONFIG.formattingsMapping[$scope.datalist.currentFormula.formattings[i].key].name].value = $scope.datalist.currentFormula.formattings[i].value;
                        }
                    }

                    for (var i = 0; i < formulaDefaults.formattingsList.length; i++) {
                        var formatting = CONFIG.formattings[formulaDefaults.formattingsList[i]];
                        if (!formatting.value) formatting.value = formulaDefaults.defaultFormattingIds[i];
                        $scope.datalist.currentFormula.formattingsList.push(formatting);
                    }

                    for (var key in AggregateOperators) {
                        if (AggregateOperators.hasOwnProperty(key)) {
                            $scope.functionsDict.push({ key: parseInt(key), value: AggregateOperators[key], type: "function", field: null });

                            functionsLookup[AggregateOperators[key]] = {
                                key: parseInt(key),
                                value: AggregateOperators[key],
                                type: "function",
                                field: null
                            };
                        }
                    }

                    $scope.datalist.isBusy = true;

                    // Get fields if numericFields are null
                    if (!$scope.datalist.numericFields) {
                        Dictionaries.ModuleFieldsRelationByType({}, { moduleId: $scope.datalist.moduleId })
                            .then(function (r) {
                                $scope.datalist.numericFields = r;
                                $scope.datalist.numericFieldsLookup = {};
                                for (var i = 0; i < r.length; i++) {
                                    $scope.datalist.numericFieldsLookup[r[i].key] = r[i];
                                }

                                $scope.setFormulara();
                            })
                    }
                    else {
                        $scope.setFormulara();
                    }
                }

                $scope.initFormula();

                $scope.$watch(function () {
                    return $scope.datalist.currentFormula;
                }, function () {
                    if (!$scope.datalist.currentFormula) {

                        doom = [];
                        index = 0;
                        $scope.functionsDict = [];
                        functionsLookup = {};

                        $scope.initFormula();
                    }
                });

                // operands actions
                var clearOperatorError = function () {
                    if ($scope.datalist.currentFormula.formula[$scope.datalist.currentFormula.formula.length - 1])
                        $scope.datalist.currentFormula.formula[$scope.datalist.currentFormula.formula.length - 1].hasError = false;
                }

                $scope.addFormula = function () {
                    clearOperatorError();
                    $scope.datalist.currentFormula.formula.push(angular.copy($scope.functionsDict[0]));
                    $scope.isOperatorTurn = true;
                }
                $scope.addOpperator = function () {
                    $scope.datalist.currentFormula.formula.push(angular.copy($scope.operatorDict[0]));
                    $scope.isOperatorTurn = false;
                }
                $scope.addNumber = function () {
                    clearOperatorError();
                    $scope.datalist.currentFormula.formula.push({ value: 0, type: "number" });
                    $scope.isOperatorTurn = true;
                }

            $scope.changeFunction = function (item, index) {
                var oldValueField = $scope.datalist.currentFormula.formula[index].field;
                $scope.datalist.currentFormula.formula[index] = angular.copy(item);
                $scope.datalist.currentFormula.formula[index].field = oldValueField;
            }

            $scope.changeField = function (f, index) {
                $scope.datalist.currentFormula.formula[index].field = f;
                $scope.datalist.currentFormula.formula[index].hasError = false;
            }
            $scope.changeOperator = function (item, index) {
                $scope.datalist.currentFormula.formula[index] = item;
            }

                $scope.removeItem = function (item) {

                    index = $scope.datalist.currentFormula.formula.indexOf(item);
                    if ($scope.datalist.currentFormula.formula[index + 1]) $scope.datalist.currentFormula.formula.splice(index + 1, 1);
                    $scope.datalist.currentFormula.formula.splice(index, 1);
                }
                $scope.removeOperator = function () {
                    $scope.datalist.currentFormula.formula.pop();
                }

            var currentFormulaBkp;

                $scope.saveRemove = function () {
                    if (!$scope.datalist.currentFormula.formula.length) {
                        $scope.isOperatorTurn = false;
                    } else {
                        if ($scope.datalist.currentFormula.formula[$scope.datalist.currentFormula.formula.length - 1].type == "operator")
                            $scope.isOperatorTurn = false;
                        else $scope.isOperatorTurn = true;
                    }
                    $scope.isDeleteMode = false;
                    currentFormulaBkp = angular.copy($scope.datalist.currentFormula.formula);
                }

                $scope.enterDeleteMode = function () {
                    $scope.isDeleteMode = true;
                    currentFormulaBkp = angular.copy($scope.datalist.currentFormula.formula);
                }

            $scope.cancelDeleteMode = function () {
                $scope.isDeleteMode = false;
                $scope.datalist.currentFormula.formula = angular.copy(currentFormulaBkp);
            }

                $scope.openMenu = function ($mdMenu, ev) {
                    $mdMenu.open(ev);
                };

                $scope.saveFormula = function () {

                    var result = validationService.validateExpression($scope.datalist.currentFormula);

                    $scope.datalist.currentFormula.expression = result[0];
                    $scope.datalist.currentFormula.formattings = result[1];

                    $scope.datalist.saveTopFormula();
                }

                $scope.$on("$mdMenuClose", function () {
                    try {
                        $scope.datalist.searchFields = '';
                    } catch { }
                });
            }

    }
});
