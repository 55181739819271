import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  formats = {
    date: 'MM/DD/YYYY',
    datetime: 'MM/DD/YYYY hh:mm A',
    time: 'hh:mm A'
  };
}