import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataCardPartSizes, DataCardTextSizeToPercent } from '../../../models/module/main';
import { FontStyleType, ScreenFieldFormattingModel } from 'src/app/models/module/fields/main';

@Component({
  selector: 'app-data-card-setting-part',
  templateUrl: './data-card-setting-part.component.html',
  styleUrls: ['./data-card-setting-part.component.scss'],
})
export class ScreenFieldFormattingModelComponent implements OnInit {
  @Input() desc = '';
  @Input() showDesc = true;
  @Input() showColonSetting = false;
  @Input() showSizeSetting = false;
  @Input() showFontColor = true;
  @Input() showFontStyle = true;
  @Input() showFontSize = true;
  @Input() showAlignHorizontal = true;
  @Input() showAlignVertical = true;

  @Input() settings: ScreenFieldFormattingModel;
  @Output() settingsChange: EventEmitter<ScreenFieldFormattingModel> = new EventEmitter<ScreenFieldFormattingModel>();

  textSizes = [...DataCardTextSizeToPercent.keys()];
  partSizes = DataCardPartSizes;

  settingsForm: FormGroup<{
    showColon: FormControl<boolean | undefined>;
    size: FormControl<number | undefined>;
    fontStyle: FormControl<number[]>;
    fontSize: FormControl<number>;
    fontColor: FormControl<string>;
    alignHorizontal: FormControl<number>;
    alignVertical: FormControl<number>;
  }>;

  ngOnInit() {
    this.settingsForm = new FormGroup({
      showColon: new FormControl(this.showColonSetting ? this.settings.showColon : undefined),
      size: new FormControl(this.showSizeSetting ? this.settings.size || 50 : undefined),
      fontStyle: new FormControl(this.enumBitToFlagValues(this.settings?.fontStyle || 0)),
      fontSize: new FormControl(this.settings?.fontSize || 4),
      fontColor: new FormControl(this.settings?.fontColor || '#000'),
      alignHorizontal: new FormControl(this.settings?.alignHorizontal || 2),
      alignVertical: new FormControl(this.settings?.alignVertical || 2),
    });
    this.settingsForm.valueChanges.subscribe(updatedSettings => {
      this.settingsChange.emit({
        id: this.settings?.id,
        showColon: updatedSettings.showColon,
        size: updatedSettings.size,
        fontStyle: this.enumFlagValuesToBit(updatedSettings.fontStyle),
        fontSize: updatedSettings.fontSize,
        fontColor: updatedSettings.fontColor,
        alignHorizontal: updatedSettings.alignHorizontal,
        alignVertical: updatedSettings.alignVertical,
      } as ScreenFieldFormattingModel);
    });
  }

  getTextSizeToPercent(textSize: number): string {
    return `${DataCardTextSizeToPercent.get(textSize)}%`;
  }

  private enumBitToFlagValues(bit: number): number[] {
    const bits = Object.keys(FontStyleType).map(Number).filter(Boolean);
    return bits.filter(b => (bit & b) === b);
  }

  private enumFlagValuesToBit(flags: number[]): number {
    return (flags || []).reduce((s, a) => s + a, 0);
  }
}
