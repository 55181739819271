import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/modules/material.module';
import { SharedComponentsModule } from '../shared/modules/shared-components.module';
import { DataCardSettingsModule } from './data-card-settings.module';
import { WeatherTableComponent } from './definition/weather-table/weather-table.component';
import { WeatherTableService } from './definition/weather-table/weather-table.service';

@NgModule({
  imports: [
    DataCardSettingsModule, 
    SharedComponentsModule,
    CommonModule,
    FormsModule,
    MaterialModule
  ],
  declarations: [WeatherTableComponent],
  exports: [WeatherTableComponent],
  providers: [WeatherTableService]
})
export class ModuleDefinitionModule {
  ngDoBootstrap() {
    // Required module bootstrap method
  }
}
