import { cipo } from 'cipo';

cipo.factory('fileService', ['Functions', '$mdDialog', 'URI', 'FileManagerConfig', '$http', 'Message', '$timeout', 'FileSaver', 'Blob', 'Model',
    function (Functions, $mdDialog, URI, FileManagerConfig, $http, Message, $timeout, FileSaver, Blob, Model) {
        var dictExtToMimeType = {
            'doc': 'application/msword',
            '7z': 'application/x-7z-compressed',
            'xer': 'application/patch-ops-error+xml',
        };

        var fileService = {};

        fileService.getExtension = function (file) {
            if (!file || !file.name) {
                return undefined;
            }
            return file.name.substring(file.name.lastIndexOf(".") + 1);
        };

        fileService.setMimeTypeFromExt = function (file) {
            if (!file) {
                return file;
            }

                // Set mimetype
                file.mimeType = file.type;

                // If we don't have a mimetype, then get it from extension
                if (!file.mimeType)
                    file.mimeType = dictExtToMimeType[fileService.getExtension(file)];

                file.mimeTypeQueryString = !file.mimeType ? "null" : encodeURIComponent(file.mimeType);

            fileService.setFileClass(file);

            return file;
        };

        fileService.setFileClass = function (file) {
            if (!file.mimeType) {
                return file;
            }

            file.fileClass = file.mimeType.replace(/\//g, '-').replace(/\./g, '-').replace(/\+/g, '-');

            return file;
        }

        fileService.createFile = function (bytes, name, type) {
            var data = Functions.base64ToArrayBuffer(bytes);
            var file = new File([data], name, { type: type });

            file.fileData = bytes;

            fileService.setMimeTypeFromExt(file);
            fileService.setFileClass(file);

            return file;
        };

        fileService.selectFolder = function (isProjectFile, attachments) {

            var info = {
                modalName: isProjectFile ? 'Select Destination Folder' : 'Select Destination Folder',
                saveButtonText: 'Select',
                modalAction: "selectDestinationFolder",
                urlsData: isProjectFile ? FileManagerConfig.projectFiles : FileManagerConfig.cipoDrive,
                otherParams: isProjectFile ? FileManagerConfig.projectFiles.itemParams : null,
                bodyParams: { isGlobal: true },
                canUseRoot: isProjectFile ? false : true,
                displayAddFolder: !isProjectFile
            }

            $mdDialog.show({
                locals: {
                    items: null,
                    info: info
                },
                controller: 'moveItemsController',
                templateUrl: '/ng/views/admin/modals/moveItems.html',
                parent: angular.element(document.body),
                fullscreen: true,
                focusOnOpen: false,
                multiple: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (result) {

                    // Check if we have a result, if not, set defautl as Root
                    var folderId = -1;
                    if (typeof (result) != 'undefined' && result.id)
                        folderId = result.id;

                    var urlData = isProjectFile ? URI.PROJECT_FILE_MANAGER.SAVE_FILES_TO_FOLDER : URI.MY_CIPO_DRIVE.SAVE_FILES_TO_FOLDER;
                    $http[urlData.method](urlData.toString() + `?contractId=${isProjectFile ? FileManagerConfig.projectFiles.itemParams.contractId : null}`, { destinationFolderId: folderId, fileIds: attachments })
                        .then(function (r) {
                            Message.info('Files saved to folder successfully.');
                        })
                        .catch(function (e) { Message.dberror(e); })
                        .finally(function (e) { })

                }, function () { });
        }

        fileService.showMultipleAttachments = function (attachments, isSaveTo, isProjectFiles) {
            var attachmentsCheckboxes = [];
            var totalLengthAllAttachments = 0;

            // Add attachments
            for (var i = 0; i < attachments.length; i++) {
                attachmentsCheckboxes.push({ key: attachments[i].id, value: attachments[i].toDownload, label: attachments[i].name, description: attachments[i].name });
                totalLengthAllAttachments = totalLengthAllAttachments + attachments[i].length;
            }

            // Define dialog local data object
            var dialogLocals = {
                data: {
                    title: "Select attachments to download",
                    description: "Total size: " + fileService.bytesToSize(totalLengthAllAttachments),
                    showSelectUnselect: true,
                    buttonLabels: [
                        { key: 'confirm', value: isSaveTo ? 'Select' : 'Download' },
                        { key: 'cancel', value: 'Cancel' },
                    ],
                    checkboxes: attachmentsCheckboxes
                }
            }

            var totalLength = 0;
            var downloadAttachments = [];

                // Open dialog
                $mdDialog.show({
                    locals: dialogLocals,
                    controller: 'checkboxDialogController',
                    templateUrl: '/ng/views/system/modals/checkboxModal.tmpl.html',
                    parent: angular.element(document.body),
                    fullscreen: false,
                    focusOnOpen: false,
                    multiple: true,
                    escapeToClose: false,
                    clickOutsideToClose: false
                })
                    .then(function (paramData) {
                        // Check if we have any data
                        if (paramData == null) return;

                        // If is to save to, then
                        if (isSaveTo) {
                            var selected = paramData.filter(function (file) { return file.value === true });
                            fileService.selectFolder(isProjectFiles, selected.map(function (a) { return a.key; }));
                        }
                        else {

                            var currentFolderId = 0;

                            // Loop through all attachments to download them
                            for (var i = 0; i < paramData.length; i++) {
                                // We need to get only the attachments that were selected
                                if (paramData[i].value === true) {
                                    // Get attachments by file id
                                    var file = attachments.find(x => x.id === paramData[i].key);
                                    // Increment total length
                                    totalLength = totalLength + file.length;
                                    // Get folder id
                                    currentFolderId = file.parentId;
                                    // Add file in array
                                    downloadAttachments.push(file);
                                }
                            }

                            // Check if we have a total length bigger thn 200 MB, then we need to create the zip
                            if (totalLength > 104857600) {
                                // Get file ids
                                var fileIds = downloadAttachments.map(function (a) { return a.id; });
                                // Download files as zip
                                fileService.downloadFilesAsZip(fileIds, currentFolderId);
                            }
                            else {

                                // Check if we have any Url, if now, we need to get them from database
                                var filesWithoutUrl = downloadAttachments.filter(function (item) {
                                    return typeof (item.url) == 'undefined' || item.url == '';
                                })

                                // If we have any files that need url, get the url
                                if (filesWithoutUrl) {

                                    var dataURL = isProjectFiles ? URI.PROJECT_FILE_MANAGER.GET_FILES_URL : URI.MY_CIPO_DRIVE.GET_FILES_URL;

                                    Model[dataURL.method](dataURL, { body: { files: filesWithoutUrl } })
                                        .then(function (result) {
                                            if (result) {
                                                for (var i = 0; i < result.length; i++) {
                                                    // Get attachments by file id
                                                    var fileToDownload = downloadAttachments.find(x => x.versionId === result[i].versionId);
                                                    if (fileToDownload)
                                                        fileToDownload.url = result[i].url;
                                                }

                                                fileService.downloadEachFile(downloadAttachments);
                                            }
                                            else
                                                Message.error('Could not get files download URL.');
                                        })
                                        .catch(function (e) {
                                            console.error(e);
                                            Message.dberror(e);
                                        })
                                        .finally(function () { });
                                }
                                else {
                                    fileService.downloadEachFile(downloadAttachments);
                                }
                            }
                        }
                    });
            }

        fileService.downloadEachFile = function (downloadAttachments) {

            var promise = $timeout();

            angular.forEach(downloadAttachments, function (downloadAttachment) {
                promise = promise.then(function () {
                    fileService.downloadFile(downloadAttachment.url, downloadAttachment.name);
                    return $timeout(700);
                });
            });
        }

        fileService.bytesToSize = function formatBytes(bytes, decimals = 2) {

            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }

        // Creates a hidden link element that clicks itself to download the provided file
        fileService.downloadFile = function (url, fileName) {
            var link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.download = fileName;
            link.click();
        }

        fileService.downloadFilesAsZip = function (fileIds, currentFolderId) {

            var dataURL = URI.MY_CIPO_DRIVE.DOWNLOAD_ZIP_FILE;
            var toDownload = { filesId: fileIds, foldersId: [], currentFolderId: currentFolderId };

            $http[dataURL.method](dataURL.toString(), toDownload, { responseType: "arraybuffer" })
                .then(function (result) {
                    if (result.status && result.status == 202) {
                        Message.stickyWarning("Download process has started and is running in the background. You will receive an email once it is completed");
                    } else {
                        var filename = 'Cipo-download';
                        var type = result.headers('Content-Type');
                        var disposition = result.headers('Content-Disposition');
                        if (disposition) {
                            var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                            if (match[1])
                                filename = match[1];
                        }
                        filename = filename.replace(/[<>:"\/\\|?*]+/g, '_') + '.zip';
                        var blob = new Blob([result.data], { type: type });
                        FileSaver.saveAs(blob, filename);
                    }
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                })
                .finally(function () { });
        }

        return fileService;
    }]);
