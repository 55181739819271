import { cipo } from 'cipo';

cipo.factory('froalaService', ['userService', 'URI', '$http',
        function (userService, URI, $http) {
            var froalaService = {};
            var ignoreImageDeletion = false;
            var ignoreImageDeletionTimeout;

        froalaService.attachImageOptions = function (froalaOptions, entityInstanceId) {
            var urlUpload = URI.RICHTEXT.UPLOAD;
            var auth = 'Bearer ' + sessionStorage.getItem('msal.idtoken');
            
            froalaOptions.imageUploadURL = urlUpload.toString();
            froalaOptions.imageUploadMethod = urlUpload.method;
            froalaOptions.imageMaxSize = 5 * 1024 * 1024; //5MB
            froalaOptions.imageAllowedTypes = ['jpeg', 'jpg', 'png'];

            froalaOptions.requestHeaders = {
                Authorization: auth,
                TenantId: userService.tenantId,
            };

            froalaOptions.events = froalaOptions.events || {};

            froalaOptions.events['image.removed'] = function (img) {
                var imageUrl = img.attr('src');
                // console.log('froala.image.removed', 'image to be deleted', imageUrl);
                
                //blob files are ignored
                //only draft instances can delete images
                if (ignoreImageDeletion || entityInstanceId || imageUrl.startsWith('blob:')) {
                    return;
                }

                    $http.delete(`${URI.RICHTEXT.DELETE}?image=${imageUrl}`, { headers: { Authorization: auth } });
                }
                froalaOptions.events['html.set'] = function () {
                    // console.log('froala.html.set');
                    froalaService.ignoreImageDeletion();
                }
            };

            froalaService.ignoreImageDeletion = function () {
                // this method is used when richtext text is replaced and we need to not delete the images while text is switched
                ignoreImageDeletion = true;
                if (ignoreImageDeletionTimeout) {
                    clearTimeout(ignoreImageDeletionTimeout);
                }
                ignoreImageDeletionTimeout = setTimeout(() => {
                    ignoreImageDeletion = false;
                }, 3000);
            }

        return froalaService;
    }]);
