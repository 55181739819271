import { cipo } from 'cipo';
import moment from 'moment';

cipo.controller('acontractsController',
    function ($scope, $state, Manager, $uibModal, Message, dialogs, URI, ACTIONS, userService, $mdDialog) {
        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.module = userService.system.modules[$state.current.code];
    // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor($state.current.code); }
        });
    $scope.sendList = function (data) {
        var listToSend = [];
        if (Object.prototype.toString.call(data) == '[object Array]') {
            if (data.length) {
                for (var i = 0; i < data.length; i++) {
                    if (typeof data[i].contractId != 'undefined') {
                        listToSend.push(data[i].contractId);
                    }
                }
            }
        } else {
            if (typeof data.contractId != 'undefined') {
                listToSend.push(data.contractId);
            }
        }
        return listToSend;
    }
    $scope.manager = new Manager({
        objectsPerPage: 20,
        dataURL: URI.CONTRACT.SEARCH,
        dataWrapper: 'data',
        options: {
            multiselect: true
        },
        otherParams: {
            showDisabled: true
        },
        rowOnClick: function (row) {
            $scope.viewContract(row.contractId);
        },
        leftActions: [
            {
                setProperties: ACTIONS.CREATE,
                conditionOnTop: function () {
                    if ($scope.operations.Create) return true;
                    else return false;
                },
                icon: 'mdi mdi-file-plus',
                click: function (rows) {
                    $scope.viewContract();
                }
            },
        ],
        actions: [
            {
                setProperties: ACTIONS.DETAILS,
                click: function (row) {
                    $scope.viewContract(row.contractId);
                }
            },
            {
                setProperties: ACTIONS.REFRESH,
                click: function () {
                    $scope.manager.loadPage();
                }
            },
            {
                setProperties: ACTIONS.CLOSE,
                conditionOnTop: function () {
                    if ($scope.operations.Update) return true;
                    else return false;
                },
                condition: function (row) {
                    if (row.contractStatusId != 0)
                        return true;
                    else return false;
                },
                click: function (rows) {
                    $scope.closeContracts($scope.sendList(rows));
                }
            }
        ],
        parseData: function (data) {
            var data = data || [];
            for (var i = 0; i < data.length; i++) {

                for (var key in data[i]) {
                    if (data[i].hasOwnProperty(key)) {
                        if (key == 'startDate' || key == 'completionDate' ||
                            key == 'plannedConstructionStartDate' || key == 'plannedConstructionEndDate'
                            || key == 'plannedDesignStartDate' || key == 'plannedDesignEndDate') {
                            if (Object.prototype.toString.call(data[i][key]) == '[object String]' && data[i][key][data[i][key].length - 1] != 'Z')
                                data[i][key] = data[i][key] + 'Z';
                            data[i][key] = moment(data[i][key]).format(userService.formats.date);
                        }
                        if (key == 'earnedToDate' || key == 'budget' || key == 'changeOrdersToDate') {
                            data[i][key] = '<div class="text-right">$' + data[i][key] + '</div>'
                        }
                        if (key == 'total') {
                            data[i][key] = '<div class="text-right">' + data[i][key] + '</div>'
                        }
                    }
                    
                }

            }

            return data;
        },
    });

    var statusLookup = {
        true: { class: 'deactivated', name: 'Closed' },
        false: { class: 'basic', name: 'Active' }
    };

    $scope.manager.set_Columns([
        { name: 'contractName', label: 'Contract Name', type: 'text' },
        { name: 'contractNumber', label: 'Contract No.', type: 'text' },
        { name: 'startDate', label: 'Start Date', type: 'text' },
        { name: 'completionDate', label: 'Completion Date', type: 'text' },
        { name: 'earnedToDate', label: 'Earned to Date', type: 'checkbox' },
        { name: 'budget', label: 'Budget', type: 'checkbox' },
        { name: 'changeOrdersToDate', label: 'Change Orders to Date', type: 'checkbox' },
        {
            name: 'total', label: 'Total Float', type: 'checkbox' },
        //{ name: 'plannedConstructionStartDate', label: 'Planned Construction Start Date', type: 'text' },
        //{ name: 'plannedConstructionEndDate', label: 'Planned Construction End Date', type: 'text' },
        //{ name: 'plannedDesignStartDate', label: 'Planned Design Start Date', type: 'text' },
        //{ name: 'plannedDesignEndDate', label: 'Planned Design End Date', type: 'text' },
        { name: 'status', label: 'Status', type: 'text' }
    ]);
    $scope.manager.loadPage();

    /* $scope.viewContract = function (id) {
        var id = (typeof id != 'undefined') ? parseInt(id) : 0;
        var modalInstance = $uibModal.open({
            controller: 'EditContractController',
            templateUrl: '/ng/views/admin/modals/editContract.html',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                "id": function () {
                    return id;
                }
            }
        });

        modalInstance.result.then(function (result) {
            $scope.manager.page = 1;
            $scope.manager.loadPage();
        }, function () {
        });
    } */

    $scope.viewContract = function (id, ev) {
        var id = (typeof id != 'undefined') ? parseInt(id) : 0;
        $mdDialog.show({
            locals: { id: id, operations: $scope.operations },
            controller: 'EditContractController',
            templateUrl: '/ng/views/admin/modals/editContract.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            clickOutsideToClose: false,
            escapeToClose: false
        })
            .then(function (result) {
                $scope.manager.page = 1;
                $scope.manager.loadPage();
            }, function () {
                //$scope.manager.loadPage();
            });
    };

    var confirm = function (title, text, ev) {
        return $mdDialog.confirm()
            .title(title)
            .textContent(text)
            .ariaLabel('Confirm Dialogue')
            .targetEvent(ev)
            .ok('Proceed')
            .cancel('Cancel');
    }

    $scope.closeContracts = function (list) {
        var list = list || [];

        if (list.length) {
            //var d = dialogs.confirm('Closing Contracts', 'Are you sure you want to close the selected Contract(s)?');
            $mdDialog.show(confirm('Close Contracts', 'Are you sure you want to close the selected Contract(s)?'))
                .then(function () {
                    $scope.manager.loading = true;
                    $scope.manager.put(URI.CONTRACT.CLOSE, { list: list })
                        .then(function () {
                            Message.info('Contract(s) closed successfully');
                        })
                        .catch(function (e) {
                            $scope.manager.loading = false;
                            Message.dberror(e, $scope.manager.rows, 'contractId', 'ContractName');
                        })
                        .finally(function () {
                            $scope.manager.loadPage();
                        });
                })
                .catch(function () {
                });
        } else {
            Message.info('Please select an item to close first');
        }
    }
});
