import { cipo } from 'cipo';
import { ScreenModel, ScreenTypeEnum } from '../../../src/app/models/module/screen';

cipo.controller('dataController',
function ($scope) {
    $scope.screen = {
        id: 602,
        name: 'Card form 1',
        type: ScreenTypeEnum.Card,
    } as ScreenModel;
});
