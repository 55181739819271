import { cipo } from 'cipo';

var tenantController = function ($state, $scope, $timeout, Upload, URI, authService, userService, Menu, Model, Message, UserProfile, $mdDialog, localStorageService, fileService) {
    // ex navigation.js

    Object.defineProperty($scope, 'Menu', {
        get: function () {
            return Menu;
        }
    });

    $scope.showMenuSearch = function () {
        $scope.Menu.isMenuSearch = true;
        $scope.Menu.focusSearch = true;

    }

        var get_modulesList = function () {
            $scope.loadingModulesList = true;
            $scope.modulesList = [];
            // $timeout(function () {
                
            //    $scope.modulesList = [
            //        {
            //            key: 1, value: "Module 1", isUsed: true
            //        },
            //        {
            //            key: 1, value: "Module 2", isUsed: false
            //        }
            //    ];
            //    var isUsed = false;
            //    for (var i = 3; i < 30; i++) {                    
            //        $scope.modulesList.push({ key: i, value: "Module " + i, isUsed: isUsed });
            //        isUsed = !isUsed;
            //    }
            //    $scope.loadingModulesList = false;

        //}, 500);

        var dataURL = URI.NOTIFICATION.MODULES_LIST;
        var params = {
            contractId: userService.system.userdata.contractId || 0
        };

        Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                $scope.modulesList = r;
            })
            .catch(function (e) {
                Message.dberror(e);
            })
            .finally(function () {
                $scope.loadingModulesList = false;
            })


    }

    $scope.toggleNotification = function (item) {
        item.loading = true;
        var dataURL = URI.NOTIFICATION.TOGGLE_PER_MODULE;
        var params = {
            contractId: userService.system.userdata.contractId || 0,
            moduleId: item.key,
        };

        Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                Message.info('Notification updated sucessfully.');
                item.isUsed = !item.isUsed;
            })
            .catch(function (e) {
                Message.dberror(e);
            }).finally(function () {
                item.loading = false;
            })



    }

    $scope.toggleNotifications = function (isOn) {
        // alert("Turning notifications " + (isOn ? 'on' : 'off'));
        // magic
        var dataURL = URI.NOTIFICATION.TOGGLE_GLOBAL;
        var params = {
            contractId: userService.system.userdata.contractId || 0,
            isOn: isOn ? 1 : 0,
        };

        Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                Message.info('Notifications updated sucessfully.');
                get_modulesList();
            })
            .catch(function (e) {
                Message.dberror(e);
            })


    }

    Object.defineProperty($scope, 'ctOps', {
        get: function () { return userService.getOperationsFor('CT') }
    });

        userService.mimeTypes();
        $scope.editNotifications = function () {
            $scope.editingNotifications = !$scope.editingNotifications;
            if ($scope.editNotifications) get_modulesList();
        }
        $scope.editContract = function (item, ev) {
            var item = userService.system.contractsLookup[userService.system.userdata.contractId];
            item.entity_instance_id = item.entityInstanceId;
            item.isInitiator = false;
            $mdDialog.show({
                locals: { item: item, module: userService.system.modules['CT'], screenId: null },
                controller: 'editHContractController',
                templateUrl: '/ng/views/admin/modals/editHContract.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false,
                disableParentScroll: true,
                multiple: true
            })
            .then(function (result) {
                userService.isCurrentContractPeriodsChanged = true;
            }, function () {
                // Get workflow from current module
                if(userService.system.oldWorkflowId)
                    userService.system.workflowId = userService.system.oldWorkflowId;
            });
    };


    Menu.init();
    $scope.contractSearch = { content: "" };
    $scope.$on("$mdMenuClose", function () { $scope.contractSearch.content = ""; });

    $scope.uploadFiles = function (file, errFiles) {
        $scope.f = file;
        $scope.errFile = errFiles && errFiles[0];
        
        fileService.setMimeTypeFromExt(file);
        
        if (file) {
            $scope.isUploading = true;
            
            var urlParamString = '?mimeType=' + file.mimeTypeQueryString;
            file.upload = Upload.upload({
                url: URI.MY_CIPO_DRIVE.UPLOAD_FILE_AS_USER_LOGO.toString() + urlParamString,
                method: URI.MY_CIPO_DRIVE.UPLOAD_FILE_AS_USER_LOGO.method.toString(),
                data: { file: file }
            });

            file.upload.then(function (response) {
                $timeout(function () {
                    file.result = response.data;
                    userService.User();
                    $scope.isUploading = false;
                });
            }, function (response) {
                if (response.status > 0)
                    $scope.errorMsg = response.status + ': ' + response.data;
                $scope.isUploading = false;
            }, function (evt) {
                file.progress = Math.min(100, parseInt(100.0 *
                    evt.loaded / evt.total));
            });
        }
    }

    $scope.editSignature = function () {
        $scope.isEditSign = !$scope.isEditSign;
    }

    $scope.selectItem = function (item) {
        //if (item.typeId != 1) Menu.show = false;
        $scope.Menu.select(item, userService.system.userdata.contractId);
        if (item.typeId != 1) $scope.isHideMenu = true;
    }
    
    $scope.trapClik = function (event) {
        event.stopPropagation();
    }

    // ex top.js
    Object.defineProperty($scope, 'userService', {
        get: function () {
            //.log('userService', userService)
            return userService;
        }
    });

    Object.defineProperty($scope, 'currentRoles', {
        get: function () {
            //.log('userService', userService)
            return userService.system.userRoleNames.join(", ");
        }
    });
    //var roleNames = userService.system.userRoleNames;

    Object.defineProperty($scope, 'tenantId', {
        get: function () {
            if (userService.system.userdata) {
                return userService.system.userdata.tenantId;
            } else return 0;

        }
    });

    var tenantsLookup = function () {
        var dict = {};
        if (userService.system.tenants && userService.system.tenants.length) {
            for (var i = 0; i < userService.system.tenants.length; i++) {
                dict[userService.system.tenants[i].id] = userService.system.tenants[i].description;
            }
        }
        return dict;
    };

    Object.defineProperty($scope, 'tenantName', {
        get: function () {
            if (userService.system.userdata) {
                return tenantsLookup()[userService.system.userdata.tenantId];
            } else return 0;

        }
    });

    Object.defineProperty($scope, 'currentContract', {
        get: function () {
            if (userService.system.userdata) {
                return userService.system.userdata.contractId;
            } else return 0;

        }
    });
    //Object.defineProperty($scope, 'currentContractName', {
    //    get: function ()
    //    {
    //        if (userService.system.userdata)
    //        {
    //            return userService.system.userdata.contractName;
    //        } else return 0;

    //    }
    //});
    //emailCounts

    Object.defineProperty($scope, 'corOps', {
        get: function () { return userService.getOperationsFor("C") && userService.getOperationsFor("C").Read ? true : false; }
    });

    Object.defineProperty($scope, 'emailCounts', {
        get: function () {
            if (userService.system.userdata) {
                return userService.system.emailCounts;
            } else return 0;

        }
    });
    $scope.openMenu = function ($mdMenu, ev) {
        // originatorEv = ev;
        $mdMenu.open(ev);
        $timeout(function () {
            $scope.focusSearch = true;
            $timeout(function () {
                $scope.focusSearch = false;

            }, 300);
        }, 300);
    };

    var setData = function (data) {
        $scope.pageLoader = false;
        $scope.user = data.userdata;
        $scope.implist = data.impList;
        $scope.currentCompany = {};
    };
    
    setData(userService.system);
    // $scope.Menu.visibleMenu = false;
    // $scope.Menu.isHideMenu = true;
    $scope.toggleMenu = function () {
        Menu.show = !Menu.show;
        localStorageService.set('menu.show', Menu.show);
        $scope.Menu.visibleMenu = !$scope.Menu.visibleMenu;
        $scope.Menu.isHideMenu = !$scope.Menu.isHideMenu;
    };
    $scope.showMenu = function () {
        Menu.show = !Menu.show;
        localStorageService.set('menu.show', Menu.show);
        $scope.Menu.visibleMenu = true;
        $scope.Menu.isHideMenu = false;
    };
    // $scope.toggleMenu();

    $scope.hideMenu = function () {
        Menu.show = false;
        localStorageService.set('menu.show', Menu.show);
        $scope.Menu.visibleMenu = false;
        $scope.Menu.isHideMenu = true;
        $scope.Menu.clearSearchTerm();
    }


    $scope.toggleProfile = function () {
        $scope.isProfile = !$scope.isProfile;
        $scope.editingNotifications = false;
        $scope.isEditSign = false;
        $scope.isSignWithName = false;
        if (!$scope.isProfile) $scope.userProfile.hideForms();
        else $scope.userProfile = new UserProfile();
    }
    $scope.trapClik = function (event) {
        event.stopPropagation();
    }
    $scope.changeTenant = function () {
        userService.clearCurrentContract();
        $state.go("main");
    }

    $scope.logout = function () {
        $scope.toggleProfile();
        authService.logout();
    };

    $scope.goToEmail = function () {
        $state.go('tenant.emails');
        Menu.select(Menu.moduleMapping[-11], userService.system.userdata.contractId);
    };
    $scope.goToDashboard = function () {
        $state.go('tenant.dashboard');
    };

    $scope.inputs = {
        contractValue: userService.system.userdata.contractId
    };

    $scope.$watch(function () {
        return userService.system.userdata.contractId;
    },
        function (newVal) {
            if (typeof newVal !== 'undefined') {
                $scope.inputs.contractValue = userService.system.userdata.contractId
            }
        });

    $scope.changeContract = function (contract) {
        if (contract.key !== $scope.inputs.contractValue) {
            userService.setContract(contract.key);
            Menu.triggerRefresh();

            if ($state.current.code == 'DYN-MOD') {
                var params = angular.copy($state.params);
                var module = userService.getModuleObjectById($state.params.id) || {};
                
                params.contractId = module.perContract ? contract.key : 0;
                $state.transitionTo($state.current.name, params, {
                    reload: true,
                    inherit: true,
                    notify: true
                });
            }
                
        }
    };

    $scope.logout = function () {
        authService.logout();
    };

    $scope.passwordChange = function () {
        authService.passwordChange();
    };

    $scope.profileEdit = function () {
        authService.profileEdit();
    };

    $scope.$watch(function () {
        return userService.hasModulesLoaded;
    },
        function (newVal) {
            if (newVal) {
                Menu.init();
                Menu.highlight(Menu.currentRoute, Menu.currentParams);
            }
        });
};

cipo.controller('tenantController', tenantController);