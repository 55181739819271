import { cipo } from 'cipo';

cipo.controller('pspdfkitViewerController',
    function ($scope, $mdDialog, MODAL, module, documentId, fileUrl, mimeType, title, contractId, fileId, userService, PSPDFKitInstance) {
        /* VARIABLES */
        $scope.module = module || {};
        $scope.documentId = documentId || null;
        $scope.fileUrl = fileUrl || null;
        $scope.fileId = fileId || null;
        $scope.mimeType = mimeType || null;
        $scope.isViewOnly = !$scope.documentId;
        $scope.title = title || '';
        $scope.contractId = contractId || 0;
        $scope.modalInfo = MODAL;
        $scope.isLoading = false;
        $scope.isBusy = false;
        $scope.setTitle = _setTitle;
        $scope.loadDocument = _loadDocument;
        $scope.close = _close;
        /* END VARIABLES */

        /* INIT */
        // $scope.setTitle($scope.isViewOnly);
        $scope.pspdfkitInstance = new PSPDFKitInstance({
            moduleId: $scope.module.moduleId,
            contractId: $scope.contractId,
            currentUserId: userService.system.userdata.id,
            isLoading: function (isLoading) { /*$scope.isLoading = isLoading;*/ }
        });
        $scope.loadDocument($scope.isViewOnly);
        /* END INIT */

        /* WATCHERS */
        $scope.$watch(function () {
            return document.querySelector('#pspdfkit').innerHTML;
        },
            function (n, o) {
                if (n != o) {
                    n.length > 0 ? $('#pspdfkitLoadingMsg').hide() : $('#pspdfkitLoadingMsg').show();
                }
            });
        $scope.$watch(function () {
            return $scope.pspdfkitInstance.showClickMessage;
        },
            function (n, o) {
                if (n != o) {
                    n ? $('.pspdfkit-click-message').slideDown() : $('.pspdfkit-click-message').slideUp();
                }
            });
        /* END WATCHERS */

        // Sets the title of the dialog
        function _setTitle(isViewOnly) {
            if (!isViewOnly)
                $scope.title = `${$scope.module.name} - ${$scope.title}`;
        }

        // Loads the document provided to the viewer
        function _loadDocument(isViewOnly) {
            // If view only, we display the document in view-only mode. Otherwise, we load it via pspdfkit document_id.
            isViewOnly
                ? $scope.pspdfkitInstance.view($scope.fileUrl, $scope.fileId, $scope.mimeType)
                : $scope.pspdfkitInstance.markup($scope.documentId, $scope.fileId);
        }

        // Unloads the document instance and then closes dialog.
        function _close(isViewOnly) {
            $scope.pspdfkitInstance.unload(isViewOnly);
            $mdDialog.cancel();
        }
    });
