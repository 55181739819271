import { cipo } from 'cipo';

cipo.controller('moveItemsController',
    function ($scope, MODAL, URI, $http, $timeout, items, info, Name, $mdDialog, Message, $q) {

            $scope.disabledBtn = false;
            $scope.message = "Upload";
            $scope.modalInfo = MODAL;
            $scope.modalInfo.modalName = info.modalName;
            $scope.modalInfo.saveButtonText = info.saveButtonText;
            $scope.modalInfo.modalAction = info.modalAction;
            $scope.modalInfo.canUseRoot = info.canUseRoot;
            $scope.modalInfo.displayAddFolder = info.displayAddFolder;
            // This is a hack, for treecontrol, we want to search folders and is working only with: filter-expression="filterFolder" to search in children too
            // The other option is to use: filter-expression="{ folderPath: filterFolder }" here will search only parents and will show children, but it will not search in expanded children, the fix was with this hack
            // If users search by 0 or 1, that will search in other properties like hasChildren if folder has children
            $scope.wantedProperties = ['id', 'parentId', 'name', 'hasChildren', 'folderPath'];

        var urlsData = info.urlsData || null;
        var otherParams = info.otherParams || null;
        var bodyParams = info.bodyParams || null;

            var initiallySelectedFolder = info.selectedFolder || null;

            var toMove = items;
            var foldersIds = [];

            if (toMove) {
                for (var i = 0; i < toMove.foldersId.length; i++) {
                    foldersIds.push(toMove.foldersId[i]);
                }
            }

            // to block the source folder if moving
            $scope.sourceFolderId = info.sourceFolderId || null;

        $scope.treeSetup = {
            treeOptions: {
                isSelectable: function (node) {
                    return !node.unselectable && node.id != $scope.sourceFolderId;
                },
                nodeChildren: "folders",
                dirSelectable: true,
                isLeaf: function (node) { return false; },
                multiSelection: false,
                injectClasses: {
                    ul: "a1",
                    li: "a2",
                    liSelected: "a7",
                    iExpanded: "a3",
                    iCollapsed: "a4",
                    iLeaf: "a5",
                    label: "a6",
                    labelSelected: "a8"

                    }
                },
                orderby: ""
            };
            $scope.addFolder = function () {
                $scope.disabledBtn = true;
                var newFolder = { name: "", parentId: $scope.selectedFolder ? $scope.selectedFolder.id : -1, isFolder: true };
                if (otherParams)
                    for (var key in otherParams) {
                        if (otherParams.hasOwnProperty(key)) {
                            newFolder[key] = otherParams[key];
                        }
                    }
                $scope.newFolder = new Name(newFolder);
            }
            $scope.cancelFolder = function () {
                $scope.newFolder = {};
                $scope.disabledBtn = false;
            }
            $scope.saveFolder = function () {
                $scope.newFolder.form.validate();
                if ($scope.newFolder.form.isValid) {
                    $scope.newFolder.form.loading = true;
                    $scope.newFolder[urlsData.folders.addFolderURL.method](urlsData.folders.addFolderURL, $scope.newFolder.properties)
                        .then(function (r) {
                            $scope.getChildren($scope.selectedFolder ? $scope.selectedFolder : $scope.root);
                            $scope.selectedFolderId = r;
                            $scope.cancelFolder();
                            Message.info("Folder added successfully");
                        })
                        .catch(function (e) {
                            console.error(e);
                            Message.dberror(e);
                            if ($scope.newFolder && $scope.newFolder.form) $scope.newFolder.form.loading = false;
                        })
                        .finally(function () { });

                }

            }
            $scope.expandedFolders = [];
            var lookupFolders = {};
            $scope.root = { id: -1 };
            $scope.folderTree = [];

            var extractFolders = function (data, unselectable) {
                var folders = [];
                for (var i = 0; i < data.length; i++) {
                    if (data[i].id != -2) {
                        if (!lookupFolders[data[i].id]) lookupFolders[data[i].id] = data[i];
                        else {
                            // if the folders was aready in, we replace with the new version, to keep it referenced but not loose info
                            data[i].folders = lookupFolders[data[i].id].folders;
                            data[i].expanded = lookupFolders[data[i].id].expanded;
                            if ($scope.expandedFolders.length)
                                for (var j = 0; j < $scope.expandedFolders.length; j++) {
                                    if ($scope.expandedFolders[j].id == data[i].id) {
                                        var toReplace = $scope.expandedFolders[j];
                                        $scope.expandedFolders.splice($scope.expandedFolders.indexOf(toReplace), 1, data[i]);
                                        break;
                                    }
                                }
                            lookupFolders[data[i].id] = data[i];
                        }
                        if (unselectable) data[i].unselectable = unselectable;
                        else {
                            if (foldersIds.indexOf(data[i].id) != -1) data[i].unselectable = true;
                        }

                        // Remove unwanted properties
                        for (var key in data[i]) {
                            if ($scope.wantedProperties.indexOf(key) === -1) {
                                delete data[i][key];
                            }
                        }

                        folders.push(data[i]);
                    }

                }
                return folders;
            }
            $scope.getChildren = function (folder, status) {
                var p = $q.defer();
                if (status) folder.expanded = status;
                if (!folder.expanded && folder.id != -1 && !status) {
                    $scope.expandedFolders.push(folder);
                    folder.expanded = true;
                }
                if (status || typeof status == 'undefined') {
                    folder.loadingChildren = true;
                    // var urlString = '?folderId=' + folder.id + '&isFolders=true';
                    var urlString = '?isFolders=true';
                    var body = { id: folder.id };
                    if (otherParams)
                        for (var key in otherParams) {
                            if (otherParams.hasOwnProperty(key)) {
                                urlString += '&' + key + '=' + otherParams[key];
                            }
                        }

                if (bodyParams)
                    for (var key in bodyParams) {
                        if (bodyParams.hasOwnProperty(key)) {
                            body[key] = bodyParams[key];
                        }
                    }
                $http[urlsData.tree.url.method](urlsData.tree.url.toString() + urlString, body)
                    .then(function (r) {
                        if (folder.id == -1) {
                            $scope.folderTree = extractFolders(r.data.data);
                        } else folder.folders = extractFolders(r.data.data, folder.unselectable);
                        if ($scope.selectedFolderId) {
                            $scope.selectedFolder = lookupFolders[$scope.selectedFolderId];
                            $scope.selectedFolderId = null;
                        }
                        p.resolve(folder.id);
                    })
                    .catch(function (e) { console.error(e); Message.dberror(e); p.reject(); })
                    .finally(function () { folder.loadingChildren = false; });

                    return p.promise;
                }


        }
        // [-1, 10, 13]

        var loopGetChildren = function (id) {

            if (!initiallySelectedFolder.parents) {
                Message.warning("The selected folder is missing path information and can't be located.");
                return;
            }

            if (!id) {
                // root folders are in
                if (initiallySelectedFolder.parents.length > 1) {
                    // selected folder is not a root folder
                    $scope.getChildren(lookupFolders[initiallySelectedFolder.parents[1]])
                        .then(function () {
                            loopGetChildren(initiallySelectedFolder.parents[1]);
                        })
                } else $scope.selectedFolder = lookupFolders[initiallySelectedFolder.id];

            } else {
                // we get the next level of children
                var x = initiallySelectedFolder.parents.indexOf(id);

                if (initiallySelectedFolder.parents[x + 1]) {
                    $scope.getChildren(lookupFolders[initiallySelectedFolder.parents[x + 1]])
                        .then(function () {
                            loopGetChildren(initiallySelectedFolder.parents[x + 1]);
                        })
                } else $scope.selectedFolder = lookupFolders[initiallySelectedFolder.id];
            }
        }

            $scope.getChildren($scope.root)
                .then(function (r) {
                    if (initiallySelectedFolder)
                        loopGetChildren();
                })



            var otherParamsString = "";
            if (otherParams) {
                otherParamsString = "?";
                for (var key in otherParams) {
                    if (otherParams.hasOwnProperty(key)) {
                        otherParamsString += key + "=" + otherParams[key] + '&';
                    }
                }
                otherParamsString = otherParamsString.substring(0, otherParamsString.length - 1);
            }

        $scope.modalAction = function () {
            $scope[info.modalAction]();
        }

            $scope.selectDestinationFolder = function () {
                $mdDialog.hide($scope.selectedFolder);
            }

        $scope.move = function () {
            toMove.destinationFolderId = $scope.selectedFolder ? $scope.selectedFolder.id : -1;
            $scope.disabledBtn = true;
            $http[urlsData.folders.moveURL.method](urlsData.folders.moveURL.toString() + otherParamsString, toMove)
                .then(function (r) {
                    $mdDialog.hide(toMove.destinationFolderId);
                    Message.info("Items moved successfully");
                })
                .catch(function (e) { console.error(e); Message.dberror(e); })
                .finally(function () { $scope.disabledBtn = false; });
        }
        $scope.closeModal = function () {
            /*if (isModified) $mdDialog.hide();
            else $mdDialog.cancel(); */
            $mdDialog.cancel();
        };

        
    });
