import { cipo } from 'cipo';
import { environment } from 'src/environments/environment';

cipo.factory('userService', ['$http', '$q', 'localStorageService', 'URI', 'CIPO_ICONS',
    function ($http, $q, localStorageService, URI, CIPO_ICONS)
    {
        var userService = {};
        userService.redirectPage = null;

        userService.init = function ()
        {
            userService.tenantId = null;
            userService.hasTenantsLoaded = false;
            userService.isCurrentContractPeriodsChanged = false;
            userService.hasModulesLoaded = false;
            userService.hasContractsLoaded = false;
            userService.isUserLoaded = false;
            userService.isAppBooted = false;
            userService.isReloadGrid = false;
            userService.isReloadModuleInformation = false;
            // userService.isLoadingMenu = true;
            userService.targetState = null;
            userService.firstPage = 'dashboard';
            userService.formats = {
                date: 'MM/DD/YYYY',
                datetime: 'MM/DD/YYYY hh:mm A',
                time: 'hh:mm A'
            };

                userService.system = {
                    userdata: {
                        contractId: null,
                        // True if user has any roles for correspondence with read permission
                        hasCorrespondenceAccess: false
                    },
                    userRoleNames: [],
                    userRoleAbbrevs: [],
                    impList: [],
                    tenants: [],
                    tenantsLookup: {},
                    contracts: [],
                    contractsLookup: {},
                    menu: [],
                    modules: {},
                    modulesList: [],
                    permissions: {},
                    mNames: {},
                    emailCounts: 0,
                    icons: [],
                    iconsLookup: {},
                    mimeTypes: {dict: {}, list: []},
                    selectedModuleId: null,
                    workflowId: null,
                    oldWorkflowId: null
                };

        };

        userService.init();

        userService.getCurrency = function ()
        {
            try
            {
                return userService.system.contractsLookup[userService.system.userdata.contractId].currencySymbol;
            } catch (e)
            {
                console.log('currency not available');
            }

            return "";
        };

        // clear
        userService.clearData = function ()
        {
            userService.init();
        };

        userService.get_EmailCounts = function () {
            var p = $q.defer();
            $http[URI.EMAIL.UNREAD_THREAD_COUNT.method](`${URI.EMAIL.UNREAD_THREAD_COUNT}?contractId=${userService.system.userdata.contractId}`.toString())
                .then(function (r) {
                    userService.system.emailCounts = r.data;
                    p.resolve(r);
                })
                .catch(function (e) { console.error(e); p.reject(); });

            return p.promise;
        }

        userService.Icons = function ()
        {
            var p = $q.defer();
            var icons = CIPO_ICONS();

            $http[URI.ICONS.DICT.method](URI.ICONS.DICT.toString())
                .then(function (r)
                {
                    if (r && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            icons.lookup[r.data[i].key].isFavorite = r.data[i].isFavorite;
                            icons.lookup[r.data[i].key].value = r.data[i].value;
                        }
                    }
                    userService.system.icons = icons.list;
                    userService.system.iconsLookup = icons.lookup;
                    p.resolve();
                })
                .catch(function (e)
                {
                    console.error(e);
                    p.reject(e);
                    // Message.dberror(e);
                });

            return p.promise;
        };

        userService.mimeTypes = function ()
        {
            var p = $q.defer();
            var mimetypes = { dict: {}, list: [] };
            $http[URI.FIELD_DEFINITION.MIME_TYPES_DICT.method](URI.FIELD_DEFINITION.MIME_TYPES_DICT.toString())
                .then(function (r)
                {
                    if (r && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            mimetypes.list.push(r.data[i]);
                            mimetypes.dict[r.data[i].key] = r.data[i];
                        }
                    }
                    userService.system.mimeTypes = mimetypes;
                    p.resolve();
                })
                .catch(function (e)
                {
                    console.error(e);
                    p.reject(e);
                    // Message.dberror(e);
                });

            return p.promise;
        };

        userService.getRolesForContract = function (contractId)
        {
            var p = $q.defer();

            userService.system.userRoleNames = [];
            userService.system.userRoleAbbrevs = [];

            if (contractId)
                $http.get(environment.baseApiUrl + '_api/Main/UserRolesForContract?contractId=' + contractId)
                    .then(function (r)
                    {
                        if (r && r.data && r.data.length)
                        {
                            var userRoleNames = [], userRoleAbbrevs = [];

                            for (var i = 0; i < r.data.length; i++)
                            {
                                userRoleNames.push(r.data[i].roleName);
                                userRoleAbbrevs.push(r.data[i].abbr);
                            }

                            userService.system.userRoleNames = userRoleNames;
                            userService.system.userRoleAbbrevs = userRoleAbbrevs;
                        }

                        p.resolve(r);
                    })
                    .catch(function (e) { console.error(e); p.reject(); });

            return p.promise;
        };

        userService.User = function ()
        {
            var p = $q.defer();

            $http.get(environment.baseApiUrl + '_api/Main/UserData').then(function (r)
            {
                if (window.StonlyWidget) {
                    window.StonlyWidget('identify', r.data.id.toString());
                }

                userService.system.userdata.signature = null;

                for (var key in r.data)
                {
                    if (key !== 'contractId')
                    {
                        userService.system.userdata[key] = r.data[key];
                    }
                    else
                    {
                        if (!userService.system.userdata.contractId)
                        {
                            userService.system.userdata[key] = r.data[key];
                        }
                    }
                }
                localStorageService.set('currentContract', userService.system.userdata.contractId);
                userService.isUserLoaded = true;
                userService.Icons();

                p.resolve(r);
            })
                .catch(function (e)
                {
                    console.error(e); p.reject();
                });

            return p.promise;
        };
        
        userService.Tenants = function (tenantIdentifier)
        {
            var p = $q.defer();

            tenantIdentifier = tenantIdentifier || null;

            $http.get(environment.baseApiUrl + '_api/Main/Tenants')
                .then(function (r)
                {
                    userService.hasTenantsLoaded = true;
                    userService.system.tenants = r.data;
                    userService.setTenant(tenantIdentifier);

                    if (r.data && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            userService.system.tenantsLookup[r.data[i].id] = r.data[i];
                        }
                    }

                    p.resolve(r);
                })
                .catch(function (e)
                {
                    console.error(e);

                    p.reject(e);
                });

            return p.promise;
        };

        userService.Reload_Tenant_Logos = function ()
        {
            var p = $q.defer();

            $http.get(environment.baseApiUrl + '_api/Main/Tenants')
                .then(function (r)
                {
                    if (r.data && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            userService.system.tenantsLookup[r.data[i].id].logo = r.data[i].logo;
                            userService.system.tenantsLookup[r.data[i].id].bigLogo = r.data[i].bigLogo;
                        }
                    }
                    p.resolve(r);
                })
                .catch(function (e) { console.error(e); p.reject(); });

            return p.promise;
        };

        userService.Contracts = function ()
        {
            var p = $q.defer();
            var userId = userService.system.userdata.id || 0;

            $http[URI.CT.DICT.method](URI.CT.DICT.toString() + '?userId=' + userId)
                .then(function (r)
                {
                    userService.hasContractsLoaded = true;
                    r.data = r.data.filter(function (o) { return o.isUsed === true });
                    userService.system.contracts = r.data;
                    userService.system.contractsLookup = {};

                    if (r.data && r.data.length)
                    {
                        for (var i = 0; i < r.data.length; i++)
                        {
                            userService.system.contractsLookup[r.data[i].key] = r.data[i];
                        }
                    }

                    if ((!userService.currentContract() || !userService.system.contractsLookup[userService.currentContract()]) && r.data.length)
                    {
                        userService.setContract(r.data[0]['key']);
                    }

                    else
                    {
                        userService.setContract(userService.currentContract());
                    }

                    p.resolve(r);
                })
                .catch(function (e) { console.error(e); p.reject(); });

            return p.promise;
        };

        userService.checkUserOnContract = function (contractId) {
            return typeof userService.system.contractsLookup[contractId] != 'undefined' && userService.system.contractsLookup[contractId].isUsed;
        };

        userService.Modules = function (contractId)
        {
            var p = $q.defer();
            contractId = contractId || 0;
            userService.isLoadingMenu = true;
            $http.get(environment.baseApiUrl + '_api/Menu/UserMenu?contractId=' + contractId)
                .then(function (r)
                {
                    userService.system.menu = r.data;
                    userService.system.modules = userService.parseModulesList(r.data);
                    userService.parseModuleNames();
                    userService.hasModulesLoaded = true;
                    userService.get_EmailCounts();
                    p.resolve(r);
                })
                .catch(function (e) { console.error(e); p.reject(); })
                .finally(function () { userService.isLoadingMenu = false; });

            return p.promise;
        };

        userService.parseModuleNames = function ()
        {
            var names = {};

            for (var key in userService.system.modules)
            {
                if (userService.system.modules.hasOwnProperty(key))
                {
                    names[key] = userService.system.modules[key].name;
                }
            }

            userService.system.mNames = names;
        };

        userService.bfs = function (tree, childrenKey, idKey, result)
        {
            if (!tree[childrenKey] || tree[childrenKey].length === 0)
            {
                return;
            }

            for (var i = 0; i < tree[childrenKey].length; i++)
            {
                var child = tree[childrenKey][i];

                    if (child['typeId'] === 2 || child['typeId'] === 3)
                    {
                        result[child[idKey]] = child;
                        userService.system.modulesList.push(child);
                    }

                userService.bfs(child, childrenKey, idKey, result);
            }

            return;
        };

        userService.parseModulesList = function (modules)
        {
            var modulesList = {},
                modules = modules || [],
                dataTree = { 'children': modules };
            userService.bfs(dataTree, 'children', 'code', modulesList);

            return modulesList;
        };

        userService.getOperationsFor = function (code)
        {
            code = code || '_no_code';
            var operations = {};

            if (userService.system.modules[code])
            {
                if (userService.system.modules[code].operations)
                {
                    for (var i = 0; i < userService.system.modules[code].operations.length; i++)
                    {
                        operations[userService.system.modules[code].operations[i].name] = true;
                    }
                }
            }

            return operations;
        };

        userService.getWorkflow = function (moduleId) {            
            var p = $q.defer();
            $http[URI.MODULE.GET_WORKFLOW.method](`${URI.MODULE.GET_WORKFLOW}?entityInstanceId=${userService.system.userdata.contractEntityInstanceId}&moduleId=${moduleId}`.toString())
                .then(function (r) {
                    userService.system.oldWorkflowId = userService.system.workflowId;
                    userService.system.workflowId = r.data;
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                });

            return p.promise;
        }

        userService.getModuleIdentifierById = function (moduleId)
        {
            moduleId = moduleId || 0;
            var retval = null;

            for (var key in userService.system.modules)
            {
                if (userService.system.modules[key]['moduleId'] == moduleId)
                {
                    retval = userService.system.modules[key]['code'];
                }
            }

            return retval;
        };

        userService.getModuleObjectById = function (moduleId) {
            moduleId = moduleId || 0;
            var retval = null;

            for (var key in userService.system.modules) {
                if (userService.system.modules[key]['moduleId'] == moduleId) {
                    retval = userService.system.modules[key];
                }
            }

            return retval;
        };

        userService.getPermissions = function (code)
        {
            code = code || 'no_code';

            var permissions = {};

            if (userService.system.modules[code])
            {
                for (var i = 0; i <= userService.system.modules[code].operations; i++)
                {
                    permissions[userService.system.modules[code].operations[i].Name] = true;
                }
            }

            return permissions;
        };

        userService.currentTenant = function ()
        {
            var p = $q.defer();
            var sTenant = localStorageService.get('CurrentTenant');
            p.resolve((userService.tenantId !== null) ?
                userService.tenantId : (typeof sTenant !== 'undefined') ? sTenant : null);

            return p.promise;
        };

        userService.changeTenant = function (tenantId)
        {
            var p = $q.defer();

            // set tenant id
            userService.tenantId = tenantId;

            // set tenant in session
            localStorageService.set('CurrentTenant', tenantId);
            
            p.resolve();

            return p.promise;
        };

        userService.getTenantIdentifierById = function (tenantId)
        {
            tenantId = tenantId || -1;
            if (tenantId > 0)
            {
                var selectedTenant = userService.system.tenants
                    .filter(function (o)
                    {
                        return o.id === tenantId;
                    });
                return selectedTenant[0] ? selectedTenant[0].name.toString().toLowerCase() : null;
            } 
            
            return undefined;
        };

        userService.getTenantIdByIdentifier = function (tenantIdentifier)
        {
            if (tenantIdentifier !== undefined)
            {
                var selectedTenant = userService.system.tenants
                    .filter(function (o)
                    {
                        var name = o.name ? o.name.toString().toLowerCase() : '';
                        var identifier = tenantIdentifier ? tenantIdentifier.toString().toLowerCase() : null;
                        return name === identifier;
                    });

                return selectedTenant[0] ? selectedTenant[0].id : null;
            }

            return -1;
        };

        userService.getTenantStatus = function ()
        {
            if (userService.tenantId === -1)
                return userService.tenantId;

            var selectedTenant = userService.system.tenants
                .filter(function (o)
                {
                    return o.id === userService.tenantId;
                });

            return selectedTenant[0] ? selectedTenant[0].statusId : null;
        };

        userService.setTenant = function (tenantIdentifier)
        {
            var p = $q.defer();
            tenantIdentifier = tenantIdentifier || null;
            var tenantId = userService.getTenantIdByIdentifier(tenantIdentifier);

            // reset data on tenant change
            if (tenantId !== userService.tenantId)
            {
                userService.hasModulesLoaded = false;
                userService.hasContractsLoaded = false;
                userService.isUserLoaded = false;
            }

            // set tenant id
            userService.tenantId = tenantId;

            // set tenant in session
            localStorageService.set('CurrentTenant', tenantId);

            return p.promise;
        };

        userService.currentContract = function ()
        {
            var sContract = localStorageService.get('currentContract');

            return typeof sContract !== 'undefined' ? sContract : null;
        };

        //userService.currentContractName = function ()
        //{
        //    var sContract = localStorageService.get('currentContractName');

        //    return typeof sContract !== 'undefined' ? sContract : null;
        //};

        userService.clearCurrentContract = function ()
        {
            // localStorageService.set('currentContractName', null);
            localStorageService.set('currentContract', null);
        };

        userService.setContract = function (contractId)
        {
            var p = $q.defer();
            userService.getRolesForContract(contractId);
            if (contractId && contractId != userService.system.userdata.contractId) {
                $http.post(environment.baseApiUrl + '_api/Contracts/AddLastUsedContract?contractId=' + contractId)
                    .then(function (r) {
                        userService.system.userdata.contractId = contractId;
                        userService.isReloadGrid = true;
                        p.resolve(r);
                    })
                    .catch(function (e) { console.error(e); p.reject(); })
                    .finally(function () {
                        // userService.isLoadingMenu = false;
                    });
            }
            else {
                p.resolve();
            }
            userService.system.userdata.contractId = contractId;
            userService.system.userdata.contractEntityInstanceId = userService.system.contractsLookup[contractId].entityInstanceId;
            // userService.system.userdata.contractName = contractName;
            //if (contractName)
            //    localStorageService.set('currentContractName', contractName);
            localStorageService.set('currentContract', contractId);
            

            return p.promise;
        };

        // triggers
        userService.triggerRefresh = function ()
        {
            return userService.bootloadData();
        };

        userService.triggerMenuRefresh = function ()
        {
            return userService.Modules(userService.system.userdata.contractId);
        };

        userService.triggerPermissionsRefresh = function ()
        {
            var p = $q.defer();

            userService.PGrid().then(function (r)
            {
                userService.system.pGrid = r.permissions;
                userService.system.mNames = r.names;
                p.resolve();
            }).catch(function (e)
            {
                p.reject(e);
            });

            return p.promise;
        };

        userService.setRedirect = function (r)
        {
            localStorageService.set('cipoRedirectPage', r);
            return;
        };

        userService.getRedirectPage = function ()
        {
            var r = localStorageService.get('cipoRedirectPage');
            localStorageService.remove('cipoRedirectPage');

            return r;
        };

        return userService;
    }]);
