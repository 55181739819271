import { cipo } from 'cipo';

cipo.controller('manageIconsController',
    function ($scope, $q, $http, $mdDialog, MODAL, URI, Message, CIPO_ICONS, userService) {

        $scope.modalInfo = MODAL;
        $scope.disableBtns = false;
        var currentListIds = [];
        var icons = CIPO_ICONS();
        $scope.icons = userService.system.icons;
        $scope.loadIconsDict = function () {
            $scope.loadingIcons = true;
            $http[URI.ICONS.DICT.method](URI.ICONS.DICT.toString())
                .then(function (r) {
                    if (r && r.data.length) {
                        for (var i = 0; i < r.data.length; i++) {
                            icons.lookup[r.data[i].key].isFavorite = r.data[i].isFavorite;
                            icons.lookup[r.data[i].key].value = r.data[i].value;
                        }
                    }
                    $scope.icons = icons.list;
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                })
                .finally(function () {
                    $scope.loadingIcons = false;
                })

        }
        $scope.save = function () {
            var temp = [];
            for (var i = 0; i < $scope.icons.length; i++) {
                temp.push({ key: $scope.icons[i].key, value: $scope.icons[i].value, isFavorite: $scope.icons[i].isFavorite });
            }
            $http[URI.ICONS.SYNC.method](URI.ICONS.SYNC.toString(), temp)
                .then(function () {
                    Message.info("List updated successfully");
                    userService.Icons();
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                })

        }
            
        // $scope.loadIconsDict();
        $scope.cancel = function () {
            $mdDialog.cancel();
        };

    });
