import { cipo } from 'cipo';
import moment from 'moment';

cipo.controller('progresspaymentController',
    function ($transition$, $scope, $state, Model, authService, ACTIONS, userService, URI, ICONS, ProgressPaymentSA, $q, Manager, Message, $mdDialog, FileSaver, Blob, Upload, $timeout, $window, Dictionaries) {
        const params = $transition$.params()
        $scope.module = userService.system.modules[$state.current.code];
        $scope.module.baseName = $scope.module.name;

        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor("PP"); }
        });
        /*Object.defineProperty($scope, 'ppOperations', {
            get: function () { return userService.getOperationsFor("PP"); }
        });*/
        $scope.currencySymbol = userService.getCurrency();

        Object.defineProperty($scope, 'userService', {
            get: function () {
                return userService;
            }
        });

        var moduleTypes;

        Dictionaries.ModuleTypes(-3)
            .then(function (r) {
                moduleTypes = r;
            })
            .catch(function () {})

        $scope.isPaymentRequestAllowed = function (selectedPeriodRequired) {
            if (selectedPeriodRequired && !$scope.selectedPeriod)
                return false;
            return !$scope.selectedPeriod ||
                $scope.selectedPeriod.key == $scope.currentPeriod.id ||
                ($scope.currentPeriod.allowedPastPeriodIDs || []).indexOf($scope.selectedPeriod.key) != -1
                ;
        }

            $scope.$on("$mdMenuClose", function () {
                try {
                    $scope.pp.assignments.searchUser = '';
                } catch { }
            });

            $scope.showPercentageEditable = function () {
                if ($scope.pp.generalInfo.approvalId) return false;
                if ($scope.pp.generalInfo.isDraftState && $scope.pp.isEditablePercentage) return true;
                if ($scope.pp.editMode && $scope.pp.isEditablePercentage) return true;
                return false;
            }

            $scope.showAmountEditable = function () {
                if ($scope.pp.generalInfo.approvalId) return false;
                if ($scope.pp.generalInfo.isDraftState && $scope.pp.isEditableAmount) return true;
                if ($scope.pp.editMode && $scope.pp.isEditableAmount) return true;
                return false;
            }

            $scope.showQuantityEditable = function () {
                if ($scope.pp.generalInfo.approvalId) return false;
                if ($scope.pp.generalInfo.isDraftState && $scope.pp.isEditableQuantity) return true;
                if ($scope.pp.editMode && $scope.pp.isEditableQuantity) return true;
                return false;
            }

            $scope.showEditableInput = function (item, col, module) {
                if (col.isEditableAmount && $scope.showAmountEditable() && !item.readonly && !module.isBlocked) return true;
                if (col.isEditablePercentage && $scope.showPercentageEditable() && !item.readonly && !module.isBlocked) return true;
                if (col.isEditableQuantity && $scope.showQuantityEditable() && !item.readonly && !module.isBlocked) return true;
                return false;
            }

        $scope.approve = function (isApprove) {
            $scope.pp.approve(isApprove)
                .then(function (r) {
                    if (!$scope.selectedPeriod.isMultiplePeriod && isApprove) {
                        $scope.selectedPeriod.hasMultiplePayments = false;
                    };
                    $scope.get_periods();
                })
                .catch(function () { })
        }

        $scope.loadPPDetails = function (pp, period) {
            $scope.errors = "";
            checkInitiator();
            if (period) {
                if (period.key != $scope.selectedPeriod.key) {
                    $scope.selectedPeriod = period;
                    if (!period.hasMultiplePayments) {
                        $scope.pp = new ProgressPaymentSA($scope.module, 0, moduleTypes, $scope.currentPeriod.id, $scope.selectedPeriod.key);
                    }
                    else {
                        $scope.pp = null;
                        $scope.get_ppList(period.key);
                    }
                }
            } else {
                $scope.pp = new ProgressPaymentSA($scope.module, pp.id, moduleTypes, $scope.currentPeriod.id, $scope.selectedPeriod.key);
            }
        }

        $scope.movePP = function (pp, period, event) {
            if (event) event.stopPropagation(); 
            var dataURL = URI.PROGRESS_PAYMENT.PP_MOVE;
            var params = {
                entityInstanceId: pp.entityInstanceId,
                periodId: period.key,
                contractId: userService.system.userdata.contractId || 0
            };

            Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
                .then(function (r) {
                    if (r) Message.warning(r);

                    $scope.get_periods()
                        .then(function() {
                            $scope.loadPPDetails(null, period);
                        })
                })
                .catch(function (e) {
                    Message.dberror(e);
                })
        }

        $scope.userIsInitiator = false;
        var checkInitiator = function () {
            var dataURL = URI.PROGRESS_PAYMENT.PP_IS_USER_INITIATOR;
            var params = {
                contractId: userService.system.userdata.contractId || 0
            };

            Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
                .then(function (r) {
                    $scope.userIsInitiator = r;
                })
                .catch(function (e) {
                    Message.dberror(e);
                })
        }
        checkInitiator();       

        $scope.get_ppList = function (periodId) {
            $scope.loadingList = true;
            $scope.pp = null;
            checkInitiator();
            var p = $q.defer();
            var period = periodId || ($scope.currentPeriod || {}).id;
            var dataURL = URI.PROGRESS_PAYMENT.PP_MULTIPLE_SEARCH;
            var params = {
                contractId: userService.system.userdata.contractId,
                paymentPeriodId: period || 0
            };
            $scope.hasDraft = false;
            Model[dataURL.method](dataURL, { url: params, urltype: 'obj' })
                .then(function (r) {
                    for (var i = 0; i < r.data.length; i++) {
                        r.data[i].createdOn = r.data[i].createdOn ? moment(r.data[i].createdOn).format(userService.formats.date) : "";
                        r.data[i].updatedOn = r.data[i].updatedOn ? moment(r.data[i].updatedOn).format(userService.formats.date) : "";
                        if (r.data[i].isDraft && (!r.data[i].is_approval || r.data[i].isOwner) || (r.data[i].isRevision && !($scope.selectedPeriod || $scope.currentPeriod).isMultiplePeriod)) {
                            $scope.hasDraft = true;
                        }
                    }
                    $scope.ppList = r.data;

                    p.resolve(r);
                })
                .catch(function (e) {
                    Message.dberror(e);
                    p.reject(e);
                })
                .finally(function () {
                    $scope.loadingList = false;
                    // self.isBusy = false;
                })

            return p.promise;
        };
        var isPeriodsLoaded = false;
        $scope.multiPPCols = [
            { name: 'paidAmount', label: "Paid amount"},
            { name: 'totalAmount', label: "Total amount" },
            { name: 'status', label: "Status", isStatus: true },
            { name: 'updatedOn', label: "Updated on"},
            { name: 'updatedBy', label: "Updated by" },
            { name: 'createdOn', label: "Created on" },
            { name: 'createdBy', label: "Created by"}
        ];
        $scope.get_currentPeriod = function () {
            
            var p = $q.defer();
            $scope.errors = "";
            $scope.isCurrentPeriodLoaded = false;
            var dataURL = URI.PROGRESS_PAYMENT.PPD_CURRENT;
            $scope.isBusyPeriods = true;
            Model[dataURL.method](dataURL, { url: { contractId: userService.system.userdata.contractId }, urltype: 'obj' })
                .then(function (r) {
                    r.startDate = r.startDate ? moment(r.startDate).format(userService.formats.date) : "";
                    r.endDate = r.startDate ? moment(r.endDate).format(userService.formats.date) : "";
                    $scope.currentPeriod = r;
                    
                    p.resolve();
                })
                .catch(function (e) {
                    $scope.errors = e.data.messages;
                    console.error(e);
                    // Message.dberror(e);
                    p.reject(e);
                })
                .finally(function () {
                    $scope.isCurrentPeriodLoaded = true;
                    if (!isPeriodsLoaded) $scope.get_periods();
                    else {

                        $scope.selectedPeriod = periodsLookup[$scope.currentPeriod.id];
                    }
                })

            return p.promise;
        }
        var periodsLookup = {};

        var createLookup = function (data) {
            var ret = {};
            if (data && data.length)
                for (var i = 0; i < data.length; i++)
                    ret[data[i].key] = data[i];
            return ret;
        }


        $scope.get_periods = function (isFromWatch) {
            
            var p = $q.defer();
            $scope.isBusyPeriods = true;
            var retainLoadedPP = false;
            Dictionaries.PPPeriods({ contractId: userService.system.userdata.contractId })
                .then(function (r) {
                    if (r && r.length) {
                        if (!$scope.currentPeriod) {
                            $scope.currentPeriod = { id: 0, key: 0, name: 'Current Period' };
                            $scope.selectedPeriod = $scope.currentPeriod;
                        }
                        for (var i = 0; i < r.length; i++) {
                            r[i].startDate = r[i].startDate ? moment(r[i].startDate).format(userService.formats.date) : "";
                            r[i].endDate = r[i].startDate ? moment(r[i].endDate).format(userService.formats.date) : "";
                            if (r[i].key == $scope.currentPeriod.id) {
                                r[i].current = true;
                                
                                for (var key in r[i])
                                    if (r[i].hasOwnProperty(key))
                                        $scope.currentPeriod[key] = r[i][key];
                                if(!$scope.selectedPeriod) $scope.selectedPeriod = r[i];
                            }
                            if (r[i].key == ($scope.selectedPeriod || {}).key) {
                                retainLoadedPP = true;
                                for (var key in r[i])
                                    if (r[i].hasOwnProperty(key))
                                        $scope.selectedPeriod[key] = r[i][key];
                            }
                        }
                        if (!$scope.currentPeriod.id) r.splice(0, 0, { key: 0, value: 'No Current Period', current: true });
                        $scope.periodsDict = r;
                        // self.currentPeriod.value = self.periodsDict[0].value;
                    } else $scope.periodsDict = [];
                    if (!retainLoadedPP && isFromWatch) {
                        $scope.loadPP(false, true);
                    }
                    periodsLookup = createLookup(r);
                    p.resolve();
                    isPeriodsLoaded = true;
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                    p.reject(e);
                })
                .finally(function () {
                    $scope.isBusyPeriods = false;
                })

            return p.promise;
        }

        $scope.createPP = function () {
            if (!$scope.pp) $scope.pp = new ProgressPaymentSA($scope.module, 0, moduleTypes, $scope.currentPeriod.id, ($scope.selectedPeriod || {}).key, true);
            $scope.pp.create()
                .then(function (r) {
                    $scope.get_periods();
                })
                .catch(function (e) {})
        }
        
        $scope.loadPP = function (keepPeriods, isFromWatch) {
            $scope.pp = null;
            if (!keepPeriods) {
                $scope.currentPeriod = null;
                $scope.selectedPeriod = null;
                if(!isFromWatch) $scope.periodsDict = [];
                $scope.ppList = [];
                $scope.errors = "";
                $scope.get_currentPeriod(isFromWatch)
                    .then(function (r) {
                        if (!$scope.currentPeriod.hasMultiplePayments) {
                            $scope.pp = new ProgressPaymentSA($scope.module, 0, moduleTypes, $scope.currentPeriod.id);
                        }
                        else {
                            $scope.get_ppList();
                        }
                    })
                    .catch(function () { })
            } else {
                //
                $scope.get_periods();
                if (!$scope.selectedPeriod.hasMultiplePayments) {
                    $scope.pp = new ProgressPaymentSA($scope.module, 0, moduleTypes, $scope.currentPeriod.id, $scope.selectedPeriod.key);
                }
                else {
                    $scope.get_ppList($scope.selectedPeriod.key);
                }
            }
        }

        $scope.delete = function () {
            $scope.pp.delete()
                .then(function () {
                    $scope.loadPP(true);
                })
                .catch(function () {

                })
        }

        $scope.deleteFromList = function (id) {
            $scope.loadingList = true;
            var dataURL = URI.PROGRESS_PAYMENT.PP_DELETE;
            Model[dataURL.method](dataURL, { url: { contractId: userService.system.userdata.contractId, entityInstanceId: id }, urltype: 'obj' })
                .then(function () {
                    $scope.get_ppList($scope.selectedPeriod.key);
                })
                .catch(function () {
                    Message.dberror(e);
                    $scope.loadingList = false;
                })
        }

        $scope.Math = Math;
        $scope.$watch(function () {
            return userService.isLoadingMenu;
        },
            function (n, o) {
                //console.error('userService.isLoadingMenu', n, o);
                if (!n && typeof userService.getOperationsFor("PP").Read != 'undefined') {
                    isPeriodsLoaded = false;
                    $scope.loadPP(false, true);
                } else if (!n) {
                    //console.error('should redirect');
                    $state.go("tenant.404", { tenantIdentifier: params.tenantIdentifier });

                }
            });

        $scope.$watch(function () {
            return userService.isCurrentContractPeriodsChanged;
        },
            function (n, o) {
                //console.error('userService.isLoadingMenu', n, o);
                if (n) {
                    $scope.get_periods(true);
                    userService.isCurrentContractPeriodsChanged = false;

                }
            });

        $scope.startTransition = function (t, role) {
            $scope.pp.isBusy = true;
            if (parseInt(t.stateTypeId) !== 0 && !$scope.pp.generalInfo.isDraftState && !t.isRevise) {
                var getFields_param = t.isComment ? { actionId: t.id } : { transitionId: t.id };
                $scope.pp.get_fields(getFields_param)
                    .then(function (r) {
                        if (r && !r.fields.length && !r.emailsDataSource.length && !$scope.pp.rolesDict.length) {
                            $scope.pp.save(t.id, role.key)
                                .then(function () {
                                    // var period = $scope.pp.period || null;
                                    $scope.pp.get_general_info();
                                    $scope.get_periods();
                                })
                                .catch(function () { });
                        }
                        else {
                            $scope.pp.isBusy = false;
                            r.rolesDict = angular.copy($scope.pp.rolesDict);
                            r.saveParams = {
                                // paymentPeriodId: $scope.selectedPeriod.key,
                                contractId: $scope.pp.contractId,
                                roleId: role.key,
                                entityInstanceId: $scope.pp.entityInstanceId,
                                paymentPeriodId: $scope.pp.period ? $scope.pp.period.key : ($scope.pp.selectedPeriodId || 0),
                                moduleTypeId: ($scope.pp.currentType || {}).key || $scope.pp.types[0].key
                            };
                            showTransitionScreen(r, t);
                        }
                    })
                    .catch(function () { });
            } else {
                $scope.pp.save(t.id, role.key, t.isRevise)
                    .then(function () {
                        // $scope.pp.isBusy = true;
                        // var period = $scope.pp.period || null;
                        $scope.pp.get_general_info();
                        $scope.get_periods();
                    })
                    .catch(function () { });
            }

            

        };
        var showTransitionScreen = function (r, t) {
            var dataURL, entityInstanceId, ppPeriodId;
            dataURL = URI.PROGRESS_PAYMENT.PP_SAVE;
            if (t.isComment) dataURL = URI.MODULE.ADD_COMMENT;
            entityInstanceId = $scope.pp.entityInstanceId;
            
            
            $mdDialog.show({
                locals: {
                    info: r,
                    transition: t,
                    entityInstanceId: entityInstanceId,
                    dataURL: dataURL,
                    contractId: userService.system.userdata.contractId,
                    isPayment: true,
                    selectedRoleId: r.saveParams.roleId
                },
                controller: 'editTransitionScreenController',
                templateUrl: '/ng/views/admin/modals/editTransitionScreen.html',
                parent: angular.element(document.body),
                //targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (r) {
                    $scope.pp.entityInstanceId = r;
                    // var period = $scope.pp.period || null;
                    $scope.pp.get_general_info();
                    $scope.get_periods();

                }, function () {

                });

        }

    });
