import { cipo } from 'cipo';

    cipo.controller('importEntitiesController',
        function ($scope, $timeout, Model, moduleId, moduleCode, importOptions, URI, Message, $mdDialog, ModuleDictionaries, userService, Upload, fileService) {

            var isModified = false;
            var isImporting = false;
            var regexAutoMap = /([a-zA-Z0-9\s\-\_]+)/;
            var importOutput;
            var importError;

            // In case of SOV, we don't need to display screen because will be saved always in draft
            $scope.isSOV = moduleId == -4 || moduleId == - 5;

            $scope.loadingData = false;

            importOptions = importOptions || {};
            importOptions.fieldsForImportDatasource = importOptions.fieldsForImportDatasource || 'fieldsForImport';
            importOptions.moduleIdForStates = importOptions.moduleIdForStates || moduleId;
            importOptions.importUrlData = importOptions.importUrlData || URI.MODULE.IMPORT_INSTANCES;
            importOptions.closeOnSuccess = importOptions.closeOnSuccess !== undefined ? importOptions.closeOnSuccess : false;
            importOptions.closeOnError = importOptions.closeOnError !== undefined ? importOptions.closeOnError : false;

            $scope.importModel = {
                moduleId: moduleId,
                contractId: userService.system.userdata.contractId,
                entityInstanceId: importOptions.entityInstanceId,
                stateId: null,
                separator: ",",
                mappings: [],
                fileId: null,
                screenId: null
            }

        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor(moduleCode) }
        });

            if (!$scope.isSOV) {
                // Initialize with importOptions.moduleIdForStates for states, this is returned first
                ModuleDictionaries.init({ moduleId: importOptions.moduleIdForStates });

                ModuleDictionaries.get_dataSource("states")
                    .then(function () {
                        if (ModuleDictionaries) $scope.moduleStates = ModuleDictionaries.dataSources.states.data;
                    })
            }

            // Initialize with moduleId, other queries needs module id, only states needs other module id, This is for SOV and SOVI, states we need to SOV, and fields from SOVI
            ModuleDictionaries.init({ moduleId: moduleId });

            ModuleDictionaries.get_dataSource("startScreens")
                .then(function () {
                    if (ModuleDictionaries)
                        $scope.screens = ModuleDictionaries.dataSources.startScreens.data;

                    if ($scope.screens.length == 1) {
                        $scope.importModel.screenId = $scope.screens[0].key;

                        // Get fields for this screen
                        $scope.getFields($scope.importModel.screenId);
                    }
                })

        $scope.fileUploaded;
        $scope.file = null;
        $scope.fileColumns = [];

            $scope.getFields = function (screenId) {

                $scope.loadingData = true;

                ModuleDictionaries.init({ moduleId: moduleId, screenId: screenId });

                ModuleDictionaries.get_dataSource(importOptions.fieldsForImportDatasource)
                    .then(function () {
                        if (ModuleDictionaries) {
                            $scope.fieldsForImport = ModuleDictionaries.dataSources[importOptions.fieldsForImportDatasource].data;

                            $scope.fieldsForImport.forEach(function (field) {
                                AutoMapFieldForImport(field);
                            });
                        }
                    })
                    .finally(function () {
                        $scope.loadingData = false;
                    })
            }

            $scope.upload = function (file) {
                var urlData = URI.MODULE.EXCEL_COLUMNS;

                if (file) {
                    var file = file[0];
                    $scope.file = file;
                    $scope.file.isUploading = true;

                fileService.setMimeTypeFromExt(file);

                file.message = "Uploading file...";

                var upload = Upload.upload({
                    url: urlData.toString(),
                    method: urlData.method.toString(),
                    data: { file: file }
                });

                file.progressPercentage = 10;

                $timeout(function () {
                    if (file.progressPercentage != 100) file.progressPercentage = 40;
                }, 1000);

                $timeout(function () {
                    if (file.progressPercentage != 100) file.progressPercentage = 80;
                }, 2000);

                upload.then(function (response) {
                    $timeout(function () {
                        $scope.fileColumns = [];
                        file.message = "File uploaded successfully.";
                        if ((response.data.columns || []).length) {
                            for (var i = 0; i < response.data.columns.length; i++) {
                                $scope.fileColumns.push({ value: response.data.columns[i], key: i });
                            }
                        }

                        $scope.importModel.fileId = response.data.fileId;
                        file.progressPercentage = 100;
                        file.isUploading = false;

                        // automap fields for imput
                        if ($scope.screens.length == 1) {
                            $scope.fieldsForImport.forEach(function (field) {
                                AutoMapFieldForImport(field);
                            });
                        }
                    });
                }, function (response) {
                    Message.dberror(response);
                    $scope.fileUploaded++;
                    if (response.status > 0) {
                        file.message = "An error has occured. The file couldn't be uploaded.";
                        file.error = true;
                    }
                    file.isUploading = false;
                });
            }
        }

        Object.defineProperty($scope, 'disabledActions', {
            get: function () {
                return ($scope.file || {}).isUploading || isImporting;
            }
        })

        Object.defineProperty($scope, 'isMappingErrors', {
            get: function () {
                var ret = false;
                for (var i = 0; i < ($scope.fieldsForImport || []).length; i++) {
                    if ($scope.fieldsForImport[i].hasError) {
                        ret = true;
                        break;
                    }
                }
                return ret;
            }
        })

        $scope.clearMappingErrors = function () {
            if (!$scope.importModel.stateId) {
                for (var i = 0; i < ($scope.fieldsForImport || []).length; i++) {
                    $scope.fieldsForImport[i].hasError = false;
                }
            }
        }

        $scope.importDocuments = function () {

            var urlData = importOptions.importUrlData;
            // validate
            var isValid = true;
            importOutput = undefined;
            importError = undefined;

            $scope.importModel.mappings = [];

            // In case of SOV, we don't need a stateId, but we will need to validate mappings
            if ($scope.isSOV || $scope.importModel.stateId) {
                for (var i = 0; i < $scope.fieldsForImport.length; i++) {
                    if ($scope.fieldsForImport[i].isRequired && (typeof $scope.fieldsForImport[i].mapping == 'undefined' || $scope.fieldsForImport[i].mapping === '')) {
                        $scope.fieldsForImport[i].hasError = true;
                        isValid = false;
                    }
                }
            }

                if (isValid) {
                    isImporting = true;
                    for (var i = 0; i < $scope.fieldsForImport.length; i++) {
                        // Check if mapping is not undefined, position 0 can be sent and is a valid value for first column
                        if (typeof $scope.fieldsForImport[i].mapping != 'undefined' && $scope.fieldsForImport[i].mapping !== '') {
                            // Get column
                            var column = $scope.fileColumns.find(c => c.key == $scope.fieldsForImport[i].mapping);
                            // Check if we have column, it should exists, just a fallback from the if
                            if (column)
                                $scope.importModel.mappings.push({ key: $scope.fieldsForImport[i].key, value: column.value })
                        }
                    }

                    Model[urlData.method](urlData, { url: { contractId: userService.system.userdata.contractId }, urltype: 'obj', body: $scope.importModel })
                        .then(function (r) {
                            importOutput = r;
                            Message.info('Documents imported successfully');
                            if (importOptions.closeOnSuccess) {
                                $scope.closeModal();
                            }
                        })
                        .catch(function (e) {
                            importError = e;
                            Message.dberror(e);
                            if (importOptions.closeOnError) {
                                $scope.closeModal();
                            }
                        })
                        .finally(function () { isImporting = false; isModified = true; })
                }
                else {
                    Message.warning("[Document Headers Mapping] Please map all the required fields.");
                    return;
                };
            }

            $scope.closeModal = function () {
                ModuleDictionaries = null;
                var output = { importOutput: importOutput, importError: importError };
                if (isModified) $mdDialog.hide(output);
                else $mdDialog.cancel(output);
            };

            function AutoMapFieldForImport(fieldForImport) {
                // first, we try to match an existing value
                var field = $scope.fileColumns.find(c => c.value.toLowerCase() == fieldForImport.value.toLowerCase());
                if (field) {
                    fieldForImport.mapping = field.key;
                    return;
                }

                // second, we try to match a regex filtered value
                var rv = AutoMapFieldForImportMatch(fieldForImport.value);
                $scope.fileColumns.every(c => {
                    var rc = AutoMapFieldForImportMatch(c.value);
                    if (rv == rc) {
                        fieldForImport.mapping = c.key;
                        // exit
                        return false;
                    }
                    return true;
                });
            }

        function AutoMapFieldForImportMatch(value) {
            var m = value.match(regexAutoMap);
            return m.length ? m[0] : null;
        }
    });
