import { cipo } from 'cipo';

cipo.factory("CommonEntityInstance", function (Model, $q, Message, $mdDialog, URI) {
    var confirm = function (title, text, isMultiple) {
        return $mdDialog.confirm({
            title: title,
            textContent: text,
            ariaLabel: 'Confirm Dialogue',
            ok: 'Proceed',
            cancel: 'Cancel',
            multiple: isMultiple || false
        });
    }

    var CommonEntityInstance = Model.extend(function () { });

    CommonEntityInstance.prototype.commonUndoLastAction = function (transition, moduleCode, contractId, moduleId) {
        var self = this;
        var p = $q.defer();
        $mdDialog.show(confirm('Undo last action', transition.rollbackToDraft
            ? 'All links to other documents will be severed and need to be reestablished. It is advisable to start a new revision of the document. Are you sure?'
            : `You are about to undo the action "${transition.transitionName}", performed by ${transition.user}. Any comments, attachments or signatures will be permanently deleted and cannot be restored. Are you sure?`, true))
            .then(function () {
                var dataURL = typeof URI[moduleCode.toUpperCase()] != "undefined" ? URI[moduleCode.toUpperCase()].ROLLBACK : URI.MODULE.ROLLBACK;
                var params = {
                    url: {
                        actionInstanceId: transition.actionInstanceId,
                        contractId: contractId
                    },
                    urltype: "obj"
                };
                self[dataURL.method](dataURL, params, { headers: { moduleId: moduleId } })
                    .then(function () {
                        Message.info('The last action was undone successfully.');
                        p.resolve();
                    })
                    .catch(function (e) {
                        console.error(e);
                        Message.dberror(e);
                        p.reject(e);
                    });
            });
        return p.promise;
    }

    return new CommonEntityInstance;
});
