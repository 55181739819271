import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { KeyValueRestrictionModel, RestrictionType, RestrictionValueType } from '../../models/module/fields/enums';
import { UserService } from '../services/user.service';

@Pipe({ name: 'itvDateTimeWithRestrictions' })
export class ItvDateTimeWithRestrictionsPipe implements PipeTransform {
  constructor(private userService: UserService, private dateCustomPipe: ItvDateTimeCustomFormatPipe) {}

  transform(value: string, restrictions: KeyValueRestrictionModel[] = [], defaultValue: string = ''): string {
    const datetimeFormatting = (restrictions || []).find(f => f.key == RestrictionType.DateTime);
    if (!datetimeFormatting) {
      return defaultValue;
    }

    let format = undefined;
    switch (datetimeFormatting.value) {
      case RestrictionValueType.Date:
        format = this.userService.formats.date;
        break;
      case RestrictionValueType.DateTime:
        format = this.userService.formats.datetime;
        break;
      case RestrictionValueType.Time:
        format = this.userService.formats.time;
        break;
      default:
        return defaultValue;
    }

    return this.dateCustomPipe.transform(value, format, defaultValue);
  }
}

@Pipe({ name: 'itvDateTimeCustomFormat' })
export class ItvDateTimeCustomFormatPipe implements PipeTransform {
  transform(value: string, format: string, defaultValue: string = ''): string {
    if (!value) {
      return defaultValue;
    }

    const hasTimezone = value.indexOf('Z') != -1;
    const valueWithTimezone = value + (hasTimezone ? '' : 'Z');

    return moment(valueWithTimezone).utc().format(format);
  }
}

@Pipe({ name: 'itvDate' })
export class ItvDatePipe implements PipeTransform {
  constructor(private userService: UserService, private dateCustomPipe: ItvDateTimeCustomFormatPipe) {}

  transform(value: string, defaultValue: string = ''): string {
    return this.dateCustomPipe.transform(value, this.userService.formats.date, defaultValue);
  }
}

@Pipe({ name: 'itvTime' })
export class ItvTimePipe implements PipeTransform {
  constructor(private userService: UserService, private dateCustomPipe: ItvDateTimeCustomFormatPipe) {}

  transform(value: string, defaultValue: string = ''): string {
    return this.dateCustomPipe.transform(value, this.userService.formats.time, defaultValue);
  }
}

@Pipe({ name: 'itvDateTime' })
export class ItvDateTimePipe implements PipeTransform {
  constructor(private userService: UserService, private dateCustomPipe: ItvDateTimeCustomFormatPipe) {}

  transform(value: string, defaultValue: string = ''): string {
    return this.dateCustomPipe.transform(value, this.userService.formats.datetime, defaultValue);
  }
}
