import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("Explorer1", function (Model, Menu, $q, $http, ACTIONS, Manager, Message, $mdDialog, userService, URI, GenericClass, FileSaver, $state) {

    const RECYCLE_BIN_NODE_ID = -10;
    const ARCHIVE_NODE_ID = -15;
    const ARCHIVE_FILE_NODE_ID = -20;

    //constructor extending Model
    var Explorer1 = Model.extend(function (obj) {
        var self = this;

        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                self[key] = obj[key];
            }
        }
        self.additionalURLParams = self.urlsData.tree.urlParams;
        self.additionalItemParams = self.urlsData.itemParams;

        Object.defineProperty(self, 'operations', {
            get: function () { return userService.getOperationsFor(self.abbreviation); }
        });

        self.expandedFolders = [];
        // self.folderTree = [];
        var root = { id: -1, folders: [], isLoadingChildren: true };
        self.lookupFolders = {};
        self.lookupFolders[root.id] = root;

        Object.defineProperty(self, 'folderTree', {
            get: function () {
                return self.lookupFolders[-1].folders;
            }
        })

        self.nodeType = 0;
        self.selectedFolder = {};
        self.lookupThumb = {};
        self.lookupManager = {};
        self.pdfMarkupMimeTypes = [];

            self.resizerOptions = {
                resizerTop: 10,
                resizerBottom: 10,
                resizerLeft: "#tree",
                resizerRight: ["#content", "#actionBar"],

            };

        // self.init();
    });

    Explorer1.prototype.functions = {
        setClass: function (mimetype) {
            return mimetype.replace(/\//g, '-').replace(/\./g, '-');
        }
    }

    Explorer1.prototype.setParams = function (params) {
        var self = this;
        var params = params || {};
        if (self.urlsData.tree.urlParams)
            for (var key in self.urlsData.tree.urlParams) {
                if (self.urlsData.tree.urlParams.hasOwnProperty(key)) {
                    params[key] = self.urlsData.tree.urlParams[key];
                }
            }

        // if (!params.currentFolderOnly) params.currentFolderOnly = (self.manager || {}).isGlobalSearch;

        return params;
    }

    Explorer1.prototype.init = function () {
        var self = this;
        var p = $q.defer();
        self.breadcrumb = [];
        self.selectedFolder = self.lookupFolders[-1];
        
        self.getPdfMarkupMimeTypes()
            .then(function (res) {
                self.pdfMarkupMimeTypes = res;
                var searchObject = (self.manager || {}).searchObject ? angular.copy(self.manager.searchObject) : null;
                self.loadManager();
                self.manager.searchObject = searchObject;
                if (self.destinationFolderFromSearch) {
                    // self.loading = true;
                    self.getChildren(self.selectedFolder, true)
                        .then(function () {
                            self.getTreeParents(self.destinationFolderFromSearch);
                            self.destinationFolderFromSearch = null;
                        }).finally(function () {
                            // self.loading = false;
                        })


                }
                else if (((self.manager || {}).searchObject || {}).isSearchView && self.isChangeContract) {
                    self.getChildren(self.selectedFolder, true)
                        .then(function () {
                            self.isChangeContract = false;
                            self.restoreSearch();
                        }).finally(function () {
                            // self.loading = false;
                        })
                }
                else {

                    self.getFolderContent();
                }
        });

        return p.promise;
    }

    Explorer1.prototype.getPdfMarkupMimeTypes = function () {
        var self = this;
        var p = $q.defer();
        if (!self.pdfMarkupMimeTypes.length)
            self[URI.PDF_MARKUP.GET_MIME_TYPES.method](URI.PDF_MARKUP.GET_MIME_TYPES)
                .then(function (res) {
                    p.resolve(res);
                })
                .catch(function (err) {
                    p.reject();
                });
        else p.resolve(self.pdfMarkupMimeTypes);

        return p.promise;
    }

    Explorer1.prototype.isInExpandedFolders = function (id) {
        var folderInExpanded = false;
        for (var i = 0; i < (this.expandedFolders || []).length; i++) {
            if (this.expandedFolders[i].id == id) {
                folderInExpanded = this.expandedFolders[i];
                break;
            }
        }
        return folderInExpanded;
    }

    Explorer1.prototype.parseManagerData = function (data) {

        var setClass = function (mimetype) {
            return (mimetype || '').replace(/\//g, '-').replace(/\./g, '-');
        }

        var self = this;
        var data = data || [], retval = [];

        if (self.manager.nodeType == ARCHIVE_NODE_ID) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].createdOn) {
                    data[i].createdOn = moment(data[i].createdOn).format(userService.formats.date);
                }
                retval.push(data[i]);
            }
        }
        else if (self.manager.nodeType == ARCHIVE_FILE_NODE_ID) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].createdOn) {
                    data[i].createdOn = moment(data[i].createdOn).format(userService.formats.date);
                }
                if (data[i].fileSize) {
                    if (data[i].fileSize < 1000000) data[i].fileSize = '<div class="text-right">' + (data[i].fileSize / 1000).toFixed(2) + 'KB</div>';
                    if (data[i].fileSize >= 1000000 && data[i].fileSize < 1000000000) data[i].fileSize = '<div class="text-right">' + (data[i].fileSize / 1000000).toFixed(2) + 'MB</div>';
                    if (data[i].fileSize >= 1000000000) data[i].fileSize = '<div class="text-right">' + (data[i].fileSize / 1000000000).toFixed(2) + 'GB</div>';
                }
                if (data[i].fileSize == 0) data[i].fileSize = '<div class="text-right"> Failed </div>';
                data[i]['contractId'] = self.manager.contractId;
                retval.push(data[i]);
            }
        }
        else {
            for (var i = 0; i < data.length; i++) {
                var sharedClass = data[i].id == -2 ? "sharedFolder" : "";
                var folderIcon = data[i].isLocked && data[i].isFolder ? " mdi-folder-marker" : "";
                if (data[i].createdOn) data[i].createdOn = moment(data[i].createdOn).format(userService.formats.date);
                if (data[i].updatedOn) data[i].updatedOn = moment(data[i].updatedOn).format(userService.formats.date);
                data[i]._name = data[i].name;
                data[i]._big_name = data[i].name;

                if (data[i].parents && typeof data[i].parents == "string") {
                    data[i].parents = data[i].parents.split(',').map(Number);
                    data[i].parents.pop();
                }

                if (data[i].contractName && data[i].contractNo) data[i].contractName = data[i].contractNo + " - " + data[i].contractName;

                if (data[i].isFolder) {

                    // data[i].canDelete = false;

                    data[i]._name = '<span class="fileInfo folderIcon ' + sharedClass + folderIcon + '">'
                        + (data[i].setOnContract == false ? '<i class="mdi mdi-earth fmSymbol successText"></i>' : "")
                        + data[i].name + '</span>';
                    data[i]._big_name = '<span class="fileInfo folderIcon ' + sharedClass + folderIcon + ' ellipsedText">'
                        + (data[i].setOnContract == false ? '<i class="mdi mdi-earth fmSymbol successText"></i>' : "")
                        + data[i].name + '</span>';
                    data[i].length = '-';
                }
                else {
                    if (data[i].thumbnail50)
                        data[i]._name = '<img class="tableSmallTmb" src="data:'
                            + data[i].mimeType + ';base64,' + data[i].thumbnail50 + '" />'
                            + (data[i].setOnContract == false ? '<i class="mdi mdi-earth fmSymbol successText"></i>' : "")
                            + (data[i].isShared ? '<i class="mdi mdi-account-check fmSymbol successText"></i>' : "")
                            + data[i].name;
                    else data[i]._name = '<span class="fileInfo '
                        + setClass(data[i].mimeType) + '">'
                        + (data[i].setOnContract == false ? '<i class="mdi mdi-earth fmSymbol successText"></i>' : "")
                        + (data[i].isShared ? '<i class="mdi mdi-account-check fmSymbol successText"></i>' : "")
                        + data[i].name + '</span>';

                    if (data[i].thumbnail200)
                        data[i]._big_name = '<span class="thumbWrapper"><img src="data:'
                            + data[i].mimeType + ';base64,'
                            + data[i].thumbnail200 + '" />'
                            + (data[i].setOnContract == false ? '<i class="mdi mdi-earth fmSymbol successText"></i>' : "")
                            + (data[i].isShared ? '<i class="mdi mdi-account-check fmSymbol successText"></i>' : "")
                            + '</span><span class="ellipsedText fileInfo '
                            + setClass(data[i].mimeType) + '">' + data[i].name + '</span>';
                    else data[i]._big_name = '<span class="thumbWrapper bigIcon fileInfo '
                        + setClass(data[i].mimeType) + '">'
                        + (data[i].setOnContract == false ? '<i class="mdi mdi-earth fmSymbol successText"></i>' : "")
                        + (data[i].isShared ? '<i class="mdi mdi-account-check fmSymbol successText"></i>' : "")
                        + '</span><span class="ellipsedText fileInfo '
                        + setClass(data[i].mimeType) + '">' + data[i].name + '</span>';
                }

                if (!data[i].isFolder && data[i].length) {
                    if (data[i].length < 1000000) data[i].length = '<div class="text-right">' + (data[i].length / 1000).toFixed(2) + 'KB</div>';
                    if (data[i].length >= 1000000) data[i].length = '<div class="text-right">' + (data[i].length / 1000000).toFixed(2) + 'MB</div>';
                } else {
                    /// data[i].length = '<div class="text-center">-</div>';
                }

                retval.push(data[i]);
            }
        }
        return retval;
    }

    Explorer1.prototype.changeContract = function (contractId, contractName) {
        userService.setContract(contractId);
        Menu.triggerRefresh();
        Message.warning('The contract was changed to ' + contractName);
        self.isChangeContract = true;
    };

    Explorer1.prototype.loadManager = function () {
        var self = this;

            self.manager = new Manager({
                objectsPerPage: 20,
                //dataURL: URLdata,
                dataWrapper: "data",
                dataURL: self.urlsData.manager.url,
                //dataWrapper: self.urlsData.manager.wrappers,
                otherParams: { id: self.selectedFolder.id },
                dataParams: { mimeTypes: self.isPdfMarkup ? self.pdfMarkupMimeTypes : [], isAllContracts: false, showAllPhotos: false },
                urlParams: self.setParams(),
                fileManagerMenu: true,
                noTabletVersion: true,
                options: {
                    multiselect: true
                },
                menuClass: 'fileManagerMenu',
                gridClass: 'fileManagerGrid',
                search: self.managerSetup.search,
                searchColumns: self.managerSetup.searchColumns || null,
                globalSearchColumns: self.managerSetup.globalSearchColumns || null,

                hasGlobalSearchToggle: self.hasGlobal ? true : false,
                globalSearchParam: "isAllContracts",
                isGlobalSearch: false,

                layout: self.layout,
                rowOnClick: function (row) {
                    //console.error('has criteria', this.criteria);
                    if (self.selectedFolder.id == RECYCLE_BIN_NODE_ID || self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) {
                        row.selected = !row.selected;
                        return;
                    }
                    if (self.selectedFolder.id == ARCHIVE_NODE_ID) {
                        //self.archiveModules(row);
                        row['id'] = ARCHIVE_FILE_NODE_ID;
                        row['folderPosition'] = 1;
                        row['parentName'] = 'Archives';
                        row['name'] = row.archiveName;
                        row['parents'] = [-15],
                            row['folderPath'] = 'Root/Archives/Teste/';// + row.archiveName;
                        self.getFolderContent(row);
                    }
                    else if (this.layout == 1 || this.layout == 2) {
                        if (row.isFolder) {
                            (self.manager.searchObject || {}).isSearchView = false;
                            if (!row.contractId || row.contractId == userService.system.userdata.contractId) {
                                if (self.manager.hasFilters || self.manager.criteria) self.getTreeParents(row);
                                else self.getFolderContent(row);
                            } else {
                                self.changeContract(row.contractId, row.contractName);
                                self.destinationFolderFromSearch = row;
                            }

                        } else {
                            self.isPdfMarkup
                                ? self.pdfMarkup([row])
                                : self.pdfMarkupMimeTypes.includes(row.mimeType)
                                    ? self.view(row)
                                    : self.fileDetails(row);
                        }
                    } else {
                        row.selected = !row.selected;
                    }

                },
                rowOnDblClick: function (row) {
                    if (self.selectedFolder.id == RECYCLE_BIN_NODE_ID || self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) {
                        row.selected = !row.selected;
                        return;
                    }
                    if (self.selectedFolder.id == ARCHIVE_NODE_ID) {
                        self.archiveModules(row);
                    }
                    else if (this.layout == 1 || this.layout == 2) {
                        if (row.isFolder) {
                            self.editFolder(row);
                        } else {
                            self.isPdfMarkup
                                ? self.pdfMarkup([row])
                                : self.fileDetails(row);
                        }
                    } else {
                        if (row.isFolder) {
                            if (self.manager.hasFilters || self.manager.criteria) self.getTreeParents(row);
                            else self.getFolderContent(row);
                        } else {
                            self.isPdfMarkup
                                ? self.pdfMarkup([row])
                                : self.fileDetails(row);
                        }
                    }
                },
                parseData: function (data) { 
                    return self.parseManagerData(data); 
                },
                leftActions: [
                    {
                        setProperties: ACTIONS.BACK,
                        conditionOnTop: function () {
                            if (self.selectedFolder.id != -1 && !(self.manager.hasFilters || self.manager.criteria))
                                return true;
                            else return false;
                        },
                        click: function () {
                            if (self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) {
                                self.selectedFolder.id = ARCHIVE_NODE_ID;
                                self.getFolderContent(self.selectedFolder);
                            }
                            else {
                                self.getFolderContent(self.lookupFolders[self.selectedFolder.parentId] || self.lookupFolders[-1]);
                            }
                        }
                    },
                    {
                        setProperties: ACTIONS.NEWFOLDER,
                        type: 'primary',
                        conditionOnTop: function () {
                            if (self.operations.Create
                                && !self.isNonEditableFolder(self.selectedFolder)
                                && self.selectedFolder.id != -2
                                && !self.selectedFolder.isLocked && !(self.manager.hasFilters || self.manager.criteria)) return true;
                            else return false;
                        },
                        click: function () {
                            self.editFolder();
                        }
                    },
                    //{
                    //    setProperties: ACTIONS.UPLOAD,
                    //    conditionOnTop: function () {
                    //        if (self.operations.Create && self.selectedFolder.id != -2)
                    //            return true;
                    //        else return false;
                    //    },
                    //    click: function () {
                    //        self.upload();
                    //    }
                    //},
                    {
                        setProperties: ACTIONS.OPENUPLOADDIALOG,
                        name: "Upload",
                        // 'multiple' drives whether dialog can open over another (Must be true for pdfMarkupAddController)
                        multiple: true,
                        mimeTypeInUrl: true,
                        noLabel: true,
                        accept: self.accept || '*',
                        urlParams: function () {
                            return self.setParams({ parentId: self.selectedFolder.id });
                        },
                        generateFileIdUrl: self.urlsData.files.generateFileIdURL,
                        startUploadUrl: self.urlsData.files.startUploadURL,
                        uploadChunkUrl: self.urlsData.files.uploadChunkURL,
                        saveFileInfoUrl: self.urlsData.files.saveFileInfoURL,
                        uploadUrl: self.urlsData.files.uploadFileURL,
                        conditionOnTop: function () {
                            if (self.operations.Create
                                && self.selectedFolder.id != -2
                                && !self.isNonEditableFolder(self.selectedFolder)
                                && !self.selectedFolder.isLocked
                                && !(self.manager.hasFilters || self.manager.criteria))
                                return true;
                            else return false;
                        }
                    },
                ],
                actions: [
                    {
                        setProperties: ACTIONS.REFRESH,
                        conditionOnTop: function () {
                            return self.manager.selectedRows().length == 0;
                        },
                        click: function () {
                            self.getFolderContent();
                        }
                    },

                    {
                        setProperties: ACTIONS.PDF_MARKUP,
                        noLabel: true,
                        condition: function (row) {
                            if (row.id == RECYCLE_BIN_NODE_ID && row.isFolder) return false;
                            if (self.abbreviation == 'FCM' 
                                && userService.system.modules["PDFM"]
                                && !self.isNonEditableFolder(self.selectedFolder)
                                && self.canFilesDoActionPdfMarkup([row]))
                                return true;

                            return false;
                        },
                        conditionOnTop: function () {
                            if (self.abbreviation == "FCM"
                                && !self.isNonEditableFolder(self.selectedFolder)
                                && self.canFilesDoActionPdfMarkup(self.manager.selectedRows()))
                                return true;

                            return false;
                        },
                        click: function (rows) {
                            if (rows.length <= 0) {
                                Message.info('Please select PDF files');
                                return;
                            }

                            if (self.isPdfMarkup) {
                                self.pdfMarkup(rows);
                            } else {
                                var isAllPdfs = true;
                                for (var i = 0; i < rows.length; i++) {
                                    if (self.pdfMarkupMimeTypes.indexOf(rows[i].mimeType) < 0) {
                                        isAllPdfs = false;
                                        Message.error('Please select only PDF, JPG, or PNG files.')
                                        return;
                                    }
                                }
                                self.pdfMarkup(rows);
                            }
                        }
                    },
                    {
                        setProperties: ACTIONS.PDF_MARKUP_ROW,
                        condition: function (row) {
                            if (self.isNonEditableFolder(self.selectedFolder) 
                                || !self.canFilesDoActionPdfMarkup([row])
                                ) return false;
                            var isProjectFiles = self.abbreviation == 'FCM';
                            var hasPdfMarkupPermissions = userService.system.modules["PDFM"];
                            var canMarkup = self.pdfMarkupMimeTypes.indexOf(row.mimeType) != -1;

                            return isProjectFiles && hasPdfMarkupPermissions && canMarkup;
                        },
                        conditionOnTop: function () {
                            return false;
                        },
                        click: function (row) {
                            self.pdfMarkup([row]);
                        }
                    },
                    {
                        setProperties: ACTIONS.VIEW,
                        condition: function (row) {
                            if (self.pdfMarkupMimeTypes.includes(row.mimeType) 
                                && !self.isNonEditableFolder(self.selectedFolder)) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        click: function (row) {
                            self.view(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.DETAILS,
                        name: 'Details',
                        noLabel: true,
                        condition: function (row) {
                            return row.isFolder 
                            || self.selectedFolder.id == ARCHIVE_NODE_ID 
                            || self.selectedFolder.id == ARCHIVE_FILE_NODE_ID 
                            ? false : true;
                        },
                        click: function (row) {
                            if (row.isFolder) {
                                if (self.manager.hasFilters || self.manager.criteria) self.getTreeParents(row);
                                else self.getFolderContent(row);
                            }
                            else self.fileDetails(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.OPEN_FOLDER,
                        noLabel: true,
                        condition: function (row) {
                            return !row.isFolder 
                            || self.isNonEditableFolder(self.selectedFolder) 
                            ? false : true;
                        },
                        click: function (row) {
                            if (row.isFolder) {
                                (self.manager.searchObject || {}).isSearchView = false;
                                if (!row.contractId || row.contractId == userService.system.userdata.contractId) {
                                    if (self.manager.hasFilters || self.manager.criteria) self.getTreeParents(row);
                                    else self.getFolderContent(row);
                                } else {
                                    self.changeContract(row.contractId, row.contractName);
                                    self.destinationFolderFromSearch = row;
                                }
                            }
                            else self.fileDetails(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.EDIT_FOLDER,
                        condition: function (row) {
                            if (self.isNonEditableFolder(row) && row.isFolder) return false;
                            if (!row.isFolder) return false;
                            else return true;
                        },
                        name: self.selectedFolder.id == RECYCLE_BIN_NODE_ID || self.selectedFolder.id == ARCHIVE_NODE_ID
                            || self.selectedFolder.id == ARCHIVE_FILE_NODE_ID ? "View folder" : "Edit/View folder",
                        click: function (row) {
                            self.editFolder(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.EDIT,
                        condition: function (row) {
                            if (self.selectedFolder.id == -2
                                || self.isNonEditableFolder(self.selectedFolder)
                                || row.id == -2
                                || !self.operations.Update
                                || row.isFolder
                                || row.isLocked) return false;
                            else return true;
                        },
                        name: "Rename file",
                        click: function (row) {
                            self.editItem(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.SET_AVATAR,
                        condition: function (row) {
                            if (self.urlsData.files.setAsUserAvatar && row.setUserLogo) return true;
                            else return false;
                        },
                        click: function (row) {
                            self.setAsUserAvatar(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.SET_LOGO,
                        condition: function (row) {
                            if (self.urlsData.files.setAsCompanyLogo && row.setCompanyLogo) return true;
                            else return false;
                        },
                        click: function (row) {
                            self.setAsCompanyLogo(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.DOWNLOAD,
                        condition: function (row) {
                            if (row.isFolder || self.isNonEditableFolder(self.selectedFolder))
                                return false;
                            else return true;
                        },
                        click: function (row) {
                            self.downloadFile(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.DOWNLOAD_ZIP,
                        conditionOnTop: function () {
                            if (self.selectedFolder.id == ARCHIVE_NODE_ID || self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) {
                                return false;
                            }
                            else if (self.manager.selectedRows().length) {
                                return true
                            };
                        },
                        condition: function () {
                            if (self.selectedFolder.id == ARCHIVE_NODE_ID) {
                                return false;
                            }
                            else {
                                return true
                            };
                        },
                        click: function (rows) {
                            if (self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) {
                                self.downloadArchiveFile(rows);
                            }
                            else {
                                self.downloadZipFile(rows);
                            }
                        }
                    },
                    {
                        setProperties: ACTIONS.MOVE,
                        isGroup: false,
                        condition: function (row) {
                            if (row.id == RECYCLE_BIN_NODE_ID && row.isFolder) return false;
                            if (self.selectedFolder.id == -2 
                                || self.isNonEditableFolder(self.selectedFolder)
                                || !self.operations.Update 
                                || row.isLocked
                            ) return false;
                            else return true;
                        },
                        conditionOnTop: function () {
                            if (self.selectedFolder.id == -2 
                                || self.isNonEditableFolder(self.selectedFolder)
                                || self.selectedFolder.isLocked 
                                || !self.manager.selectedRows().length
                                || self.selectedFilesOrCurrentFolder().some(f => 
                                    f.isLocked
                                    || self.isNonEditableFolder(f)
                                )
                                || !self.operations.Update
                            ) return false;
                            else return true;
                        },
                        click: function (rows) {
                            self.moveItems(rows);
                        }
                    },
                    {
                        setProperties: ACTIONS.SHARE,
                        conditionOnTop: function () {
                            if (!self.hasShare 
                                || !self.operations.Update
                                || !self.manager.selectedRows().length)
                                return false;
                            else return true;
                        },
                        condition: function (row) {
                            if (row.isFolder 
                                || !self.hasShare 
                                || !self.operations.Update)
                                return false;
                            else return true;
                        },
                        click: function (rows) {
                            self.shareItems(rows);
                        }
                    },
                    {
                        setProperties: ACTIONS.UNSHARE,
                        conditionOnTop: function () {
                            if (!self.hasShare 
                                || !self.operations.Update
                                || !self.manager.selectedRows().length)
                                return false;
                            else return true;
                        },
                        condition: function (row) {
                            if (row.isFolder 
                                || !self.hasShare 
                                || !row.isShared 
                                || !self.operations.Update)
                                return false;
                            else return true;
                        },
                        click: function (rows) {
                            self.unshareItems(rows);
                        }
                    },
                    {
                        setProperties: ACTIONS.GLOBAL,
                        conditionOnTop: function () {
                            if (!self.hasGlobal 
                                || !self.operations.Update 
                                || self.anySelectedFileIsLocked()
                                || self.isNonEditableFolder(self.selectedFolder)
                                || !self.manager.selectedRows().length
                                || self.manager.selectedRows().some(f => 
                                    f.isFolder 
                                    || !self.hasGlobal 
                                    || self.hasGlobal && !f.setOnContract
                                    || f.isLocked)
                            )
                                return false;
                            else return true;
                        },
                        condition: function (row) {
                            if (row.isFolder 
                                || !self.hasGlobal 
                                || !row.setOnContract 
                                || row.isLocked
                                || self.isNonEditableFolder(self.selectedFolder)
                            )
                                return false;
                            else return true;
                        },
                        click: function (rows) {
                            self.setGlobal(rows);
                        }
                    },
                    {
                        setProperties: ACTIONS.UNSET_GLOBAL,
                        conditionOnTop: function () {
                            if (!self.hasGlobal 
                                || !self.operations.Update 
                                || self.anySelectedFileIsLocked()
                                || self.isNonEditableFolder(self.selectedFolder)
                                || !self.manager.selectedRows().length
                                || self.manager.selectedRows().some(f => 
                                    f.isFolder 
                                    || !self.hasGlobal 
                                    || self.hasGlobal && f.setOnContract
                                    || f.isLocked)
                            )
                                return false;
                            else return true;
                        },
                        condition: function (row) {
                            if ((row.id == RECYCLE_BIN_NODE_ID && row.isFolder) 
                                || self.selectedFolder.id == ARCHIVE_NODE_ID 
                                || self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) 
                                return false;
                            if (row.isFolder 
                                || !self.hasGlobal 
                                || self.hasGlobal && row.setOnContract 
                                || self.isLocked
                                || !self.operations.Update 
                                || self.selectedFolder.id == RECYCLE_BIN_NODE_ID)
                                return false;
                            else return true;
                        },
                        click: function (rows) {
                            self.unsetGlobal(rows);
                        }
                    },
                    {
                        setProperties: ACTIONS.DELETE,
                        condition: function (row) {
                            return (self.operations.Delete || self.operations.Create) 
                                && self.selectedFilesOrCurrentRow(row)
                                    .every(f => 
                                        f.isFolder && !f.isLocked && !self.isNonEditableFolder(f)  
                                        || !f.isFolder && !f.isLocked) 
                                ? true : false;
                        },
                        conditionOnTop: function () {
                            return (self.operations.Delete || self.operations.Create)
                                && self.selectedFolder.id != RECYCLE_BIN_NODE_ID
                                && self.selectedFilesOrCurrentFolder()
                                    .every(f => 
                                        f.isFolder && !f.isLocked && !self.isNonEditableFolder(f)  
                                        || !f.isFolder && !f.isLocked) 
                                ? true : false;
                        },
                        click: function (rows) {
                            if (self.selectedFolder.id == ARCHIVE_NODE_ID) {
                                var archiveIds = [];
                                var size = 1;
                                if (rows.archiveId) {
                                    archiveIds.push(rows.archiveId);
                                    size = rows.filesNumber;
                                }
                                else {
                                    archiveIds = rows.map((r) => r.archiveId);
                                }
                                self.deleteArchives(archiveIds, size);
                            }
                            else if (self.selectedFolder.id == ARCHIVE_FILE_NODE_ID) {
                                var archiveFileIds = [];
                                if (rows.archiveFileId) {
                                    archiveFileIds.push(rows.archiveFileId);
                                }
                                else {
                                    archiveFileIds = rows.map((r) => r.archiveFileId);
                                }
                                self.deleteArchiveFiles(archiveFileIds);
                            }
                            else {
                                self.delete(rows);
                            }
                        }
                    },
                    {
                        setProperties: ACTIONS.DETAILS,
                        name: 'Modules',
                        noLabel: true,
                        condition: function () {
                            return self.selectedFolder.id == ARCHIVE_NODE_ID ? true : false;
                        },
                        click: function (row) {
                            self.archiveModules(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.DELETE,
                        condition: function (row) {
                            if (self.selectedFolder.id != RECYCLE_BIN_NODE_ID 
                                || !self.operations.Delete) return false;
                            else return true;
                        },
                        name: "Permanently Delete",
                        conditionOnTop: function () {
                            if (self.selectedFolder.id != RECYCLE_BIN_NODE_ID 
                                || !self.operations.Delete
                                || !self.manager.selectedRows().length
                            ) return false;
                            else return true;
                        },
                        icon: "mdi mdi-delete-forever",
                        click: function (rows) {
                            self.delete(rows, true);
                        }
                    },
                    {
                        setProperties: ACTIONS.RESTORE,
                        condition: function (row) {
                            return self.operations.Delete 
                                && self.selectedFolder.id == RECYCLE_BIN_NODE_ID
                                && !row.isLocked
                                ? true : false;
                        },
                        conditionOnTop: function () {
                            return self.operations.Delete 
                                && self.selectedFolder.id == RECYCLE_BIN_NODE_ID
                                && self.manager.selectedRows().length
                                && !self.anySelectedFileIsLocked()
                                ? true : false;
                        },
                        click: function (rows) {
                            self.restore(rows);
                        }
                    }
                ]
            });
            self.manager.set_Columns(self.managerSetup.columns);
            if (self.managerSetup.customFilters) {
                self.manager.customFilters = angular.copy(self.managerSetup.customFilters);
                // hook global to manager

                if ((self.manager.customFilters.filter(f => f.key == "isAllContracts") || []).length > 0) {
                    Object.defineProperty(self.manager.customFilters.filter(f => f.key == "isAllContracts")[0], 'value', {
                        get: function () { return self.manager.isGlobalSearch },
                        set: function (value) { self.manager.isGlobalSearch = value; }
                    });
                }
            }
        }

        var compare = function (a, b) {
            var ret;
            if (a.id == -2 || a.id == RECYCLE_BIN_NODE_ID || a.id == ARCHIVE_NODE_ID) ret = -1;
            else if (b.id == -2 || b.id == RECYCLE_BIN_NODE_ID || b.id == ARCHIVE_NODE_ID) ret = 1;
            else {
                ret = a.name > b.name ? 1 : -1;
                ret = a.name == b.name ? 0 : ret;
            }

            return ret;
        }

        Explorer1.prototype.isNonEditableFolder = function (folder) {
            return folder.id == RECYCLE_BIN_NODE_ID
                || folder.id == ARCHIVE_NODE_ID
                || folder.id == ARCHIVE_FILE_NODE_ID;
        }

        Explorer1.prototype.selectedFilesOrCurrentRow = function (row) {
            var selectedRows = this.manager.selectedRows();
            if (selectedRows.length) {
                return selectedRows;
            }
            else if (row) {
                return [row];
            }
            return [];
        }

        Explorer1.prototype.selectedFilesOrCurrentFolder = function () {
            var selectedRows = this.manager.selectedRows();
            if (selectedRows.length) {
                return selectedRows;
            } 
            else if (this.selectedFolder) {
                return [this.selectedFolder];
            }
            return [];
        }

        Explorer1.prototype.anySelectedFileIsLocked = function () {
            return this.manager.selectedRows().some(r => r.isLocked);
        }

        Explorer1.prototype.canFilesDoActionPdfMarkup = function (rows) {
            var self = this;
            if (rows.length <= 0) {
                return false;
            }

            if (rows.some(r => r.isLocked)) {
                return false;
            }

            if (self.isPdfMarkup) {
                return true;
            }

            var isAllPdfs = true;
            for (var i = 0; i < rows.length; i++) {
                if (self.pdfMarkupMimeTypes.indexOf(rows[i].mimeType) < 0) {
                    isAllPdfs = false;
                    break;
                }
            }
            return isAllPdfs;
        }

        Explorer1.prototype.processListFolders = function () {
            var self = this;
            for (var i = 0; i < self.manager.rows.length; i++) {
                if (self.manager.rows[i].isFolder && !self.manager.hasFilters && !self.manager.criteria) {
                    if (self.lookupFolders[self.manager.rows[i].id]) {
                        for (var key in self.manager.rows[i])
                            if (self.manager.rows[i].hasOwnProperty(key))
                                self.lookupFolders[self.manager.rows[i].id][key] = self.manager.rows[i][key];
                    } else {
                        self.lookupFolders[self.manager.rows[i].id] = self.manager.rows[i];

                        if (!self.selectedFolder.folders) self.selectedFolder.folders = [];
                        self.selectedFolder.folders.push(self.manager.rows[i]);



                    }
                }

            }
            if (self.selectedFolder.folders) self.selectedFolder.folders.sort(compare);

            // if (self.selectedFolder.id == -1) self.folderTree = self.selectedFolder.folders;
        }
        Explorer1.prototype.restoreSearch = function () {
            var self = this;
            var contractId = self.manager.searchObject.urlParams.contractId || self.manager.searchObject.additionalSearchInfo.contractId;
            if (contractId == userService.system.userdata.contractId) {
                self.manager.restoreSearch();
                self.getTreeParents(self.manager.searchObject.additionalSearchInfo);
            } else {
                self.manager.searchObject.isSearchView = true;
                self.changeContract(contractId, self.manager.searchObject.additionalSearchInfo.contractName);
            }


        }
        Explorer1.prototype.clearSearch = function () {
            var self = this;
            self.manager.searchObject = null;
        }



    Explorer1.prototype.getFolderContent = function (folder, isTree) {
        var self = this;
        var p = $q.defer();
        if (isTree && (self.manager.searchObject || {}).isSearchView) self.manager.searchObject.isSearchView = false;
        if (!(self.manager.searchObject || {}).isSearchView) self.manager.criteria = '';
        else {
            self.manager.criteria = self.manager.searchObject.dataParams.criteria;
            self.manager.isGlobalSearch = self.manager.searchObject.dataParams[self.manager.globalSearchParam];
        }
        if (folder) var folder = self.lookupFolders[folder.id] || folder;

            //if (self.manager.hasFilters) {
            //    if (self.manager.dataParams.filter) delete self.manager.dataParams.filter;
            //    self.manager.currentFilters = null;
            //    self.manager.hasFilters = false;
            //}

            if (folder) {

                self.selectedFolder = folder;
                var otherParams = { id: self.selectedFolder.id, parentName: (self.selectedFolder.name || "root") };
                if (folder.id == RECYCLE_BIN_NODE_ID) otherParams.isDeletedItems = true;
                else otherParams.isDeletedItems = false;
                self.manager.otherParams = self.setParams(otherParams);

        } else {
            folder = self.selectedFolder;
        }
        self.populateBreadcrumb(folder);

        self.manager.additionalSearchInfo = angular.copy(folder);
        if (!self.manager.additionalSearchInfo.contractName)
            self.manager.additionalSearchInfo.contractName = userService.system.contractsLookup && userService.system.contractsLookup.length && userService.system.userdata.contractId
                ? userService.system.contractsLookup[userService.system.userdata.contractId].value
                : '';
        if (!self.manager.additionalSearchInfo.contractId)
            self.manager.additionalSearchInfo.contractId = userService.system.userdata.contractId;

            if ((folder || {}).id == ARCHIVE_NODE_ID) {
                self.manager.dataURL = URI.CT.SEARCH_ARCHIVES;
                self.manager.urlParams = { contractId: userService.system.userdata.contractId };
                self.manager.searchColumns = self.managerSetup.archiveColumns;
                self.manager.globalSearchColumns = self.managerSetup.archiveColumns;
                self.manager.nodeType = ARCHIVE_NODE_ID;
            }
            else if ((folder || {}).id == ARCHIVE_FILE_NODE_ID) {
                self.manager.dataURL = URI.CT.SEARCH_ARCHIVE_FILES;
                self.manager.urlParams = { archiveId: folder.archiveId };
                self.manager.contractId = folder.contractId;
                self.manager.searchColumns = self.managerSetup.archiveFileColumns;
                self.manager.globalSearchColumns = self.managerSetup.archiveFileColumns;
                self.manager.nodeType = ARCHIVE_FILE_NODE_ID;
            }
            else {
                self.manager.dataURL = self.urlsData.manager.url;
                self.manager.urlParams = { contractId: userService.system.userdata.contractId };
                self.manager.searchColumns = self.managerSetup.searchColumns;
                self.manager.globalSearchColumns = self.managerSetup.globalSearchColumns;
                self.manager.nodeType = RECYCLE_BIN_NODE_ID;
            }
            self.manager.options.sort = [];

            self.manager.loadPage(null, null, true)
                .then(function () {
                    if ((folder || {}).id == RECYCLE_BIN_NODE_ID) { // recycle bin folder
                        if (self.managerSetup.recycleBinColumns) {
                            self.recycleBinView = true;

                            //do not modify the order of these lines as it will create regressions !!!
                            self.manager.layoutCols[1] = self.managerSetup.recycleBinColumns;
                            if (!self.manager.regularCols) self.manager.regularCols = self.manager.layoutCols[1];
                        }
                    }
                    else if ((folder || {}).id == ARCHIVE_NODE_ID) { // archive documents folder                         
                        if (self.managerSetup.archiveColumns) {
                            self.recycleBinView = true;

                            //do not modify the order of these lines as it will create regressions !!!
                            self.manager.layoutCols[1] = self.managerSetup.archiveColumns;
                            if (!self.manager.regularCols) self.manager.regularCols = self.manager.layoutCols[1];
                        }
                    }
                    else if ((folder || {}).id == ARCHIVE_FILE_NODE_ID) { // archive files folder                         
                        if (self.managerSetup.archiveFileColumns) {
                            self.recycleBinView = true;

                            //do not modify the order of these lines as it will create regressions !!!
                            self.manager.layoutCols[1] = self.managerSetup.archiveFileColumns;
                            if (!self.manager.regularCols) self.manager.regularCols = self.manager.layoutCols[1];
                        }
                    }
                    else {
                        if (self.recycleBinView) {
                            self.recycleBinView = false;
                        }

                        if ((self.manager.searchObject || {}).isSearchView) {                          
                            self.manager.set_Columns(self.manager.layoutCols[1]);
                        }
                        else {
                            self.manager.set_Columns(self.managerSetup.columns);
                        }                        

                        //this is the root and it is not a search view
                        if (folder.id == -1 &&  !(self.manager.searchObject || {}).isSearchView && self.abbreviation == "FCM") {
                            // add the Archives node                            
                            var archive = {
                                id: ARCHIVE_NODE_ID,
                                createdBy: "CIPO Admin",
                                createdOn: "06/29/2022",
                                updatedOn: "06/29/2022",
                                canDelete: true,
                                isProjectGlobal: true,
                                isContractGlobal: true,
                                setOnContract: false,
                                mimeTypeId: 0,
                                name: "Archives",
                                parentId: - 1,
                                parentName: "Root",
                                folderPath: ".../Archives/",
                                folderPosition: 0,
                                isContractGlobal: true,
                                isDeleted: false,
                                isFolder: true,
                                isLocked: false,
                                isProjectGlobal: true,
                                length: "-",
                                parents: [-1],
                                parentsString: "-1",
                                setCompanyLogo: false,
                                setOnContract: false,
                                setUserLogo: false,
                                version: 0,
                                versionId: 0,
                                _big_name: '<span class="fileInfo folderIcon  ellipsedText"><i class="mdi mdi-archive fmSymbol successText"></i>Archives</span>',
                                _name: '<span class="fileInfo folderIcon "><i class="mdi mdi-archive fmSymbol successText"></i>Archives</span>'
                            }

                        self.manager.rows.unshift(archive);
                    }

                    self.processListFolders();
                    if (!self.isInExpandedFolders(folder.id)) self.expandedFolders.push(folder);

                    if (!folder.expanded) {
                        folder.expanded = true;
                    }
                    if (self.destinationFolderFromSearch) self.loading = true;
                    if (!self.manager.hasFilters && !self.manager.criteria && self.manager.pages <= 1) {
                        //
                    } else {
                        if (!folder.isChildrenLoaded) {
                            if (self.manager.hasFilters || self.manager.criteria) self.loading = true;
                            self.getChildren(folder, true)
                        }
                    }
                }

                    //console.error('folder in get-content', folder);
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                    p.reject();
                })
            return p.promise;

    }

    Explorer1.prototype.getTreeParents = function (folder) {
        var self = this;
        self.manager.loading = true;
        // self.manager.criteria = "";
        var getParentsLine = function (id) {
            if ((self.lookupFolders[id] || {}).isChildrenLoaded) {
                if (!self.lookupFolders[id].expanded) {
                    self.expandedFolders.push(self.lookupFolders[id]);
                    self.lookupFolders[id].expanded = true;
                }

                if (folder.parents.indexOf(id) != (folder.parents.length - 1)) getParentsLine(folder.parents[folder.parents.indexOf(id) + 1]);
                else {
                    self.getFolderContent(self.lookupFolders[folder.id]);
                }

            } else {
                self.getChildren(self.lookupFolders[id], true)
                    .then(function () {
                        if (folder.parents.indexOf(id) != (folder.parents.length - 1)) getParentsLine(folder.parents[folder.parents.indexOf(id) + 1]);
                        else {
                            self.getFolderContent(self.lookupFolders[folder.id]);
                        }
                    })
            }
        }

            if (((folder || {}).parents || []).length > 1) getParentsLine(folder.parents[1]); // [0] is root (-1), always loaded
            else {
                self.getFolderContent(self.lookupFolders[folder.id]);
                // self.selectedFolder = self.lookupFolders[folder.id];
            }

        }

    Explorer1.prototype.processTreeFolders = function (folderId, list) {
        var self = this;

            // add the Archives node
            if (list != null && self.abbreviation == "FCM") {
                var archive = {
                    id: ARCHIVE_NODE_ID,
                    canDelete: true,
                    isProjectGlobal: true,
                    isContractGlobal: true,
                    setOnContract: false,
                    mimeTypeId: 0,
                    name: "Archives",
                    parentId: - 1,
                    parentName: "Root",
                    folderPath: ".../Archives/",
                    folderPosition: 0,
                    isContractGlobal: true,
                    isDeleted: false,
                    isFolder: true,
                    isLocked: false,
                    isProjectGlobal: true,
                    length: "-",
                    parents: [-1],
                    parentsString: "-1",
                    setCompanyLogo: false,
                    setOnContract: false,
                    setUserLogo: false,
                    version: 0,
                    versionId: 0
                };

            list.splice(1, 0, archive);
        }

            list = list ? self.parseManagerData(list) : [];
            for (var i = 0; i < list.length; i++) {
                if (list[i].isFolder) {
                    if (self.lookupFolders[list[i].id]) {
                        for (var key in list[i])
                            if (list[i].hasOwnProperty(key))
                                self.lookupFolders[list[i].id][key] = list[i][key];
                    } else {
                        self.lookupFolders[list[i].id] = list[i];
                        if (!self.lookupFolders[folderId].folders) self.lookupFolders[folderId].folders = [];
                        self.lookupFolders[folderId].folders.push(list[i]);
                    }
                }

            }

        if (self.selectedFolder.folders) self.selectedFolder.folders.sort(compare);
    }

    Explorer1.prototype.getChildren = function (folder, status) {
        var self = this;
            var p = $q.defer();
            try {

                if (folder) folder.expanded = status;
                if (status && folder && !folder.isChildrenLoaded) {
                    folder.loadingChildren = true;
                    self[self.urlsData.tree.url.method](self.urlsData.tree.url, { url: self.setParams({ isFolders: true }), urltype: 'obj', body: { id: folder.id, isFolders: true } })
                        .then(function (r) {
                            folder.isChildrenLoaded = true;
                            self.processTreeFolders(folder.id, r.data);
                            folder.expanded = true;
                            if (!self.isInExpandedFolders(folder.id)) self.expandedFolders.push(folder);

                        // if (folder.id == 1) self.folderTree = folder.folders;

                        p.resolve();
                    })
                    .catch(function (e) { console.error(e); p.reject(e); Message.dberror(e); })
                    .finally(function () { folder.loadingChildren = false; self.loading = false; });


            }
        }
        catch (e) {
            console.error(e);
            p.reject();
        }

        return p.promise;
    }

    Explorer1.prototype.populateBreadcrumb = function (folder) {
        var self = this;
        self.breadcrumb = [];
        if (folder.parents && folder.parents.length) {
            for (var i = 0; i < folder.parents.length; i++) {
                if (self.lookupFolders[folder.parents[i]])
                    self.breadcrumb.push(self.lookupFolders[folder.parents[i]]);
            }
            if (folder.id == ARCHIVE_FILE_NODE_ID) {
                self.breadcrumb.push(folder);
            }
            else {
                self.breadcrumb.push(self.lookupFolders[folder.id]);
            }
        }
        if (self.breadcrumb.length) self.breadcrumbItemWidth = 100 / (self.breadcrumb.length + 1);
        else self.breadcrumbItemWidth = 100;
    }

        Explorer1.prototype.editItem = function (item, ev) {
            var self = this;
            // if (!(item || {}).id) var isAdd = true;
            var item = item ? self.setParams({ id: item.id, name: item.name, parentId: item.parentId, isFolder: item.isFolder })
                : self.setParams({ name: "", parentId: self.selectedFolder.id });

            var dataURL = item.id ?
                item.isFolder ? self.urlsData.folders.editFolderURL : self.urlsData.files.renameFileURL :
                self.urlsData.folders.addFolderURL;

            $mdDialog.show({
                locals: { item: item, dataURL: dataURL, showIcons: false, otherParams: self.setParams() },
                controller: 'renameItemController',
                templateUrl: '/ng/views/admin/modals/renameItem.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                focusOnOpen: false,
                multiple: self.isPdfMarkup || false,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function () {


                self.getFolderContent(self.selectedFolder);
            }, function () {
                // cancel pressed
            });
    }

        Explorer1.prototype.editFolder = function (folder, ev) {
            var self = this;
            if (!(folder || {}).id) var isAdd = true;
            var item = folder ? self.setParams({
                id: folder.id,
                name: folder.name,
                parentId: folder.parentId,
                setOnContract: folder.setOnContract || false,
                isLocked: folder.isLocked || self.selectedFolder.id == RECYCLE_BIN_NODE_ID || self.selectedFolder.id == ARCHIVE_NODE_ID
            })
                : self.setParams({ name: "", parentId: self.selectedFolder.id, setOnContract: false });

            if (!isAdd) item.description = '<h2>Folder Information</h2><div class="gridContainerx2" style="grid-gap: .5em;">'
                + '<p>Created on:<strong> ' + folder.createdOn
                + '</strong></p><p>Created by: <strong>' + folder.createdBy
                + '</strong></p><p>' + (self.selectedFolder.id != RECYCLE_BIN_NODE_ID ? 'Updated' : 'Deleted') + ' on:<strong> ' + folder.updatedOn
                + '</strong></p><p>' + (self.selectedFolder.id != RECYCLE_BIN_NODE_ID ? 'Updated' : 'Deleted') + ' by: <strong>' + folder.updatedBy
                + '</strong></p><p>' + (self.selectedFolder.id != RECYCLE_BIN_NODE_ID ? '' : 'Original ') + 'Location: <strong>' + folder.folderPath + '</strong></p>'
                + (folder.moduleName ? '<p>Destination for module: <strong>' + folder.moduleName + ' (' + folder.moduleAbbr + ')</strong></p>' : '')
                + (!folder.setOnContract ? '<p><i class="mdi mdi-earth successText"></i> <strong>Global folder</strong></p>' : '')
                + '</div>'

        var urlParams = self.hasGlobal ? { contractId: userService.system.userdata.contractId } : {};

        item = new GenericClass(item, urlParams);

        GenericClass.prototype.formFields = function () {

            var formFields = {
                name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 255 }, editMode: !item.properties.isLocked }
            }

            if (self.hasGlobal) formFields.setOnContract = { label: 'Set only on current contract', type: 'checkbox', editMode: !item.properties.isLocked };

                return formFields;
            };


        GenericClass.prototype.formPattern = function () {
            var formPattern = [{ name: 100 }];
            if (self.hasGlobal) formPattern = [{ name: 50, setOnContract: 50 }];
            return formPattern;
        };

            GenericClass.prototype.resetPropertiesOnAddNew = { id: null, name: "" };

        GenericClass.prototype.urls = {
            create: function () { return self.urlsData.folders.addFolderURL },
            edit: function () { return self.urlsData.folders.editFolderURL },
            delete: function () { return null }
        };

            var options = {
                titles: { modal: item.properties.id ? 'Edit Folder' : 'Add Folder' },
                hasAddNew: true,
                hideSaveButtons: (folder || {}).moduleName || self.selectedFolder.id == RECYCLE_BIN_NODE_ID ? true : false
            }


            item.createForm();

            $mdDialog.show({
                locals: { item: item, options: options },
                controller: 'genericClassController',
                templateUrl: '/ng/views/system/modals/genericClass.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                focusOnOpen: false,
                multiple: self.isPdfMarkup || false,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (itemsList) {
                    if (!self.selectedFolder.folders) self.selectedFolder.folders = [];
                    for (var i = 0; i < itemsList.length; i++) {
                        if (!self.lookupFolders[itemsList[i].id]) {
                            self.lookupFolders[itemsList[i].id] = itemsList[i];

                            self.selectedFolder.folders.push(itemsList[i]);
                        }
                    }
                    self.selectedFolder.folders.sort(compare);
                    self.getFolderContent(self.selectedFolder);
                }, function () {
                    // cancel pressed
                });
        }

    Explorer1.prototype.fileDetails = function (file, ev) {
        var self = this;

            $mdDialog.show({
                locals: { file: file, dataURL: self.urlsData.files.fileDetailsURL, otherParams: self.setParams({ fileId: file.id }) },
                controller: 'editFileController',
                templateUrl: '/ng/views/admin/modals/editFile.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                focusOnOpen: false,
                multiple: self.isPdfMarkup || false,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                // hide function
                .then(function () {
                    self.getFolderContent(self.selectedFolder);
                    self.clearStateParams();
                },
                    // cancel function
                    function () {
                        self.clearStateParams();
                    });
        }

        Explorer1.prototype.clearStateParams = function () {
            $state.go('.', { f: '' }, { notify: false });
        }

        Explorer1.prototype.archiveModules = function (archive, ev) {
            var self = this;

            $mdDialog.show({
                locals: { modulesList: archive.modules },
                controller: 'archiveModulesController',
                templateUrl: '/ng/views/admin/modals/archiveModules.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                focusOnOpen: false,
                multiple: self.isPdfMarkup || false,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                // hide function
                .then(function () {
                },
                    // cancel function
                    function () {
                    });
        }

    //Explorer1.prototype.upload = function (ev) {
    //    var self = this;
    //    var uploadURL = self.urlsData.files.uploadFileURL;
    //    $mdDialog.show({
    //        locals: { action: null },
    //        controller: 'fmUploadController',
    //        templateUrl: '/ng/views/admin/modals/fmUpload.html',
    //        parent: angular.element(document.body),
    //        targetEvent: ev,
    //        fullscreen: true,
    //        escapeToClose: false,
    //        clickOutsideToClose: false
    //    })
    //        .then(function () {

    //            self.manager.loadPage()
    //                .then(function () {

    //                })
    //                .catch(function () {

    //                })
    //        }, function () {
    //            console.log('cancel pressed');
    //        });
    //}

    var confirm = function (title, text, isMultiple) {
        var dialog = $mdDialog.confirm({
            title: title,
            textContent: text,
            ariaLabel: 'Confirm Dialogue',
            ok: 'Proceed',
            cancel: 'Cancel',
            multiple: isMultiple || false
        });
        // .title(title)
        // .textContent(text)
        // .ariaLabel('Confirm Dialogue')
        // .targetEvent(ev)
        // .ok('Proceed')
        // .cancel('Cancel');
        return dialog;
    }

    // Allows viewing of pdf/jpeg/png files via pspdfkit viewer
    Explorer1.prototype.view = function (row, event) {
        var self = this;
        self.manager.loading = true;
        var uri = self.abbreviation == 'FCM'
            ? URI.PROJECT_FILE_MANAGER.GET_FILE_URL
            : URI.MY_CIPO_DRIVE.GET_FILE_URL;

            self[uri.method](`${uri}?versionId=${row.versionId}&isPreview=false`)
                .then(function (url) {
                    $mdDialog.show({
                        locals: {
                            module: userService.system.modules['FCM'],
                            documentId: null,
                            fileUrl: url,
                            fileId: row.id,
                            mimeType: row.mimeType,
                            title: row.name,
                            contractId: userService.system.userdata.contractId
                        },
                        controller: 'pspdfkitViewerController',
                        templateUrl: '/ng/views/admin/modals/pspdfkitViewer.html',
                        parent: angular.element(document.body),
                        targetEvent: event,
                        fullscreen: true,
                        escapeToClose: false,
                        clickOutsideToClose: false,
                        disableParentScroll: true,
                        multiple: true
                    }).then(function (result) {
                    }, function () {
                    })
                        .finally(function () {
                            self.manager.loading = false;
                        });
                });
        }

    Explorer1.prototype.pdfMarkup = function (rows) {
        var self = this;
        self.manager.loading = true;
            // If single already uploaded row is clicked
            if (rows.length == 1 && rows[0].markupDocumentId) {
                var parameters = {
                    tenantIdentifier: userService.getTenantIdentifierById(userService.tenantId),
                    d: rows[0].markupDocumentId,
                    f: rows[0].id,
                    n: rows[0].name
                };
                self.isPdfMarkup
                    ? self.closeDialog(parameters.d, parameters.f, parameters.n)
                    : self.navigateTo('tenant.pdfMarkup', parameters);
                return;
            }

        var payload = [];
        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            if (!row.markupDocumentId)
                payload.push({
                    fileId: row.id,
                    versionId: row.versionId,
                    fileName: row.name
                });
        }

        var uri = payload.length == 1
            ? URI.PDF_MARKUP.UPLOAD_DOCUMENT
            : URI.PDF_MARKUP.UPLOAD_DOCUMENTS;
        var contractId = userService.system.userdata.contractId;
        var pdfMarkupModuleId = userService.system.modules["PDFM"].moduleId;

        self[uri.method](`${uri}?moduleId=${pdfMarkupModuleId}&contractId=${contractId}`,
            { body: payload.length == 1 ? payload[0] : payload },
            { headers: { moduleId: pdfMarkupModuleId } })
            .then(function (res) {
                (payload.length > 1 && res.length > 0)
                    ? Message.error(`Failed to load ${res.length}/${payload.length} selected files for markup`)
                    : Message.info(payload.length == 1 ? 'File loaded' : 'Files ready for markup');

                self.manager.loading = false;
                // Navigate to detail if single file. Otherwise, navigate to markup grid
                var parameters = payload.length == 1
                    ? { tenantIdentifier: userService.getTenantIdentifierById(userService.tenantId), d: res, f: payload[0].fileId, n: payload[0].fileName }
                    : { tenantIdentifier: userService.getTenantIdentifierById(userService.tenantId) };

                self.isPdfMarkup
                    ? payload.length == 1 ? self.closeDialog(parameters.d, parameters.f, parameters.n) : self.closeDialog()
                    : self.navigateTo('tenant.pdfMarkup', parameters);
            })
            .catch(function (err) {
                self.manager.loading = false;
                console.error(err);
                Message.error(`An error occurred when attempting to markup ${payload.length == 1 ? 'this PDF' : 'the selected PDFs'}.`);
            });
    }

        Explorer1.prototype.delete = function (rows, isPermanent) {
            var self = this;
            var dataURL = isPermanent ? self.urlsData.folders.permanentlyDeleteURL : self.urlsData.folders.deleteURL;
            var toSend = { files: [], foldersId: [] };
            if (Object.prototype.toString.call(rows) == '[object Object]') {
                rows = [rows];
            }
            // var itemsUnableToProcess = false;
            if (rows && rows.length) {
                for (var i = 0; i < rows.length; i++) {
                    if (rows[i].isFolder) {
                        toSend.foldersId.push(rows[i].id);
                    }
                    else toSend.files.push({ id: rows[i].id, folderId: rows[i].parentId });
                }

                var modalTitle = isPermanent ? "Permanently Delete" : 'Delete';
                var modalText = isPermanent ? 'Are you sure you want to permanently delete the selected items?' : 'Are you sure you want to delete the selected items?';

                $mdDialog.show(confirm(modalTitle, modalText, self.isPdfMarkup))
                    .then(function () {
                        self.manager.loading = true;
                        self[dataURL.method](dataURL, { url: self.setParams(), urltype: 'obj', body: toSend })
                            .then(function (result) {
                                //moved to the Recycle Bin
                                Message.info('Item(s) deleted successfully.');                               

                                if ((self.selectedFolder.folders || []).length) {
                                    for (var i = 0; i < toSend.foldersId.length; i++) {
                                        self.selectedFolder.folders.splice(self.selectedFolder.folders.indexOf(self.lookupFolders[toSend.foldersId[i]]), 1);
                                        delete self.lookupFolders[toSend.foldersId[i]];
                                    }
                                }

                                self.getFolderContent(self.selectedFolder);
                            })
                            .catch(function (e) {
                                console.error(e);
                                Message.dberror(e);
                                self.manager.loading = false;

                        })
                        .finally(function () {

                        });
                })
                .catch(function () {
                    // cancel pressed
                })

        } else {
            Message.info('Please select items to delete');
        }
    }

        Explorer1.prototype.deleteArchives = function (archiveIds, size) {
            var self = this;

        if (size == 0) {
            $mdDialog.show(confirm('Deleting Archive(s)', 'Are you sure you want to delete the selected Archive(s)?\r\nPlease be aware that  this is an irreversible process.'))
                .then(function () {
                    self.manager.loading = true;
                    $http[URI.CT.DELETE_ARCHIVES.method](URI.CT.DELETE_ARCHIVES.toString(), archiveIds)
                        .then(function () {
                            Message.info('Archive(s) deleted successfully');
                            self.getFolderContent(self.selectedFolder);
                        })
                        .catch(function (e) {
                            console.error(e);
                            Message.dberror(e);
                            self.manager.loading = false;
                        })
                        .finally(function () {

                            });
                    })
                    .catch(function () {
                        // cancel pressed
                    })
            }
            else {
                Message.stickyWarning("The selected archive cannot be deleted because it is not empty.");
            }
        }


        Explorer1.prototype.deleteArchiveFiles = function (archiveFileIds) {
            var self = this;

            $mdDialog.show(confirm('Deleting Archive File(s)', 'Are you sure you want to delete the selected archive file(s)?\r\nPlease be aware that  this is an irreversible process.'))
                .then(function () {
                    self.manager.loading = true;
                    $http[URI.CT.DELETE_ARCHIVE_FILES.method](URI.CT.DELETE_ARCHIVE_FILES.toString(), archiveFileIds)
                        .then(function () {
                            Message.info('Archive file(s) deleted successfully');
                            self.getFolderContent(self.selectedFolder);
                        })
                        .catch(function (e) {
                            console.error(e);
                            Message.dberror(e);
                            self.manager.loading = false;
                        })
                        .finally(function () {

                        });
                })
                .catch(function () {
                    // cancel pressed
                })
        }

        Explorer1.prototype.restore = function (rows, ev) {
            var self = this;
            var dataURL = self.urlsData.folders.restoreURL;

            var toSend = { files: [], foldersId: [] };

            if (Object.prototype.toString.call(rows) == '[object Object]') {
                rows = [rows];
            }
            var itemsUnableToProcess = false;

            if (rows && rows.length) {
                for (var i = 0; i < rows.length; i++) {

                    if (rows[i].isFolder) {
                        toSend.foldersId.push(rows[i].id);
                    }
                    else toSend.files.push({ id: rows[i].id, folderId: rows[i].parentId });
                }

                if (!toSend.foldersId.length && !toSend.files.length) {
                    Message.warning("You don't have the right to restore the items you selected.");
                    return;
                }

                if (itemsUnableToProcess) {
                    Message.warning("The items you don't have the right to restore were remove from the list.");
                }
                // restoreItemsController

                $mdDialog.show({
                    locals: {
                        items: toSend,
                        options: { url: self.urlsData.folders.restoreURL }
                    },
                    controller: 'restoreItemsController',
                    templateUrl: '/ng/views/admin/modals/restoreItems.html',
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    fullscreen: true,
                    focusOnOpen: false,
                    multiple: self.isPdfMarkup || false,
                    escapeToClose: false,
                    clickOutsideToClose: false
                })
                    .then(function (result) {
                        self.getFolderContent(self.selectedFolder);
                        // reload tree
                        self.lookupFolders[-1].isChildrenLoaded = false;
                        // self.lookupFolders[-1].folders = [];
                        self.getChildren(self.lookupFolders[-1], true)
                            .then(function () {
                                for (var i = 0; i < self.expandedFolders.length; i++) {
                                    if (self.expandedFolders[i].id != -1) {
                                        self.expandedFolders[i].isLoadingChildren = false;
                                        self.getChildren(self.lookupFolders[self.expandedFolders[i].id], true)
                                    }
                                }
                            })


                    }, function () {
                        // cancel pressed
                    });

        } else {
            Message.info('Please select items to restore');
        }
    }

    Explorer1.prototype.downloadZipFile = function (rows) {
        var self = this;
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }
        var altrows = self.manager.selectedRows();

        // here if altrows has any entries, we combine altRows (the rows with boxes checked) with
        //  rows(the row the icon was pressed from), while excluding duplicates
        if (altrows.length > 0) {
            for (var row in altrows) {
                if (rows.filter(obj => {
                    return obj.id === altrows[row].id
                }).length == 0) {
                    rows.push(altrows[row]);
                }
            }
        }
        var toDownload = { filesId: [], foldersId: [] };

        if (rows && rows.length) {
            self.manager.loading = true;

            for (var i = 0; i < rows.length; i++) {
                if (rows[i].isFolder) {
                    toDownload.foldersId.push(rows[i].id);
                }
                else toDownload.filesId.push(rows[i].id);
            }

                $http[self.urlsData.files.downloadZipFile.method](self.urlsData.files.downloadZipFile.toString() + (self.hasGlobal ? '?contractId=' + userService.system.userdata.contractId ?? '' : ''), toDownload, { responseType: "arraybuffer" })
                    .then(function (result) {
                        if (result.status && result.status == 202) {
                            Message.stickyWarning("Download process has started and is running in the background. You will receive an email once it is completed");
                        } else {
                            var filename = 'Cipo-download';
                            var type = result.headers('Content-Type');
                            var disposition = result.headers('Content-Disposition');
                            if (disposition) {
                                var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                                if (match[1])
                                    filename = match[1];
                            }
                            filename = filename.replace(/[<>:"\/\\|?*]+/g, '_');
                            if (filename.startsWith('cipo-download-')) {
                                filename = filename + '.zip';
                            }
                            
                            var blob = new Blob([result.data], { type: type });
                            FileSaver.saveAs(blob, filename);
                        }
                    })
                    .catch(function (e) {
                        console.error(e);
                        Message.dberror(e);
                    })
                    .finally(function () { self.manager.loading = false; });

        } else {
            Message.info('Please select items to download');
        }
    }

    Explorer1.prototype.moveItems = function (rows, ev) {
        var self = this;
        //var dataURL = self.urlsData.folders.moveURL;
        var toMove = { filesId: [], foldersId: [] };
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }

        if (rows && rows.length) {
            for (var i = 0; i < rows.length; i++) {
                if (rows[i].isFolder) {
                    toMove.foldersId.push(rows[i].id);
                }
                else toMove.filesId.push(rows[i].id);
            }

            var info = {
                modalName: 'Move items',
                saveButtonText: 'Move to',
                modalAction: "move",
                urlsData: self.urlsData,
                sourceFolderId: self.selectedFolder.id,
                otherParams: self.setParams(),
                canUseRoot: true
            }

            $mdDialog.show({
                locals: {
                    items: toMove,
                    info: info
                },
                controller: 'moveItemsController',
                templateUrl: '/ng/views/admin/modals/moveItems.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                focusOnOpen: false,
                multiple: self.isPdfMarkup || false,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (result) {
                    self.getFolderContent(self.selectedFolder)
                        .then(function () {
                            if (toMove.foldersId.length) {
                                for (var i = 0; i < toMove.foldersId.length; i++) {
                                    self.selectedFolder.folders.splice(self.selectedFolder.folders.indexOf(self.lookupFolders[toMove.foldersId[i]]), 1);
                                    delete self.lookupFolders[toMove.foldersId[i]];
                                }
                            }
                            var parent = (result == -1 || !self.lookupFolders[result]) ? { id: -1 } : self.lookupFolders[result];
                            self.getChildren(parent, true);
                            // self.getChildren(self.selectedFolder, true);
                        })

                }, function () {
                    // cancel pressed
                });

        } else {
            Message.info('Please select items to move');
        }
    }

    Explorer1.prototype.shareItems = function (rows, ev) {
        var self = this;
        //var dataURL = self.urlsData.folders.moveURL;
        var toShare = { filesId: [], usersId: [], files: [] };
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }

        if (rows && rows.length) {
            for (var i = 0; i < rows.length; i++) {
                if (!rows[i].isFolder) {
                    toShare.files.push(rows[i]);
                    toShare.filesId.push(rows[i].id);
                } else {
                    rows[i].selected = false;
                }
            }
            if (!toShare.filesId.length) {
                Message.info('Please select files');
                return;
            }

            $mdDialog.show({
                locals: { items: toShare, urlData: self.urlsData.files.shareFileURL },
                controller: 'shareItemsController',
                templateUrl: '/ng/views/admin/modals/shareItems.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                focusOnOpen: false,
                multiple: self.isPdfMarkup || false,
                escapeToClose: false,
                clickOutsideToClose: false
            })
                .then(function (result) {
                    for (var i = 0; i < toShare.files.length; i++) {
                        toShare.files[i].selected = false;
                    }
                    self.manager.loadPage();
                }, function () {
                    // cancel pressed
                });


        } else {
            Message.info('Please select files');
        }
    }

    Explorer1.prototype.unshareItems = function (rows, ev) {
        var self = this;
        var dataURL = self.urlsData.files.unshareFileURL;
        var toUnshare = { list: [] };
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }
        var files = [];
        if (rows && rows.length) {
            for (var i = 0; i < rows.length; i++) {
                if (!rows[i].isFolder) {
                    toUnshare.list.push(rows[i].id);
                    files.push(rows[i]);
                } else {
                    rows[i].selected = false;
                }
            }
            if (!toUnshare.list.length) {
                Message.info('Please select files');
                return;
            }

                $mdDialog.show(confirm('Unshare file(s)', 'Are you sure you want to unshare the selected item(s)?', self.isPdfMarkup))
                    .then(function () {
                        self.manager.loading = true;
                        self[dataURL.method](dataURL, { url: { parentId: self.selectedFolder.id }, urltype: "obj", body: toUnshare })
                            .then(function (result) {

                                Message.info('The selected files were unshared successfully.');
                                self.manager.loadPage();
                            })
                            .catch(function (e) {
                                console.error(e);
                                Message.dberror(e);
                            })
                            .finally(function () {
                                // self.manager.loading = false;
                            });
                    })
                    .catch(function () {
                        // cancel pressed
                    })

        } else {
            Message.info('Please select files');
        }
    }

    Explorer1.prototype.setGlobal = function (rows, ev) {
        var self = this;
        var dataURL = self.urlsData.files.globalFileURL;
        var toShare = { filesId: [] };
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }
        var files = [];
        if (rows && rows.length) {
            for (var i = 0; i < rows.length; i++) {
                if (!rows[i].isFolder) {
                    toShare.filesId.push(rows[i].id);
                    files.push(rows[i]);
                } else {
                    rows[i].selected = false;
                }
            }
            if (!toShare.filesId.length) {
                Message.info('Please select files');
                return;
            }

                $mdDialog.show(confirm('Set global', 'Are you sure you want to set global the selected item(s)?', self.isPdfMarkup))
                    .then(function () {
                        self.manager.loading = true;
                        self[dataURL.method](dataURL, { url: self.setParams(), urltype: 'obj', body: toShare })
                            .then(function (result) {

                                Message.info('The selected files were set global successfully.');
                                for (var i = 0; i < files.length; i++) {
                                    files[i].selected = false;
                                }
                                self.manager.loadPage();
                            })
                            .catch(function (e) {
                                console.error(e);
                                Message.dberror(e);
                                self.manager.loading = false;

                        })
                        .finally(function () {
                            // self.manager.loading = false;
                        });
                })
                .catch(function () {
                    // cancel pressed
                })


        } else {
            Message.info('Please select files');
        }


    }

    Explorer1.prototype.unsetGlobal = function (rows, ev) {
        var self = this;
        var dataURL = self.urlsData.files.unsetGlobalFileURL;
        var toShare = { filesId: [] };
        if (Object.prototype.toString.call(rows) == '[object Object]') {
            rows = [rows];
        }
        var files = [];
        if (rows && rows.length) {
            for (var i = 0; i < rows.length; i++) {
                if (!rows[i].isFolder) {
                    toShare.filesId.push(rows[i].id);
                    files.push(rows[i]);
                } else {
                    rows[i].selected = false;
                }
            }
            if (!toShare.filesId.length) {
                Message.info('Please select files');
                return;
            }

                $mdDialog.show(confirm('Unset global', 'Are you sure you want to unset global the selected item(s)?', self.isPdfMarkup))
                    .then(function () {
                        self.manager.loading = true;
                        self[dataURL.method](dataURL, { url: self.setParams(), urltype: 'obj', body: toShare })
                            .then(function (result) {

                                Message.info('The selected files were unset global successfully.');
                                for (var i = 0; i < files.length; i++) {
                                    files[i].selected = false;
                                }
                                self.manager.loadPage();
                            })
                            .catch(function (e) {
                                console.error(e);
                                Message.dberror(e);
                                self.manager.loading = false;

                        })
                        .finally(function () {
                            //self.manager.loading = false;
                        });
                })
                .catch(function () {
                    // cancel pressed
                })


        } else {
            Message.info('Please select files');
        }


    }

    Explorer1.prototype.downloadFile = function (row) {
        var self = this;
        row.isBusy = true;
        var uri = self.abbreviation == 'FCM'
            ? URI.PROJECT_FILE_MANAGER.GET_FILE_URL
            : URI.MY_CIPO_DRIVE.GET_FILE_URL;

        self[uri.method](`${uri}?versionId=${row.versionId}&isPreview=false`)
            .then(function (r) {
                var link = document.createElement("a");
                link.href = r;
                link.download = row.name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(function (e) {
                Message.dberror(e);
            }).finally(function () {
                row.isBusy = false;

                })
        }

        Explorer1.prototype.downloadArchiveFile = function (row) {
            var self = this;
            row.isBusy = true;
            var uri = URI.CT.GET_ARCHIVE_FILE_URL;

            // Creates a hidden link element that clicks itself to download the provided file            
            self[uri.method](`${uri}?contractId=${row.contractId}&fileName=${row.archiveFileName}`)
                .then(function (r) {
                    var link = document.createElement("a");
                    link.href = r;
                    link.download = row.archiveFileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).catch(function (e) {
                    Message.dberror(e);
                }).finally(function () {
                    row.isBusy = false;
                })
        }


    Explorer1.prototype.setAsUserAvatar = function (row) {
        var self = this;
        var dataURL = self.urlsData.files.setAsUserAvatar;
        self[dataURL.method](dataURL, { url: { fileId: row.id }, urltype: 'obj' })
            .then(function (r) {
                Message.info("Profile image set successfully");
                userService.User();
            })
            .catch(function (e) {
                Message.dberror(e);
            })
    }

    Explorer1.prototype.setAsCompanyLogo = function (row) {
        var self = this;
        var dataURL = self.urlsData.files.setAsCompanyLogo;
        self[dataURL.method](dataURL, { url: { fileId: row.id }, urltype: 'obj' })
            .then(function (r) {
                Message.info("Company logo set successfully");
                userService.Reload_Tenant_Logos();
            })
            .catch(function (e) {
                Message.dberror(e);
            })
    }

    return Explorer1;
});
