import { Component, Input } from '@angular/core';
import { DisplayType } from 'src/app/models/module/fields/enums';
import { FontStyleType, HorizontalAlignType, ScreenFieldFormattingModel, VerticalAlignType } from 'src/app/models/module/fields/main';
import { DataCardViewPart } from '../../../models/module/main';
import {
  DataCardSettingFontColorIndicatorDown,
  DataCardSettingFontColorIndicatorUp,
  DataCardTextSizeToPercent,
} from '../../../models/module/main/dataCardSetting';

@Component({
  selector: 'app-data-card-view-part',
  templateUrl: './data-card-view-part.component.html',
  styleUrls: ['./data-card-view-part.component.scss'],
})
export class DataCardViewPartComponent {
  @Input() part: DataCardViewPart;

  get showLabel(): boolean {
    return this.part.displayTypeId == DisplayType.Text && this.part.field.valueFormatting.size < 100;
  }

  get valueSize(): number {
    return this.showLabel ? this.part.field.valueFormatting.size : 100;
  }

  getFontWeight(partSetting: ScreenFieldFormattingModel): string {
    return this.hasFlag(partSetting.fontStyle, FontStyleType.Bold) ? 'bold' : 'normal';
  }

  getFontStyle(partSetting: ScreenFieldFormattingModel): string {
    return this.hasFlag(partSetting.fontStyle, FontStyleType.Italic) ? 'italic' : 'normal';
  }

  getNumberFontColor(partSetting: ScreenFieldFormattingModel): string {
    if (
      this.part.displayTypeId == DisplayType.IndicatorUp ||
      (this.part.displayTypeId == DisplayType.IndicatorBasedOnValue && this.part.valueNumber > 0) ||
      this.part.displayTypeId == DisplayType.PercentAmountProgress ||
      this.part.displayTypeId == DisplayType.PercentDurationProgress
    ) {
      return DataCardSettingFontColorIndicatorUp;
    }

    if (
      this.part.displayTypeId == DisplayType.IndicatorDown ||
      (this.part.displayTypeId == DisplayType.IndicatorBasedOnValue && this.part.valueNumber < 0)
    ) {
      return DataCardSettingFontColorIndicatorDown;
    }

    return partSetting.fontColor;
  }

  getFontColor(partSetting: ScreenFieldFormattingModel): string {
    if (this.part.isNumber) {
      return this.getNumberFontColor(partSetting);
    }
    return partSetting.fontColor;
  }

  getTextDecoration(partSetting: ScreenFieldFormattingModel): string {
    return this.hasFlag(partSetting.fontStyle, FontStyleType.Underlined) ? 'underline' : 'none';
  }

  getVerticalAlign(partSetting: ScreenFieldFormattingModel): string {
    var horizontalAlign =
      partSetting.alignHorizontal == HorizontalAlignType.Left
        ? 'start'
        : partSetting.alignHorizontal == HorizontalAlignType.Right
        ? 'end'
        : 'center';
    var verticalAlign =
      partSetting.alignVertical == VerticalAlignType.Top
        ? 'start'
        : partSetting.alignVertical == VerticalAlignType.Bottom
        ? 'end'
        : 'center';
    return `${horizontalAlign} ${verticalAlign}`;
  }

  getFontSize(partSetting: ScreenFieldFormattingModel): string {
    return `${DataCardTextSizeToPercent.get(partSetting.fontSize)}%`;
  }

  get numberProgressValue(): number {
    return this.part.valueNumber > 100 ? 100 : this.part.valueNumber < 0 ? 0 : this.part.valueNumber;
  }

  private hasFlag(flags: number, flag: number): boolean {
    return (flags & flag) == flag;
  }
}
