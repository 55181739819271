import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("ContractH", function ($q, Model, Form, Functions, AssignURC, ADJUSTMENTS, Adjustment, AdjustmentH, PaymentPeriod, URI, Dictionaries, userService, Message, YEAR) {

    var ContractH = Model.extend(function (id, operations) {
        var self = this;
        self.operations = operations;

        self.adjustmentsTypes = ADJUSTMENTS.types;
        self.adjustmentsTerm = ADJUSTMENTS.term;

        self.messages = {
            cantAddAssignments: "This contract is closed. You can't assign/unassign roles",
            addBtn: 'Assign role/user(s)',
            noPivotItems: 'All available roles are already assigned',
            noSecondaryItems: 'All available users are already assigned',
            noItems: 'All available roles and users are already assigned',
            title: 'Assigned Roles',
            pivotLabel: 'Select Role',
            secondaryLabel: 'Select User(s)'
        };
        self.therecanbeonlyone = true;
        this.type = 3;
        this.id = id || null;

        this.properties = {
            id: this.id,
            

        };

        this.init_details();
        var self = this;

        Object.defineProperty(self, "periodMessage", {
            get: function () {
                var periodMessage = "";
                var errorMsg = [];
                if (!self.details.endDate || !self.details.startDate || !self.details.financialScheduleId || !self.details.shiftScheduleId) {
                    periodMessage = "Cannot generate periods. The contract is missing ";
                    if (!self.details.startDate) errorMsg.push("a start date");
                    if (!self.details.endDate) errorMsg.push("an end date");
                    if (!self.details.shiftScheduleId) errorMsg.push("a shift schedule");
                    if (!self.details.financialScheduleId) errorMsg.push("a financial schedule");

                    for (var i = 0; i < errorMsg.length; i++) {
                        periodMessage += errorMsg[i];
                        if (i == errorMsg.length - 1) periodMessage += " to generate payment periods.";
                        else {
                            if (i == errorMsg.length - 2) periodMessage += " and ";
                            else periodMessage += ", ";
                        }
                    }
                }

                return periodMessage;
            }
        })


        self.sortableOptions = {
            
            handle: '> span>.listHandle',
            'ui-floating': true
        };
        self.year = YEAR;

        if (self.id) {
            // self.get_contractId();
            // self.get_assignments();
            
        }
        self.userService = userService;

        self.presetModulesList = [];
        self.assignmentsList = [];
        self.backupAssignmentsList = [];
        self.lookupRoles = {};
        self.isOverviewLoaded = false;
        self.detailedSummaries = [];
        self.details = {};
        //Object.defineProperty(self, 'dirtyAssignments', {
        //    get: function () { return !angular.equals(self.assignmentsList, self.backupAssignmentsList); }
        //});
        self.addZ = function(date) {
            var ret = date || null;
            if (date && date.indexOf("Z") == -1)
                ret = date + "Z";
            return ret;
        }

        self.formatDate = function (date) {
            return moment(date).format(userService.formats.date);
        }
        
        Object.defineProperty(self, 'isDirtyModules', {
            get: function () {
                var isDirty = false;
                for (var i = 0; i < self.presetModulesList.length; i++) {
                    if (self.presetModulesList[i].isDirty) {
                        isDirty = true;
                        break;
                    }

                }
                return isDirty;
            }
        });

        Object.defineProperty(self, 'isDirtyAssignments', {
            get: function () {
                var isDirty = false;
                for (var i = 0; i < self.assignmentsList.length; i++) {
                    if (self.assignmentsList[i].isDelete || self.assignmentsList[i].isChanged) {
                        isDirty = true;
                        break;
                    }

                }
                return isDirty;
            }
        });

        self.periodsList = [];

        Object.defineProperty(self, 'selectedPeriodsNo', {
        get: function () {
            var ret = 0;
            for (var i = 0; i < self.periodsList.length; i++) {
                if (self.periodsList[i].isSelected) { ret++; }
            }
            return ret;
        }
    })

    });


    // assignments

    ContractH.prototype.init_assignments = function () {
        var self = this;
        Dictionaries.AllRoles()
            .then(function (r) {
                self.rolesDict = r;
                for (var i = 0; i < self.rolesDict.length; i++) {
                    self.lookupRoles[self.rolesDict[i].key] = self.rolesDict[i];
                }
                self.get_assignments();
            })
            .catch(function (e) {

            })
        //Dictionaries.recurringAdjustmantTypes(), Dictionaries.onetimeAdjustmentTypes(), 
        

        self.loadingAssignments = true;
    }

    ContractH.prototype.get_assignments = function () {
        var self = this;
        var p = $q.defer();

        Dictionaries.Users({ contractId: self.properties.id, getDisabled: false })
            .then(function (r) {

                self.assignmentsDict = r;


                self.isAssignmentsDictLoaded = true;
                self.get_assignmentsList();
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            });

        return p.promise;
    }

    ContractH.prototype.revertAssignmentsChanges = function () {
        var self = this;
        self.assignmentsList = angular.copy(self.backupAssignmentsList);
    }

    ContractH.prototype.updateRoleLine = function (item, isEdit) {
        var self = this;
        item.rolesLine = "";
        var rolesLine = [];
        if ((item.roleIds || []).length) {
            
            if (isEdit) {
                item.roles = [];
                for (var j = 0; j < item.roleIds.length; j++) {
                    
                    if (self.lookupRoles[item.roleIds[j]]) {
                        var role = angular.copy(self.lookupRoles[item.roleIds[j]]);
                        role.order = j + 1;
                        rolesLine.push(role.value);
                        item.roles.push(role);
                    }
                    

                    // item.rolesLine += self.lookupRoles[item.roleIds[j]] ? self.lookupRoles[item.roleIds[j]].value + ', ' : "";
                }
            } else {
                item.backupRoleIds = angular.copy(item.roleIds);
                for (var j = 0; j < item.roles.length; j++) {
                    rolesLine.push(item.roles[j].value);
                    // item.rolesLine += self.lookupRoles[item.roleIds[j]] ? self.lookupRoles[item.roleIds[j]].value + ', ' : "";
                }
            }
            
        }

        if (rolesLine.length) {
            // rolesLine.sort();
            item.rolesLine = rolesLine.join(', ');
        }

        // if (item.rolesLine) item.rolesLine = item.rolesLine.substring(0, item.rolesLine.length - 2);

        return item;
    };

        ContractH.prototype.updateAssignment = function (item) {
            item = this.updateRoleLine(item, true);
            if (!angular.equals(item.roleIds.sort(), item.backupRoleIds.sort())) {
                item.isChanged = true;
                // this.hasDirtyAssignment = true;
            }
        }

    ContractH.prototype.openPrimaryDialog = function () {
        var self = this;
        self.isShowingPrimary = true;
        self.isBusy = true;
        self.get_primaries();
        
    }

    ContractH.prototype.cancelPrimaryDialog = function () {
        var self = this;
        self.isShowingPrimary = false;
        self.isBusy = false;
        // self.rolesList = angular.copy(self.rolesListBkp);
    }

    ContractH.prototype.get_primaries = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.ROLES.GET_CONTRACT_PRIMARIES;
        self.isPrimariesLoaded = false;

        self[urlData.method](urlData, { url: { contractId: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                
                self.rolesList = r;
                // self.rolesListBkp = angular.copy(self.rolesList);

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {

                self.isPrimariesLoaded = true;
            });

        return p.promise;
    }

    ContractH.prototype.sync_primaries = function () {
        var self = this;
        var p = $q.defer();
        var validRoles = true;
        var urlData = URI.ROLES.SYNC_CONTRACT_PRIMARIES;
        self.isSavingPrimaries = true;
        self[urlData.method](urlData, { body: { primaries: self.rolesList, contractId: self.properties.id} })
                .then(function (r) {
                    Message.info('Roles updated successfully.');
                    self.cancelPrimaryDialog();
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject(e);
                    Message.dberror(e);
                })
                .finally(function () {
                    self.isSavingPrimaries = false;
                });

        return p.promise;
    }

    ContractH.prototype.order_roles = function (user) {
        var self = this;
        var p = $q.defer();
        var urlData = URI.CT.SYNC_ROLE_POSITIONS;
        var data = angular.copy(self.rolesList);
        for (var i = 0; i < user.roles.length; i++) {
            user.roles[i].order = i + 1;
        }
        user.isBusy = true;
        self[urlData.method](urlData, { body: { roles: user.roles, contractId: self.properties.id, userId: user.userId } })
            .then(function (r) {
                user.isOrderMode = false;
                user = self.updateRoleLine(user);
                Message.info('Roles updated successfully.');
                self.backupAssignmentsList = angular.copy(self.assignmentsList);
                self.isBusy = false;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                user.isBusy = false;
            });

        return p.promise;
    }

    ContractH.prototype.openAssignmentDialog = function () {
        var self = this;
        self.isShowingRoles = !self.isShowingRoles;
        self.isBusy = true;
        self.assigmentsDictBkp = angular.copy(self.assignmentsDict);
    }

    ContractH.prototype.cancelAssignmentDialog = function () {
        var self = this;
        self.isShowingRoles = !self.isShowingRoles;
        self.isBusy = false;
        self.assignmentsDict = angular.copy(self.assigmentsDictBkp);
    }

    ContractH.prototype.changePrimary = function (item) {
        var self = this;
        if (self.primaryUserId) self.assignmentsListLookup[self.primaryUserId].isPrimary = false;
        self.primaryUserId = item.userId;
    }

    ContractH.prototype.get_assignmentsList = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.ROLES.GET_USERS;
        self.isAssignmentsLoaded = false;
        self.assignmentsListLookup = {};
        self[urlData.method](urlData, { url: { contractId: self.properties.id }, urltype: 'obj' })
            .then(function (r) {

                for (var i = 0; i < r.data.length; i++) {
                    r.data[i].isDelete = false;
                    r.data[i].isChanged = false;
                    r.data[i] = self.updateRoleLine(r.data[i]);
                    if (r.data[i].isPrimary) self.primaryUserId = r.data[i].userId;
                    self.assignmentsListLookup[r.data[i].userId] = r.data[i];
                }
                self.assignmentsList = r.data;

                


                self.backupAssignmentsList = angular.copy(self.assignmentsList);

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {

                self.isAssignmentsLoaded = true;
            });

        return p.promise;
    }

    ContractH.prototype.sync_assignments = function () {
        var self = this;
        var p = $q.defer();
        self.isSavingAssignments = true;

        var urlData = URI.ROLES.ASSIGN_USERS_TO_CONTRACT;
        var toSend = [];
        for (var i = 0; i < self.assignmentsDict.length; i++) {
            if (self.assignmentsDict[i].isUsed) toSend.push(self.assignmentsDict[i].key);
        }
        self[urlData.method](urlData, { url: {}, body: { id: self.properties.id, userIds: toSend }, urltype: 'obj' })
            .then(function (r) {
                self.isShowingRoles = false;
                self.get_assignmentsList();
                userService.Contracts();
                Message.info('Assignments saved successfully.');
                self.isBusy = false;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.isSavingAssignments = false;

            });

        return p.promise;
    }

    ContractH.prototype.save_assignments = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlData = URI.ROLES.SYNC_ROLES_TO_CONTRACT;
        var toSend = { id: self.properties.id, users: [] };
        for (var i = 0; i < self.assignmentsList.length; i++) {
            if (self.assignmentsList[i].roleIds.length && !self.assignmentsList[i].isDelete)
                toSend.users.push({ userId: self.assignmentsList[i].userId, roles: self.assignmentsList[i].roles });
        }
        self[urlData.method](urlData, { url: {}, body: toSend, urltype: 'obj' })
            .then(function (r) {
                self.isAssignmentsDictLoaded = false;
                // self.hasDirtyAssignment = false;
                self.backupAssignmentsList = angular.copy(self.assignmentsList);
                self.get_assignments();
                userService.Contracts();
                Message.info('Assignments saved successfully.');
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
                Message.dberror(e);
            })
            .finally(function () {

                self.isBusy = false;
            });

        return p.promise;

    }

    // new adjustments

    ContractH.prototype.getAdjustments = function () {
        var self = this;
        var p = $q.defer();
        //self.isAdjustmentLoaded = false;
        var urlData = URI.ADJUSTMENTS.SEARCH;
        self[urlData.method](urlData, { url: { entityInstanceId: self.properties.id }, urltype: "obj" })
            .then(function (r) {
                //self.adjustmentList = [];
                //if ((r || {}).data.length) {
                //    for (var i = 0; i < r.data.length; i++) {
                //        self.adjustmentList.push(self.new_Adjustment(r.data[i]));
                //    }
                //}

                p.resolve();
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject();
            }).finally(function () {
                //self.isAdjustmentLoaded = true;
                self.isBusy = false;
            });

        return p.promise;
    }

    ContractH.prototype.addAdjustment = function (obj) {
        var self = this;
        self.newAdjustment = new AdjustmentH(obj, self.AccrualFrequencyDict, self.AdjustmentCategoryDict, self);
    }

    // adjustments

    ContractH.prototype.init_adjustments = function () {
        var self = this;
        var all = $q.all([Dictionaries.adjustmentCategories(), Dictionaries.Financials(), Dictionaries.accrualFrequencies()]);
        all
            .then(function (r) {

                //self.OTAdict = r[1];
                //self.RAdict = r[0];
                self.AdjustmentCategoryDict = r[0];
                self.FinancialScheduleDict = r[1];
                self.AccrualFrequencyDict = r[2];
                //self.get_rAdjustmants();
                // self.get_otAdjustments();
                self.get_adjustments();
                // self.getAdjustments();
                // self.get_periods();
                // self.get_details();
            })
            .catch(function (e) {

            })
    }

    ContractH.prototype.get_adjustments = function () {
        var self = this;
        var p = $q.defer();
        self.isAdjustmentLoaded = false;
        var urlData = URI.ADJUSTMENTS.SEARCH;
        self[urlData.method](urlData, { url: { entityInstanceId: self.properties.id }, urltype: "obj" })
            .then(function (r) {
                self.adjustmentList = [];
                if ((r || {}).data.length) {
                    for (var i = 0; i < r.data.length; i++) {
                        self.adjustmentList.push(self.new_Adjustment(r.data[i]));
                    }
                }

                p.resolve();
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject();
            }).finally(function () {
                self.isAdjustmentLoaded = true;
                self.isBusy = false;
            });

        return p.promise;
    }

    ContractH.prototype.refresh_Adjustment = function (adjustment) {
        var self = this;
        var p = $q.defer();
        // self.loadingAssignments = true;
        self.isAdjustmentLoaded = false;
        var urlData = URI.ADJUSTMENTS.GET;
        self[urlData.method](urlData, { url: { id: adjustment.properties.id, contractId: self.properties.id }, urltype: "obj" })
            .then(function (r) {
                if (r && r.id) {
                    var i = self.adjustmentList.indexOf(adjustment);
                    self.adjustmentList[i] = self.new_Adjustment(r);
                    self.adjustmentList[i].listExpanded = adjustment.listExpanded;
                }

                p.resolve();
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject();
            }).finally(function () {
                self.isAdjustmentLoaded = true;
                self.isBusy = false;
            });

        return p.promise;
    }

    
    ContractH.prototype.new_Adjustment = function (data) {
        var self = this;
        if (!data.id) data = { type: data.key, typeName: data.value, entityInstanceId: self.properties.id };
        return new Adjustment(data, self.AccrualFrequencyDict, self.AdjustmentCategoryDict, self);
    }

    

    ContractH.prototype.adjustment_Action = function (item, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting adjustment..." : "Saving adjustment...";
        var action = isDelete ? item.delete() : item.save();
        action
            .then(function () {
                if (isDelete) {
                    self.adjustmentList.splice(self.adjustmentList.indexOf(item), 1);
                } else delete item.form;
                self.get_adjustments();


                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    ContractH.prototype.get_periods = function () {
        var self = this;
        var p = $q.defer();
        // self.loadingAssignments = true;
        
        self.isPeriodsLoaded = false;
        var urlData = URI.PROGRESS_PAYMENT.PPD_SEARCH;
        self[urlData.method](urlData, { url: { contractId: self.properties.id }, urltype: "obj" })
            .then(function (r) {
                self.periodsList = [];
                self.periodsObjList = [];
                if (r && r.data && r.data.length) {
                    for (var i = 0; i < r.data.length; i++) {
                        self.periodsObjList.push(new PaymentPeriod(angular.copy(r.data[i]), self.properties.id));
                        r.data[i].isSelected = false;
                        r.data[i].endDate = process_incomingDate(r.data[i].endDate).format(self.userService.formats.date);
                        r.data[i].startDate = process_incomingDate(r.data[i].startDate).format(self.userService.formats.date);
                    }

                        self.startPadding = parseInt(process_incomingDate(r.data[0].startDate).format('D')) - 1;
                        self.endPadding = self.year[process_incomingDate(r.data[r.data.length - 1].endDate).format('M')].days - parseInt(process_incomingDate(r.data[r.data.length - 1].endDate).format('D'));
                        self.periodsList = r.data;
                    }

                    if (self.periodsList.length)
                        self.calculateRenderData();

                    p.resolve();

                }).catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                    p.reject();
                }).finally(function () {
                    self.isPeriodsLoaded = true;
                    if (!self.isEditingPeriods) self.isBusy = false;
                });

            return p.promise;
        }

        ContractH.prototype.cancelJoinPeriods = function () {
            var self = this;
            self.startJoinIndex = null;
            self.isEditingPeriods = false;
            self.isBusy = false;
            for (var i = 0; i < self.periodsList.length; i++) {
                self.periodsList[i].isSelected = false;
            }
        }

    ContractH.prototype.saveJoinPeriods = function () {
        var self = this;
        
        self.isSavingPeriods = true;
        var ids = [];
        //self.isBusy = false;
        for (var i = 0; i < self.periodsList.length; i++) {
            if (self.periodsList[i].isSelected) ids.push(self.periodsList[i].id);
        }
        var urlData = URI.PROGRESS_PAYMENT.COMBINE_PERIODS;
        self[urlData.method](urlData, { url: { entityInstanceId: self.properties.id }, urltype: "obj", body: ids })
            .then(function (r) {
                //self.startJoinIndex = null;
                //self.isEditingPeriods = false;
                self.isModified = true;
                self.get_periods();
                //p.resolve();
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                //p.reject();
            }).finally(function () {
                self.isSavingPeriods = false;
                // self.isBusy = false;
            });
    }

    ContractH.prototype.splitPeriod = function (p) {
        var self = this;
        p.isBusy = true;
        self.isSavingPeriods = true;
        
        var urlData = URI.PROGRESS_PAYMENT.REVERT_COMBINE_PERIODS;
        self[urlData.method](urlData, { url: { id: p.id }, urltype: "obj" })
            .then(function (r) {
                self.isModified = true;
                self.get_periods();
                //p.resolve();
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                //p.reject();
            }).finally(function () {
                self.isSavingPeriods = false;
                // self.isBusy = false;
            });
    }
    ContractH.prototype.selectPeriods = function (p) {
        var self = this;
        var currentIndex = self.periodsList.indexOf(p);

        if (!self.selectedPeriodsNo) {
            self.startJoinIndex = currentIndex;
            p.isSelected = true;
            return;
        }

        if (!p.isSelected) {
            p.isSelected = true;

            if (currentIndex < self.startJoinIndex) {
                for (var i = self.startJoinIndex; i > currentIndex ; i--) {
                    /*if (!self.periodsList[i].isJoined) self.periodsList[i].isSelected = true;
                    else {
                        p.isSelected = false;
                        Message.warning('Joined periods need to be split before they can be part of another joined period.');
                        break;
                    }*/
                    self.periodsList[i].isSelected = true;
                }
            } else {
                for (var i = self.startJoinIndex; i < currentIndex; i++ ) {
                    /*if (!self.periodsList[i].isJoined) self.periodsList[i].isSelected = true;
                    else {
                        p.isSelected = false;
                        Message.warning('Joined periods need to be split before they can be part of another joined period.');
                        break;
                    }*/
                    self.periodsList[i].isSelected = true;
                }
            }

            //for (var i = (currentIndex < self.startJoinIndex ? currentIndex : self.startJoinIndex); i < (currentIndex < self.startJoinIndex ? self.startJoinIndex : currentIndex); i++) {
            //    if (!self.periodsList[i].isJoined) self.periodsList[i].isSelected = true;
            //    else {
            //        p.isSelected = false;
            //        Message.warning('Joined periods need to be split before they can be part of another joined period.');
            //        break;
            //    }
            //}
            return;
        }

        if (p.isSelected) {
            if (self.startJoinIndex == currentIndex) {
                if (self.selectedPeriodsNo == 1) {
                    p.isSelected = false;
                    self.startJoinIndex = null;
                    return;
                }
                else {
                    self.startJoinIndex = (self.periodsList[currentIndex + 1] || {}).isSelected ? currentIndex + 1 : currentIndex - 1;
                    self.selectPeriods(p);
                    return;
                }
            } else {
                for (var i = (currentIndex < self.startJoinIndex ? 0 : currentIndex); i < (currentIndex < self.startJoinIndex ? currentIndex + 1 : self.periodsList.length); i++) {
                    self.periodsList[i].isSelected = false;
                }
                return;
            }
        }
        



    }

    ContractH.prototype.calculateRenderData = function () {
        var self = this;
        var duration = Math.ceil(process_incomingDate(self.periodsList[self.periodsList.length - 1].endDate).diff(process_incomingDate(self.periodsList[0].startDate), 'months', true));
        var startMonth = parseInt(process_incomingDate(self.periodsList[0].startDate).format('M'));
        var startYear = parseInt(process_incomingDate(self.periodsList[0].startDate).format('YYYY'));
        var periodMonths = [];
        var periodYears = [];
        var yearDays = 0;
        var month = 0;
        var currentYear = startYear;

        for (var i = startMonth; i < (startMonth + duration); i++) {
            // the actual no of the month
            var index = calculateMonth(i);
            month = angular.copy(self.year[index]);
            month.days = currentYear % 4 === 0 && index == 2 ? month.days + 1 : month.days;

            periodMonths.push(month);


            if (index == 1 && i != startMonth) {

                currentYear = currentYear + 1;
                periodYears.push(yearDays);
                yearDays = 0;
                yearDays = month.days;
            } else {
                yearDays += month.days;
            }
        }

        periodYears.push(yearDays);

            self.startYear = startYear;
            self.periodMonths = periodMonths;
            self.periodYears = periodYears;
        }

    ContractH.prototype.generatePeriods = function () {
        var self = this;
        var p = $q.defer();
        // self.loadingAssignments = true;
        self.isBusy = true;
        var urlData = URI.PROGRESS_PAYMENT.RECREATE_PAYMENT_PERIODS;
        self[urlData.method](urlData, { url: { contractId: self.properties.id }, urltype: "obj" })
            .then(function (r) {
                self.get_periods();
                p.resolve();
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject();
            }).finally(function () {
                self.isBusy = false;
            });

        return p.promise;
    }

    var process_incomingDate = function (date) {
        if (date) {
            if (date.indexOf('Z') == -1) {
                date = date + 'Z';
            }
            var momentDate = moment(date).utc();
            momentDate.set({ h: 12, m: 0 });
            return momentDate;
        }
        else return null;
    }

    var calculateMonth = function (i) {
        var index = i;
        if (index <= 12) return index;
        else {
            index = index - 12;
            return calculateMonth(index);
        }
    }

    ContractH.prototype.get_detailedSummaries = function (module) {
        var self = this;
        var deferred = $q.defer();
        var uri = URI.CT.MODULE_OVERVIEW_DETAILS;
        module.isBusy = true;
        self[uri.method](uri, { url: { entityInstanceId: self.properties.id, moduleId: module.id || 0 }, urltype: 'obj' })
            .then(function (res) {
                module.aggregates = res.aggregates || [];
                if (module.aggregates.length) {
                    for (var i = 0; i < module.aggregates.length; i++) {
                        if (module.aggregates[i].totals && module.aggregates[i].totals.length) {
                            module.aggregates[i].totalsLookup = {};
                            for (var j = 0; j < module.aggregates[i].totals.length; j++) {
                                module.aggregates[i].totalsLookup[module.aggregates[i].totals[j].key] = module.aggregates[i].totals[j].value;
                            }
                        }
                    }
                }
                module.summary = res.summary || [];
                module.turnAroundDays = res.turnAroundDays || [];
                module.turnAroundDaysColumns = res.turnAroundDaysColumns || [];

                deferred.resolve();
            })
            .catch(function (err) {
                console.error(err);
                Message.dberror(err);
                deferred.reject();
            })
            .finally(function () {
                module.isBusy = false;
            });
    }

    ContractH.prototype.get_overview = function() {
        var self = this;
        var deferred = $q.defer();
        self.overview = {};
        self.overview.allModulesSummariesExpanded = false;
        self.isOverviewLoaded = false;
        self.isBusy = true;
        var uri = URI.CT.CONTRACT_OVERVIEW;
        self[uri.method](uri, { url: { entityInstanceId: self.properties.id }, urltype: 'obj'})
            .then(function (res) {
                self.overview = res;
                self.overview.noticeToProceedDate = self.overview.noticeToProceedDate ? self.formatDate(self.overview.noticeToProceedDate) : "-";
                self.overview.revisedProjectionCompletionDate = self.overview.revisedProjectionCompletionDate ? self.formatDate(self.overview.revisedProjectionCompletionDate) : "-";
                self.overview.paidThroughDate = self.overview.paidThroughDate ? self.formatDate(self.overview.paidThroughDate) : "-";
                self.charts = {
                    changeOrderPercentage: {
                        title: Math.round((self.overview.changeOrderPercentage + Number.EPSILON) * 100) / 100 + '%',
                        data: [
                            ['Change Order', self.overview.changeOrderPercentage],
                            ['Contract', (100.0 - self.overview.changeOrderPercentage)]
                        ]
                    }
                }

                self.overview.detailedSummaries = self.overview.detailedSummaries || [];
                if (self.overview.detailedSummaries.length) {
                    for (var i = 0; i < self.overview.detailedSummaries.length; i++) {
                        self.overview.detailedSummaries[i].aggregates = [];
                        self.overview.detailedSummaries[i].summary = [];
                        self.overview.detailedSummaries[i].turnAroundDays = [];
                        self.overview.detailedSummaries[i].turnAroundDaysColumns = [];
                        self.overview.detailedSummaries[i].expanded = true;
                        self.overview.detailedSummaries[i].isBusy = false;

                        self.get_detailedSummaries(self.overview.detailedSummaries[i]);
                    }
                } else {
                    self.overview.allModulesSummariesExpanded = true;
                }

                deferred.resolve();
            })
            .catch(function (err) {
                console.error(err);
                Message.dberror(err);
                deferred.reject();
            })
            .finally(function() {
                self.isOverviewLoaded = true;
                self.isBusy = false;
            });
        return deferred.promise;
    }
    
    ContractH.prototype.init_details = function () {
        this.details = {
            startDate: null,
            endDate: null,
            shiftScheduleId: null,
            financialScheduleId: null,
        }
    }

    ContractH.prototype.get_details = function () {
        var self = this;
        var p = $q.defer();
        // self.loadingAssignments = true;
        self.isDetailsLoaded = false;
        var urlData = URI.CT.CONTRACT_DETAILS;
        self[urlData.method](urlData, { url: { entityInstanceId: self.properties.id }, urltype: "obj" })
            .then(function (r) {
                self.init_details();
                for (var key in r) {
                    if (r.hasOwnProperty(key)) {
                        if (key == 'startDate' || key == 'endDate') {
                            r[key] = process_incomingDate(r[key]);
                        }
                        self.details[key] = r[key];
                    }
                }
                
                self.properties.name = self.properties.contractName;
                p.resolve(r);
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject();
            }).finally(function () {
                self.isDetailsLoaded = true;
                self.isBusy = false;
            });

        return p.promise;
    }

    ContractH.prototype.new_Period = function () {
        var self = this;
        return new PaymentPeriod(null, self.properties.id);
    }

    ContractH.prototype.period_Action = function (item, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting period..." : "Saving period...";
        var action = isDelete ? item.delete() : item.save();
        action
            .then(function () {
                if (isDelete) {
                    self.periodsObjList.splice(self.periodsObjList.indexOf(item), 1);
                } else delete item.form;
                self.get_periods();

                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    p.reject();
                })
                .finally(function () {
                    self.message = "";
                })
            return p.promise;
        }

    ContractH.prototype.duplicate = function (paramsObject) {
        var self = this;
        var p = $q.defer();

        self[URI.CT.DUPLICATE.method](URI.CT.DUPLICATE, { url: paramsObject, urltype: 'obj' })
            .then(function (newContractId) {
                p.resolve(newContractId);
            })
            .catch(function(err) {
                p.reject(err);
            });

        return p.promise;
    }

    ContractH.prototype.init_presets = function () {
        var self = this;
        // var p = $q.defer();
        self.getPresetModulesList();

        // return p.promise;
    }
    ContractH.prototype.getPresetModulesList = function () {
        var self = this;
        var p = $q.defer();
        self.isPresetModulesLoaded = false;
        var dataURL = URI.MODULE_PRESETS.ALL_MODULES_DICT;
        self[dataURL.method](dataURL, { url: { contractId: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                if ((r || []).length) {
                    for (var i = 0; i < r.length; i++) {
                        r[i].presets = [];

                        (function (j) {
                            Object.defineProperty(r[j], "isDirty", {
                                get: function () {
                                    var isDirty = false;
                                    for (var k = 0; k < r[j].presets.length; k++) {
                                        if (r[j].presets[k].isDirty) {
                                            isDirty = true;
                                            break;
                                        }
                                            
                                    }
                                    return isDirty;
                                }
                            })

                        })(i);
                    }
                }
                self.presetModulesList = r;
                self.isPresetModulesLoaded = true;
                p.resolve();
            })
            .catch(function(e) {
                p.reject(e);
                Message.dberror(e);
            });

        return p.promise;
    }

    ContractH.prototype.getPresets = function (module) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE_PRESETS.EICONTRACT_ALLOCATION_DICT;
        self[dataURL.method](dataURL, { url: { contractId: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: module.key } })
            .then(function (r) {                    
                module.presets = r;
                module.isPresetsLoaded = true;
                p.resolve();
            })
            .catch(function(e) {
                p.reject(e);
                Message.dberror(e);
            });

        return p.promise;
    }

    ContractH.prototype.toggleAllPresets = function (module, isSelect) {
        for (var i = 0; i < module.presets.length; i++) {
            if (!module.presets[i].isPicked) {
                if (isSelect) {
                    if (!module.presets[i].isUsed) {
                        module.presets[i].isUsed = true;
                        module.presets[i].isDirty = !module.presets[i].isDirty;
                    }

                } else {
                    if (module.presets[i].isUsed) {
                        module.presets[i].isUsed = false;
                        module.presets[i].isDirty = !module.presets[i].isDirty;
                    }

                }
            }

        }
    }

    ContractH.prototype.syncPresets = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE_PRESETS.SYNC_ON_EI;
        var savePresets = function (module, index) {
            module.isBusy = true;
            self[dataURL.method](dataURL, { url: { contractId: self.properties.id }, urltype: 'obj', body: module.presets },
                { headers: { moduleId: module.key } })
                .then(function (r) {
                    module.isBusy = false;
                    for (var k = 0; k < module.presets.length; k++) module.presets[k].isDirty = false;
                    Message.info('The presets on ' + module.value + " saved successfully.");
                    if (index < self.presetModulesList.length) {
                        for (var i = index; i < self.presetModulesList.length; i++) {
                            if (self.presetModulesList[i].isDirty) {
                                savePresets(self.presetModulesList[i], i);
                                break;
                            }
                        }
                    }
                    p.resolve();
                })
                .catch(function (e) {
                    p.reject(e);
                    Message.dberror(e);
                })
                .finally(function () {
                    module.isBusy = false;
                });
        }



        for (var i = 0; i < self.presetModulesList.length; i++) {
            if (self.presetModulesList[i].isDirty) {
                savePresets(self.presetModulesList[i], i);
                break;
                //(function (j) {
                //    self.presetModulesList[j].isBusy = true;
                //    self[dataURL.method](dataURL, { url: { contractId: self.properties.id }, urltype: 'obj', body: self.presetModulesList[j].presets },
                //        { headers: { moduleId: self.presetModulesList[j].key } })
                //        .then(function (r) {
                //            self.presetModulesList[j].isBusy = false;
                //            for (var k = 0; k < self.presetModulesList[j].presets.length; k++) self.presetModulesList[j].presets[k].isDirty = false;
                //            Message.info('The presets on ' + self.presetModulesList[j].value + " saved successfully.")
                //            p.resolve();
                //        })
                //        .catch(function (e) {
                //            p.reject(e);
                //            Message.dberror(e);
                //        })
                //        .finally(function () {
                //            self.presetModulesList[j].isBusy = false;
                //        });

                //})(i);
            }
        }

        

        return p.promise;
    }




    return ContractH;

});
