import { cipo } from 'cipo';

cipo.factory('validationService', ['Message',
        function (Message) {
            var validationService = {};

            validationService.validateExpression = function (formula, skipLabel) {

                // Check if we have any formula
                if (!formula)
                    return [null, null];

                var validFormula = true;
                if (!formula.label && !skipLabel) {
                    formula.errors.label = "This field is required";
                    validFormula = false;
                }
                for (var i = 0; i < formula.formula.length; i++) {
                    if (formula.formula[i].type == 'function')
                        if (!formula.formula[i].field) {
                            formula.formula[i].hasError = true;
                            validFormula = false;
                        }
                    if (formula.formula[i].type == 'number')
                        if (!formula.formula[i].value) {
                            formula.formula[i].hasError = true;
                            validFormula = false;
                        }
                }
                if (formula.formula.length > 0) {
                    if (formula.formula[formula.formula.length - 1].type == "operator") {
                        formula.formula[formula.formula.length - 1].hasError = true;
                        validFormula = false;
                        Message.error("The formula can't end with an operator");
                    }
                }

                if (validFormula) {
                    var expression = "", formattings = [], expressionFields = [];
                    for (var i = 0; i < formula.formula.length; i++) {
                        if (formula.formula[i].type == 'function') {
                            expression += formula.formula[i].value;
                            expression += '({{';
                            expression += formula.formula[i].field.key;
                            expression += '}})';

                            expressionFields.push(formula.numericFields.find(n => n.key == formula.formula[i].field.key));
                        } else {
                            expression += formula.formula[i].value;
                        }
                    }

                    for (var i = 0; i < formula.formattingsList.length; i++) {
                        formattings.push({
                            key: formula.formattingsList[i].key,
                            value: formula.formattingsList[i].value
                        })
                    }

                    return [expression, formattings, expressionFields];
                }
            };

            return validationService;
    }]);