import { cipo } from 'cipo';

cipo.factory("AttachmentHandler", function (Model, URI, $window, PSPDFKIT_CONFIG, $mdDialog, $http, Message, FileSaver) {
    //constructor extending Model
    var AttachmentHandler = Model.extend(function (obj) {
        var self = this;
        obj = obj || {};
        self.isLoading = obj.isLoading || null;

        // Private functions
        self.view = function (attachment) {
            self.isLoading(true);
            self[URI.MY_CIPO_DRIVE.GET_FILE_URL.method](`${URI.MY_CIPO_DRIVE.GET_FILE_URL}?versionId=${attachment.versionId}&isPreview=false`)
                .then(function (url) {
                    self.isLoading(false);
                    $mdDialog.show({
                        locals: {
                            module: null,
                            contractId: 0,
                            documentId: null,
                            title: attachment.name,
                            fileUrl: url,
                            fileId: attachment.id,
                            mimeType: attachment.mimeType
                        },
                        controller: 'pspdfkitViewerController',
                        templateUrl: '/ng/views/admin/modals/pspdfkitViewer.html',
                        parent: angular.element(document.body),
                        // targetEvent: event,
                        fullscreen: true,
                        escapeToClose: false,
                        clickOutsideToClose: false,
                        disableParentScroll: true,
                        multiple: true
                    })
                        .then(function (result) {

                        }, function () {
                        });

                    // If using inline file manager, update z-index of new dialog so it's on top
                    if ($('.inlineManagerWrapper').length) {
                        setTimeout(function () {
                            $('#pspdfkitViewerDialog').closest('.md-dialog-container').css('z-index', '100');
                        }, 200);
                    }
                })
                .catch(function (err) {
                    console.error(err);
                    self.isLoading(false);
                });
        }

        self.viewAttachments = function (attachment, list) {
            $mdDialog.show({
                locals: {
                    attachment: attachment,
                    list: list || [],
                },
                controller: 'pspdfkitAttachmentViewerController',
                templateUrl: '/ng/views/admin/modals/pspdfkitAttachmentViewer.html',
                parent: angular.element(document.body),
                // targetEvent: event,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false,
                disableParentScroll: true,
                multiple: true
            })
                .then(function (result) {

                }, function () {
                });

            // If using inline file manager, update z-index of new dialog so it's on top
            if ($('.inlineManagerWrapper').length) {
                setTimeout(function () {
                    $('#pspdfkitViewerDialog').closest('.md-dialog-container').css('z-index', '100');
                }, 200);
            }
        }

        self.preview = function (attachment) {
            self.isLoading(true);
            var uri = URI.MY_CIPO_DRIVE.GET_FILE_URL;
            self[uri.method](`${uri}?fileId=${attachment.id}&versionId=${attachment.versionId}&isPreview=true`)
                .then(function (url) {
                    self.isLoading(false);
                    $window.open(url, "_blank");
                });
        }
        // End private functions
    });

    // Public functions
    AttachmentHandler.prototype.open = function (attachment) {
        var self = this;
        PSPDFKIT_CONFIG.MIME_TYPES.includes(attachment.mimeType)
            ? self.view(attachment)
            : self.preview(attachment);
    }

    AttachmentHandler.prototype.openAttachment = function (attachment, list) {
        var self = this;
        self.viewAttachments(attachment, list);
    }

    AttachmentHandler.prototype.openInNewTab = function (attachment) {
        var self = this;
        self.preview(attachment);
    }

    AttachmentHandler.prototype.save = function (attachment) {
        var self = this;
        self.isLoading(true);

        if (attachment.length > 104857600) {

            var dataURL = URI.MY_CIPO_DRIVE.DOWNLOAD_ZIP_FILE;
            var toDownload = { filesId: [], foldersId: [], currentFolderId: attachment.parentId };
            toDownload.filesId.push(attachment.id);

            $http[dataURL.method](dataURL.toString(), toDownload, { responseType: "arraybuffer" })
                .then(function (result) {
                    if (result.status && result.status == 202) {
                        Message.stickyWarning("Download process has started and is running in the background. You will receive an email once it is completed");
                    } else {
                        var filename = 'Cipo-download';
                        var type = result.headers('Content-Type');
                        var disposition = result.headers('Content-Disposition');
                        if (disposition) {
                            var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                            if (match[1])
                                filename = match[1];
                        }
                        filename = filename.replace(/[<>:"\/\\|?*]+/g, '_') + '.zip';
                        var blob = new Blob([result.data], { type: type });
                        FileSaver.saveAs(blob, filename);
                    }
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                })
                .finally(function () { self.isLoading(false); });
        }
        else {
            var uri = URI.MY_CIPO_DRIVE.GET_FILE_URL;
            self[uri.method](`${uri}?fileId=${attachment.id}&versionId=${attachment.versionId}`)
                .then(function (url) {
                    self.isLoading(false);
                    var link = document.createElement("a");
                    link.style.display = 'none';
                    link.href = url;
                    link.download = attachment.name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        }
    }
    // End public functions

    return AttachmentHandler;
});
