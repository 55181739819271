import { cipo } from 'cipo';

cipo.factory("Role", function ($q, Model, Form, Functions, URI, UserRoleContract, AssignURC, Dictionaries, Message, Permissions) {
    var Role = Model.extend(function (role) {
        var self = this;
        self.tabWarning = false;
        self.hightlight = false;
        this.id = role ? role.id : 0;
        // this.roleType = roleType || 0;
        this.type = 2;
        this.disabled = role ? role.disabled : false;
        self.init();
        self.messages = {
            cantAddAssignments: 'This role is deactivated. Please activate it to be able to assign/unassign users',
            addBtn: 'Assign contract/user(s)',
            noPivotItems: 'All available contracts are already assigned',
            noSecondaryItems: 'All available users are already assigned',
            noItems: 'All available users and contracts are already assigned',
            title: 'Assigned Contracts',
            pivotLabel: 'Select Contract',
            secondaryLabel: 'Select User(s)'
        };
        self.moduleTypes = [{ key: 1, value: "All modules" }, { key: 2, value: "Modules per contract" }, { key: 3, value: "Modules not per contract" }, { key: 4, value: "Assigned Modules" }, { key: 5, value: "Modules on Menu" }];
        self.filterId = 1;
        self.modulesList = [];
        self.usersList = [];
        self.isUsersLoaded = false;
        self.isModulesLoaded = false;
        self.modulesLookup = {};
        self.form = new Form(self.properties);
        self.form.initializing = true;
        //if (!isUpdater) self.form.editMode = false;
        self.form.set_Description({
            name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 64 } },
            abbr: { label: 'Abbreviation', type: 'text', validation: { required: true, maxChars: 4 } },
            description: { label: 'Description', type: 'textarea', validation: { maxChars: 250 } }
        });

        self.form.setTemplate('grid', [
            { name: 50, abbr: 50 },
            { description: 100 }
        ]);

        self.form.store_Data();
        
        self.form.initializing = false;
        if (self.id) {
            self.get_Data();
        }
        else {
            self.form.initializing = false;
            
        }
        
    });

    Role.prototype.init = function (isAdd) {
        var self = this;
        this.properties = {
            id: null,
            name: "",
            abbr: "",
            description: "",
            disabled: false,
            userIds: [],
            permissions: isAdd || !self.id ? [1,2] : []
        };
        this.backupModulesList = {
            id: this.properties.id,
            modules: []
        };
        this.modulesList = [];
        if (isAdd) {
            this.id = null;
            self.form.set_Data(self.properties);
            self.form.store_Data();

        }
        if (!this.id || isAdd) {
            this.id = null;
            self.operations = {};
            for (var j = 0; j < self.properties.permissions.length; j++) {
                Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
            }
        }
    }

    Role.prototype.initialize = function () {
        var self = this;
        self.get_users();
        self.get_modules();
    }

    Role.prototype.get_Data = function (id) {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        var urlData = URI.ROLES.DETAIL;
        if (self.id || id) {
            self[urlData.method](urlData, { id: id || self.id })
                .then(function (r) {
                    if (r) {
                        self.operations = {};
                        for (var key in r) {
                            if (r.hasOwnProperty(key)) {
                                self.properties[key] = r[key];

                            }
                        }
                        // self.properties.permissions = [1, 2, 3];
                        if ((self.properties.permissions || []).length) {
                            for (var j = 0; j < self.properties.permissions.length; j++) {
                                Object.assign(self.operations, Permissions[self.properties.permissions[j]]);
                            }
                        }
                        if (!self.operations.Update) self.form.editMode = false;

                        self.form.set_Data(self.properties);
                        self.form.store_Data();

                        self.initialize();   
                    }
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                })
                .finally(function () {
                    self.form.loading = false;
                    if (self.properties.disabled != null) {
                        if (self.properties.disabled) self.disabled = true;
                        else self.disabled = false;
                    }
                    p.resolve();
                });
        } else {
            self.form.loading = false;
            p.resolve();
        }

        return p.promise;
    }

    Role.prototype.toggle_disable = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        // self.disabled = false;
        var urlData = URI.ROLES.TOGGLE_ROLE_DISABLE;
        self[urlData.method](urlData, { url: {id: self.properties.id}, urltype: 'obj' })
            .then(function (r) {
                // self.disabled = false;
                self.properties.disabled = self.disabled;
                self.form.set_Data(self.properties);
                self.form.store_Data();
                p.resolve(r);
            })
            .catch(function (e) {
                self.disabled = !self.disabled;
                p.reject(e);
            })
            .finally(function () { self.isBusy = false; });


        return p.promise;
    }

    Role.prototype.processItem = function (item) {
        item.permissionsLine = "";
        if ((item.permissions || []).length) {
            for (var j = 0; j < item.permissions.length; j++) {
                item.permissions[j].filterId = item.permissions[j].filterId || item.permissions[j].filters[0].key;
                if (item.permissions[j].isUsed) item.permissionsLine += item.permissions[j].name + ', ';
            }
        }
        if (!item.permissionsLine) item.permissionsLine = '';
        else item.permissionsLine = item.permissionsLine.substring(0, item.permissionsLine.length - 2);

        return item;
    }

    Role.prototype.get_modules = function () {
        var self = this;
        var p = $q.defer();
        var urlData = URI.ROLES.GET_MODULES;
        // self.isModulesLoaded = false;
        self.isBusy = true;
        //self.backupModulesList = {
        //    id: self.properties.id,
        //    modules: []
        //};
        //self.modulesList = [];
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (r) {
                if (r && r.length) {
                    for (var i = 0; i < r.length; i++) {
                        r[i] = self.processItem(r[i]);
                        r[i].isExpanded = (self.modulesLookup[r[i].moduleTypeId] || {}).isExpanded || false;
                        self.modulesLookup[r[i].moduleTypeId] = r[i];
                    }

                    
                }
                self.modulesList = r || [];
                self.isModulesLoaded = true;
                self.backupModulesList = angular.copy(self.extractPermissions());

                Object.defineProperty(self, 'dirtyPermissions', {
                    get: function () { return !angular.equals(self.extractPermissions(), self.backupModulesList); }
                });
                p.resolve();
            })
            .catch(function (e) { p.reject(e); })
            .finally(function () {
                self.isBusy = false;
            });

        return p.promise;
    }


    

    Role.prototype.get_users = function () {
        var self = this;
        var p = $q.defer();
        self.loadingSecondary = true;
        //self.loadingDynRoles = true;
        // self.hasSecondaryItems = self.properties.userIds.length ? true : false;
        Dictionaries.Users()
            .then(function (r) {
                if (r && r.length) {
                    for (var i = 0; i < r.length; i++) {
                        if (self.properties.userIds.indexOf(parseInt(r[i].key)) != -1) {
                            r[i].isUsed = true;
                        }
                    }
                }

                self.usersList = (r || []).filter(user => !user.isDisabled);
                self.loadingSecondary = false;
                self.backupUsersList = angular.copy(self.usersList);
                Object.defineProperty(self, 'dirtyUsers', {
                    get: function () { return !angular.equals(self.usersList, self.backupUsersList); }
                });
                self.isUsersLoaded = true;
                p.resolve();

            })
            .catch(function (e) {
                p.reject(e);
            })

    }

    Role.prototype.sync_users = function (isAdd) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlData = URI.ROLES.ASSIGN_USERS_TO_ROLE;
        var userIds = [];
        for (var i = 0; i < self.usersList.length; i++) {
            if (self.usersList[i].isUsed) userIds.push(self.usersList[i].key);
        }
        self[urlData.method](urlData, { url: {}, urltype: 'obj', body: { id: self.properties.id, userIds: userIds }})
            .then(function (r) {
                p.resolve();
                self.backupUsersList = angular.copy(self.usersList);
                if (isAdd) self.init();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.isBusy = false;
            })
        return p.promise;
    }

    Role.prototype.save = function (isAdd) {
        var self = this;
        self.form.validate();
        var p = $q.defer();
        var urlData = self.properties.id ? URI.ROLES.UPDATE : URI.ROLES.CREATE;
        if (self.form.isValid) {
            self.form.loading = true;
            var params = {
                url: !self.properties.id ? { roleId: self.selectedRoleId } : {}, urltype: 'obj',
                body: self.properties
            };
            self[urlData.method](urlData, params)
                .then(function (r) {
                    p.resolve(r);
                    self.isModified = true;


                    // if new, call for details to get permissions
                    if (!self.properties.id && !isAdd) {
                        self.properties.id = r;
                        self.get_Data(r);
                        return;
                    }
                    // is not new
                    if (!isAdd) {
                        self.form.store_Data();
                        self.form.set_Data(self.properties);

                        return;
                    }
                    // if is add new just start another
                    if (isAdd) {
                        self.init(true);
                    }
                })
                .catch(function (e) {
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    self.form.loading = false;
                });
            
        }
        else {
            self.form.loading = false;
            p.reject();
        }
        

        return p.promise;
    }

    

    Role.prototype.extractPermissions = function () {
        var self = this;
        var permissionsObj = {
            id: self.properties.id,
            modules: []
        };
        if (self.modulesList && self.modulesList.length) {
            for (var i = 0; i < self.modulesList.length; i++) {
                var module = { moduleTypeId: self.modulesList[i].moduleTypeId };
                module.permissions = [];
                if (self.modulesList[i].permissions && self.modulesList[i].permissions.length) {

                    for (var j = 0; j < self.modulesList[i].permissions.length; j++) {

                        if (self.modulesList[i].permissions[j].isUsed)
                            module.permissions.push({
                                permissionId: self.modulesList[i].permissions[j].id,
                                filterId: self.modulesList[i].permissions[j].filterId
                            });
                    }
                    if (module.permissions.length) permissionsObj.modules.push(module);
                }
            }
        }

        return permissionsObj;

    }

    Role.prototype.all_permissions = function (e, isSelectAll, module) {
        var self = this;
        var p = $q.defer();
        e.preventDefault();
        e.stopPropagation();
        if (!module) {
            for (var i = 0; i < self.modulesList.length; i++) {
                if (self.filterId == 1 || self.filterId == 2 && self.modulesList[i].perContract || self.filterId == 3 && !self.modulesList[i].perContract) {
                    if ((self.modulesList[i].permissions || []).length) {
                        self.modulesList[i].permissionsLine = '';
                        for (var j = 0; j < self.modulesList[i].permissions.length; j++) {

                            if (isSelectAll) {

                                self.modulesList[i].permissions[j].isUsed = true;
                                self.modulesList[i].permissions[j].filterId = self.modulesList[i].permissions[j].filterId || self.modulesList[i].permissions[j].filters[0].key;
                                self.modulesList[i].permissionsLine += self.modulesList[i].permissions[j].name + ', ';

                            } else {
                                self.modulesList[i].permissions[j].isUsed = false;
                                self.modulesList[i].permissions[j].filterId = self.modulesList[i].permissions[j].filters[0].key;
                            }


                        }
                    }
                    if (!isSelectAll) self.modulesList[i].permissionsLine = '';
                    else self.modulesList[i].permissionsLine = self.modulesList[i].permissionsLine.substring(0, self.modulesList[i].permissionsLine.length - 2);
                }


                
            }
        } else {
            if ((module.permissions || []).length) {
                module.permissionsLine = '';
                for (var j = 0; j < module.permissions.length; j++) {

                    if (isSelectAll) {
                        module.permissions[j].isUsed = true;
                        module.permissions[j].filterId = module.permissions[j].filterId || module.permissions[j].filters[0].key;
                        module.permissionsLine += module.permissions[j].name + ', ';

                    } else {
                        module.permissions[j].isUsed = false;
                        module.permissions[j].filterId = module.permissions[j].filters[0].key;
                    }


                }
                if (!isSelectAll) module.permissionsLine = '';
                else module.permissionsLine = module.permissionsLine.substring(0, module.permissionsLine.length - 2);
            }
        }
        
    }

    Role.prototype.sync_permissions = function (isAdd) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlData = URI.ROLES.SYNC_PERMISSIONS;
        var toSend = self.extractPermissions();
        self[urlData.method](urlData, { url: {}, urltype: 'obj', body: toSend})
            .then(function (r) {
                
                self.backupModulesList = angular.copy(self.extractPermissions());
                if (isAdd) {
                    self.init();
                    self.form.set_Data(self.properties);
                    self.form.store_Data();
                } else {
                    self.get_modules();
                }
                p.resolve(r);
            })
            .catch(function (e) {
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            });

        return p.promise;
    }

    

    Role.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        // self.form.validate();
        self[URI.ROLES.DELETE.method](URI.ROLES.DELETE, { body: { list: [self.id] } })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                p.reject(e);
            });


        return p.promise;
    }

    return Role;
});
