import { cipo } from 'cipo';

cipo.factory("FormConfig", function ($q, Model, Form, FieldConfig, TableFieldConfig, URI, Message,
    FIELDS_CONFIG, SET_FIELDS_GRIDSTER, ModuleDictionaries, $timeout) {

    const TYPE_NONE = 0;
    const TYPE_ENTITY = 1;
    const TYPE_ACTION = 2;
    const TYPE_CARD = 3;

    var FormConfig = Model.extend(function (module, obj, options) {
        var self = this;
        self.properties = {
            name: "",
            roleIds: [],
            id: null,
            type: TYPE_ACTION,
        };
        self.fieldsConfig = FIELDS_CONFIG();
        self.dashboard = [];
        self.dashboardLookup = {};
        // self.dirtyDashboard = false;
        self.isModified = false;
        self.entityFields = [];
        self.entityFieldsLookup = {};
        self.isEntityFieldsLoaded = false;
        self.module = module;
        self.showContractFields = false;

        // external data handling
        self.saveDataExternally = false;
        self.isDirtyExternally = false;

        // temporary
        ModuleDictionaries.getDicts(["moduleEntities"])
            .then(function () {
                self.module.entityId = ModuleDictionaries.dataSources.moduleEntities.data[0].key;
            })

        // gridster options
        self.options = {
            gridType: 'scrollVertical',
            compactType: 'none',
            initCallback: self.gridster_methods["gridInit"],
            destroyCallback: self.gridster_methods["gridDestroy"],
            itemChangeCallback: self.gridster_methods["itemChange"],
            itemResizeCallback: self.gridster_methods["itemResize"],
            itemInitCallback: self.gridster_methods["itemInit"],
            itemRemovedCallback: self.gridster_methods["itemRemoved"],
            margin: 10,
            outerMargin: true,
            outerHeight: 500,
            mobileBreakpoint: 300,
            minCols: 12,
            maxCols: 12,
            minRows: 20,
            maxRows: 60,
            maxItemCols: 20,
            minItemCols: 1,
            maxItemRows: 3,
            minItemRows: 1,
            maxItemArea: 2500,
            minItemArea: 1,
            defaultItemCols: 1,
            defaultItemRows: 1,
            fixedColWidth: 250,
            fixedRowHeight: 250,
            enableEmptyCellClick: false,
            enableEmptyCellContextMenu: false,
            enableEmptyCellDrop: false,
            enableEmptyCellDrag: false,
            emptyCellClickCallback: self.gridster_methods["emptyCellClick"],
            emptyCellContextMenuCallback: self.gridster_methods["emptyCellClick"],
            emptyCellDropCallback: self.gridster_methods["emptyCellClick"],
            emptyCellDragCallback: self.gridster_methods["emptyCellClick"],
            emptyCellDragMaxCols: 50,
            emptyCellDragMaxRows: 50,
            draggable: {
                delayStart: 0,
                enabled: true,
                stop: self.gridster_methods["eventStop"]
            },
            resizable: {
                delayStart: 0,
                enabled: true,
                stop: self.gridster_methods["eventStop"]
            },
            swap: true,
            pushItems: true,
            disablePushOnDrag: false,
            disablePushOnResize: false,
            pushDirections: { north: true, east: true, south: true, west: true },
            pushResizeItems: false,
            displayGrid: 'always', //onDrag&Resize
            disableWindowResize: false,
            disableWarnings: false,
            scrollToNewItems: true
        };
        if (options) {
            for (var key in options) {
                if (options.hasOwnProperty(key)) {
                    self.options[key] = options[key];
                }
            }
        }

        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
        }
    });

    FormConfig.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        self.form.initializing = true;

        ModuleDictionaries.getDicts(["allRoles"])
            .then(function () {
            
                var form = {
                    name: { label: 'Name', type: 'text', validation: { required: true, maxChars: 100 } }
                };
                var layout = { name: 100 };

                // is start screen
                if (self.properties.type == TYPE_ENTITY) {
                    form.roleIds = { label: 'Roles', type: 'select', multiple: true, options: self.getUsedRoles(), validation: { required: true } };
                    layout = { name: 60, roleIds: 40 };
                }

                self.form.set_Description(form);
                self.form.setTemplate('grid', [layout]);
                self.form.store_Data();

                self.form.initializing = false;
            })
    }

    FormConfig.prototype.getUsedRoles = function () {
        return (ModuleDictionaries.dataSources.allRoles.data || []).filter(r => r.isUsed);
    }

    FormConfig.prototype.init = function () {
        var self = this;
        self.dashboard = [];
        self.dashboardLookup = {};
        self.entityFields = [];
        self.entityFieldsLookup = {};

        if (self.properties.type == 1/* start screen */ || self.properties.type == 1/* action screen */) {
            // This method will call to get fields
            self.getIfScreenContainsContractFields();
        }
    }

    FormConfig.prototype.savedExternally = function () {
        // data has been saved externally, reset
        this.saveDataExternally = false;
        this.isDirtyExternally = false;
    }
    
    FormConfig.prototype.changedExternally = function (value) {
        // data changed externally
        this.isDirtyExternally = value;
    }

    FormConfig.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        self.form.validate();
        self.form.loading = true;

        var urlData = self.properties.id ? URI.SCREEN_DEFINITION.UPDATE : URI.SCREEN_DEFINITION.CREATE;
        if (self.form.isValid) {
            self[urlData.method](urlData, { body: self.properties }, { headers: { moduleId: self.module.id } })
                .then(function (result) {
                    if (!self.properties.id) self.properties.id = result;
                    Message.info('Form saved successfully');
                    p.resolve();
                })
                .catch(function (e) {
                    self.form.catch(e);
                    p.reject();
                })
                .finally(function () {
                    if (self.form) self.form.loading = false;
                })
        }
        else {
            self.form.loading = false;
            p.reject();
        }
        return p.promise;
    }

    FormConfig.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlData = URI.SCREEN_DEFINITION.DELETE;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.module.id } })
            .then(function () {
                Message.info('Form deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                // self.form.catch(e);
                p.reject();
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                self.isBusy = false;
            })
        return p.promise;
    }

    FormConfig.prototype.getIfScreenContainsContractFields = function () {
        var self = this;
        var dataURL = URI.SCREEN_FIELDS.SCREEN_HAS_CONTRACT_FIELDS;
        self[dataURL.method](dataURL, { url: { screenId: self.properties.id }, urltype: 'obj' }, { headers: {} })
            .then(function (r) {
                self.showContractFields = r;
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                self.getScreenFields();
                self.getEntityFields();
            });
    }

    FormConfig.prototype.getScreenFields = function () {
        var self = this;
        self.loadingScreenFields = true;
        var dataURL = URI.SCREEN_FIELDS.SEARCH;
        self[dataURL.method](dataURL, {
            url: { screenId: self.properties.id }, urltype: 'obj'
        }, { headers: { moduleId: self.module.id } })
            .then(function (r) {
                if ((r || {}).data.length) {
                    for (var i = 0; i < r.data.length; i++) {
                        // set additional gridster related info
                        if (self.fieldsConfig.fieldTypes[r.data[i].typeId]) {
                            self.set_gridsterInfo(r.data[i]);
                        } else {
                            r.data[i].isError = true;
                            r.data[i].subtitle = '<span class="dangerText">Unknown type</span>';

                        }

                        r.data[i].screenId = self.properties.id;
                        r.data[i].isBusy = false;
                        self.dashboard.push(r.data[i]);
                        if (r.data[i].typeId != 50) self.dashboardLookup[r.data[i].id] = r.data[i];
                    }
                }
                self.backupDashboard = self.createReference(self.dashboard);
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
            })
            .finally(function () {
                self.loadingScreenFields = false;
            });
    }

    FormConfig.prototype.set_gridsterInfo = function (field) {
        var self = this;
        SET_FIELDS_GRIDSTER(field);
        field.subtitle = self.setFieldSubtitle(field);
    }

    FormConfig.prototype.setFieldSubtitle = function (field) {
        var self = this;
        var ret = "";
        // extract properties from field formatting and restrictions
        ret += self.fieldsConfig.fieldTypes[field.typeId] + ' ';
        if (self.fieldsConfig.displayTypeDict[field.displayTypeId] && self.fieldsConfig.displayTypeDict[field.displayTypeId].name != "Text") {
            ret += "[" + self.fieldsConfig.displayTypeDict[field.displayTypeId].name + "] ";
        }

        if ((field.formattings || []).length) {
            for (var j = 0; j < field.formattings.length; j++) {
                if (field.formattings[j].value && self.fieldsConfig.formattingsMapping[field.formattings[j].key].elements[field.formattings[j].value])
                    ret += '<span class="noteBlock fieldFormattingWrapper">'
                        + self.fieldsConfig.formattingsMapping[field.formattings[j].key].elements[field.formattings[j].value]
                        + '</span>';
            }
        }
        if ((field.restrictions || []).length) {
            for (var j = 0; j < field.restrictions.length; j++) {
                if (field.restrictions[j].value && self.fieldsConfig.restrictionsMapping[field.restrictions[j].key].elements[field.restrictions[j].value])
                    ret += '<span class="noteBlock fieldRestrictionWrapper">'
                        + self.fieldsConfig.restrictionsMapping[field.restrictions[j].key].elements[field.restrictions[j].value]
                        + '</span>';
            }
        }
        return ret;
    }

    FormConfig.prototype.getEntityFields = function (fieldToBeAddedToFormId, addContractFields) {
        var self = this;
        var dataURL = URI.FIELD_DEFINITION.SEARCH;
        self.showContractFields = typeof (addContractFields) != 'undefined' ? addContractFields : self.showContractFields;
        self.isEntityFieldsLoaded = false;
        self.isBusy = true;
        self[dataURL.method](dataURL, {
            url: {
                screenId: self.properties.id,
                entityId: self.module.entityId,
                addContractFields: self.showContractFields,
            }, urltype: 'obj'
        }, { headers: { moduleId: self.module.id } })
            .then(function (r) {
                if (((r || {}).data || []).length) {
                    var list = [];
                    for (var i = 0; i < r.data.length; i++) {
                        var item = {};
                        // retain the iffy properties if previously set
                        if (self.entityFieldsLookup[r.data[i].id]) {
                            r.data[i].iffy = self.entityFieldsLookup[r.data[i].id].iffy;
                            r.data[i].inUse = self.entityFieldsLookup[r.data[i].id].inUse;
                        }
                        item.properties = r.data[i];
                        self.entityFieldsLookup[r.data[i].id] = r.data[i];
                        list.push(item);
                    }
                }
                self.entityFields = list;
                // add field to dashboard
                if (fieldToBeAddedToFormId) {
                    if (!self.dashboardLookup[fieldToBeAddedToFormId])
                        self.addFieldToDashboard({ properties: self.entityFieldsLookup[fieldToBeAddedToFormId] });
                    if (self.currentField) self.currentField.properties.inUse = true;

                }

            })
            .catch(function (e) { console.error(e); })
            .finally(function () {
                self.isEntityFieldsLoaded = true;
                self.isBusy = false;
            });

    }

    FormConfig.prototype.gridster_methods = {

        emptyCellClick: function (event, item) {
            // console.log('empty cell click', event, item);
            // self.dashboard.push(item);
            // this.$applyAsync();
        },

        eventStop: function (item, itemComponent, event) {
            // console.log('eventStop', item, itemComponent, event);
        },

        itemChange: function (item, itemComponent) {
            // console.log('itemChanged', item, itemComponent, this, self);
            //self.dirtyDashboard = true;
        },

        itemResize: function (item, itemComponent) {
            // console.log('itemResized', item, itemComponent);
            //this.api.optionsChanged();
        },

        itemInit: function (item, itemComponent) {
            //console.log('itemInitialized', item, itemComponent);
        },

        itemRemoved: function (item, itemComponent) {
            // console.log('itemRemoved', item, itemComponent, this);
            // itemComponent.gridster.options.api.optionsChanged();
        },
        gridInit: function (grid) {
            // console.log('gridInit', grid);
        },
        gridDestroy: function (grid) {
            // console.log('gridDestroy', grid);
        }
    }

    FormConfig.prototype.createField = function (f, info) {
        var self = this;
        if (self.currentField) {
            f.fieldOrigin = self.currentField.properties.fieldOrigin;
            f.screenId = self.currentField.properties.screenId;

        }
        f.entityId = self.module.entityId;
        self.currentField = self.setupField(f, info);
        self.currentField.moduleTypeId = self.module.currentType.key;
        self.currentField.isAddToForm = true;
    }


    FormConfig.prototype.setupField = function (f, info) {
        var self = this;
        var field = new FieldConfig(f, info, self.module.id);

        return field;
    }

    FormConfig.prototype.editField = function (f, info) {
        var self = this;
        var info = info || null;
        var field = f.properties ? f.properties : f;
        self.currentField = new FieldConfig(field, info, self.module.id);
        if (field.typeId == 7 || field.typeId == 11) {
            self.tableConfig = new TableFieldConfig({
                tableId: field.id,
                moduleId: self.module.id,
                fieldOrigin: self.currentField.properties.fieldOrigin
            });
            //self.tableConfig.mimetypesDict = self.mimetypesDict;
            self.tableConfig.module = self.module;
        }
    }
    // just the delete matters here
    FormConfig.prototype.entityField_Action = function (field, isDelete) {
        var self = this;
        var p = $q.defer();
        var dataURL = isDelete ? URI.FIELD_DEFINITION.DELETE : "";
        field.deleting = true;
        self[dataURL.method](dataURL, {
            url: {
                id: field.properties.id
            }, urltype: 'obj'
        }, { headers: { moduleId: self.module.id } })
            .then(function (r) {
                field.deleting = false;
                field.isBusy = true;
                self.getEntityFields();
                p.resolve();
                Message.info("Field deleted successfully");
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject();
                field.deleting = false;
            })
            .finally(function () {

            });

        return p.promise;

    }

    FormConfig.prototype.clearDashboard = function () {
        var self = this;
        self.dashboard = [];
        self.saveDashboard();
    };

    FormConfig.prototype.saveField = function (field, cat) {
        var self = this;
        var p = $q.defer();
        self.currentField.save()
            .then(function (r) {
                // if editing the dashboard version
                if (self.currentField.properties.screenId == self.properties.id) {
                    // retain coordinates and size, if field is already on the dashboard
                    if (self.dashboardLookup[r.id]) {
                        r.cols = self.dashboardLookup[r.id].cols;
                        r.rows = self.dashboardLookup[r.id].rows;
                        r.x = self.dashboardLookup[r.id].x;
                        r.y = self.dashboardLookup[r.id].y;

                        self.set_gridsterInfo(r);
                        // update field
                        for (var key in r)
                            if (r.hasOwnProperty(key))
                                self.dashboardLookup[r.id][key] = r[key];

                        // reload gridster
                        self.options.api.optionsChanged();

                    }
                    // if not on the screen, is white field
                    else {
                        self.getEntityFields(self.currentField.isAddToForm ? r.id : null);
                    }




                }
                else {
                    self.getEntityFields(self.currentField.isAddToForm ? r.id : null);


                }
                // is add new
                if (field) {

                    self.createField(field, cat);
                } else if (self.currentField.properties.typeId != 7 && self.currentField.properties.typeId != 11) {
                    // not table
                    self.currentField = null;
                } else {
                    if (!self.tableConfig) {
                        self.tableConfig = new TableFieldConfig({
                            tableId: self.currentField.properties.id,
                            moduleId: self.module.id,
                            fieldOrigin: self.currentField.properties.fieldOrigin
                        });
                        //self.tableConfig.mimetypesDict = self.mimetypesDict;
                        self.tableConfig.module = self.module;
                    }
                }
                p.resolve();

            })
            .catch(function (e) { console.error(e); p.reject(); })

        return p.promise;
    }

    FormConfig.prototype.addFieldToDashboard = function (field, isUpdate) {
        var self = this;

        if (field.properties.displayTypeId) {
            self.entityFieldsLookup[field.properties.id].isBusy = true;

            if (self.dashboardLookup[field.properties.id]) {
                field = { properties: self.dashboardLookup[field.properties.id] };
            }

            // set iffy properties
            self.entityFieldsLookup[field.properties.id].iffy = !self.entityFieldsLookup[field.properties.id].iffy;
            self.entityFieldsLookup[field.properties.id].inUse = true;

            // setup and add field to dashboard and lookup
            var fieldToPush = angular.copy(field.properties);
            fieldToPush.screenId = self.properties.id;
            self.set_gridsterInfo(fieldToPush);
            this.dashboard.push(fieldToPush);
            this.dashboardLookup[field.properties.id] = fieldToPush;

            $timeout(function () {
                self.entityFieldsLookup[field.properties.id].isBusy = false;
                fieldToPush.isBusy = false;
            }, 300);
        }
        else {
            Message.warning("This field doesn't have a display type. Please add one.");

            self.editField(field);
            self.currentField.isAddToForm = true;
        }


    }


    FormConfig.prototype.removeItem = function (field, e) {
        //e.preventDefault();
        //e.stopPropagation();
        var self = this;
        if (field.typeId != 50) {
            self.dashboardLookup[field.id].isBusy = true;
            (self.entityFieldsLookup[field.id] || {}).isBusy = true;
            $timeout(function () {
                (self.entityFieldsLookup[field.id] || {}).isBusy = false;
                self.dashboardLookup[field.id].isBusy = false;
            }, 500);

            // set iffy properties
            (self.entityFieldsLookup[field.id] || {}).inUse = false;
            (self.entityFieldsLookup[field.id] || {}).iffy = !(self.entityFieldsLookup[field.id] || {}).iffy;


            // put in timeout so that the html is updated properly
            $timeout(function () {
                self.dashboard.splice(self.dashboard.indexOf(self.dashboardLookup[field.id]), 1);
            }, 0);
        } else {
            // put in timeout so that the html is updated properly
            $timeout(function () {
                self.dashboard.splice(self.dashboard.indexOf(field), 1);
            }, 0);
        }

    }

    FormConfig.prototype.createReference = function (fields, preserveIffy) {
        var self = this;
        var reference = {};
        if (fields.length) {
            for (var i = 0; i < fields.length; i++) {
                if (!preserveIffy) fields[i].iffy = false;
                reference[fields[i].id] = {
                    x: fields[i].x,
                    y: fields[i].y,
                    cols: fields[i].cols,
                    rows: fields[i].rows
                };
            }
        }
        return reference;
    }

    FormConfig.prototype.checkDirty = function () {
        var self = this;
        if (!self.loadingFields) return !angular.equals(self.backupDashboard, self.createReference(self.dashboard, true));
        else return false;


    }

    FormConfig.prototype.restoreBackup = function () {
        var self = this;
        // self.dashboard = angular.copy(self.backupDashboard);
    }

    FormConfig.prototype.update = function () {
        var self = this;
        var p = $q.defer();

        if (self.properties.type == 3 /* card screen type */) {
            // mark that data will be saved externally
            // in this case it will be saved by the data card settings component
            self.saveDataExternally = true;
            p.resolve();
            return p.promise;
        }

        var urlData = URI.SCREEN_FIELDS.SYNC_FIELDS;
        self.updateMessage = "Updating form...";
        self.isBusy = true;

        self[urlData.method](urlData,
            { url: { screenId: self.properties.id }, urltype: 'obj', body: self.dashboard },
            { headers: { moduleId: self.module.id } })
            .then(function (result) {
                // clean lookups
                self.entityFieldsLookup = {};
                self.dashboardLookup = {};
                for (var i = 0; i < self.dashboard.length; i++) {
                    self.dashboardLookup[self.dashboard[i].id] = self.dashboard[i];
                }
                self.getEntityFields();
                Message.info('Form updated successfully');
                self.backupDashboard = self.createReference(self.dashboard);

                    p.resolve();
                })
                .catch(function (e) {
                    p.reject("update", e);
                    Message.dberror(e);
                    console.error(e);

            })
            .finally(function () {
                self.updateMessage = "";
                self.isBusy = false;
            })
        return p.promise;
    }


    return FormConfig;
});
