import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("Adjustment", function ($q, Model, URI, Message, AdjustmentRelease, ADJUSTMENTS, Form, userService) {
    var Adjustment = Model.extend(function (obj, accrualFrequencies, categoriesDict, parent) {

        var self = this;

        self.editable = false;
        self.deleteConfirm = false;
        
        self.parent = parent;
        self.name = "";

        self.categoriesDict = categoriesDict || [];
        self.categoriesLookup = {};
        // self.financiaSchedulesLookup = {};
        self.accrualPeriodsLookup = {};
        for (var i = 0; i < self.categoriesDict.length; i++) {
            self.categoriesLookup[self.categoriesDict[i].key] = self.categoriesDict[i];
        }
        
        self.accrualFrequencies = accrualFrequencies || [];
        for (var i = 0; i < self.accrualFrequencies.length; i++) {
            self.accrualPeriodsLookup[self.accrualFrequencies[i].key] = self.accrualFrequencies[i];
        }
        
        
        self.properties = {
            id: null,
            contractId: null,
            entityInstanceId: null,
            name: "",
            categoryId: null,
            categoryName: "",
            description: "",
            comment: "",
            amount: null,
            percentage: null,
            type: obj.type,
            typeName: obj.typeName,
            class: 1, // term

            end: null,
            start: null,

            // willBeRetained: false,
            frequency: null,

            valueType: 1
        }

        self.adjustmentTypeDict = [{ key: 1, value: "Amount" }, { key: 2, value: "Percentage" }];

        if (obj) {
            self.name = obj.name;
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
            }
            self.properties.start = self.process_incomingDate(obj.start);
            self.properties.end = self.process_incomingDate(obj.end);
            if (obj.id) {
                self.properties.valueType = self.properties.amount ? 1 : 2;
                self.properties.amount = self.properties.amount ? self.properties.amount : self.properties.percentage;
            }
            // self.properties.type = self.properties.isDeduction ? 2 : 1;
            // self.properties.recurrence = self.properties.isRecurring ? 2 : self.properties.isAccrual ? 3 : 1;
            
            // self.properties.resolvedDate = self.process_incomingDate(obj.resolvedDate);

            if ((obj.retainedAmount || obj.releasedAmount) && !obj.remainingRetainedAmount)
                self.properties.remainingRetainedAmount = (obj.retainedAmount - obj.releasedAmount);
        }

        self.releaseList = [];
        if ((obj || {}).releases && (obj || {}).releases.length) {
            for (var i = 0; i < obj.releases.length; i++) {
                obj.releases[i].entityInstanceId = self.properties.entityInstanceId;
                self.releaseList.push(new AdjustmentRelease(self, obj.releases[i]));
            }
        }

        if (!self.properties.id) {
            if (self.categoriesDict.length == 1) {
                self.properties.categoryId = self.categoriesDict[0].key;
            }
            self.createForm();
        }

    });

    Adjustment.prototype.createForm = function () {
        var self = this;

        self.form = new Form(self.properties);
        self.form.initializing = true;

        var form = {
            comment: { label: 'Comment', type: 'editor', validation: { required: true } },
            amount: { label: 'Adjustment Value', type: 'number', validation: { required: true, isNumber: true } },
            valueType: { label: '', type: 'radio', options: self.adjustmentTypeDict, validation: { required: true } },
            categoryId: { label: 'Adjustment Category', type: 'select', options: self.categoriesDict, validation: { required: true } },
            start: { label: 'Valid From', type: 'datepicker', options: { format: userService.formats.date }, validation: { required: true } },
            end: { label: 'Valid Through', type: 'datepicker', options: { format: userService.formats.date } },
            name: { label: 'Name', type: 'text', validation: { required: true, minChars: 1, maxChars: 255 } },
            class: { label: '', type: 'radio', options: ADJUSTMENTS.term },
            // recurrence: { label: '', type: 'radio', options: [{ key: 1, value: "One Time" }, { key: 2, value: "Recurring" }, { key: 3, value: "Accrual" },] },
            frequency: { label: 'Accrual Frequency', type: 'select', options: self.accrualFrequencies },
            description: { label: 'Description', type: 'text' },
            // willBeRetained: { label: 'Retained', type: 'checkbox' },
        }


        // accrual deduction radio --> adj type
        // recurring / 1 time --> recurrence

        // name, value, value type, adjust type, accrualFrequencyId, recurrence,
        // 
        // recurring // class 1 !!!
        if (self.properties.type == 1) {
            self.form.set_Description(form);
            self.form.setTemplate('grid', [
                { name: 33, categoryId: 33, description: 33 },
                { amount: 25, valueType: 25, start: 25, end: 25 },
                { comment: 100 },
            ]);
        }
        // one time
        if (self.properties.type == 2) {
            delete form.valueType.validation;
            delete form.start.validation;
            form.amount.label = "Adjustment amount";
            form.start.label = "Effective date";
            form.start.validation = { required: true };
            form.amount.type = "money";
            self.form.set_Description(form);
            self.form.setTemplate('grid', [
                { name: 33, categoryId: 33, description: 33 },
                { amount: 33, class: 33, start: 33},
                { comment: 100 },
            ]);
            //self.form.fieldsList.class.onChange = function (fieldValue) {
            //    // console.error("field", field);
            //    if (fieldValue == 2) {
            //        self.form.fieldsList.willBeRetained.editMode = false;
            //        self.form.fieldsList.willBeRetained._value = false;
            //    } else {
            //        self.form.fieldsList.willBeRetained.editMode = true;
            //    }
            //}
        }
        // accrual
        if (self.properties.type == 3) {
            form.frequency.validation = { required: true };
            self.form.set_Description(form);
            self.form.setTemplate('grid', [
                { name: 33, categoryId: 33, description: 33 },
                { amount: 50, valueType: 25, class: 25, },
                { start: 25, end: 25, frequency: 50 },
                { comment: 100 },
            ]);
        }


        self.form.initializing = false;
        self.form.store_Data();
    }

    var addZ = function (date) {
        var ret = date || null;
        if (date && date.indexOf("Z") == -1)
            ret = date + "Z";
        return ret;
    }

    Adjustment.prototype.process_incomingDate = function (date) {
        if (date) {
            var momentDate = moment.utc(addZ(date));
            momentDate.set({ h: 12, m: 0 });
            //console.error('momentDate', date,  momentDate)
            return momentDate;
        }
        else return null;
    }

    Adjustment.prototype.save = function () {
        var self = this;
        var p = $q.defer();

        self.form.loading = true;
        self.form.validate();
        if (self.form.isValid) {
            var temp = angular.copy(self.properties);
            if (temp.valueType == 2) {
                temp.percentage = temp.amount;
                temp.amount = 0;
            } else {
                temp.percentage = null;
            }

            //temp.isRecurring = temp.recurrence == 2;
            //temp.isAccrual = temp.recurrence == 3;

            //temp.isDeduction = temp.adjustmentType == 2;

            var urlData = temp.id ? URI.ADJUSTMENTS.UPDATE : URI.ADJUSTMENTS.CREATE;
            temp.start = temp.start ? temp.start.set({ h: 12, m: 0 }).format("YYYY-MM-DDTHH:mm:ss") : null;
            temp.end = temp.end ? temp.end.set({ h: 12, m: 0 }).format("YYYY-MM-DDTHH:mm:ss") : null;


            self[urlData.method](urlData, { url: { entityInstanceId: self.properties.entityInstanceId }, urltype: 'obj', body: temp })
                .then(function () {
                    p.resolve();
                    Message.info('Adjustment saved successfully');
                })
                .catch(function (e) {
                    console.error(e);
                    self.form.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    (self.form || {}).loading = false;
                });

        }
        else {
            self.form.loading = false;
            p.reject();
        }

        return p.promise;
    }

    Adjustment.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting adjustment...";
        self.deleting = true;
        var urlData = URI.ADJUSTMENTS.DELETE;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' })
            .then(function (result) {
                Message.info('Adjustment deleted successfully');
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete adjustment. Try again?";
                p.reject(e);
                Message.dberror(e);
            });

        return p.promise;
    }

    Adjustment.prototype.new_Release = function (release) {
        var self = this;
        if (!release) release = { adjustmentId: self.properties.id };
        release.entityInstanceId = self.properties.entityInstanceId;
        return new AdjustmentRelease(self, release);
    }

    Adjustment.prototype.get_Releases = function () {
        var self = this;
        var p = $q.defer();
        self.loadingReleases = true;
        self.isReleasesLoaded = false;
        self.isBusy = true;
        var urlData = URI.ADJUSTMENTS.GET_RELEASES;
        self[urlData.method](urlData, { url: { adjustmentId: self.properties.id, entityInstanceId: self.properties.entityInstanceId }, urltype: 'obj' })
            .then(function (result) {

                self.loadingReleases = false;
                self.releaseList = [];
                if (result && result.length) {
                    for (var i = 0; i < result.length; i++) {
                        var release = self.new_Release(result[i]);
                        self.releaseList.push(release);
                    }
                }
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.loadingReleases = false;
                self.isReleasesLoaded = true;
                self.isBusy = false;
            })

        return p.promise;
    }

    Adjustment.prototype.release_Action = function (item, isDelete) {
        var self = this;
        var p = $q.defer();
        self.message = isDelete ? "Deleting release..." : "Saving release...";
        var action = isDelete ? item.delete() : item.save();
        action
            .then(function () {
                if (isDelete) {
                    self.releaseList.splice(self.releaseList.indexOf(item), 1);
                } else {
                    delete item.form;
                }
                self.get_Releases();

                if (self.parent) 
                    self.parent.isBusy = false;

                p.resolve();
            })
            .catch(function (e) {
                if (self.parent && !isDelete) 
                    self.parent.isBusy = false;
                    
                console.error(e);
                p.reject();
            })
            .finally(function () {
                self.message = "";
            })
        return p.promise;
    }

    return Adjustment;
});
