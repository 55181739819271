import { cipo } from 'cipo';

cipo.factory("Conversation", function ($window, $q, Model, Functions, URI, Email, Message, Dictionaries, userService, TEMPLATES_TYPES, AttachmentHandler) {
    var Conversation = Model.extend(function (obj) {
        var self = this;
        self.id = obj.id || 0;
        self.operations = obj.operations || {};
        // if (self.id) this.get_data(self.id);
        self.convList = [];
        self.subject = "";
        self.folderId = obj.folderId || 0;
        self.contractId = userService.system.userdata.contractId;
        self.moduleId = obj.moduleId || 0;
        self.contractName = "";
        self.recipientsArray = [];
        self.typeArray = [];
        self.disableBtns = false;
        self.emails = [];
        self.hideEdit = false;
        self.loading = true;
        self.owner = {};
        self.editSingleDraft = false;
        self.contractsList = userService.system.contracts;
        self.attachmentHandler = new AttachmentHandler({
            isLoading: function(isLoading) { self.loading = isLoading; }
        });
        // self.init(self.id);
    });

    Conversation.prototype.chips = function () {
        var self = this;
        self.chip = {};
        self.chip.readonly = false;
        self.chip.selectedItem = null;
        self.chip.searchText = null;
        self.chip.querySearch = querySearch;
        self.chip.selectedItemChange = selectedItemChange;
        self.chip.vegetables = loadVegetables();
        self.chip.selectedVegetables = [];
        self.chip.numberChips = [];
        self.chip.numberChips2 = [];
        self.chip.numberBuffer = '';
        self.chip.autocompleteDemoRequireMatch = true;
        self.chip.transformChip = transformChip;

        /**
         * Return the proper object when the append is called.
         */
        function transformChip(chip) {
            // If it is an object, it's already a known chip
            if (angular.isObject(chip)) {
                return chip;
            }

            // Otherwise, create a new one
            return { name: chip, code: 'new' }
        }

        /**
         * Search for vegetables.
         */
        function querySearch(query) {
            var results = query ? self.chip.vegetables.filter(createFilterFor(query)) : [];
            return results;
        }

        /**
         * Create filter function for a query string
         */
        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);

            return function filterFn(vegetable) {
                return (vegetable._lowername.indexOf(lowercaseQuery) !== -1) ||
                    (vegetable._lowertype.indexOf(lowercaseQuery) !== -1);
            };

        }

        function loadVegetables() {
            var veggies = self.recipientsArray;

            return veggies.map(function (veg) {
                veg._lowername = veg.name.toLowerCase();
                veg._lowertype = veg.email.toLowerCase();
                return veg;
            });
        }

        function selectedItemChange(object, model) {
            if (object) {
                // object.is_used = true;
            }
        }
    }

    Conversation.prototype.init = function (callFromURL) {
        var self = this;
        var p = $q.defer();

        if (self.id) {
            self.get_data(self.id, self.folderId)
                .then(function (result) {
                    if (result.length) {
                        if (result.length == 1 && result[0].isDraft == true && callFromURL) {
                            p.reject("the email found is a draft");
                        } else {
                            var params = { contractId: self.contractId || result[0].contractId, moduleId: self.moduleId };
                            var gatherIntersectData = $q.all([Dictionaries.RecipientsList(params), Dictionaries.UserTemplates({ moduleId: -11 }, { typeName: TEMPLATES_TYPES[2], contractId: self.contractId })]);

                            gatherIntersectData
                                .then(function (res) {
                                    self.recipientsArray = res[0];
                                    self.typeArray = res[1];

                                    self.process_Details(result);

                                    if (self.contractId != 0) {
                                        self.contractName = Functions.lookupValueInArray("key", self.contractId, "value", self.contractsList);
                                    } else {
                                        if (self.editSingleDraft) {
                                            self.emails[0].set_all_recipients(self.emails[0]);
                                        }
                                    }
                                    for (var i = 0; i < self.recipientsArray.length; i++) {
                                        if (self.recipientsArray[i].id == self.emails[0].properties.userId) {
                                            self.owner = {
                                                id: self.recipientsArray[i].id,
                                                isContact: self.recipientsArray[i].isContact,
                                                firstName: self.recipientsArray[i].firstName || null,
                                                lastName: self.recipientsArray[i].lastName || null,
                                                name: self.recipientsArray[i].name || null,
                                                email: self.recipientsArray[i].email
                                            };

                                            if (self.owner.firstName == null && self.owner.lastName == null) {
                                                firstName = name != null
                                                    ? name
                                                    : email;
                                            }

                                            break;
                                        }
                                    }
                                    self.loading = false;
                                    p.resolve();
                                })
                                .catch(function (reject) {
                                    console.error(reject);
                                })
                                .finally(function () {

                                });
                        }
                    }
                    else {
                        p.reject("no emails found");
                    }

                })
                .catch(function (reject) {
                });

        } else {
            var params = { contractId: self.contractId, moduleId: self.moduleId };
            var gatherData = $q.all([Dictionaries.RecipientsList(params), Dictionaries.UserTemplates({ moduleId: -11 }, { typeName: TEMPLATES_TYPES[2], contractId: self.contractId })]);

            gatherData
                .then(function (result) {
                    self.recipientsArray = result[0];
                    self.typeArray = result[1];
                    var newEmail = new Email(null, self.moduleId);
                    newEmail.properties.sender = {
                        id: userService.system.userdata.id,
                        firstName: userService.system.userdata.firstName,
                        lastName: userService.system.userdata.lastName,
                        name: userService.system.userdata.displayName,
                        isContact: false,
                        email: userService.system.userdata.email
                    };
                    newEmail.set_selectsData(self.recipientsArray, self.typeArray);
                    self.emails.push(newEmail);
                    self.loading = false;
                    // self.emails[0].properties.templateId = self.typeArray[0].key;
                    p.resolve();
                })
                .catch(function (reject) {
                })
                .finally(function () {
                });
        }

        return p.promise;
    }

    // populate the recipients when adding the newly sent email to the conversation in front-end
    Conversation.prototype.set_recipients = function (array, lookUpArray) {
        var arrayToPopulate = [];
        for (var i = 0; i < array.length; i++) {
            for (var j = 0; j < lookUpArray.length; j++) {
                if (lookUpArray[j].id == array[i].id) {
                    var temp = {
                        id: lookUpArray[j].id,
                        firstName: lookUpArray[j].firstName || "",
                        lastName: lookUpArray[j].lastName || "",
                        isContact: lookUpArray[j].isContact,
                        email: lookUpArray[j].email || ""
                    }
                    arrayToPopulate.push(temp);
                    break;
                }
            }
        }
        return arrayToPopulate;
    }

    Conversation.prototype.reply = function (obj) {
        var self = this;
        if (!obj.hasDraft) {

            obj.draft = [];
            obj.hasDraft = true;
            var newDraft = new Email(null, self.moduleId);
            newDraft.set_draft_info(obj);
            newDraft.set_reply_recipients(obj);
            newDraft.set_selectsData(self.recipientsArray, self.typeArray);
            obj.draft.push(newDraft);
        } else {
            obj.draft[0].properties.attachments = [];
            obj.draft[0].set_reply_recipients(obj);
        }
    }
    Conversation.prototype.replyAll = function (obj) {
        var self = this;
        if (!obj.hasDraft) {
            obj.draft = [];
            obj.hasDraft = true;
            var newDraft = new Email(null, self.moduleId);
            newDraft.set_draft_info(obj);
            newDraft.set_all_recipients(obj);
            newDraft.set_selectsData(self.recipientsArray, self.typeArray);
            obj.draft.push(newDraft);
            //self.saveDraft(obj);
        } else {
            obj.draft[0].properties.attachments = [];
            obj.draft[0].set_all_recipients(obj);
        }
    }
    Conversation.prototype.forward = function (obj) {
        var self = this;
        if (!obj.hasDraft) {
            obj.draft = [];
            obj.hasDraft = true;
            var newDraft = new Email(null, self.moduleId);
            newDraft.set_draft_info(obj);
            newDraft.set_forward_info(obj);
            newDraft.set_selectsData(self.recipientsArray, self.typeArray);
            obj.draft.push(newDraft);
            //self.saveDraft(obj);
        } else {
            obj.draft[0].set_forward_info(obj);
        }
    }

    Conversation.prototype.send = function (obj) {
        var isValidContractId = !isNaN(parseInt(obj.draft[0].properties.contractId)) && obj.draft[0].properties.contractId != 0;
        if (!isValidContractId) {
            Message.error('Please select a contract.');
            return;
        }

        var self = this;
        self.disableBtns = true;
        obj.draft[0].form.validate();
        if (obj.draft[0].form.isValid) {
            if (obj.draft[0].properties.id == obj.draft[0].properties.parentId) {
                obj.draft[0].send_conversation_email()
                    .then(function (result) {
                        if (result) {
                            obj.draft[0].set_sender(userService.system.userdata);
                            obj.draft[0].properties.sender = obj.draft[0].sender;
                            obj.draft[0].properties.userId = obj.draft[0].sender.id;
                            obj.draft[0].properties.sent = new Date();
                            obj.draft[0].properties.to = [];
                            obj.draft[0].properties.cc = [];
                            obj.draft[0].isDraft = false;
                            obj.draft[0].properties.to = self.set_recipients(obj.draft[0].to, self.recipientsArray);
                            obj.draft[0].properties.cc = self.set_recipients(obj.draft[0].cc, self.recipientsArray);
                            self.emails.push(obj.draft[0]);
                            obj.hasDraft = false;
                            self.loading = false;
                        }
                        else {
                            Message.dberror("An error has occured. Your message couldn't be sent.");
                            self.loading = false;
                        }
                        self.disableBtns = false;
                    })
                    .catch(function () {
                    });
            }
            else {
                obj.draft[0].send_draft()
                    .then(function (result) {
                        if (result) {
                            obj.draft[0].set_sender(userService.system.userdata);
                            obj.draft[0].properties.sender = obj.draft[0].sender;
                            obj.draft[0].properties.userId = obj.draft[0].sender.id;
                            obj.draft[0].properties.sent = new Date();
                            obj.draft[0].properties.to = [];
                            obj.draft[0].properties.cc = [];
                            obj.draft[0].properties.to = self.set_recipients(obj.draft[0].to, self.recipientsArray);
                            obj.draft[0].properties.cc = self.set_recipients(obj.draft[0].cc, self.recipientsArray);
                            Message.info('Message sent successfully!');
                            self.emails.push(obj.draft[0]);
                            obj.hasDraft = false;
                            self.loading = false;
                        }
                        else {
                            Message.dberror("An error has occured. Your message couldn't be sent.");
                            self.loading = false;
                        }
                        self.disableBtns = false;
                    })
                    .catch(function (e) {
                        Message.dberror(e);
                    });
            }
        }
        else {
            obj.draft[0].form.loading = false;
        }
    }

    Conversation.prototype.saveDraft = function (obj) {
        var isValidContractId = !isNaN(parseInt(obj.draft[0].properties.contractId)) && obj.draft[0].properties.contractId != 0;
        if (!isValidContractId) {
            Message.error('Please select a contract.');
            return;
        }

        var self = this;
        self.disableBtns = true;
        //obj.draft[0].saving = true;
        if ((obj.draft[0].properties.id == obj.draft[0].properties.parentId) || obj.draft[0].properties.id == 0 || !obj.draft[0].properties.id) {
            //"new draft to be saved!"
            obj.draft[0].save_new_draft()
                .then(function (result) {
                    if (result) {
                    } else {
                        Message.dberror("An error has occured. Your message couldn't be saved.");
                    }
                    self.disableBtns = false;
                })
                .catch(function () {

                });
        } else {
            //"existing draft to be saved!
            obj.draft[0].save_existing_draft()
                .then(function (result) {
                    obj.draft[0].saving = false;
                    if (result) {
                    } else {
                        Message.dberror("An error has occured. Your message couldn't be saved.");
                    }
                    self.disableBtns = false;
                })
                .catch(function () {

                });
        }

    }
    //discard_draft

    Conversation.prototype.discardDraft = function (obj) {
        obj.hasDraft = false;
        self.disableBtns = true;
        obj.draft[0].discard_draft()
            .then(function () {
            })
            .catch(function () {
                Message.dberror("An error has occured. Your draft couldn't be deleted.");
            })
            .finally(function () {
                self.disableBtns = false;
            })
    }

    Conversation.prototype.cancel = function (obj) {
        obj.hasDraft = false;
        obj.draft = [];
    }

    Conversation.prototype.process_emails = function (list) {
        var self = this;
        var tempParents = [];
        var tempDrafts = [];
        var processedList = [];
        for (var i = 0; i < list.length; i++) {
            if (list[i].isDraft) {
                if (list[i].properties) {
                    tempParents.push(list[i].properties.parentId);
                } else {
                    tempParents.push(list[i].parentId);
                }
                tempDrafts.push(list[i]);
            }
        }
        for (var i = 0; i < list.length; i++) {
            if (!list[i].isDraft) {
                if (tempParents.indexOf(list[i].id) != -1) {
                    list[i].draft = [];
                    var newEmail = new Email(tempDrafts[tempParents.indexOf(list[i].id)], self.moduleId);
                    newEmail.set_selectsData(self.recipientsArray, self.typeArray);

                    list[i].draft.push(newEmail);

                    list[i].hasDraft = true;
                    list[i].draft[0].set_all_recipients(list[i].draft[0]);
                    processedList.push(list[i]);
                } else {
                    processedList.push(list[i]);
                }
            }
        }
        return processedList;
    }

    Conversation.prototype.get_data = function (id, folderId = 0) {
        var self = this;
        var p = $q.defer();

        self[URI.EMAIL.DETAIL.method](`${URI.EMAIL.DETAIL}?contractId=${self.contractId}&conversationId=${self.id}&folderId=${folderId}`).then(function (result) {
            p.resolve(result);
        }).catch(function (e) {
            p.reject(e);
        }).finally(function () {
        });

        return p.promise;
    }

    Conversation.prototype.process_Details = function (result) {
        var self = this;
        if (result && result.length) {
            for (var i = 0; i < result.length; i++) {
                if (result[i].attachments && result[i].attachments.length) {
                    for (var j = 0; j < result[i].attachments.length; j++) {
                        var dotIndex = result[i].attachments[j].name.lastIndexOf(".");
                        var extension = result[i].attachments[j].mimeType.replace(/\//g, '-').replace(/\./g, '-');
                        result[i].attachments[j].extension = extension;
                    }
                }
            }
        }

        if (!result.length) {
            self.emails = [];
        } else
            if (result.length == 1 && result[0].isDraft) {
                if (result[0].contractId != 0 && typeof result[0].contractId != "undefined")
                    self.contractId = result[0].contractId;
                else {
                    self.contractId = 0;
                    result[0].contractId = "";
                }
                result[0]
                var newEmail = new Email(result[0], self.moduleId);
                newEmail.set_selectsData(self.recipientsArray, self.typeArray);
                self.emails.push(newEmail);
                self.editSingleDraft = true;
            }
            else {
                self.convList = self.process_emails(result);
                // Grab latest subject as it's the most current for the thread
                self.subject = self.convList[self.convList.length - 1].subject;
                self.contractId = self.convList[0].contractId;
                self.emails = [];

                for (var i = 0; i < self.convList.length; i++) {
                    self.emails.push(new Email(self.convList[i], self.moduleId));
                }

            }
    }

    //Conversation.prototype.reload_data = function (id) {
    //    var self = this;
    //    self.loading = true;
    //    var p = $q.defer();
    //    self[URI.EMAIL.DETAIL.method](URI.EMAIL.DETAIL, { conversationId: self.id }).then(function (result) {
    //        self.convList = result;
    //        self.emails = [];
    //        for (var i = 0; i < self.convList.length; i++) {
    //            var temp = new Email(self.convList[i]);
    //            self.emails.push(temp);
    //        }
    //        self.loading = false;
    //    }).catch(function (e) {
    //        console.error(e);
    //    }).finally(function () {
    //        p.resolve();
    //    });

    //    return p.promise;
    //};

    //Conversation.prototype.repopulate_recipients = function (contractId) {
    //    var self = this;
    //    self.loadingRecipients = true;
    //    Dictionaries.RecipientsList(contractId)
    //        .then(function () {
    //            if (self.emails[0].to.length) {
    //                var temp = [];
    //                for (var i = 0; i < self.emails[0].to.length; i++) {
    //                    for (var j = 0; j < self.recipientsArray.length; j++) {
    //                        if (self.recipientsArray[j].id == self.emails[0].to[i]) {
    //                            temp.push(self.emails[0].to[i]);
    //                            break;
    //                        }
    //                    }
    //                }

    //                self.emails[0].to = [];
    //                self.emails[0].to = temp;
    //            }
    //            if (self.emails[0].cc.length) {
    //                var temp = [];
    //                for (var i = 0; i < self.emails[0].cc.length; i++) {
    //                    for (var j = 0; j < self.recipientsArray.length; j++) {
    //                        if (self.recipientsArray[j].id == self.emails[0].cc[i]) {
    //                            temp.push(self.emails[0].cc[i]);
    //                            break;
    //                        }
    //                    }
    //                }
    //                self.emails[0].cc = [];
    //                self.emails[0].cc = temp;
    //            }
    //            self.loadingRecipients = false;
    //        })
    //        .catch(function () {

    //        });
    //}

    Conversation.prototype.openAttachment = function (attachment) {
        this.attachmentHandler.open(attachment);
    }

    Conversation.prototype.openAttachmentInNewTab = function (attachment) {
        this.attachmentHandler.openInNewTab(attachment);
    }

    Conversation.prototype.saveAttachment = function (attachment) {
        this.attachmentHandler.save(attachment);

    }

    return Conversation;
});
