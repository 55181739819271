import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    request = request.clone({
      setHeaders: {
        tenantId: this.getTenantId(),
        moduleId: this.getModuleId(),
        Authorization: `Bearer ${this.getToken()}`
      }
    });
    return next.handle(request);
  }

  private getToken(): string {
    return sessionStorage.getItem('msal.idtoken');
  }

  private getTenantId(): string {
    return sessionStorage.getItem('ls.CurrentTenant');
  }

  private getModuleId(): string {
    return sessionStorage.getItem('ls.currentModule');
  }
}