import { cipo } from 'cipo';

cipo.factory("WorkflowMod", function ($q, Model, Form, URI, ModuleDictionaries, Message, userService) {
    var WorkflowMod = Model.extend(function (moduleId, workflowId) {
        var self = this;
        self.moduleId = moduleId || null;
        self.properties = { reviseRoleIds: [] };
        self.sources = ["initiators", "startScreens"];
        self.workflowId = workflowId;
        self.get_Data();
    });

    WorkflowMod.prototype.get_Data = function () {
        var self = this;
        var dataURL = URI.WORKFLOW.GET;
        self[dataURL.method](dataURL, { url: { id: self.workflowId }, urltype: "obj" }, { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                if (r) {
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) {
                            self.properties[key] = r[key];
                        }
                    }
                }
                self.createForm();
            })
            .catch(function (e) { console.error(e) })
            .finally(function () { self.form.loading = false; });
    }

    WorkflowMod.prototype.createForm = function () {
        var self = this;
        self.form = new Form(self.properties);
        // self.form.initializing = true;
        self.form.loading = true;
        ModuleDictionaries.addUrlParameter({ workflowId: self.workflowId });
        ModuleDictionaries.getDicts(self.sources)
            .then(function (r) {
                var form = {
                    autoAssign: { label: 'Assign users on transitions automatically', type: 'checkbox', noErrorMsg: true, onChange: function (data) { self.onChangeProperty(data); } },
                    manualAssign: { label: 'Assign users on transitions manually', type: 'checkbox', noErrorMsg: true, onChange: function (data) { self.onChangeProperty(data); } },
                    ownership: { label: 'Only owner can change assignment or make a transition', type: 'checkbox', noErrorMsg: true },
                    autoApproval: { label: 'Auto approve for approvers', type: 'checkbox', noErrorMsg: true },
                    hidePastTransitionForExcluded: { label: 'Hide activity log items from excluded roles', type: 'checkbox', noErrorMsg: true },
                    reviseRoleIds: { label: 'Can create revisions', type: 'select', multiple: true, options: ModuleDictionaries.dataSources.initiators.data },
                    screenIds: { label: 'Start Screens', type: 'select', multiple: true, options: ModuleDictionaries.dataSources.startScreens.data, validation: { required: true } },
                    keepAssignmentOnStateChange: { label: 'Keep assignment on state change', type: 'checkbox', noErrorMsg: true, onChange: function (data) { self.onChangeProperty(data); } }
                }

                self.form.set_Description(form);

                var grid = [
                    { autoAssign: 50, manualAssign: 50 },
                    { ownership: 50, hidePastTransitionForExcluded: 50 },
                    { autoApproval: 50, keepAssignmentOnStateChange: 50 },
                    { screenIds: 100 },
                    { reviseRoleIds: 100 }
                ]

                self.form.setTemplate('grid', grid);

                self.form.set_Data(self.properties);
                self.form.store_Data();
            })
    }

    WorkflowMod.prototype.onChangeProperty = function (data){
        var self = this;
        if (data) {
            if (data._fieldProperty === 'autoAssign' && data._value){
                // Set manualAssign and keepAssignmentOnStateChange to false
                self.properties.manualAssign = false;
                self.properties.keepAssignmentOnStateChange = false;
            }

            if ((data._fieldProperty === 'manualAssign' || data._fieldProperty == 'keepAssignmentOnStateChange') && data._value){
                // Set autoAssign to false
                self.properties.autoAssign = false;
            }
        }
    }

    WorkflowMod.prototype.eqArray = function (arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }
    
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
    
        return true;
    }

    WorkflowMod.prototype.update = function () {
        var self = this;
        self.form.loading = true;
        var p = $q.defer();
        var dataURL = URI.WORKFLOW.EDIT;
        var screenIdsChanged = !self.eqArray(self.form.data.screenIds, self.form.originalData.screenIds);
        self[dataURL.method](dataURL, { body: self.properties }, { headers: { moduleId: self.moduleId } })
            .then(function () {
                self.form.store_Data();

                // reload the grid if screen ids are changed
                userService.isReloadGrid = screenIdsChanged;

                p.resolve();
                Message.info("Settings updated successfully");
            })
            .catch(function (e) { console.error(e); p.reject(); Message.dberror(e); })
            .finally(function () { self.form.loading = false; });
        return p.promise;
    }


    return WorkflowMod;
});
