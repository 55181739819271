import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../shared/services/http.service';
import { WeatherFieldModel } from 'src/app/models/module/fields/main/weatherField.model';

@Injectable()
export class WeatherTableService {
  route: string = '_api/weatherField'
  constructor(private httpService: HttpService) {}

  getContractWeatherDataByTime(weatherTableId: number, entityInstanceId: number, time: Date, fieldInstanceId: number): Observable<WeatherFieldModel> {
    let qs = `weatherTableId=${weatherTableId}&entityInstanceId=${entityInstanceId}&time=${time}&fieldInstanceId=${fieldInstanceId}`;
    return this.httpService.get<WeatherFieldModel>(`${this.route}/GetContractWeatherDataByTime?${qs}`);
  }

  createTimeFieldInstance(weatherTableId: number, entityInstanceId: number): Observable<any> {
    let qs = `weatherTableId=${weatherTableId}&entityInstanceId=${entityInstanceId}`;
    return this.httpService.post<any>(`${this.route}/CreateTimeFieldInstance?${qs}`, {});
  }

  getTimeFieldInstances(weatherTableId: number, entityInstanceId: number): Observable<WeatherFieldModel[]> {
    let qs = `weatherTableId=${weatherTableId}&entityInstanceId=${entityInstanceId}`;
    return this.httpService.get<WeatherFieldModel[]>(`${this.route}/GetTimeFieldInstances?${qs}`);
  }

  getTableTimeFieldId(weatherTableId: number, entityInstanceId: number): Observable<number> {
    let qs = `weatherTableId=${weatherTableId}&entityInstanceId=${entityInstanceId}`;
    return this.httpService.get<number>(`${this.route}/GetTableTimeFieldId?${qs}`);
  }
}
