import { cipo } from 'cipo';

cipo.directive("wfentityinstance", function ($timeout, $location, userService, $state, Message) {
    return {
        restrict: "E",
        scope: {
            "entityinstance": "=",
            "percontract": "="
        },
        templateUrl: "/ng/views/directives/dynamics/wfentityinstance.html",
        controller: function ($scope, $http, URI, $mdDialog) {
            
            if (!$scope.entityinstance.isDraft) {
                $scope.collapsedDetails = false;
            }

            $scope.startTransition = function (t, r) {

                //special case to deal with document archiving actions
                if (t.name == 'Create Archive') {
                    $scope.createArchive();
                    return;
                } 

                if (t.name == 'View Archives') {
                    $scope.viewArchives();
                    return;
                } 

                $scope.entityinstance.startTransition(t, r)
                    .then(function (autoTrigger) {
                        if (autoTrigger) $scope.triggerTransition();
                    })
                    .catch(function (e) { });
            }

                $scope.triggerTransition = function () {
                    $scope.entityinstance.triggerTransition()
                        .then(function (result) {
                            if (result && $state.current.name == 'tenant.dm.details') {
                                var params = {
                                    id: $scope.entityinstance.moduleId,
                                    contractId: userService.system.userdata.contractId,
                                    urlActionId: 0,
                                    urlActionEntityId: 0,
                                    entityId: result
                                };

                            try {
                                $state.transitionTo($state.current.name, params, {
                                    reload: true,
                                    inherit: true,
                                    notify: true
                                });
                            }
                            catch (e) {
                                console.error(e);
                            }
                        } else {
                            if (result) {
                                $scope.entityinstance.entityInstanceId = result;
                                $scope.entityinstance.isModified = true
                                $scope.entityinstance.presetId = null;
                                $scope.entityinstance.presetProperties = {};                                
                            }
                            $scope.entityinstance.init();
                        }
                    })
                    .catch(function (e) { });
            }

            $scope.createArchive = function () {
                var filter = {};
                var modulesCheckboxes = [];
                var cid;
                var cNo;

                $http[URI.CT.CONTRACT_DETAILS.method](URI.CT.CONTRACT_DETAILS.toString() + "?entityInstanceId=" + $scope.entityinstance.entityInstanceId)
                    .then(function (result) {
                        if (result.data) {
                            var selectedContract = userService.system.contracts.find(x => x.contractNo == result.data.contractNo);
                            cid = selectedContract.key;
                            cNo = selectedContract.contractNo;
                        }

                        $http[URI.MODULES.SEARCH.method](URI.MODULES.SEARCH.toString(), filter)
                            .then(function (result) {
                                if (result.data && result.data.data.length > 0) {
                                    for (var i = 0; i < result.data.data.length; i++) {
                                        if (result.data.data[i].perContract == true) {
                                            var desc = '(' + result.data.data[i].abbreviation + ')';
                                            if (!(result.data.data[i].projectFolderId != null)) {
                                                desc = desc + ' - This module has no Destination Folder defined!';
                                            }
                                            modulesCheckboxes.push({
                                                description: desc,
                                                abbr: result.data.data[i].abbreviation,
                                                key: result.data.data[i].id,
                                                value: result.data.data[i].name,
                                                label: result.data.data[i].name,
                                                folderId: result.data.data[i].projectFolderId,
                                                hasFolder: result.data.data[i].projectFolderId != null
                                            });
                                        }
                                    }
                                }

                                var dialogLocals = {
                                    data: {
                                        title: "Select the modules you would like to create an archive for:",
                                        description: " ",
                                        showSelectUnselect: true,
                                        buttonLabels: [
                                            { key: 'confirm', value: 'Create Archive' },
                                            { key: 'cancel', value: 'Cancel' },
                                        ],
                                        contractId: cid,
                                        contractNumber: cNo,
                                        checkboxes: modulesCheckboxes
                                    }
                                }

                                $mdDialog.show({
                                    templateUrl: '/ng/views/admin/modals/archiveModuleSelector.html',
                                    locals: dialogLocals,
                                    controller: 'archiveModuleSelectorController',
                                    parent: angular.element(document.body)
                                })
                                    .then(function (result) {

                                    }, function () {

                                    });
                            })
                            .catch(function (e) {
                                console.error(e);
                                Message.dberror(e);
                            })                    
                            })
                            .catch(function (e) {
                                console.error(e);
                                Message.dberror(e);
                            })                           
            };

            $scope.viewArchives = function () {
                var tenantName = userService.system.tenantsLookup[userService.tenantId].name.toLowerCase();
                var url = tenantName + "/filecontractmanager";

                $mdDialog.cancel();
                $location.url(url);                                  
            };

            // $scope.list = $scope.options.listKey ? $scope.parent[$scope.options.listKey] : [];
            Object.defineProperty($scope, 'operations', {
                get: function () { return userService.getOperationsFor($scope.entityinstance.moduleCode) }
            });
            Object.defineProperty($scope, 'nonBindingSignature', {
                get: function () {
                    return (userService.system.userdata.signature || {}).signatureText }
            });
            $scope.openMenu = function ($mdMenu, ev) {
                // originatorEv = ev;
                $timeout(function () {
                    $scope.focusSearch = true;
                    $timeout(function () {
                        $scope.focusSearch = false;

                    }, 300);
                }, 300);
                $mdMenu.open(ev);
            };
            

        }
    }
});
