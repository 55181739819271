import { cipo } from 'cipo';

cipo.directive("heading", function () {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "module": "=",
            "global": "=",
            "percontract": "="
        },
        templateUrl: "/ng/views/directives/system/heading.html",
        controller: function ($scope, userService, Menu, $state, Dictionaries, $timeout, $mdDialog, Message, $document, $window) {
            // console.log('heading params', $scope.percontract);
            Object.defineProperty($scope, 'userService', {
                get: function () {
                    return userService;
                }
            });
            const tenantIdentifier = userService.getTenantIdentifierById(userService.tenantId);
            if ($("#section-to-print").length != 0) {
                $("#section-to-print").remove();

            }

            //if ($scope.module) {
            //    Object.defineProperty($scope, 'operations', {
            //        get: function () { return userService.getOperationsFor(moduleCode) }
            //    });

            //    $scope.display = true;
            //} else {
            //    $state.go('tenant.404', null, { location: false });
            //}

            // make sure user can edit module configuration
            Object.defineProperty($scope, 'moduleOperations', {
                get: function () { return userService.getOperationsFor("M"); }
            });
            //userService.system.modules[module.code]
            Object.defineProperty($scope, 'currentModule', {
                get: function () {
                    return (userService.system.modules || {})[($scope.module || {}).code]; }
            });
            if (!$scope.module.roles) $scope.module.roles = [{key: 0, value: "No roles found"}];
            Dictionaries.RoleUsers({ id: $scope.module.moduleId, permissionId: 1, contractId: userService.system.userdata.contractId })
                .then(function (r) {
                    $scope.module.roles = r;
                })
                .catch(function (e) { Message.dberror(e); })

            if (!$scope.currentModule) {
                $state.go("tenant.404", { tenantIdentifier: tenantIdentifier });
            }

                $document[0].title = 'CIPO® ' + ($scope.currentModule || {}).name;
                if (($scope.module || {}).perContract || $scope.percontract) {

                $scope.openMenu = function ($mdMenu, ev) {
                    // originatorEv = ev;
                    // console.log('clicked', $mdMenu)
                    $timeout(function () {
                        $scope.focusSearch = true;
                        $timeout(function () {
                            $scope.focusSearch = false;

                        }, 300);
                    }, 300);
                    $mdMenu.open(ev);
                };
                $scope.contractSearch = { content: "" };
                $scope.$on("$mdMenuClose", function () { 
                    // console.log("menu closing"); 
                    $scope.contractSearch.content = ""; 
                });
                $scope.changeContract = function (contract) {
                    if (contract.key != $scope.inputs.contractValue) {
                        userService.setContract(contract.key);
                        Menu.triggerRefresh();

                        if ($state.current.code == 'DYN-MOD') {
                            var params = angular.copy($state.params);
                            var module = userService.getModuleObjectById($state.params.id) || {};

                            params.contractId = module.perContract ? contract.key : 0;
                            $state.transitionTo($state.current.name, params, {
                                reload: true,
                                inherit: true,
                                notify: true
                            });
                        }
                    }

                };
                
                if (($scope.module || {}).perContract) {
                    $scope.loadingContracts = true;
                    Dictionaries.Contracts({ moduleId: $scope.module.moduleId, userId: userService.system.userdata.id })
                        .then(function (r) {
                            r = r.filter(function (o) { return o.isUsed === true });
                            $scope.moduleContracts = r;
                        })
                        .catch(function () { })
                        .finally(function () { $scope.loadingContracts = false; });
                } else {
                    $scope.moduleContracts = userService.system.contracts;
                }
                

                $scope.inputs = {
                    contractValue: userService.system.userdata.contractId
                }

                $scope.contractSelect = function () {
                    for (var i = 0; i < $scope.contracts.length; i++) {
                        if ($scope.contracts[i].key == $scope.inputs.contractValue) {
                            var contractName = $scope.contracts[i].value;
                            break;
                        }
                    }
                    userService.setContract($scope.inputs.contractValue);
                    Menu.triggerRefresh();
                };
                $scope.$watch(function () {
                    return userService.system.userdata.contractId;
                },
                    function (newVal) {
                        if (typeof newVal != 'undefined') {
                            $scope.inputs.contractValue = userService.system.userdata.contractId
                        }
                    });
                // console.error('currentModule', $scope.module);
                $scope.$watch(function () {
                    return userService.isLoadingMenu;
                },
                    function (n, o) {
                        //console.error('userService.isLoadingMenu', n, o);
                        if (!n && $scope.module && typeof userService.getOperationsFor($scope.module.code).Read != 'undefined') {
                            // console.error('all good');
                        } else if (!n) {
                            //console.error('should redirect');
                            $state.go("tenant.404", { tenantIdentifier: tenantIdentifier });

                        }
                    });

            }

            $scope.sendMail = function (emailId) {
                $scope.copyClipboard(emailId);
                $window.open("mailto:" + emailId, "_self");
            };

            $scope.copyClipboard = function (emailId) {
                // create temp element
                var copyElement = document.createElement("span");
                copyElement.appendChild(document.createTextNode(emailId));
                copyElement.id = 'tempCopyToClipboard';
                angular.element(document.body.append(copyElement));

                // select the text
                var range = document.createRange();
                range.selectNode(copyElement);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);

                // copy & cleanup
                document.execCommand('copy');
                window.getSelection().removeAllRanges();
                copyElement.remove();

                Message.info('Email copied to clipboard.');
            }

            $scope.editModule = function (ev) {
                if ($scope.moduleOperations.Read || $scope.moduleOperations.Update) {
                    var obj = $scope.module;
                    $scope.module.isConfig = true;
                    var module = {
                        abbreviation: obj.code,
                        disabled: false,
                        iconId: obj.iconId,
                        id: obj.moduleId,
                        isSystem: obj.typeId == 3 ? true : false,
                        name: obj.name,
                        perContract: obj.perContract,
                        color: obj.color
                    };
                    $mdDialog.show({
                        locals: { obj: module, simplifiedVersion: false, operations: $scope.moduleOperations, module: $scope.module },
                        controller: 'editNModuleController',
                        templateUrl: '/ng/views/admin/modals/editNModule.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        fullscreen: true,
                        focusOnOpen: false,
                        escapeToClose: false,
                        multiple: true,
                        clickOutsideToClose: false
                    })
                    .then(function (result) {
                        //$scope.manager.page = 1;
                        //$scope.manager.loadPage();
                        
                        $scope.module.code = result.abbr;
                        Menu.triggerRefresh()
                            .then(function () {
                                $document[0].title = 'CIPO Cloud® ' + $scope.currentModule.name;
                            })
                            .catch(function () {
                            })
                        userService.isReloadGrid = result.isReloadGrid;
                        userService.isReloadModuleInformation = result.isReloadModuleInformation || false;
                        Dictionaries.RoleUsers({ id: $scope.module.moduleId, permissionId: 1, contractId: userService.system.userdata.contractId })
                            .then(function (r) {
                                $scope.module.roles = r;
                            })
                            .then(function () { })
                    }, function (isReloadGrid) {
                        
                        Menu.triggerRefresh()
                            .then(function () {
                                // console.error($scope.module);
                                if (!$scope.currentModule) {
                                    $state.go("tenant.404", { tenantIdentifier: tenantIdentifier });
                                } else {
                                    userService.isReloadGrid = isReloadGrid;
                                }
                                Dictionaries.RoleUsers({ id: $scope.module.moduleId, permissionId: 1, contractId: userService.system.userdata.contractId })
                                    .then(function (r) {
                                        $scope.module.roles = r;
                                    })
                                    .then(function () { })
                            })
                            .catch(function () {
                            })
                        // 
                        //$scope.manager.loadPage();
                    });
                }
            };
        }
    }
});