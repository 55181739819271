import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ScreenFieldFormattingModel } from 'src/app/models/module/fields/main';
import { DisplayType, FieldTypeEnum } from '../../../models/module/fields/enums';
import { DataCardDisplayTypeValues, DataCardViewPart } from '../../../models/module/main';

@Component({
  selector: 'app-data-card-setting-dialog',
  templateUrl: './data-card-setting-dialog.component.html',
  styleUrls: ['./data-card-setting-dialog.component.scss'],
})
export class DataCardSettingDialogComponent {
  part: DataCardViewPart;
  displayTypes: DisplayType[];
  changed = false;

  constructor(
    private translate: TranslateService,
    private dialogRef: MatDialogRef<DataCardSettingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public item: DataCardViewPart
  ) {
    this.part = new DataCardViewPart(item.id, item.field, true);
    this.part.field.labelFormatting = { ...item.field.labelFormatting };
    this.part.field.valueFormatting = { ...item.field.valueFormatting };
    this.part.field.displayTypeId = this.part.field.displayTypeId || DisplayType.Text;
    this.displayTypes = DataCardDisplayTypeValues.get(this.part.field.typeId || FieldTypeEnum.Text) || [];
  }

  get typeDescription(): string {
    const descType = this.item.field.typeId ? this.translate.instant('MODULE.FIELD_TYPE.' + FieldTypeEnum[this.item.field.typeId]) : '';
    const descCardDisplayType = this.translate.instant(
      'MODULE.DISPLAY_TYPE.' + DisplayType[this.item.displayTypeId]
    );
    if (this.item.field.typeId) {
      return descType;
    }
    if (!this.displayTypes.length) {
      return descCardDisplayType;
    }
    return '';
  }

  get showLabel(): boolean {
    return (
      (this.part.field.displayTypeId || DisplayType.Text) == DisplayType.Text && this.part.field.valueFormatting.size < 100
    );
  }

  get showSizeSetting(): boolean {
    return !this.part.isNumberIndicator && !this.part.isNumberProgress && !this.part.isState && !this.part.isLine;
  }

  get showValueFontColor(): boolean {
    return !this.part.isNumberIndicator && !this.part.isNumberProgress && !this.part.isState;
  }

  get showValueFontStyle(): boolean {
    return !this.part.isLine;
  }

  get showValueFontSize(): boolean {
    return !this.part.isLine;
  }

  get showValueAlignHorizontal(): boolean {
    return !this.part.isLine;
  }

  get showValueAlignVertical(): boolean {
    return !this.part.isLine;
  }

  displayTypeLabel(type: DisplayType) {
    return this.translate.instant('MODULE.DISPLAY_TYPE.' + DisplayType[type]);
  }

  changeDisplayType(type: DisplayType) {
    this.part.field.displayTypeId = type;
    this.changed = true;
  }

  labelSettingsChanged(value: ScreenFieldFormattingModel) {
    this.part.field.labelFormatting = value;
    this.changed = true;
  }

  valueSettingsChanged(value: ScreenFieldFormattingModel) {
    this.part.field.valueFormatting = value;
    this.changed = true;
  }

  save(): void {
    this.item.field = this.part.field;
    this.dialogRef.close();
  }
}
