import { cipo } from 'cipo';
import moment from 'moment';

    cipo.controller('dmController',
        function ($transition$, $scope, $state, $http, $uibModal, userService, Manager, WorkflowEntityInstance, dialogs, URI,
            ACTIONS, URL_ACTIONS, $mdDialog, Message, AggregateOperators, Permissions, $timeout, $q, rememberManagerService, $stateParams) {
            const params = $transition$.params()
            var moduleId = params.id || null;
            var moduleCode = userService.getModuleIdentifierById(moduleId);
            $scope.module = userService.system.modules[moduleCode];
            $scope.initiator = false;
            $scope.canPrint = false;
            $scope.subMenusModel = "";

        //$scope.AggregateOperators = [];
        //$scope.bottomFormulas = [];
        var bottomFormulasLookup = {};
        var statusMap = new Map();
        var transitionsMap = new Map();               

        var setBottomFormulas = function () {
            $scope.AggregateOperators = [];
            $scope.bottomFormulas = [];
            bottomFormulasLookup = {};

            for (var key in AggregateOperators) {
                if (AggregateOperators.hasOwnProperty(key)) {
                    $scope.AggregateOperators.push({ key: parseInt(key), value: AggregateOperators[key] });
                    var op = {
                        key: parseInt(key), value: AggregateOperators[key], fields: {}, fieldIds: []
                    };
                    $scope.bottomFormulas.push(op);
                    bottomFormulasLookup[key] = op;
                }

            }
        }
        // setBottomFormulas();
        

        if ($scope.module) {
            Object.defineProperty($scope, 'operations', {
                get: function () { return userService.getOperationsFor(moduleCode) }
            });

            $scope.display = true;
        } else {
            $state.go('tenant.404', null, { location: false });
        }
        $scope.sendList = function (data) {
            var listToSend = [];
            if (Object.prototype.toString.call(data) == '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].entity_instance_id != 'undefined' && data.entity_instance_id != null) {
                            listToSend.push(data[i].entity_instance_id);
                        }
                    }
                }
            } else {
                if (typeof data.entity_instance_id != 'undefined' && data.entity_instance_id != null) {
                    listToSend.push(data.entity_instance_id);
                }
            }
            return listToSend;
        }

        $scope.sendListOfClosed = function (data) {
            var listToSend = [];
            var disqualified = "";
            if (Object.prototype.toString.call(data) == '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].entity_instance_id != 'undefined' && data[i].is_signed) {
                            listToSend.push(data[i].entity_instance_id);
                        } else {
                            disqualified += disqualified + data[i].full_doc_num;
                            if (i != data.length - 1) disqualified += ", ";
                            data[i].selected = false;
                        }
                    }
                }
                if (disqualified != "") Message.warning("Documents " + disqualified + " are not signed and can't be downloaded.");
            } else {
                if (typeof data.entity_instance_id != 'undefined' && data.is_signed) {
                    listToSend.push(data.entity_instance_id);
                }
            }
            return listToSend;
        }

        // hybrid temporary changes
        var dataURL = typeof URI[moduleCode.toUpperCase()] != "undefined" ?
            URI[moduleCode.toUpperCase()].GRID_SEARCH : URI.MODULE_GRID.SEARCH;
        var presetsDataURL = URI.MODULE_GRID.SEARCH_PRESETS;
        var columnsURL = typeof URI[moduleCode.toUpperCase()] != "undefined" ?
            URI[moduleCode.toUpperCase()].GRID_FIELDS : URI.MODULE_GRID.FIELDS;

        var printHeader = function () {
            var header;
            if ($scope.module.perContract)
                header = "<table style='margin-bottom: 15px; border-top: 2px solid #bbb; border-bottom: 2px solid #bbb;'><tr><td style='width: 25%;'>"
                    + userService.system.tenantsLookup[userService.tenantId].description + "</td>"
                    + "<td rowspan='2' class='text-center'><h3 style='margin: 15px 0;'>" + $scope.module.name + "</h3></td><td class='text-right' style='width: 25%;'>"
                    + (userService.system.contractsLookup[userService.system.userdata.contractId] || {}).value + "</td></tr>"
                    + "<tr><td>" + (userService.system.contractsLookup[userService.system.userdata.contractId] || {}).contractNo + "</td><td class='text-right'>" + moment(new Date()).format(userService.formats.date) + "</td></tr>"
                    + "</table>";
            else {
                header = "<table style='margin-bottom: 15px; border-top: 2px solid #bbb; border-bottom: 2px solid #bbb;'><tr><td style='width: 25%;'>"
                    + userService.system.tenantsLookup[userService.tenantId].description + "</td>"
                    + "<td class='text-center'><h3 style='margin: 15px 0;'>" + $scope.module.name + "</h3></td><td class='text-right' style='width: 25%;'>"
                    + moment(new Date()).format(userService.formats.date) + "</td></tr>"
                    + "</table>";
            }
            return header;
        }

        var addZ = function (date) {
            var ret = date || null;
            if (date && date.indexOf("Z") == -1)
                ret = date + "Z";
            return ret;
        }

            if ($scope.module)
                $scope.manager = new Manager({
                    objectsPerPage: 50,
                    dataURL: dataURL,
                    presetsDataURL: presetsDataURL,
                    urlParams: {
                        contractId: userService.system.userdata.contractId,
                        moduleId: moduleId
                    },
                    isUseLoadMoreSetting: true,
                    moduleId: $scope.module.moduleId,
                    moduleName: $scope.module.name,
                    moduleCode: $scope.module.code,
                    contractId: userService.system.userdata.contractId,
                    contractNo: $scope.module.perContract ? (userService.system.contractsLookup[userService.system.userdata.contractId] || {}).contractNo : "",
                    correspondenceOperations: userService.getOperationsFor("C"),
                    options: {
                        multiselect: true
                    },
                    rowOnClick: function (row) {
                        $scope.saveItem(row, 0, row.screen_id);
                    },
                    rowClass: function (row) {
                        if (row._is_draft) return "draftRow";
                        else if (row.entity_template_id && !row.entity_instance_id) return "presetRow";
                    },
                    dataWrapper: 'data',
                    printHeader: printHeader(),
                    parseData: function (data, columns) {
                        // for legacy purposes the current 'name' is the actual id of the fields/columns
                        // console.log('before parsing', angular.copy(data), angular.copy(columns));
                        var data = data || [], retval = [], columnsLookup = {}, stateNameId;
                        for (var j = 0; j < columns.length; j++) {
                            columnsLookup[columns[j].replacementFor] = columns[j];
                            if (columnsLookup[columns[j].replacementFor].fieldName == 'state_name') stateNameId = columns[j].replacementFor;
                        }

                    for (var i = 0; i < data.length; i++) {
                        data[i]._is_draft = data[i].is_draft;
                        data[i]._in_my_court = data[i].in_my_court;
                        if (data[i].needs_approval) data[i].state_name = 'In Approval';
                        if (data[i].entity_template_id && !data[i].entity_instance_id) {
                            data[i].state_name = 'Preset';
                            // data[i].state_color = '#fff';
                            data[i].state_text_color = '#1ab393';
                        }


                        for (var key in data[i]) {
                            if (data[i].hasOwnProperty(key) && typeof columnsLookup[key] != 'undefined') {
                                if (columnsLookup[key].typeId == 3)
                                    data[i][key] = ([true, false].indexOf(data[i][key]) != -1) ? (data[i][key] ? "Yes" : "No") : data[i][key]||"-";
                                    //date
                                else if (columnsLookup[key].typeId == 4 && data[i][key]) {
                                    var format = userService.formats.datetime;
                                    if (columnsLookup[key].restrictionsLookup[5] == 5) {
                                        format = userService.formats.time;
                                        data[i][key] = moment(addZ(data[i][key])).format(format);
                                    } else if (columnsLookup[key].restrictionsLookup[5] == 3) {
                                        format = userService.formats.date;
                                        data[i][key] = moment.utc(addZ(data[i][key])).format(format);
                                    } else {
                                        data[i][key] = moment(addZ(data[i][key])).format(format);
                                    }
                                }
                                else if (columnsLookup[key].typeId == 5 && columnsLookup[key].showThumbnail) {
                                    if (data[i][key] && data[i][key + '_thumb'])
                                        data[i][key + '_thumb'] = '<span class=""><img src="data:'
                                            + 'image/jpeg;base64,'
                                            + data[i][key + '_thumb'] + '" />'
                                            + '</span>';
                                    else {
                                        data[i][key + '_thumb'] = data[i][key] ? "<i title='Thumbnail Missing' class='neutralText mdi mdi-image-minus'></i>": "-";
                                        data[i][key] = "-";
                                        
                                    }
                                }

                                else data[i][key] = (data[i][key]||"-").toString();
                            }
                        }

                        data[i].permissions = {};
                        // map permissions
                        if (data[i].permission_list) {                                
                            data[i].permission_list = data[i].permission_list.split(",");
                            for (var j = 0; j < data[i].permission_list.length; j++) {
                                Object.assign(data[i].permissions, Permissions[data[i].permission_list[j]]);
                            }
                        }

                        retval.push(data[i]);
                    }

                    //loads and caches the transactions for each distinct type of status 
                    //that each entity instance from the data array can have
                    $scope.loadAllTransitions(data);

                        //console.log('parsed data', retval);
                        return retval;
                    },
                    leftActions: [
                        {
                            setProperties: ACTIONS.CREATE,
                            // name: 'Create new',
                            conditionOnTop: function () {
                                if ($scope.initiator && $scope.hasStartScreenFields && $scope.hasStartScreens) return true;
                                else return false;
                            },
                            click: function (rows, screen) {
                                $scope.saveItem(null, 0, screen);
                            }
                        },
                    ],
                    actions: [
                        // TOP ACTIONS
                        {
                            setProperties: ACTIONS.REFRESH,
                            click: function () {
                                $scope.manager.refresh();
                            }
                        },
                        {
                            setProperties: ACTIONS.MORE,
                            name: "More Actions",
                            actions: [
                                {
                                    setProperties: ACTIONS.MANAGE_PRESETS,
                                    alwaysOnRow: false,
                                    click: function () {
                                        $scope.managePresets();
                                    },
                                    conditionOnTop: function () {

                                        return $scope.manager.hasPresets;
                                    }
                                },
                                {
                                    setProperties: ACTIONS.WORKFLOW,
                                    name: "Action",                                    
                                    isGroup: true,
                                    hasSubMenus: true,
                                    actions: [
                                        {
                                            //feed the repeater
                                            alwaysOnTop: true,
                                            visible: true,
                                            dynamicData: true,
                                            id: "id",
                                            name: "name",
                                            orderBy: "name",
                                            model: $scope.subMenusModel,
                                            data: [],                                           
                                            conditionOnTop: function (dataId) {
                                                if ($scope.folderId != dataId) {
                                                    return true;
                                                }
                                                else return false;
                                            },
                                            click: function (transitionName, roleName) {
                                                var selectedRows = ($scope.manager.selectedRows() || []);                                                
                                                $scope.executeBulkTransitions(selectedRows, selectedRows[0].state_name, transitionName, roleName);                                               
                                            }
                                        }
                                    ],
                                    conditionOnTop: function () {
                                        var selectedRows = ($scope.manager.selectedRows() || []);
                                        var show = selectedRows.length > 1 && selectedRows.every((r) => r.state_name == selectedRows[0].state_name);
                                        if (show) {
                                            var menuList = transitionsMap.get(selectedRows[0].state_name);
                                            if (menuList == null || menuList.length == 0) {
                                                show = false;
                                            }
                                            else {
                                                $scope.manager.actions[1].actions[1].actions[0].data = transitionsMap.get(selectedRows[0].state_name);
                                            }
                                        }
                                        return show;                                        
                                    },
                                    click: function (transitionName) {
                                        var selectedRows = ($scope.manager.selectedRows() || []);
                                        $scope.executeBulkTransitions(selectedRows, selectedRows[0].state_name, transitionName, null); 
                                    }
                                },
                                {
                                    setProperties: ACTIONS.DOWNLOAD,
                                    name: "Download Documents",
                                    alwaysOnRow: false,
                                    click: function (rows) {
                                        $scope.manager.download(rows);
                                    },
                                    conditionOnTop: function () {
                                        return $scope.canPrint;
                                    }
                                },
                                {
                                    setProperties: ACTIONS.EXCEL,
                                    name: "Download Log",
                                    click: function (rows) {
                                        $scope.manager.exportAsExcel();
                                    }
                                },
                                {
                                    setProperties: ACTIONS.EXCEL,
                                    name: "Import documents",
                                    click: function () {
                                        $scope.manager.openImportEntitiesDialog();
                                    },
                                    conditionOnTop: function () {
                                        return ($scope.operations.Create || $scope.operations.Update) ? true : false;
                                    }
                                },
                                
                                {
                                    setProperties: ACTIONS.EMAIL,
                                    click: function (rows) {
                                        $scope.manager.email($scope, rows);
                                    },
                                    conditionOnTop: function () {
                                        return $scope.operations.Read && $scope.canPrint ? true : false;
                                    }
                                },
                                {
                                    setProperties: ACTIONS.DOWNLOAD_PDF_VAR,
                                    name: "Print Documents",
                                    noLabel: false,
                                    click: function (rows) {
                                        $scope.manager.printDocuments(rows);
                                    },
                                    conditionOnTop: function () {
                                        return $scope.operations.Print && $scope.canPrint ? true : false;
                                    }
                                },
                                {
                                    setProperties: ACTIONS.DOWNLOAD_PDF_VAR,
                                    name: "Print PDF",
                                    noLabel: false,
                                    alwaysOnRow: true,
                                    alwaysOnTop: false,
                                    click: function (rows) {
                                        $scope.manager.printDocuments(rows, true);
                                    },
                                    condition: function () {
                                        return $scope.operations.Print && $scope.canPrint ? true : false;
                                    }
                                },
                                {
                                    setProperties: ACTIONS.LOG,
                                    name: "Print Log",
                                    click: function () {
                                        $scope.manager.printLog();
                                    },
                                    conditionOnTop: function () {
                                        return $scope.operations.Print ? true : false;
                                    }
                                },
                                {
                                    setProperties: ACTIONS.REPORTS,
                                    // name: "Reports",
                                    click: function () {
                                        $scope.manager.init_reports($scope.module);
                                    },
                                    conditionOnTop: function () {
                                        return $scope.operations['Read Reports'] ? true : false;
                                        // return true;
                                    }
                                },
                                {
                                    setProperties: ACTIONS.DELETE,
                                    name: "Delete documents",
                                    noLabel: false,
                                    alwaysOnRow: false,
                                    click: function (rows) {
                                        if (!(rows && rows.length)) {
                                            return;
                                        }
                                        
                                        var entityId = rows[0].entity_id;
                                        var entityInstanceIds = rows.map((r) => r.entity_instance_id);
                                        $scope.deleteItems(entityId, entityInstanceIds);
                                    },
                                    conditionOnTop: function () {
                                        var selectedRows = ($scope.manager.selectedRows() || []);
                                        return selectedRows.length && selectedRows.every((r) => r.is_draft == true);
                                    }
                                }                                
                            ]
                        },
                        // ROW ACTIONS
                        {
                            setProperties: ACTIONS.DELETE,
                            alwaysOnTop: false,
                            alwaysOnRow: true,
                            condition: function (row) {

                                return row.is_draft == true ||
                                    (row.doc_num_increment && row.doc_num_increment < $scope.docNumAutoIncrementStart
                                        && $scope.operations.Delete);
                            },
                            click: function (row) {
                                $scope.deleteItem(row.entity_instance_id);
                            }
                        },
                        {
                            setProperties: ACTIONS.DETAILS,
                            click: function (row) {
                                $scope.saveItem(row, 0, row.screen_id);
                            }
                        },
                        {
                            setProperties: ACTIONS.DOCUMENTS,
                            name: "Print PDF",
                            noLabel: false,
                            alwaysOnRow: true,
                            alwaysOnTop: false,
                            click: function (row) {
                                $scope.manager.printDocuments(row, true);
                            },
                            condition: function (row) {
                                return $scope.operations.Print && $scope.canPrint && row.entity_instance_id && row.closed_on ? true : false;
                            }
                        },
                        {
                            setProperties: ACTIONS.DOWNLOAD,
                            name: 'Download PDF',
                            alwaysOnRow: true,
                            condition: function (row) {
                                return row.entity_instance_id && $scope.canPrint ? true : false;
                            },
                            click: function (row) {
                                $scope.manager.download(row);                                
                            }
                        },
                        {
                            setProperties: ACTIONS.DUPLICATE,
                            click: function (row) {
                                $scope.saveItem(null, row.entity_instance_id, row.screen_id);
                            },
                            condition: function (row) {
                                if ($scope.initiator && $scope.hasStartScreenFields && row.entity_instance_id) return true;
                                else return false;
                            }
                        },
                        {
                            setProperties: ACTIONS.EMAIL,
                            alwaysOnRow: true,
                            alwaysOnTop: false,
                            click: function (row) {
                                $scope.manager.email($scope, row);
                            },
                            condition: function (row) {
                                return $scope.operations.Read && row.entity_instance_id && $scope.canPrint ? true : false;
                            }
                        },
                        {
                            setProperties: ACTIONS.DOCUMENTS,
                            name: "Print Documents",
                            noLabel: false,
                            alwaysOnRow: true,
                            alwaysOnTop: false,
                            click: function (row) {
                                $scope.manager.printDocuments(row);
                            },
                            condition: function (row) {
                                return $scope.operations.Print && $scope.canPrint && row.entity_instance_id ? true : false;
                            }
                        }
                        // END ROW ACTIONS
                    ]
                });
           
            var isColumnsLoaded = false;
            var getGridColumns = function () {
                
                var p = $q.defer();
                $scope.manager[columnsURL.method](columnsURL,
                    { url: { contractId: userService.system.userdata.contractId, workflowId: userService.system.workflowId }, urltype: 'obj' },
                    { headers: { moduleId: moduleId } })
                    .then(function (result) {

                    var currentFieldsLookup = {};

                        var r = result.fields || [];
                        $scope.initiator = result.userIsInitiator ? result.userIsInitiator : false;
                        $scope.canPrint = result.canPrint;
                        $scope.hasStartScreenFields = result.hasStartScreenFields ? result.hasStartScreenFields : false;
                        $scope.hasStartScreens = result.startScreens && result.startScreens.length;
                        $scope.docNumAutoIncrementStart = result.docNumAutoIncrementStart;
                        var columns = [];

                    if (!$scope.hasStartScreenFields) Message.warning("The start screen has no fields.");

                    $scope.manager.hasPresets = result.hasPresets;
                    $scope.manager.moduleInfo = result.information;

                    if (r && r.length) {
                        var widthBits = 0;
                        for (var i = 0; i < r.length; i++) {
                            if (!r[i].isFilter) {
                                r[i].width = r[i].width || 1;
                                widthBits += parseInt(r[i].width);
                            }
                            
                        }
                        for (var i = 0; i < r.length; i++) {
                            if (!r[i].isSystem || r[i].name == "Name") {
                                //set flags
                                if ((1 & r[i].priority) != 0) r[i].onPhone = true;
                                else r[i].onPhone = false;
                                if ((2 & r[i].priority) != 0) r[i].onTablet = true;
                                else r[i].onTablet = false;
                                r[i].restrictionsLookup = {};

                                // put date restriction if no restrictions on a datetime field
                                if (r[i].typeId == 4 && !(r[i].restrictions || []).length) {
                                    r[i].restrictions = [{ key: 5, value: 3 }];
                                    r[i].options = "YYYY-MM-DD";
                                    
                                }
                                    

                                if ((r[i].restrictions || []).length) {
                                    for (var j = 0; j < r[i].restrictions.length; j++) {
                                        r[i].restrictionsLookup[r[i].restrictions[j].key] = r[i].restrictions[j].value || true;
                                    }
                                }

                                    var column = {
                                        width: 100 / widthBits * r[i].width,
                                        onPhone: r[i].onPhone,
                                        onTablet: r[i].onTablet,
                                        name: r[i].id > 0 ? r[i].id : r[i].name,
                                        id: r[i].id,
                                        fieldName: r[i].name,
                                        replacementFor: r[i].id > 0 ? r[i].id : r[i].name,
                                        label: r[i].label,
                                        type: r[i].name =='state_name' ? 'state' : 'checkbox',
                                        typeId: r[i].typeId,
                                        displayTypeId: r[i].displayTypeId,
                                        dataSourceId: r[i].dataSourceId || null,
                                        relationId: r[i].relationId || null,
                                        fieldTypeId: r[i].fieldTypeId,
                                        options: r[i].options || "",
                                        restrictionsLookup: r[i].restrictionsLookup,
                                        isPrimarySort: r[i].isPrimarySort,
                                        isDescending: r[i].isDescending,
                                        showThumbnail: r[i].showThumbnail,
                                        printable: r[i].printable,
                                        isFilter: r[i].isFilter,
                                        // valueLookup: dataSourceLookup,
                                        // dataSourceValues: r[i].dataSourceValues
                                    }

                                    columns.push(column);

                                currentFieldsLookup[column.replacementFor] = column;
                            }
                        }
                    }

                    $scope.manager.set_Screens(result.startScreens);
                    $scope.manager.set_Columns(columns);
                    isColumnsLoaded = true;

                    setBottomFormulas();

                    // bottom formulas
                    if ((result.bottomFormulas || []).length) {
                        for (var i = 0; i < result.bottomFormulas.length; i++) {
                            if (result.bottomFormulas[i].operator != 5) {
                                bottomFormulasLookup[result.bottomFormulas[i].operator].fields[result.bottomFormulas[i].fieldId] = result.bottomFormulas[i];
                                bottomFormulasLookup[result.bottomFormulas[i].operator].fieldIds.push(result.bottomFormulas[i].fieldId);
                            }

                        }


                        for (var index = 0; index < $scope.bottomFormulas.length; index++) {
                            (function (i) {
                                Object.defineProperty($scope.bottomFormulas[i], "visibleOnTablet", {
                                    get: function () {
                                        var visible = false;
                                        for (var key in $scope.bottomFormulas[i].fields) {
                                            if ($scope.bottomFormulas[i].fields.hasOwnProperty(key))
                                                if ((currentFieldsLookup[key] || {}).onTablet) {
                                                    visible = true;
                                                    break;
                                                }
                                        }
                                        return visible;
                                    }
                                })

                                Object.defineProperty($scope.bottomFormulas[i], "visibleOnPhone", {
                                    get: function () {
                                        var visible = false;
                                        for (var key in $scope.bottomFormulas[i].fields) {
                                            if ($scope.bottomFormulas[i].fields.hasOwnProperty(key))
                                                if ((currentFieldsLookup[key] || {}).onPhone) {
                                                    visible = true;
                                                    break;
                                                }
                                        }
                                        return visible;
                                    }
                                })

                            })(index);

                        }

                        $scope.manager.bottomFormulas = $scope.bottomFormulas;
                        

                    }
                    else {
                        $scope.manager.bottomFormulas = [];
                    }
                    $scope.manager.globalFilters = result.globalFilters || null;
                    $scope.manager.topFormulas = result.topFormulas;

                    $scope.manager.loading = true;

                    if ($scope.manager.dataParams.filter) delete $scope.manager.dataParams.filter;
                    $scope.manager.hasFilters = false;
                    $scope.manager.currentFilters = null;
                    $scope.manager.loadPage(null, null, true)
                        .finally(function () {
                            $scope.manager.loading = false;
                            rememberManagerService.set($scope.manager, moduleId);
                        });
                        

                    p.resolve(result);
                })
                .catch(function (e) {
                    $scope.manager.loading = false;
                    p.reject();
                    console.error(e);
                })
                .finally(function () {
                    //
                })
            return p.promise;

            }


        


        var confirm = function (title, text, ev) {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        }
        $scope.deleteItem = function (id) {
            $mdDialog.show(confirm('Deleting Document(s)', 'Are you sure you want to delete the selected Document(s)?'))
                .then(function () {
                    var deleteURL = typeof URI[moduleCode.toUpperCase()] != "undefined" ?
                        URI[moduleCode.toUpperCase()].DELETE : URI.MODULE.DELETE;

                        // $scope.manager.loading = true;
                        $scope.manager[deleteURL.method](deleteURL.toString(), { url: { moduleId: moduleId, entityInstanceId: id, contractId: userService.system.userdata.contractId }, urltype: 'obj' })
                            .then(function () {
                                $scope.manager.page = 1;
                                $scope.manager.loadPage();
                                Message.info('Document(s) deleted successfully');
                            })
                            .catch(function (e) {
                                if (e.data.ids) {
                                    var messageContent = $scope.processError(e.data.ids, "deleted");
                                    dialogs.notify("Deleting Instances", messageContent);
                                } else {
                                    Message.dberror(e, $scope.manager.rows, 'id', 'name');
                                }
                            })
                    });
            }

            $scope.deleteItems = function (entityId, entityInstanceIds) {
                $mdDialog.show(confirm('Deleting Document(s)', 'Are you sure you want to delete the selected Document(s)?'))
                    .then(function () {
                        var deleteURL = URI.MODULE.DELETE_MULTIPLE;
                        var params = {
                            url: {
                                moduleId: moduleId,
                                entityId: entityId
                            },
                            body: entityInstanceIds,
                            urltype: 'obj'
                        }
                        $scope.manager[deleteURL.method](deleteURL.toString(), params)
                            .then(function () {
                                $scope.manager.page = 1;
                                $scope.manager.loadPage();
                                Message.info('Document(s) deleted successfully');
                            })
                            .catch(function (e) {
                                if (e.data.ids) {
                                    var messageContent = $scope.processError(e.data.ids, "deleted");
                                    dialogs.notify("Deleting Instances", messageContent);
                                } else {
                                    Message.dberror(e, $scope.manager.rows, 'id', 'name');
                                }
                            })
                    });
            }

        $scope.loadAllTransitions = function (rows) {              

            // First clear statusMap because after GridSearch (refreshing data), what we have in statusMap can be old
            statusMap = new Map();

            for (let i = 0; i < rows.length; i++) {
                var status = rows[i].state_name;
                if (!statusMap.has(status)) {
                    statusMap.set(status, rows[i].entity_instance_id);                        
                }                    
            }

            if (statusMap.size > 0) {
                var keys = Array.from(statusMap.keys());
                var entityInstanceIds = Array.from(statusMap.values());

            }

            for (let j = 0; j < entityInstanceIds.length; j++) {
                $scope.getTransitions(entityInstanceIds[j], keys[j]);
            }
        }

        $scope.getTransitions = function (entityInstanceId, status) {           
            var dataURL = URI.MODULE.GET_TRANSITIONS;
            var urlParams = {
                workflowId: userService.system.workflowId,
                entityInstanceId: entityInstanceId || 0,
                contractId: userService.system.userdata.contractId || 0
            };               
            
            $scope.manager[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': $stateParams.id } })
                .then(function (r) {
                    if (r != null && r.length > 0) {
                        var trList = r.filter(e => e.isUsedInMultiple == true);
                        r = trList;
                    }
                    transitionsMap.set(status, r);
                })
                .catch(function (e) {
                    Message.dberror(e);
                    console.error(e);
                })
        }

        $scope.executeBulkTransitions = function (rows, statusName, transitionName, roleName) {
            var entityInstanceIdList = (rows || []).map(r => r.entity_instance_id).filter(r => r);
            var presetsIdList = (rows || []).map(r => r.entity_template_id).filter(r => r);
            var trList = transitionsMap.get(statusName);
            var transition = trList.find(t => { return t.name === transitionName });

            var role = !(!transition.roles) && transition.roles.length > 0 ?
                (transition.roles.length === 1 ? transition.roles[0] : transition.roles.find(r => { return r.value === roleName })) : null;

                if (rows && rows.length) {
                    $mdDialog.show({
                        locals: {
                            module: userService.system.modules[moduleCode],
                            contractId: userService.system.userdata.contractId,
                            entityInstanceIdList,
                            presetsIdList,
                            transition,
                            role
                        },
                        controller: 'SaveMultipleWorkflowEntityInstanceController',
                        templateUrl: '/ng/views/dynamics/modals/saveMultipleWorkflowEntityInstance.html',
                        parent: angular.element(document.body),
                        fullscreen: true,
                        escapeToClose: false,
                        multiple: true,
                        clickOutsideToClose: false
                    })
                    .then(function () {
                        $scope.manager.page = 1;
                        $scope.manager.loadPage();
                    });
                }
            }

        $scope.saveItem = function (row, fromItemId, screen) {
            var itemId = row ? row.entity_instance_id * 1 : 0;
            fromItemId = fromItemId * 1;         

            var params = $state.params;
            params.moduleId = moduleId;
            params.urlActionId = 0;
            params.urlActionEntityId = 0;
            params.entityId = itemId;
            params.screenId = screen;

            if (fromItemId) {
                params.urlActionId = URL_ACTIONS.CLONE;
                params.urlActionEntityId = parseInt(fromItemId);
            } else if (row && row.entity_template_id) {
                params.urlActionId = URL_ACTIONS.PRESET;
                params.urlActionEntityId = row.entity_template_id;
            }

            $state.go($state.current.name + '.details', params);
        };

        
        var get_PresetsStatus = function () {

            var p = $q.defer();

            $scope.manager[URI.MODULE_GRID.HAS_PRESETS.method](URI.MODULE_GRID.HAS_PRESETS,
                { url: { contractId: userService.system.userdata.contractId, moduleId: moduleId }, urltype: 'obj' },
                { headers: { moduleId: moduleId } })
                .then(function (r) { $scope.manager.hasPresets = r; p.resolve(r); })
                .catch(function (e) { p.reject(e); })
            return p.promise;
        }

        $scope.managePresets = function (ev) {                
            $mdDialog.show({
                locals: { module: userService.system.modules[moduleCode] },
                controller: 'ManagePresetsController',
                templateUrl: '/ng/views/dynamics/modals/managePresets.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                fullscreen: true,
                escapeToClose: false,
                multiple: true,
                clickOutsideToClose: false
            })
                .then(function (result) {
                    get_PresetsStatus();
                    $scope.manager.loadPage()
                    
                    // getGridColumns();
                }, function () {
                    // $scope.manager.loadPage();
                });
        };

        /*$scope.$watch(function () {
            return userService.system.userdata.contractId;
        },
            function (newVal) {
                if (typeof newVal != 'undefined' && $scope.module.perContract) {
                    console.log('contract watch');
                    if (!isColumnsLoaded) {
                        getGridColumns();
                    } else {
                        if ($scope.manager.dataParams.filter) delete $scope.manager.dataParams.filter;
                        $scope.manager.currentFilters = null;
                        $scope.manager.hasFilters = false;
                        $scope.manager.closeFilters();
                        $scope.manager.urlParams = {
                            contractId: userService.system.userdata.contractId,
                            moduleId: moduleId
                        }
                        $scope.manager.page = 1;
                        $scope.manager.printHeader = printHeader();
                        $scope.manager.loadPage();
                    }

                }
            });*/

        var loadManager = function () {
            if (rememberManagerService.moduleId == moduleId) {
                $scope.manager = rememberManagerService.get();
                if (rememberManagerService.entityInstanceId) {
                    $scope.manager.parseEntityInstance(rememberManagerService.entityInstanceId);
                    rememberManagerService.entityInstanceId = 0;
                }
                
                $scope.manager.loading = false;
            } else {
                if (!isColumnsLoaded) {
                    // Get workflow for the module
                    userService.getWorkflow(moduleId)
                        .then(function() {           
                            getGridColumns();
                        });
                } else {
                    if ($scope.manager.dataParams.filter) delete $scope.manager.dataParams.filter;
                    $scope.manager.currentFilters = null;
                    $scope.manager.hasFilters = false;
                    $scope.manager.closeFilters();
                    $scope.manager.urlParams = {
                        contractId: userService.system.userdata.contractId,
                        moduleId: moduleId
                    }
                    $scope.manager.page = 1;
                    $scope.manager.printHeader = printHeader();
                    $scope.manager.loadPage();
                }

                rememberManagerService.set($scope.manager, moduleId);
            }
        }

        loadManager();

        $scope.$watch(function () {
            return userService.isReloadGrid;
        },
            function (n, o) {
                if (n) {
                    $scope.manager.contractId = userService.system.userdata.contractId;
                    $scope.manager.urlParams.contractId = userService.system.userdata.contractId;
                    $scope.manager.loading = true;
                    getGridColumns();
                    userService.isReloadGrid = false;

                }
            });

        $scope.$watch(function () {
            return rememberManagerService.entityInstanceId;
        },
            function (n, o) {
                if (n) {
                    loadManager();
                }
            });
    });
