<div fxLayout="row"
     fxLayoutAlign="space-between center" mat-dialog-title>
    <h4 class="setting-title">{{ 'MODULE_DEF_CARD.PART_DEFINITION.TITLE_EDIT' | translate }}</h4>
    <span class="setting-label" fxFlex>{{part?.field?.label}}</span>
    <span class="setting-type">{{typeDescription}}</span>
    <div *ngIf="displayTypes.length">
        <button mat-button
                [matMenuTriggerFor]="menu">
            <span>{{displayTypeLabel(part.field.displayTypeId)}}</span>
            <mat-icon>expand_more</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item
                    *ngFor="let displayType of displayTypes"
                    (click)="changeDisplayType(displayType)">
                {{displayTypeLabel(displayType)}}
            </button>
        </mat-menu>
    </div>
</div>
<mat-dialog-content>
    <app-data-card-setting-part [desc]="'MODULE_DEF_CARD.PART_DEFINITION.LABEL' | translate"
                                *ngIf="showLabel"
                                [showColonSetting]="true"
                                [settings]="part?.field?.labelFormatting"
                                (settingsChange)="labelSettingsChanged($event)">
    </app-data-card-setting-part>
    <app-data-card-setting-part [desc]="'MODULE_DEF_CARD.PART_DEFINITION.VALUE' | translate"
                                [showDesc]="showLabel"
                                [showSizeSetting]="showSizeSetting"
                                [showFontColor]="showValueFontColor"
                                [showFontStyle]="showValueFontStyle"
                                [showFontSize]="showValueFontSize"
                                [showAlignHorizontal]="showValueAlignHorizontal"
                                [showAlignVertical]="showValueAlignVertical"
                                [settings]="part?.field?.valueFormatting"
                                (settingsChange)="valueSettingsChanged($event)">
    </app-data-card-setting-part>
    <mat-card>
        <mat-card-title>{{ 'MODULE_DEF_CARD.PART_DEFINITION.PREVIEW' | translate }}</mat-card-title>
        <mat-card-content>
            <app-data-card-view-part [part]="part"></app-data-card-view-part>
        </mat-card-content>
    </mat-card></mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button
            mat-dialog-close>{{ 'MODULE_DEF_CARD.PART_DEFINITION.ACTION_CLOSE' | translate }}</button>
    <button mat-raised-button
            color="primary"
            [disabled]="!changed"
            cdkFocusInitial
            (click)="save()">{{ 'MODULE_DEF_CARD.PART_DEFINITION.ACTION_UPDATE' | translate }}</button>
</mat-dialog-actions>