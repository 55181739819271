<div fxLayout="row">
     <div *ngIf="showLabel"
          class="partLabel"
          [fxFlex]="100 - valueSize"
          [fxLayoutAlign]="getVerticalAlign(part.field.labelFormatting)"
          [style.color]="getFontColor(part.field.labelFormatting)"
          [style.fontSize]="getFontSize(part.field.labelFormatting)"
          [style.fontWeight]="getFontWeight(part.field.labelFormatting)"
          [style.fontStyle]="getFontStyle(part.field.labelFormatting)"
          [style.textDecoration]="getTextDecoration(part.field.labelFormatting)"
          [title]="part.field.label">
          {{part.field.label}}{{part.field.labelFormatting.showColon ? ':' : ''}}
     </div>
     <div [fxFlex]="valueSize"
          class="partValue"
          [fxLayoutAlign]="getVerticalAlign(part.field.valueFormatting)"
          [style.color]="getFontColor(part.field.valueFormatting)"
          [style.fontSize]="getFontSize(part.field.valueFormatting)"
          [style.fontWeight]="getFontWeight(part.field.valueFormatting)"
          [style.fontStyle]="getFontStyle(part.field.valueFormatting)"
          [style.textDecoration]="getTextDecoration(part.field.valueFormatting)">
          <ng-container *ngIf="part.isNumber && part.valueNumber">
               <ng-container *ngIf="part.isNumberIndicator"
                             [ngTemplateOutlet]="numberIndicatorTpl">
               </ng-container>
               <ng-container *ngIf="part.isNumberProgress"
                             [ngTemplateOutlet]="numberProgressTpl">
               </ng-container>
               <ng-container *ngIf="!part.isNumberIndicator && !part.isNumberProgress"
                             [ngTemplateOutlet]="numberTpl">
               </ng-container>
          </ng-container>
          <ng-container *ngIf="part.isDate && part.valueString">
               {{part.valueString | itvDateTimeWithRestrictions:part.field.restrictions}}
          </ng-container>
          <ng-container *ngIf="part.isText">
               {{part.valueString}}
          </ng-container>
          <ng-container *ngIf="part.isYesNo && part.valueString"
                        [ngTemplateOutlet]="yesNoTpl">
          </ng-container>
          <ng-container *ngIf="part.isState && part.valueState"
                        [ngTemplateOutlet]="stateTpl">
          </ng-container>
          <ng-container *ngIf="part.isLine"
                        [ngTemplateOutlet]="lineTpl">
          </ng-container>
     </div>
</div>

<ng-template #numberTpl>
     {{part.valueNumber | itvNumberWithFormattings:part.field.formattings:part.field.restrictions}}
</ng-template>

<ng-template #numberIndicatorTpl>
     <mat-icon class="indicator-icon">
          {{ part.isNumberIndicatorDown ? 'arrow_drop_down' : 'arrow_drop_up'}}
     </mat-icon>
     <ng-container *ngTemplateOutlet="numberTpl"></ng-container>
</ng-template>

<ng-template #numberProgressTpl>
     <itv-number-progress-bar [value]="numberProgressValue"
                              [formattings]="part.field.formattings"
                              [restrictions]="part.field.restrictions"
                              [isDuration]="part.isNumberDurationProgress">
     </itv-number-progress-bar>
</ng-template>

<ng-template #yesNoTpl>
     {{part.valueBoolean ? 'Yes' : 'No'}}
</ng-template>

<ng-template #stateTpl>
     <span class="stateBlock"
           [style.color]="part.valueState.fontColor"
           [style.backgroundColor]="part.valueState.bgColor"
           [style.fontWeight]="getFontWeight(part.field.valueFormatting)"
           [style.fontStyle]="getFontStyle(part.field.valueFormatting)"
           [style.textDecoration]="getTextDecoration(part.field.valueFormatting)">
          {{part.valueState.name}}
     </span>
</ng-template>

<ng-template #lineTpl>
     <hr class="lineBlock"
         [style.borderTopColor]="getFontColor(part.field.valueFormatting)" />
</ng-template>