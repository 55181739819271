import { cipo } from 'cipo';

cipo.factory("AssignURC", function ($q, Model, URI,Message, userService ) {

    var AssignURC = Model.extend(function (id, type, obj) {
        
        // type is a hardcoded param: 1 - user, 2 - role, 3 - contract
        // self.userService = userService;
        var self = this;
        self.urlParams = {};
        if (type) {
            switch (type) {
                case 1:
                    // if main is user
                    self.syncURL = URI.USER.SYNC_ROLE_CONTRACTS;
                    self.pivotLabel = 'Select Role';
                    self.secondaryLabel = 'Select Contract(s)';
                    self.urlParams.userId = id;
                    break;
                case 2:
                    // if main is role
                    self.syncURL = URI.ROLE.SYNC_CONTRACT_USERS;
                    self.pivotLabel = 'Select Contract';
                    self.secondaryLabel = 'Select User(s)';
                    self.urlParams.roleId = id;
                    break;
                default:
                    // if main is contract
                    self.syncURL = URI.CT.SYNC_ROLE_USERS;
                    self.pivotLabel = 'Select Role';
                    self.secondaryLabel = 'Select User(s)';
                    self.urlParams.entityInstanceId = id;
                    break;

            }

        }
        self.pivot = { key: null, value: "" };
        // self.pivot = { key: null, value: "" };
        self.properties = {
            key: null,
            children: []
        };
        
        
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                }
                self.pivot = { key: obj.key, value: obj.value };
            }
        }
    });


    AssignURC.prototype.remove = function () {
        var self = this;
        var p = $q.defer();
        self.properties.children = [];
        self[self.syncURL.method](self.syncURL, { url: self.urlParams, body: [self.properties], urltype: 'obj' })
            .then(function (result) {
                p.resolve(result);
                userService.Contracts();
                Message.info('Unassign successful');
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            }).finally(function () {

            });
        return p.promise;
    }

    AssignURC.prototype.sync = function () {
        var self = this;
        var p = $q.defer();
        var toSend = { key: null, children: []};
        toSend.key = self.pivot.key;
        for (var i = 0; i < self.properties.children.length; i++) {
            toSend.children.push(self.properties.children[i]);
        }
        
        if (toSend.children.length == 0) {
            Message.error('Please select a Contract');
            return;
        }

        self.isBusy = true;
        self[self.syncURL.method](self.syncURL, { url: self.urlParams, body: [toSend], urltype: 'obj' })
            .then(function (result) {
                p.resolve(result);
                userService.Contracts();
                Message.info('Assignment successful');
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            }).finally(function () {
                self.isBusy = false;

            });


        return p.promise;
    }

    return AssignURC;

});
