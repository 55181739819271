import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("ProgressPayment", function (Model, Form, WorkflowScreen, PPItem,  $q, Message, Dictionaries, URI, SOVCategory, userService, $http, FileSaver, Blob, $timeout, $window, Functions) {
    var ProgressPayment = Model.extend(function (moduleId, entityInstanceId, categoriesDict, types) {
        var self = this;
        self.properties = { entityInstanceId: entityInstanceId || 0 };
        self.contractId = userService.system.userdata.contractId || 0;
        self.userService = userService;
        self.moduleId = moduleId;
        self.loading = true;
        self.loadingTransitions = true;
        self.isPercentage = true;
        self.lookup = {};
        //self.period = {};
        self.cleanDict = angular.copy(categoriesDict);
        self.set_Categories(categoriesDict);
        self.types = types || [];
        if (self.types.length) self.get_Data();
        self.currencySymbol = userService.getCurrency();
    });

    ProgressPayment.prototype.set_Categories = function (dict) {
        var self = this;
        self.total = null;
        self.userService = userService;
        self.currencySymbol = userService.getCurrency();
        if (dict) self.dict = angular.copy(dict);
        else self.dict = angular.copy(self.cleanDict);
        self.dict.push({ isUsed: false, itemsCount: 0, key: 0, number: "", value: "Change Orders", subTotal: 0 });
        self.dict.push({ isUsed: false, itemsCount: 0, key: -1, number: "", value: "Deductive Change Orders" });
        for (var i = 0; i < self.dict.length; i++) {
            self.dict[i].items = [];
            self.dict[i].printItems = [];
            self.dict[i].colSpan = 0;
            self.dict[i].totals = [];
            if (self.dict[i].key > 0) {
                // self.dict[i].keys = ['ppi_payment_amount', 'total_previous_payment_percentage', 'total_previous_payment_amount', 'total_payment_percentage', 'total_payment_amount'];
                self.dict[i].keys = ['ppi_payment_amount', '', 'total_previous_payment_amount', '', 'total_payment_amount'];
                self.dict[i].markup = [[self.currencySymbol, ''], ['', ''], [self.currencySymbol, ''], ['', ''], [self.currencySymbol, '']];
            } else if (self.dict[i].key == 0) {
                self.dict[i].keys = ['cop_payment_amount', '', 'total_previous_payment_amount', '', 'total_payment_amount'];
                self.dict[i].markup = [[self.currencySymbol, ''], ['', ''], [self.currencySymbol, ''], ['', ''], [self.currencySymbol, '']];
            }
            self.lookup[self.dict[i].key] = self.dict[i];
            
        }
        // console.error("TOTAL", self.total);
    }

    ProgressPayment.prototype.resetTotals = function (isCO) {
        var self = this;
        for (var i = 0; i < self.dict.length; i++) {
            if (self.dict[i].key > 0 && !isCO) {
                self.dict[i].items = [];
                self.dict[i].colSpan = 0;
                self.dict[i].totals = [];
            } else if (self.dict[i].key <= 0 && isCO) {
                self.dict[i].items = [];
                self.dict[i].colSpan = 0;
                self.dict[i].totals = [];
            }

        }
    }
    // ??
    ProgressPayment.prototype.get_RolesDict = function () {
        var self = this;
        var p = $q.defer();
        self.rolesDict = [];
        var params = {
            moduleId: self.moduleId,
            contractId: self.contractId
        };
        var dataURL = URI.MODULE.MODULE_ROLES_DICT;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.rolesDict = r;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
        return p.promise;
    }

    ProgressPayment.prototype.processPrint = function (obj) {
        var self = this;
        self.printOutObj = {};
        self.printOutObj.lists = [];
        // self.printoutDict = angular.copy(self.dict);
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (key == 'contractDate' || key == 'estimateDate') obj[key] = moment(obj[key]).format(userService.formats.date);
                if (key != 'progressPaymentItems' && key != 'changeOrderPayments' && key != 'deductiveChangeOrderPayments') self.printOutObj[key] = obj[key];
                else {
                    if (obj[key].length) {
                        for (var i = 0; i < obj[key].length; i++) {
                            if (obj[key][i].sov_item_category_id) self.lookup[obj[key][i].sov_item_category_id].printItems.push(obj[key][i]);
                            else {
                                if (obj[key][i].co_cost) obj[key][i].co_cost > 0 ? self.lookup[0].printItems.push(obj[key][i]) : self.lookup[-1].printItems.push(obj[key][i]);
                                else self.lookup[-1].printItems.push(obj[key][i]);
                            }
                        }

                    }
                } 
                
            }
        }
    }

    ProgressPayment.prototype.get_printout = function () {
        var self = this;
        var p = $q.defer();

        var params = {
            progressPaymentEntityInstanceId: self.properties.entityInstanceId,
            contractId: self.contractId
        };
        var dataURL = URI.PROGRESS_PAYMENT.PP_PRINT;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.processPrint(r);
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
        return p.promise;
    }

    ProgressPayment.prototype.exportsov = function (isBlank) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.ProgressPayment.SOV_EXPORT;
        var urlParam = '?entityInstanceId=' + (isBlank ? 0 : self.properties.entityInstanceId) + '&contractId=' + self.contractId;
        $http[dataURL.method](dataURL.toString() + urlParam, { responseType: "arraybuffer" })
            .then(function (r) {
                var type = r.headers('Content-Type');
                var disposition = r.headers('Content-Disposition');
                if (disposition) {
                    var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                    if (match[1])
                        defaultFileName = match[1];
                }
                defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
                var blob = new Blob([r.data], { type: type });
                FileSaver.saveAs(blob, defaultFileName);
                p.resolve(defaultFileName);
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e);
            });
        return p.promise;
    }

    ProgressPayment.prototype.calculateTotal = function (id) {
        var self = this;
        var currentCategory = self.lookup[id];
        for (var j = 0; j < currentCategory.keys.length; j++) {
            currentCategory.totals[j] = currentCategory.keys[j] ? 0 : "";
        }
        for (var i = 0; i < currentCategory.items.length; i++) {
            for (var key in currentCategory.items[i].properties) {
                if (currentCategory.keys.indexOf(key) != -1) {
                    currentCategory.totals[currentCategory.keys.indexOf(key)] += parseFloat(currentCategory.items[i].properties[key]);
                }
            }
        }
        self.total = 0;
        // self.activeCategories = [];
        if (self.activeCategories.indexOf(currentCategory.key)==-1) self.activeCategories.push(currentCategory.key);
        for (var i = 0; i < self.activeCategories.length; i++) {
            self.total += (self.lookup[self.activeCategories[i]] || {}).totals[0];
        }
        
        
        self.calculateFinalPayment();
    }

    ProgressPayment.prototype.formatMoney = function (amount) {
        var self = this;
        if (amount) {
            var sign = amount > 0 ? self.currencySymbol : "-" + self.currencySymbol;
            var noString = Math.abs(amount).toFixed(2).toString();
            var decimal = noString.slice(-3);
            noString = noString.slice(0, -3);
            noString = noString.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            return sign + noString + decimal;

        }
        else return self.currencySymbol + "0.00";
    }

    ProgressPayment.prototype.calculateFinalPayment = function () {
        var self = this;
        var amount=0, percentage=0;
        self.finalAmount = self.total;
        if ((self.properties.recurringAdjustments || []).length || (self.properties.oneTimeAdjustments || []).length) {
            if (self.properties.recurringAdjustments.length)
                for (var i = 0; i < self.properties.recurringAdjustments.length; i++) {
                    var a = self.properties.recurringAdjustments[i];
                    if (a.amount) amount +=  parseFloat(a.amount);
                    if (a.percentage) percentage += parseFloat(a.percentage);
                }
            if (self.properties.oneTimeAdjustments.length)
                for (var i = 0; i < self.properties.oneTimeAdjustments.length; i++) {
                    var a = self.properties.oneTimeAdjustments[i];
                    if (a.amount) amount += parseFloat(a.amount);
                    if (a.percentage) percentage += parseFloat(a.percentage);
                }

            self.finalAmount = percentage ? self.finalAmount * (1 - percentage / 100) : self.finalAmount;
            self.finalAmount = Math.round(self.finalAmount*100)/100;
            self.finalAmount = self.formatMoney(self.finalAmount - amount);

        } else {
            if (self.properties.retentionAmount) self.finalAmount = self.formatMoney(self.total - parseInt(self.properties.retentionAmount));
            else self.finalAmount = self.formatMoney(self.total);
        }
    }

    ProgressPayment.prototype.retrieve = function () {
        var self = this;
        var dataURL = URI.PROGRESS_PAYMENT.PPI_RETRIEVE;
        var params = {
            contractId: self.contractId,
            progressPaymentEntityInstanceId: self.properties.entityInstanceId
        }
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) { })
            .catch(function (e) { })
    }

    ProgressPayment.prototype.get_items = function (isCO) {
        var self = this;
        self.resetTotals(isCO);
        var type = isCO ? "CoItems" : "Items";
        var isCO = isCO || false;
        self['loading' + type] = true;
        var p = $q.defer();
        var dataURL = !isCO ? URI.PROGRESS_PAYMENT.PPI_SEARCH : URI.PROGRESS_PAYMENT.COP_SEARCH;
        var params = {
            contractId: self.contractId,
            categoryId: 0,
            progressPaymentEntityInstanceId: self.properties.entityInstanceId
        }
        self.activeCategories = [];
        self[dataURL.method](dataURL, {url: params, urltype: 'obj'})
            .then(function (r) {
                if (!isCO) self.retrieve();
                if (r.data && r.data.length) {
                    var currentCategory;
                    for (var i = 0; i < r.data.length; i++) {
                        if (!isCO) {
                            currentCategory = self.lookup[r.data[i].sov_item_category_id];
                            if (!currentCategory.totals.length)
                                for (var j = 0; j < currentCategory.keys.length; j++) {
                                    currentCategory.totals[j] = currentCategory.keys[j] ? 0 : "";
                                }
                            currentCategory.colSpan = self.columns.length - currentCategory.keys.length -1;
                            for (var key in r.data[i]) {
                                if (r.data[i].hasOwnProperty(key)) {
                                    if (currentCategory.keys.indexOf(key) != -1) {
                                        currentCategory.totals[currentCategory.keys.indexOf(key)] += parseFloat(r.data[i][key]);
                                    }
                                    
                                } 
                            }
                            // self.total += currentCategory.totals.length ? currentCategory.totals[0] : 0;
                            // if (self.activeCategories.indexOf(parseInt(currentCategory.key)) == -1) self.activeCategories.push(parseInt(currentCategory.key));
                            // console.error("SUBTOTAL", self.total, self.activeCategories);

                            currentCategory.items.push(new PPItem(r.data[i], self.properties.entityInstanceId, false, self));
                        }
                        else {
                            if (r.data[i].co_cost && parseFloat(r.data[i].co_cost) > 0) {
                                currentCategory = self.lookup[0];
                                currentCategory.isUsed = true;
                                currentCategory.colSpan = self.coColumns.length - currentCategory.keys.length - 1;
                                currentCategory.subTotal += r.data[i].co_cost;
                                if (!currentCategory.totals.length)
                                    for (var j = 0; j < currentCategory.keys.length; j++) {
                                        currentCategory.totals[j] = currentCategory.keys[j] ? 0 : "";
                                    }
                                for (var key in r.data[i]) {
                                    if (r.data[i].hasOwnProperty(key)) {
                                        if (currentCategory.keys.indexOf(key) != -1) {
                                            currentCategory.totals[currentCategory.keys.indexOf(key)] += parseFloat(r.data[i][key]);
                                        }

                                    }
                                }
                                currentCategory.items.push(new PPItem(r.data[i], self.properties.entityInstanceId, true, self));
                            }
                            // if (self.activeCategories.indexOf(parseInt(currentCategory.key)) == -1) self.activeCategories.push(parseInt(currentCategory.key));
                            // self.total += currentCategory.totals.length ? currentCategory.totals[0] : 0;
                            /*else {
                                self.lookup[-1].isUsed = true;
                                self.lookup[-1].items.push(new PPItem(r.data[i], self.properties.entityInstanceId, true));
                            }*/
                            
                            
                        }
                        if (currentCategory && self.activeCategories.indexOf(parseInt(currentCategory.key)) == -1) self.activeCategories.push(parseInt(currentCategory.key));
                        
                    }
                    self.total = 0;
                    for (var i = 0; i < self.activeCategories.length; i++) {
                        self.total += (self.lookup[self.activeCategories[i]].totals || []).length ? self.lookup[self.activeCategories[i]].totals[0] : 0;
                    }
                    self.calculateFinalPayment();

                }
                p.resolve();
            })
            .catch(function (e) { console.error(e); Message.dberror(e); p.reject(); })
            .finally(function () { self['loading' + type] = false; })
        return p.promise;
    }

    ProgressPayment.prototype.updateCategory = function (id) {
    }

    ProgressPayment.prototype.get_transitions = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.PROGRESS_PAYMENT.PP_TRANSITIONS;
        var params = { contractId: self.contractId, entityInstanceId: self.properties.entityInstanceId };
        self.loadingTransitions = true;
        self.pastTransitions = [];
        self.transitions = [];
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                var transitions = [];
                if (((r || {}).transitions ||[]).length) {
                    if (self.period && self.period.key != self.currentPeriod.id) {
                        for (var i = 0; i < r.transitions.length; i++) {
                            if (r.transitions[i].stateTypeId != 0) {
                                transitions.push(r.transitions[i]);
                            }
                        }
                    } else transitions = r.transitions;
                }
                self.transitions = transitions;
                // self.revisions = r.revisions;
                if (r && r.pastTransition && r.pastTransitions.length) {
                    var temp;
                    for (var i = 0; i < r.pastTransitions.length; i++) {
                        temp = r.pastTransitions[i];
                        temp.screen = new WorkflowScreen();
                        temp.screen.data = { fields: temp.fields };
                        temp.screen.setupForm(false);
                        if (r.pastTransitions[i].createdOn)
                            r.pastTransitions[i].createdOn = moment(r.pastTransitions[i].createdOn).format(userService.formats.datetime);
                        self.pastTransitions.push(temp);
                    }
                }
                // self.pastTransitions = r.pastTransitions;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loadingTransitions = false;
            })

        return p.promise;
    }

    ProgressPayment.prototype.get_Data = function (transitionId, period, entityId) {
        var self = this;
        var p = $q.defer();
        if(period) self.period = period;
        var dataURL = URI.PROGRESS_PAYMENT.PP_GET;
        self.message = "";
        var params = {
            contractId: self.contractId,
            transitionId: transitionId || 0,
            entityInstanceId: typeof entityId != 'undefined' ? entityId : self.properties.entityInstanceId,
            paymentPeriodId: self.period ? self.period.key : 0,
            moduleTypeId: (self.currentType || {}).key || self.types[0].key
        };
        // self.loading = transitionId ? false : true;
        if (!transitionId) {
            self.loading = true;
            self.set_Categories();
        }
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                if (r && !transitionId) {
                    self.properties = {};
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) {
                            self.properties[key] = r[key];
                        }
                    }

                    if (!self.currentPeriod) self.get_currentPeriod();
                }
                if (!transitionId) {
                    if (r.entityInstanceId) self.get_transitions();
                    self.printOutObj = null;
                    if (r.entityInstanceId) {
                        self.get_printout();
                        if (!self.columns) self.get_gridfields(); else self.get_items();
                        if (!self.coColumns) self.get_gridfields(true); else self.get_items(true);
                    }
                }
                    
                self.userIsInitiator = r.userIsInitiator;

                self.get_RolesDict();

                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                // Message.dberror(e);
                self.get_periods();
                self.message = e.data.messages;
                p.reject(e);
            })
            .finally(function () {
                
                self.loading = false;
            })

        return p.promise;
    }

    ProgressPayment.prototype.get_gridfields = function (isCO) {
        var self = this;
        var p = $q.defer();
        var dataURL = !isCO ? URI.PROGRESS_PAYMENT.PPI_FIELDS : URI.PROGRESS_PAYMENT.COP_FIELDS;
        var params = { contractId: self.contractId };
        self.loadingGridfields = true;
        if (!isCO) {
            self.columns = [
                { key: 'sov_item_item_no_minor', _key: '_sov_item_item_no_minor', value: '' },
                { key: 'sov_item_item_description', _key: '_sov_item_item_description', value: '' },
                { key: 'sov_item_qty', _key: '_sov_item_qty', value: '', rightAligned: true },
                { key: 'sov_item_amount', _key: '_sov_item_amount', value: '', rightAligned: true },
                { key: 'sov_item_uom_list_elmnt_key', _key: '_sov_item_uom_list_elmnt_key', value: '' },
                { key: 'sov_item_total', _key: '_sov_item_total', value: 'Total', rightAligned: true },
                { key: 'ppi_payment_percentage', _key: '_ppi_payment_percentage', value: '% Payment', rightAligned: true },
                { key: 'ppi_payment_amount', _key: '_ppi_payment_amount', value: '$ Payment', rightAligned: true },
                { key: 'total_previous_payment_percentage', _key: '_total_previous_payment_percentage', value: '% Total Prev Payment', rightAligned: true },
                { key: 'total_previous_payment_amount', _key: '_total_previous_payment_amount', value: '$ Total Prev Payment', rightAligned: true },
                { key: 'total_payment_percentage', _key: '_total_payment_percentage', value: '% Total Payment', rightAligned: true },
                { key: 'total_payment_amount', _key: '_total_payment_amount', value: '$ Total Payment', rightAligned: true },
                
                
            ];
        } else {
            self.coColumns = [
                { key: 'co_full_doc_num', _key: '_co_full_doc_num', value: 'Doc No' },
                { key: 'co_description', _key: '_co_description', value: 'Description' },
                { key: 'co_subject', _key: '_co_subject', value: 'Subject' },
                { key: 'co_cost', _key: '_co_cost', value: 'Cost', rightAligned: true },
                { key: 'cop_payment_percentage', _key: '_cop_payment_percentage', value: '% Payment', rightAligned: true },
                { key: 'cop_payment_amount', _key: '_cop_payment_amount', value: '$ Payment', rightAligned: true },
                { key: 'total_previous_payment_percentage', _key: '_total_previous_payment_percentage', value: '% Total Prev Payment', rightAligned: true },
                { key: 'total_previous_payment_amount', _key: '_total_previous_payment_amount', value: '$ Total Prev Payment', rightAligned: true },
                { key: 'total_payment_percentage', _key: '_total_payment_percentage', value: '% Total Payment', rightAligned: true },
                { key: 'total_payment_amount', _key: '_total_payment_amount', value: '$ Total Payment', rightAligned: true },
                
            ];
            self.dcoColumns = [
                { key: 'co_description', _key: '_co_description', value: 'Description' },
                { key: 'co_subject', _key: '_co_subject', value: 'Subject' },
                { key: 'co_cost', _key: '_co_cost', value: 'Cost', rightAligned: true }
            ];
        }
        var lookupgridfields = {};
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.get_items(isCO);
                for (var i = 0; i < r.fields.length; i++) {
                    
                    if (r.fields[i].dataSourceValues && r.fields[i].dataSourceValues.length) {
                        r.fields[i].dataSourceLookup = {};
                        for (var j = 0; j < r.fields[i].dataSourceValues.length; j++) {
                            var value = r.fields[i].dataSourceValues[j]['value'] ? r.fields[i].dataSourceValues[j]['value'] : "";
                            r.fields[i].dataSourceLookup[r.fields[i].dataSourceValues[j]['key'].toString()] = value;
                        }
                    }
                    lookupgridfields[r.fields[i].name] = r.fields[i];
                }
                var col = isCO ? "coColumns" : "columns";
                for (var i = 0; i < self[col].length; i++) {
                    self[col][i].value = lookupgridfields[self[col][i].key] && !self[col][i].value ? lookupgridfields[self[col][i].key].label : self[col][i].value;
                    self[col][i].dataSourceLookup = lookupgridfields[self[col][i].key] ? lookupgridfields[self[col][i].key].dataSourceLookup : null;

                }
                
                
                p.resolve();
                console.error(self, self[col]);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loadingGridfields = false;
            })

        return p.promise;
    }

    ProgressPayment.prototype.create = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.PROGRESS_PAYMENT.PP_CREATE;
        var params = {
            contractId: self.contractId,
            paymentPeriodId: 0,
            moduleTypeId: (self.currentType || {}).key || self.types[0].key,
            workflowId: userService.system.workflowId
        };
        self.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                if (r) {
                    self.properties.entityInstanceId = r;
                }
                self.get_Data();
                Message.info("Progress Payment Request created successfully.")
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    ProgressPayment.prototype.save = function (transitionId) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.ProgressPayment.SOV_SAVE;
        var params = {
            contractId: self.contractId,
            transitionId: transitionId,
            entityInstanceId: self.properties.entityInstanceId,
            moduleTypeId: (self.currentType || {}).key || self.types[0].key
        };
        self.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj', body: {} })
            .then(function (r) {
                if (r) self.properties.entityInstanceId = r;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    ProgressPayment.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.PROGRESS_PAYMENT.PP_DELETE;
        var params = {
            contractId: self.contractId,
            entityInstanceId: self.properties.entityInstanceId
        };
        self.loading = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                Message.info("Progress payment request deleted successfully.");
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    ProgressPayment.prototype.get_itemDetails = function (i) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.ProgressPayment.SOVI_GET;
        var params = { contractId: self.contractId, entityInstanceId: i ? i.entity_instance_id : 0 };
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e)
            })
            .finally(function () {
                self.loading = false;
            })

        return p.promise;
    }

    ProgressPayment.prototype.get_category_items = function (c) {
        var self = this;
        var p = $q.defer();
        var params = { contractId: self.contractId, categoryId: c.key, progressPaymentEntityInstanceId: self.properties.entityInstanceId };
        var dataURL = URI.PROGRESS_PAYMENT.PPI_SEARCH;
        c.items = [];
        c.loadingItems = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                if (r.data && r.data.length) {
                    for (var i = 0; i < r.data.length; i++) {
                        c.items.push(new PPItem(r.data[i], self.properties.entityInstanceId));
                    }
                }
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                c.loadingItems = false;
            })

        return p.promise;
    }

    ProgressPayment.prototype.get_periods = function () {
        var self = this;
        var p = $q.defer();
        self.loadingPeriods = true;
        if (!self.currentPeriod) self.currentPeriod = { id: 0, name: 'Current Period'};
        Dictionaries.PPPeriods({ contractId: self.contractId })
            .then(function (r) {
                if (r && r.length) {
                    if (r && r.length && self.currentPeriod) {
                        for (var i = 0; i < r.length; i++) {
                            r[i].startDate = r[i].startDate ? moment(r[i].startDate).format(userService.formats.date) : "";
                            r[i].endDate = r[i].startDate ? moment(r[i].endDate).format(userService.formats.date) : "";
                            if (r[i].key == self.currentPeriod.id) {
                                r[i].current = true;
                            }
                        }
                    }
                    if (!self.currentPeriod.id) r.splice(0, 0, { key: 0, value: 'Current Period', current: true });
                    self.periodsDict = r;
                    // self.currentPeriod.value = self.periodsDict[0].value;
                } else self.periodsDict = [];
                
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.loadingPeriods = false;
            })

        return p.promise;
    }

    ProgressPayment.prototype.get_currentPeriod = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.PROGRESS_PAYMENT.PPD_CURRENT;
        self[dataURL.method](dataURL, { url: { contractId: self.contractId }, urltype: 'obj' })
            .then(function (r) {
                r.startDate = r.startDate ? moment(r.startDate).format(userService.formats.date) : "";
                r.endDate = r.startDate ? moment(r.endDate).format(userService.formats.date) : "";
                self.currentPeriod = r;
                self.get_periods();
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                
            })

        return p.promise;
    }

    ProgressPayment.prototype.printDocument = function () {
        var self = this;
        var p = $q.defer();
        self.isPrintStarted = true;
        var dataURL = URI.TEMPLATES.PREVIEWS;
        self[dataURL.method](dataURL, { list: [self.properties.entityInstanceId] })
            .then(function (result) {
                if (result && result.length) {
                    if ($("#section-to-print").length == 0) {
                        var div = $('<div />').appendTo('body');
                        div.attr('id', 'section-to-print');
                        div.attr('class', 'fr-view');

                    }
                    var content = "";
                    for (var i = 0; i < result.length; i++) {

                        content += '<div style="position: fixed; top: 0; left: 0; width: 100%;">'
                            + (result[i].header || "")
                            + '</div><table><thead><tr><td><div class="invisible">'
                            + (result[i].header || "")
                            + '</div></td></tr></thead>'
                            + '<tbody><tr><td>'
                            + (result[i].content || "")
                            + '</td></tr></tbody>'
                            + '<tfoot><tr><td><div class="invisible">'
                            + (result[i].footer || "")
                            + '</div></td></tr></tfoot></table >'
                            + '<div style="position: fixed; bottom: 0; left: 0; width: 100%;">'
                            + (result[i].footer || "")
                            + '</div>';
                        if (i != result.length - 1) content += "<div style='page-break-after: always;'></div>";

                    }
                    $('#section-to-print').html(content);
                    $timeout(function () {
                        $window.print();
                    }, 400)
                }
                else {
                    Message.dberror('No print document found');
                }
            })
            .catch(function (e) {
                Message.dberror(e);
            })
            .finally(function () {
                self.isPrintStarted = false;
                //$scope.manager.loadPage();
            });
    }

    ProgressPayment.prototype.downloadPdfs = function () {
        var self = this;
        var p = $q.defer();
        var list = [self.properties.entityInstanceId];
        self.isPrintStarted = true;
        var urlparams = {
            moduleId: self.moduleId,
            contractId: self.contractId
        }
        var saveByteArray = function (reportName, byte) {
            var blob = new Blob([byte], { type: "application/pdf" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = reportName;
            link.download = fileName;
            link.click();
        };


        // $scope.manager.loading = true;
        //var d = dialogs.confirm('Deactivate User', 'Are you sure you want to deactivate the selected User(s)?');
        self[URI.MODULE.GET_INSTANCES_AS_PDF.method](URI.MODULE.GET_INSTANCES_AS_PDF, { url: urlparams, urltype: 'obj', body: { list: list } })
            .then(function (result) {
                var contractNo = self.userService.system.contractsLookup[self.userService.system.userdata.contractId].contractNo;
                var name = "Progress Payment" + '-' + contractNo;
                var sampleArr = Functions.base64ToArrayBuffer(result);
                saveByteArray(name, sampleArr);

            })
            .catch(function (e) {
                Message.dberror(e);
            })
            .finally(function () {
                // $scope.manager.loading = false;
                self.isPrintStarted = false;
            });
    }

    return ProgressPayment;
});
