import { cipo } from 'cipo';
import moment from 'moment';

    cipo.factory("ProgressPaymentSA", function (CommonEntityInstance, Form, WorkflowScreen, AdjustmentItem, $q, Message, Dictionaries, URI, TEMPLATES_TYPES,
        userService, Blob, $timeout, $window, Functions, fileService) {
        const EDIT_PERCENTAGE = 1;
        const EDIT_AMOUNT = 2;
        const EDIT_QUANTITY = 3;
        const MODULE_SOVI = -5;

        var ProgressPaymentSA = CommonEntityInstance.extend(function (module, entityInstanceId, types, currentPeriodId, selectedPeriodId, isNoDetails) {
            var self = this;
            self.properties = { entityInstanceId: entityInstanceId || 0 };
            self.entityInstanceId = entityInstanceId || 0;
            self.contractId = userService.system.userdata.contractId || 0;
            self.userService = userService;
            self.moduleId = module.moduleId;
            self.module = module;
            self.moduleCode = 'PP';
            self.currentPeriodId = currentPeriodId || 0;
            self.selectedPeriodId = selectedPeriodId || currentPeriodId || 0;
            self.isBusy = true;
            self.editType = EDIT_PERCENTAGE;
            self.hasPaymentQuantityFields = false;
            self.isPayByQuantityEnabled = false;
            self.isBusyTransitions = true;
            self.isAllPayments = false;
            self.editMode = false;
            self.lookup = {};
            self.printouts = [];
            //self.period = {};
            self.moduleCode = 'PP';
            self.types = types || [];
            self.pageSize = 20;

            // payment quantity fields: -65/payment_qty, -66/previous_payment_qty, -67/paid_qty
            self.quantityFieldsId = [-65, -66, -67];

            // Get workflow for the module
            userService.getWorkflow(self.moduleId)
                .then(function() {
                    self.initGeneralInfo(isNoDetails);
                })
                .catch(function () {
                    self.initGeneralInfo(isNoDetails);
                });

            self.currencySymbol = userService.getCurrency();
            //self.get_Data();
            self.itemsLookup = {};
            self.ppiLookup = {};
            self.isModulesLoaded = false;
            self.modulesLookup = {};
            self.modulesList = [];

            Object.defineProperty(self, 'isEditablePercentage', {
                get: function () {
                    return this.editType == EDIT_PERCENTAGE;
                }
            });
            Object.defineProperty(self, 'isEditableAmount', {
                get: function () {
                    return this.editType == EDIT_AMOUNT;
                }
            });
            Object.defineProperty(self, 'isEditableQuantity', {
                get: function () {
                    return this.editType == EDIT_QUANTITY;
                }
            });
            Object.defineProperty(self, 'isEditQuantityAvailable', {
                get: function () {
                    return this.hasPaymentQuantityFields && this.isPayByQuantityEnabled;
                }
            });
        });

        ProgressPaymentSA.prototype.initGeneralInfo = function (isNoDetails) {
            var self = this;
            if (self.types.length && !isNoDetails) { self.get_general_info(); }
                    else if (!self.types.length) {
                        Dictionaries.ModuleTypes(-3)
                            .then(function (r) {
                                self.types = r || [];
                                if (self.types.length) self.get_general_info();
                                else Message.error("No module types");
                            })
                            .catch(function () { })
                    }
        }

        ProgressPaymentSA.prototype.setEditTypePercentage = function () {
            this.editType = EDIT_PERCENTAGE;
            this.showHideColumns();
        }
        ProgressPaymentSA.prototype.setEditTypeAmount = function () {
            this.editType = EDIT_AMOUNT;
            this.showHideColumns();
        }
        ProgressPaymentSA.prototype.setEditTypeQuantity = function () {
            this.editType = EDIT_QUANTITY;
            this.showHideColumns();
        }

        ProgressPaymentSA.prototype.showHideColumns = function () {
            var moduleSovi = this.modulesList.find(m => m.moduleId == MODULE_SOVI);
            if (!moduleSovi) {
                return;
            }

            // remove quantity fields when quantity payment is not selected
            moduleSovi.fields.forEach(f => f.visible = !this.quantityFieldsId.includes(f.id) || this.isEditableQuantity);
        }

        ProgressPaymentSA.prototype.setupApprovalForm = function () {
            var self = this;
            self.approvalContent = {
                comment: ""
            }
            self.approvalForm = new Form(self.approvalContent);
            // self.approvalForm.initializing = true;
            self.approvalForm.set_Description(
                { comment: { label: 'Comment', type: 'editor' } });
        }

    ProgressPaymentSA.prototype.cancelApprove = function () {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlParams = {
            approvalId: self.generalInfo.approvalId,
            contractId: userService.system.userdata.contractId,
            moduleId: self.moduleId
        };
        self[URI.MODULE_APPROVAL.CANCEL_APPROVAL.method](URI.MODULE_APPROVAL.CANCEL_APPROVAL, { url: urlParams, urltype: 'obj' })
            .then(function (result) {
                self.get_general_info();
                self.isModified = true;
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
            })
            .finally(function () {
                self.isBusy = false;
            });

        return p.promise;
    }

    ProgressPaymentSA.prototype.approve = function (isApprove) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var urlParams = {
            // approvalId: self.data.approvalId,
            contractId: userService.system.userdata.contractId,
            moduleId: self.moduleId
        }
        var bodyParams = {
            id: self.generalInfo.approvalId,
            approved: isApprove ? true : false,
            comment: self.approvalContent.comment,
            // documentNumber: self.selectedDocNo ? self.selectedDocNo.key : null
        };
        self.approvalForm.loading = true;
        var dataURL = URI.PROGRESS_PAYMENT.PP_DO_APPROVAL;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj', body: bodyParams })
            .then(function (result) {
                self.get_general_info();

                self.isModified = true;

                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
                self.approvalForm.loading = false;
                // self.form.catch(e);
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    ProgressPaymentSA.prototype.comment = function () {
        var self = this;
        var p = $q.defer();


        if (self.approvalContent.comment) {
            self.isBusy = true;
            var urlParams = {
                // approvalId: self.data.approvalId,
                contractId: userService.system.userdata.contractId,
                moduleId: self.moduleId
            }
            var bodyParams = {
                entityInstanceId: self.entityInstanceId,
                comment: self.approvalContent.comment
            };
            self.approvalForm.loading = true;
            self[URI.MODULE_APPROVAL.ADD_COMMENT.method](URI.MODULE_APPROVAL.ADD_COMMENT, { url: urlParams, urltype: 'obj', body: bodyParams })
                .then(function (result) {
                    self.get_activity_summary();
                    self.approvalContent.comment = "";
                    self.isModified = true;

                    p.resolve();
                })
                .catch(function (e) {
                    p.reject(e);
                    Message.dberror(e);

                })
                .finally(function () {
                    self.isBusy = false;
                    self.approvalForm.loading = false;
                })
        }
        else {
            Message.error("Please add comment");
            p.reject();
        }

        return p.promise;
    }

    ProgressPaymentSA.prototype.save = function (transitionId, roleId, isRevise) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.PROGRESS_PAYMENT.PP_SAVE;
        if (isRevise) dataURL = URI.PROGRESS_PAYMENT.PP_REVISE;
        var saveParams = {
            contractId: self.contractId,
            roleId: roleId,
            entityInstanceId: self.entityInstanceId,
            paymentPeriodId: self.selectedPeriodId,
            moduleTypeId: (self.currentType || {}).key || self.types[0].key,
            workflowId: userService.system.workflowId
        };
        saveParams.transitionId = transitionId;
        self.isBusy = true;
        self[dataURL.method](dataURL, { url: saveParams, urltype: 'obj', body: {} })
            .then(function (r) {
                if (r) self.entityInstanceId = r;
                self.isBusy = false;
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                self.isBusy = false;
                p.reject(e)
            })
            .finally(function () {

            })

        return p.promise;
    }

    ProgressPaymentSA.prototype.get_general_info = function (id) {
        var self = this;
        var p = $q.defer();
        self.isBusy = true;
        var dataURL = URI.PROGRESS_PAYMENT.GET_GENERAL_INFO;

        self.itemsLookup = {};
        self.ppiLookup = {};
        self.isModulesLoaded = false;
        self.modulesLookup = {};
        self.modulesList = [];

        self.generalInfo = {};
        self.workflow = {
            transitions: [],
            transitionsInstancesList: [],
            pastAssignments: []
        };
        self.activityList = [];
        self.approvalsList = [];
        self.signInfo = {};

        var params = {
            contractId: self.contractId,
            // transitionId: transitionId || 0,
            entityInstanceId: id || self.entityInstanceId || 0,
            paymentPeriodId: self.selectedPeriodId,
            moduleTypeId: (self.currentType || {}).key || self.types[0].key
        };
        self[dataURL.method](dataURL, { url: params, urltype: 'obj', body: {} })
            .then(function (r) {
                if (r) {
                    for (var key in r) {
                        if (r.hasOwnProperty(key))
                            self.generalInfo[key] = r[key];
                    }
                    self.entityInstanceId = self.generalInfo.entityInstanceId || 0;

                    self.get_RolesDict();

                        // if (r.documentNumber) self.module.name = self.module.baseName + " " + r.documentNumber;
                        if (r.entityInstanceId) {
                            self.getModules();
                            if (r.hasManualDocumentAssignment || r.isClosedState) self.get_assignments();
                            if (r.isClosedState) self.get_signers();
                            if (!r.isDraftState) self.get_assignments_history();
                            self.get_activity_summary();
                            self.get_transitionList();
                        }
                        self.operations = userService.getOperationsFor("PP");
                        //if ((r.permissions || []).length) {
                        //    for (var j = 0; j < r.permissions.length; j++) {
                        //        Object.assign(self.operations, Permissions[r.permissions[j]]);
                        //    }
                        //}
                        self.isDraft = r.isDraftState;
                        self.isPayByQuantityEnabled = r.payByQuantityEnabled;

                        if (self.generalInfo.approvalId && self.generalInfo.canApprove) {
                            self.setupApprovalForm();
                        }

                        self.get_PrintoutList();
                    }
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);

                p.reject(e)
            })
            .finally(function () {
                self.isBusy = false;
            })

        return p.promise;
    }

    ProgressPaymentSA.prototype.get_assignments = function () {
        var self = this;
        var dataURL = URI.PROGRESS_PAYMENT.GET_ASSIGNMENT;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };
        self.assignments = {
            lookup: {}
        };
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var key in (r || {})) {
                    if (r.hasOwnProperty(key))
                        self.assignments[key] = r[key];
                }
                for (var i = 0; i < (r.manualAssignUsers || []).length; i++) {
                    self.assignments.lookup[r.manualAssignUsers[i].key] = r.manualAssignUsers[i];
                }
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            });

        return p.promise;

    }

    ProgressPaymentSA.prototype.get_signers = function () {
        var self = this;
        var dataURL = URI.PROGRESS_PAYMENT.GET_SIGNERS;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };
        self.signInfo = {
            lookup: {}
        };
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var key in (r || {})) {
                    if (r.hasOwnProperty(key))
                        self.signInfo[key] = r[key];
                }
                for (var i = 0; i < (r.signers || []).length; i++) {
                    self.signInfo.lookup[r.signers[i].key || r.signers[i].id] = r.signers[i];
                }
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            });

        return p.promise;

    }

    ProgressPaymentSA.prototype.get_assignments_history = function () {
        var self = this;
        var dataURL = URI.PROGRESS_PAYMENT.GET_ASSIGNMENT_HISTORY;
        self.workflow.pastAssignments = [];
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };

        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var i = 0; i < (r || []).length; i++) {
                    if (r[i].validFrom)
                        r[i].validFrom = moment(addZ(r[i].validFrom)).format(userService.formats.datetime);
                    if (r[i].validThrough)
                        r[i].validThrough = moment(addZ(r[i].validThrough)).format(userService.formats.datetime);
                }

                self.workflow.pastAssignments = r;
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            });

        return p.promise;

    }
    var addZ = function (date) {
        var ret = date || null;
        if (date && date.indexOf("Z") == -1)
            ret = date + "Z";
        return ret;
    }
    ProgressPaymentSA.prototype.get_activity_summary = function () {
        var self = this;
        var dataURL = URI.PROGRESS_PAYMENT.GET_ACTIVITY_SUMMARY;
        var urlParams = {
            entityInstanceId: self.entityInstanceId || 0,
            contractId: self.contractId || 0
        };

        self.activityList = [];
        self.isActivityLoaded = false;

        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var i = 0; i < (r.approvals || []).length; i++) {
                    if (r.approvals[i].approvedOn.charAt(r.approvals[i].approvedOn.length - 1) != 'Z') r.approvals[i].approvedOn = r.approvals[i].approvedOn + 'Z';
                    r.approvals[i].approvedOn = moment(addZ(r.approvals[i].approvedOn)).format(userService.formats.datetime);

                }
                self.approvalsList = (r || {}).approvals || [];
                for (var i = 0; i < r.actionInstanceIds.length; i++) {
                    if (r.actionInstanceIds[i].isDraft) r.actionInstanceIds[i].msg = 'The payment draft has entered the workflow';
                    self.activityList.push(r.actionInstanceIds[i]);
                    r.actionInstanceIds[i].loading = true;
                    self.get_past_transition_info(r.actionInstanceIds[i]);
                }
                //var relations = [];
                //for (var i = 0; i < (r.relations || []).length; i++) {
                //    if (r.relations[i].records > 0) relations.push(r.relations[i]);
                //}

                // self.loadManagers(relations);
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                self.isActivityLoaded = true;
            });

        return p.promise;

    }

    ProgressPaymentSA.prototype.get_transitionList = function () {
        var self = this;
        var dataURL = URI.PROGRESS_PAYMENT.GET_TRANSITIONS;
        var urlParams = {
            workflowId: userService.system.workflowId,
            entityInstanceId: self.entityInstanceId || 0,
            paymentPeriodId: self.selectedPeriodId || 0,
            contractId: self.contractId || 0
        };
        self.workflow.transitions = [];
        self.loadingTransitions = true;
        var p = $q.defer();
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                self.workflow.transitions = r;
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            })
            .finally(function () {
                self.loadingTransitions = false;
            })

        return p.promise;

    }

    ProgressPaymentSA.prototype.get_past_transition_info = function (actionInstance) {
        var self = this;
        var p = $q.defer();

        // self.activityList.push(actionInstance);
        var urlParams = {
            // entityInstanceId: self.entityInstanceId === 0 ? self.fromEntityInstanceId : self.entityInstanceId,
            actionInstanceId: actionInstance.key,
            contractId: userService.system.userdata.contractId,
            noFields: actionInstance.isDraft || false
        };
        var dataURL = URI.PROGRESS_PAYMENT.GET_TRANSITION_INSTANCE;
        self[dataURL.method](dataURL, { url: urlParams, urltype: 'obj' }, { headers: { 'moduleId': self.moduleId } })
            .then(function (r) {
                for (var key in (r || {})) {
                    if (r.hasOwnProperty(key))
                        actionInstance[key] = r[key];
                }
                if (actionInstance.createdOn.charAt(actionInstance.createdOn.length - 1) != 'Z') actionInstance.createdOn = actionInstance.createdOn + 'Z';
                actionInstance.createdOn = moment(addZ(actionInstance.createdOn)).format(userService.formats.datetime);
                if (actionInstance.fields) {
                    actionInstance.screen = new WorkflowScreen({
                        moduleId: self.moduleId,
                        entityInstanceId: self.entityInstanceId,
                        contractId: self.contractId
                    });
                    actionInstance.screen.data = { fields: actionInstance.fields };
                    actionInstance.screen.setupForm(false);
                }


                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                console.error(e);
                p.reject(e);
            })
            .finally(function () {

                actionInstance.loading = false;

            });


        return p.promise;
    }

    ProgressPaymentSA.prototype.assign_User = function (u) {
        var self = this;
        var p = $q.defer();
        self.isAssigningUser = true;
        self[URI.STATE_ASSIGNMENT.ASSIGN_USER.method](URI.STATE_ASSIGNMENT.ASSIGN_USER, { url: { entityInstanceId: self.entityInstanceId, contractId: self.contractId || 0, moduleId: self.moduleId }, urltype: 'obj', body: u })
            .then(function (result) {
                //self.dataOriginalObject = result;
                p.resolve(result);
                self.assignments.assignee = self.assignments.lookup[u.key];
                Message.info('User assignment changed');
                if (result && result.length) {
                    for (var i = 0; i < result.length; i++) {

                            if (result[i].validFrom)
                                result[i].validFrom = moment(addZ(result[i].validFrom)).format(userService.formats.datetime);
                            if (result[i].validThrough)
                                result[i].validThrough = moment(addZ(result[i].validThrough)).format(userService.formats.datetime);
                        }
                    }
                    self.workflow.pastAssignments = result;

                    self.get_activity_summary();
                })
                .catch(function (e) {
                    p.reject(e);
                })
                .finally(function () { self.isAssigningUser = false; });

        return p.promise;
    }

    ProgressPaymentSA.prototype.get_fields = function (trigger) {
        var self = this;
        var p = $q.defer();
        var params = {
            entityInstanceId: self.entityInstanceId,
            // transitionId: transitionId,
            contractId: self.contractId,
            workflowId: userService.system.workflowId
        };
        for (var key in trigger)
            if (trigger.hasOwnProperty(key))
                params[key] = trigger[key];

        var dataURL = URI.PROGRESS_PAYMENT.GET_FIELDS;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
        return p.promise;
    }


    ProgressPaymentSA.prototype.get_RolesDict = function () {
        var self = this;
        var p = $q.defer();
        self.rolesDict = [];
        var params = {
            moduleId: self.moduleId,
            contractId: self.contractId
        };
        // self.propertiesScreen = {};
        self.rolesDictLookup = {};
        var dataURL = URI.MODULE.MODULE_ROLES_DICT;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                self.rolesDict = r;
                for (var i = 0; i < r.length; i++) {
                    self.rolesDictLookup[r[i].key] = r[i];
                }

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject();
            })
        return p.promise;
    }


    ProgressPaymentSA.prototype.create = function (periodId) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.PROGRESS_PAYMENT.PP_CREATE;
        var params = {
            contractId: self.contractId,
            paymentPeriodId: periodId || self.selectedPeriodId,
            moduleTypeId: (self.currentType || {}).key || self.types[0].key,
            workflowId: userService.system.workflowId
        };
        self.isBusy = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                if (r) {
                    self.entityInstanceId = r;
                }

                self.get_general_info();
                Message.info("Progress Payment Request created successfully.");
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
                self.isBusy = false;
            })
            .finally(function () {

            })

        return p.promise;
    }

    ProgressPaymentSA.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.PROGRESS_PAYMENT.PP_DELETE;
        var params = {
            contractId: self.contractId,
            entityInstanceId: self.entityInstanceId
        };
        self.isBusy = true;
        self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
            .then(function (r) {
                Message.info("Progress payment request deleted successfully.");
                // self.get_Data();
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
                self.isBusy = false;
            })
            .finally(function () {
                // self.isBusy = false;
            })

        return p.promise;
    }

    ProgressPaymentSA.prototype.get_periods = function () {
        var self = this;
        var p = $q.defer();
        self.isBusyPeriods = true;
        if (!self.currentPeriod) self.currentPeriod = { id: 0, name: 'Current Period' };
        Dictionaries.PPPeriods({ contractId: self.contractId })
            .then(function (r) {
                if (r && r.length) {
                    if (r && r.length && self.currentPeriod) {
                        for (var i = 0; i < r.length; i++) {
                            r[i].startDate = r[i].startDate ? moment(r[i].startDate).format(userService.formats.date) : "";
                            r[i].endDate = r[i].startDate ? moment(r[i].endDate).format(userService.formats.date) : "";
                            if (r[i].key == self.currentPeriod.id) {
                                r[i].current = true;
                            }
                        }
                    }
                    if (!self.currentPeriod.id) r.splice(0, 0, { key: 0, value: 'Current Period', current: true });
                    self.periodsDict = r;
                    // self.currentPeriod.value = self.periodsDict[0].value;
                } else self.periodsDict = [];

                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {
                self.isBusyPeriods = false;
            })

        return p.promise;
    }

    ProgressPaymentSA.prototype.get_currentPeriod = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.PROGRESS_PAYMENT.PPD_CURRENT;
        self[dataURL.method](dataURL, { url: { contractId: self.contractId }, urltype: 'obj' })
            .then(function (r) {
                r.startDate = r.startDate ? moment(r.startDate).format(userService.formats.date) : "";
                r.endDate = r.startDate ? moment(r.endDate).format(userService.formats.date) : "";
                self.currentPeriod = r;
                self.get_periods();
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
            .finally(function () {

            })

        return p.promise;
    }

        ProgressPaymentSA.prototype.printDocument = function (id, isClosed) {
            var self = this;
            var p = $q.defer();
            self.isPrintStarted = true;
            var dataURL = isClosed ? URI.MODULE.GET_INSTANCES_AS_PDF : URI.TEMPLATES.PREVIEWS;
            var params = {
                url: {
                    id: id,
                    moduleId: self.moduleId,
                    contractId: self.contractId
                },
                body: { list: [self.entityInstanceId] },
                urltype: 'obj'
            }
            self[dataURL.method](dataURL, params)
                .then(function (result) {

                    // If we downloaded the blob, then we just need to print the content
                    if (isClosed && result && result.length) {

                        $timeout(function () {
                            var iframe = document.createElement('iframe');
                            iframe.style.display = 'none';
                            iframe.src = window.URL.createObjectURL(fileService.createFile(result, '', 'application/pdf'));
                            document.body.appendChild(iframe);

                            $timeout(function () {
                                iframe.contentWindow.print();
                            }, 400);
                        }, 0);
                    }
                    // We do a print
                    else {
                        if (result && result.length) {
                            if ($("#section-to-print").length == 0) {
                                var div = $('<div />').appendTo('body');
                                div.attr('id', 'section-to-print');
                                div.attr('class', 'fr-view');
                            }
                            var margin = {};
                            if (!result[0].margins) margin = { marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0 };
                            else margin = { marginLeft: result[0].margins.marginLeft || 0, marginRight: result[0].margins.marginRight || 0, marginTop: result[0].margins.marginTop || 0, marginBottom: result[0].margins.marginBottom || 0 };
                            $("#section-to-print").css({
                                "margin-left": margin.marginLeft + "in",
                                "margin-right": margin.marginRight + "in",
                                "margin-bottom": margin.marginBottom + "in",
                                "margin-top": margin.marginTop + "in"
                            });
                            var content = "";
                            for (var i = 0; i < result.length; i++) {

                                content += '<div style="position: fixed; top: ' + result[i].margins.marginTop + 'in; left:  ' + result[i].margins.marginLeft + 'in; right:  ' + result[i].margins.marginRight + 'in;  overflow: hidden; height: ' + ((result[i].header || {}).height + 'in' || 'auto') + ';">'
                                    + ((result[i].header || {}).content || "")
                                    + '</div><table><thead><tr><td><div class="invisible" style="height: ' + ((result[i].header || {}).height + 'in' || 'auto') + ';">'
                                    + ((result[i].header || {}).height ? "" : ((result[i].header || {}).content || ""))
                                    + '</div></td></tr></thead>'
                                    + '<tbody class="forceBlock"><tr class="forceBlock"><td class="forceBlock">'
                                    + (result[i].content || "")
                                    + '</td></tr></tbody>'
                                    + '<tfoot><tr><td><div class="invisible" style="height: ' + ((result[i].footer || {}).height + 'in' || 'auto') + ';">'
                                    + ((result[i].footer || {}).height ? "" : ((result[i].footer || {}).content || ""))
                                    + '</div></td></tr></tfoot></table >'
                                    + '<div style="position: fixed; bottom: ' + result[i].margins.marginBottom + 'in; left:  ' + result[i].margins.marginLeft + 'in; right:  ' + result[i].margins.marginRight + 'in; overflow: hidden; height: ' + ((result[i].footer || {}).height + 'in' || 'auto') + ';">'
                                    + ((result[i].footer || {}).content || "")
                                    + '</div>';

                                if (i != result.length - 1) content += "<div style='page-break-after: always;'></div>";

                            }
                            $('#section-to-print').html(content);
                            $timeout(function () {
                                $window.print();
                            }, 400)
                        }
                        else {
                            Message.dberror('No print document found');
                        }
                    }
                })
                .catch(function (e) {
                    Message.dberror(e);
                })
                .finally(function () {
                    self.isPrintStarted = false;
                    //$scope.manager.loadPage();
                });
        }

        ProgressPaymentSA.prototype.get_PrintoutList = function () {
            var self = this;
            var p = $q.defer();

            if (self.entityInstanceId) {
                Dictionaries.UserTemplates({ moduleId: self.moduleId }, { contractId: self.contractId, typeName: TEMPLATES_TYPES[1], screenId: self.generalInfo.screenId })
                    .then(function (result) {
                        if (result) self.printouts = result;
                        p.resolve();
                    })
                    .catch(function (e) {
                        p.reject(e);
                    });
            } else {
                p.resolve();
            }

            return p.promise;
        }

    ProgressPaymentSA.prototype.downloadPdfs = function () {
        var self = this;
        var p = $q.defer();
        var list = [self.entityInstanceId];
        self.isPrintStarted = true;
        var urlparams = {
            moduleId: self.moduleId,
            contractId: self.contractId
        }
        var saveByteArray = function (reportName, byte) {
            var blob = new Blob([byte], { type: "application/pdf" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = reportName;
            link.download = fileName;
            link.click();
        };


        // $scope.manager.loading = true;
        //var d = dialogs.confirm('Deactivate User', 'Are you sure you want to deactivate the selected User(s)?');
        self[URI.MODULE.GET_INSTANCES_AS_PDF.method](URI.MODULE.GET_INSTANCES_AS_PDF, { url: urlparams, urltype: 'obj', body: { list: list } })
            .then(function (result) {
                var contractNo = self.userService.system.contractsLookup[self.userService.system.userdata.contractId].contractNo;
                var name = "Progress Payment" + '-' + contractNo;
                var sampleArr = Functions.base64ToArrayBuffer(result);
                saveByteArray(name, sampleArr);

            })
            .catch(function (e) {
                Message.dberror(e);
            })
            .finally(function () {
                // $scope.manager.loading = false;
                self.isPrintStarted = false;
            });
    }

    ProgressPaymentSA.prototype.excludeRolesFromTransition = function (t) {
        var self = this;
        t.exclusionRoles = angular.copy(self.rolesDict);
        t.showExcludeRoles = true;
        if ((t.roleToExcludeIds || []).length) {
            for (var i = 0; i < t.exclusionRoles.length; i++) {
                if (t.roleToExcludeIds.indexOf(t.exclusionRoles[i].key) != -1)
                    t.exclusionRoles[i].isUsed = true;
            }
        }
    }

    ProgressPaymentSA.prototype.cancelExcludeRoles = function (t) {
        t.showExcludeRoles = false;
    }

    ProgressPaymentSA.prototype.syncExcludeRoles = function (t) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE.SYNC_EXCLUDED_ROLES;
        var roleToExcludeIds = [];
        for (var i = 0; i < t.exclusionRoles.length; i++) {
            if (t.exclusionRoles[i].isUsed)
                roleToExcludeIds.push(t.exclusionRoles[i].key);
        }
        var params = {
            url: {
                moduleId: self.moduleId,
                actionInstanceId: t.actionInstanceId,
                contractId: self.contractId
            },
            body: roleToExcludeIds,
            urltype: 'obj'
        }
        self[dataURL.method](dataURL, params)
            .then(function (result) {
                t.roleToExcludeIds = roleToExcludeIds;
                t.showExcludeRoles = false;
                p.resolve();
            })
            .catch(function (e) {
                p.reject(e);
            });
        return p.promise;
    }

    ProgressPaymentSA.prototype.sign_Doc = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE_SIGNATURE.GET;
        var signId;
        self.signatureOptions = {
            sign: false
        };
        for (var i = 0; i < self.signInfo.signers.length; i++) {
            if (self.signInfo.signers[i].id == userService.system.userdata.id && !self.signInfo.signers[i].signed) {
                signId = self.signInfo.signers[i].signId;
                break;
            }
        }
        self[dataURL.method](dataURL, { id: signId })
            .then(function (result) {
                self.signatureOptions = {
                    sign: true,
                    signatureId: result.signId,
                    clientId: result.clientId,
                    url: result.signatureUrl,
                    // redirect: true,
                    isProd: result.isProd,
                    setSigned: function (sid) {
                        self.put(URI.MODULE_SIGNATURE.SET_SIGNED, { url: { id: sid }, urltype: 'obj' })
                            .then(function () { self.get_general_info(); })
                            .catch(function (e) { console.error(e) })
                    },
                    callback: function () {

                        return;
                    }
                };
            })
            .catch(function (e) {
                Message.dberror(e);
            });
    }

    // payment related

    var processColumns = function (r) {
        var columns = [];
        if (r && r.length) {
            // setup width
            var widthBits = 0;
            for (var i = 0; i < r.length; i++) {
                if (!r[i].isFilter) {
                    r[i].width = r[i].width || 1;
                    widthBits += parseInt(r[i].width);
                }
            }

            for (var i = 0; i < r.length; i++) {

                r[i].formattingsMapping = {};
                r[i].restrictionsMapping = {};
                if ((r[i].formattings || []).length) {
                    for (var j = 0; j < r[i].formattings.length; j++) {
                        r[i].formattingsMapping[r[i].formattings[j].key] = r[i].formattings[j].value;
                    }
                }
                if ((r[i].restrictions || []).length) {
                    for (var j = 0; j < r[i].restrictions.length; j++) {
                        r[i].restrictionsMapping[r[i].restrictions[j].key] = r[i].restrictions[j].value;
                    }
                }

                //set flags
                if ((1 & r[i].priority) != 0) r[i].onPhone = true;
                else r[i].onPhone = false;
                if ((2 & r[i].priority) != 0) r[i].onTablet = true;
                else r[i].onTablet = false;

                var type = function (column) {
                    if (column.typeId == 2) {
                        if (column.formattingsMapping[1] == 2) return 'money';
                        if (column.formattingsMapping[1] == 3) return 'number';
                        if (column.formattingsMapping[1] == 1) return 'number';
                    }
                    if (column.name == 'state_name') return 'stateName';
                    if (column.showThumbnail) return 'thumbnail';
                    return 'text';
                };

                    columns.push({
                        width: 100 / widthBits * r[i].width,
                        onPhone: r[i].name == "payment_amount" || r[i].name == "payment_percent" ? true : r[i].onPhone,
                        onTablet: r[i].name == "payment_amount" || r[i].name == "payment_percent" ? true : r[i].onTablet,
                        id: r[i].id,
                        name: r[i].id > 0 ? r[i].id : r[i].name,
                        label: r[i].label,
                        type: type(r[i]),
                        isFilter: r[i].isFilter,
                        isPercent: r[i].formattingsMapping[1] == 3 ? true : false,
                        isPayment: r[i].isPayment,
                        isEditableAmount: r[i].isEditableAmount || r[i].name == "payment_amount" ? true : false,
                        isEditablePercentage: r[i].isEditablePercentage || r[i].name == "payment_percent" ? true : false,
                        isEditableQuantity: r[i].isEditableQuantity || r[i].name == "payment_qty" ? true : false,
                        typeId: r[i].typeId,
                        fieldTypeId: r[i].fieldTypeId,
                        formattingsMapping: r[i].formattingsMapping,
                        restrictionsMapping: r[i].restrictionsMapping,
                        visible: true,
                    });
                }
            }

        return columns;
    }

    var processFields = function (row, columnsLookup) {
        for (var key in row) {
            if (row.hasOwnProperty(key) && typeof columnsLookup[key] != 'undefined') {
                if (row[key] !== "" && row[key] !== null && typeof row[key] != "undefined") {
                    // console.log(columnsLookup[key].typeId, columnsLookup[key].type, row[key])
                    if (columnsLookup[key].typeId == 3)
                        row[key] = ([true, false].indexOf(row[key]) != -1) ? (row[key] ? "Yes" : "No") : row[key];
                    else if (columnsLookup[key].typeId == 2 && (columnsLookup[key].isPayment || columnsLookup[key].isPercent))
                        row[key] = (row[key] || "") * 1;
                    //date
                    else if (columnsLookup[key].typeId == 4 && row[key]) {
                        var format = userService.formats.datetime;
                        if (columnsLookup[key].restrictionsMapping[5] == 5) {
                            format = userService.formats.time;
                            row[key] = moment(addZ(row[key])).format(format);
                        } else if (columnsLookup[key].restrictionsMapping[5] == 3) {
                            format = userService.formats.date;
                            row[key] = moment.utc(addZ(row[key])).format(format);
                        } else {
                            row[key] = moment(addZ(row[key])).format(format);
                        }
                    }
                    else if (columnsLookup[key].type == 'thumbnail') {
                        if (row[key] && row[key + '_thumb'])
                            row[key + '_thumb'] = '<span class=""><img src="data:'
                                + 'image/jpeg;base64,'
                                + row[key + '_thumb'] + '" />'
                                + '</span>';
                        else {
                            row[key] = "-";
                            row[key + '_thumb'] = "<i title='Thumbnail Missing' class='neutralText mdi mdi-image-minus'></i>";
                        }
                    }

                    else row[key] = (row[key] || "").toString();

                } else {
                    row[key] = '-';
                }
            }
        }
        return row;
    }

    var parseData = function (data, columns) {
        var data = data || [], retval = [], columnsLookup = {};
        for (var j = 0; j < columns.length; j++) columnsLookup[columns[j].name] = columns[j];
        for (var i = 0; i < data.length; i++) retval.push(processFields(data[i], columnsLookup));
        return retval;
    }

        ProgressPaymentSA.prototype.search = function () {
            var self = this;
            for (var i = 0; i < self.modulesList.length; i++) {
                if (self.modulesList[i].moduleId) {
                    self.modulesList[i].isExpanded = true;
                    self.toggleModuleItems(self.modulesList[i], 1);
                }
            }
        }

    ProgressPaymentSA.prototype.togglePartials = function (item) {
        var self = this;
        for (var i = 0; i < item.partials.length; i++) {
            item.partials[i].isHideChildren = !item.partials[i].isHideChildren;
        }
        item.hasChildrenHidden = item.partials[0].isHideChildren;
    }

        ProgressPaymentSA.prototype.getModulePages = function (m) {
            var self = this;
            var dataURL = URI.PROGRESS_PAYMENT.PPI_PAGES;
            var p = $q.defer();

            if (!m.relationId || m.customPagesInfo) {
                m.customPagesInfo = m.customPagesInfo || self.generateCustomPagesInfo(m.itemsCount, self.pageSize);
                p.resolve();
                return p.promise;
            }

            var params = {
                progressPaymentEntityInstanceId: self.entityInstanceId,
            };
            var bodyParams = {
                criteria: self.searchTerm || "",
                isCurrent: false,
                pagesize: self.pageSize
            }

            m.loading = true;
            if (m.moduleId) params.moduleId = m.moduleId;
            if (self.editMode || self.isAllPayments) params.editMode = true;

            self[dataURL.method](dataURL, { url: params, urltype: 'obj', body: bodyParams })
                .then(function (r) {
                    m.customPagesInfo = r;
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                    m.message = "An error has occured while loading page data.";
                    p.reject(e);
                })
            return p.promise;
        }

        ProgressPaymentSA.prototype.generateCustomPagesInfo = function (itemsCount, pageSize) {
            itemsCount ??= 0;
            pageSize ??= 1;

            var result = [];

            // in case we have only one page, then we just need to create one custom page info
            if (itemsCount <= pageSize) {
                result.push({ offset: 0, pageNo: 1, pageSize: itemsCount, parentItems: itemsCount });
            }
            else {
                // we have multiple pages, we need to split pages
                var nrOfPages = itemsCount / pageSize;

                for (var i = 0; i < nrOfPages; i++) {

                    if (i == (nrOfPages - 1)) {
                        var _itemsCount = itemsCount - ((i + 1) * pageSize);
                        result.push({ offset: i * pageSize, pageNo: i + 1, pageSize: _itemsCount, parentItems: _itemsCount });
                    }
                    else {
                        result.push({ offset: i * pageSize, pageNo: i + 1, pageSize: pageSize, parentItems: pageSize });
                    }
                }
            }

            return result;
        }

        ProgressPaymentSA.prototype.toggleModuleItems = function (m, pageNo) {
            var self = this;
            self.getModulePages(m)
                .then(function() {
                    self.toggleModuleItemsInternal(m, pageNo);
                })
                .catch(function() {
                    self.toggleModuleItemsInternal(m, 0);
                });
        }

        ProgressPaymentSA.prototype.toggleModuleItemsInternal = function (m, pageNo) {
            var self = this;
            var dataURL = m.relationId ? URI.PROGRESS_PAYMENT.PPI_RETRIEVE : URI.ADJUSTMENTS.SEARCH_PP;
            m.message = null;

            if (!pageNo) m.isExpanded = !m.isExpanded;

            var setPagination = function (m, count, pageNo) {
                if (pageNo) {
                    if (pageNo <= 0) pageNo = 1;
                    if (pageNo > m.pages) pageNo = m.pages;
                }
                m.currentPage = pageNo || 1;

                if (m.customPagesInfo) {
                    var currentCustomPageInfo = m.customPagesInfo[m.currentPage - 1];
                    m.totalCount = m.customPagesInfo.reduce((a, i) => a + i.parentItems, 0);
                    m.pages = m.customPagesInfo.length;
                    m.page = currentCustomPageInfo.offset;
                    m.pageStart = [...m.customPagesInfo].splice(0, m.currentPage - 1).reduce((a, i) => a + i.parentItems, 0) + 1;
                    m.pageEnd = [...m.customPagesInfo].splice(0, m.currentPage).reduce((a, i) => a + i.parentItems, 0);
                    m.pageSize = currentCustomPageInfo.pageSize;
                }    
                else {
                    m.totalCount = count;
                    m.pages = Math.ceil(count / self.pageSize);
                    m.page = pageNo || 1;
                    m.pageStart = self.pageSize * (m.currentPage - 1) + 1;
                    m.pageEnd = self.pageSize * m.currentPage < count ? self.pageSize * m.currentPage : count;
                    m.pageSize = self.pageSize;
                }
            }

        setPagination(m, m.itemsCount, pageNo);

            if (m.isExpanded) {
                var params = {
                    contractId: self.contractId,
                    progressPaymentEntityInstanceId: self.entityInstanceId,
                    // relationId: m.relationId
                };
                var bodyParams = {
                    criteria: self.searchTerm || "",
                    isCurrent: false,
                    page: m.page,
                    pagesize: m.pageSize
                }
                if (m.moduleId) params.moduleId = m.moduleId;
                if (self.editMode || self.isAllPayments) params.editMode = true;
                m.loading = true;
                self[dataURL.method](dataURL, { url: params, urltype: 'obj', body: bodyParams })
                    .then(function (r) {

                    setPagination(m, r.records, pageNo);

                    m.items = [];
                    if (((r || {}).data || []).length) {
                        m.blockingStateName = r.data[0].blockingStateName || "";
                        m.blockingUsername = r.data[0].blockingUsername || "";
                        m.isBlocked = r.data[0].isBlocked || false;
                    }
                    if (m.relationId) {
                        if (((r || {}).data || []).length) {
                            var items = [];

                            for (var i = 0; i < r.data[0].items.length; i++) {
                                var currentMainItem = r.data[0].items[i];

                                self.ppiLookup[currentMainItem.child_entity_instance_id] = currentMainItem;
                                items.push(currentMainItem);

                                if ((currentMainItem.items || []).length) {
                                    //has partials
                                    var partials = [];
                                    currentMainItem.readonly = true;
                                    for (var j = 0; j < currentMainItem.items.length; j++) {
                                        if (currentMainItem.items[j].moduleId == 0) { // itself as child
                                            items.push(currentMainItem.items[j].items[0]);
                                            partials.push(currentMainItem.items[j].items[0]);
                                        }
                                        else {
                                            currentMainItem.items[j].fields = processColumns(currentMainItem.items[j].fields);
                                            items.push(currentMainItem.items[j]);
                                            partials.push(currentMainItem.items[j]);
                                        }

                                    }

                                    currentMainItem.partials = partials;
                                }
                            }

                            m.items = parseData(items, m.fields);
                        }

                        if (((r || {}).data || []).length && r.data[0].totals) {
                            m.totals = r.data[0].totals;
                            self.modulesLookup[m.relationId].totals = m.totals;
                        }

                    } else {
                        // adjustments
                        var itemsLookup = {};
                        m.totals = {};
                        // var totals = { amount: r.totalPayment || r.total_payment }
                        if (r.totals) {
                            for (var key in r.totals) {
                                m.totals[key] = r.totals[key]
                            }
                            // m.totals = r.totals;
                            self.modulesLookup[0].totals = m.totals;
                        }
                        m.totals.amount = r.totalPayment || r.total_payment;
                        for (var i = 0; i < (r.items || []).length; i++) {
                            itemsLookup[r.items[i].id] = r.items[i];
                            if (r.items[i].type == 1) {
                                m.hasRecurring = true;
                            }
                        }

                            m.items = r.items;
                            m.itemsLookup = itemsLookup;
                            // m.totals = totals;
                        }
                    })
                    .catch(function (e) {
                        console.error(e);
                        Message.dberror(e);
                        m.message = "An error has occured while loading data.";
                    })
                    .finally(function () {
                        m.loading = false;
                    })
            } else {
                m.items = null;
            }

    }

        ProgressPaymentSA.prototype.getModules = function () {
            var self = this;
            self.message = null;
            var dataURL = URI.PROGRESS_PAYMENT.PP_RELATIONS;
            var adjustmentsCols = [
                { name: 'name', label: 'Name', type: 'text', onTablet: true, onPhone: true, fieldTypeId: 1, visible: true },
                { name: 'category', label: 'Category', type: 'text', onTablet: true, onPhone: true, fieldTypeId: 1, visible: true },
                { name: 'typeName', label: 'Type', type: 'text', onTablet: true, onPhone: true, fieldTypeId: 1, visible: true },
                { name: 'retainedAmount', label: 'Total retained', type: 'money', onTablet: true, onPhone: true, fieldTypeId: 1, isMoneyAmount: true, visible: true },
                { name: 'totalThisPeriod', label: 'This period', type: 'money', onTablet: true, onPhone: true, fieldTypeId: 1, isMoneyAmount: true, visible: true },
                { name: 'totalReleased', label: 'Total Released', type: 'money', onTablet: true, onPhone: true, fieldTypeId: 1, isMoneyAmount: true, visible: true },
                { name: 'availableToPay', label: 'Available to pay', type: 'money', onTablet: true, onPhone: true, fieldTypeId: 1, isMoneyAmount: true, visible: true },
                { name: 'payment_amount', label: 'Amount', type: 'money', onTablet: true, onPhone: true, fieldTypeId: 23, isPayment: true, isEditableAmount: true, formattingsMapping: { 1: 2 }, visible: true },
                { name: 'payment_percent', label: 'Percentage', type: 'number', onTablet: true, onPhone: true, fieldTypeId: 2, isPercent: true, isPayment: true, isEditablePercentage: true, formattingsMapping: { 1: 3 }, visible: true },
                { name: 'totalPrevious', label: 'Previous', type: 'money', onTablet: true, onPhone: true, fieldTypeId: 1, isMoneyAmount: true, visible: true },
            ];
            self.isBusy = true;
            self.isModulesLoaded = false;
            var params = {
                contractId: self.contractId,
                progressPaymentEntityInstanceId: self.entityInstanceId,
                editMode: false
            };
            if (self.editMode || self.isAllPayments) params.editMode = true;
            self[dataURL.method](dataURL, { url: params, urltype: 'obj' })
                .then(function (r) {
                    self.totals = {};
                    for (var key in (r || {}).ppTotals) {
                        if (r.ppTotals.hasOwnProperty(key)) {
                            self.totals[key] = r.ppTotals[key];
                        }
                    }
                    if (((r || {}).modules || []).length) {
                        for (var i = 0; i < r.modules.length; i++) {
                            var module = r.modules[i];

                            if (module.moduleId == MODULE_SOVI) {
                                self.hasPaymentQuantityFields = module.fields.some(f => self.quantityFieldsId.includes(f.id));
                            }

                            module.fields = processColumns(module.fields);
                            self.modulesLookup[module.relationId] = module;
                        }
                    }

                self.modulesList = r.modules;

                if ((r || {}).releases && (r.releases.itemsCount > 0)) {
                    var totals = {
                        amount: 0,
                    };

                    //if (self.generalInfo.isDraftState || self.editMode) adjustmentsCols.unshift(
                    //    { name: 'selected', label: 'Selected', type: 'checkbox', onTablet: true, onPhone: true, fieldTypeId: 1, width: 10 })
                    for (var i = 0; i < (r.releases.items || []).length; i++) {
                        totals.amount = totals.amount + r.items[i].amount;
                        r.releases.items[i] = new AdjustmentItem(r.releases.items[i], { modulesLookup: self.modulesLookup, itemsLookup: self.itemsLookup, ppiLookup: self.ppiLookup, totals: self.totals }, self.entityInstanceId);
                    }
                    var adjustments = {
                        moduleName: 'Adjustments',
                        relationId: 0,
                        fields: adjustmentsCols,
                        isAdjustments: true,
                        total_payment: r.releases.total_payment,
                        payment_amount: r.releases.payment_amount,
                        itemsCount: r.releases.itemsCount
                    }
                    self.modulesLookup[0] = adjustments;
                    self.modulesList.unshift(adjustments);
                }

                if (adjustments && self.modulesList[1]) self.toggleModuleItems(self.modulesList[1], null);
                else if (self.modulesList[0]) self.toggleModuleItems(self.modulesList[0], null);

                self.showHideColumns();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                self.message = e.data.messages;
            })
            .finally(function () {
                self.isBusy = false;
                self.isModulesLoaded = true;
            })
        }

    ProgressPaymentSA.prototype.toggleEditMode = function () {
        var self = this;
        if (self.editMode || self.isAllPayments) self.get_activity_summary();
        self.editMode = !self.editMode;
        self.getModules();
    }


    // pp items
    ProgressPaymentSA.prototype.PPIsave = function (item) {
        var self = this;
        item.loading = true;

        if (typeof item.availableToPay != 'undefined') {
            self.AIsave(item);
        } else {
            self.MIsave(item);
        }
    }

    // module item
    ProgressPaymentSA.prototype.MIsave = function (item) {
        var self = this;
        item.loading = true;

            var dataURL = URI.PROGRESS_PAYMENT.PPI_UPDATE;
            var toSend = {
                progressPaymentEntityInstanceId: self.entityInstanceId,
                payment_percent: null,
                payment_amount: null,
                payment_qty: null,
                child_entity_instance_id: item.child_entity_instance_id,
                parent_child_entity_instance_id: item.parent_child_entity_instance_id,
                relation_id: item.relation_id,
                relation_instance_id: item.relation_instance_id,
                isParentSubItem: item.isParentSubItem
            }
            var fieldName = item.editingAmount ? 'payment_amount' : item.editingPercentage ? 'payment_percent' : item.editingQuantity ? 'payment_qty' : '';
            if (fieldName) {
                toSend[fieldName] = item[fieldName] || 0;
            }

        var params = { contractId: userService.system.userdata.contractId };
        if (self.editMode || self.isAllPayments) params.editMode = true;

        self[dataURL.method](dataURL, { url: params, urltype: 'obj', body: toSend })
            .then(function (r) {
                if (r) {

                    if (self.modulesLookup[0] && !self.modulesLookup[0].relationId && self.modulesLookup[0].hasRecurring && self.modulesLookup[0].isExpanded) {
                        self.toggleModuleItems(self.modulesLookup[0], self.modulesLookup[0].currentPage)
                    }

                    self.ppiLookup[r.items[0].child_entity_instance_id].loading = true;

                    $timeout(function () {
                        self.ppiLookup[r.items[0].child_entity_instance_id].loading = false;
                    }, 3000)

                    for (var key in (r.items || [])[0]) if (r.items[0].hasOwnProperty(key)) self.ppiLookup[r.items[0].child_entity_instance_id][key] = r.items[0][key];
                    // partials
                    if (parseInt(item.level) != 1 || item.IsParentSubItem) {

                        var processResponse = function () {
                            for (var i = 0; i < r.items[0].items.length; i++) {
                                for (var j = 0; j < r.items[0].items[i].items.length; j++) {
                                    if (r.items[0].items[i].items[j].child_entity_instance_id == item.child_entity_instance_id) {
                                        for (var key in r.items[0].items[i].items[j]) {
                                            if (r.items[0].items[i].items[j].hasOwnProperty(key))
                                                item[key] = r.items[0].items[i].items[j][key];
                                        }
                                        return;

                                    }

                                }
                            }
                        }

                        processResponse();
                    }

                    for (var key1 in r.ppTotals) if (r.ppTotals.hasOwnProperty(key1)) self.totals[key1] = r.ppTotals[key1];
                    for (var key2 in r.totals) if (r.totals.hasOwnProperty(key2)) self.modulesLookup[r.items[0].relation_id].totals[key2] = r.totals[key2];
                    self.modulesLookup[r.items[0].relation_id]["total_payment"] = self.modulesLookup[r.items[0].relation_id].totals["total_payment"];
                    self.modulesLookup[r.items[0].relation_id]["payment_amount"] = self.modulesLookup[r.items[0].relation_id].totals["payment_amount"];
                }
                Message.info('Payment item updated successfully');

                })
                .catch(function (e) {
                    Message.dberror(e);
                    console.error(e);
                    if (item.editingAmount) {
                        item.payment_amount = item.bkp;
                    } else if (item.editingPercentage) {
                        item.payment_percent = item.bkp;
                    } else if (item.editingQuantity) {
                        item.payment_qty = item.bkp;
                    }
                })
                .finally(function () { item.loading = false; })

    }

        ProgressPaymentSA.prototype.PPIonFocus = function (item) {
            var self = this;
            item.focus = true;
            item.editingPercentage = self.isEditablePercentage;
            item.editingAmount = self.isEditableAmount;
            item.editingQuantity = self.isEditableQuantity;

            var fieldName = self.isEditableAmount ? 'payment_amount' : self.isEditablePercentage ? 'payment_percent' : self.isEditableQuantity ? 'payment_qty' : '';
            if (fieldName) {
                var field = item[fieldName];
                item.bkp = field;
                if (!field) field = null;
            }
        }
        ProgressPaymentSA.prototype.PPIonBlur = function (item) {
            var self = this;
            item.focus = false;

            var fieldName = self.isEditableAmount ? 'payment_amount' : self.isEditablePercentage ? 'payment_percent' : self.isEditableQuantity ? 'payment_qty' : '';
            if (fieldName) {
                var field = item[fieldName];
                if (!field) {
                    field = 0;
                    if (item.bkp) self.PPIsave(item);
                } else {
                    if (item.bkp != field) self.PPIsave(item);
                }
            }
        }

    ProgressPaymentSA.prototype.PPIsubmit = function (event) {
        if (event) event.target.blur();
    }

    // adjustments items

        ProgressPaymentSA.prototype.AIsave = function (item) {
            var self = this;
            var params = {
                progressPaymentEntityInstanceId: self.entityInstanceId,
                contractId: userService.system.userdata.contractId,
                editMode: self.editMode || self.isAllPayments
            }
            var toSend = angular.copy(item);
            toSend.payment_percent = 0;
            toSend.payment_amount = 0;
            toSend.payment_qty = 0;

            var fieldName = item.editingAmount ? 'payment_amount' : item.editingPercentage ? 'payment_percent' : item.editingQuantity ? 'payment_qty' : '';
            if (fieldName) {
                toSend[fieldName] = item[fieldName] || 0;
            }

            self.modulesLookup[0].isBusy = true;
            item.loading = true;
            var dataURL = URI.ADJUSTMENTS.PAY_ADJUSTMENT;
            self[dataURL.method](dataURL, { url: params, urltype: 'obj', body: toSend })
                .then(function (r) {
                    for (var key in r.ppTotals) if (r.ppTotals.hasOwnProperty(key)) self.totals[key] = r.ppTotals[key];
                    for (var key in r.items[0]) if (r.items[0].hasOwnProperty(key)) item[key] = r.items[0][key];
                    for (var key2 in r.totals) if (r.totals.hasOwnProperty(key2)) self.modulesLookup[0].totals[key2] = r.totals[key2];
                    self.modulesLookup[0]["total_payment"] = r["total_payment"];
                    self.modulesLookup[0]["payment_amount"] = r["payment_amount"];
                    Message.info('Adjustment item updated successfully');

                })
                .catch(function (e) {
                    if (item.editingAmount) {
                        item.payment_amount = item.bkp;
                    } else {
                        item.payment_percent = item.bkp;
                    }
                    Message.dberror(e);
                })
                .finally(function () {
                    item.loading = false;
                    self.modulesLookup[0].isBusy = false;
                })
        }

        ProgressPaymentSA.prototype.undoLastAction = function (t) {
            var self = this;
            CommonEntityInstance.commonUndoLastAction(t, 'PROGRESS_PAYMENT', self.contractId, self.moduleId).then(function () {
                self.get_general_info().then(function () {
                    userService.isCurrentContractPeriodsChanged = true;
                });
            })
        }

    //ProgressPaymentSA.prototype.AIsave = function (item) {
    //    var self = this;
    //    var p = $q.defer();
    //    var toSend = {
    //        progressPaymentEntityInstanceId: self.entityInstanceId,
    //        releaseId: item.id,
    //    }
    //    self.modulesLookup[0].isBusy = true;
    //    item.loading = true;
    //    var dataURL = item.selected ? URI.PROGRESS_PAYMENT.PAY_RELEASED : URI.PROGRESS_PAYMENT.WITHHOLD_RELEASED;
    //    self[dataURL.method](dataURL, { url: { contractId: self.contractId }, urltype: 'obj', body: toSend })
    //        .then(function (r) {
    //            for (var key1 in r.ppTotals) if (r.ppTotals.hasOwnProperty(key1)) self.totals[key1] = r.ppTotals[key1];
    //            for (var key2 in r.totals) if (r.totals.hasOwnProperty(key2)) self.modulesLookup[0].totals[key2] = r.totals[key2];
    //            self.modulesLookup[0]["total_payment"] = self.modulesLookup[0].totals["total_payment"];
    //            self.modulesLookup[0]["payment_amount"] = self.modulesLookup[0].totals["payment_amount"];
    //            Message.info('Adjustment item updated successfully');

    //        })
    //        .catch(function (e) {
    //            Message.dberror(e);
    //            self.properties.selected = !self.properties.selected;
    //        })
    //        .finally(function () {
    //            item.loading = false;
    //            self.modulesLookup[0].isBusy = false;
    //        })
    //}

    return ProgressPaymentSA;
});
