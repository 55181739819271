import { cipo } from 'cipo';

cipo.controller('archiveModuleSelectorController',
    function ($scope, $mdDialog, $http, URI, data, Message) {
        $scope.data = data.checkboxes;
        $scope.title = data.title;
        $scope.showSelectUnselect = data.showSelectUnselect;
        $scope.confirmLabel = formatLabel(data.buttonLabels.find(x => x.key == 'confirm').value);
        $scope.cancelLabel = formatLabel(data.buttonLabels.find(x => x.key == 'cancel').value);
        $scope.description = data.description;
        $scope.contractId = data.contractId;
        $scope.contractNumber = data.contractNumber;
        $scope.isChecked = true;
        $scope.isCheckedAtt = false;

        $scope.confirm = function () {               
            var modules = []

            for (var i = 0; i < $scope.data.length; i++) {
                if ($scope.data[i].value == true) {                      
                    modules.push({
                        id: $scope.data[i].key,
                        abbreviation: $scope.data[i].abbr,
                        projectFolderId: $scope.data[i].folderId
                    });
                }
            }

            // validation
            if ($scope.archiveName == null) {
                Message.error("The Archive name cannot be empty!");
                return;
            }

            if (modules.length == 0) {
                Message.error("Please select at least one valid module to be archived!");
                return;
            }

            var body = {
                archiveName: $scope.archiveName,
                archiveDescription: $scope.archiveDescription,
                contractId: $scope.contractId,
                contractNumber: $scope.contractNumber,
                excludeEmptyFolders: $scope.isChecked,
                excludeAttachments: $scope.isCheckedAtt, 
                modules: modules
            };
            
            $http[URI.CT.GET_ARCHIVES.method](URI.CT.GET_ARCHIVES.toString() + '?contractId=' + $scope.contractId + '&validate=true')
                .then(function (result) {
                    var nameExists = false;

                    if (result.data != null && result.data.length > 0) {
                        for (var i = 0; i < result.data.length; i++) {
                            if (result.data[i].archiveName.toLowerCase().startsWith($scope.archiveName.toLowerCase())) {
                                nameExists = true;
                                break;
                            }
                        }
                    }   

                    if (nameExists) {
                        Message.error("The Archive name already exists. The Archive name is case insensitive and must be unique per contract. "
                                    + "Please choose another Archive name.");
                        return;
                    }

                    $http[URI.CT.SAVE_ARCHIVE.method](URI.CT.SAVE_ARCHIVE.toString() + '?contractId=' + $scope.contractId, body)
                        .then(function () {
                            //Message.info("Archive process has started and is running in the background. You will receive an email once it is completed");
                            Message.stickyWarning("The archive process has started and will run in the background.Once completed, you will receive an email notification.");
                            $mdDialog.hide(null);
                        })
                        .catch(function (e) {
                            console.error(e);
                            Message.dberror(e);
                        })
                })
                .catch(function (e) {
                    console.error(e);
                    Message.dberror(e);
                })
        }

        $scope.cancel = function () {
            $mdDialog.hide(null);
        }

        $scope.selectAll = function () {
            for (var i = 0; i < $scope.data.length; i++) {
                if ($scope.data[i].hasFolder) {
                    $scope.data[i].value = true;
                }
            }
        }

        $scope.deselectAll = function () {
            for (var i = 0; i < $scope.data.length; i++) {
                $scope.data[i].value = false;
            }
        }

        function formatLabel(label) {
            //return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
            return label;
        }
    });
