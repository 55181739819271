import { cipo } from 'cipo';

cipo.directive("tableSub", function () {
    return {
        replace: true,
        scope: {
            "fielddata": "=",
            "editmode": "=",
            "loading": "=",
            "cols": "=",
            "rows": "=",
            "aggregates": "="
        },
        templateUrl: "/ng/views/directives/system/form/fields/table-sub.html",
        controller: function ($scope, AggregateOperators) {
            $scope.cols = angular.copy($scope.cols);
            $scope.sortColumn = function (col) {
                for (var i = 0; i < $scope.cols.length; i++) {
                    if ($scope.cols[i].isSelected && $scope.cols[i].id != col.id) {
                        $scope.cols[i].isSelected = false;
                        $scope.cols[i].isDescending = false;
                    }
                }

                var compare = function (a, b) {
                    var property = col.id;
                    var term1 = a[property];
                    var term2 = b[property];
                    if (col.typeId == 3) {
                        if (!a[property]) term1 = "No";
                        if (!b[property]) term2 = "No";
                    }

                    if (col.typeId == 2) {
                        term1 = parseFloat(term1.replace(/[^\d.-]/g, ''));
                        term2 = parseFloat(term2.replace(/[^\d.-]/g, ''));
                    }

                    if (term1 < term2) {
                        return col.isDescending ? 1 : -1;
                    }
                    if (term1 > term2) {
                        return col.isDescending ? -1 : 1;
                    }
                    return 0;
                }

                col.isDescending = typeof col.isDescending != 'undefined' ? !col.isDescending : true;
                col.isSelected = true;

                $scope.rows.sort(compare);
            };

            $scope.changeOrder = function (row, isDown) {
                var i = $scope.rows.indexOf(row);
                $scope.rows.splice(i, 1);
                if (!isDown) $scope.rows.splice(i - 1, 0, row);
                else $scope.rows.splice(i + 1, 0, row);
            };

            $scope.checkAggreggatesExists = function (aggregates) {
                if (aggregates && Object.keys(aggregates).length) {
                    return true;
                }

                return false;
            }

            $scope.checkAggreggateValueExists = function (fieldId, aggregates) {
                if (fieldId && aggregates && Object.keys(aggregates).length && fieldId in aggregates) {
                    return true;
                }

                return false;
            }

            $scope.getAggregateName = function (aggregateId) {
                for (var key in AggregateOperators) {
                    // For SUM, return Total
                    if (aggregateId == 1) {
                        return "TOTAL";
                    }
                    else if (key == aggregateId) {
                        return AggregateOperators[key];
                    }
                }

                return "";
            };

            $scope.deleteTableRow = function (rowIndex) {
                $scope.rows.splice(rowIndex, 1);
            };
        }
    }
});
