import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("PdfHandler", function ($q, Model, URI, fileService, Message, $mdDialog, TEMPLATES_TYPES, Upload, $timeout, Conversation, userService) {
        //constructor extending Model
        var PdfHandler = Model.extend(function (obj) {
            var self = this;
            obj = obj || {};
            self.clearSelectedRows = obj.clearSelectedRows || null;
            self.contractId = obj.contractId || null;
            self.contractNo = obj.contractNo || null;
            self.correspondenceOperations = obj.correspondenceOperations || null;
            self.isLoading = obj.isLoading || null;
            self.moduleCode = obj.moduleCode || null;
            self.moduleId = obj.moduleId || null;
            self.moduleName = obj.moduleName || null;
            self.scope = obj.scope || null;
            // Array of { entityInstanceId:, documentNumber: } objects
            self.entityInstances = [];
            self.pdfMimeType = "application/pdf";
            self.zipMimeType = "application/zip";
            self.screenId = null;
            self.multipleScreens = false;

            // Returns an array of objects with the entityInstanceId and documentNumber (if available)
            self.getEntityInstances = function (rows) {
                rows = Array.isArray(rows) ? rows : [rows];
                var entityInstances = [];
                var screenIds = [];

            if (rows.length) {
                for (var i = 0; i < rows.length; i++) {
                    var newEntityInstance = { entityInstanceId: null, documentNumber: null };

                    if (typeof rows[i].entity_instance_id != 'undefined') {
                        newEntityInstance.entityInstanceId = rows[i].entity_instance_id;

                        if (typeof rows[i].full_doc_num != 'undefined') {
                            newEntityInstance.documentNumber = rows[i].full_doc_num;
                        }

                            if (typeof rows[i].closed_on != 'undefined') {
                                newEntityInstance.isClosedState = rows[i].closed_on ? true : false;
                            }

                            entityInstances.push(newEntityInstance);
                        }

                        if (!screenIds.find(e => e == rows[i].screen_id))
                            screenIds.push(rows[i].screen_id);
                    }
                }

                // If we have only one screen, then just select that one to be sent in method
                if (screenIds.length === 1)
                    self.screenId = screenIds[0];
                // In case we have multiple screens selected, we don't need to select any printouts because will don't have any on the same screen, so just print default from each screen
                else if (screenIds.length > 1)
                    self.multipleScreens = true;

                return entityInstances;
            }

            // Dialog to confirm whether to include entity instance attachments in PDF we're generating from documents
            self.showPdfOptionsDialog = function (entityInstances, actionType, printBlob) {
                var self = this;
                var isBundleDocuments = true;
                var isIncludeAttachments = false;
                var isIncludeActivity = false;

                if (entityInstances.length < 1) {
                    Message.warning(`Please select the documents you\'d like to ${actionType}.`);
                    return;
                }

                // Print blobl
                if (printBlob) {

                    // Check if documents are closed
                    var allEntityInstancesAreClosed = entityInstances.filter(function (ei) { return ei.isClosedState }).length == entityInstances.length;

                    // Check if documents are closed
                    if (!allEntityInstancesAreClosed) {
                        Message.warning(`Print PDF is only available for closed documents.`);
                        return;
                    }

                    self.getPdfs(entityInstances, null, actionType);
                    return;
                }

                // Define dialog local data object
                var dialogLocals = {
                    data: {
                        title: "Choose your settings",
                        buttonLabels: [
                            { key: 'confirm', value: 'Proceed' },
                            { key: 'cancel', value: 'Cancel' },
                        ],
                        checkboxes: [
                            { key: "isBundleDocuments", value: isBundleDocuments, label: "Bundle Documents Checkbox", description: "Combine documents into single PDF" },
                            { key: "isIncludeActivity", value: isIncludeActivity, label: "Include Activity Checkbox", description: "Include activity" },
                            { key: "isIncludeAttachments", value: isIncludeAttachments, label: "Include Attachments Checkbox", description: "Include attachments" }
                        ]
                    }
                }

            // Remove bundle documents checkbox if only single document selected OR if printing
            // (where we don't allow non-bundled document download)
            if (actionType == 'print' || entityInstances.length < 2) {
                dialogLocals.data.checkboxes.shift();
            }

                // Define dialog
                var dialog = $mdDialog
                    .confirm({
                        templateUrl: '/ng/views/system/modals/checkboxModal.tmpl.html',
                        locals: dialogLocals,
                        controller: 'checkboxDialogController',
                        parent: angular.element(document.body)
                    });

                // Open dialog
                $mdDialog.show(dialog)
                    .then(function (paramData) {
                        if (paramData == null)
                            return;

                        // If we have only one screen, we need to select
                        if (!self.multipleScreens) {
                            self.isLoading(true);
                            self.showPdfTemplateDialog(entityInstances, paramData, actionType);
                        }
                        else {
                            // We have multiple screens, so we need to print default
                            paramData.push({ key: 'printDefault', value: true });
                            self.getPdfs(entityInstances, paramData, actionType);
                        }
                    });
            }

            // Dialog to confirm which printout template to use when generating PDF
            self.showPdfTemplateDialog = function (entityInstances, paramData, actionType) {
                var self = this;
                self[URI.TEMPLATES.USER_TEMPLATES_DICT.method](URI.TEMPLATES.USER_TEMPLATES_DICT, { url: { typeName: TEMPLATES_TYPES[1], contractId: self.contractId, screenId: self.screenId }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
                    .then(function (res) {

                        // If only a single template to choose from (the default), bypass this step
                        if (res.length == 1) {

                            // Set first template, it can happen to not be the default one
                            // We need this only for print
                            if (actionType == 'print') {
                                paramData.push({ key: 'templateId', value: res[0].key });
                            }

                            self.getPdfs(entityInstances, paramData, actionType);
                            return;
                        }

                        // Say 'holup' if no printout templates (which are required)
                        if (res.length <= 0) {
                            Message.warning('No printout templates found. Please create a printout template before creating a PDF.');
                            self.isLoading(false);
                            return;
                        }

                    // Stop loading icon
                    self.isLoading(false);

                        // Define dialog local data object
                        var dialogLocals = {
                            data: {
                                title: "Choose a PDF Template",
                                buttonLabels: [
                                    { key: 'confirm', value: actionType != 'send' ? actionType : 'Proceed' },
                                    { key: 'cancel', value: 'Cancel' },
                                ],
                                options: self.getSelectOptions(res),
                                defaultOption: self.getSelectOptions(res, true)
                            }
                        }

                    // Define dialog
                    var dialog = $mdDialog
                        .confirm({
                            templateUrl: '/ng/views/system/modals/selectModal.tmpl.html',
                            locals: dialogLocals,
                            controller: 'selectDialogController',
                            parent: angular.element(document.body)
                        });

                    // Open dialog
                    $mdDialog.show(dialog)
                        .then(function (templateId) {
                            if (templateId == null) return;

                            paramData.push({ key: 'templateId', value: templateId });
                            self.isLoading(true);
                            self.getPdfs(entityInstances, paramData, actionType);
                        });
                });
        }

            // Builds array of options required for selectDialogController
            self.getSelectOptions = function (data, isGetDefault = false) {
                var isDefaultSet = false;
                var result = [];
                for (var i = 0; i < data.length; i++) {
                    // If current item is the default and we haven't set our default item yet
                    if (data[i].isPrimary && !isDefaultSet) {
                        var name = data[i].value;
                        if (name.toLowerCase().indexOf('default') <= -1)
                            name = name + ' (default)';
                        var newOption = { key: name, value: data[i].key };

                    if (isGetDefault) {
                        return newOption;
                    }

                        result.push(newOption);
                        isDefaultSet = true;
                    } else {
                        var newOption = { key: data[i].value, value: data[i].key };
                        result.push(newOption);
                    }
                }

                if (isGetDefault && result && result.length > 0)
                    return result[0];
                else
                    return result;
            }

            // returns a combined PDF of provided entity instances
            self.getPdfs = async function (entityInstances, paramData, actionType) {
                const LIMIT_SIZE = 104857600; //100 MB               

                var self = this;                            
                var urlParams = self.getUrlParams(paramData);

                // This is a single or multiple download with attachments
                if (actionType == 'download' && urlParams.isIncludeAttachments) {
                    var size = await self.getAttachementsSize(entityInstances);

                    //if the size of the PDF plus attachments is larger than LIMIT_SIZE, 
                    //download the ZIP file async, in the background, using Azure Functions
                    if (size > LIMIT_SIZE) {
                        self.backgroundDownloadInstances(entityInstances, paramData);
                    }
                    else {
                        self.getPdfData(entityInstances, paramData, actionType);
                    }
                }
                else {
                    self.getPdfData(entityInstances, paramData, actionType);
                }
            }

            self.getAttachementsSize = function (entityInstances) {
                var p = $q.defer();
                var self = this;

                var params = {};
                var pList = [];
                pList.unshift({ key: 'contractId', value: self.contractId });
                for (var i = 0; i < pList.length; i++) {
                    if (pList[i].key != null && pList[i].value != null) {
                        params[pList[i].key] = pList[i].value;
                    }
                }
                self[URI.MODULE.GET_ATTACHEMENTS_SIZE.method](URI.MODULE.GET_ATTACHEMENTS_SIZE, { url: params, urltype: 'obj', body: entityInstances.map(x => { return x.entityInstanceId }) })
                    .then(function (result) {
                        p.resolve(result);                        
                    })
                    .catch(function (e) {
                        console.error(e);
                        Message.dberror(e);
                        self.isLoading(false);
                        p.reject(e);
                    }) 

                return p.promise;
            }

            self.backgroundDownloadInstances = function (entityInstances, paramData) {               
                var self = this;
                var urlParams = self.getUrlParams(paramData);

                if (urlParams.isBundleDocuments) {
                    urlParams.title = self.getFileName();
                }
                else {
                    urlParams.title = self.getFileName(entityInstances[0], self.pdfMimeType);
                }

                self[URI.MODULE.BACKGROUND_DOWNLOAD_INSTANCES.method](URI.MODULE.BACKGROUND_DOWNLOAD_INSTANCES, { url: urlParams, urltype: 'obj', body: entityInstances.map(x => { return x.entityInstanceId }) })
                    .then(function () {
                        Message.stickyWarning("The total size of the download is larger than 100 MB. The download process has started and is running in the background. You will receive an email once it is completed");
                        self.isLoading(false);
                        self.clearSelectedRows();
                    })
                    .catch(function (e) {
                        console.error(e);
                        Message.dberror(e);
                        self.isLoading(false);                      
                    })
            }

            self.getPdfData = async function (entityInstances, paramData, actionType) {                    
                var self = this;
                var url = URI.MODULE.GET_INSTANCES_AS_SEPARATE_PDFS;
                var urlMethod = URI.MODULE.GET_INSTANCES_AS_SEPARATE_PDFS.method;
                var urlParams = self.getUrlParams(paramData);               

            // Print is always bundled
            if (actionType == 'print') {
                urlParams.isBundleDocuments = true;
            }

            // If printing OR bundling PDFs
            if (actionType == 'print' || urlParams.isBundleDocuments && entityInstances.length > 1) {
                url = URI.MODULE.GET_INSTANCES_AS_PDF;
                urlMethod = URI.MODULE.GET_INSTANCES_AS_PDF.method;
            }

            var requestBody = self.getRequestBody(urlParams.isBundleDocuments, entityInstances);

            requestBody.isPrint = actionType == 'print' ? true : false;

            if (urlParams.isBundleDocuments)
                urlParams.title = self.getFileName();

            // PDF byte array call
            self[urlMethod](url, { url: urlParams, urltype: 'obj', body: requestBody })
                .then(function (data) {
                    data = Array.isArray(data)
                        ? data
                        : [data];

                        var mimeType = actionType == 'print'
                            ? self.pdfMimeType : (urlParams.isIncludeAttachments
                                ? self.zipMimeType
                                : self.pdfMimeType);

                    var files = self.createFiles(data, entityInstances, mimeType, urlParams.isBundleDocuments);

                    switch (actionType) {
                        case 'download':
                            self.downloadFiles(files);
                            break;
                        case 'print':
                            self.printPdfs(files);
                            break;
                        case 'send':
                            self.getCipoDriveFolderId(mimeType, files, urlParams.contractId);
                            break;
                    }
                })
                .catch(function (e) {
                    Message.dberror(e);
                    self.isLoading(false);
                });
        }

        /*
            If we're bundling, only send list of entityInstanceIds in the request body.
            Otherwise, provide an array of objects where each object has its unique title & entityInstanceId.
        */
        self.getRequestBody = function (isBundleDocuments, entityInstances) {
            var self = this;
            var requestBody = {};

            requestBody.list = isBundleDocuments
                ? entityInstances.map(x => { return x.entityInstanceId })
                : entityInstances.map(instance => {
                    return {
                        title: self.getFileName(instance),
                        entityInstanceId: instance.entityInstanceId
                    }
                });

            return requestBody;
        }

        // Prints a list of PDFs
        self.printPdfs = function (files) {
            var self = this;

            $timeout(function () {
                // Should always be a single/bundled PDF file when printing
                var file = files[0];

                var iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = window.URL.createObjectURL(file);
                document.body.appendChild(iframe);

                $timeout(function () {
                    iframe.contentWindow.print();
                }, 400);

                self.isLoading(false);
                self.clearSelectedRows();
            }, 0);
        }

            // Builds urlParams object
            self.getUrlParams = function (paramData) {
                var self = this;
                var result = {};
                paramData ??= [];
                paramData.unshift({ key: 'contractId', value: self.contractId }, { key: 'moduleId', value: self.moduleId });

            for (var i = 0; i < paramData.length; i++) {
                if (paramData[i].key != null && paramData[i].value != null) {
                    result[paramData[i].key] = paramData[i].value;
                }
            }

            return result;
        }

            // Creates file objects
            self.createFiles = function (dataArray, list, mimeType, isBundleDocuments) {
                var result = [];
                var fileName = self.getFileName(null, mimeType);
                if (Array.isArray(dataArray) && dataArray.length > 0) {
                    for (var i = 0; i < dataArray.length; i++) {
                        // Build file from byte[]
                        if (!isBundleDocuments) {
                            fileName = self.getFileName(list[i], mimeType);
                        }
                        if (dataArray[i] != null) {
                            var newFile = fileService.createFile(dataArray[i], fileName, mimeType);
                            result.push(newFile);
                        }
                    }
                }

            return result;
        }

        self.getFileName = function (entityInstance, mimeType) {
            var self = this;
            // If doc number
            var documentNumber = entityInstance != null && typeof entityInstance.documentNumber != 'undefined'
                ? entityInstance.documentNumber
                : null;

            // If creating name for non-bundled PDF, use moduleName because the document number may have the
            // module code/abbreviation in it. Otherwise, use the module code.
            var moduleValue = documentNumber != null
                ? self.moduleName
                : self.moduleCode;

            var fileName = [moduleValue, documentNumber, self.contractNo]
                .filter(x => x)
                .join('-');
            fileName = fileName.length
                ? fileName + ` - ${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`
                : 'Documents';

            var fileExtension = mimeType == self.zipMimeType ? '.zip' : '.pdf';
            return fileName + fileExtension;
        }

        // Loops through a list of files and downloads them
        self.downloadFiles = function (fileArray) {
            var self = this;

            if (Array.isArray(fileArray) && fileArray.length > 0) {
                for (var i = 0; i < fileArray.length; i++) {
                    var blob = new Blob([fileArray[i]], { type: fileArray[i].type });
                    var url = window.URL.createObjectURL(blob);
                    self.downloadFile(url, fileArray[i].name);
                }
            }

            self.isLoading(false);
            self.clearSelectedRows();
        }

        // Creates a hidden link element that clicks itself to download the provided file
        self.downloadFile = function (url, fileName) {
            var link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.download = fileName;
            link.click();
        }

        // Gets/creates a folder within My CIPO Drive for user for the current entity/module
        // 
        self.getCipoDriveFolderId = function (mimeType, files, contractId) {
            var self = this;

            $timeout(function () {
                var data = {
                    id: 0,
                    name: self.moduleName + " - Shared",
                    // Indicates root folder
                    parentId: -1
                };

                self[URI.MY_CIPO_DRIVE.GET_FOLDER_ID.method](URI.MY_CIPO_DRIVE.GET_FOLDER_ID, data)
                    .then(function (folderId) {
                        // var uploadUrl = `${URI.MY_CIPO_DRIVE.UPLOAD_FILE}?parentId=${folderId}&mimeType=${fileType}`;
                        // self.uploadPdfsToMyCipoDrive(uploadUrl, contractId, files, []);
                        var uploadUrl = `${URI.MY_CIPO_DRIVE.UPLOAD_FILES}?parentId=${folderId}&mimeType=${mimeType}`;
                        self.uploadPdfsToMyCipoDrive(uploadUrl, files, contractId);
                    })
                    .catch(function (err) {
                        self.isLoading(false);
                    });
            }, 0);
        }

        /// Recursive
        // self.uploadPdfsToMyCipoDrive = function (uploadUrl, contractId, filesToUpload, uploadedFiles) {
        //     var self = this;

        //     // If all files uploaded, continue with opening Correspondence modal
        //     if (filesToUpload.length == 0) {
        //         self.isLoading(false);
        //         self.openCorrespondenceModal(uploadedFiles, contractId);
        //         return;
        //     }

        //     // Otherwise, continue uploading files.
        //     var uploadObject = Upload.upload({
        //         url: uploadUrl,
        //         data: {
        //             file: filesToUpload[0]
        //         }
        //     });

        //     uploadObject
        //         .then(function (res) {
        //             $timeout(function () {
        //                 filesToUpload.shift();
        //                 uploadedFiles.push(res.data);
        //                 self.uploadPdfsToMyCipoDrive(uploadUrl, contractId, filesToUpload, uploadedFiles)
        //             });
        //         }, function (err) {
        //             if (err.status > 0) {
        //                 filesToUpload[0].message = "An error has occured. The file couldn't be uploaded.";
        //                 filesToUpload[0].error = true;
        //                 self.isLoading(false);
        //             }
        //         }, function (ev) {
        //         });
        // }

        // Returns an upload file object
        self.uploadPdfsToMyCipoDrive = function (uploadUrl, files, contractId) {
            var self = this;
            var uploadObject = Upload.upload({
                url: uploadUrl,
                data: {
                    files: files
                }
            });

            uploadObject
                .then(function (res) {
                    $timeout(function () {
                        self.isLoading(false);
                        self.openCorrespondenceModal(res.data, contractId);
                    });
                }, function (err) {
                    if (err.status > 0) {
                        for (var i = 0; i < files.length; i++) {
                            file.message = "An error has occured. The file couldn't be uploaded.";
                            file.error = true;
                        }
                        self.isLoading(false);
                    }
                }, function (ev) {
                });
        }

        // Inits correspondence draft and opens modal
        self.openCorrespondenceModal = function (uploadedFiles, contractId) {
            var self = this;
            var thread = {};
            var initData = {
                id: 0,
                operations: self.correspondenceOperations,
                folderId: -3,
                moduleId: self.moduleId
            };

            thread = new Conversation(initData);

            var save = function (thread) {
                var isValidContractId = !isNaN(parseInt(thread.emails[0].properties.contractId)) && thread.emails[0].properties.contractId != 0;
                if (!isValidContractId) {
                    Message.error('Please select a contract.');
                    return;
                }
                thread.disableBtns = true;
                thread.saving = true;
                thread.emails[0].saving = true;
                if (thread.id == 0 || !thread.id) {
                    thread.emails[0].save_new_draft()
                        .then(function (result) {
                            if (result) {
                                thread.saving = false;
                                thread.emails[0].properties.id = thread.emails[0].properties.id == 0
                                    ? result
                                    : thread.emails[0].properties.id;
                                // thread.emails[0].properties.conversationId = result;
                            } else {
                                Message.dberror("An error has occured. Your message couldn't be saved.");
                            }
                            thread.disableBtns = false;
                        });
                } else {
                    thread.emails[0].save_existing_draft()
                        .then(function (result) {
                            thread.saving = false;
                            if (result) {
                            } else {
                                Message.dberror("An error has occured. Your message couldn't be saved.");
                            }
                            thread.disableBtns = false;
                        });
                }
            };

            thread.init()
                .then(function () {
                    thread.contractId = contractId;
                    thread.emails[0].contractId = contractId;
                    thread.emails[0].properties.contractId = contractId;
                    thread.emails[0].attachments = uploadedFiles;
                    thread.emails[0].properties.attachments = uploadedFiles;
                    thread.emails[0].sendAttachments = true;
                    thread.emails[0].properties.sendAttachments = true;
                    thread.emails[0].form.clearDirty();
                    // watch if the form gets dirty
                    var clearWatch = self.scope.$watch(function () { return thread.emails[0].form.dirty; }, function (newParam, oldParam) {
                        if (newParam) {
                            save(thread);
                            clearWatch();
                        }
                    });
                    thread.templateWatch = self.scope.$watch(function () { return thread.emails[0].properties.templateId; }, function (n, o) {
                        if (n != o) thread.emails[0].set_Template(n, thread.emails[0].properties.contractId);
                    }
                    );
                    thread.contractWatch = self.$watch(function () { return thread.emails[0].properties.contractId; }, function (n, o) {
                        if (n != o) thread.emails[0].reload_Recipients(n, self.moduleId);
                    }
                    );
                    if ((id == 0) && (thread.emails[0].form.dirty == true)) {
                        save(thread);
                    }
                })
                .catch(function (e) {
                });

            thread.expand = true;
            thread.isModal = true;
            thread.contractName = userService.system.contractsLookup[self.contractId].value;

            $mdDialog.show({
                locals: { conv: thread, isCorrespondencePage: false, senderId: userService.system.userdata.id },
                controller: 'editDraftController',
                templateUrl: '/ng/views/admin/modals/editDraft.html',
                parent: angular.element(document.body),
                targetEvent: event || null,
                fullscreen: true,
                escapeToClose: false,
                clickOutsideToClose: false
            }).then(function (conv) {
                conv.isModal = false;
            }, function (res) {
                thread.isModal = false;
                self.clearSelectedRows();
                var message = null;
                switch (res.msg) {
                    case 'send':
                        message = 'Documents sent.';
                        break;
                    case 'discard':
                        // Do nothing.
                        break;
                    case 'close':
                    case 'cancel':
                        message = 'Draft saved to correspondence.';
                        break;
                }

                if (message != null) {
                    Message.info(message);
                }
            });
        }
    });

    PdfHandler.prototype.download = function (rows) {
        var self = this;
        var list = self.getEntityInstances(rows) || [];
        self.showPdfOptionsDialog(list, 'download');
    }

    PdfHandler.prototype.downloadByUrl = function (url, fileName) {
        var self = this;
        self.downloadFile(url, fileName);
    }

    PdfHandler.prototype.email = function (scope, rows) {
        var self = this;
        self.scope = scope;
        var list = self.getEntityInstances(rows) || [];
        self.showPdfOptionsDialog(list, 'send');
    }

    PdfHandler.prototype.emailPdf = function (scope, file) {
        var self = this;
        self.scope = scope;
        self.getCipoDriveFolderId(self.pdfMimeType, [file], self.contractId);
    }

        PdfHandler.prototype.printDocuments = function (rows, printBlob) {
            var self = this;
            var list = self.getEntityInstances(rows) || [];
            self.showPdfOptionsDialog(list, 'print', printBlob);
        }

    PdfHandler.prototype.printPdf = function (pdf) {
        var self = this;
        self.printPdfs([pdf], 0);
    }

    return PdfHandler;
});