<div fxLayout="row"
     fxLayoutAlign="space-between center"
     fxLayoutGap="8px"
     class="progressBlock">
     <mat-icon class="progress-icon"
               fxFlex="18px"
               data-test="icon">{{ isDuration ? 'access_time_filled' : 'attach_money'}}</mat-icon>
     <span fxFlex="90"
           class="progressHolder progressBackground"
           data-test="progressBackground">
          <span [style.width]="valuePercent"
                class="progressHolder progressForeground"
                data-test="progress">
          </span>
     </span>
     <span data-test="value">
            {{value | itvNumberWithFormattings:formattings:restrictions}}
     </span>
</div>